import { defineMessages } from 'react-intl';

export default defineMessages({
    policyContacts: {
        id: 'gateway.contacts.views.policy-contact-detail.Policy Contacts',
        defaultMessage: 'Contract Contacts'
    },
    policyContactsCount: {
        id: 'gateway.contacts.views.policy-contact-detail.Policy Contacts Count',
        defaultMessage: 'Contract Contacts {policyContactsCount}'
    }
});
