import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class PVVehicleTypeAvailabilityService {

    static availableVehicleCategoriesTypes(virtualProductCode, countVehicles, countVehiclesTB, isDirectChannel, offerNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayavailability'),
            'availableVehicleCategoriesTypes_PV',
            [virtualProductCode, countVehicles, countVehiclesTB, isDirectChannel, offerNumber],
            additionalHeaders
        );
    }
}
