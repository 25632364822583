import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { PolicyService, UserService } from 'gw-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { LobIconUtil } from '@xengage/gw-portals-util-js';
import { PolicyClaims } from 'gw-capability-gateway-claim-react';
import { PolicyCommission } from 'gw-capability-gateway-commission-react';
import { isCapabilityEnabled } from '@xengage/gw-portals-config-js';
import { withRouter } from 'react-router-dom';
import { Loader, withModalContext } from '@jutro/components';
import { GatewayBillingService } from 'gw-capability-gateway-billing';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { DocumentsPage } from 'gw-capability-document-react';
import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { PortimaService } from 'pv-capability-portima';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import FormattedHeaderComponent from '../../Components/FormattedHeaderComponent/FormattedHeaderComponent';
import CommonRoutes from '../../Common/CommonRouting';
import metadata from './PolicyDetails.metadata.json5';
import styles from '../Policies.module.scss';
import messages from '../Policies.messages';
import pageRouting from '../../Common/Policies-config.json5';

const { capabilitiesConfig } = appConfig;

const DocumentPageService = {
    getDocuments: PolicyService.getDocumentsForPolicy,
    getNonInvoiceDocuments: GatewayBillingService.getNonInvoiceDocuments,
    generateUploadToken: GatewayDocumentService.generateUploadToken,
    uploadDocument: GatewayDocumentService.uploadDocument,
    removeDocument: GatewayDocumentService.removeDocument,
    downloadDocument: GatewayDocumentService.downloadDocument,
    fromPolicyDetails: true
};

function documentFeatureTurnedOn(routes) {
    const documentRoute = {
        path: '/documents',
        component: DocumentsPage
    };
    if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'document' })) {
        routes.push(documentRoute);
    }
}

class PolicyDetails extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        match: PropTypes.shape({
            path: PropTypes.string,
            params: PropTypes.shape({
                policyNumber: PropTypes.string
            }),
            url: PropTypes.string
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        authenticated: PropTypes.bool.isRequired,
        location: PropTypes.shape({
            state: PropTypes.string,
            pathname: PropTypes.string
        }).isRequired
    };

    static contextType = TranslatorContext;

    state = {
        policyResponse: {},
        policyBillingData: {},
        currentView: undefined,
        isLoading: true,
        commissionTurnedOn: false,
        showBlocRetourButton: false,
        disableBlocRetour: false,
        nonInvoiceDocumentCount: 0
    };

    componentDidMount() {
        const {
            authUserData,
            location: { pathname }
        } = this.props;
        if (_.isEqual(_.lowerCase(authUserData.organizationType_PV), 'vivium')) {
            this.setState({ showBlocRetourButton: true });
        }
        if (pathname.includes('endorsement') && !pathname.includes('summary')) {
            this.props.location.pathname += '/summary';
        }
        this.getPolicyDetails();
    }

    componentDidUpdate(preProp) {
        const {
            match: {
                params: { policyNumber }
            }
        } = this.props;
        if (preProp.location.pathname.indexOf(policyNumber) === -1) {
            this.getPolicyDetails();
        }
    }

    getAccountHolderNameandNumber = (accountData, type) => {
        if (_.isEmpty(accountData)) {
            return null;
        }
        if (type === 'status') {
            return accountData.latestPeriod.displayStatus;
        }
        return `${accountData.product.productName} (${accountData.policyNumber})`;
    };

    getRoutesWithFeature() {
        const routes = [...pageRouting];
        this.billingFeatureTurnedOn(routes);
        this.hasAccessForCommision(routes);
        this.claimFeatureTurnedOn(routes);
        documentFeatureTurnedOn(routes);
        return routes;
    }

    billingFeatureTurnedOn = (routes) => {
        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })) {
            const billingRoute = {
                path: '/billing',
                componentPath: 'Policies/Billing/Billing'
            };
            routes.push(billingRoute);
        }
    };

    hasAccessForCommision = (routes) => {
        const { commissionTurnedOn } = this.state;
        if (
            commissionTurnedOn
            && isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'commission' })
        ) {
            const commissionRoute = {
                path: '/commission',
                component: PolicyCommission
            };
            routes.push(commissionRoute);
        }
    };

    claimFeatureTurnedOn = (routes) => {
        if (this.isClaimsTurnedOn()) {
            const claimRoute = {
                path: '/claims',
                component: PolicyClaims
            };
            routes.push(claimRoute);
        }
    };

    updateCountInfo = (type) => {
        const { policyResponse } = this.state;
        const currentCount = _.get(policyResponse, `numberOf${type}`);
        _.set(policyResponse, `numberOf${type}`, currentCount + 1);
        this.setState({ policyResponse });
    };

    updateDocumentCount = (count, type = 'Documents') => {
        const { policyResponse } = this.state;
        _.set(policyResponse, `numberOf${type}`, count);
        this.setState({ policyResponse });
    };

    getPolicyDetails = async () => {
        const {
            authenticated,
            authHeader,
            match: {
                params: { policyNumber }
            },
            location: { state: redirectPath, pathname }
        } = this.props;

        if (authenticated) {
            this.getCommissionTurnedOn(authHeader);
            const getPolicyResponse = await PolicyService.getPolicy(policyNumber, authHeader);
            const routePath = pathname && (pathname.trim().split('/') || []);
            const selectedPageFromPath = routePath[routePath.length - 1];
            const activeTile = selectedPageFromPath || redirectPath || 'summary';
            let countNonInvoiceDocuments = 0;
            try {
                countNonInvoiceDocuments = await GatewayBillingService.getCountNonInvoiceDocuments(policyNumber, authHeader);
            } catch (error) {
                console.error(error);
            }

            this.setState({
                currentView: activeTile,
                policyResponse: getPolicyResponse,
                nonInvoiceDocumentCount: countNonInvoiceDocuments
            });
        }
        await PolicyService.getPolicyBillingSummaryInfo(policyNumber, authHeader).then((billingResponseData = {}) => {
            if (!_.isUndefined(billingResponseData.policyPeriodBillingSummaries)) {
                const billingData = billingResponseData.policyPeriodBillingSummaries;
                const billingIcon = _.get(billingData, '[0].isDelinquent') ? 'mi-warning' : 'mi-payment';
                this.setState({
                    policyBillingData: billingResponseData,
                    billingIcon: billingIcon
                });
            } else {
                const billingIcon = 'mi-payment';
                this.setState({
                    billingIcon: billingIcon
                });
            }
        });

        await PolicyService.addRecentlyViewedPolicy(policyNumber, authHeader);
        this.setState({
            isLoading: false
        });
    };

    getCommissionTurnedOn = async (authHeader) => {
        const permissionDTO = {
            permission: 'viewcommissions'
        };
        const commissionTurnedOn = await UserService.hasUserSystemPermission(
            permissionDTO,
            authHeader
        );
        this.setState({ commissionTurnedOn });
    };

    isClaimsTurnedOn = () => {
        return isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'claim' });
    };

    handleTilesOnClick = (id) => {
        this.setState({ currentView: id });
    };

    triggerBlocRetour = async () => {
        const {
            authHeader,
            match: {
                params: { policyNumber }
            }
        } = this.props;
        const response = await PortimaService.triggerBlocRetourPV(undefined, policyNumber, authHeader);
        if (response !== null) {
            this.setState({
                disableBlocRetour: true
            });
        }
    };

    render() {
        const {
            policyResponse,
            currentView,
            isLoading,
            billingIcon,
            commissionTurnedOn,
            policyBillingData
        } = this.state;
        const productName = _.get(policyResponse, 'product.productCode');
        if (isLoading) {
            return <Loader loaded={!isLoading} />;
        }

        if (_.isEmpty(policyResponse)) {
            return null;
        }

        const overrides = {
            [currentView]: {
                active: true
            },
            commission: {
                active: currentView === 'commission',
                visible: commissionTurnedOn && isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'commission' })
            },
            billing: {
                active: currentView === 'billing',
                tileIcon: billingIcon,
                visible: isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })
            },
            claims: {
                active: currentView === 'claims',
                visible: this.isClaimsTurnedOn()
            },
            policyProductName: {
                content: this.getAccountHolderNameandNumber(policyResponse, '')
            },
            policyStatus: {
                message: this.getAccountHolderNameandNumber(policyResponse, 'status')
            },
            accountHeader: {
                redirectPath: `/accounts/${policyResponse.account.accountNumber}/summary`,
                displayName: policyResponse.account.accountHolder.displayName,
                message: messages.accountNavigation
            },
            policyIcon: {
                icon: LobIconUtil.getMaterialIcon(productName)
            },
            triggerBlocRetour: {
                visible: this.state.showBlocRetourButton,
                content: this.state.disableBlocRetour ? messages.blocRetourRequested : messages.triggerBlocRetour,
                disabled: this.state.disableBlocRetour
            },
            documents: {
                active: currentView === 'documents',
                value: _.parseInt(_.get(policyResponse, 'numberOfDocuments')) + _.parseInt(this.state.nonInvoiceDocumentCount)
            }
        };

        const resolvers = {
            resolveComponentMap: {
                accountheadercomponent: FormattedHeaderComponent
            },
            resolveCallbackMap: {
                handleTilesOnClick: this.handleTilesOnClick,
                onTriggerBlocRetour: this.triggerBlocRetour
            },
            resolveClassNameMap: styles
        };
        const {
            match: { url }
        } = this.props;
        const policyDetailsData = {
            policyResponse: policyResponse,
            getUpdateCountInfo: this.updateCountInfo,
        };
        return (
            <DependencyProvider value={{ DocumentPageService }}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={policyResponse}
                    overrideProps={overrides}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
                <CommonRoutes
                    steps={this.getRoutesWithFeature()}
                    basePath={url}
                    setDocumentCount={this.updateDocumentCount}
                    policyNumber={policyResponse.policyNumber}
                    policyDetailsData={policyDetailsData}
                    billingData={policyBillingData}
                    additionalDocMetadataPropsPV={{ availability_PV: 'gpa' }} // P&V: stating the visibility of the document gpa/customerzone/internal/customer zone & gpa
                />
            </DependencyProvider>
        );
    }
}
export default withRouter(withAuthenticationContext(withModalContext(PolicyDetails)));
