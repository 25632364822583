import _ from 'lodash';
import BusinessConstant from './BusinessConstant';
import CustomClassValidations from './CustomClassValidations';
import DriverComponentUtil from './DriverComponentUtil';
import PersonUtil from './PersonUtil';

function getDisplayName(driver, translator) {
    if (driver.person.firstName === '0dummyname0') {
        return '';
    }
    return PersonUtil.getShortDisplayName(driver.person, translator);
};

function isPrimaryDriver(driver, vehicleDrivers) {
    if (_.isArray(vehicleDrivers)) {
        const primaryDriver = (usage) => {
            const compareID = (firstID, secondID) => {
                return !_.isNil(firstID) && !_.isNil(secondID) && firstID === secondID;
            };
            const uv = _.isObject(usage.value) ? usage.value : usage; // supersmart extract from VM vs. direct model
            const d = _.isObject(driver.value) ? driver.value : driver; // supersmart extract from VM vs. direct model
            return (compareID(uv.driverTempID, d.tempID) || compareID(uv.driverID, d.fixedId)) && uv.vehicleDriverUsage === 'PRI';
        };
        return vehicleDrivers.some(primaryDriver);
    }

    return false;
};

function setDefaultValues(driver) {
    const defaultBooleanToIfNotSet = function (propertyValue, defaultValue) {
        if (!_.isBoolean(propertyValue)) {
            return defaultValue;
        }
        return propertyValue;
    };

    const defaultTypeKey = (typekeyValue, defaultValue) => {
        if (_.isNil(typekeyValue)) {
            return defaultValue;
        }
        return typekeyValue;
    };
    driver.person.isCustomerInPVAgency_PV = defaultBooleanToIfNotSet(driver.person.isCustomerInPVAgency_PV, false);
    driver.person.hasDrivingLicenseB_PV = defaultBooleanToIfNotSet(driver.person.hasDrivingLicenseB_PV, true);
    driver.person.shareEmailAddress_PV = defaultBooleanToIfNotSet(driver.person.shareEmailAddress_PV, false);

    driver.hasOwnInsurance = defaultBooleanToIfNotSet(driver.hasOwnInsurance, true);
    driver.hasClaimAttestLast3Years = defaultBooleanToIfNotSet(driver.hasClaimAttestLast3Years, true);

    driver.disability = defaultTypeKey(driver.disability, 'No');

    driver.claimsTPL5YearsFault_PV = defaultTypeKey(driver.claimsTPL5YearsFault_PV, '0');
    driver.claimsTPL5YearsFaultCL_PV = defaultTypeKey(driver.claimsTPL5YearsFaultCL_PV, '0');

    driver.noOfYearsSinceLastClaim_PV = defaultTypeKey(driver.noOfYearsSinceLastClaim_PV, 'None');

    driver.claimsTPL5YearsNFault_PV = defaultTypeKey(driver.claimsTPL5YearsNFault_PV, '0');
    driver.claimsTPL5YearsNFaultCL_PV = defaultTypeKey(driver.claimsTPL5YearsNFaultCL_PV, '0');
    driver.claimsTheft5YearsFault_PV = defaultTypeKey(driver.claimsTheft5YearsFault_PV, '0');
    driver.claimsTheft5YearsFaultCL_PV = defaultTypeKey(driver.claimsTheft5YearsFaultCL_PV, '0');
    driver.claimsMD5YearsFault_PV = defaultTypeKey(driver.claimsMD5YearsFault_PV, '0');
    driver.claimsMD5YearsFaultCL_PV = defaultTypeKey(driver.claimsMD5YearsFaultCL_PV, '0');

    driver.person.primaryLanguage_PV = defaultTypeKey(driver.person.primaryLanguage_PV, 'fr');

    driver.wasRiskRefusedByInsurer = defaultBooleanToIfNotSet(driver.wasRiskRefusedByInsurer, false);
};

function restoreMissingDefaultValues(driver) {
    // setup scene
    const driverWithDefaults = _.cloneDeep(driver);
    setDefaultValues(driverWithDefaults);

    // iterate through default values and find out what is missing in given driver
    for (const prop in driverWithDefaults) {
        if (typeof driverWithDefaults[prop] !== 'function'
            && typeof driverWithDefaults[prop] !== 'object' // skip 'person' object
            && driverWithDefaults.hasOwnProperty(prop)
            && _.isNil(driver[prop]))
        {
            // add missing default property
            driver[prop] = driverWithDefaults[prop];
        }
    }
}

const visibleFields = (driverVm, allCoverables, jobType, quickQuoteMode, isCommercialOrProductCode, brand) => {
    const { showClaimsQuestions } = DriverComponentUtil();
    const driver = driverVm.value;
    const _isPrimaryDriver = isPrimaryDriver(driver, allCoverables.vehicleDrivers);
    const isAccountHolder = driver.isPolicyHolder;
    const isOnlyAssignedTo2Wheelers = CustomClassValidations.isDriverAssignedTo2WheelersOnly(allCoverables.vehicles, allCoverables.vehicleDrivers, driver);
    const isPolicyChange = jobType === BusinessConstant.JOB_TYPE_POLICY_CHANGE;
    const isCommercial = typeof isCommercialOrProductCode === "boolean" ? isCommercialOrProductCode : isCommercialOrProductCode === BusinessConstant.PCV_PROD_CODE;

    return {
        // vehilces-in-contract text
        driverVehiclesContractSection: {
            visible: _isPrimaryDriver
        },
        vehicleInContractText: {
            visible: _isPrimaryDriver
        },

        // driver general info
        driverNameContainer: {
            visible: !quickQuoteMode || !isAccountHolder
        },

        // address
        driverAddressSectionContainer: {
            visible: (!quickQuoteMode || !isAccountHolder)
        },
        driverSameAddress: {
            visible: !isAccountHolder && !isCommercial,
        },

        // driver contact info
        driverContactSectionContainer: {
            visible: !quickQuoteMode
        },
        shareEmailAddress_PV: {
            visible: (brand === 'pv')
        },

        // driving license & driving vehicles
        dateFirstLicensed_PV: {
            visible: driver.person.hasDrivingLicenseB_PV,
        },
        dateFirstLicenseA_PV: {
            required: CustomClassValidations.isRequiredDateFirstLicenseAForDriver(allCoverables.vehicles, allCoverables.vehicleDrivers, driver),
            visible: CustomClassValidations.isRequiredDateFirstLicenseAForDriver(allCoverables.vehicles, allCoverables.vehicleDrivers, driver)
        },

        // history
        driverHistorySectionContainer: {
            visible: _isPrimaryDriver && !isOnlyAssignedTo2Wheelers || !quickQuoteMode
        },
        driverInsuranceAssociated: {
            visible: _isPrimaryDriver && !isOnlyAssignedTo2Wheelers
        },
        isCurrentlyARegularDriver: {
            visible: driver.hasOwnInsurance === false,
        },
        insuranceEndTime: {
            visible: driver.isCurrentlyARegularDriver === false,
        },
        driverClaimCertificates: {
            visible: (_isPrimaryDriver && !isOnlyAssignedTo2Wheelers) &&
                (driver.hasOwnInsurance === true ||
                driver.insuranceEndTime === 'less_than_one_year' ||
                driver.insuranceEndTime === 'between_one_and_three_years' ||
                (driver.hasOwnInsurance === false && driver.isCurrentlyARegularDriver === true))
        },
        riskRefusedDetails: {
            visible: !quickQuoteMode
        },

        // claims
        driverClaimSectionContainer: {
            visible: !quickQuoteMode || (showClaimsQuestions(allCoverables, driver) && !isOnlyAssignedTo2Wheelers)
        },
        claimsTPL5YearsFault_PV: {
            visible: !isCommercial,
        },
        claimsTPL5YearsFaultCL_PV: {
            visible: isCommercial,
        },
        noOfYearsSinceLastClaim_PV: {
            visible: (!isCommercial && driver.claimsTPL5YearsFault_PV !== '0'
                      || isCommercial && driver.claimsTPL5YearsFaultCL_PV !== '0'), // not code '0'
        },
        claimsTPL5YearsNFault_PV: {
            visible: !isCommercial && !quickQuoteMode,
        },
        claimsTPL5YearsNFaultCL_PV: {
            visible: isCommercial && !quickQuoteMode,
        },
        claimsTheft5YearsFault_PV: {
            visible: !isCommercial && !quickQuoteMode,
        },
        claimsTheft5YearsFaultCL_PV: {
            visible: isCommercial && !quickQuoteMode,
        },
        claimsMD5YearsFault_PV: {
            visible: !isCommercial && !quickQuoteMode,
        },
        claimsMD5YearsFaultCL_PV: {
            visible: isCommercial && !quickQuoteMode,
        },

        // insurance
        driverInsuranceSectionContainer: {
            visible: isPolicyChange
        },
        medicalPermissionToDrive_PV: {
            visible: driver.medicalDisability_PV,
        },
    };
};

export default {
    getShortDisplayName: getDisplayName, // pre-upgrade orignal getDisplayName
    isPrimaryDriver,
    visibleFields,
    restoreMissingDefaultValues,
    setDefaultValues // pre-upgrade setDefaultValuesToFalse
};
