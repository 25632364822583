import { defineMessages } from 'react-intl';

export default defineMessages({
    phoneNumber: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Phone Number',
        defaultMessage: 'Phone Number'
    },
    paymentPlan: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Payment Plan',
        defaultMessage: 'Payment Plan'
    },
    contractStartDate: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Policy Start Date',
        defaultMessage: 'Contract Start Date'
    },
    address: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Address',
        defaultMessage: 'Address'
    },
    email: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Email',
        defaultMessage: 'Email'
    },
    monthlyPremium: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Monthly premium',
        defaultMessage: 'Monthly premium'
    },
    halfYearlyPremium: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Half Yearly premium',
        defaultMessage: 'Half Yearly premium'
    },
    quarterlyPremium: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Quarterly premium',
        defaultMessage: 'Quarterly premium'
    },
    totalPremium: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Total Premium',
        defaultMessage: 'Total premium'
    }
});
