/* eslint-disable camelcase */
import React, { useContext, useCallback, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useModal } from '@jutro/components';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { getApdLobs, getApdLobQuoteUrl } from '@xengage/gw-portals-url-js';
import { LocalDateUtil, JobUtil} from '@xengage/gw-portals-util-js';
import { SubmissionService, OrganizationService } from 'gw-capability-gateway';
import { AccountService } from 'gw-capability-gateway-policy';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import metadata from './NewSubmissionPage.metadata.json5';
import styles from './NewSubmissionPage.module.scss';
import messages from '../../NewQuoteAccountSearch.messages';
import gatewayMessages from '../../../gateway.messages';

function NewSubmissionPage(props) {
    const {
        showAlert,
        showConfirm
    } = useModal();

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        isAnExistingAccount,
        submissionVM,
        postalCode,
        updateSubmissionVM,
        producerCodeValue,
        isAgent,
        allOrganisationValue,
        authHeader
    } = props;
    const [productValue, updateProductValue] = useState([]);
    const [producerCodeOrgValue, updateProducerCodeValue] = useState([]);
    const [selectedOrganisationValue, updateOrganisation] = useState('');
    const [showErrors, updateShowErrors] = useState([false]);
    const { history } = props;
    const { lobQuoteURL } = appConfig;
    const { onValidate, isComponentValid } = useValidation('NewSubmissionPage');

    const startSubmission = useCallback(async () => {
        const productCode = _.get(submissionVM, 'productCode.value');

        if (!_.isNil(getApdLobQuoteUrl(productCode))) {
            const accountId = _.get(submissionVM, 'accountPublicId');
            const nextLocation = {
                productCode,
                accountId,
                jobId: undefined
            };
            history.push(getApdLobQuoteUrl(productCode), nextLocation);
        } else {
            const data = {
                ...submissionVM.value,
                // eslint-disable-next-line camelcase
                quoteType_PV: 'Quick'
            };
            const newSubmissionResponse = await SubmissionService.createSubmission(
                // submissionVM.value - TODO restore when UI is worked
                data,
                authHeader
            );
            if (newSubmissionResponse.jobNumber > 0) {
                if (!_.isNil(lobQuoteURL[productCode]) || !_.isNil(lobQuoteURL[`QQ${productCode}`])) {
                    const nextLocation = {
                        quoteentry: {
                            postalCode: postalCode,
                            quoteID: newSubmissionResponse.jobNumber
                        },
                        productCode
                    };
                    const url = newSubmissionResponse.quoteType === 'Quick' ? lobQuoteURL[`QQ${productCode}`] : lobQuoteURL[productCode];
                    history.push(url, nextLocation);
                }
                else {
                    JobUtil.openJobInXCenter(newSubmissionResponse.jobNumber);
                }
            }
        }
    }, [authHeader, history, appConfig.lobQuoteURL, postalCode, submissionVM]);

    const inferProductName = (productCode) => {
        const productName = productCode.replace(/([A-Z])/g,' $1');
        return productName.charAt(0).toUpperCase() + productName.slice(1);
    };

    const refreshProducts = useCallback(
        async (newSubmissionVM) => {
            try {
                const submission = {
                    ...newSubmissionVM.value,
                    // product code is a required field and must have a value
                    productCode: _.get(newSubmissionVM, 'productCode.value', '')
                };
                const productCodeResponse = await AccountService.getAvailableProductsForAccount(
                    submission,
                    authHeader
                );
                const productCodeValues = productCodeResponse;
                const productCodeAvailableValue = productCodeValues
                    .filter((value) => !value.isRiskReserved)
                    .map(({ productCode, productName, virtualProductCode_PV, virtualProductVersion_PV }) => {
                        return {
                            code: productCode,
                            name: `${productName} (v${virtualProductVersion_PV})`, // for the convinience includes version
                            virtualProductCode: virtualProductCode_PV,
                            virtualProductVersion: virtualProductVersion_PV
                        };
                    });
                const apdLobs = getApdLobs().map((productCode) => {
                    return {
                        code: productCode,
                        name: inferProductName(productCode)
                    };
                });
                apdLobs
                    .forEach((apdLob) => {
                        const element = productCodeAvailableValue.find((lob) => lob.code === apdLob.code);
                        if (element === undefined) {
                            productCodeAvailableValue.push(apdLob);
                        }
                    });

                updateProductValue(productCodeAvailableValue);
            } catch {
                updateProductValue([]);
                showAlert({
                    title: gatewayMessages.modalError,
                    message: gatewayMessages.gatewayViewsModalErrorData,
                    status: 'error',
                    icon: 'mi-error-outline'
                }).catch(_.noop);
            }
        },
        [authHeader, showAlert]
    );

    const getProducerCodesOrganization = useCallback(
        async (organisationCode) => {
            const producerCodeResponse = await OrganizationService.getProducerCodeForOrganization(
                organisationCode,
                authHeader
            );
            const producerCodeOrganisation = producerCodeResponse.map((value) => {
                return {
                    code: value.code,
                    name: value.displayValue
                };
            });
            updateProducerCodeValue(producerCodeOrganisation);
            _.set(submissionVM, 'producerCode', '');
            _.set(submissionVM, 'productCode', '');
            updateSubmissionVM(submissionVM);
        },
        [authHeader, submissionVM, updateSubmissionVM]
    );
    const handleValueOrganisation = (value) => {
        let organisationCode = {};

        const selectedOrganisation = value;
        allOrganisationValue.forEach((result) => {
            if (result.code === selectedOrganisation) {
                organisationCode = {
                    displayName: result.name,
                    publicID: result.code
                };
            }
        });
        getProducerCodesOrganization(organisationCode);
        updateOrganisation(value);
    };
    const writeValue = useCallback(
        (value, path) => {
            const accountId = _.get(submissionVM, 'accountPublicId');
            const newSubmissionVMClone = viewModelService.clone(submissionVM);
            _.set(newSubmissionVMClone.value, path, value);
            _.set(newSubmissionVMClone.value, 'accountPublicId', accountId);
            updateSubmissionVM(newSubmissionVMClone);
            if (
                (path === 'producerCode' || path === 'effectiveDate' || path === 'state')
                && !!(
                    _.get(newSubmissionVMClone, 'producerCode.value')
                    && _.get(newSubmissionVMClone, 'state.value.code')
                    && _.get(newSubmissionVMClone, 'effectiveDate.value')
                )
            ) {
                refreshProducts(newSubmissionVMClone);
                _.set(newSubmissionVMClone.value, 'productCode', '');
                _.set(newSubmissionVMClone.value, 'accountPublicId', accountId);
                updateSubmissionVM(newSubmissionVMClone);
            }

            if (path === 'productCode') {
                const foundValue = productValue.find((element) => element.code === value);
                if (!_.isUndefined(foundValue.virtualProductCode)) {
                    _.set(newSubmissionVMClone, 'virtualProductCode_PV', foundValue.virtualProductCode);
                    _.set(newSubmissionVMClone, 'virtualProductVersion_PV', foundValue.virtualProductVersion);
                    updateSubmissionVM(newSubmissionVMClone);
                }
            }
        },
        [productValue, refreshProducts, submissionVM, updateSubmissionVM, viewModelService]
    );
    const handleCancel = useCallback(() => {
        const accountNumber = _.get(submissionVM, 'accountNumber.value');
        showConfirm({
            title: messages.cancelQuote,
            message: messages.cancelMessage,
            status: 'warning',
            icon: 'mi-error-outline'
        }).then((results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            if (accountNumber) {
                history.push(`/accounts/${accountNumber}/summary`);
            } else {
                history.push('/');
            }
            return true;
        }, _.noop);
    }, [history, showConfirm, submissionVM]);
    const handleValidation = useCallback(() => {
        updateShowErrors(true);
    }, [updateShowErrors]);
    const producerdisable = producerCodeOrgValue.length > 0
        ? isAgent : producerCodeOrgValue.length === 0;
    const isAgentProducer = !isAgent ? producerdisable : !isAnExistingAccount;
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        submissionProducerCode: {
            availableValues:
                _.isArray(producerCodeValue) && producerCodeValue.length > 0
                    ? producerCodeValue
                    : producerCodeOrgValue,
            disabled: isAgentProducer
        },
        submissionState: {
            disabled: !isAnExistingAccount
        },
        submissionDate: {
            minDate: LocalDateUtil.today()
        },
        submissionProductCode: {
            availableValues: productValue,
            disabled: !_.get(submissionVM, 'producerCode.value')
        },
        submissionOrganisation: {
            visible: !isAgent,
            availableValues: allOrganisationValue,
            onValueChange: handleValueOrganisation,
            value: selectedOrganisationValue
        },
        cancel: {
            disabled: !isAnExistingAccount
        },
        next: {
            disabled: !isAnExistingAccount
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            startSubmission: isComponentValid ? startSubmission : handleValidation,
            onCancel: handleCancel
        }
    };
    const readValue = (id, path) => {
        return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            resolveValue={readValue}
            onValidationChange={onValidate}
            onValueChange={writeValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            showErrors={showErrors}
        />
    );
}
NewSubmissionPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    isAnExistingAccount: PropTypes.bool,
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({})
    }),
    allOrganisationValue: PropTypes.shape({}),
    postalCode: PropTypes.string,
    updateSubmissionVM: PropTypes.func,
    producerCodeValue: PropTypes.arrayOf(PropTypes.string),
    isAgent: PropTypes.bool,
    authHeader: PropTypes.shape({})
};

NewSubmissionPage.defaultProps = {
    isAnExistingAccount: false,
    submissionVM: {},
    allOrganisationValue: [],
    postalCode: '',
    updateSubmissionVM: undefined,
    producerCodeValue: [],
    isAgent: false,
    authHeader: {}
};
export default withRouter(NewSubmissionPage);
