import { defineMessages } from 'react-intl';

export default defineMessages({
    downloadTariff: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Download Tariff',
        defaultMessage: 'Download tariff'
    },
    downloadTariffAndBlocRetour: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Download Tariff Bloc Retour',
        defaultMessage: 'Download Tariff + Bloc Retour'
    },
    numberMissingTitle: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Enterprise number is missing',
        defaultMessage: 'Enterprise number is missing'
    },
    numberMissing: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Enterprise number is missing Message',
        defaultMessage: 'Triggering a bloc retour is not possible without an enterprise number. The enterprise number can be provided by clicking on Edit Contact Details in the account summary screen.'
    },
});
