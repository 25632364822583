import {MaintenanceService as MaintenanceServicePC} from "gw-capability-pcmaintenance";
import {MaintenanceService as MaintenanceServiceBC} from "gw-capability-bcmaintenance";
import {MaintenanceService as MaintenanceServiceCC} from "gw-capability-ccmaintenance";
import {MaintenanceService as MaintenanceServiceCM} from "gw-capability-cmmaintenance";

/**
 * A function to call the backend maintenance services to check if an application is in maintenance mode. If any of
 * the services return an error, the application is considered to be in maintenance mode. Only services that are enabled
 * in the capabilitiesConfig of the config.json5 will be called.
 *
 * @function checkMaintenanceMode
 * @memberof module:gw-maintenance-js
 *
 * @property {Object} authHeader - Authentication headers passed to the backend for authenticated apps.
 *
 * @returns {Promise} - A promise that resolves to true if the application is in maintenance mode, false otherwise.
 */
export const checkMaintenanceMode = (authHeader) => Promise.all([
        MaintenanceServicePC?.isInMaintenanceMode(authHeader),
        MaintenanceServiceBC?.isInMaintenanceMode(authHeader),
        MaintenanceServiceCC?.isInMaintenanceMode(authHeader),
        MaintenanceServiceCM?.isInMaintenanceMode(authHeader),
    ]).then((responses) => {
        // If any service returns true, show the maintenance page
        if(responses.some((response) => response === true)) {
            return Promise.resolve(true);
        }

        return Promise.resolve(false);
    }).catch(() =>
        // If any service call is rejected, show the maintenance page
         Promise.resolve(true)
    );