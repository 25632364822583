import React, { useState, useEffect, useCallback } from 'react';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import PropTypes from 'prop-types';
import metadata from './VehicleListPopup.metadata.json5';
import vehicleLookupMessages from '../VehicleLookup.messages';
import styles from './VehicleListPopup.scss';

import { Button } from '@jutro/legacy/components';

function VehicleListPopup({
    searchCriterias,
    vehiclesInfo,
    onChooseAnotherVehicle,
    onConfirmChoosenVehicleId
}) {

    const translator = useTranslator();

    const [tableData, setTableData] = useState([]);
    const [selection, setSelection] = useState(null);

    const titleMsg = translator(vehicleLookupMessages.selectYourvehicleMsg);
    const chooseAnotherMsg = translator(vehicleLookupMessages.chooseOtherVehicle);
    const confirmVehicleMsg = translator(vehicleLookupMessages.confirm);

    useEffect(() => {
        /* Informex result looks like:
        [
            123: {vehicleInfoJson},
            113: {vehicleInfoJson},
            333: {vehicleInfoJson},
            023: {vehicleInfoJson}
        ]
        need simpliers format:
        [
            {...vehicleInfo, vehicleId: 123},
            {...vehicleInfo, vehicleId: 113},
            {...vehicleInfo, vehicleId: 333},
            {...vehicleInfo, vehicleId: 023},
        ]
        */
        let res = [];
        for (const vehicleKey of Object.keys(vehiclesInfo)) {
            const item = JSON.parse(vehiclesInfo[vehicleKey]);
            res.push({
                ...item,
                vehicleId: vehicleKey,
                minimumCatalogPrice: parseFloat(item.minimumCatalogPrice) > 0 ? `${item.minimumCatalogPrice} EUR` : null
            });
        }
        res = _.orderBy(res, ['production_start'], ['desc']);
        setTableData(res);
    }, [vehiclesInfo]);

    const overrideProps = {
        searchCriteriaList: {
            data: searchCriterias,
        },
        datatableVehicle: {
            data: tableData
        }
    };
    
    const findRadioItem = useCallback((node) => {
        const radioItemSelector = '[data-col="selection"] [role="radio"]';
        return node.querySelector(radioItemSelector) || findRadioItem(node.parentElement);
    }, []);

    // PV-20806: DataTable#selectOnRowClick attribute is not working
    // Therefore here is a custom implementation:
    // On row click the radio-item gets selected
    const onRowClick = useCallback((selectedObj, index, event) => {
        findRadioItem(event.target).click();
        setSelection(selectedObj);
    }, [findRadioItem]);

    const onFilter = (filterValue) => (row) => {
        const words = filterValue.trim().toLowerCase().split(' ').filter(Boolean);

        if (!words.length) {
            return true;
        }

        return words.every((word) => (
            row.production_start.toLowerCase().includes(word)
            || row.variant.toLowerCase().includes(word)
            || row.submodel.toLowerCase().includes(word)
            || row.minimumCatalogPrice?.toLowerCase().includes(word)
        ));
    };

    const callbackMap = {
        onRowClick,
        onFilter,
    };

    return (
        <ModalNext isOpen onRequestClose={onChooseAnotherVehicle}>
            <ModalHeader title={titleMsg} onRequestClose={onChooseAnotherVehicle}/>
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                    callbackMap={callbackMap}
                    classNameMap={styles}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onChooseAnotherVehicle} type="outlined">
                    {chooseAnotherMsg}
                </Button>
                <Button disabled={!selection} onClick={() => onConfirmChoosenVehicleId(selection.vehicleId)}>
                    {confirmVehicleMsg}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

VehicleListPopup.propTypes = {
    searchDetails: PropTypes.shape({}),
    vmPath: PropTypes.string,
    getVehicleLookupData: PropTypes.func
};

VehicleListPopup.defaultProps = {};

export default VehicleListPopup;
