import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { UserService } from 'gw-capability-gateway';
import SelectProducerCodeMessages from './SelectProducerCode.messages';

export const ProducerContext = React.createContext({
    producerCode: 'all',
    setProducerCode: () => {}
});
class SelectProducerCodeContextProvider extends Component {
    static propTypes = {
        children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        authHeader: PropTypes.shape({}).isRequired
    };

    state = {
        producerCodeList: [],
        unSortedProducerCodeList: [],
        producerCode: 'all',
        setProducerCode: (producerCode) => {
            this.setState({ producerCode });
        }
    };

    componentDidMount() {
        this.getProducerCode();
    }

    getProducerCode = async () => {
        const { authHeader } = this.props;
        const workStartedByMe = {
            code: 'myWork',
            name: SelectProducerCodeMessages.workStartedByMe
        };
        const everything = {
            code: 'all',
            name: SelectProducerCodeMessages.everything
        };
        const availableProducerCode = await UserService.getAvailableProducerCodesForCurrentUser(
            authHeader
        );
        const codeList = _.sortBy(
            availableProducerCode.map((codes) => {
                const availableProducerCodesforUser = {
                    code: codes.code,
                    name: codes.displayValue
                };
                return availableProducerCodesforUser;
            }),
            (o) => o.code
        );
        const producerCodeList = [workStartedByMe, everything, ...codeList];
        this.setState({ producerCodeList, unSortedProducerCodeList: availableProducerCode });
    };

    render() {
        const { children } = this.props;
        const {
            unSortedProducerCodeList,
            producerCodeList,
            producerCode,
            setProducerCode
        } = this.state;
        return (
            <ProducerContext.Provider
                value={{
                    unSortedProducerCodeList,
                    producerCodeList,
                    producerCode,
                    setProducerCode
                }}
            >
                {children}
            </ProducerContext.Provider>
        );
    }
}

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
export function withProducerContext(WrappedComponent) {
    function WithProducerContext(props) {
        const producerProps = useContext(ProducerContext);
        return <WrappedComponent {...producerProps} {...props} />;
    }
    hoistNonReactStatic(WithProducerContext, WrappedComponent);
    WithProducerContext.displayName = `WithProducerContext(${getDisplayName(WrappedComponent)})`;
    return WithProducerContext;
}
export const SelectProducerContextProvider = withAuthenticationContext(
    SelectProducerCodeContextProvider
);
export default ProducerContext.Consumer;
