import { defineMessages } from 'react-intl';

export default defineMessages({
    garageOrCarport: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Is the vehicle parked in a garage or carport?',
        defaultMessage: 'Is the vehicle parked in a garage or carport?'
    },
    claimsMaterialDamage: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.Claims Material Damage',
        defaultMessage: 'Number of claims for material damage in the past 5 years'
    },
    catalogueValue: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Catalogue value',
        defaultMessage: 'Catalogue value (without option or accessoire) (exclusive of VAT)'
    },
    catalogueValueTooltip: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Catalogue value.tooltip',
        defaultMessage: 'The catalog value is the price of your new vehicle (without VAT and without reduction), without any option or accessory. You can find this data on the original purchase invoice of the vehicle.'
    },
    optionsValueToltip: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Options value.tooltip',
        defaultMessage: 'This information is available on the purchase order or on the invoice. The options are installed only at the factory or at montage. For example: "leather seats; sliding roof; safety pack; Ambitronix air conditioner".'
    },
    accessoriesValueToltip: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Accessories Value.tooltip',
        defaultMessage: 'The accessories are installed on assambly or after the assembly. For example: "luxure wheels, specific muffler".'
    },
    invoiceValueTooltip: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Invoice Value.tooltip',
        defaultMessage: 'The invoice value = the value to be indicated is the price that, according to the purchase invoice of the vehicle, was charged for the purchase of the specified vehicle, not the standard equipment, excluding taxes and VAT.'
    },
    invoiceDate: {
        id: "quoteandbind.quickquote.common.directives.qq-editable-coverages.Invoice Date",
        defaultMessage: "Invoice date"
    },
    expertValueTooltip: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Expert Value.tooltip',
        defaultMessage: 'The expert value = the value to be indicated is the estimation of the vehicle according to the expert who checked the vehicle including the options and accessories.'
    },
    carDealerValueTooltip: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Car dealer value.tooltip',
        defaultMessage: 'Value the garage has actually paid for / what it has entered in its accounts when the vehicle is first put into circulation (= investment value = catalog value + options - discount)'
    },
});
