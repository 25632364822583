import _ from 'lodash';
import { useCallback } from 'react';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import BusinessConstant from 'pv-portals-util-js/BusinessConstant';
import VehicleUtil from 'pv-portals-util-js/VehicleUtil';
import messages from './VehiclesPage.messages';
import messagesPolicyJob from '../../policyjob.messages';

const VehiclePageUtil = () => {

    const translator = useTranslator();

    const { showAlert } = useModal();

    const ensureVehicleCategoryTB = useCallback((vehicles) => {
        const numVehicles = vehicles.length;
        const numVehiclesTB = vehicles.filter((veh) => veh.category === BusinessConstant.VEHICLE_CATEGORY_TOURISM_AND_BUSINESS);

        // PV If there at least 2 vehicles and any Tourism and Business, show error message
        if (numVehicles > 1 && numVehiclesTB.length === 0) {
            showAlert({
                title: messages.atLeast1TBErrorMsg,
                message: messages.editQuoteMsg,
                status: 'error',
                icon: 'mi-error-outline'
            });

            return false;
        }
        return true;
    }, [showAlert]);

    const ensureAtLeast1SecuritySystemSelected = useCallback((vehicle) => {
        if (vehicle.isSecuritySystemInstalled) {
            if (!(vehicle.automaticEmergencyBraking || vehicle.laneDepartureWarningSystem
                || vehicle.driverDrowsinessDetection || vehicle.adaptiveCruiseControl
                || vehicle.blindSpotSystem || vehicle.cyclAndPedestrDetectSystem)) {
                return false;
            }
        }
        return true;
    }, []);

    const retrieveVehiclesWithDuplicateLicensePlates = useCallback((vehicles) => {
        const vehiclesWithDuplicateLicensePlates = [];
        let duplicateFound = false;
        for (let i = 0; i < vehicles.length && !duplicateFound; i++) {
            for (let j = 0; j < vehicles.length; j++) {
                if (i !== j) {
                    if (!!vehicles[i].license && vehicles[i].license === vehicles[j].license) {
                        vehiclesWithDuplicateLicensePlates.push(vehicles[i]);
                        vehiclesWithDuplicateLicensePlates.push(vehicles[j]);
                        duplicateFound = true;
                        break;
                    }
                }
            }
        }
        return vehiclesWithDuplicateLicensePlates;
    }, []);

    const retrieveVehiclesWithDuplicateVins = useCallback((vehicles) => {
        const vehiclesWithDuplicateVins = [];
        let duplicateFound = false;
        for (let i = 0; i < vehicles.length && !duplicateFound; i++) {
            for (let j = 0; j < vehicles.length; j++) {
                if (i !== j) {
                    if (!!vehicles[i].vin && vehicles[i].vin === vehicles[j].vin) {
                        vehiclesWithDuplicateVins.push(vehicles[i]);
                        vehiclesWithDuplicateVins.push(vehicles[j]);
                        duplicateFound = true;
                        break;
                    }
                }
            }
        }
        return vehiclesWithDuplicateVins;
    }, []);

    const ensureVehicleAddedAfterEdit = useCallback((vehicles) => {
        // NOTE: a bit cryptic function
        const incompleteVehicles = vehicles.filter((veh) => (_.isNil(veh.vin) || _.isNil(veh.primaryUse)) && _.isNil(veh.make));
        return incompleteVehicles.length === 0;
    }, []);

    const ensureVehicleTypeAndCategoryAdded = useCallback((vehicle) => {
        return vehicle && vehicle.type && vehicle.category;
    }, []);

    const isValid = useCallback((vehicleVm, vehicles) => {

        // 1. type & category selected
        if (!ensureVehicleTypeAndCategoryAdded(vehicleVm.value)) {
            showAlert({
                title: messages.addOrRemoveVehicle,
                message: messages.oneVehicleIncomplete,
                status: 'error',
                icon: 'mi-error-outline'
            });
            return false;
        }

        // 2. all other DTO errors
        if (!vehicleVm.aspects.subtreeValid) {
            return false;
        }

        // 3. complex cross vehicles validations ...
        const vehiclesWithDuplicateLicensePlates = retrieveVehiclesWithDuplicateLicensePlates(vehicles);
        const vehiclesWithDuplicateVins = retrieveVehiclesWithDuplicateVins(vehicles);

        // 4. complex current vehicle checks
        const securitySystemSelected = ensureAtLeast1SecuritySystemSelected(vehicleVm.value);
        const vehicleAddedAfterEdit = ensureVehicleAddedAfterEdit(vehicles);


        if (vehiclesWithDuplicateLicensePlates.length >= 2) {
            const licenceMatchedMsg = translator(messages.licenceMatchedMsg, {
                firstVehicle: VehicleUtil.getShortDisplayName(vehiclesWithDuplicateLicensePlates[0], translator),
                secondVehicle: VehicleUtil.getShortDisplayName(vehiclesWithDuplicateLicensePlates[1], translator)
            });
            showAlert({
                title: messages.licenseNotUnique,
                message: licenceMatchedMsg,
                status: 'error',
                icon: 'mi-error-outline'
            });
            return false;
        }

        if (vehiclesWithDuplicateVins.length >= 2) {
            const vinMatchedMsg = translator(messagesPolicyJob.vinMatchedMsg, {
                firstVehicle: VehicleUtil.getShortDisplayName(vehiclesWithDuplicateVins[0], translator),
                secondVehicle: VehicleUtil.getShortDisplayName(vehiclesWithDuplicateVins[1], translator)
            });
            showAlert({
                title: messagesPolicyJob.vinNotUnique,
                message: vinMatchedMsg,
                status: 'error',
                icon: 'mi-error-outline'
            });
            return false;
        }

        if (!securitySystemSelected) {
            showAlert({
                title: messages.atLeast1SecuritySelected,
                message: messages.editQuoteMsg,
                status: 'error',
                icon: 'mi-error-outline'
            });
            return false;
        }

        if (!vehicleAddedAfterEdit) {
            showAlert({
                title: messages.addOrRemoveVehicle,
                message: messages.oneVehicleIncomplete,
                status: 'error',
                icon: 'mi-error-outline'
            });
            return false;
        }

        return true;
    }, [ensureVehicleTypeAndCategoryAdded, retrieveVehiclesWithDuplicateVins, retrieveVehiclesWithDuplicateLicensePlates, ensureVehicleAddedAfterEdit, ensureAtLeast1SecuritySystemSelected, translator, showAlert]);

    return {
        ensureVehicleCategoryTB,
        isValid
    };
};

export default VehiclePageUtil;
