import React, { useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import metadata from './VehicleRejectionPopup.metadata.json5';
import messages from './VehicleRejectionPopup.messages';
import vehicleLookupMessages from '../VehicleLookup.messages';

import { Button } from '@jutro/legacy/components';

function VehicleRejectionPopup({
    onChooseAnotherVehicle,
    isPersonalLine
}) {

    const translator = useContext(TranslatorContext);

    const titleMsg = translator(messages.vehicleDetails);
    const chooseAnotherMsg = translator(vehicleLookupMessages.chooseOtherVehicle);

    const overrideProps = {
        vehicleRejectionContainerTitlePPV : {
            visible : isPersonalLine
        },
        vehicleRejectionContainerTitlePCV : {
            visible : !isPersonalLine
        }
    };

    return (
        <ModalNext isOpen>
            <ModalHeader title={titleMsg} className="wizardPageHeader" onRequestClose={onChooseAnotherVehicle}/>
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onChooseAnotherVehicle} type="outlined">
                    {chooseAnotherMsg}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

export default VehicleRejectionPopup;
