import React, { useMemo, useContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LocalDateUtil } from '@xengage/gw-portals-util-js';
import { VehicleUtil, BusinessConstant as BC } from 'pv-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { PolicyChangesContext } from 'pv-capability-policychange-common-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PVInsurersListService } from 'pv-capability-policyjob';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './VehicleDetails.metadata.json5';
import messages from './VehicleDetails.messages';
import styles from "./VehicleDetails.module.scss";

// ID for dynamically generated "Other"-<div> inside 'Primary Use' section
const PRIMARY_USE_OTHER_DIV_ID = 'primary-use-other-div';

const SECURITY_SYSTEMS = [
    'automaticEmergencyBraking',
    'laneDepartureWarningSystem',
    'driverDrowsinessDetection',
    'adaptiveCruiseControl',
    'blindSpotSystem',
    'cyclAndPedestrDetectSystem'
];

const ALL_VEHICLE_SECTIONS_OPEN = [
    'vehicleInfoContainer',
    'vehicleDetailsContainer',
    'vehicleUsageContainer',
    'claimSectionContainer'
];

const FIELDS_COVERT_UPPERCASE = ['license', 'vin'];

function VehicleDetails(props) {
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);

    const {
        data: vehicleVM,
        onValueChange,
        path,
        labelPosition,
        phoneWide,
        showOptional,
        showErrors,
        showRequired,
        onValidate,
        id,
        productCode,
        vpVersionChecker,
        quickQuoteMode,
        jobType,
        onChangeDesignatedDriver,
        index,
        brand
    } = props;

    const [insurers, setInsurers] = useState([]);
    const { authHeader } = useAuthentication();
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const [accordionStates, setAccordionStates] = useState([]);
    const [enabledAccordionCollapse, setEnabledAccordionCollapse] = useState(false);
    const [isPolicyChange] = useState(jobType === BC.JOB_TYPE_POLICY_CHANGE);
    const [showOtherVehicleTypes, setShowOtherVehicleTypes] = useState(false);
    const changesContext = useContext(PolicyChangesContext);

    const today = LocalDateUtil.today();
    const [vehicleNotCirculation, setVehicleNotCirculation] = useState(_.isEqual(vehicleVM.firstRegistrationDate_PV.value, today));

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, vehicleVM]);

    useEffect(() => {
        async function fetchData() {
            const list = await PVInsurersListService.getInsurersLists(authHeader);
            setInsurers(list.map((item) => {
                return {
                    name: item.currentInsurerName_PV,
                    code: item.publicID_PV
                };
            }));
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // accordion is collapsible only for tourism and business vehicles
        setEnabledAccordionCollapse(VehicleUtil.isTourismAndBusiness(vehicleVM.value.category));

        // auto-open cases
        if (!VehicleUtil.isTourismAndBusiness(vehicleVM.value.category) || !_.isEmpty(vehicleVM.value.make) || !_.isEmpty(vehicleVM.value.model)) {
            setAccordionStates(ALL_VEHICLE_SECTIONS_OPEN);
        }
    }, [vehicleVM.value.make, vehicleVM.value.model, vehicleVM.value.category]);

    const isOtherVehicleType = useCallback((codeString) => {
        return [
            'Ambulance_PV',
            'Taxi_PV',
            'TransportOfPeople_PV',
            'CeremonyVehicle_PV',
            'ExpressDelivery_PV',
            'Hearse_PV',
            'TransportAccOfOthers_PV',
            'TowingVehicle_PV'
        ].includes(codeString);
    }, []);

    useEffect(() => {
        if (isOtherVehicleType(vehicleVM.primaryUse.value?.code)) {
            setShowOtherVehicleTypes(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateRadioItem = useCallback((item) => {
        return ({
            name: translator({
                id: item.name,
                defaultMessage: item.name
            }),
            code: item.code
        });
    }, [translator]);

    const generatePrimaryUseOtherDiv = useCallback(() => {
        // generates read-only option. currently not supported in Jutro
        if (!document.getElementById(PRIMARY_USE_OTHER_DIV_ID)) {
            const otherDiv = document.createElement('div');
            otherDiv.id = PRIMARY_USE_OTHER_DIV_ID;
            otherDiv.classList.add('jut__RadioButton__radioButton');
            otherDiv.innerText = translator({
                id: messages.primaryUseOther,
                defaultMessage: 'Other'
            });
            document.querySelector('#primaryUse').insertAdjacentElement('beforeend', otherDiv);
        }
    }, [translator]);

    const deletePrimaryUseOtherDiv = useCallback(() => {
        document.getElementById(PRIMARY_USE_OTHER_DIV_ID)?.remove();
    }, []);

    const filteredPrimaryUseValues = useCallback(() => {
        const lob = productCode === BC.PPV_PROD_CODE ? BC.LINE_OF_BUSINESS_CODE_PPVLINE_PV : BC.LINE_OF_BUSINESS_CODE_PCVLINE_PV;
        const primaryUseAvailableValues = VehicleUtil.getAvailablePrimaryUseValues(viewModelService, vehicleVM, lob, vpVersionChecker);
        let primaryUseMain = [];
        const primaryUseOther = [];
        if (productCode === BC.PPV_PROD_CODE) {
            primaryUseMain = _.sortBy(primaryUseAvailableValues.map((key) => generateRadioItem(key)));
        } else {
            primaryUseAvailableValues.forEach((use) =>  {
                isOtherVehicleType(use.code) ? primaryUseOther.push(generateRadioItem(use)) : primaryUseMain.push(generateRadioItem(use)) ;
            });
            if (primaryUseMain.concat(primaryUseOther).length === 1) {
                // Only 1 Primary Use value available, skip 'other' logic
                return primaryUseMain.concat(primaryUseOther);
            }

            // 'other' logic: Hide OtherVehicleType's behind a synthetic 'Other'-div
            if (primaryUseOther.includes(vehicleVM.primaryUse.value)) {
                setShowOtherVehicleTypes(true);
            }
            if (showOtherVehicleTypes) {
                generatePrimaryUseOtherDiv();
            }
            if (showOtherVehicleTypes) {
                primaryUseMain = primaryUseMain.concat(primaryUseOther);
            } else if (primaryUseOther.length > 0) {
                primaryUseMain.push({
                    name: messages.primaryUseOther,
                    code: 'other'
                });
            }
        }

        return primaryUseMain;
    }, [productCode, viewModelService, vehicleVM, generateRadioItem, showOtherVehicleTypes, isOtherVehicleType, generatePrimaryUseOtherDiv, vpVersionChecker]);

    const getAvailableFuelType = useMemo(() => {
        const lossDataArray = _.sortBy(vehicleVM.fuelType.aspects.availableValues.map((key) => {
            return {
                name: translator({
                    id: key.name,
                    defaultMessage: key.name
                }),
                code: key.code
            };
        }));
        return lossDataArray;
    }, [translator, vehicleVM.fuelType.aspects.availableValues]);

    const getCheckBoxValue = useCallback(() => {
        return SECURITY_SYSTEMS.filter((value) => _.get(vehicleVM, `value.${value}`));
    }, [vehicleVM]);

    const getAvailableAnnualKilometer = useMemo(() => {
        const lossDataArray = _.sortBy(vehicleVM.annualKilometer.aspects.availableValues.map((key) => {
            return {
                name: translator({
                    id: key.name,
                    defaultMessage: key.name
                }),
                code: key.code
            };
        }));
        return lossDataArray;
    }, [translator, vehicleVM.annualKilometer.aspects.availableValues]);

    const handleValueChange = useCallback((value, changedPath) => {
        let formattedValue = value;
        if (changedPath === "license" && value) {
            formattedValue = value.replace(/[^A-Za-z0-9]+/gi, '');
        }

        if (FIELDS_COVERT_UPPERCASE.includes(changedPath)) {
            formattedValue = formattedValue?.toUpperCase();
        }
        const fullPath = `${path}.${changedPath}`;
        if (onValueChange) {
            onValueChange(formattedValue, fullPath);
        }
    }, [onValueChange, path]);

    const onChangeFirstRegDate = useCallback((value) => {
        // catch the moment when firstRegistrationDate_PV is reset and remove flag setVehicleNotCirculation too
        if (_.isEmpty(value)) {
            setVehicleNotCirculation(false);
        }

        _.set(vehicleVM, `value.firstRegistrationDate_PV`, value);
        if (_.isEqual(vehicleVM.firstRegistrationDate_PV.value, LocalDateUtil.today())) {
            // when first reg date = today, then first owner = true
            _.set(vehicleVM, `value.firstOwner`, true);
        }

        onValueChange(vehicleVM, path);
    }, [onValueChange, path, vehicleVM]);

    const onChangeVehicleNotCirculation = useCallback((isVehicleNotCirculation) => {
        setVehicleNotCirculation(isVehicleNotCirculation);
        if (isVehicleNotCirculation) {
            if (vpVersionChecker.isEqualOrAfterVP6()) {
                // auto-set firstOwner=true, when vehicle is not yet even in circulation
                _.set(vehicleVM, `firstOwner`, true);
            }
            handleValueChange(LocalDateUtil.today(), 'firstRegistrationDate_PV');
        } else {
            handleValueChange(undefined, 'firstRegistrationDate_PV');
        }
    }, [handleValueChange, vpVersionChecker, vehicleVM]);

    const onChangeIsSecuritySystemInstalled = useCallback((value, changedPath) => {
        if (!value) {
            SECURITY_SYSTEMS.forEach((system) => {
                _.set(vehicleVM, `value.${system}`, undefined);
            });
        }
        handleValueChange(value, changedPath);
    }, [handleValueChange, vehicleVM]);

    const onChangeSecuritySystems = useCallback((options) => {
        if (options) {
            SECURITY_SYSTEMS.forEach((system) => {
                _.set(vehicleVM, `value.${system}`, options.includes(system));
            });
            onValueChange(vehicleVM, path);
        }
    }, [onValueChange, path, vehicleVM]);

    const onChangePrimaryUse = (value) => {
        if (value === 'other') {
            setShowOtherVehicleTypes(true);
            onValueChange(undefined, `${path}.primaryUse`);
        } else {
            if (!isOtherVehicleType(value)) {
                deletePrimaryUseOtherDiv();
                setShowOtherVehicleTypes(false);
            }
            if (vehicleVM.dangerousGoods_PV && !(value === BC.PRIMARY_USE_TRANSPORT_ACCOUNT_OF_OTHERS || value === BC.PRIMARY_USE_TRANSPORT_OWN_ACCOUNT)){
                vehicleVM.dangerousGoods_PV.value = undefined;
            }
            if (vehicleVM.typeOfPreparation_PV && !(value === BC.PRIMARY_USE_FOODTRUCK || value === BC.PRIMARY_USE_MARKET_VEHICLE)){
                vehicleVM.typeOfPreparation_PV.value = undefined;
            }
            onValueChange(value, `${path}.primaryUse`);
        }
    };

    const onChangeClaimsTPL5YearsFault = useCallback((value) => {
        if (value === '0') {
            // eslint-disable-next-line camelcase
            vehicleVM.dateLastClaim_PV = undefined;
            // eslint-disable-next-line camelcase
            vehicleVM.noOfYearsSinceLastClaim_PV = "None";
        }
        onValueChange(value, `${path}.claimsTPL5YearsFault_PV`);
    }, [onValueChange, path, vehicleVM]);

    const onChangeNumberOfYearsSinceLastClaim = useCallback((value, changedPath) => {
        vehicleVM.noOfYearsValChanged_PV.value = true;
        onValueChange(value, `${path}.${changedPath}`);
    }, [onValueChange, path, vehicleVM]);

    const numberOfYearsSinceLastClaimValuesWithoutNone = useMemo(() => {
        // NOTE: backedn allows values: None, 0, 1, 2, 3, 4
        // due to widget change, None is not needed anymore on frontend
        const typelistDef = viewModelService.productMetadata.get('pc').types.getTypelist('NoOfYrsSinceLastClaim_PV');
        const filteredValues = typelistDef.codes.filter((typecode) => typecode.code !== 'None');
        const translatedValues = filteredValues.map((item) => {
            return {
                id: item.code,
                displayName: translator({ id: item.name, defaultMessage: item.code })
            };
        });
        return translatedValues;
    }, [viewModelService, translator]);

    const onUpdateAccordion = useCallback((newAccordionStates) => {
        setAccordionStates((oldAccordionStates) => {
            if (!oldAccordionStates.includes('vehicleInfoContainer') && newAccordionStates.includes('vehicleInfoContainer')) {
                // when opening 1st accordion, all opens ...
                return ALL_VEHICLE_SECTIONS_OPEN;
            }
            return newAccordionStates;
        });
    }, []);

    const overrideProps = {
        '@field': {
            labelPosition,
            phoneWide,
            showOptional,
            showRequired
        },
        accordion: {
            accordionStates: accordionStates,
            onUpdateAccordionStates: onUpdateAccordion
        },

        // VEHICLE INFO
        vehicleInfoContainer: {
            isCollapsible: enabledAccordionCollapse
        },
        vehicleTitle: {
            content: VehicleUtil.getFullDisplayName(vehicleVM, translator)
        },
        vin: {
            disabled: isPolicyChange && vehicleVM.value.existingVehicle_PV &&
                changesContext.isVinAndLicenseplateQuickChangeAllowed
        },
        fuelType: {
            availableValues: getAvailableFuelType,
        },

        // VEHICLE DETAILS
        vehicleDetailsContainer: {
            isCollapsible: enabledAccordionCollapse
        },
        firstRegistrationDate_PV: {
            disabled: vehicleNotCirculation // NOTE: vehicleNotCirculation is NON DTO field
        },
        vehicleNotCirculation: {
            showOptional: false,
            value: vehicleNotCirculation // NOTE: vehicleNotCirculation is NON DTO field
        },
        securitySystems: {
            value: getCheckBoxValue()
        },

        // VEHICLE USAGE
        vehicleUsageContainer: {
            isCollapsible: enabledAccordionCollapse
        },
        primaryUse: {
            value: _.get(vehicleVM.value, 'primaryUse'),
            availableValues: filteredPrimaryUseValues(),
        },
        annualKilometer: {
            value: _.get(vehicleVM.value, 'annualKilometer'),
            availableValues: getAvailableAnnualKilometer
        },
        typeOfPreparation_PV: {
            value: vehicleVM.typeOfPreparation_PV?.value?.code,
        },
        cancenCurrentInsurerDetails: {
            vehiclePath: path,
            insurers: insurers,
            onValidate: setComponentValidation,
            vehicleVM,
        },
        financialPlan: {
            showPlanSelection: true,
            vehiclePath: path,
            vehicleVM,
            captureDetails: isPolicyChange,
            onValidate: setComponentValidation,
            brand
        },
        designatedDriver_PV: {
            readOnly: index !== 0,
            secondaryLabel: index !== 0 ? messages.designatedDriverInfoText : null
        },

        // CLAIM DETAILS
        claimSectionContainer: {
            isCollapsible: enabledAccordionCollapse
        },
        riskRefusedDetails: {
            riskObjectVM: vehicleVM,
            riskObjectPath: path,
            onValidate: setComponentValidation,
            insurers
        },

        // claims section, number of claims subsection - same for all categories
        claimsTPL5YearsFault_PV: {
            value: vehicleVM.value.claimsTPL5YearsFault_PV,
        },
        noOfYearsSinceLastClaim_PV: {
            value: vehicleVM.value.noOfYearsSinceLastClaim_PV,
            availableValues: numberOfYearsSinceLastClaimValuesWithoutNone
        },
        claimsTPL5YearsNFault_PV: {
            value: vehicleVM.value.claimsTPL5YearsNFault_PV,
        },
        claimsTheft5YearsFault_PV: {
            value: vehicleVM.value.claimsTheft5YearsFault_PV,
        },
        claimsMD5YearsFault_PV: {
            value: vehicleVM.value.claimsMD5YearsFault_PV,
        }
    };

    // need deep merge, not shallow (operator ... doesn't work)
    _.merge(overrideProps, VehicleUtil.visibleFields(vehicleVM, quickQuoteMode, jobType, productCode, vpVersionChecker));

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onChangeVehicleNotCirculation,
            onChangeIsSecuritySystemInstalled,
            onChangeSecuritySystems,
            onChangePrimaryUse,
            onChangeClaimsTPL5YearsFault,
            onChangeDesignatedDriver,
            onChangeNumberOfYearsSinceLastClaim,
            onChangeFirstRegDate
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={vehicleVM}
            showErrors={showErrors}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

VehicleDetails.propTypes = {
    data: PropTypes.shape({}).isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string,
    onValidate: PropTypes.func.isRequired,
    labelPosition: PropTypes.string,
    phoneWide: PropTypes.shape({}),
    showOptional: PropTypes.bool,
    showRequired: PropTypes.bool,
    showErrors: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string.isRequired,
    productCode: PropTypes.string,
    quickQuoteMode: PropTypes.bool,
    virtualProduct: PropTypes.string,
    virtualProductVersion: PropTypes.number
};

VehicleDetails.defaultProps = {
    data: {},
    labelPosition: 'left',
    phoneWide: {
        labelPosition: 'top'
    },
    path: undefined,
    showOptional: true,
    showRequired: true,
    index: 0
};

export default VehicleDetails;
