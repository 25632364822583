import _ from 'lodash';

const getVersionChecker = (jobVm) => {
    const vpCode = _.get(jobVm, 'baseData.virtualProductCode_PV.value');
    const vpVersion = _.get(jobVm, 'baseData.virtualProductVersion_PV.value');
    return {

        // getVPCode: () => should not be even needed

        // getVPVersion: () => should not be even needed

        isEqualOrAfterVP6: () => {
            // vp6 is a symbolic name for a timeline/checkpoint, in the future it will include new products with different vp versions
            return (vpCode === 'ppv_broker_pv' && vpVersion >= 6
                || vpCode === 'pcv_broker_pv' && vpVersion >= 6
                || vpCode === 'ppv_agent_pv' && vpVersion >= 6
                || vpCode === 'pcv_agent_pv' && vpVersion >= 6
                // coming soon - 'enterprise' && vpVersion >= 1
            );
        },

        isEqualOrAfterVP7: () => {
            // vp7 is a symbolic name for a timeline/checkpoint, in the future it will include new products with different vp versions
            return (vpCode === 'ppv_broker_pv' && vpVersion >= 7
                || vpCode === 'pcv_broker_pv' && vpVersion >= 7
                || vpCode === 'ppv_agent_pv' && vpVersion >= 7
                || vpCode === 'pcv_agent_pv' && vpVersion >= 7
                // coming soon - 'enterprise' && vpVersion >= 1
            );
        },

        // isAfterVP123: () =>
    };
};

export default {
    getVersionChecker
    // ... other fn ...
};
