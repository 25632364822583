import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

import { TranslatorContext } from '@jutro/locale';
import { Avatar } from '@jutro/components';
import { ServiceManager } from '@jutro/legacy/services';

import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PhoneUtil } from '@xengage/gw-portals-viewmodel-js';

import { ContactService } from 'gw-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';

import metadata from './PolicyContactsPage.metadata.json5';
import tabStyles from '../Contacts.module.scss';
import messages from '../Contacts.messages';
import gatewayMessages from '../../gateway.messages';

const PRIMARY_PHONE_TYPE_MAP = {
    home: 'homeNumber',
    mobile: 'cellNumber',
    work: 'workNumber'
};

const roleAbstractEntities = [
    'APDPolicyInvolvedParty',
    'PolicyContactRole',
    'BAPolicyContactRole',
    'BOPPolicyContactRole',
    'CPPolicyContactRole',
    'GLPolicyContactRole',
    'PAPolicyContactRole',
    'IMPolicyContactRole',
    'PolicyNamedInsured',
    'WCPolicyContactRole',
    'WCLaborContact',
    'PlcyNonPriNamedInsured',
];

class PolicyContactsPage extends Component {
    static contextType = TranslatorContext;

    state = {
        policyContactRoleVM: {},
        policyContactRole: 'allRoles',
        policyContactRoleOptions: [],
        policyContactTableData: {}
    };

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        policyNumber: PropTypes.string.isRequired,
        authHeader: PropTypes.shape({}).isRequired
    };

    writeValue = (value) => {
        this.setState({
            policyContactRole: value
        });
    };

    createVM = () => {
        const { viewModelService } = this.props;
        return viewModelService.create(
            {},
            'pc',
            'edge.capabilities.gateway.contact.dto.roles.policy.PolicyContactRoleDTO'
        );
    };

    componentDidMount = () => {
        const policyContactRoleVM = this.createVM();

        this.setState({ policyContactRoleVM }, () => {
            const policyContactRoleOptions = this.getPolicyContactRoles();
            this.setState({ policyContactRoleOptions });
        });
    };

    getPolicyContactSummaries = (paginationParams) => {
        const { policyContactRole } = this.state;
        const { policyNumber, authHeader } = this.props;
        const role = policyContactRole !== 'allRoles' ? [policyContactRole] : [];

        return ContactService.getPolicyContactSummaries(
            policyNumber,
            authHeader,
            role,
            paginationParams.fromPage,
            paginationParams.pageSize
        ).then((response) => {
            this.setState({
                policyContactTableData: response
            });
            const data = this.getResponseData(response.policyContacts);
            return {
                rows: data,
                numberOfRows: response.maxNumberOfResults
            };
        });

    };

    getResponseData = (policyContacts) => {
        if (policyContacts) {
            const contactsInfo = policyContacts.map((contact) => {
                return {
                    contact: contact.displayName,
                    role: contact.policyContactRoles,
                    involvedWith: contact.associatedPoliciesCount,
                    active: contact.active
                };
            });
            return contactsInfo;
        }
        return [];
    };

    getPolicyContactRoles = () => {
        const { policyContactRoleVM } = this.state;
        const translator = this.context;
        const allRolesOption = {
            code: 'allRoles',
            name: messages.allRoles
        };

        const contactRoles = _.get(policyContactRoleVM, 'subtype.aspects.availableValues', []);

        const roleOptions = contactRoles
            .filter((option) => {
                return !roleAbstractEntities.includes(option.code);
            })
            .map((role) => {
                return {
                    code: role.code,
                    name: _.isUndefined(messages[_.lowerFirst(role.code)]) ? role.code : translator(messages[_.lowerFirst(role.code)])
                };
            });

        roleOptions.unshift(allRolesOption);
        return roleOptions;
    };

    redirectToContactDetails = (index) => {
        const { policyNumber, history } = this.props;
        const { policyContactTableData } = this.state;
        const { policyContacts } = policyContactTableData;

        history.push({
            pathname: `/policies/${policyNumber}/contactDetails`,
            state: {
                contact: 'policyContacts',
                accountOrPolicyNumber: policyNumber,
                relatedTo: 'policies',
                contactId: policyContacts[index].accountContactPublicID
            }
        });
    };

    handlePolicyContactSelect = (selectedOption) => {
        this.setState({
            policyContactRole: selectedOption
        });
        this.loadData(0, this.pageSize);
    };

    renderContact = (item, index, { path: property }) => {
        const { policyContactTableData } = this.state;
        if (index < policyContactTableData.policyContacts.length) {
            const contact = policyContactTableData.policyContacts[index];

            if (property === 'phone') {

                if(!contact.primaryPhoneType) {
                    return null;
                }
                const phoneNumber = contact[PRIMARY_PHONE_TYPE_MAP[contact.primaryPhoneType]];
                const localeService = ServiceManager.getService('locale-service');
                const phoneUtil = PhoneUtil();
                const primaryPhoneNumber = phoneUtil.prettyPrint(
                    phoneNumber,
                    localeService.getDefaultCountryCode()
                );

                return primaryPhoneNumber && (
                    <div className={tabStyles.gwContactPhoneNumber}>
                        {primaryPhoneNumber}
                    </div>
                );
            }

            if(property === 'email') {
                return contact.emailAddress1 && (
                    <div className={tabStyles.gwContactEmail}>
                        {contact.emailAddress1}
                    </div>
                );
            }

            const contactAvatarClass = cx(tabStyles.gwContactAvatar, {
                [tabStyles.gwActiveContactAvatar]: item.active,
                [tabStyles.gwInactiveContactAvatar]: !item.active
            });

            return (
                <div className={tabStyles.gwContactsTableWrapper}>
                    <div className={tabStyles.gwContactAvatarContainer}>
                        <Avatar
                            className={contactAvatarClass}
                            username={item[property]}
                            onClick={() => {
                                this.redirectToContactDetails(index);
                            }}
                        />
                    </div>
                    <div className={tabStyles.gwContactNameContainer}>
                        <div className={tabStyles.gwContactName}>{item[property]}</div>
                    </div>
                </div>
            );
        }

        return <div />;
    };

    renderRole = (item, index, { path: property }) => {
        const { policyContactRoleVM } = this.state;
        const translator = this.context;
        const roles = item[property];
        const roleOptions = policyContactRoleVM.subtype.aspects.availableValues;
        const getAllRoles = roles.map((data) => {
            const matchingRole = roleOptions.find((opt) => opt.code === data);
            return matchingRole ? (
                translator(messages[_.lowerFirst(matchingRole.code)])
            ) : null;
        }).join(', ');

        return (
            <div title={getAllRoles}>
                { getAllRoles }
            </div>
        );
    };

    renderPoliciesInvolved = (item, index, { path: property }) => {
        const policiesCount = item[property];
        const isActive = item.active;

        const policiesInvolved = isActive
            ? (
                <TranslatorContext.Consumer>
                    {(translator) => {
                        const activePolicies = policiesCount > 1
                            ? translator(messages.activePolicies)
                            : translator(messages.activePolicy);
                        return (
                            <div className={tabStyles.gwInvolvedPoliciesContainer}>
                                <h4 className={tabStyles.gwInvolvedPolicies}>{policiesCount}</h4>
                                {activePolicies}
                            </div>
                        );
                    }}
                </TranslatorContext.Consumer>
            )
            : (
                <TranslatorContext.Consumer>
                    {(translator) => {
                        return (
                            translator(gatewayMessages.inactive)
                        );
                    }}
                </TranslatorContext.Consumer>
            );

        return <div>{policiesInvolved}</div>;
    };

    loadData = ({ pageSize, page }) => {
        const paginationParams = {
            fromPage: page,
            pageSize: pageSize
        };

        return this.getPolicyContactSummaries(paginationParams);
    };

    render() {
        const { policyContactRole, policyContactRoleOptions } = this.state;
        const overrideProps = {
            policyContactsRoles: {
                availableValues: policyContactRoleOptions,
                value: policyContactRole
            },
            policyContactsDataTable: {
                key: policyContactRole,
                onFetchData: this.loadData
            }
        };

        const resolvers = {
            resolveClassNameMap: tabStyles,
            resolveCallbackMap: {
                renderContact: this.renderContact,
                renderRole: this.renderRole,
                renderPoliciesInvolved: this.renderPoliciesInvolved,
                handlePolicyContactSelect: this.handlePolicyContactSelect,
                onRowClick: (data, index) => {
                    this.redirectToContactDetails(index);
                }
            }
        };

        const readValue = (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                policyContactRole,
                id,
                path,
                overrideProps
            );
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                onValueChange={this.writeValue}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}

export const PolicyContactsComponent = PolicyContactsPage;
export default withRouter(withViewModelService(withAuthenticationContext(PolicyContactsPage)));
