import { defineMessages } from 'react-intl';

export default defineMessages({
    paNewDriver: {
        id: 'quoteandbind.ppv.views.ppv-driver-details.New Driver',
        defaultMessage: 'Add Driver'
    },
    removeDriverTitle: {
        id: 'quoteandbind.ppv.controllers.PPVDriverDetailsCtrl.Remove driver?',
        defaultMessage: 'Remove Driver?',
    },
    removeDriverDescription: {
        id: 'quoteandbind.ppv.controllers.PPVDriverDetailsCtrl.DriverRemovalMsg',
        defaultMessage: 'Are you sure you want to remove this {driver} from the list of drivers?',
    },
    secondaryDriverMustDriveDesc: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-driver.driver is assigned to at least one vehicle.',
        defaultMessage: '{driverName} should be assigned to at least one vehicle.'
    },
    another:{
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-driver.OtherDrivers Add another driver',
        defaultMessage: 'Add a driver'
    },
    missingInformationErrorTitle:{
        id: 'quoteandbind.ppv.controllers.PPVDriverDetailsCtrl.Information is missing',
        defaultMessage: 'Information is missing'
    },
    mainDriverErrorDesc:{
        id: 'quoteandbind.ppv.controllers.PPVDriverDetailsCtrl.A main driver needs be assigned',
        defaultMessage: 'A main driver needs to be assigned to the contract.'
    },
    close:{
        id: 'platform.modal.ModalService.Close',
        defaultMessage: 'Close'
    },
    thisDriver: {
        id: 'quoteandbind.controllers.this driver',
        defaultMessage: 'This driver'
    },
    vehicleCanHave1PriDriver: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-driver.Maximum one main driver per vehicle is allowed',
        defaultMessage: 'Only one main driver can be assigned per vehicle.'
    },
    vehicleHasMoreThen1Drivers: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-driver.has more than one main driver assigned.',
        defaultMessage: 'The vehicle: {vehicleName} has more than one main driver assigned. Only one main driver per vehicle is allowed. The main driver should be the same for all vehicles. Please only indicate one main driver.'
    },
    inconsistentInformation: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-driver.The information provided is inconsistent.',
        defaultMessage: 'The information provided is inconsistent.'
    },
    vehicleHas0Drivers: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-driver.has no driver assigned.',
        defaultMessage: '{vehicleName} must have main driver assigned.'
    },
    errorNameAndDOBChanged: {
        id: 'quoteandbind.policy-change.drivers-page.error-message.name-and-dob-changed',
        defaultMessage: 'It is not allowed to modify the First Name, Last Name and Date of Birth. Please create a new driver.'
    }
});
