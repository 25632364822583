import { defineMessages } from 'react-intl';

export default defineMessages({
    insuranceDetails: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Details of the insurance you have chosen for this vehicle',
        defaultMessage: 'Details of the insurance you have chosen for this vehicle'
    },
    pricesInEuros: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.All prices are mentioned in euros',
        defaultMessage: 'All prices are mentioned in euros'
    },
    coverageName: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Coverages',
        defaultMessage: 'Coverages'
    },
    coverageDetails: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Details',
        defaultMessage: 'Details'
    },
    coverageNetPremium: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Net premium',
        defaultMessage: 'Net premium'
    },
    spreadCosts: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Spread costs',
        defaultMessage: 'Spread costs'
    },
    taxAndSurcharges: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Tax and surcharges',
        defaultMessage: 'Tax and surcharges'
    },
    inclusiveAcquistionAndCommissionCosts: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Inclusive acquistion and commission costs',
        defaultMessage: 'Inclusive acquistion and commission costs'
    },
    inclusiveAcquistionCosts: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Inclusive acquistion costs',
        defaultMessage: 'Inclusive acquistion costs'
    },
    inclusiveAdministrationCosts: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Inclusive administration costs',
        defaultMessage: 'Inclusive administration costs'
    },
    grandTotal: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Grand Total',
        defaultMessage: 'Grand Total'
    },
    totalPPVMyPassengersTheOthersAndIGrp: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Total for My passenger, the Other and I',
        defaultMessage: 'Total for My passenger, the Other and I'
    },
    totalPCVMyPassengersTheOthersAndIGrpCV: {
        id: 'quoteandbind.pcv.views.pcv-policy-info.Total for My passenger, the Other and I',
        defaultMessage: 'Total for My passenger, the Other and I'
    },
    totalPPVMyVehicleGrp: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Total for My Vehicle',
        defaultMessage: 'Total for My Vehicle'
    },
    totalPCVMyVehicleGrpCV: {
        id: 'quoteandbind.pcv.views.pcv-policy-info.Total for My Vehicle',
        defaultMessage: 'Total for My Vehicle'
    },
    totalPPVMyMobilityGrp: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Total for My Mobility',
        defaultMessage: 'Total for My Mobility'
    },
    totalPCVMyMobilityGrpCV: {
        id: 'quoteandbind.pcv.views.pcv-policy-info.Total for My Mobility',
        defaultMessage: 'Total for My Mobility'
    },
    totalPPVMyContentGrp: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Total for My Content',
        defaultMessage: 'Total for My Content'
    },
    totalPCVMyContentGrpCV: {
        id: 'quoteandbind.pcv.views.pcv-policy-info.Total for My Content',
        defaultMessage: 'Total for My Content'
    },
    labelPPVMyPassengersTheOthersAndIGrp: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.My Passengers, the Other and I',
        defaultMessage: 'My Passengers, the Other and I'
    },
    labelPCVMyPassengersTheOthersAndIGrpCV: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.My Passengers, the Other and I',
        defaultMessage: 'My Passengers, the Other and I'
    },
    labelPPVMyVehicleGrp: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.My Vehicle',
        defaultMessage: 'My Vehicle'
    },
    labelPCVMyVehicleGrpCV: {
        id: 'quoteandbind.pcv.views.pcv-policy-info.My Vehicle',
        defaultMessage: 'My Vehicle'
    },
    labelPPVMyMobilityGrp: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.My Mobility',
        defaultMessage: 'My Mobility'
    },
    labelPCVMyMobilityGrpCV: {
        id: 'quoteandbind.pcv.views.pcv-policy-info.My Mobility',
        defaultMessage: 'My Mobility'
    },
    labelPPVMyContentGrp: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.My Content',
        defaultMessage: 'My Content'
    },
    labelPCVMyContentGrpCV: {
        id: 'quoteandbind.pcv.views.pcv-policy-info.My Content',
        defaultMessage: 'My Content'
    },
    compensationFormula: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Compensation formula',
        defaultMessage: 'Compensation formula'
    },
    additionalCoverages: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Your additional coverages',
        defaultMessage: 'Your additional coverages:'
    }
});
