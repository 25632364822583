import React, { useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Chevron } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { DriverUtil, VehicleUtil } from 'pv-portals-util-js';

import metadata from './PolicyChangesComponent.metadata.json5';
import messages from './PolicyChangesComponent.messages';
import styles from './PolicyChangesComponent.module.scss';

function getEntity(policyChange, path, fixedId) {
    const entities = _.get(policyChange, path, []);
    return entities.find((entity) => entity.fixedId === fixedId);
}

function formatEntityData(entity, history, translator, getDisplayName) {
    const entityName = _.isUndefined(entity) ? history.itemName : getDisplayName(entity, translator) || entity.displayName;
    return {
        ...history,
        actionName: translator(messages[history.action]),
        entityName: entityName
    };
}

function renderHeader({ entityName, actionName, action }) {
    return (isOpen) => (
        <Flex justifyContent="between">
            <Flex>
                {action !== 'removed' ? (<Chevron isOpen={isOpen} />) : null}
                <span>{entityName}</span>
            </Flex>
            <span>{actionName}</span>
        </Flex>
    );
}

function PolicyChangesComponent({ value: policyChange }) {
    const translator = useTranslator();

    const historyChanges = useMemo(() => {
        const history = _.get(policyChange, 'history', []);
        let addressChanges = {};
        const driverChanges = [];
        const vehicleChanges = [];
        const lobName = _.get(policyChange, 'baseData.productCode') === 'PersonalVehicle_PV' ? 'ppvPersonalAuto_PV' : 'pcvCommercialAuto_PV';
        history.forEach((h) => {
            switch (h.entityType) {
                case 'address':
                    addressChanges = {
                        ...h,
                        actionName: translator(messages[h.action]),
                        address: _.get(policyChange, 'baseData.policyAddress')
                    };
                    break;
                case 'driver':
                    const driver = getEntity(policyChange, `lobData.${lobName}.coverables.drivers`, h.fixedId);
                    const driverData = formatEntityData(driver, h, translator, DriverUtil.getShortDisplayName);
                    driverChanges.push(driverData);
                    break;
                case 'vehicle':
                    const vehicle = getEntity(policyChange, `lobData.${lobName}.coverables.vehicles`, h.fixedId);
                    const vehicleData = formatEntityData(vehicle, h, translator, VehicleUtil.getShortDisplayName);
                    vehicleChanges.push(vehicleData);
                    break;
                default:
                    break;
            }
        });

        const coveragesChanges = history.filter((h) => h.entityType === 'coverage');
        const vehicleCoveragesChanges = _.groupBy(coveragesChanges, 'parentId');
        Object.entries(vehicleCoveragesChanges).forEach(([keyText, covChanges]) => {
            const key = parseInt(keyText);
            const hasVehicleChange = vehicleChanges.find((veh) => veh.fixedId === key);
            if (!hasVehicleChange) {
                // Vehicle coverages updates don't mark vehicle as changed, so we need to do it manually
                const newHistory = {
                    entityType: 'vehicle',
                    action: 'changed',
                    covChanges: covChanges.map((change) => formatEntityData(undefined, change, translator, _.noop))
                };
                const vehicle = getEntity(policyChange, `lobData.${lobName}.coverables.vehicles`, key);
                const vehicleData = formatEntityData(vehicle, newHistory, translator, VehicleUtil.getShortDisplayName);
                vehicleChanges.push(vehicleData);
            } else if (hasVehicleChange.action !== 'removed') {
                // Attach coverages updates to the added or changed vehicles, skip removed vehicles
                hasVehicleChange.covChanges = covChanges.map((change) => formatEntityData(undefined, change, translator, _.noop));
            }
        });

        return {
            hasChanges: !_.isEmpty(history),
            addressChanges,
            driverChanges,
            vehicleChanges
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generatedVehOverrides = useMemo(() => {
        const overrides = historyChanges.vehicleChanges.map((vehChange, index) => {
            return {
                [`vehicleCard${index}`]: {
                    renderHeader: renderHeader(vehChange),
                    isCollapsible: vehChange.action !== 'removed' // Don't show coverages for removed vehicles
                },
            };
        });
        return Object.assign({}, ...overrides);
    }, [historyChanges.vehicleChanges]);

    const overrideProps = {
        policyChangesComponent: {
            visible: historyChanges.hasChanges
        },
        addressContainer: {
            visible: !_.isEmpty(historyChanges.addressChanges)
        },
        driversContainer: {
            visible: !_.isEmpty(historyChanges.driverChanges)
        },
        vehiclesContainer: {
            visible: !_.isEmpty(historyChanges.vehicleChanges)
        },
        ...generatedVehOverrides
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={historyChanges}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PolicyChangesComponent.propTypes = {
    policyChange: PropTypes.shape({}).isRequired
};

export default PolicyChangesComponent;
