import { CurrencyValue } from '@jutro/components'
import React, { Component, useContext } from 'react'
import { IntlContext } from '@jutro/locale'
import { formatCurrency, FormattedCurrency } from '@jutro/components'


function CurrencyValue_PV(props) {
  const intl = useContext(IntlContext)

	return (

    <div><b>
      {formatCurrency(intl,
        { showFractions: true, ...FormattedCurrency.defaultProps },
        props.value,
      ).replace('\u00a0', '')}
    </b></div> 
  )
}

export default CurrencyValue_PV