import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './VehicleCoverage.metadata.json5';
import styles from '../PolicyVehiclesDriversInfo.module.scss';

function getCell(items, index, property) {
    return !_.isObject(items[property.id]) ? items[property.id] : null;
}
function getCurrencyCell(items, index, property) {
    return (
        <CurrencyField
            id={`currency_${index}`}
            value={items[property.id]}
            readOnly
            hideLabel
            showOptional={false}
            className={styles.currencyValue}
        />
    );
}

function VehicleCoverage({ data, show }) {
    if (_.isEmpty(data)) {
        return null;
    }
    const overrides = {
        vehicleCoverageInfo: {
            data: data,
            visible: show
        },
        coverageTypeColumn: {
            visible: false
        },
        limitsColumn: {
            visible: false
        },
        deductibleColumn: {
            visible: false
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            getCellCoverage: getCell,
            getCellCurrencyCoverage: getCurrencyCell
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

VehicleCoverage.propTypes = {
    data: PropTypes.shape([]),
    show: PropTypes.bool
};
VehicleCoverage.defaultProps = {
    data: [],
    show: false
};
export default VehicleCoverage;
