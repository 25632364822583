import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Loader } from '@jutro/components';
import { Wizard } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { useDependencies, DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { useWizardInit, useWizardExit, useWizardError, PolicyChangesContextProvider } from 'pv-capability-policychange-common-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { BusinessConstant } from 'pv-portals-util-js';
import wizardConfig from './config/pcv-policychange-wizard-config.json5';

function PCVPolicyChangeWizard(props) {
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const viewModelService = useContext(ViewModelServiceContext);
    const { location, history } = props;
    const { EndorsementService } = useDependencies('EndorsementService');

    const vmContext = {
        IsAgent: true,
        DriverDetails: true
    };

    // hook to initialize the wizard
    const {
        policyChangeVM,
        wizardSteps,
        wizardTitle
    } = useWizardInit({
        location,
        history,
        viewModelService,
        setIsLoading,
        wizardConfig,
        vmContext,
        EndorsementService
    });

    // handles modals when exiting/cancelling the flow
    const handleCancel = useWizardExit({ hasErrorOccurred, history });

    // handles error modals
    const handleError = useWizardError({ location, setHasErrorOccurred });

    const [selectedChanges, setSelectedChanges] = useState(
        {
            [BusinessConstant.POL_CHNG_VEHICLES]: false,
            [BusinessConstant.POL_CHNG_DRIVERS]: false,
            [BusinessConstant.POL_CHNG_PAYMENTS]: false,
            [BusinessConstant.POL_CHNG_COVERAGES]: false,
            [BusinessConstant.POL_CHNG_VIN_LICENSEPLATE]: false,
        }
    );
    const [isVinAndLicenseplateQuickChangeAllowed, setVinAndLicenseplateQuickChangeAllowed] = useState(true);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!policyChangeVM) {
        return null;
    }
    const wizardContext = {
        selectedChanges,
        setSelectedChanges,
        isVinAndLicenseplateQuickChangeAllowed,
        setVinAndLicenseplateQuickChangeAllowed
    };

    return (
        <ErrorBoundary onError={handleError}>
            <DependencyProvider
                value={{
                    LoadSaveService: EndorsementService, // injects PolicyChange service
                    CustomQuoteService: EndorsementService,
                    initialSteps: wizardSteps
                }}
            >
                <PolicyChangesContextProvider wizardContext={wizardContext}>
                    <Wizard
                        initialSteps={wizardSteps}
                        wizardTitle={wizardTitle}
                        initialData={policyChangeVM}
                        onCancel={handleCancel}
                        onPreviousModalProps={{
                            title: platformMessages.wantToJump,
                            message: platformMessages.wantToJumpMessage,
                            status: 'warning',
                            icon: 'mi-error-outline',
                            confirmButtonText: platformMessages.yes,
                            cancelButtonText: platformMessages.no
                        }}
                        skipCompletedSteps
                    />
                </PolicyChangesContextProvider>
            </DependencyProvider>
        </ErrorBoundary>
    );
}

PCVPolicyChangeWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            policyNumber: PropTypes.string,
            requestType: PropTypes.string,
            policyDetails: PropTypes.shape({
                effective: PropTypes.instanceOf(Date)
            })
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(withViewModelService(PCVPolicyChangeWizard));
