import { defineMessages } from 'react-intl';

export default defineMessages({
    parameters:{
        id: "quoteandbind.common.directives.CommonOfferingSelection.Parameters",
        defaultMessage: "Parameters"
    },
    discount:{
        id: "quoteandbind.ppv.views.discount-commission-popup.Discount",
        defaultMessage: "Discount"
    },
    commission:{
        id: "quoteandbind.ppv.views.discount-commission-popup.Commission",
        defaultMessage: "Commission"
    },
    surcharge:{
        id: "quoteandbind.ppv.views.discount-commission-popup.Surcharge",
        defaultMessage: "Surcharge"
    },
    reset:{
        id:"quoteandbind.ppv.views.discount-commission-popup.rest",
        defaultMessage: "Reset"
    },
    applyMaxDiscounts:{
        id:"quoteandbind.ppv.views.discount-commission-popup.Apply Maximum Discounts",
        defaultMessage: "Apply Maximum Discounts"
    },
    promoCode:{
        id: "quoteandbind.common.directives.CommonOfferingSelection.MultipleOfferingView.Promocode",
        defaultMessage: "Promo Code"
    },
    initialize:{
        id: "quoteandbind.ppv.views.discount-commission-popup.initialize",
        defaultMessage: "Initialize"
    },
    setAllto0:{
        id: "quoteandbind.ppv.views.discount-commission-popup.apply zero",
        defaultMessage: "Set all commissions to 0%"
    },
    save:{
        id: "quoteandbind.ppv.views.discount-commission-popup.save",
        defaultMessage: "Save"
    },
    none:{
        id: "quoteandbind.ppv.views.ppv-additional-info.None Selected",
        defaultMessage: "none"
    },
    numberOnly:{
        id :"platform.modelvalidation.DTOValidationService.Please enter numbers only",
        defaultMessage: "Please enter numbers only."
    }
});
