import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@jutro/components';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { BusinessConstant, DriverUtil } from 'pv-portals-util-js';
import metadata from './DriverInfo.metadata.json5';
import './DriverInfo.messages.js';

const getDriverIcon = (item, index, { path }) => {
    return item[path] ? <Icon icon='mi-done' /> : '-';
};

function DriverInfo(props) {
    const {
        data: driverVM,
        submissionVM,
        pageLoBType,
        quickQuoteMode
    } = props;

    const [productCode] = useState(_.get(submissionVM, 'baseData.productCode.value'));
    const [associatedVehicles, setAssociatedVehicles] = useState([]);
    const [primaryOnVehicles, setPrimaryOnVehicles] = useState([]);
    const [secondaryOnVehicles, setScondaryVehicles] = useState([]);
    const [isMaterialDamageSelected, setIsMaterialDamageSelected] = useState(false);

    useEffect(() => {
        const getAssociatedVehicles = [];
        const getPrimaryDriverVehicleNames = [];
        const secondaryDriverVehicleNames = [];

        const vehicleDrivers = _.get(submissionVM, `lobData.${pageLoBType}.coverables.vehicleDrivers.children`, []);
        const vehicles = _.get(submissionVM, `lobData.${pageLoBType}.coverables.vehicles.children`, []);
        const vehiclesCoverages = _.get(submissionVM, `lobData.${pageLoBType}.offerings.value`).find((offering) => offering.branchCode === BusinessConstant.BRANCH_CODE_CUSTOM).coverages.vehicleCoverages;
        const materialDamageCov = vehiclesCoverages.find((vCovs) =>
            vCovs.coverages.some((c) => c.selected === true
                    && (c.publicID === BusinessConstant.COVERAGE_PUBLICID_PPV_MATERIAL_DAMAGE || c.publicID === BusinessConstant.COVERAGE_PUBLICID_PCV_MATERIAL_DAMAGE)));

        _.each(vehicleDrivers, (vehicleDriver) => {
            if (_.get(driverVM, 'fixedId.value') === _.get(vehicleDriver, 'driverID.value')) {
                // loop on vehicles.
                _.each(vehicles, (vehicle) => {
                    if (_.get(vehicleDriver, 'vehicleID.value') === _.get(vehicle, 'fixedId.value') &&
                        _.get(vehicleDriver, 'vehicleDriverUsage.value.code') !== BusinessConstant.VEHICLE_DRIVER_USAGE_NDR) {
                        getAssociatedVehicles.push({
                            isPrimary: _.get(vehicleDriver, 'value.vehicleDriverUsage') === BusinessConstant.VEHICLE_DRIVER_USAGE_PRI,
                            isSecondary: _.get(vehicleDriver, 'value.vehicleDriverUsage') === BusinessConstant.VEHICLE_DRIVER_USAGE_SEC,
                            vehicleName: `${_.get(vehicle, 'value.make')} ${_.get(vehicle, 'value.model')}`
                        });
                    }
                    // filter by vehicleid. Vehicle list contains all vehicles on submission
                    if (_.get(vehicleDriver, 'vehicleID.value') === _.get(vehicle, 'fixedId.value')) {
                        // if usage is PRI, add vehicle displayname to primary vehicles
                        if (_.get(vehicleDriver, 'value.vehicleDriverUsage') === BusinessConstant.VEHICLE_DRIVER_USAGE_PRI) {
                            getPrimaryDriverVehicleNames.push(`${_.get(vehicle, 'value.make')} ${_.get(vehicle, 'value.model')}`);
                        } else if (vehicleDriver.value.vehicleDriverUsage === BusinessConstant.VEHICLE_DRIVER_USAGE_SEC) {
                            secondaryDriverVehicleNames.push(`${_.get(vehicle, 'value.make')} ${_.get(vehicle, 'value.model')}`);
                        }
                    }
                });
            }
        });

        const primaryVehicles = _.reduce(getPrimaryDriverVehicleNames, (memoizer, vehName) => {
            return `${memoizer}, ${vehName}`;
        });

        const secondaryVehicles = _.reduce(secondaryDriverVehicleNames, (memoizer, vehName) => {
            return `${memoizer}, ${vehName}`;
        });

        setAssociatedVehicles(getAssociatedVehicles);
        setPrimaryOnVehicles(primaryVehicles);
        setScondaryVehicles(secondaryVehicles);
        setIsMaterialDamageSelected(!_.isUndefined(materialDamageCov));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isDisplayValue = useCallback((type) => {
        const selectedValue = _.get(driverVM.value, productCode === BusinessConstant.PCV_PROD_CODE ? `${type}CL_PV` : `${type}_PV`);
        return !quickQuoteMode && selectedValue;
    }, [driverVM, quickQuoteMode, productCode]);

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        maindriver: {
            visible: productCode === BusinessConstant.PPV_PROD_CODE && !!primaryOnVehicles,
            value: !!primaryOnVehicles
        },
        secondaryVehicles: {
            visible: !quickQuoteMode && !_.isEmpty(secondaryOnVehicles),
            value: secondaryOnVehicles
        },
        // Unique case, these fields might be displayed on Vehicles/Drivers (FQ) or Pack (QQ)
        // extract visibility logic into a util would require implementing complex logic for one single case
        numberOfClaimsForMaterialDamage: {
            visible: (quickQuoteMode && isMaterialDamageSelected) || isDisplayValue('claimsMD5YearsFault'),
            path: productCode === BusinessConstant.PCV_PROD_CODE ? 'claimsMD5YearsFaultCL_PV' : 'claimsMD5YearsFault_PV'
        },
        vehicleDriversDetails: {
            data: associatedVehicles
        }
    };

    const allCoverables = _.get(submissionVM, `lobData.${pageLoBType}.coverables.value`, []);
    const jobType = _.get(submissionVM, 'baseData.jobType.value.code');
    const brand = _.get(submissionVM, 'baseData.value.brand_PV');
    _.merge(overrideProps, DriverUtil.visibleFields(driverVM, allCoverables, jobType, quickQuoteMode, productCode, brand));

    const resolvers = {
        resolveCallbackMap: {
            getDriverIcon
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driverVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

DriverInfo.propTypes = {
    data: PropTypes.shape({}),
    submissionVM: PropTypes.shape({}),
    pageLoBType: PropTypes.string,
    quickQuoteMode: PropTypes.bool
};

DriverInfo.defaultProps = {
    data: {},
    submissionVM: {},
    pageLoBType: undefined,
    quickQuoteMode: false
};

export default DriverInfo;
