import React from 'react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import metadata from './AvatarMenu.metadata.json5';

const AvatarMenu = (props) => {
    const { avatarProps, avatarChildren } = props;

    const overrideProps = {
        dropdownAvatar: {
            ...avatarProps,
            content: avatarChildren
        }
    };

    return <MetadataContent
        uiProps={metadata.componentContent}
        overrideProps={overrideProps} />
};


export default AvatarMenu;
