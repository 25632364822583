import React, { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useModal } from '@jutro/components';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { messages as commonMessages } from 'gw-capability-policychange-common-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { LocalDateUtil } from '@xengage/gw-portals-util-js';
import { BusinessConstant as BC, VehicleUtil } from 'pv-portals-util-js';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { PolicyChangesContext } from '../../components/PolicyChangesContext/PolicyChangesContextProvider';
import messages from './PolicyChangePage.messages';
import metadata from './PolicyChangePage.metadata.json5';
import styles from './PolicyChangePage.module.scss';

// NOTE: AVAILABLE_VALUES::CODE must be aligned to ***-wizard-config.json5::Steps::ID
// onNext page slicer is comparing those two values and leaving page in wizard or removing
const AVAILABLE_VALUES = [
    {
        code: BC.POL_CHNG_VIN_LICENSEPLATE,
        name: messages.vinLicenseplate
    },
    {
        code: BC.POL_CHNG_VEHICLES,
        name: messages.vehicles
    },
    {
        code: BC.POL_CHNG_DRIVERS,
        name: messages.drivers
    },
    {
        code: BC.POL_CHNG_PAYMENTS,
        name: messages.payments
    },
    {
        code: BC.POL_CHNG_COVERAGES,
        name: messages.coverages,
        tooltip: messages.coveragesTooltip
    },
];

function PolicyChangePage(props) {
    const { authHeader } = useAuthentication();
    const { updateWizardData,
        changeNextSteps,
        currentStepIndex,
        wizardData: policyChangeVM,
        currentStep: { stepProps: { isContinueExistingJob }}
    } = props;
    const translator = useTranslator();
    const { EndorsementService } = useDependencies('EndorsementService');
    const [ initialSteps, setInitialSteps ] = useState(useDependencies('initialSteps').initialSteps);
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation('PolicyChangePage');
    const changesContext = useContext(PolicyChangesContext);
    // NOTE: need pure skipping on initial validation function, without ViewModel validation
    const [ initialValidation ] = useState(policyChangeVM.value.status === 'Proposal_pv'
        || (isContinueExistingJob && policyChangeVM.value.status === 'Bound'));
    const [ productCode ] = useState(_.get(policyChangeVM, 'baseData.productCode.value'));
    const [ isCommercial ] = useState(productCode === BC.PCV_PROD_CODE);
    const [ lobVmPath ] = useState(isCommercial ? BC.PCV_LOB_CODE : BC.PPV_LOB_CODE);
    const [ previouslySelectedChangeReason ] = useState(policyChangeVM.value.status !== 'Bound' && policyChangeVM.policyChangeReason_PV?.value?.code);
    const { showAlert } = useModal();
    const [ availableValues, setAvailableValues ] = useState(AVAILABLE_VALUES);
    const [ hasDesignatedDrivers ] = useState(policyChangeVM?.lobData?.pcvCommercialAuto_PV?.coverables?.vehicles?.children?.some((vehicle) => vehicle.designatedDriver_PV.value));

    const getInitialValidation = useCallback(() => {
        return initialValidation;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isCommercial && !hasDesignatedDrivers) {
            // removes Drivers page completely for PCV with no DesignatedDrivers
            // NOTE: a better solution would be selectedChanges[drivers]=false
            setAvailableValues((values) => values.filter((v) => v.code !== BC.POL_CHNG_DRIVERS));
            changesContext.setSelectedChanges((prevSelectedChanges) => {
                const { [BC.POL_CHNG_DRIVERS]: unused, ...restSelectedChanges } = prevSelectedChanges;
                return restSelectedChanges;
            });
            setInitialSteps((steps) => steps.filter((step) => step.id !== BC.POL_CHNG_DRIVERS));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasDesignatedDrivers, changesContext.setSelectedChanges, setInitialSteps, isCommercial]);

    const hasInvalidPlate = useMemo(() => {
        const vehicles = policyChangeVM.lobData[lobVmPath].coverables.vehicles.value;
        const licensePlates = vehicles.map((veh) => veh.license);
        return !licensePlates.every((lp) => VehicleUtil.isLicensePlateValid(lp));
    }, [lobVmPath, policyChangeVM.lobData]);

    const hasInvalidVin = useMemo(() => {
        const vehicles = policyChangeVM.lobData[lobVmPath].coverables.vehicles.value;
        const vinNumbers = vehicles.map((veh) => veh.vin);
        return !vinNumbers.every((vn) => VehicleUtil.isVinValid(vn));
    }, [lobVmPath, policyChangeVM.lobData]);

    const invalidPaymentData = useMemo(() => {
        const paymentMethod = policyChangeVM.bindData_PV.paymentDetails.paymentMethod_PV.value.code;
        const accountNumber = policyChangeVM.bindData_PV.paymentDetails.bankAccountData.bankAccountNumber?.value;
        return paymentMethod === BC.PAYMENT_METHOD_SEPA && _.isEmpty(accountNumber);
    }, [policyChangeVM.bindData_PV.paymentDetails.bankAccountData.bankAccountNumber?.value, policyChangeVM.bindData_PV.paymentDetails.paymentMethod_PV?.value.code]);

    useEffect(() => {
        changesContext.setVinAndLicenseplateQuickChangeAllowed(!(hasInvalidPlate || hasInvalidVin || invalidPaymentData));
    }, [changesContext, hasInvalidPlate, hasInvalidVin, invalidPaymentData]);

    const isMandatoryStep = useCallback(
        (stepCode, currentSelections) => {
            switch (stepCode) {
                case BC.POL_CHNG_VEHICLES:
                    // mandatory only if errors present
                    return hasInvalidPlate || hasInvalidVin;

                case BC.POL_CHNG_DRIVERS:
                    // 1) mandatory if vehicles checked
                    return currentSelections[BC.POL_CHNG_VEHICLES];

                case BC.POL_CHNG_PAYMENTS:
                    // mandatory if errors present || mandatory if vehicles checked

                    return invalidPaymentData || currentSelections[BC.POL_CHNG_VEHICLES];

                case BC.POL_CHNG_COVERAGES:
                    // 1) mandatory if payments or vehicles or drivers checked
                    return currentSelections[BC.POL_CHNG_VEHICLES] || currentSelections[BC.POL_CHNG_PAYMENTS] || currentSelections[BC.POL_CHNG_DRIVERS];

                default:
                    return false;
            }
        },
        [hasInvalidPlate, hasInvalidVin, invalidPaymentData]
    );

    const removeNotNeededPages = useCallback((currentChanges) => {
        const allInitialSteps = _.clone(initialSteps);
        const newSteps = allInitialSteps.slice(currentStepIndex + 1, initialSteps.length);
        const currentChangesIncludingDefaultDrivers = { [BC.POL_CHNG_DRIVERS]: false, ...currentChanges };
        for (const [key, value] of Object.entries(currentChangesIncludingDefaultDrivers)) {
            if (!value) {
                const idx = newSteps.findIndex(({ id }) => id === key);
                if (idx >= 0) {
                    newSteps.splice(idx, 1);
                }
            }
        }
        changeNextSteps(newSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStepIndex, initialSteps]);

    const setAllMandatorySteps = useCallback(() => {
        changesContext.setSelectedChanges((selectedChanges) => {
            let continueUpdates;
            do {
                continueUpdates = false;
                for (const [key, value] of Object.entries(selectedChanges)) {
                    if (isMandatoryStep(key, selectedChanges) && !value) {
                        selectedChanges[key] = true;
                        continueUpdates = true;
                    }
                }
            } while (continueUpdates);
            removeNotNeededPages(selectedChanges);
            return selectedChanges;
        });
    }, [changesContext, isMandatoryStep, removeNotNeededPages]);

    const selectStep = useCallback((changeReason) => {
        changesContext.setSelectedChanges((selectedChanges) => {
            if (changeReason === BC.POL_CHNG_REASON_CHANGE_VIN_AND_LICENSEPLATE) {   
                selectedChanges[BC.POL_CHNG_VIN_LICENSEPLATE] = true;
            } else if (changeReason === BC.POL_CHNG_REASON_CHANGE_VEHICLE) {
                selectedChanges[BC.POL_CHNG_VEHICLES] = true;
            } else if (changeReason === BC.POL_CHNG_REASON_CHANGE_DRIVER) {
                selectedChanges[BC.POL_CHNG_DRIVERS] = true;
            } else if (changeReason === BC.POL_CHNG_REASON_CHANGE_PAYMENT_DETAILS) {
                selectedChanges[BC.POL_CHNG_PAYMENTS] = true;
            } else if (changeReason === BC.POL_CHNG_REASON_CHANGE_COVERAGE) {
                selectedChanges[BC.POL_CHNG_COVERAGES] = true;
            }
            return selectedChanges;
        });
    }, [changesContext]);

    useEffect(() => {
        if (!initialValidation) {
            // make changes only if page is NOT skipped

            // 1 ---- preselected steps if there is any previous pending policy change
            if (previouslySelectedChangeReason) {
                selectStep(previouslySelectedChangeReason);
            }

            // 2 ---- auto-select mandatory steps ------
            setAllMandatorySteps();

            // 3 ---- already pre-set earliest policychangedate
            const minDate = _.get(policyChangeVM, 'baseData.minimumEffectiveDate.value');
            const effDate = _.get(policyChangeVM, 'baseData.effectiveDate.value');
            if (LocalDateUtil.toMidnightDate(effDate).getTime() < LocalDateUtil.toMidnightDate(minDate).getTime()) {
                _.set(policyChangeVM, 'baseData.effectiveDate.value', minDate);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (changesContext.selectedChanges[BC.POL_CHNG_VIN_LICENSEPLATE]) {
            // eslint-disable-next-line camelcase
            policyChangeVM.policyChangeReason_PV.value = BC.POL_CHNG_REASON_CHANGE_VIN_AND_LICENSEPLATE;
        } else if (changesContext.selectedChanges[BC.POL_CHNG_VEHICLES]) {
            // eslint-disable-next-line camelcase
            policyChangeVM.policyChangeReason_PV.value = BC.POL_CHNG_REASON_CHANGE_VEHICLE;
        } else if (changesContext.selectedChanges[BC.POL_CHNG_DRIVERS]) {
            // eslint-disable-next-line camelcase
            policyChangeVM.policyChangeReason_PV.value = BC.POL_CHNG_REASON_CHANGE_DRIVER;
        } else if (changesContext.selectedChanges[BC.POL_CHNG_PAYMENTS]) {
            // eslint-disable-next-line camelcase
            policyChangeVM.policyChangeReason_PV.value = BC.POL_CHNG_REASON_CHANGE_PAYMENT_DETAILS;
        } else if (changesContext.selectedChanges[BC.POL_CHNG_COVERAGES]) {
            // eslint-disable-next-line camelcase
            policyChangeVM.policyChangeReason_PV.value = BC.POL_CHNG_REASON_CHANGE_COVERAGE;
        }
    }, [changesContext.selectedChanges, policyChangeVM]);

    const handleChange = useCallback(
        (options) => {
            const newSelectedChanges = {...changesContext.selectedChanges};
            for (const [key] of Object.entries(newSelectedChanges)) {
                newSelectedChanges[key] = options.includes(key);
            }
            changesContext.setSelectedChanges(() => newSelectedChanges);

            setAllMandatorySteps();
        },
        [changesContext, setAllMandatorySteps]
    );

    const onNext = useCallback(async () => {
        const currentReason = {...policyChangeVM}?.policyChangeReason_PV?.value;
        const response = await EndorsementService.checkEffectiveDateIsValid(
            [policyChangeVM.policyNumber.value, policyChangeVM.baseData.effectiveDate.value],
            authHeader
        );
        if (response) {
            const effectiveDateResponse = await EndorsementService.loadEndorsementWithEffectiveDate(
                [policyChangeVM.policyNumber.value, policyChangeVM.baseData.effectiveDate.value],
                authHeader
            );
            policyChangeVM.value = effectiveDateResponse;
            // eslint-disable-next-line camelcase
            policyChangeVM.policyChangeReason_PV.value = currentReason;
            const policyChangeResponse = await EndorsementService.saveEndorsement([policyChangeVM.value], authHeader);
            policyChangeVM.value = policyChangeResponse;

            return policyChangeVM;
        }

        // normally not reachable code at all
        showAlert({
            title: platformMessages.genericError,
            message: platformMessages.genericErrorMessage,
            status: 'error',
            icon: 'mi-error-outline'
        });

        return false;
    }, [EndorsementService, policyChangeVM, authHeader, showAlert]);

    const getAvailableValuesAndMandatoryFlags = useCallback(() => {
        const values = [];
        availableValues.forEach((item) => {
            const isMandatory = isMandatoryStep(item.code, changesContext.selectedChanges);
            const nameStr = isMandatory ? `* ${translator(item.name)}` : translator(item.name); // visually emulates MANDATORY field (displays *)
            values.push({
                code: item.code,
                name: nameStr,
                subtitle: item.tooltip ? translator(item.tooltip) : null // NOTE: ideally should be tooltip, but for now just subtitle
            });
        });
        return values;
    }, [isMandatoryStep, changesContext.selectedChanges, translator, availableValues]);

    const getCheckBoxValues = useCallback(() => {
        const result = [];
        _.forEach(changesContext.selectedChanges, (value, key) => {
            if (value) {
                result.push(key);
            }
        });
        return result;
    }, [changesContext.selectedChanges]);

    // It is not possible to define individual disabled checkboxes in current Jutro version (7.4.3)
    // So we have to add extra styles, based on the current selection.
    useEffect(() => {
        // 1) Remove tabindex so the checkboxes can't be selected with keyboard tabs
        const checkboxes = document.querySelectorAll('#changesSelector [role=checkbox]');
        checkboxes.forEach((checkbox) => checkbox.removeAttribute('tabindex'));

        const checkboxControlSelector = (reason) => `#changesSelector > div:nth-child(${availableValues.findIndex((v) => v.code === reason) + 1})`;

        // 2) Restoring styles aka removing disabled styles (iterate only availableValues, not all options)
        availableValues.forEach((v) => {
            document.querySelector(checkboxControlSelector(v.code)).classList.remove(styles.disabledCheckbox);
        });

        // 3) Check for administrative selection (VIN and Licenseplate) and disable change reasons
        const administrativeChangeReasonSelected = changesContext.selectedChanges[BC.POL_CHNG_VIN_LICENSEPLATE];
        const allNonAdministrativeChangeOptions = availableValues.filter(({code}) => code !== BC.POL_CHNG_VIN_LICENSEPLATE).map(({code}) => code);
        if (administrativeChangeReasonSelected) {
            allNonAdministrativeChangeOptions.forEach((reason) => {
                document.querySelector(checkboxControlSelector(reason)).classList.add(styles.disabledCheckbox);
            });
        }

        // 4) Check for regular policy change reason and disable administrative changes
        const policyChangeReasonSelected = Object.keys(changesContext.selectedChanges)
            .filter((key) => changesContext.selectedChanges[key])
            .some((selected) => allNonAdministrativeChangeOptions.includes(selected));
        if (policyChangeReasonSelected || !changesContext.isVinAndLicenseplateQuickChangeAllowed) {
            document.querySelector(checkboxControlSelector(BC.POL_CHNG_VIN_LICENSEPLATE)).classList.add(styles.disabledCheckbox);
        }
    }, [changesContext.isVinAndLicenseplateQuickChangeAllowed, availableValues, changesContext.selectedChanges]);

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            phoneWide: {
                labelPosition: 'top'
            }
        },
        policyChangeEffectiveDate: {
            minDate: _.get(policyChangeVM, 'baseData.minimumEffectiveDate.value'),
            maxDate: _.get(policyChangeVM, 'baseData.maximumExpirationDate.value')
        },
        changesSelector: {
            value: getCheckBoxValues(),
            disabled: !!previouslySelectedChangeReason,
            onValueChange: handleChange,
            availableValues: getAvailableValuesAndMandatoryFlags()
        }
    };

    const checkSelectedAtLeast1Page = useCallback(() => {
        // at least some must be TRUE
        return _.values(changesContext.selectedChanges).some((item) => item);
    }, [changesContext.selectedChanges]);

    useEffect(() => {
        registerComponentValidation(checkSelectedAtLeast1Page);
    }, [registerComponentValidation, checkSelectedAtLeast1Page]);

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
            showCancel
            showPrevious={false}
            cancelLabel={commonMessages.cancel}
            skipWhen={getInitialValidation}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                showErrors
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}

PolicyChangePage.propTypes = wizardProps;
export default PolicyChangePage;
