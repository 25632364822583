import React from 'react';
import { Tooltip } from '@jutro/components';

function TooltipComponent({ children, ...otherProps}) {
    return (
        <Tooltip {...otherProps}>
            <span>{children}</span>
        </Tooltip>
    );
}

TooltipComponent.propTypes = Tooltip.propTypes;
TooltipComponent.defaultProps = Tooltip.defaultProps;

export default TooltipComponent;
