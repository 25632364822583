import _ from 'lodash';
import { useState, useEffect } from 'react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { ClauseService } from 'gw-capability-policycommon';
import { MockUpUtil } from '@xengage/gw-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { EdgeErrorParser } from 'pv-portals-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useModal } from '@jutro/components';
import { messages as platformMessages } from '@xengage/gw-platform-translations';

export default ({
    history,
    location,
    viewModelService,
    setIsLoading,
    wizardConfig,
    mockDataDetails,
    dtoPath = 'edge.capabilities.quote.submission.dto.QuoteDataDTO',
    vmContext = {},
    LoadSaveService
}) => {
    const { authHeader } = useAuthentication();
    const [submissionVM, setInitialSubmission] = useState(null);
    const [wizardSteps, setWizardSteps] = useState(null);
    const [wizardTitle, setWizardTitle] = useState(null);
    const [skipCompletedSteps, setSkipCompletedSteps] = useState(true);
    const { showAlert } = useModal();
    const translator = useTranslator();

    useEffect(() => {
        const loadSubmission = async () => {
            if (!location.state) {
                history.push('/');
                return;
            }

            const { quoteID, postalCode, isBmsQuote } = location.state.quoteentry;
            setSkipCompletedSteps(!isBmsQuote);
            const { useQuoteDTO } = wizardConfig;
            const requestData = useQuoteDTO ? {
                postalCode: postalCode,
                quoteID: quoteID
            } : quoteID;

            LoadSaveService.retrieveSubmission(requestData, authHeader)
                .then((response) => {
                    if (!_.isUndefined(mockDataDetails)) {
                        return MockUpUtil.cleanUpMockedProperties(
                            response,
                            mockDataDetails.PATH_TO_MOCK_DATA,
                            ...mockDataDetails.MOCK_DATA_TO_REMOVE
                        );
                    }
                    return response;
                })
                .then((response) => {
                    ClausesUtil.getDependentClausesFromServer(
                        ClauseService,
                        _.get(response, 'quoteID'),
                        authHeader
                    );

                    const initialSubmission = viewModelService.create(
                        response,
                        'pc',
                        dtoPath,
                        vmContext
                    );
                    setInitialSubmission(initialSubmission);
                    setWizardTitle(_.get(wizardConfig, 'title'));

                    let steps = _.get(wizardConfig, 'steps');
                    if (_.get(initialSubmission, 'baseData.brand_PV.value.code') === 'pv'
                        || _.get(initialSubmission, 'baseData.accountHolder.hasDummyContextExchangeData_PV.value') === false) {
                        steps = steps.filter((step) => step.id !== 'contact');
                    }

                    const commonStepsProps = _.get(wizardConfig, 'commonStepsProps');
                    if (!_.isUndefined(commonStepsProps)) {
                        steps = steps.map((step) => {
                            step.stepProps = {
                                ...step.stepProps,
                                ...commonStepsProps
                            };
                            return step;
                        });
                    }
                    setWizardSteps(steps);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    const redirectPath = `/quotes/${quoteID}/summary`;
                    const msg = EdgeErrorParser.getErrorMessage(error);

                    return showAlert({
                        title: platformMessages.genericError,
                        message: `${translator(platformMessages.genericErrorMessage)}\n${msg}`,
                        status: 'error',
                        icon: 'mi-error-outline'
                    }).then(() => {
                        history.push(redirectPath);
                    });
                });

        };
        loadSubmission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        submissionVM,
        wizardSteps,
        wizardTitle,
        skipCompletedSteps
    };
};
