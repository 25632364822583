import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { GlobalizationChooser, Icon } from '@jutro/components';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useBreakpoint } from '@jutro/layout';
import { BurgerMenu, BurgerMenuItem, BurgerMenuLink } from '@jutro/router';
import { ServiceManager } from '@jutro/legacy/services';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import SearchField from '../SearchField/SearchField';
import NewQuoteButton from '../NewQuoteButton/NewQuoteButton';
import AvatarMenu from '../AvatarMenu/AvatarMenu';
import metadata from './ApplicationHeader.metadata.json5';
import styles from './ApplicationHeader.module.scss';
import messages from './ApplicationHeader.messages';

import { Button, InputField } from '@jutro/legacy/components';

export default function ApplicationHeader(props) {
    const {
        logoSrc,
        onSearchValueChange,
        onLoadValues,
        searchFieldPlaceholder,
        avatarProps,
        avatarChildren
    } = props.header;
    const { breakpoint } = useBreakpoint({});
    const localeService = ServiceManager.getService('locale-service');
    const translator = useTranslator();
    const [showRef, setShowRef] = useState(false);
    const history = useHistory();
    const gpaReferer = localStorage.getItem('gpaReferer') || history.location.state?.gpaReferer

    useEffect(() => {
        setShowRef(!!gpaReferer);
    }, [gpaReferer]);

    const exitApplication = useCallback(() => {
        window.location.href = decodeURIComponent(gpaReferer);
        localStorage.removeItem('gpaReferer');
    }, [gpaReferer]);

    const [searchFilter, setSearchFilter] = useState(undefined);

    const onSearchclick = useCallback(() => {
        onLoadValues(searchFilter);
    }, [onLoadValues, searchFilter]);

    const onAccountSearchClick = useCallback(() => {
        history.push('/new-quote-account-search');
    }, [history])

    const onLanguageValueChange = useCallback((language) => {
        localeService.saveLanguage(language);
    }, [localeService])

    // burgerContent prop of BurguerMenu component only accepts React nodes,
    // this is why we define the content in the JSX
    const mobileBurgerContent = useMemo(() => {
        return [
            (
                <BurgerMenuItem
                    id="burger-search-menu"
                    key="burger-search-menu"
                    isContainerOnly
                    className={cx(styles.fullWidth)}
                >
                    <div className={cx(styles.inputSearchContainer)}>
                        <InputField
                            id="burgerSearch"
                            onValueChange={setSearchFilter}
                            onEnter={() => onLoadValues(searchFilter)}
                            value={searchFilter}
                            placeholder={searchFieldPlaceholder}
                        />
                        <Icon
                            id="burgerSearchIcon"
                            icon="search"
                            className={cx(styles.searchIcon)}
                            onClick={(event) => { event.stopPropagation(); onLoadValues(searchFilter); }}
                        />
                    </div>
                    <Button type="text" onClick={onAccountSearchClick}>{translator(messages.accountSearch)}</Button>
                </BurgerMenuItem>
            ),
            (
                <BurgerMenuItem
                    id="burger-langugage-menu"
                    key="burger-langugage-menu"
                    isContainerOnly
                >
                    <GlobalizationChooser
                        showLocaleSelect={false}
                        showLanguageLabel={false}
                        containerStyle={styles.narrowGlobalizationChooser}
                        onLanguageValueChange={onLanguageValueChange}
                    />
                </BurgerMenuItem>
            ),
            _.map(props.navigationRoutes, (item, index) => {
                return (
                    <BurgerMenuLink
                        id={`burguer-link-menu-${index}`}
                        key={`burguer-link-menu-${index}`}
                        to={item.path}>
                        {translator(item.title)}
                    </BurgerMenuLink>
                )
            })

        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, searchFilter]);

    const overrideProps = {
        desktopHeaderComponent: {
            visible: breakpoint === 'desktop'
        },
        phoneTabletHeaderComponent: {
            visible: breakpoint !== 'desktop'
        },
        burguerMenu: {
            burgerContent: mobileBurgerContent
        },
        logoImage: {
            src: logoSrc
        },
        searchField: {
            value: searchFilter,
            placeholder: searchFieldPlaceholder
        },
        AvatarMenu: {
            avatarProps,
            avatarChildren
        },
        referrerButton: {
            visible: showRef
        },
        accountSearchLinkComponent: {
            content: messages.accountSearch
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            SearchField,
            NewQuoteButton,
            AvatarMenu,
            BurgerMenu
        },
        resolveCallbackMap: {
            setSearchFilter,
            onSearchValueChange,
            onLoadValues,
            onSearchclick,
            onLanguageValueChange: onLanguageValueChange,
            exitApplication,
            onAccountSearchClick
        }
    };

    return <MetadataContent
        uiProps={metadata.pageContent}
        overrideProps={overrideProps}
        {...resolvers} />
}
