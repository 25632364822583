import { defineMessages } from 'react-intl';

export default defineMessages({
    Gender: {
        id: 'quoteandbind.ppv.views.ppv-contact-details.Gender',
        defaultMessage: 'Gender'
    },
    firstName: {
        id: 'platform.inputs.contact-details.First Name',
        defaultMessage: 'First Name'
    },
    lastName: {
        id: 'platform.inputs.contact-details.Last Name',
        defaultMessage: 'Last Name'
    },
    clientShareEmailAddress: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.Client does not have or does not want to share an e-mail address',
        defaultMessage: 'Client does not have or does not want to share an e-mail address'
    },
    clientReceivePromotions: {
        id: 'quoteandbind.ppv.views.ppv-contract-details.Does the client want to receive promotions via email?',
        defaultMessage: 'Does the client want to receive promotions via email?'
    },
    legalForm: {
        id: 'gateway.views.account.account-summary.Legal Form',
        defaultMessage: 'Legal Form'
    },
    companyName: {
        id: 'gateway.views.account.account-summary.Company Name',
        defaultMessage: 'Company Name'
    },
    enterpriseNumber: {
        id: 'gateway.views.account.account-summary.Enterprise Number',
        defaultMessage: 'Enterprise Number'
    },
    specialTaxRegime: {
        id: 'gateway.views.account.account-summary.Special tax regime',
        defaultMessage: 'Special tax regime'
    },
    workPhone:{
        id: 'gateway.directives.contactphone-details.Work Phone',
        defaultMessage: 'Work Phone'
    },
    mobilePhone:{
        id: 'gateway.directives.contactphone-details.Mobile Phone',
        defaultMessage: 'Mobile Phone'
    },
    homePhone: {
        id: 'gateway.directives.contactphone-details.Home Phone',
        defaultMessage: 'Home Phone'
    }
});
