import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentReceivedLabel:{
        id:'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Payment Received',
        defaultMessage:'Payment Received'
    },
    plannedInvoicesLabel:{
        id:'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Planned Invoices',
        defaultMessage:'Planned Invoices'
    },
    confirmTransactionLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Confirmation the transaction',
        defaultMessage: 'Confirm the transaction'
    },
    cancelLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Cancel',
        defaultMessage: 'Cancel'
    },
    confirmLabel:{
        id:'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Confirm',
        defaultMessage:'Confirm'
    },
    producerBillLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Change to Producer Bill',
        defaultMessage: 'Change to Producer Bill'
    },
    directBillLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Change to Direct Bill',
        defaultMessage: 'Change to Direct Bill'
    },
    invoiceNumberLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.InvoiceNumber',
        defaultMessage: 'Invoice {invoiceNumber}'
    },
    invoiceAmountLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.InvoiceAmount',
        defaultMessage: '({amount}{currency})'
    },
    yesLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Yes',
        defaultMessage: 'Yes'
    },
    noLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.No',
        defaultMessage: 'No'
    }
});
