import React, { Component } from 'react';
import { TranslatorContext, withIntl } from '@jutro/locale';
import { Loader } from '@jutro/components';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import { AccountService } from 'gw-capability-gateway-policy';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { JobUtil, DatatableUtil } from '@xengage/gw-portals-util-js';
import { LocalDateUtil } from 'pv-portals-util-js';
import metadata from './OpenTransactions.metadata.json5';
import styles from './OpenTransactions.module.scss';
import messages from '../Accounts.messages';

import { Link } from '@jutro/router';

function transactionTypeIsViewableInXCenter(transaction) {
    return (
        !_.isUndefined(transaction.type) && transaction.type.toLowerCase() !== 'reinstatement'
        && !_.isUndefined(transaction.type) && transaction.type.toLowerCase() !== 'rewrite'
    );
}

function canUserOpenJobInXCenter(transaction) {
    return transaction.canUserView && transactionTypeIsViewableInXCenter(transaction) && transaction.type !== 'Issuance'
        && transaction.status.toLowerCase() !== 'declined'
        && transaction.status.toLowerCase() !== 'nottaken'
        && transaction.status.toLowerCase() !== 'withdrawn';
}

class AccountOpenTransactions extends Component {
    static propTypes = {
        fromAccountLanding: PropTypes.shape({
            accountDetailsData: PropTypes.shape({
                accountNumber: PropTypes.string
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        intl: PropTypes.func.isRequired,
    };

    static contextType = TranslatorContext;

    state = {
        openTransactionsResponse: '',
        openTransactionsDataTable: '',
        selectedTransactionType: '',
        searchFilter: '',
        responseTransactionData: [],
        isLoading: true
    };

    componentDidMount = () => {
        this.getResponse();
    };

    getResponse = async () => {
        const { authHeader, fromAccountLanding } = this.props;
        const accountDetails = {
            accountNumber: fromAccountLanding.accountDetailsData.accountNumber
        };
        await AccountService.getPolicyTransactionsForAccountByCriteria(
            accountDetails,
            authHeader
        ).then((res) => {
            this.setState(
                {
                    responseTransactionData: res
                },
                () => {
                    this.getData();
                }
            );
        });
    };

    getData = () => {
        const response = this.getOpenTransactionsModelResponse();
        const translator = this.context;
        const filter = {
            type: null,
            value: null
        };
        this.getOpenTransactionsDataTable(response, filter);
        this.setState({
            openTransactionsResponse: response,
            selectedTransactionType: translator(messages.allTransaction),
            isLoading: false
        });
    };

    getOpenTransactionsModelResponse = () => {
        const { responseTransactionData } = this.state;
        const openTransactionsData = responseTransactionData;
        return {
            openTransactionsData: openTransactionsData,
            transactionsOptions: this.getTransactionsOptions()
        };
    };

    getFilterValues = (openTransactionsArrayResult, filter) => {
        return _.filter(openTransactionsArrayResult, (res) => {
            return res.status === filter.value;
        });
    };

    getSearchFilterValues = (openTransactionsArrayResult, filter) => {
        const lowerCaseFilterValue = filter.value.toLocaleLowerCase();
        return _.filter(openTransactionsArrayResult, (res) => {
            return Object.keys(res).some(
                (key) => typeof res[key] === 'string'
                    && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
            );
        });
    };

    getOpenTransactionsDataTable = (openTransactionsData, filter) => {
        const { searchFilter, selectedTransactionType } = this.state;
//         const { intl } = this.props;
        let openTransactionsArrayResult = openTransactionsData.openTransactionsData;
        const combineFilter = {};
        switch (filter.type) {
            case 'statusFilter':
                openTransactionsArrayResult = this.getFilterValues(
                    openTransactionsArrayResult,
                    filter
                );
                if (searchFilter) {
                    combineFilter.value = searchFilter;
                    openTransactionsArrayResult = this.getSearchFilterValues(
                        openTransactionsArrayResult,
                        combineFilter
                    );
                }
                break;
            case 'SearchFilter':
                openTransactionsArrayResult = this.getSearchFilterValues(
                    openTransactionsArrayResult,
                    filter
                );
                if (
                    typeof selectedTransactionType === 'string'
                    && selectedTransactionType !== 'All'
                ) {
                    combineFilter.value = selectedTransactionType;
                    openTransactionsArrayResult = this.getFilterValues(
                        openTransactionsArrayResult,
                        combineFilter
                    );
                }
                break;
            case null:
                if (searchFilter) {
                    combineFilter.value = searchFilter;
                    openTransactionsArrayResult = this.getSearchFilterValues(
                        openTransactionsArrayResult,
                        combineFilter
                    );
                }
                break;
            default:
                openTransactionsArrayResult = openTransactionsData.openTransactionsData;
                break;
        }

        openTransactionsArrayResult = openTransactionsArrayResult
        && openTransactionsArrayResult.length > 0
            ? openTransactionsArrayResult.map((openTransactionsInfo) => {
                const openTransactions = {
                    jobNumber: openTransactionsInfo.jobNumber,
                    status: openTransactionsInfo.status,
//                     effectiveDate: intl.formatDate(
//                         new Date(openTransactionsInfo.policyEffectiveDate),
//                         { year: 'numeric', month: 'short', day: 'numeric' }
//                     ),
                    effectiveDate: openTransactionsInfo.policyEffectiveDate,
                    policyDisplayStatus: openTransactionsInfo.status,
                    type: openTransactionsInfo.displayType,
                    policyNumber: openTransactionsInfo.policyNumber,
                    canUserView: openTransactionsInfo.canUserView,
                    jobType: openTransactionsInfo.type,
                    uwIssueStatus_PV: openTransactionsInfo.uwIssueStatus_PV
                };
                return openTransactions;
            })
            : [];
        openTransactionsArrayResult.sort((a, b) => {
            return b.jobNumber - a.jobNumber;
        });
        this.setState({
            openTransactionsDataTable: openTransactionsArrayResult
        });
    };

    getTransactionsOptions = () => {
        const translator = this.context;
        const transactionsOptionsArray = [
            translator(messages.allTransaction),
            translator(messages.draftTransaction)
        ];
        return transactionsOptionsArray.map((key) => {
            return {
                code: key,
                name: key
            };
        });
    };

    handleFilterChange = (value, id) => {
        const { openTransactionsResponse } = this.state;
        const filter = {
            type: null,
            value: null
        };
        if (id === 'statusFilter') {
            if (value !== 'All') {
                filter.type = 'statusFilter';
                filter.value = value;
            }
            this.setState(
                {
                    selectedTransactionType: value
                },
                () => {
                    this.getOpenTransactionsDataTable(openTransactionsResponse, filter);
                }
            );
        }
        if (id === 'searchFilter') {
            filter.type = 'SearchFilter';
            filter.value = value;
            this.setState(
                {
                    searchFilter: value
                },
                () => {
                    this.getOpenTransactionsDataTable(openTransactionsResponse, filter);
                }
            );
        }
    };

    getLink = (item, index, property) => {
        if (canUserOpenJobInXCenter(item)) {
            if (this.showOpenJobInXCenter(item.jobType)) {
                return (
                    <span>
                        <Link href="/" onClick={() => JobUtil.openJobInXCenter(item.jobNumber)}>
                            {item[property.id]}
                        </Link>
                    </span>
                );
            }
            return (
                <span>
                    <Link to={JobUtil.getJobDetailURLByJobType(item.jobType, item.jobNumber)}>
                        {item[property.id]}
                    </Link>
                </span>
            );
        }
        return <span>{item.jobNumber}</span>;
    };

    showOpenJobInXCenter = (type) => {
        return (
            type !== 'Submission'
            && type !== 'PolicyChange'
            && type !== 'Cancellation'
            && type !== 'Renewal'
        );
    };

    getCell = (items, index, property) => {
        return items[property.id] ? items[property.id] : '-';
    };

    getPolicyNumber = (item, index, property) => {
        return item[property.id] ? (
            <span>
                <Link to={`/policies/${item[property.id]}/summary`}>
                    {item[property.id]}
                </Link>
            </span>
        ) : (
            '-'
        );
    };

    translateUWIssueStatus = (item) => {
        const translator = this.context;
        if (!item.uwIssueStatus_PV) {
            return '-';
        }
        return translator({
            id: `gateway.directives.job-summary.${item.uwIssueStatus_PV}`,
            defaultMessage: item.uwIssueStatus_PV
        });
    };

    render() {
        const { intl } = this.props;
        const {
            openTransactionsResponse,
            openTransactionsDataTable,
            selectedTransactionType,
            searchFilter,
            isLoading
        } = this.state;

        if (isLoading) {
            return <Loader loaded={!isLoading} />;
        }
        const overrides = {
            openTransactionDataTable: {
                data: openTransactionsDataTable
            },
            dropDownFilter: {
                availableValues: openTransactionsResponse.transactionsOptions,
                value: selectedTransactionType
            },
            searchFilter: {
                value: searchFilter
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell: this.getCell,
                getLink: this.getLink,
                getPolicyNumber: this.getPolicyNumber,
                translateUWIssueStatus: this.translateUWIssueStatus,
                handleStatusValueChange: (value) => this.handleFilterChange(value, 'statusFilter'),
                handleSearchValueChange: (value) => this.handleFilterChange(value, 'searchFilter'),
                sortDate: DatatableUtil.sortDate,
                sortString: DatatableUtil.sortString,
                sortNumber: DatatableUtil.sortNumber,
                getDateFormat: (item) => LocalDateUtil.dateFormat_PV(intl, item.effectiveDate)
            }
        };
        const openTransactionPage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
        return <div className={styles.openTransaction}>{openTransactionPage}</div>;
    }
}

export const AccountOpenTransactionsComponent = AccountOpenTransactions;
export default withIntl(withAuthenticationContext(AccountOpenTransactions));
