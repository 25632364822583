import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { BusinessConstant } from 'pv-portals-util-js';
import metadata from './ContractEffectiveDate.metadata.json5';

const findCoverage = (cov, type) => {
    const foundCoverage = (type === "TPL" && (cov.value.publicID === BusinessConstant.PPV_THIRD_PARTY_LIABILITY_COV_PV || cov.value.publicID === BusinessConstant.PCV_THIRD_PARTY_LIABILITY_COV_PV) ) ||
    ( type === "Legal" && (cov.value.publicID === BusinessConstant.PPV_LEGAL_PROTECTION_COV_PV || cov.value.publicID === BusinessConstant.PCV_LEGAL_PROTECTION_COV_PV ));
    return foundCoverage && cov.selected.value === true;
};

function ContractEffectiveDate(props) {
    const {
        id,
        data,
        type,
        index,
        onValidate,
        onValueChange,
        vehicleCoveragesPath
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
        return () => {
            onValidate(true, id);
        };
    }, [id, isComponentValid, onValidate]);

    const writeValue = useCallback((value) => {
        const findIndex = data.coverages.children.findIndex((cov) => findCoverage(cov, type));
        onValueChange(value, `${vehicleCoveragesPath}[${index}]`, findIndex);
    }, [onValueChange, data, type, index, vehicleCoveragesPath]);

    const getCoveragePath = useCallback(() => {
        return data.coverages.children.find((cov) => findCoverage(cov, type));
    }, [data, type]);

    const coverageVM = getCoveragePath();

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        effectiveDate: {
            label: _.get(data, "value.vehicleName")
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={coverageVM}
            onValueChange={writeValue}
            onValidationChange={setComponentValidation}
            overrideProps={overrideProps}
        />
    );
}

ContractEffectiveDate.propTypes = {
    data: PropTypes.shape({}),
    path: PropTypes.string,
    type: PropTypes.string
};

ContractEffectiveDate.defaultProps = {
    submissionVM: {},
    path: undefined,
    type: undefined
};

export default ContractEffectiveDate;
