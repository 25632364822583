import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class GatewayAvailabilityService {
    static checkAvailabilityBasedOnAddress(availabilityRequestDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayavailability'),
            'isProductAvailableBasedOnAddress',
            [availabilityRequestDTO],
            additionalHeaders
        );
    }

    // P&V functions

    static getAvailableBillingMethodForProducerCode(producerCode, productCode, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayavailability'),
            'getAvailableBillingMethodForProducerCode_PV',
            [producerCode, productCode],
            additionalHeaders
        );
    }
}
