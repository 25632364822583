import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as platformMessagesPV } from 'pv-platform-translations';

import ProducerComponent from '../ProducerComponent/ProducerComponent';
import metadata from './ProfessionalComponent.metadata.json5';
import styles from './ProfessionalComponent.module.scss';
import './ProfessionalComponent.messages';

function ProfessionalComponent(props) {

    const {
        id,
        labelPosition,
        showRequired,
        showErrors,
        submissionVM,
        updateSubmissionVM,
        producerCodeValue,
        onValidate,
        isAnExistingAccount,
        accountHolderViewVM,
        updateAccountHolderVM,
        accountProducerCodes
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const writeValue = useCallback(
        (value, path) => {
            const updateToAccount = ['independentOrLiberalProfession_PVQuestion', 'deductVatForVehicle_PVQuestion', 'enterpriseNumber_PV'];
            if (!isAnExistingAccount && updateToAccount.includes(path)) {
                const correctPath = path.replace('Question', '');
                _.set(accountHolderViewVM, correctPath, value);
                updateAccountHolderVM(accountHolderViewVM);
            }
            if (path.includes('independentOrLiberalProfession_PVQuestion')) {
                if (value === true) {
                    _.set(submissionVM, 'deductVatForVehicle_PVQuestion', false);
                    _.set(accountHolderViewVM, 'deductVatForVehicle_PV', false);
                } else {
                    _.set(submissionVM, 'deductVatForVehicle_PVQuestion', undefined);
                    _.set(accountHolderViewVM, 'deductVatForVehicle_PV', undefined);
                }
                updateAccountHolderVM(accountHolderViewVM);
            }
            _.set(submissionVM, path, value);
            updateSubmissionVM(submissionVM);
        },
        [submissionVM, updateSubmissionVM, isAnExistingAccount, accountHolderViewVM, updateAccountHolderVM]
    );

    const overrideProps = {
        '@field': {
            labelPosition,
            showRequired,
            showErrors
        },
        liberalProfessionContainer: {
            visible: _.get(accountHolderViewVM, 'subtype.value') === 'Person'
        },
        existingProducerComponent: {
            submissionVM,
            producerCodeValue,
            updateSubmissionVM,
            isAnExistingAccount,
            accountHolderViewVM,
            updateAccountHolderVM,
            accountProducerCodes,
            visible: isAnExistingAccount
        },
        deductVatForVehicle: {
            visible: _.get(submissionVM, 'independentOrLiberalProfession_PVQuestion.value', false)
        },
        enterpriseNumber: {
            visible: _.get(submissionVM, 'independentOrLiberalProfession_PVQuestion.value', false) &&  _.get(submissionVM, 'deductVatForVehicle_PVQuestion.value', false),
            validationMessages: _.get(submissionVM, 'enterpriseNumber_PV.value') === "0000000000" ? [platformMessagesPV.enterpriseError] : _.get(submissionVM, `enterpriseNumber_PV.aspects.validationMessages`)
        },
        policyNumber: {
            visible: _.get(submissionVM, 'hasPredecessorPolicy_PV.value', false)
        },
        effDate: {
            visible: _.get(submissionVM, 'hasPredecessorPolicy_PV.value', false)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            producerComponent: ProducerComponent
        },
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}
ProfessionalComponent.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({})
    }),
    updateSubmissionVM: PropTypes.func,
    labelPosition: PropTypes.string,
    showRequired: PropTypes.bool,
    accountHolderViewVM: PropTypes.shape({
        value: PropTypes.shape({})
    }),
    updateAccountHolderVM: PropTypes.func,
    producerCodeValue: PropTypes.array,
    onValidate: PropTypes.func,
    isAnExistingAccount: PropTypes.bool,
    showErrors: PropTypes.bool
};

ProfessionalComponent.defaultProps = {
    submissionVM: {},
    updateSubmissionVM: undefined,
    labelPosition: 'left',
    showRequired: true,
    accountHolderViewVM: {},
    updateAccountHolderVM: undefined,
    producerCodeValue: [],
    onValidate: undefined,
    isAnExistingAccount: false,
    showErrors: false
};
export default ProfessionalComponent;
