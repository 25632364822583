import React, { useEffect, useState, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import { useModal, ModalNextEmitter } from '@jutro/components';
// import { useBreakpoint } from '@jutro/layout';
import './App.scss';
import { ViewModelServiceFactory } from '@xengage/gw-portals-viewmodel-js';
import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { policyJobComponentMap, policyJobComponents } from 'gw-capability-policyjob-react';
import { policyJobComponentMapPV, policyJobComponentsPV } from 'pv-capability-policyjob-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { AccurateBreakpointPropagation } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { routeConfirmationModal, DropdownMenuLink } from '@jutro/router';
import vmTranslator, {messages as commonMessages} from '@xengage/gw-platform-translations';
import {withMaintenancePage} from '@xengage/gw-portals-maintenance-react';
import {
    platformComponents,
    platformComponentMap,
    ImageComponent,
    KnockOutPage,
} from 'gw-components-platform-react';
import PELandingPage, {
    Accounts,
    BmsQuote,
    Policies,
    Activities,
    Analytics,
    AccountDetails,
    PolicyDetails,
    NewQuoteAccountSearch,
    NewQuotePage,
    SearchResults,
    QuoteDetails,
    Renewal,
    Endorsement,
    Cancellation,
    AccountBillingAndPayment,
    MakePayment,
    ContactUnderwriter,
    PaymentConfirmation,
    producerEngageComponentMap,
    producerEngageAdditionalComponents
} from 'gw-capability-gateway-react';
import { PolicyTransactionPage } from 'gw-policytransaction-react';
import { SelectProducerContextProvider } from 'gw-gateway-common-react';
import { Redirect, useHistory, Router } from 'react-router-dom';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';


import {
    useAuthentication,
    withAuthenticationProvider
} from '@xengage/gw-digital-auth-react';
import { BrandingUtil as BrandingService } from 'pv-portals-util-js';
import { GatewayAvailabilityService } from 'gw-capability-gateway-policycommon';
import { GatewayRenewalService } from 'gw-capability-gateway-policyrenewal';
import { GatewayEndorsementService } from 'gw-capability-gateway-policychange';
import { LoadSaveService, CustomQuoteService } from 'gw-capability-gateway-quoteandbind';
import { QuickQuoteService } from 'gw-capability-quoteandbind';
import { UserService, SubmissionService, JobService, SearchService } from 'gw-capability-gateway';
import { GatewayClaimService } from 'gw-capability-gateway-claim';
import { GatewayBillingService } from 'gw-capability-gateway-billing';
import { GatewayFNOLService } from 'gw-capability-gateway-fnol';
import { AccountService } from 'gw-capability-gateway-policy';
import { GatewayDocumentService } from 'gw-capability-gateway-document';

import { GatewaySpreadsheetService } from 'gw-capability-gateway-spreadsheet';
import { ProducerInfoService } from 'gw-capability-profileinfo';
import { WizardPageTemplateWithTitle } from 'gw-portals-wizard-components-ui';
import { AddressLookupComponentMap, AddressLookupComponents } from 'gw-capability-address-react';
import { VehicleInfoLookupComponentMap, VehicleInfoLookupComponents } from 'gw-capability-vehicleinfo-react';
import { GatewayClaimDocumentService } from 'gw-capability-gateway-claimdocument';
import { filterCapabilityRoutes } from '@xengage/gw-portals-config-js';
import '@jutro/legacy/dataview';
import '@jutro/legacy/address';
import { PolicyDocumentError } from 'gw-capability-document-react';
import BaseFNOLWizard, { ClaimsConfirmationPage } from 'gw-capability-fnol-common-react';
import { ContactAgentPage, Preferences } from 'gw-pages-platform-react';
import FNOLHOWizard from 'gw-capability-fnol-ho-react';
import FNOLPAWizard from 'gw-capability-fnol-pa-react';
import FNOLCAWizard from 'gw-capability-fnol-ca-react';
import FNOLCPWizard from 'gw-capability-fnol-cp-react';
import FNOLWCWizard from 'gw-capability-fnol-wc-react';
import FNOLBOPWizard from 'gw-capability-fnol-bop-react';
import FNOLGeneralWizard from 'gw-capability-fnol-gl-react';
import { PPVWizard } from 'pv-capability-gateway-quoteandbind-ppv-react';
import { PCVWizard } from 'pv-capability-gateway-quoteandbind-pcv-react';
import { QQPPVWizard } from 'pv-capability-gateway-quoteandbind-qqppv-react';
import { QQPCVWizard } from 'pv-capability-gateway-quoteandbind-qqpcv-react';
import { PPVPolicyChangeWizard } from 'pv-capability-policychange-ppv-react';
import { PCVPolicyChangeWizard } from 'pv-capability-policychange-pcv-react';
import FaqPage from 'gw-capability-faq-react';
import { ClaimDetails } from 'gw-capability-claim-react';
import { ClaimsLanding } from 'gw-capability-gateway-claim-react';
import { CommissionLanding } from 'gw-capability-gateway-commission-react';
import { checkMaintenanceMode } from "gw-maintenance-js";
import appConfig from 'app-config';


import {
    platformComponents as platformComponentsPV,
    platformComponentMap as platformComponentMapPV
} from 'pv-components-platform-react';

import ApplicationHeader from '../components/ApplicationHeader/ApplicationHeader';

import messages from './App.messages';
import appRoutes from './App.routes.metadata.json5';
import { AppFloorPlan } from '@jutro/legacy/floorplan';


const { capabilitiesConfig, env, gatewayAnalytics, applicationDetails } = appConfig;

const componentMap = {
    landingpage: PELandingPage,
    accounts: Accounts,
    bmsquote: BmsQuote,
    policies: Policies,
    activities: Activities,
    analytics: Analytics,
    accountdetails: AccountDetails,
    policydetails: PolicyDetails,
    newquoteaccountsearch: NewQuoteAccountSearch,
    newquotepage: NewQuotePage,
    searchresults: SearchResults,
    quotedetails: QuoteDetails,
    renewal: Renewal,
    endorsement: Endorsement,
    cancellation: Cancellation,
    accountbillingandpayment: AccountBillingAndPayment,
    makepayment: MakePayment,
    contactunderwriter: ContactUnderwriter,
    paymentconfirmation: PaymentConfirmation,
    basefnolwizard: BaseFNOLWizard,
    claimsconfirmationpage: ClaimsConfirmationPage,
    contactagentpage: ContactAgentPage,
    preferences: Preferences,
    fnolhowizard: FNOLHOWizard,
    fnolpawizard: FNOLPAWizard,
    fnolcawizard: FNOLCAWizard,
    fnolcpwizard: FNOLCPWizard,
    fnolwcwizard: FNOLWCWizard,
    fnolbopwizard: FNOLBOPWizard,
    fnolgeneralwizard: FNOLGeneralWizard,
    peppvWizard: PPVWizard,
    pepcvWizard: PCVWizard,
    peqqppvWizard: QQPPVWizard,
    peqqpcvWizard: QQPCVWizard,
    ppvpolicychangewizard: PPVPolicyChangeWizard,
    pcvpolicychangewizard: PCVPolicyChangeWizard,
    faqpage: FaqPage,
    knockoutpage: KnockOutPage,
    claimdetails: ClaimDetails,
    claimslanding: ClaimsLanding,
    commissionlanding: CommissionLanding,
    policydocumenterror: PolicyDocumentError,
    policytransactionpage: PolicyTransactionPage,
};

setComponentMapOverrides({
    ...platformComponentMap,
    ...platformComponentMapPV,
    ...AddressLookupComponentMap,
    ...VehicleInfoLookupComponentMap,
    ...producerEngageComponentMap,
    ...policyJobComponentMap,
    ...policyJobComponentMapPV,
    WizardPageTemplateWithTitle: { component: 'WizardPageTemplateWithTitle' },
    // replace the native IMG component with a proxied version
    img: { component: 'img' }
},
{
    ...platformComponents,
    ...platformComponentsPV,
    ...AddressLookupComponents,
    ...VehicleInfoLookupComponents,
    ...producerEngageAdditionalComponents,
    ...policyJobComponents,
    ...policyJobComponentsPV,
    WizardPageTemplateWithTitle,
    img: ImageComponent
});

const defaultRoutes = filterCapabilityRoutes(capabilitiesConfig, appRoutes.routes);

const getUserAuthData = (authData) => UserService.getGatewayCurrentUser(authData.authHeader).catch(() => {
        if(authData) {
            authData.logout();
        }
    });

const prefix = _.get(env, 'DEPLOYMENT_PATH', '').replace(/\/$/, '');
let logoSrc = `${prefix}/branding/pv-logo.svg`;

if (BrandingService.getBrandingTheme().name === "vivium") {
    logoSrc = `${prefix}/branding/viv-logo.svg`;
}

const modalEmitter = new ModalNextEmitter();

function App() {
    const {
        showAlert
    } = useModal();
    const history = useHistory();
    const auth = useAuthentication();
    const translator = useTranslator();
    const [viewModelService, updateViewModelService] = useState(undefined);
    const [permissionCheck, updatePermissionCheck] = useState(false);
    const [routes, updateRoutes] = useState(defaultRoutes);

/*
    const { breakpoint } = useBreakpoint({});
    const getLogoSrc = useCallback(() => {
        let logoName = applicationDetails.applicationLogo;

        if(breakpoint === 'phone' || breakpoint === 'phoneWide'){
            logoName = applicationDetails.applicationLogoMobile;
        }

        const prefix = _.get(env, 'DEPLOYMENT_PATH', '').replace(/\/$/, '');

        return `${prefix}/${logoName}`;
    }, [breakpoint]);
*/

    useEffect(
        () => {
            const translatorFn = vmTranslator(translator);

            import(
                /* webpackChunkName: "product-metadata" */

                'product-metadata'
            ).then((productMetadata) => {
                if(!productMetadata || _.isEmpty(productMetadata.default)) {
                    throw new Error(translator(messages.errorMetadataMessage));
                }

                const { default: result } = productMetadata;

                updateViewModelService(
                    ViewModelServiceFactory.getViewModelService(result, translatorFn)
                );
            });
        },
        [translator]
    );

    const handleError = useCallback((error = {}) => {
        const state = {
            pathname: '/',
            state: error
        };

        return showAlert({
            title: messages.error,
            message: messages.errorMessage,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok
        }).then(
            () => <Redirect to={state} />,
            _.noop
        );
    }, [showAlert]);

    // returning Blank array to remove default bordertop solid line
    const avatarLinks = useMemo(() => [
        //     Below FAQ link hidden for story PV-7711
        //     <DropdownMenuLink key="faq" to="/faq">{translator(messages.faq)}</DropdownMenuLink>,
        //     Below hidden for June Release story PV-18626
        //     <DropdownMenuLink key="preferences" to="/preferences">{translator(messages.preferences)}</DropdownMenuLink>,
        // ], [translator]);
    ], []);

    const checkViewCommissionsAnalyticsPermission = useCallback(() => {
        const commissionsDTO = {
            permission: 'viewcommissions'
        };

        const reportsDTO = {
            permission: 'gpaviewreports'
        };

        if(_.isEmpty(auth.authHeader) || permissionCheck) {
            return;
        }

        updatePermissionCheck(true);

        const commissionPermission = UserService.hasUserSystemPermission(commissionsDTO, auth.authHeader);

        const analyticsPermission = UserService.hasUserSystemPermission(reportsDTO, auth.authHeader);

        Promise.all([commissionPermission, analyticsPermission])
            .then(([hasCommission, hasAnalytics]) => {

                const routesToBeUpdated = ['/commission', '/analytics'];
                let updatedRoutes = 0;

                for (const route of routes) {
                    if (routesToBeUpdated.includes(route.path)) {
                        updatedRoutes += 1;
                        route.showOnNavBar = (
                            route.path === '/commission' ? hasCommission : gatewayAnalytics && hasAnalytics
                        );
                    }

                    if(routesToBeUpdated.length === updatedRoutes) {
                        break;
                    }
                }

                updateRoutes([...routes]);
            });


    }, [auth.authHeader, permissionCheck, routes]);

    const onSearchValueChange = useCallback((selectedValue) => {
        const searchText = _.get(selectedValue, 'id', '');

        if (!_.isEmpty(searchText)){
            let redirectPath = `/search/${searchText}`;

            if (_.get(selectedValue, 'type', '') === 'account') {
                redirectPath = `/accounts/${searchText}/summary`;
            }

            if (_.get(selectedValue, 'type', '') === 'policy') {
                redirectPath = `/policies/${searchText}/summary`
            }

            if (_.get(selectedValue, 'type', '') === 'PolicyChange') {
                redirectPath = `/change/${searchText}/summary`
            }

            if (_.get(selectedValue, 'type', '') === 'Submission') {
                redirectPath = `/quotes/${searchText}/summary`
            }

            if (_.get(selectedValue, 'type', '') === 'Cancellation') {
                redirectPath = `/cancellation/${searchText}/summary`
            }

            if (_.get(selectedValue, 'type', '') === 'Renewal') {
                redirectPath = `/renewal/${searchText}/summary`
            }

            if (_.get(selectedValue, 'type', '') === 'Issuance') {
                redirectPath = `/policies/${searchText}/summary`
            }

            history.push(redirectPath);
        }
    }, [history]);

    /*
    const formatSearchValues = useCallback((displayName, id, type) => {
        const availableObject = {
            displayName,
            id,
            type
        };

        return availableObject;
    }, []);
    */

    const handleLoadValues = useCallback(async (searchParam) => {
        const searchObj = {
            previousSearchParam: '',
            searchParam
        };
        /*
        const displayName = translator(messages.viewAll, { Number: searchParam});
        const viewAll = formatSearchValues(displayName, searchParam, '');
        const searchResults = await SearchService.search(searchObj, auth.authHeader);
        const availableValues = [viewAll];

        if (!_.isEmpty(searchResults)) {
            const { accounts, policies } = searchResults;

            if (!_.isEmpty(accounts)) {
                _.each(accounts, (account) => {
                    availableValues.push(formatSearchValues(`${account.accountHolder} - ${account.accountNumber}`, account.accountNumber, 'account'));
                })
            }

            if (!_.isEmpty(policies)) {
                _.each(policies, (policy) => {
                    availableValues.push(formatSearchValues(`${policy.accountNumber} - ${policy.policyNumber}`, policy.policyNumber, 'policy'));
                })
            }
        }

        return availableValues;
        */
        const searchResults = await SearchService.search(searchObj, auth.authHeader);
                const { accounts, policies } = searchResults;

                const obj = { displayName: '', id: searchParam, type: '' };

                if (accounts.length === 1) {
                    obj.id = accounts[0].accountNumber;
                    obj.type = 'account';
                } else if (policies.length === 1) {
                    if(searchParam === policies[0].policyNumber) {
                        obj.id = policies[0].policyNumber;
                        obj.type = 'policy';
                    } else {
                        obj.id = policies[0].jobNumber;
                        obj.type = policies[0].jobType_PV;
                    }
                }

                onSearchValueChange(obj);
    }, [auth.authHeader, onSearchValueChange]);

    const renderCustomHeader = (componentProps) => <ApplicationHeader {...componentProps} />;


    const floorPlansConfig = useMemo(() => [{
            scrollContent: false,
            showFooter: false,
            routes,
            renderHeader: 'renderCustomHeader',
            header: {
                avatarChildren: avatarLinks,
                avatarProps: {
                    username: auth.userInfo.name,
                    title: auth.userInfo.name,
                    subtitle: auth.userInfo.email
                },
                className: 'appHeader',
                logoSrc,
                onSearchValueChange,
                onLoadValues: handleLoadValues,
                showAppSwitcher: false,
                showNotifications: false,
                searchFieldPlaceholder: messages.searchPlaceHolder,
                searchOptionTypes:[
                    {
                      "type": "account",
                      "icon": "gw-account-box",
                      "displayName": translator(messages.account)
                    },
                    {
                      "type": "policy",
                      "icon": "gw-policy",
                      "displayName": translator(messages.policy)
                    },
                    {
                        "type": "",
                        "displayName": " "
                    }
                  ]
            }
        }], [auth, avatarLinks, handleLoadValues, onSearchValueChange, routes, translator]);

    useEffect(() => {
        checkViewCommissionsAnalyticsPermission();
    }, [checkViewCommissionsAnalyticsPermission]);

    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider
                value={{
                    LoadSaveService,
                    CustomQuoteService,
                    ClaimService: GatewayClaimService,
                    ClaimDownloadService: GatewayClaimDocumentService,
                    FNOLService: GatewayFNOLService,
                    AvailabilityService: GatewayAvailabilityService,
                    RenewalService: GatewayRenewalService,
                    DocumentService: GatewayDocumentService,
                    SpreadsheetService: GatewaySpreadsheetService,
                    EndorsementService: GatewayEndorsementService,
                    AccountService,
                    BillingService: GatewayBillingService,
                    UserService,
                    ContactService: ProducerInfoService,
                    quoteUnderWritingService: {
                        referToUnderwriter: SubmissionService.referToUnderwriter,
                        withdrawJobByJobNumber: JobService.withdrawJobByJobNumber,
                        approveUnderwritingIssue: LoadSaveService.approveUnderwritingIssue
                    },
                    QuickQuoteService
                }}
            >
                <ViewModelServiceContext.Provider value={viewModelService}>
                    <ErrorBoundary onError={handleError}>
                        <SelectProducerContextProvider>
                            <Router history={history} basename="/producer-engage" getUserConfirmation={(message,callback)=>routeConfirmationModal(message,callback,modalEmitter)}>
                                <AppFloorPlan
                                    componentMap={componentMap}
                                    floorPlans={floorPlansConfig}
                                    renderHeader={renderCustomHeader}
                                    callbackMap={{
                                        renderCustomHeader
                                    }}
                                />
                            </Router>
                        </SelectProducerContextProvider>
                    </ErrorBoundary>
                </ViewModelServiceContext.Provider>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
}

export default withAuthenticationProvider({onAuthDataCreation: getUserAuthData})(withMaintenancePage({appConfig, checkMaintenanceMode})(App));
