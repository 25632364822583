import { Loader } from '@jutro/components';
import { getConfigValue } from '@jutro/config';
import { MicroFrontend } from '@jutro/micro-frontends';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { useRouteMatch } from 'react-router-dom';

/**
 * A wrapper component surrounding the Policy Transaction microapp
 *
 * @memberof module:gw-microapp-policytransaction-react
 * @function PolicyTransactionMicroAppWrapper
 *
 * @param {Object} schema Experience schema to be rendered in the PolicyTransaction micro-app
 * @param {Object} metadata Jutro metadata to be rendered in the PolicyTransaction micro-app
 * @param {string} productCode PolicyCenter product code
 * @param {string} jobId Job Public Id of the pre-generated submission in PolicyCenter
 * @param {string} accountId Account Public Id
 * @param {string} googleMapsApiKey Google Map Api Key, pass to enable address lookup in the PolicyTransaction micro-app
 *
 * @returns {ReactElement}
 */

export const PolicyTransactionMicroAppWrapper = ({
    schema,
    metadata,
    productCode,
    jobId,
    accountId,
    handleError,
    googleMapsApiKey,
}) => {
    const pcApiURL = getConfigValue('PC_API_URL');
    const match = useRouteMatch();

    return (
        <Suspense fallback={<Loader />}>
            <Loader loaded={!!metadata}>
                <ErrorBoundary onError={handleError}>
                    <MicroFrontend
                        src={getConfigValue('policy-transaction')}
                        jutro={{
                            launchPropOverrides: {
                                routerBasename: match.url,
                            },
                        }}
                        id="policy-transaction"
                        schema={schema}
                        uiMetadata={metadata}
                        dynamicLink={pcApiURL}
                        relativeLocationPath={match.url}
                        product={productCode}
                        jobId={jobId}
                        accountId={accountId}
                        googleMapsApiKey={googleMapsApiKey}
                    />
                </ErrorBoundary>
            </Loader>
        </Suspense>
    );
};

PolicyTransactionMicroAppWrapper.propTypes = {
    schema: PropTypes.object,
    metadata: PropTypes.object,
    productCode: PropTypes.string.isRequired,
    submissionId: PropTypes.string,
    googleMapsApiKey: PropTypes.string,
    handleError: PropTypes.func,
};
