import { defineMessages } from 'react-intl';

export default defineMessages({
    vinMatchedMsg: {
        id: 'quoteandbind.ppv.controllers.PPVVehicleDetailsCtrl.VinMatchMsg',
        defaultMessage: 'Sorry, but the VIN of {firstVehicle} matches that of {secondVehicle}. Please ensure that all VINs are unique.'
    },
    vinNotUnique: {
        id: 'quoteandbind.ppv.controllers.PPVVehicleDetailsCtrl.VIN is not unique',
        defaultMessage: 'VIN is not unique'
    },
    whatistheenginecapacityofyourvehiclecc: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-vehicle.What is the engine capacity of your vehicle? (cc)',
        defaultMessage: 'What is the engine capacity of your vehicle? (cc)'
    },
    vehiclePremium: {
        id: 'quoteandbind.common.views.qq-policy-info.Total premium',
        defaultMessage: 'Total annual premium vehicle: {premium}'
    }
});
