import React from 'react';
import { ServiceManager } from '@jutro/legacy/services';
import { CurrencyField } from '@jutro/legacy/components';
import CurrencyValue_PV from '../../../../producer-engage/src/components/CurrencyValue_PV/CurrencyValue_PV';

function Currency(props) {
    const localeService = ServiceManager.getService('locale-service');
    return (
        <CurrencyValue_PV
            {...props}
            defaultCurrency={localeService.getDefaultCurrencyCode()}
        />
    );
}

Currency.propTypes = CurrencyField.propTypes;
Currency.defaultProps = CurrencyField.defaultProps;

export default Currency;
