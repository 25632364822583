import _ from 'lodash';

// copy from digitalportals\applications\common\capabilities-react\gw-capability-policychange-common-react\models\Driver.js
const nameConfig = {
    formatMode: 'default',
    mode: 'default',
    visibleFields: ['Suffix', 'FirstName', 'Prefix', 'LastName', 'Middlename', 'Name']
};

function append(sb, fieldId, value) {
    if (!_.isEmpty(value) && _.includes(nameConfig.visibleFields, fieldId)) {
        sb.push(value);
    }
}

function format(person, delimiter) {
    const lastName = person.lastNameKanji || person.lastName;
    const firstName = person.firstNameKanji || person.firstName;
    const result = [];
    switch (nameConfig.formatMode) {
        case 'default':
            append(result, 'FirstName', firstName);
            append(result, 'LastName', lastName);

            break;
        case 'France':
            append(result, 'LastName', lastName);
            append(result, 'FirstName', firstName);
            break;
        case 'Japan':
            append(result, 'LastName', lastName);
            append(result, 'FirstName', firstName);
            break;
        case 'China':
            append(result, 'LastName', lastName);
            append(result, 'FirstName', firstName);
            break;
        default:
            break;
    }
    return result.join(delimiter);
}


function getDisplayName(person, translator) {
    return format(person, ' ') || translator({id: 'quoteandbind.controllers.this driver', defaultMessage: 'this driver'});
};

export default {
    getShortDisplayName: getDisplayName

};
