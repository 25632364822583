import { defineMessages } from 'react-intl';

export default defineMessages({
    insureVehicleMsgPL: {
        "id": "quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Under the same contract, you can insure several vehicles",
        "defaultMessage": "Under the same contract, you can insure several vehicles (of which 1 vehicle Tourism and Business) with one main driver for all vehicles, but potentially different occasional drivers."
    },
    insureVehicleMsgCL: {
        "id": "quoteandbind.pcv.directives.templates.pcv-edit-vehicle-type.Under the same contract maximum 10 vehicles",
        "defaultMessage": "Under the same contract, the contract can insure several vehicles with (if applicable) 1 main driver for all vehicles, but potentially different occasional drivers."
    },
});
