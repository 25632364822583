const TYPEKEY_PREFIX = 'typekey.DelinquencyLevel_PV.';
const CUSTOM_TYPEKEY_PREFIX = 'gateway.views.policy-detail.Delinquent.';
const EXPIRED = 'expired';
const MANUAL_CANCELLATION = 'manualCancellation';
const NORMAL_CANCELLATION = 'normalCancellation';

const getDelinquencyLevel = (delinquencyLevel, defaultLabel) => {
    let label = defaultLabel;
    if (delinquencyLevel) {
        if (delinquencyLevel === EXPIRED || delinquencyLevel === MANUAL_CANCELLATION || delinquencyLevel === NORMAL_CANCELLATION) {
            label = {id : CUSTOM_TYPEKEY_PREFIX.concat(delinquencyLevel), defaultMessage : delinquencyLevel};
        } else {
            label = {id : TYPEKEY_PREFIX.concat(delinquencyLevel), defaultMessage : delinquencyLevel};
        }
    }
    return label;
};

const getDelinquencyDate = (delinquencyDate, intl) => {
    const dateToConvert = delinquencyDate !== undefined ? new Date(delinquencyDate) : new Date();
    return intl.formatDate(dateToConvert, { year: 'numeric', month: 'short', day: 'numeric' });
};

export default {
    getDelinquencyLevel: getDelinquencyLevel,
    getDelinquencyDate: getDelinquencyDate,

}
