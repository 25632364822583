/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { VehicleUtil, CoverageUtil, VirtualProductUtil } from 'pv-portals-util-js';
import { messagesPolicyJob } from 'pv-capability-policyjob-react';
import metadata from './VehicleInfo.metadata.json5';
import styles from './VehicleInfo.module.scss';
import messages from './VehicleInfo.messages.js';

function VehicleInfo(props) {
    const {
        data: vehicleVM,
        submissionVM,
        pageLoBType,
        quickQuoteMode
    } = props;
    const vpVersionChecker = VirtualProductUtil.getVersionChecker(submissionVM);
    const translator = useTranslator();
    const vehicleCoverages = _.get(submissionVM, `lobData.${pageLoBType}.offerings.value[0].coverages.vehicleCoverages`, []);
    const vehicleCoverage = _.find(vehicleCoverages, (vehicle) => vehicle.publicID === vehicleVM.publicID.value);
    const categoryName  = _.get(vehicleVM,'category.value.code');
    const vehicleType = _.get(vehicleVM,'type.value.code');

    const { isOldTimerAndClassic, isUBIAvailable, securitySystemEnabled } = VehicleUtil;

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        coverableType: {
            label: quickQuoteMode ? translator(messages.coverableType) : translator(messages.modelType)
        },
        makeAndModel: {
            value: `${_.get(vehicleVM, 'make.value')} ${_.get(vehicleVM, 'model.value')}`
        },
        insuredValue: {
            visible: !!_.get(vehicleVM, 'insuredValue.amount.value')
        },
        engineCapacity_PV: {
            label: quickQuoteMode ? translator(messagesPolicyJob.whatistheenginecapacityofyourvehiclecc) : translator(messages.engineCapacity)
        },
        gearBox: {
            visible: !!_.get(vehicleVM, 'gearBox.value.name'),
        },
        securitySystemInstalledContainer: {
            visible: securitySystemEnabled(
                _.get(vehicleVM,'automaticEmergencyBraking.value'),
                _.get(vehicleVM,'laneDepartureWarningSystem.value'),
                _.get(vehicleVM,'driverDrowsinessDetection.value'),
                _.get(vehicleVM,'adaptiveCruiseControl.value'),
                _.get(vehicleVM,'blindSpotSystem.value'),
                _.get(vehicleVM,'cyclAndPedestrDetectSystem.value')
            )
        },
        automaticEmergencyBraking: {
            visible: !!_.get(vehicleVM, 'automaticEmergencyBraking.value')
        },
        laneDepartureWarningSystem: {
            visible: !!_.get(vehicleVM, 'laneDepartureWarningSystem.value')
        },
        driverDrowsinessDetection: {
            visible: !!_.get(vehicleVM, 'driverDrowsinessDetection.value')
        },
        adaptiveCruiseControl: {
            visible: !!_.get(vehicleVM, 'adaptiveCruiseControl.value')
        },
        blindSpotSystem: {
            visible: !!_.get(vehicleVM, 'blindSpotSystem.value')
        },
        cyclAndPedestrDetectSystem: {
            visible: !!_.get(vehicleVM, 'cyclAndPedestrDetectSystem.value')
        },
        vinInput: {
            visible: !!_.get(vehicleVM, 'vin.value')
        },
        ubi: {
            visible: isUBIAvailable(_.get(submissionVM, 'baseData.isUbiEnabled.value'), categoryName, vehicleType) && !isOldTimerAndClassic(categoryName)
        },
    };

    // need deep merge, not shallow (operator ... doesn't work)
    _.merge(overrideProps, VehicleUtil.visibleFields(vehicleVM, quickQuoteMode, _.get(submissionVM, 'baseData.jobType.value.code'), _.get(submissionVM, 'baseData.productCode.value'), vpVersionChecker));
    _.merge(overrideProps, CoverageUtil.visibleFields({ vehicleVM, coverages: _.get(vehicleCoverage, 'coverages', []), quickQuoteMode, vpVersionChecker }));
    _.merge(overrideProps, { optionsValue: { visible: (_.get(vehicleVM, 'optionsValue.amount.value') > 0) &&  overrideProps.optionsValue.visible}, accessoriesValue: { visible: (_.get(vehicleVM, 'accessoriesValue.amount.value') > 0) && overrideProps.accessoriesValue.visible } });

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

VehicleInfo.propTypes = {
    data: PropTypes.shape({}),
    submissionVM: PropTypes.shape({}),
    pageLoBType: PropTypes.string,
    quickQuoteMode: PropTypes.bool
};

VehicleInfo.defaultProps = {
    data: {},
    submissionVM: {},
    pageLoBType: undefined,
    quickQuoteMode: false
};

export default VehicleInfo;
