import React, { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { SearchService } from 'gw-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { withRouter } from 'react-router-dom';

import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './SearchResults.metadata.json5';

import styles from './SearchResults.module.scss';
import messages from './SearchResults.messages';

function SearchResults(props) {
    const {
        match: {
            params: { searchText }
        },
        param,
        title,
        noResultsMessage
    } = props;
    const translator = useTranslator();
    const [searchResult, setSearchResult] = useState({
        accounts: [],
        policies: []
    });
    const [prevParam, setPrevParam] = useState('');
    const [loading, setLoading] = useState(true);

    const { authHeader } = useAuthentication();
    const intl = useContext(IntlContext);

    const populateAccountResults = useCallback((data) => {
        const accounts = [];
        if (data.accounts.length) {
            data.accounts.forEach((account) => {
                if (account !== null) {
                    const accountInfo = {};
                    accountInfo.accountNumber = account.accountNumber;
                    accountInfo.accountName = account.accountHolder;
//                     const {
//                         addressLine1, city, state, postalCode
//                     } = account.accountHolderAddress;
//                     const addressLine2Data = account.accountHolderAddress.addressLine2
//                         ? account.accountHolderAddress.addressLine2
//                         : '';
//
//                     accountInfo.addressLine = `${addressLine1}, ${addressLine2Data}`;
//                     accountInfo.addressOthers = `${city}, ${state}${postalCode}`;
                    accountInfo.primaryAddress = account.accountHolderAddress;
                    accountInfo.dateOfBirth = account.accountHolderBirthday_PV;
                    accounts.push(accountInfo);
                }
            });
        }
        return accounts;
    }, []);

    const populatePolicyResults = useCallback((data) => {
        const policies = [];
        if (data.policies.length) {
            data.policies.forEach((policy) => {
                const policyInfo = {};

                policyInfo.policyNumber = policy.policyNumber;
                policyInfo.accountNumber = policy.accountNumber;
                policyInfo.boundPV = policy.bound_PV;
                policyInfo.jobNumber = policy.jobNumber;

                if (policy.accountHolder.subtype === 'Company') {
                    policyInfo.accountName = policy.accountHolder.contactName;
                } else if (policy.accountHolder.subtype === 'Person') {
                    policyInfo.accountName = `${policy.accountHolder.firstName} ${policy.accountHolder.lastName}`;
                    policyInfo.dateOfBirth = policy.accountHolder.dateOfBirth;
                }
                policyInfo.primaryAddress = policy.accountHolder.primaryAddress;
                policies.push(policyInfo);
            });
        }
        return policies;
    }, []);

    useEffect(() => {
        const searchParam = param || searchText;
        const fetchData = async () => {
            setLoading(true);
            setPrevParam(searchParam);
            const searchObj = {
                previousSearchParam: '',
                searchParam
            };
            const data = await SearchService.search(searchObj, authHeader);
            setLoading(false);
            setSearchResult(() => {
                return {
                    accounts: populateAccountResults(data),
                    policies: populatePolicyResults(data)
                };
            });
        };
        if (prevParam !== searchParam) {
            fetchData();
        }
    }, [searchText, populateAccountResults, populatePolicyResults, authHeader, param, prevParam]);

    const accountDetailsOverrides = useMemo(() => {
        const overrides = searchResult.accounts.map((account, index) => {
            const { year, month, day } = account.dateOfBirth;
            return {
                [`accountAccountNameLink${index}`]: {
                    to: `/accounts/${account.accountNumber}/summary`,
                    content: `${account.accountName}`
                },
                [`accountNumberLink${index}`]: {
                    to: `/accounts/${account.accountNumber}/summary`,
                    content: `# ${account.accountNumber}`
                },
                [`accountDOB${index}`]: {
                    content: intl.formatDate(new Date(year, month, day),
                        { year: 'numeric', month: 'short', day: 'numeric' })
                },
                [`accountAddressLine${index}`]: {
                    content: `${account.primaryAddress.addressLine1 || ''},${account.primaryAddress.addressLine2 || ''}`
                },
                [`accountCityState${index}`]: {
                    content: `${account.primaryAddress.city || ''},${account.primaryAddress.state || ''}`
                },
                [`accountPostalCode${index}`]: {
                    content: `${account.primaryAddress.postalCode || ''}`
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [intl, searchResult.accounts]);

    const policyDetailsOverrides = useMemo(() => {
        const overrides = searchResult.policies.map((policy, index) => {
            const { year, month, day } = policy.dateOfBirth;
            return {
                [`policyNumberLink${index}`]: {
                    to: policy.bound_PV ? `/policies/${policy.policyNumber}/summary` :
                        `/quotes/${policy.jobNumber}/summary`,
                    content: policy.bound_PV ? `# ${policy.policyNumber}` : `# ${policy.jobNumber}`
                },
                [`policyAccountNameLink${index}`]: {
                    to: `/accounts/${policy.accountNumber}/summary`,
                    content: `${policy.accountName}`
                },
                [`policyDOB${index}`]: {
                    content: intl.formatDate(new Date(year, month, day),
                        { year: 'numeric', month: 'short', day: 'numeric' })
                },
                [`policyAddressLine${index}`]: {
                    content: `${policy.primaryAddress.addressLine1 || ''},${policy.primaryAddress.addressLine2 || ''}`
                },
                [`policyCityState${index}`]: {
                    content: `${policy.primaryAddress.city || ''},${policy.primaryAddress.state || ''}`
                },
                [`policyPostalCode${index}`]: {
                    content: `${policy.primaryAddress.postalCode || ''}`
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [intl, searchResult.policies]);

    const overrideProps = {
        accountResults: {
            visible: searchResult.accounts.length > 0 && !loading
        },
        searchResultsTitle: {
            content: title || translator(messages.searchResults)
        },
        policyResults: {
            visible: searchResult.policies.length > 0 && !loading
        },
        searchingText: {
            visible: loading
        },
        loadingIndicator: {
            loaded: !loading
        },
        noResultsMessage: {
            visible:
                (!!searchText || param)
                && !loading
                && !searchResult.accounts.length
                && !searchResult.policies.length,
            content:
                noResultsMessage
                || translator(messages.noResultsFoundFor, {
                    query: searchText || param
                })
        },
        accountHeader: {
            content: translator(
                messages.accountResults,
                { accountResultsNumber: searchResult.accounts.length }
            )
        },
        policyHeader: {
            content: translator(
                messages.policyResults,
                { policyResultsNumber: searchResult.policies.length }
            )
        },
        ...accountDetailsOverrides,
        ...policyDetailsOverrides
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, searchResult, id, path, overrideProps);
        },
        [overrideProps, searchResult]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={searchResult}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

SearchResults.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            searchText: PropTypes.string
        })
    }).isRequired,
    param: PropTypes.string,
    title: PropTypes.string,
    noResultsMessage: PropTypes.string
};

SearchResults.defaultProps = {
    param: '',
    title: '',
    noResultsMessage: ''
};

export default withRouter(SearchResults);
