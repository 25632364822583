import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

/*
    For now we place these pages here as they are common for PPV and PCV,
    if one of this becomes LOB specific we'll have to create the page in the LOB capability
 */
import SummaryPage from './pages/SummaryPage/SummaryPage';
import QuoteConfirmationPage from './pages/QuoteConfirmationPage/QuoteConfirmationPage';
import AdditionalInfoPage from './pages/AdditionalInfoPage/AdditionalInfoPage';
import ContractPage from './pages/ContractPage/ContractPage';
import ContactPage from './pages/ContactPage/ContactPage';

setComponentMapOverrides(
    {
        SummaryPage: { component: 'SummaryPage' },
        AdditionalInfoPage: { component: 'AdditionalInfoPage' },
        QuoteConfirmationPage: { component: 'QuoteConfirmationPage' },
        ContractPage: { component: 'ContractPage' },
        ContactPage: { component: 'ContactPage' }
    },
    {
        SummaryPage: SummaryPage,
        QuoteConfirmationPage: QuoteConfirmationPage,
        AdditionalInfoPage: AdditionalInfoPage,
        ContractPage: ContractPage,
        ContactPage: ContactPage
    }
);

export { default as useWizardError } from './hooks/useWizardError';
export { default as useWizardExit } from './hooks/useWizardExit';
export { default as useWizardInit } from './hooks/useWizardInit';
export { default as commonQuoteMessages } from './quoteandbind.messages';
