import LandingPage from './LandingPage/LandingPage';
import SRELandingPage from './SRELandingPage/SRELandingPage';
import Accounts from './Accounts/Accounts';
import Policies from './Policies/Policies';
import Activities from './Activities/Activities';
import Analytics from './Analytics/Analytics';
import AccountDetails from './Accounts/AccountDetails/AccountDetails';
import PolicyDetails from './Policies/PolicyDetails/PolicyDetails';
import NewQuoteAccountSearch from './NewQuote/NewQuoteAccountSearch';
import NewQuotePage from './NewQuote/NewQuote/NewQuotePage';
import SearchResults from './SearchResults/SearchResults';
import QuoteDetails from './QuoteDetails/QuoteDetails';
import Renewal from './Renewal/Renewal';
import Endorsement from './Endorsement/Endorsement';
import Cancellation from './Cancellation/Cancellation';
import AccountBillingAndPayment from './Accounts/BillingAndPayment/BillingAndPayment';
import MakePayment from './Accounts/BillingAndPayment/MakePayment/MakePayment';
import ContactUnderwriter from './ContactUnderwriter/ContactUnderwriter';
import PaymentConfirmation from './Accounts/BillingAndPayment/PaymentConfirmation/PaymentConfirmation';
import ContactRoles from './Components/ContactRoles/ContactRoles';
import BmsQuote from './Components/BmsQuote/BmsQuote';
import gatewayMessages from './gateway.messages';
import accountMessages from './Accounts/Accounts.messages';
import CopyContractComponent from './Components/CopyContractComponent/CopyContractComponent';

const producerEngageComponentMap = {
    ContactRoles: { component: 'ContactRoles' },
};

const producerEngageAdditionalComponents = {
    ContactRoles,
};

export { default as summaryMessages } from './Endorsement/Summary/Summary.messages';

export {
    LandingPage as default,
    SRELandingPage,
    Accounts,
    Policies,
    Activities,
    Analytics,
    AccountDetails,
    PolicyDetails,
    CopyContractComponent,
    NewQuoteAccountSearch,
    NewQuotePage,
    SearchResults,
    QuoteDetails,
    Renewal,
    Endorsement,
    Cancellation,
    AccountBillingAndPayment,
    MakePayment,
    ContactUnderwriter,
    PaymentConfirmation,
    ContactRoles,
    BmsQuote,
    producerEngageComponentMap,
    producerEngageAdditionalComponents,
    gatewayMessages,
    accountMessages
};

export { default as AutomaticPaymentPolicyAffectedValueIterableComponent } from './Accounts/BillingAndPayment/AutomaticPaymentPolicyAffectedValueIterableComponent';
