import UnderwritingMessageComponent from './UnderwritingMessageComponent/UnderwritingMessageComponent';
import PaymentBankAccountComponent from './PaymentBankAccountComponent/PaymentBankAccountComponent';
import PaymentDetailsComponent from './PaymentDetailsComponent/PaymentDetailsComponent';
import PrintDocumentComponent from './PrintDocumentComponent/PrintDocumentComponent';
import DeclarationsComponent from './DeclarationsComponent/DeclarationsComponent';
import TooltipComponent from './TooltipComponent/TooltipComponent';
import DateFieldComponent from './DateFieldComponent/DateFieldComponent';
import LocalizedCurrencyOrNumberInput from './LocalizedNumberInput/LocalizedCurrencyOrNumberInput';

const platformComponentMap = {
    UnderwritingMessageComponent: { component: 'UnderwritingMessageComponent' },
    PrintDocumentComponent: { component: 'PrintDocumentComponent'},
    DeclarationsComponent: { component: 'DeclarationsComponent'},
    TooltipComponent: { component: 'TooltipComponent'},
    PaymentBankAccountComponent: { component: 'PaymentBankAccountComponent' },
    PaymentDetailsComponent: { component: 'PaymentDetailsComponent' },
    LocalizedCurrencyOrNumberInput: { component: 'LocalizedCurrencyOrNumberInput' },
    date: { component: 'date' },
    Date: { component: 'Date' }
};

const platformComponents = {
    UnderwritingMessageComponent,
    PrintDocumentComponent,
    DeclarationsComponent,
    TooltipComponent,
    PaymentBankAccountComponent,
    PaymentDetailsComponent,
    LocalizedCurrencyOrNumberInput,
    date: DateFieldComponent,
    Date: DateFieldComponent
};

export {
    platformComponentMap,
    platformComponents
};
