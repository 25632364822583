import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './RiskRefusedInsurerComponent.metadata.json5';
import './RiskRefusedInsurerComponent.messages';

function RiskRefusedInsurerComponent(props) {

    const {
        riskObjectVM,
        riskObjectPath,

        insurers,

        onValueChange,
        onValidate,

        id,
        labelPosition,
        phoneWide,
        showOptional,
        showErrors,
        showRequired,
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, riskObjectVM]);

    const onValueChangeWasRiskRefused = useCallback((value, path) => {
        _.set(riskObjectVM, path, value);
        if (value === false) {
            riskObjectVM.reasonRefusedByInsurer.value = null;
            riskObjectVM.refusedContractNumber.value = null;
            riskObjectVM.riskRefusedByInsurer.value = {}; // complex object
        }
        onValueChange(riskObjectVM, riskObjectPath);
    }, [onValueChange, riskObjectVM, riskObjectPath]);

    const onValueChangeInsurer = useCallback((value, path) => {
        // eslint-disable-next-line camelcase
        const valueObj = _.isEmpty(value) ? null : { currentInsurerName_PV: value.name, publicID_PV: value.code };
        _.set(riskObjectVM, path, valueObj);
        onValueChange(riskObjectVM, riskObjectPath);
    }, [riskObjectPath, riskObjectVM, onValueChange]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${riskObjectPath}.${changedPath}`;
        if (onValueChange) {
            onValueChange(value, fullPath);
        }
    }, [onValueChange, riskObjectPath]);

    const isInsurerSelected = !_.isEmpty(_.get(riskObjectVM, 'riskRefusedByInsurer.currentInsurerName_PV.value'));

    const overrideProps = {
        '@field': {
            labelPosition,
            phoneWide,
            showOptional,
            showRequired
        },

        wasRiskRefusedByInsurer: {
            onValueChange: onValueChangeWasRiskRefused
        },

        riskRefusedDetailsFragment: {
            visible: riskObjectVM.wasRiskRefusedByInsurer.value === true
        },
        riskRefusedByInsurer: {
            availableValues: insurers,
            value: isInsurerSelected ? {
                code: riskObjectVM.riskRefusedByInsurer.publicID_PV.value,
                name: riskObjectVM.riskRefusedByInsurer.currentInsurerName_PV.value
            } : null,
            onValueChange: onValueChangeInsurer
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={riskObjectVM}
            showErrors={showErrors}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
        />
    );
};

RiskRefusedInsurerComponent.propTypes = {
    riskObjectVM: PropTypes.shape({}).isRequired,
    riskObjectPath: PropTypes.string,

    insurers: PropTypes.array.isRequired,

    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,

    id: PropTypes.string.isRequired,
    labelPosition: PropTypes.string,
    phoneWide: PropTypes.shape({}),
    showOptional: PropTypes.bool,
    showErrors: PropTypes.bool,
    showRequired: PropTypes.bool,
};

RiskRefusedInsurerComponent.defaultProps = {
};

export default RiskRefusedInsurerComponent;
