import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { withValidation, validationPropTypes, validationDefaultProps } from '@xengage/gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { ServiceManager } from '@jutro/legacy/services';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { AccountService } from 'gw-capability-gateway-policy';
import { UserService, OrganizationService, SubmissionService, ContactService } from 'gw-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { LocalDateUtil, JobUtil } from '@xengage/gw-portals-util-js';
import { Loader, withModalContext } from '@jutro/components';
import { getApdLobQuoteUrl } from '@xengage/gw-portals-url-js';
import appConfig from 'app-config';

import { EdgeErrorParser, BusinessConstant } from 'pv-portals-util-js';
import { messages as platformMessagesPV } from 'pv-platform-translations';
import NewSubmissionPage from './NewSubmission/NewSubmissionPage';
import ProducerComponent from './ProducerComponent/ProducerComponent';
import ProfessionalComponent from './ProfessionalComponent/ProfessionalComponent';
import styles from './NewQuotePage.module.scss';
import metadata from './NewQuotePage.metadata.json5';
import messages from '../NewQuoteAccountSearch.messages';
import newQuoteMessages from './NewQuotePage.messages';
import gatewayMessages from '../../gateway.messages';
import { ScrollToError } from '@jutro/legacy/wizard-next';

class NewQuotePage extends Component {
    static contextType = TranslatorContext;

    state = {
        submissionVM: {},
        producerCode: [],
        accountProducerCodes: [],
        isAnExistingAccount: false,
        accountHolderDetails: {},
        accountHolderViewVM: {},
        newAccountCreate: {},
        allOrganisationValue: [],
        selectedOrganisationValue: '',
        showErrors: false,
        isLoading: true,
        quickQuote: false,
        errorTimestamp: 0
    };

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({
                accountSearchCriteria: PropTypes.object
            })
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                accountNumber: PropTypes.string
            })
        }).isRequired,
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        authUserData: PropTypes.shape({}),
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        ...validationPropTypes
    };

    static defaultProps = {
        authUserData: undefined,
        ...validationDefaultProps
    }

    localeService = ServiceManager.getService('locale-service');

    componentDidUpdate(prevProps) {
        const { authUserData } = this.props;
        if (_.get(prevProps, 'authUserData.userType') !== _.get(authUserData, 'userType')) {
            this.getAccountHolderDetails();
        }
    }

    updateAccountHolderVM = (accountHolderViewVM) => {
        this.setState({ accountHolderViewVM });
    };

    userIsAgent = () => {
        const { authUserData } = this.props;
        return _.get(authUserData, 'userType') === 'producer';
    };

    getProducerCodes = () => {
        let { producerCode } = this.state;
        const { authHeader } = this.props;
        UserService.getAvailableProducerCodesForCurrentUser(authHeader).then((producerCodeResponse) => {
            producerCode = _.sortBy(producerCodeResponse, 'code').map((value) => {
                return {
                    code: value.code,
                    name: value.displayValue
                };
            });
            this.setState({
                producerCode,
                accountProducerCodes: producerCodeResponse
            });
        });
    };

    handleSearchContact = async (requestPromise, id) => {
        const { authHeader, history, modalContext } = this.props;
        const translator = this.context;
        try {
            const contact = await requestPromise(id, authHeader);
            const { accountHolderDetails, submissionVM } = this.state;
            accountHolderDetails.contactType = contact.subtype;
            _.set(accountHolderDetails, 'newAccount.accountHolder', contact);
            _.set(submissionVM, 'state', contact.primaryAddress.state);
            accountHolderDetails.displayAddressArray = contact.primaryAddress.displayName.split(', ').join('\n');
            _.set(submissionVM, 'contactType_PV', contact.subtype);

            this.setState(
                {
                    accountHolderDetails,
                    submissionVM,
                    isAnExistingAccount: true
                },
                () => {
                    this.setAccountHolderViewVM(contact);
                }
            );
        } catch (error) {
            const msg = EdgeErrorParser.getErrorMessage(error);
            modalContext
                .showAlert({
                    title: gatewayMessages.modelError,
                    message: `${translator(messages.noContactFound)}\n${msg}`,
                    status: 'warning',
                    icon: 'mi-error-outline'
                })
                .then(() => history.push('/new-quote-account-search'));
        }
    };

    getAccountHolderDetails = async () => {
        const { submissionVM } = this.state;
        const {
            match: {
                params: { accountNumber }
            },
            location
        } = this.props;

        this.setState({
            isLoading: true
        });

        const { bdpid, publicId } = _.get(location, 'state', {});
        if (bdpid) {
            await this.handleSearchContact(ContactService.searchContactByBdpId, bdpid);
        } else if (publicId) {
            await this.handleSearchContact(ContactService.searchContactByPublicId, publicId);
        } else if (accountNumber) {
            await this.findAccount(accountNumber);
        } else {
            const { accountHolderDetails } = this.state;
            const { history } = this.props;
            const accountSearchCriteria = _.get(location, 'state.accountSearchCriteria');
            const accountHolder = {
                contactName: '',
                primaryAddress: {}
            };
            if (_.isNil(accountSearchCriteria)) {
                history.push('/');
                return;
            }
            const criteriaContactType = accountSearchCriteria.contactType;
            accountHolderDetails.contactType = _.upperFirst(criteriaContactType);
            if (accountHolderDetails.contactType === 'Person') {
                accountHolder.firstName = accountSearchCriteria.firstName;
                accountHolder.lastName = accountSearchCriteria.lastName;
                accountHolder.dateOfBirth = accountSearchCriteria.dob_pv;
                if (accountSearchCriteria.lastNameKanji) {
                    accountHolder.firstNameKanji = accountSearchCriteria.firstNameKanji;
                    accountHolder.lastNameKanji = accountSearchCriteria.lastNameKanji;
                }
            } else {
                accountHolder.contactName = accountSearchCriteria.contactName;
                accountHolder.enterpriseNumber_PV = accountSearchCriteria.enterpriseNumber_PV;
                if (accountSearchCriteria.contactNameKanji) {
                    accountHolder.contactNameKanji = accountSearchCriteria.contactNameKanji;
                }
                accountHolder.specialTaxRegime = BusinessConstant.TAX_REGIME_NORMAL_TAXATION;
            }
            accountHolder.primaryAddress.city = accountSearchCriteria.city;
            accountHolder.primaryAddress.state = accountSearchCriteria.state;
            accountHolder.primaryAddress.postalCode = accountSearchCriteria.postalCode;
            accountHolder.primaryAddress.country = accountSearchCriteria.country;
            if (accountSearchCriteria.cityKanji) {
                accountHolder.primaryAddress.cityKanji = accountSearchCriteria.cityKanji;
            }
            // eslint-disable-next-line camelcase
            accountHolder.independentOrLiberalProfession_PV = false;

            _.set(submissionVM, 'contactType_PV', accountHolderDetails.contactType);

            this.setState({
                accountHolderDetails,
                isAnExistingAccount: false,
                submissionVM
            }, () => {
                this.setAccountHolderViewVM(accountHolder);
            });

//             accountSearchCriteria.contactName = '';
//             accountSearchCriteria.lastName = '';
//             accountSearchCriteria.firstName = '';
//             accountSearchCriteria.city = '';
//             accountSearchCriteria.state = '';
//             accountSearchCriteria.postalCode = '';
        }
        if (this.userIsAgent()) {
            this.getProducerCodes();
        } else {
            this.getAllOrganisation();
        }
        this.setState({
            isLoading: false
        });
    };

    getAllOrganisation = () => {
        const { authHeader } = this.props;
        let { allOrganisationValue } = this.state;

        OrganizationService.getAvailableOrganizations(authHeader).then(
            (getOrganizationsResponse) => {
                allOrganisationValue = getOrganizationsResponse.map((value) => {
                    return {
                        code: `${value.publicID}`,
                        name: `${value.displayName}`
                    };
                });

                this.setState({
                    allOrganisationValue
                });
            }
        );
    };

    handleValueOrganisation = (value) => {
        let { selectedOrganisationValue } = this.state;
        selectedOrganisationValue = value;
        this.getProducerCodesForAnOrganization(value);
        this.setState({ selectedOrganisationValue });
    };

    getProducerCodesForAnOrganization = (selectedOrganisationValue) => {
        let { producerCode } = this.state;
        const { accountHolderViewVM, allOrganisationValue } = this.state;
        const { authHeader } = this.props;
        let organisationCode = {};

        const selectedOrganisation = selectedOrganisationValue;
        allOrganisationValue.forEach((value) => {
            if (value.code === selectedOrganisation) {
                organisationCode = {
                    displayName: value.name,
                    publicID: value.code
                };
            }
        });
        OrganizationService.getProducerCodeForOrganization(organisationCode, authHeader).then(
            (producerCodeResponse) => {
                producerCode = producerCodeResponse.map((value) => {
                    return {
                        code: value.code,
                        name: value.displayValue
                    };
                });
                _.set(accountHolderViewVM, 'producerCode', '');
                this.setState({
                    producerCode,
                    accountHolderViewVM,
                    accountProducerCodes: producerCodeResponse
                });
            }
        );
    };

    componentDidMount = () => {
        let { submissionVM } = this.state;
        const { viewModelService, location } = this.props;
        const quickQuote = !!(_.get(location, 'state.quickQuote') || location.pathname.includes('pvQuickQuote'));
        const model = {
            country: this.localeService.getDefaultCountryCode(),
            // eslint-disable-next-line camelcase
            predecessorPolicy_PV: {}
        };
        submissionVM = viewModelService.create(model, 'pc', 'edge.capabilities.gateway.job.submission.dto.NewSubmissionDTO');
        _.set(submissionVM, 'effectiveDate', LocalDateUtil.today());
        _.set(submissionVM, 'productCode', '');
        _.set(submissionVM, 'hasPredecessorPolicy_PV', false);
        _.set(submissionVM, 'independentOrLiberalProfession_PVQuestion', false);
        this.setState(
            {
                submissionVM,
                quickQuote
            },
            () => {
                this.getAccountHolderDetails();
            }
        );
    };

    findAccount = async (accountNumber) => {
        const { accountHolderDetails, submissionVM } = this.state;
        const { authHeader, modalContext } = this.props;
        const translator = this.context;
        try {
            const account = await AccountService.getAccountDetails(accountNumber, authHeader);

            accountHolderDetails.contactType = account.accountHolder.subtype;
            accountHolderDetails.newAccount = account;
            _.set(submissionVM, 'state', account.accountHolder.primaryAddress.state);
            accountHolderDetails.displayAddressArray = account.accountHolder.primaryAddress.displayName.split(', ').join('\n');
            _.set(submissionVM, 'accountNumber', account.accountNumber);
            _.set(submissionVM, 'accountPublicId', account.publicID);
            _.set(submissionVM, 'contactType_PV', account.accountHolder.subtype);

            this.setState(
                {
                    accountHolderDetails,
                    isAnExistingAccount: true,
                    submissionVM
                },
                () => {
                    this.setAccountHolderViewVM(accountHolderDetails.newAccount);
                }
            );
        } catch (error) {
            const msg = EdgeErrorParser.getErrorMessage(error);
            modalContext
                .showConfirm({
                    title: gatewayMessages.modelError,
                    message: `${translator(messages.accountTypeErrorMessage)}\n${msg}`,
                    status: 'warning',
                    icon: 'mi-error-outline'
                })
                .then((results) => {
                    if (results === 'cancel' || results === 'close') {
                        return _.noop();
                    }
                    return window.history.back();
                }, _.noop);
        }
    };

    setAccountHolderViewVM = (account) => {
        const { viewModelService } = this.props;
        let { accountHolderViewVM } = this.state;
        const { accountHolderDetails } = this.state;
        const viewModel = viewModelService.create(
            account,
            'pc',
            'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO',
            {
                ProducerCodeRequired: true,
                ValidateGender: true
            }
        );
        _.set(viewModel, 'primaryLanguage_PV.value', account.primaryLanguage);
        if (accountHolderDetails.contactType === 'Person') {
            _.set(viewModel, 'subtype.value', 'Person');
        } else {
            _.set(viewModel, 'subtype.value', 'Company');
        }
        accountHolderViewVM = viewModel;
        this.setState({
            accountHolderViewVM
        });
    };

    createNewAccount = async () => {
        const { accountHolderDetails, newAccountCreate, accountHolderViewVM, submissionVM } = this.state;
        const { authHeader, modalContext } = this.props;
        const translator = this.context;
        if (_.get(accountHolderDetails, 'newAccount.accountNumber')) {
            // CREATE SUBMISSION PV
            this.startSubmission();
        } else {
            const newAccount = {
                accountHolder: {
                    primaryAddress: {
                        addressType: 'business'
                    }
                }
            };
            newAccount.accountHolder = _.get(accountHolderViewVM, 'value');
            if (accountHolderDetails.contactType === 'Person') {
                newAccount.accountHolder.contactName = `${accountHolderViewVM.firstName.value} ${accountHolderViewVM.lastName.value}`;
            }
            newAccount.accountHolder.primaryAddress.addressType = 'home';
            newAccount.producerCodes = [
                {
                    code: _.get(accountHolderViewVM, 'producerCode.value')
                }
            ];
            try {
                const newAccountResponse = await AccountService.getOrCreateAccount(newAccount, authHeader);

                newAccountCreate.newAccount = newAccountResponse;
                newAccountCreate.displayAddressArray = newAccountResponse.accountHolder.primaryAddress.displayName
                    .split(', ')
                    .join('\n');
                _.set(submissionVM, 'accountNumber', newAccountResponse.accountNumber);

                this.setState(
                    {
                        newAccountCreate,
                        submissionVM
                    },
                    () => {
                        this.startSubmission();
                    }
                );
            } catch (error) {
                const msg = EdgeErrorParser.getErrorMessage(error);
                modalContext.showAlert({
                    title: messages.accountTypeError,
                    message: `${translator(messages.newAccountCreateErrorMessage)}\n${msg}`,
                    status: 'warning',
                    icon: 'mi-error-outline'
                });
            }
        }
    };

    handleValidation = () => {
        this.setState({ showErrors: true, errorTimestamp: Date.now() });
    };

    handleCancel = () => {
        const { history, modalContext } = this.props;
        modalContext
            .showConfirm({
                title: messages.cancelQuote,
                message: messages.cancelMessage,
                status: 'warning',
                icon: 'mi-error-outline'
            })
            .then((results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                return history.push('/accounts');
            }, _.noop);
    };

    updateSubmission = (submissionVM) => {
        this.setState({
            submissionVM
        });
    };

    startSubmission = async () => {
        const { submissionVM, newAccountCreate, accountHolderDetails, quickQuote } = this.state;
        const { modalContext } = this.props;
        const postalCode =
            _.get(newAccountCreate.newAccount, 'accountHolder.primaryAddress.postalCode') ||
            _.get(accountHolderDetails.newAccount, 'accountHolder.primaryAddress.postalCode');
        const { authHeader, history } = this.props;
        const { lobQuoteURL } = appConfig;

        const productCode = _.get(submissionVM, 'productCode.value');

        if (!_.isNil(getApdLobQuoteUrl(productCode))) {
            const accountId = _.get(submissionVM, 'accountPublicId');
            const nextLocation = {
                productCode,
                accountId,
                jobId: undefined
            };
            history.push(getApdLobQuoteUrl(productCode), nextLocation);
        } else {
            // eslint-disable-next-line no-warning-comments
            const data = {
                ...submissionVM.value,
                // eslint-disable-next-line camelcase
                quoteType_PV: quickQuote ? 'Quick' : undefined
            };
            try {
                const newSubmissionResponse = await SubmissionService.createSubmission(data, authHeader);

                if (newSubmissionResponse.jobNumber > 0) {
                    if (!_.isNil(lobQuoteURL[productCode]) || !_.isNil(lobQuoteURL[`QQ${productCode}`])) {
                        const nextLocation = {
                            quoteentry: {
                                postalCode: postalCode,
                                quoteID: newSubmissionResponse.jobNumber
                            },
                            productCode
                        };
                        const url =
                            newSubmissionResponse.quoteType === 'Quick'
                                ? lobQuoteURL[`QQ${productCode}`]
                                : lobQuoteURL[productCode];

                        history.push(url, nextLocation);
                        this.setState({
                            isAnExistingAccount: true
                        });
                    } else {
                        JobUtil.openJobInXCenter(newSubmissionResponse.jobNumber);
                    }
                }
            } catch (error) {
                const msg = EdgeErrorParser.getErrorMessage(error);
                modalContext.showAlert({
                    title: messages.submissionCreationError,
                    message: msg,
                    status: 'warning',
                    icon: 'mi-error-outline'
                });
            }
        }
    };

    legalFormValues = (accountHolderViewVM, translator) => {
        const availableValues = _.get(accountHolderViewVM, 'legalForm.aspects.availableValues', []);
        const filteredValues = _.sortBy(
            availableValues.map((key) => {
                return {
                    code: key.code,
                    name: translator({
                        id: key.name,
                        defaultMessage: key.name
                    })
                };
            }),
            ['name']
        );
        return filteredValues;
    };

    primaryLanguageValues = () => {
        const { viewModelService } = this.props;
        const typelist = viewModelService.productMetadata.get('pc').types.getTypelist(BusinessConstant.LANGUAGE_TYPE);
        const codes = typelist.getFilter(BusinessConstant.DUTCH_AND_FRENCH_ONLY).codes;
        return codes.map((code) => ({
            code: code.code,
            name: {
                id: code.name,
                defaultValue: code.code
            }
        }));

    };

    render() {
        const {
            accountHolderViewVM,
            accountHolderDetails,
            isAnExistingAccount,
            submissionVM,
            newAccountCreate,
            allOrganisationValue,
            selectedOrganisationValue,
            showErrors,
            isLoading,
            errorTimestamp,
            accountProducerCodes
        } = this.state;
        const isAgent = this.userIsAgent();
        const { producerCode } = this.state;
        const translator = this.context;
        const { authHeader, setComponentValidation, isComponentValid } = this.props;
        let contactTypeValue = '';
        let subTypeLabel = '';
        if (_.get(accountHolderViewVM, 'subtype.value') === 'Person') {
            contactTypeValue = translator(messages.personal);
            subTypeLabel = translator(newQuoteMessages.address);
        } else if (_.get(accountHolderViewVM, 'subtype.value') === 'Company') {
            contactTypeValue = translator(messages.commercial);
            subTypeLabel = translator(messages.company);
        }
        const accountType = !!(_.get(accountHolderViewVM, 'subtype') && _.get(accountHolderViewVM, 'subtype.value') === 'Person');
        const legalFormValues = this.legalFormValues(accountHolderViewVM, translator);
        const overrideProps = {
            '@field': {
                labelPosition: 'left',
                showRequired: true
            },
            newQuoteDetailsContainer: {
                visible: !isAnExistingAccount
            },
            policyDetailsContainer: {
                visible: isAnExistingAccount
            },
            commercialContainer: {
                visible: !accountType
            },
            legalForm: {
                availableValues: legalFormValues
            },
            personalContainer: {
                visible: accountType
            },
            genderRadioButton: {
                value: _.get(accountHolderViewVM, 'gender.value.code')
            },
            languageRadioButton: {
                value: _.get(accountHolderViewVM, 'primaryLanguage_PV.value.code'),
                availableValues: this.primaryLanguageValues()
            },
            producerCode: {
                availableValues: producerCode
            },
            contactType: {
                value: contactTypeValue
            },
            organisation: {
                visible: !isAgent,
                availableValues: allOrganisationValue,
                onValueChange: this.handleValueOrganisation,
                value: selectedOrganisationValue
            },
            addressDetails: {
                label: subTypeLabel,
                value: _.get(newAccountCreate, 'displayAddressArray')
                    ? `${_.get(newAccountCreate, 'newAccount.accountHolder.displayName')}\n${_.get(
                        newAccountCreate,
                        'displayAddressArray'
                    )}`
                    : `${_.get(accountHolderDetails, 'newAccount.accountHolder.displayName')}\n${_.get(
                        accountHolderDetails,
                        'displayAddressArray'
                    )}`
            },
            accountNumberDetails: {
                value: _.get(submissionVM, 'accountNumber.value')
            },
            contactTypeDetails: {
                value: contactTypeValue
            },
            newSubmission: {
                visible: false,
                isAnExistingAccount: isAnExistingAccount,
                submissionVM: submissionVM,
                postalCode:
                    _.get(newAccountCreate.newAccount, 'accountHolder.primaryAddress.postalCode') ||
                    _.get(accountHolderDetails.newAccount, 'accountHolder.primaryAddress.postalCode'),
                updateSubmissionVM: this.updateSubmission,
                producerCodeValue: isAgent ? producerCode : [],
                isAgent: isAgent,
                allOrganisationValue: allOrganisationValue,
                authHeader: authHeader
            },
            professionalComponentExistingAccount: {
                submissionVM,
                isAnExistingAccount,
                accountHolderViewVM,
                visible: isAnExistingAccount,
                producerCodeValue: producerCode,
                accountProducerCodes: accountProducerCodes,
                updateSubmissionVM: this.updateSubmission,
                updateAccountHolderVM: this.updateAccountHolderVM
            },
            professionalComponentNewAccount: {
                submissionVM,
                isAnExistingAccount,
                accountHolderViewVM,
                visible: !isAnExistingAccount,
                producerCodeValue: producerCode,
                accountProducerCodes: accountProducerCodes,
                updateSubmissionVM: this.updateSubmission,
                updateAccountHolderVM: this.updateAccountHolderVM
            },
            newPersonProducerComponent: {
                submissionVM,
                isAnExistingAccount,
                accountHolderViewVM,
                producerCodeValue: producerCode,
                accountProducerCodes: accountProducerCodes,
                updateSubmissionVM: this.updateSubmission,
                updateAccountHolderVM: this.updateAccountHolderVM
            },
            enterpriseNumber: {
                validationMessages: _.get(accountHolderViewVM, 'enterpriseNumber_PV.value') === "0000000000" ? [platformMessagesPV.enterpriseError] : _.get(accountHolderViewVM, `enterpriseNumber_PV.aspects.validationMessages`)
            }
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                newsubmission: NewSubmissionPage,
                professionalComponent: ProfessionalComponent,
                producerComponent: ProducerComponent
            },
            resolveCallbackMap: {
                submitCreateAccountForm: isComponentValid ? this.createNewAccount : this.handleValidation,
                onCancel: this.handleCancel,
                onValidate: setComponentValidation
            }
        };
        if (isLoading) {
            return <Loader loaded={!isLoading} />;
        }

        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, accountHolderViewVM, id, path, overrideProps);
        };

        return (
            <div>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={accountHolderViewVM}
                    onModelChange={this.updateAccountHolderVM}
                    overrideProps={overrideProps}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    onValidationChange={setComponentValidation}
                    resolveValue={readValue}
                    showErrors={showErrors}
                />
                <ScrollToError counter={errorTimestamp} timeout={200} />
            </div>
        );
    }
}

export default withValidation(withRouter(withViewModelService(withAuthenticationContext(withModalContext(NewQuotePage)))));
