import VehiclesPage from './pages/VehiclesPage/VehiclesPage';
import VehiclesQuickPage from './pages/VehiclesQuickPage/VehiclesQuickPage';
import DriversPage from './pages/DriversPage/DriversPage';
import PackPage from './pages/PackPage/PackPage';
import ESignaturePage from './pages/ESignaturePage/ESignaturePage';
import FinancialInstitutionComponent from './components/FinancialInstitutionComponent/FinancialInstitutionComponent';
import CancelCurrentInsurerComponent from './components/CancelCurrentInsurerComponent/CancelCurrentInsurerComponent';
import RiskRefusedInsurerComponent from './components/RiskRefusedInsurerComponent/RiskRefusedInsurerComponent';
import PolicyChangesComponent from './components/PolicyChangesComponent/PolicyChangesComponent';
import PackParametersModal from './components/PackParametersModal/PackParametersModal';
import PolicyContext from './context/PolicyContext';
import PolicyContextProvider from './context/PolicyContextProvider';

const policyJobComponentMapPV = {
    VehiclesPage: { component: 'VehiclesPage' },
    VehiclesQuickPage: { component: 'VehiclesQuickPage' },
    DriversPage: { component: 'DriversPage' },
    PackPage: { component: 'PackPage' },
    ESignaturePage: { component: 'ESignaturePage' },
    FinancialInstitutionComponent: { component: 'FinancialInstitutionComponent' },
    CancelCurrentInsurerComponent: { component: 'CancelCurrentInsurerComponent' },
    RiskRefusedInsurerComponent: { component: 'RiskRefusedInsurerComponent' },
    PolicyChangesComponent: { component: 'PolicyChangesComponent' },
    PackParametersModal: { component: 'PackParametersModal' },
};

const policyJobComponentsPV = {
    VehiclesPage,
    VehiclesQuickPage,
    DriversPage,
    PackPage,
    ESignaturePage,
    FinancialInstitutionComponent,
    CancelCurrentInsurerComponent,
    RiskRefusedInsurerComponent,
    PolicyChangesComponent,
    PackParametersModal
};

export {
    policyJobComponentMapPV,
    policyJobComponentsPV,
    PolicyContext,
    PolicyContextProvider
};

export { default as messagesPolicyJob } from './policyjob.messages';
