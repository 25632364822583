import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './CancelCurrentInsurerComponent.metadata.json5';
import './CancelCurrentInsurerComponent.messages';

function CancelCurrentInsurerComponent(props) {

    const {
        vehicleVM,
        vehiclePath,

        insurers,

        onValueChange,
        onValidate,

        id,
        labelPosition,
        phoneWide,
        showOptional,
        showErrors,
        showRequired,
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, vehicleVM]);

    const onValueChangeCancelPolicy = useCallback((value, path) => {
        _.set(vehicleVM, path, value);
        if (value === false) {
            vehicleVM.policyNumber_PV.value = null;
            vehicleVM.cancellationDate_PV.value = null;
            vehicleVM.currentInsurer_PV.value = {}; // complex object
        }
        onValueChange(vehicleVM, vehiclePath);
    }, [onValueChange, vehicleVM, vehiclePath]);

    const onValueChangeCurrentInsurer = useCallback((value, path) => {
        // eslint-disable-next-line camelcase
        const valueObj = _.isEmpty(value) ? null : { currentInsurerName_PV: value.name, publicID_PV: value.code };
        _.set(vehicleVM, path, valueObj);
        onValueChange(vehicleVM, vehiclePath);
    }, [vehiclePath, vehicleVM, onValueChange]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${vehiclePath}.${changedPath}`;
        if (onValueChange) {
            onValueChange(value, fullPath);
        }
    }, [onValueChange, vehiclePath]);

    const isInsurerSelected = !_.isEmpty(_.get(vehicleVM, 'currentInsurer_PV.currentInsurerName_PV.value'));

    const overrideProps = {
        '@field': {
            labelPosition,
            phoneWide,
            showOptional,
            showRequired
        },

        cancelPolicy: {
            onValueChange: onValueChangeCancelPolicy
        },
        currentInsurer: {
            availableValues: insurers,
            value: isInsurerSelected ? {
                code: vehicleVM.currentInsurer_PV.publicID_PV.value,
                name: vehicleVM.currentInsurer_PV.currentInsurerName_PV.value
            } : null,
            onValueChange: onValueChangeCurrentInsurer
        },
        cancelPolicyFragment: {
            visible: vehicleVM.cancelPolicy_PV.value
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            showErrors={showErrors}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
        />
    );
};

CancelCurrentInsurerComponent.propTypes = {
    vehicleVM: PropTypes.shape({}).isRequired,
    vehiclePath: PropTypes.string,

    insurers: PropTypes.shape([]).isRequired,

    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,

    id: PropTypes.string.isRequired,
    labelPosition: PropTypes.string,
    phoneWide: PropTypes.shape({}),
    showOptional: PropTypes.bool,
    showErrors: PropTypes.bool,
    showRequired: PropTypes.bool,
};

CancelCurrentInsurerComponent.defaultProps = {
};

export default CancelCurrentInsurerComponent;
