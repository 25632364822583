import { BusinessConstant } from "pv-portals-util-js";
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';

export default class ESignatureService {
    static getOrCreateTokenUrl(quoteID, sessionID, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl("esignature"),
            "getOrCreateTokenUrl",
            [quoteID, sessionID],
            additionalHeaders
        );
    }

    static wait(delay) {
        return new Promise((resolve) => setTimeout(resolve, delay));
    }

    static retryableGetOrCreateTokenUrl(
        quoteID,
        sessionID,
        additionalHeaders = {},
        delay = 5000,
        tries = 30
    ) {
        function onError(err) {
            const triesLeft = tries - 1;
            if (!triesLeft) {
                throw err;
            }
            return ESignatureService.wait(delay).then(() =>
                ESignatureService.retryableGetOrCreateTokenUrl(
                    quoteID,
                    sessionID,
                    additionalHeaders,
                    delay,
                    triesLeft
                )
            );
        }
        return ESignatureService.getOrCreateTokenUrl(
            quoteID,
            sessionID,
            additionalHeaders
        ).catch(onError);
    }

    static getSignatureStatus(quoteID, sessionID, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl("esignature"),
            "getSigningStatus",
            [quoteID, sessionID],
            additionalHeaders
        );
    }

    static getRetryableSignatureStatus(
        quoteID,
        sessionID,
        additionalHeaders = {},
        delay = 5000,
        tries = 20
    ) {
        function onError(err) {
            const triesLeft = tries - 1;
            if (!triesLeft) {
                throw err;
            }
            return ESignatureService.wait(delay).then(() =>
                ESignatureService.getRetryableSignatureStatus(
                    quoteID,
                    sessionID,
                    additionalHeaders,
                    delay,
                    triesLeft
                )
            );
        }
        return ESignatureService.getSignatureStatus(
            quoteID,
            sessionID,
            additionalHeaders
        )
            .then((signatureStatus) => {
                if (
                    signatureStatus ===
                        BusinessConstant.SIGNATURE_STATUS_PENDING &&
                    tries > 0
                ) {
                    const triesLeft = tries - 1;
                    return ESignatureService.wait(delay).then(() =>
                        ESignatureService.getRetryableSignatureStatus(
                            quoteID,
                            sessionID,
                            additionalHeaders,
                            delay,
                            triesLeft
                        )
                    );
                }
                return signatureStatus;
            })
            .catch(onError);
    }
}