import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';

export default class EPaymentService {
    static getOrCreateTokenUrl(
        jobID,
        sessionID,
        refererUrl,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl("epayment"),
            "getPaymentToken",
            [jobID, sessionID, refererUrl],
            additionalHeaders
        );
    }

    static wait(delay) {
        return new Promise((resolve) => setTimeout(resolve, delay));
    }

    static retryableGetOrCreateTokenUrl(
        quoteID,
        sessionID,
        paymentRefererUrl,
        additionalHeaders = {},
        delay = 5000,
        tries = 3
    ) {
        function onError(err) {
            const triesLeft = tries - 1;
            if (!triesLeft) {
                throw err;
            }
            return EPaymentService.wait(delay).then(() =>
                EPaymentService.retryableGetOrCreateTokenUrl(
                    quoteID,
                    sessionID,
                    paymentRefererUrl,
                    additionalHeaders,
                    delay,
                    triesLeft
                )
            );
        }
        return EPaymentService.getOrCreateTokenUrl(
            quoteID,
            sessionID,
            paymentRefererUrl,
            additionalHeaders
        ).catch(onError);
    }
}
