/* eslint-disable no-shadow */
import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { BusinessConstant as BC } from 'pv-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, Loader } from '@jutro/components';
import metadata from './PackParametersModal.metadata.json5';
import messages from './PackParametersModal.messages';
import styles from './PackParametersModal.module.scss';

import { Button } from '@jutro/legacy/components';

function PackParametersModal({
    submissionVM,
    onCloseParametersModal,
    onSaveParameters,
    onUpdateParameters,
    isLoading,
    readOnly
}) {
    const translator = useTranslator();
    const { onValidate } = useValidation('PackParametersModal');
    const titleMsg = translator(messages.parameters);
    const saveButtonText = translator(messages.save);

    const isPolicyChange = useMemo(() => submissionVM.baseData.jobType.value.code === BC.JOB_TYPE_POLICY_CHANGE, [submissionVM.baseData.jobType]);
    const lobName = useMemo(() => submissionVM.baseData.productCode.value === BC.PPV_PROD_CODE ? BC.PPV_LOB_CODE : BC.PCV_LOB_CODE, [submissionVM.baseData.productCode]);
    const lobDataCustomBranchIdx = useMemo(() => submissionVM.value.lobData[lobName].offerings.findIndex((o) => o.branchCode === BC.BRANCH_CODE_CUSTOM),
        [lobName, submissionVM.value.lobData[lobName].offerings]);
    const lobDataBranchIdx = useMemo(() => lobDataCustomBranchIdx !== -1 ? lobDataCustomBranchIdx : 0, [lobDataCustomBranchIdx])
    const quoteDataCustomBranchIdx = useMemo(() => submissionVM.quoteData?.offeredQuotes?.value?.findIndex((o) => o.branchCode === BC.BRANCH_CODE_CUSTOM), [submissionVM.quoteData?.offeredQuotes]);
    const quoteDataBranchIdx = useMemo(() => quoteDataCustomBranchIdx !== -1 ? quoteDataCustomBranchIdx : 0, [quoteDataCustomBranchIdx])
    const offeringCoveragesPath = useMemo(() => `lobData.${lobName}.offerings.children[${lobDataBranchIdx}].coverages`, [lobName, lobDataBranchIdx]);

    const promoCodePath = isPolicyChange ?
        `lobData.${lobName}.offerings.children[${lobDataBranchIdx}].vehiclePromotion_PV.value` :
        `quoteData.offeredQuotes.children[${quoteDataBranchIdx}].vehiclePromotion_PV.value`;
    const parametersDataVM = _.get(submissionVM, offeringCoveragesPath);
    const parametersData = parametersDataVM.value || { discounts: [], commissions: [] };
    const promoData = _.get(submissionVM, promoCodePath, []);
    const invalidDiscounts = useMemo(() => parametersDataVM.discounts.children.filter((d) => !d.aspects.subtreeValid), [parametersDataVM]);

    const invertErrorMessagesForDiscount = useCallback(() => {
        // Discount values are displayed inverted, thus validation messages also have to be inverted.
        parametersDataVM.discounts.children.forEach((discount) => {
            if (!discount.appliedValue.aspects.valid && discount.appliedValue.value < 0) {
                const messages = discount.appliedValue.aspects.validationMessages
                    .map((m) => ['min', 'Min'].some((str) => m.includes(str))
                        ? m.replace("min", "max").replace("Min", "Max").replace(/-?\d+/, num => Math.abs(parseInt(num)))
                        : m.replace("max", "min").replace("Max", "Min").replace(/-?\d+/, num => Math.abs(parseInt(num)))
                    );
                Object.defineProperty(discount.appliedValue.aspects, 'validationMessages', {value: messages});
            }
        });
    }, [parametersDataVM.discounts]);

    useEffect(() => {
        invertErrorMessagesForDiscount();
    }, [invertErrorMessagesForDiscount]);

    // sort discounts on vehicleNumber, then coverageCategoryPriority and then priority
    const sortDiscounts = useCallback(() => {
        const copyDiscountsData = _.clone(parametersData.discounts);
        copyDiscountsData.sort((d1, d2) => {
            if (d1.vehicleNumber !== d2.vehicleNumber ) {
                return d1.vehicleNumber - d2.vehicleNumber;
            }
            if (d1.coverageCategoryPriority !== d2.coverageCategoryPriority) {
                return d1.coverageCategoryPriority - d2.coverageCategoryPriority;
            }
            return d1.priority - d2.priority;
        });
        parametersData.discounts = copyDiscountsData;
    }, [parametersData]);

    useEffect(() => {
        sortDiscounts();
    }, [sortDiscounts]);

    const onCommissionBlur = useCallback(
        (event, dataObj) => {
            if (_.isEmpty(dataObj.value)) {
                onUpdateParameters(0, dataObj.model);
            }
        }, [onUpdateParameters]
    );

    const resetDiscount = useCallback(
        () => {
            const copyDiscountsData = _.clone(parametersData.discounts);
            copyDiscountsData.forEach((discount) => {
                if (discount.appliedValue < 0) {
                    discount.appliedValue = undefined;
                    discount.updated = true;
                }
            });
            _.set(submissionVM.value, `${offeringCoveragesPath}.discounts`, copyDiscountsData);
            onUpdateParameters(copyDiscountsData, `${offeringCoveragesPath}.discounts`);
        }, [parametersData.discounts, submissionVM.value, offeringCoveragesPath, onUpdateParameters]
    );

    const applyMaxDiscount = useCallback(
        () => {
            const copyDiscountsData = _.clone(parametersData.discounts);
            copyDiscountsData.forEach((discount) => {
                const discountVisible = discount.rangeMin < 0;
                const noSurcharge = (discount.appliedValue || 0) <= 0;
                if (discountVisible && noSurcharge) {
                    discount.appliedValue = discount.rangeMin;
                    discount.updated = true;
                }
            });
            onUpdateParameters(copyDiscountsData, `${offeringCoveragesPath}.discounts.value`);
        }, [parametersData.discounts, offeringCoveragesPath, onUpdateParameters]
    );

    const resetCommissions = useCallback(
        () => {
            const copyCommissionsData = _.clone(parametersData.commissions);
            copyCommissionsData.forEach((commission) => {
                commission.commissionOverride = commission.maximumValue;
                commission.updated = true;
            });
            onUpdateParameters(copyCommissionsData, `${offeringCoveragesPath}.commissions`);
        }, [parametersData, offeringCoveragesPath, onUpdateParameters]
    );

    const setCommissionsToZero = useCallback(
        () => {
            const copyCommissionsData = _.clone(parametersData.commissions);
            copyCommissionsData.forEach((commission) => {
                commission.commissionOverride = 0;
                commission.updated = true;
            });
            onUpdateParameters(copyCommissionsData, `${offeringCoveragesPath}.commissions`);

        }, [parametersData.commissions, offeringCoveragesPath, onUpdateParameters]
    );

    const resetSurcharge = useCallback(
        () => {
            const copySurchargesData = _.clone(parametersData.discounts);
            copySurchargesData.forEach((surcharge) => {
                if (surcharge.appliedValue > 0) {
                    surcharge.appliedValue = null;
                    surcharge.updated = true;
                }
            });
            _.set(submissionVM.value, `${offeringCoveragesPath}.discounts`, copySurchargesData);
            onUpdateParameters(copySurchargesData, `${offeringCoveragesPath}.discounts`);
        }, [parametersData.discounts, submissionVM.value, offeringCoveragesPath, onUpdateParameters]
    );

    const onCommissionChange = useCallback(
        (index, value, path) => {
            const submissionCopy = _.clone(submissionVM);
            _.set(submissionCopy, path.replace('commissionOverride', 'updated'), true)
            onUpdateParameters(value, path);
        }, [submissionVM, onUpdateParameters]
    );

    const handleDiscountChange = useCallback(
        (index, value, path) => {
            const submissionCopy = _.clone(submissionVM);
            _.set(submissionCopy, path.replace('appliedValue', 'updated'), true)
            // value is positive on UI but stored negatively
            // Thats why we invert the value before writing to the model.
            onUpdateParameters(_.isNaN(-value) ? undefined : -value, path);
        }, [submissionVM, onUpdateParameters]
    );

    const handleSurchargeChange = useCallback(
        (index, value, path) => {
            const submissionCopy = _.clone(submissionVM);
            _.set(submissionCopy, path.replace('appliedValue', 'updated'), true)
            onUpdateParameters(value, path);
        }, [submissionVM, onUpdateParameters]
    );

    const onPromoCodeChange = useCallback(
        (index, value, path) => {
            onUpdateParameters(value, path);
        }, [onUpdateParameters]
    );

    const generateOverrides = useCallback(() => {
        const overrideProps = {};
        // discount and surcharge share the same PC discount element.
        // A discount is from rangeMin all negativ values up to 0, and surcharge is from 0 up to rangeMax
        parametersData.discounts.forEach((discount, index) => {
            // discount element props
            overrideProps[`discountFlexContainer${index}`] = {
                visible: discount.rangeMin < 0,
            };
            overrideProps[`discountCoverageName${index}`] = {
                content: discount.name,
            };
            overrideProps[`rangeMaxDiscount${index}`] = {
                content: `${Math.abs(discount.rangeMin || 0)}% max.`
            };
            overrideProps[`rangeMinDiscount${index}`] = {
                content: `${(discount?.rangeMax || 0) >= 0 ? 0 : Math.abs(discount.rangeMax)}% min.`
            };
            overrideProps[`discountVehicleName${index}`] = {
                content: discount.vehicleName,
                visible: (index === 0 || parametersData.discounts[index].vehicleName !== parametersData.discounts[index - 1].vehicleName)
            };
            overrideProps[`discountValueId${index}`] = {
                disabled: discount.appliedValue > 0,
                value: discount.appliedValue > 0 ? 0 : -discount.appliedValue || undefined,
            };

            // surcharge element props
            overrideProps[`surchargeFlexContainer${index}`] = {
                visible: discount.rangeMax > 0,
            };
            overrideProps[`surchargeCoverageName${index}`] = {
                content: discount.name,
            };
            overrideProps[`rangeMinSurcharge${index}`] = {
                content: `${discount.rangeMin < 0 ? 0 : discount.rangeMin}% min.`,
            };
            overrideProps[`rangeMaxSurcharge${index}`] = {
                content: `${discount.rangeMax}% max.`,
            };
            overrideProps[`surchargeVehicleName${index}`] = {
                content: discount.vehicleName,
                visible: (index === 0 || parametersData.discounts[index].vehicleName !== parametersData.discounts[index - 1].vehicleName)
            };
            overrideProps[`surchargeValueId${index}`] = {
                disabled: discount.appliedValue < 0,
                value: discount.appliedValue < 0 ? 0 : discount.appliedValue,
            };
        });

        parametersData.commissions?.forEach((commission, index) => {
            overrideProps[`commissionCoverageName${index}`] = {
                content: commission.name,
            };
            overrideProps[`commissionValueId${index}`] = {
                value: commission.commissionOverride
            };
            overrideProps[`rangeMaxCommission${index}`] = {
                content: `${commission.maximumValue}% max.`,
            };
            overrideProps[`commissionVehicleName${index}`] = {
                content: commission.vehicleName,
                visible: (index === 0 || parametersData.commissions[index].vehicleName !== parametersData.commissions[index - 1].vehicleName)
            };
        });

        promoData.forEach((promo, index) => {
            overrideProps[`vehicleName${index}`] = {
                content: promo.vehicleName_PV,
            };
            overrideProps[`promocodeSelectId${index}`] = {
                availableValues: promo.promotion_PV,
                path: `${promoCodePath}[${index}].selectedPromotion_PV.code`,
                value: _.get(submissionVM.value, `${promoCodePath}[${index}].selectedPromotion_PV.code`)
            };
        });

        return overrideProps;
    }, [parametersData.commissions, parametersData.discounts, promoCodePath, promoData, submissionVM.value]);

    const overrideProps = {
        '@field': {
            showRequired: true,
            showError: true,
            readOnly
        },
        buttonContainer: {
            visible: !readOnly
        },
        accordion: {
            disabled: isLoading
        },
        discount: {
            errorState: invalidDiscounts.some((d) => d.appliedValue.value < 0),
            visible: parametersData?.discounts?.some((d) => d.rangeMin < 0)
        },
        commission: {
            errorState: !parametersDataVM.commissions.aspects.subtreeValid,
            visible: !_.isEmpty(parametersData.commissions)
        },
        surcharge: {
            errorState: invalidDiscounts.some((d) => d.appliedValue.value > 0),
            visible: parametersData?.discounts?.some((d) => d.rangeMax > 0)
        },
        promoCode: {
            visible: !_.isUndefined(_.get(submissionVM, promoCodePath))
        },
        discountsId: {
            path: `${offeringCoveragesPath}.discounts.children`
        },
        commissionsId: {
            path: `${offeringCoveragesPath}.commissions.children`
        },
        surchargeId: {
            path: `${offeringCoveragesPath}.discounts.children`
        },
        promoId: {
            path: `${promoCodePath}`
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            onCommissionBlur,
            resetDiscount,
            applyMaxDiscount,
            resetCommissions,
            setCommissionsToZero,
            onCommissionChange,
            handleDiscountChange,
            handleSurchargeChange,
            resetSurcharge,
            onPromoCodeChange,
        },
    };

    return (
        <ModalNext isOpen className={styles.parametersModal}>
            <ModalHeader title={titleMsg} status="info"
                onClose={onCloseParametersModal} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onValidationChange={onValidate}
                />
            </ModalBody>
            <ModalFooter>
                {isLoading ? <div className={styles.loaderContainer}><Loader/></div> :
                    (
                        !readOnly && (
                            <Button onClick={onSaveParameters} disabled={!parametersDataVM.aspects.subtreeValid} type='filled'>
                                {saveButtonText}
                            </Button>
                        )
                    )
                }
            </ModalFooter>
        </ModalNext>
    );
}

PackParametersModal.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    showRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    onCloseParametersModal: PropTypes.func.isRequired,
    onSaveParameters: PropTypes.func,
    onUpdateParameters: PropTypes.func
};

export default PackParametersModal;
