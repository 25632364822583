import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ServiceManager } from '@jutro/legacy/services';
import { Icon, withModalContext } from '@jutro/components';
import { LobIconUtil } from '@xengage/gw-portals-util-js';
import { PaymentComponent, Currency as CurrencyField } from 'gw-components-platform-react';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { GatewayBillingService, GatewayPaymentService } from 'gw-capability-gateway-billing';
import { Link, withRouter } from 'react-router-dom';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './MakePayment.metadata.json5';
import makePaymentStyles from './MakePayment.module.scss';
import messages from './MakePayment.messages';

class MakePayment extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        location: PropTypes.shape({
            state: PropTypes.shape({
                accountNumber: PropTypes.string,
                xCenter: PropTypes.string.isRequired,
                billingData: PropTypes.shape({}).isRequired
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                accountNumber: PropTypes.string
            })
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired
    };

    state = {
        invoiceData: [],
        hideShowInvoice: [],
        showTotalPayment: true,
        showEnterPayment: false,
        totalPayment: 0,
        selectedInvoiceIds: [],
        amountVM: {},
        showNoInvoiceMsg: false
    };

    localeService = ServiceManager.getService('locale-service');

    componentDidMount = () => {
        const {
            viewModelService,
            authHeader,
            location: {
                state: { accountNumber }
            }
        } = this.props;

        GatewayBillingService.getAccountBillingSummaryData(accountNumber, authHeader).then(
            (responseData) => {
                const invoiceData = responseData;
                const totalPaymentAmount = invoiceData.activeInvoices.reduce((acc, obj) => {
                    return acc + obj.amountDue.amount;
                }, 0);
                this.setState({
                    invoiceData: invoiceData,
                    totalPayment: totalPaymentAmount,
                    selectedInvoiceIds: invoiceData.activeInvoices.map((data) => data.id)
                });
            }
        );

        const amountVM = viewModelService.create(
            {
                currency: this.localeService.getDefaultCurrencyCode()
            },
            'bc',
            'edge.capabilities.currency.dto.AmountDTO'
        );
        this.setState({ amountVM });
    };

    getPaymentScheduleDataTable = (invoiceData) => {
        const translator = this.context;
        let invoiceArrayResult = [];
        invoiceArrayResult = invoiceData.map((data) => {
            const objData = {
                line: data.lineOfBusiness,
                policy: data.policyId,
                chargeType: data.chargeName,
                itemType: translator({
                    id: `typekey.InvoiceItemType.${data.invoiceItemType}`,
                    defaultMessage: data.invoiceItemType
                }),
                amount: data.unsettledGrossAmount
            };
            return objData;
        });
        return invoiceArrayResult;
    };

    getCell = (items, index, property) => {
        return items[property.id];
    };

    getFormattedCurrency = (item, index, property) => {
        return (
            <CurrencyField
                id={`currency_${index}`}
                value={item[property.id]}
                readOnly
                hideLabel
                className={makePaymentStyles.currencyAmount}
            />
        );
    };

    generateInvoiceDataOverrides = () => {
        const { invoiceData, hideShowInvoice } = this.state;

        const overrides = invoiceData.activeInvoices.map((invoiceMapData, index) => {
            const visibleValue = hideShowInvoice.find((data) => Number(data.id) === index);
            return {
                [`invoiceSummaryCheck${index}`]: {
                    value: visibleValue ? visibleValue.checkInvoice : true,
                    onValueChange: this.selectInvoice(index)
                },
                [`hideInvoiceDetailsLabel${index}`]: {
                    visible: visibleValue ? !visibleValue.viewInvoice : false,
                    onClick: this.handleHideShowInvoiceDetails(index)
                },
                [`viewInvoiceDetailsLabel${index}`]: {
                    visible: visibleValue ? visibleValue.viewInvoice : true,
                    onClick: this.handleHideShowInvoiceDetails(index)
                },
                [`invoiceDataTable${index}`]: {
                    visible: visibleValue ? !visibleValue.viewInvoice : false,
                    data: this.getPaymentScheduleDataTable(invoiceMapData.invoiceItems)
                }
            };
        });
        return Object.assign({}, ...overrides);
    };

    onValueChangePaymentAmount = (value) => {
        const { amountVM } = this.state;
        _.set(amountVM, 'amount.value', value);
        this.setState({ amountVM });
    };

    selectInvoice = (index) => () => {
        const {
            hideShowInvoice, invoiceData, totalPayment, selectedInvoiceIds
        } = this.state;
        const newInvoiceData = hideShowInvoice.find((data) => data.id === index);
        const activeInvoiceData = invoiceData.activeInvoices.find((data, indexNo) => {
            return indexNo === index;
        });
        let check = true;
        if (newInvoiceData) {
            newInvoiceData.checkInvoice = !newInvoiceData.checkInvoice;
            check = newInvoiceData.checkInvoice;
            this.setState({
                hideShowInvoice: [...hideShowInvoice, newInvoiceData]
            });
        } else {
            const newData = {
                id: index,
                checkInvoice: false,
                viewInvoice: true
            };
            this.setState({
                hideShowInvoice: [...hideShowInvoice, newData]
            });
            check = false;
        }

        if (activeInvoiceData) {
            if (check) {
                this.setState({
                    totalPayment: totalPayment + activeInvoiceData.amountDue.amount,
                    selectedInvoiceIds: [...selectedInvoiceIds, activeInvoiceData.id]
                });
            } else {
                this.setState({
                    totalPayment: totalPayment - activeInvoiceData.amountDue.amount,
                    selectedInvoiceIds: selectedInvoiceIds.filter(
                        (item) => item !== activeInvoiceData.id
                    )
                });
            }
        }
    };

    handleHideShowInvoiceDetails = (index) => () => {
        const { hideShowInvoice } = this.state;
        const newInvoiceData = hideShowInvoice.find((data) => data.id === index);
        if (newInvoiceData) {
            newInvoiceData.viewInvoice = !newInvoiceData.viewInvoice;
            this.setState({
                hideShowInvoice: [...hideShowInvoice, newInvoiceData]
            });
        } else {
            const newData = {
                id: index,
                checkInvoice: true,
                viewInvoice: false
            };
            this.setState({
                hideShowInvoice: [...hideShowInvoice, newData]
            });
        }
    };

    handleChangePaymentAmount = () => {
        this.setState({
            showTotalPayment: false,
            showEnterPayment: true
        });
    };

    handleCancelEnteredPaymentAmount = () => {
        this.setState({
            showTotalPayment: true,
            showEnterPayment: false
        });
    };

    getProductImage = (item) => {
        const icon = LobIconUtil.getFontIcon(item.productCode);
        return (
            <Icon
                icon={icon}
                title={item.productCode}
            />
        );
    };

    getPolicyNoLink = (item, index, { id: property }) => {
        const translator = this.context;
        const toolTipMessage = translator(messages.policyNumber);
        return (
            <>
                <Link
                    to={`/policies/${item[property]}/summary`}
                    className={makePaymentStyles.removeLinkStyle}
                    title={toolTipMessage}
                >
                    {item[property]}
                </Link>
            </>
        );
    };

    onResultCallback = (paymentData, paymentMethod) => {
        const { authHeader, match, history, modalContext } = this.props;
        const {
            params: { accountNumber }
        } = match;
        const {
            selectedInvoiceIds, showEnterPayment, totalPayment, amountVM
        } = this.state;
        const enteredPaymentAmount = _.get(amountVM, 'amount.value', 0);

        if (selectedInvoiceIds.length === 0) {
            this.setState({
                showNoInvoiceMsg: true
            });
        } else {
            const paymentInstrument = {};

            if (paymentMethod === 'wire') {
                const bankAccountData = {
                    bankAccountType: _.get(paymentData, 'bankAccountType.value.code'),
                    bankAccountNumber: _.get(paymentData, 'bankAccountNumber.value'),
                    bankABANumber: _.get(paymentData, 'bankABANumber.value'),
                    bankName: _.get(paymentData, 'bankName.value')
                };

                paymentInstrument.paymentMethod = paymentMethod;
                paymentInstrument.bankAccountData = bankAccountData;
                paymentInstrument.creditCardData = null;
            } else {
                const creditBankAccountData = {
                    creditCardIssuer: _.get(paymentData, 'creditCardIssuer.value.code'),
                    creditCardNumber: _.get(paymentData, 'creditCardNumber.value'),
                    creditCardExpDate: _.get(paymentData, 'creditCardExpDate.value')
                };

                paymentInstrument.paymentMethod = 'creditcard';
                paymentInstrument.creditCardData = creditBankAccountData;
                paymentInstrument.bankAccountData = null;
            }

            const request = {
                invoiceIds: selectedInvoiceIds,
                amountPaid: showEnterPayment
                    ? _.floor(_.toNumber(enteredPaymentAmount), 1)
                    : _.floor(totalPayment, 1),
                paymentInstrument: paymentInstrument
            };
            GatewayPaymentService.makeDirectBillPayment(accountNumber, request, authHeader)
                .then(() => {
                    history.push(`/make-payment-confirmation/${accountNumber}`);
                })
                .catch(() => {
                    modalContext.showAlert({
                        title: messages.paymentRequestFailed,
                        message: messages.sorryYourPaymentCould,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                });
        }
    };

    onCancelPayment = () => {
        const {
            location: {
                state: { accountNumber, billingData }
            },
            history
        } = this.props;
        const nextPath = `/accounts/${accountNumber}/billingAndPayment`;
        history.push({
            pathname: nextPath,
            accountDetailsData: {
                accountNumber: accountNumber
            },
            billingDataFromMakePayment: billingData
        });
    };

    getManualValidationsMessages = () => {
        const { totalPayment, amountVM } = this.state;
        const translator = this.context;
        const enteredPaymentAmount = _.get(amountVM, 'amount.value', 0);

        if (_.get(amountVM, 'amount.aspects.validationMessages', []).length > 0) {
            return _.get(amountVM, 'amount.aspects.validationMessages', [])[0];
        }
        if (_.isNaN(_.toNumber(enteredPaymentAmount)) || enteredPaymentAmount === '') {
            return translator(messages.paymentNumbersOnly);
        }
        if (_.toNumber(enteredPaymentAmount) < 0 || _.isUndefined(enteredPaymentAmount)) {
            return translator(messages.paymentPositiveNumber);
        }
        if (_.floor(_.toNumber(enteredPaymentAmount), 1) > _.floor(totalPayment, 1)) {
            return translator(messages.overpayNotSupported);
        }
        return '';
    };

    render() {
        const {
            invoiceData,
            showTotalPayment,
            showEnterPayment,
            totalPayment,
            amountVM,
            showNoInvoiceMsg
        } = this.state;
        const enteredPaymentAmount = _.get(amountVM, 'amount.value', 0);
        const {
            location: { state }
        } = this.props;
        if (_.isEmpty(invoiceData)) {
            return null;
        }

        const { activeInvoices } = invoiceData;
        const totalPaymentAmount = {
            amount: 0,
            currency: ''
        };
        if (activeInvoices && activeInvoices[0]) {
            totalPaymentAmount.currency = activeInvoices[0].amountDue.currency;
            totalPaymentAmount.amount = totalPayment;
        }

        const manualErrorMessage = this.getManualValidationsMessages();
        const checkAmount = manualErrorMessage !== '';

        const overrides = {
            enterPaymentAmount: {
                value:
                    enteredPaymentAmount === 0
                        ? _.floor(totalPaymentAmount.amount, 1)
                        : enteredPaymentAmount,
                onValueChange: this.onValueChangePaymentAmount
            },
            totalPaymentSelected: {
                value: totalPaymentAmount
            },
            totalPaymentSelectedContainer: {
                visible: showTotalPayment
            },
            enterPaymentContainer: {
                visible: showEnterPayment
            },
            makePaymentInvoiceContainer: {
                visible: activeInvoices.length > 0
            },
            makePaymentNoPayableInvoicesMessage: {
                visible: activeInvoices.length === 0
            },
            totalPaymentSelectedContainerMainDiv: {
                visible: _.floor(totalPaymentAmount.amount, 2) > 0
            },
            paymentComponent: {
                xCenter: state ? state.xCenter : '',
                isDisabled: checkAmount
            },
            errorNotification: {
                message: manualErrorMessage,
                visible: checkAmount
            },
            noInvoiceMsg: {
                visible: showNoInvoiceMsg
            },
            ...this.generateInvoiceDataOverrides()
        };

        const resolvers = {
            resolveClassNameMap: makePaymentStyles,
            resolveComponentMap: {
                paymentcomponent: PaymentComponent
            },
            resolveCallbackMap: {
                getCell: this.getCell,
                getFormattedCurrency: this.getFormattedCurrency,
                handleHideShowInvoiceDetails: this.handleHideShowInvoiceDetails,
                handleChangePaymentAmount: this.handleChangePaymentAmount,
                handleCancelEnteredPaymentAmount: this.handleCancelEnteredPaymentAmount,
                selectInvoice: this.selectInvoice,
                getProductImage: this.getProductImage,
                getPolicyNoLink: this.getPolicyNoLink,
                onResultCallback: this.onResultCallback,
                onCancelPayment: this.onCancelPayment
            }
        };

        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, invoiceData, id, path, overrides);
        };

        return (
            <div className={makePaymentStyles.makePayment}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={invoiceData}
                    overrideProps={overrides}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                    resolveValue={readValue}
                />
            </div>
        );
    }
}

export const MakePaymentComponent = withModalContext(MakePayment);
export default withRouter(withViewModelService(withAuthenticationContext(withModalContext(MakePayment))));
