import { defineMessages } from 'react-intl';

export default defineMessages({
    vehicleDetails: {
        "id": 'quoteandbind.ppv.views.ppv-confirmation.Vehicle Details',
        "defaultMessage": "Vehicle Details"
    },
    found1result: {
        "id": "quoteandbind.ppv.directives.templates.ppv-informex-vehicle-confirmation-popup.message",
        "defaultMessage": "We found only 1 matching result based on your input"
    }
});
