import { defineMessages } from 'react-intl';

export default defineMessages({
    quickQuoteSummaryTitle: {
        id: 'quoteandbind.quickquote.common.views.quick-quote-policy-info.Summary',
        defaultMessage: 'Summary'
    },
    submissionID: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.SubmissionID',
        defaultMessage: 'Submission ID'
    },
    policyHolder: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Policy Holder',
        defaultMessage: 'Contract Holder'
    },
    referToUnderwriter: {
        id: 'gateway.views.job-uwissues.Refer to underwriter(optional)',
        defaultMessage: 'Refer to underwriter (optional)'
    },
    underwritingIssueQuote: {
        id: 'gateway.views.job-uwissues.qq.Below you can raise an underwriting issue for this quote.',
        defaultMessage: 'Below you can raise an underwriting issue for this tariff.'
    },
    blockQuote: {
        id: 'gateway.views.job-uwissues.Please not that this wil block the quote',
        defaultMessage: 'Keep in mind that you will not be able to make changes as long as the company does not grant you the appropriate access'
    },
    underwriterMaxLengthWarning:{
        id: 'gateway.views.job-uwissues.Refer to underwriter(optional) field max length warning',
        defaultMessage: 'Maximum 255 characters allowed for this field'
    }
});
