/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { Flex, Grid } from '@jutro/layout';
import { Loader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { BusinessConstant } from 'pv-portals-util-js';
import { PVVehicleTypeAvailabilityService } from 'pv-capability-policyjob';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import messages from './VehicleType.messages';
import {
    VanIcon,
    CarIcon,
    MobileHomeIcon,
    MinibusIcon,
    OtherIcon
} from './VehicleTypeMainIcons';
import styles from './VehicleType.module.scss';

import { RadioButtonField } from '@jutro/legacy/components';

const CustomRadio = ({ displayName, icon: Icon, isSelected, onSelect }) => {

    const labelClassName = cx(
        styles.option,
        isSelected && styles.selected,
        false
    );
    const translator = useTranslator();
    const translatedDisplayName = translator(displayName);

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div className={labelClassName} onClick={onSelect}>
            <Flex
                direction='column'
                alignItems='center'
                justifyContent='center'
                gap='medium'
            >
                <Icon selected={isSelected}/>
                {translatedDisplayName}
            </Flex>
        </div>
    );
};

const VehicleTypeMain = ({
    vehicleCategoryValue,
    vehicleTypeValue,

    onValueChange, // (category, type) at the same time

    productCode,
    vehicle, // current vehicle
    vehicles, // all vehicles (including current)
    virtualProduct,
    offerNumber
}) => {
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();

    // available main vehicle options
    const [actualMainVehicleTypes, setActualMainVehicleTypes] = useState([]);

    // available remaining vehicles options (super dynamic)
    const [actualRemainingVehicleTypes, setActualRemainingVehicleTypes] = useState([]);


    const [isLoading, setLoading] = useState(true);
    const [showMoreOptions, setShowMoreOptions] = useState(false);

    // NOTE: category___type  e.g.  TourismAndBusiness___Car_PV
    const [allMainVehicleTypes] = useState([
        {
            id: 'TourismAndBusiness___Car_PV',
            displayName: {
                id: 'typekey.VehicleType.Car_PV',
                defaultMessage: 'Car',
            },
            ico: CarIcon
        },
        {
            id: 'TourismAndBusiness___Van_PV',
            displayName: {
                id: 'typekey.VehicleType.Van_PV',
                defaultMessage: 'Van',
            },
            ico: VanIcon,
        },
        {
            id: 'TourismAndBusiness___Minibus_PV',
            displayName: {
                id: 'typekey.VehicleType.Minibus_PV',
                defaultMessage: 'Minibus',
            },
            ico: MinibusIcon,
        },
        {
            id: 'Mobilhome___Mobilhome_PV',
            displayName: {
                id: 'typekey.VehicleType.Mobilhome_PV',
                defaultMessage: 'Mobilhome',
            },
            ico: MobileHomeIcon,
        },
    ]);
    const [moreOptionsType] = useState({
        id: 'other___other',
        displayName: {
            id: 'typekey.VehicleType.Other_PV',
            defaultMessage: 'Other',
        },
        ico: OtherIcon,
    });

    useEffect(() => {

        // Applies dynamic filter for available vehicle categories & vehicle types

        const applyFilters = (availableVehicleCategories, availableVehicleTypes) => {

            const calcVehiclesTypesForCategory = (categoryType) => {
                if (!availableVehicleCategories.includes(categoryType.code)) {
                    // if category is not available, for sure there are no types too!
                    return [];
                }
                const typelist = viewModelService.productMetadata.get('pc').types.getTypelist('VehicleType');
                const typeCodesWithCategory = typelist.codes.filter((item) => item.belongsToCategory(categoryType));
                const availableTypeCodes = typeCodesWithCategory.filter((item) => availableVehicleTypes.includes(item.code));
                const radioButtonList = availableTypeCodes.map((item) => {
                    return {
                        // id format = category___type, single selection provides 2 values
                        id: `${categoryType.code}___${item.code}`,
                        displayName: translator({ id: item.name, defaultMessage: item.name })
                    };
                });
                return radioButtonList;
            };

            // 1. verify available main types
            const actualMainList = allMainVehicleTypes.filter(
                (item) => availableVehicleCategories.findIndex((item2) => item.id.startsWith(item2)) !== -1
            );
            setActualMainVehicleTypes(actualMainList);

            // 2. get out ALL available categories and ALL available types
            const categories = viewModelService.productMetadata.get('pc').types.getTypelist('PPVVehicleCategory_PV').codes;
            const allCategoriesAndTypesList = categories.map((categoryType) => {
                return {
                    categoryCode: categoryType.code,
                    categoryDisplayName: translator({ id: categoryType.name, defaultMessage: categoryType.name }),
                    vehicleTypes: calcVehiclesTypesForCategory(categoryType)
                };
            });

            // 3. filter ALL available types by removing already existing main types (we don't want to display them twice :) )
            allCategoriesAndTypesList.forEach(
                (category) => {
                    category.vehicleTypes = category.vehicleTypes.filter(
                        (vehicleType) => actualMainList.findIndex((mainVehicleType) => vehicleType.id === mainVehicleType.id) === -1
                    );
                }
            );
            setActualRemainingVehicleTypes(allCategoriesAndTypesList);
        };

        const countVehicles = vehicles.length - 1;
        let countVehiclesTB = 0;

        const firstTBvehicle = _.find(vehicles, (veh) => {
            return veh !== vehicle
                    && veh.category === BusinessConstant.VEHICLE_CATEGORY_TOURISM_AND_BUSINESS;
        });

        countVehiclesTB = firstTBvehicle ? 1 : 0;

        const isChannelDirect = false;

        PVVehicleTypeAvailabilityService.availableVehicleCategoriesTypes(virtualProduct, countVehicles, countVehiclesTB, isChannelDirect, offerNumber, authHeader)
            .then((result) => {
                applyFilters(result.availableVehicleCategories, result.availableVehicleTypes);
                setLoading(false);
            });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translator, viewModelService, allMainVehicleTypes, offerNumber, virtualProduct, authHeader]);

    const onLocalValueChange = useCallback((primaryVehicleType, optionId) => {
        const splittedOptionId = optionId.split("___");
        if (splittedOptionId[0] === "other" && splittedOptionId[1] === "other") {
            onValueChange(null, null);
            setShowMoreOptions(true);
        } else {
            onValueChange(splittedOptionId[0], splittedOptionId[1]);
            setShowMoreOptions(!primaryVehicleType);
        }
    }, [onValueChange]);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    const currentSelectedValue = `${vehicleCategoryValue}___${vehicleTypeValue}`;

    return (
        <React.Fragment>
            <div className={styles.title}>{translator(productCode === "PersonalVehicle_PV" ? messages.insureVehicleMsgPL : messages.insureVehicleMsgCL)}</div>
            <Grid gap='medium' className={cx(styles.optionContainer)}
            >
                {actualMainVehicleTypes.map((option) => (
                    <CustomRadio
                        key={option.id}
                        displayName={option.displayName}
                        icon={option.ico}
                        isSelected={option.id === currentSelectedValue}
                        onSelect={() => onLocalValueChange(true, option.id)}
                    />
                ))}
                <CustomRadio
                    key={moreOptionsType.id}
                    displayName={moreOptionsType.displayName}
                    icon={moreOptionsType.ico}
                    isSelected={showMoreOptions}
                    onSelect={() => onLocalValueChange(false, moreOptionsType.id)}
                />
            </Grid>

            {showMoreOptions && (
                <Flex>
                    {actualRemainingVehicleTypes.filter((option) => option.vehicleTypes.length > 0).map((option) => (
                        <RadioButtonField
                            availableValues={option.vehicleTypes}
                            id={`radio${option.categoryCode}`}
                            key={`radio${option.categoryCode}`}
                            labelPosition='top'
                            value={currentSelectedValue}
                            label={option.categoryDisplayName}
                            onValueChange={(optionId) => onLocalValueChange(false, optionId)}
                            labelClassName={styles.labelClassName}
                        />
                    ))}
                </Flex>
            )}

        </React.Fragment>
    );
};
export default VehicleTypeMain;
