import { defineMessages } from 'react-intl';

export default defineMessages({
    ownReference: {
        id: 'quoteandbind.ppv.directives.templates.wizard-sidebar-additional.Own Reference',
        defaultMessage: 'Own Reference'
    },
    saving: {
        id: 'quoteandbind.ppv.directives.templates.wizard-sidebar-additional.Save',
        defaultMessage: 'Saving...'
    }
});
