/* eslint-disable camelcase */

const _ = require('lodash');
const { BusinessConstant, LocalDateUtil } = require('pv-portals-util-js');

const validateInvoiceField_PV = (invoiceValue, optionValue, catalogValue, isAgent) => {
    const optValue = (optionValue !== undefined) ? optionValue : 0;
    const catValue = (catalogValue !== undefined) ? catalogValue : 0;
    const minimumInvoiceValue = ((catValue + optValue) * 0.6501);
    const maximumInvoiceValue = ((catValue + optValue) * 0.9499);

    return !(invoiceValue < minimumInvoiceValue || invoiceValue > maximumInvoiceValue);
};

const validateEnterpriseNumber_PV = (enterpriseNumber) => {
    let lastTwoDigits;
    let diffWithNintySeven;
    let isValid = false;
    if (enterpriseNumber === BusinessConstant.INVALID_ENTERPRISENUMBER) {
        isValid = true;
    } else if (enterpriseNumber.length === 9 || enterpriseNumber.length === 10) {
        const remainingNumber = enterpriseNumber.substring(0, enterpriseNumber.length - 2);
        let convertedEnterpriseNumber = 0;
        if (remainingNumber != null && remainingNumber !== '') {
            convertedEnterpriseNumber = remainingNumber;
        }
        lastTwoDigits = enterpriseNumber.substring(enterpriseNumber.length - 2, enterpriseNumber.length);
        const enterpriseNumberDividedByNintySeven = convertedEnterpriseNumber / 97;
        const enterpriseNumberDividedByNintySevenInt = Math.floor(enterpriseNumberDividedByNintySeven);
        const enterpriseNumberMultipliedByNintySeven = enterpriseNumberDividedByNintySevenInt * 97;
        const differenceInNumbers = convertedEnterpriseNumber - enterpriseNumberMultipliedByNintySeven;
        diffWithNintySeven = 97 - differenceInNumbers;
        isValid = (_.toInteger(lastTwoDigits) === _.toInteger(diffWithNintySeven));
    }
    return isValid;
};

const matchesStringPattern_PV = (pattern, value) => {
    return !pattern || !value || new RegExp(`^${pattern}$`).test(value);
};

const findNumberOfMonthsBetweenDates_PV = (fromDate, toDate) => {
    const MAX_MONTH_DIFF_FOR_CANCELLATION = 3;
    const date1 = new Date(Date.now());
    const date2 = new Date(toDate);
    date1.setDate(date1.getDate());
    const fromDateDay = date1.getDate();
    const fromDateMonth = date1.getMonth() + 1;
    const fromDateYear = date1.getFullYear();
    date2.setDate(toDate.getDate());
    const toDateDay = date2.getDate();
    const toDateMonth = date2.getMonth() + 1;
    const toDateYear = date2.getFullYear();
    const monthDifference = toDateMonth - fromDateMonth;
    const dayDifference = toDateDay - fromDateDay;
    const totalMonths = ((toDateYear - fromDateYear) * 12) + monthDifference;

    if (totalMonths < MAX_MONTH_DIFF_FOR_CANCELLATION) {
        return false;
    }

    if (totalMonths === MAX_MONTH_DIFF_FOR_CANCELLATION && (dayDifference <= 0)) {
        return false;
    }

    return true;
};

const isCurrency_PV = (obj) => {
    if (_.isUndefined(obj) || obj === null || obj === '') {
        return false;
    }

    if (typeof obj === 'number') {
        obj = obj.toString();
    }

    if (typeof obj !== 'string') {
        return false;
    }

    if (!obj.match(/^-?\d*(.\d*)?$/)) {
        return false;
    }

    return true;
}

module.exports = {
    addYears_PV: LocalDateUtil.addYears_PV,
    addYearsMonthsDays_PV: LocalDateUtil.addYearsMonthsDays_PV,
    today_PV: LocalDateUtil.today_PV,
    validateLocalDate_PV: LocalDateUtil.validateLocalDate_PV,
    validateInvoiceField_PV,
    validateEnterpriseNumber_PV,
    matchesStringPattern_PV,
    findNumberOfMonthsBetweenDates_PV,
    isCurrency_PV
}
