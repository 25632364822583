import React, { useContext, useCallback, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useModal } from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { messages as platformMessagesPV } from 'pv-platform-translations';
import { BusinessConstant, EdgeErrorParser } from "pv-portals-util-js";

import policychangeMessages from '../../policychange.messages';
import metadata from './DeclarationMifidPage.metadata.json5';

function DeclarationMifidPage(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { EndorsementService } = useDependencies('EndorsementService');
    const { wizardData: policyChangeVM, updateWizardData } = props;
    const [showError, setShowError] = useState(false);
    const { onValidate, isComponentValid, initialValidation } = useValidation('DeclarationMifidPage');
    const history = useHistory();
    const {
        showAlert
    } = useModal();

    useEffect(() => {
        const vm = viewModelService.changeContext(policyChangeVM, {
            DeclarationMifid: true
        });
        updateWizardData(vm);
        // Only on first render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(policyChangeVM);
            _.set(newSubmissionVM, path, value);
            updateWizardData(newSubmissionVM);
        },
        [policyChangeVM, updateWizardData, viewModelService]
    );

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setShowError(true);
            return false;
        }

        try {
            policyChangeVM.value = await EndorsementService.saveDeclarationMifid(
                policyChangeVM.value,
                authHeader
            );
        } catch (e) {
            if (EdgeErrorParser.isUWIssueError(e)) {
                const result = await showAlert({
                    title: platformMessagesPV.uwIssueErrorTitle,
                    message: platformMessagesPV.uwIssueErrorMsg,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: platformMessagesPV.uwIssueErrorBtn
                });
                if (result === 'confirm') {
                    const jobID = _.get(policyChangeVM, 'jobID.value');
                    return history.push({ pathname: `/change/${jobID}/summary` });
                }
            } else {
                showAlert({
                    title: platformMessages.genericError,
                    message: platformMessages.genericErrorMessage,
                    status: 'error',
                    icon: 'mi-error-outline'
                });
            }
            return false;
        }

        try {
            const validPaymentPlanChange = _.get(policyChangeVM, 'validPaymentPlanChange_PV.value');
            const lastInvoiceNotPaid = _.get(policyChangeVM, 'bindData_PV.paymentDetails.lastInvoiceNotPaid_PV.value');
            const viviumBrand = _.get(policyChangeVM, 'baseData.brand_PV.value.code') === BusinessConstant.PV_BRAND_VIVIUM;

            if ((viviumBrand && validPaymentPlanChange === true)
                || (viviumBrand && validPaymentPlanChange === false && lastInvoiceNotPaid)
                || (!viviumBrand && validPaymentPlanChange === true)) {
                policyChangeVM.value = await EndorsementService.proposeEndorsement_PV(
                    policyChangeVM.jobID.value,
                    authHeader
                );
            } else {
                showAlert({
                    title: policychangeMessages.endorsementPaymentValidationErrorTitle,
                    message: policychangeMessages.endorsementPaymentValidationError,
                    status: 'error',
                    icon: 'mi-error-outline'
                });
                return false;
            }
        } catch (e) {
            showAlert({
                title: platformMessagesPV.unableToQuoteTitle,
                message: platformMessagesPV.unableToQuoteMsg,
                status: 'error',
                icon: 'mi-error-outline'
            });
            return false;
        }

        return policyChangeVM;
    }, [isComponentValid, policyChangeVM, EndorsementService, authHeader, showAlert, history]);

    const isBlockingUWIssues = useMemo(() => {
        const underwritingIssues = policyChangeVM.underwritingIssues?.value || [];
        const offeredQuotes = (policyChangeVM.quoteData?.value?.offeredQuotes || [])[0];
        const hasBlockingUWIssues = !!offeredQuotes?.hasBlockingUWIssues;
        const onlyFreeTextUWBlockBind = underwritingIssues.length === 1 && underwritingIssues[0].issueType_PV === 'RequestFromProducer';
        return hasBlockingUWIssues && !onlyFreeTextUWBlockBind;
    }, [policyChangeVM.underwritingIssues?.value, policyChangeVM.quoteData?.value?.offeredQuotes]);

    const overrideProps = {
        '@field': {
            showOptional: false,
        },
        declarationsDetails: {
            submissionVM: policyChangeVM,
            isBlockingUWIssues,
            showError
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

DeclarationMifidPage.propTypes = wizardProps;
export default DeclarationMifidPage;
