import { defineMessages } from 'react-intl';

export default defineMessages({
    Accounts: {
        id: 'gateway.views.account-landing.Accounts',
        defaultMessage: 'Accounts'
    },
    RecentlyViewed: {
        id: 'gateway.views.account-landing.Recently Viewed',
        defaultMessage: 'Recently Viewed'
    },
    RecentlyCreated: {
        id: 'gateway.views.account-landing.Recently Created',
        defaultMessage: 'Recently Created'
    },
    PersonalAccounts: {
        id: 'gateway.views.account-landing.Personal Accounts',
        defaultMessage: 'Personal Accounts'
    },
    CommercialAccounts: {
        id: 'gateway.views.account-landing.Commercial Accounts',
        defaultMessage: 'Commercial Accounts'
    },
    Account: {
        id: 'gateway.views.account-landing.Account',
        defaultMessage: 'Account'
    },
    // P&V: Duplicate message id. Commented out to prevent build failure
    // Type: {
    //     id: 'policy.common.directives.templates.policy-account-policy-summaries.type',
    //     defaultMessage: 'Type'
    // },
    Policies: {
        id: 'gateway.views.account-detail.Policies',
        defaultMessage: 'Policies'
    },
    DateCreated: {
        id: 'gateway.views.account-landing.Created Date',
        defaultMessage: 'Date Created'
    },
    Address: {
        id: 'gateway.views.account-landing.Address',
        defaultMessage: 'Address'
    },
    OpenActivities: {
        id: 'gateway.views.account-landing.Open Activities',
        defaultMessage: 'Open Activities'
    },
    contracts: {
        id: 'gateway.views.account-landing.Policies',
        defaultMessage: 'Contracts'
    },
    summary: {
        id: 'gateway.views.account-detail.Summary',
        defaultMessage: 'Summary',
    },
    contacts: {
        id: 'gateway.views.account-detail.Contacts',
        defaultMessage: 'Contacts'
    },
    openQuotes: {
        id: 'gateway.views.account-detail.Open Quotes',
        defaultMessage: 'Open Tariffs'
    },
    openTransactions: {
        id: 'gateway.views.account-detail.Open Transactions',
        defaultMessage: 'Open Transactions'
    },
    transactions: {
        id: 'gateway.views.account-detail.Transactions',
        defaultMessage: 'Transactions'
    },
    openActivities: {
        id: 'gateway.views.account-detail.Open Activities',
        defaultMessage: 'Open Activities'
    },
    claims: {
        id: 'gateway.views.account-detail.Claims',
        defaultMessage: 'Claims'
    },
    billingPayment: {
        id: 'gateway.views.account-detail.Billing & Payment',
        defaultMessage: 'Billing & Payment'
    },
    timeline: {
        id: 'gateway.views.account-detail.Timeline',
        defaultMessage: 'Timeline'
    },
    producerCode: {
        id: 'gateway.views.account-detail.Producer Code(s)',
        defaultMessage: 'Producer Code(s)'
    },
    premiumAmountLabel: {
        id: 'gateway.views.account-detail.Total Issued Premium',
        defaultMessage: 'Total Issued Premium'
    },
    renewals: {
        id: 'gateway.views.account-detail.Renewals',
        defaultMessage: 'Open Renewals'
    },
    cancellations: {
        id: 'gateway.views.account-detail.Cancellations',
        defaultMessage: 'Open Cancellations'
    },
    changes: {
        id: 'gateway.views.account-detail.Changes',
        defaultMessage: 'Open Changes'
    },
    customerSince: {
        id: 'gateway.views.account-detail.Customer Since',
        defaultMessage: 'Customer Since'
    },
    accountStatus: {
        id: 'gateway.views.account-detail.Account Status',
        defaultMessage: 'Account Status'
    },
    issuedPolicyLabel: {
        id: 'gateway.views.account-detail.Issued Policies',
        defaultMessage: 'Issued Contracts'
    },
    newQuoteForAccount: {
        id: 'gateway.views.new-quote.New Quote for This Account',
        defaultMessage: 'New Quote for This Account'
    },
    productCode: {
        id: 'gateway.directives.PolicySummary.Line',
        defaultMessage: 'Product'
    },
    displayStatus: {
        id: 'gateway.directives.PolicySummary.Status',
        defaultMessage: 'Status'
    },
    policyNumber: {
        id: 'gateway.directives.PolicySummary.Policy Number',
        defaultMessage: 'Contract Number'
    },
    insuredName: {
        id: 'gateway.directives.PolicySummary.Named Insured',
        defaultMessage: 'Named Insured'
    },
    effectiveDate: {
        id: 'gateway.directives.PolicySummary.Effective Date',
        defaultMessage: 'Effective Date'
    },
    expirationDate: {
        id: 'gateway.directives.PolicySummary.Expiration Date',
        defaultMessage: 'Expiration Date'
    },
    accountContacts: {
        id: 'gateway.contacts.views.account-contact-detail.Account Contacts',
        defaultMessage: 'Account Contacts',
    },
    accountContactsCount: {
        id: 'gateway.contacts.views.account-contact-detail.Account Contacts Count',
        defaultMessage: 'Account Contacts {accountContactsCount}',
    },
    associatedContacts: {
        id: 'gateway.contacts.views.account-contact-detail.Associated Contacts',
        defaultMessage: 'Associated Contacts'
    },
    account: {
        id: 'gateway.views.account-detail.Account',
        defaultMessage: 'Account'
    },
    altBillingAccount: {
        id: 'gateway.views.account-detail.Alt Billing Account',
        defaultMessage: 'Alt Billing Account'
    },
    unbilledAmount: {
        id: 'gateway.views.account-detail.Unbilled',
        defaultMessage: 'Unbilled'
    },
    billedAmount: {
        id: 'gateway.views.account-detail.Billed Amount',
        defaultMessage: 'Billed Amount'
    },
    billedMonthly: {
        id: 'gateway.views.account-detail.Billed Monthly',
        defaultMessage: 'Billed Monthly'
    },
    invoiceStream: {
        id: 'gateway.directives.GatewayBillingPaymentLineInfo.Invoice Stream',
        defaultMessage: '{periodicity} Invoice Stream',
    },
    billingAccount: {
        id: 'gateway.views.account-detail.Billing Account',
        defaultMessage: 'Billing Account'
    },
    billingStatus: {
        id: 'gateway.views.account-detail.Billing Status',
        defaultMessage: 'Billing Status'
    },
    cancel: {
        id: 'gateway.views.account-detail.Cancel',
        defaultMessage: 'Cancel'
    },
    collateralHeld: {
        id: 'gateway.views.account-detail.Collateral Held',
        defaultMessage: 'Collateral Held'
    },
    collateralRequirement: {
        id: 'gateway.views.account-detail.Collateral Requirement',
        defaultMessage: 'Collateral Requirement'
    },
    currentPayment: {
        id: 'gateway.views.account-detail.Current Payment',
        defaultMessage: 'Current Payment'
    },
    delinquent: {
        id: 'gateway.views.account-detail.Delinquent',
        defaultMessage: 'Delinquent'
    },
    documents: {
        id: 'gateway.views.account-detail.Documents',
        defaultMessage: 'Documents'
    },
    edit: {
        id: 'gateway.views.account-detail.Edit',
        defaultMessage: 'Update Contact Info'
    },
    editContactDetails: {
        id: 'gateway.views.account-detail.Edit Contact Details',
        defaultMessage: 'Edit Contact Details'
    },
    email: {
        id: 'platform.contacts.templates.contact-template.Email Address',
        defaultMessage: 'Email Address'
    },
    effDate: {
        id: 'gateway.views.account-detail.Eff Date',
        defaultMessage: 'Eff Date'
    },
    errorLoadingBillingData: {
        id: 'gateway.views.account-detail.Error loading billing data',
        defaultMessage: 'Error loading billing data'
    },
    expDate: {
        id: 'gateway.views.account-detail.Exp Date',
        defaultMessage: 'Exp Date'
    },
    inGoodStanding: {
        id: 'gateway.views.account-detail.In Good Standing',
        defaultMessage: 'In Good Standing'
    },
    invoice: {
        id: 'gateway.views.account-detail.Invoice',
        defaultMessage: 'Invoice #'
    },
    invoicesForLast12Months: {
        id: 'gateway.views.account-detail.Invoices for Last 12 Months',
        defaultMessage: 'Invoices for Last 12 Months'
    },
    makeAPayment: {
        id: 'gateway.views.account-detail.Make a Payment',
        defaultMessage: 'Make a Payment'
    },
    pastDue: {
        id: 'gateway.views.account-detail.Past Due',
        defaultMessage: 'Past Due'
    },
    nextDue: {
        id: 'gateway.directives.GatewayBillingPaymentLineInfo.Next Due',
        defaultMessage: 'Next Due'
    },
    nextDueDate: {
        id: 'gateway.directives.GatewayBillingPaymentLineInfo.Next Due Date',
        defaultMessage: 'Next Due on {firstUnpaidBillDueDate}'
    },
    pastDueDate: {
        id: 'gateway.directives.GatewayBillingPaymentLineInfo.Past Due Date',
        defaultMessage: 'Past Due on {overdueStatEarliestDate}'
    },
    payments: {
        id: 'gateway.views.account-detail.Payments',
        defaultMessage: 'Automatic Payments'
    },
    policiesBilledToThisAccount: {
        id: 'gateway.views.account-detail.Policies Billed to this Account',
        defaultMessage: 'Other Policies Billed to this Account'
    },
    policiesOwnedByThisAccount: {
        id: 'gateway.views.account-detail.Policies Owned by this Account',
        defaultMessage: 'Contracts Owned by this client'
    },
    contractNumber:{
        id:'gateway.views.account-detail.Policy #',
        defaultMessage:'Contract #'
    },
    totalPremium:{
        id:'gateway.views.account-detail.Total premium',
        defaultMessage:'Total Premium'
    },
    comission:{
        id:'gateway.views.account-detail.Comission',
        defaultMessage:'Commission'
    },
    make:{
        id:'gateway.views.account-detail.Make',
        defaultMessage:'Make'
    },
    model:{
        id:'gateway.views.account-detail.Model',
        defaultMessage:'Model'
    },
    customerPaymentSummary: {
        id: 'gateway.views.account-detail.Customer Payment Summary',
        defaultMessage: 'Customer Payment Summary'
    },
    policyTransactions: {
        id: 'gateway.views.account-detail.Policy Transactions',
        defaultMessage: 'Contract Transactions'
    },
    primaryContact: {
        id: 'gateway.views.account-detail.Primary Contact',
        defaultMessage: 'Primary Contact'
    },
    primaryPayer: {
        id: 'gateway.views.account-detail.Primary Payer',
        defaultMessage: 'Primary Payer'
    },
    accountDelinquencyLevelLabel: {
        id: 'gateway.views.account-detail.delinquency-level',
        defaultMessage: 'Delinquency Level'
    },
    accountDelinquencyLevelDateLabel: {
        id: 'gateway.views.account-detail.delinquency-level-date',
        defaultMessage: 'Delinquency Level Date'
    },
    noBillingData: {
        id: 'gateway.views.account-detail.No billing data for this account, check back again in the next',
        defaultMessage: 'No billing data for this account, check back again in the next 24 hours'
    },
    billing: {
        id: 'gateway.views.account-detail.Billing',
        defaultMessage: 'Billing'
    },
    automaticPayments: {
        id: 'gateway.views.account-detail.Automatic Payments',
        defaultMessage: 'Automatic Payments'
    },
    notInGoodStanding: {
        id: 'gateway.views.account-detail.Not In Good Standing',
        defaultMessage: 'Not In Good Standing'
    },
    producerCodes: {
        id: 'gateway.views.account-detail.Producer Codes',
        defaultMessage: 'Producer Code(s)'
    },
    policyNumberAccountDetail: {
        id: 'gateway.views.account-detail.Policy Number',
        defaultMessage: 'Policy Number'
    },
    product: {
        id: 'gateway.views.account-detail.Product',
        defaultMessage: 'Product'
    },
    quotes: {
        id: 'gateway.views.account-detail.Quotes',
        defaultMessage: 'Quotes'
    },
    saveChanges: {
        id: 'gateway.views.account-detail.Save Changes',
        defaultMessage: 'Save Changes'
    },
    saveFailedMessage: {
        id: 'gateway.views.account-detail.Save Failed Message',
        defaultMessage: 'Your changes were not saved.'
    },
    saveFailedTitle: {
        id: 'gateway.views.account-detail.Save Failed Title',
        defaultMessage: 'Failed to Save'
    },
    savedMessage: {
        id: 'gateway.views.account-detail.Saved Message',
        defaultMessage: 'Your changes have been saved.'
    },
    savedTitle: {
        id: 'gateway.views.account-detail.Saved Title',
        defaultMessage: 'Changes Saved'
    },
    searchTransactions: {
        id: 'gateway.views.account-detail.Search Transactions',
        defaultMessage: 'Search Transactions'
    },
    serviceTier: {
        id: 'gateway.views.account-detail.Service Tier',
        defaultMessage: 'Service Tier'
    },
    statementDate: {
        id: 'gateway.views.account-detail.Statement Date',
        defaultMessage: 'Statement Date'
    },
    thisAccountHasNoInvoices: {
        id: 'gateway.views.account-detail.This account has no invoices',
        defaultMessage: 'This account has no invoices'
    },
    thisAccountHasNoOwnedBilledPolicies: {
        id: 'gateway.views.account-detail.This account has no owned billed policies',
        defaultMessage: 'There are no contracts on this client'
    },
    thisAccountHasNoUnownedBilledPolicies: {
        id: 'gateway.views.account-detail.This account has no unowned billed policies',
        defaultMessage: 'This account has no unowned billed policies'
    },
    totalDue: {
        id: 'gateway.views.account-detail.Total Due',
        defaultMessage: 'Total Due'
    },
    policiesAffected: {
        id: 'gateway.directives.GatewayBillingPaymentLineInfo.Policies Affected:',
        defaultMessage: 'Policies Affected:'
    },
    automaticPaymentsEnabled: {
        id: 'gateway.directives.GatewayBillingPaymentLineInfo.Automatic Payments are ENABLED',
        defaultMessage: 'Automatic Payments are ENABLED'
    },
    automaticPaymentsNotEnabled: {
        id: 'gateway.directives.GatewayBillingPaymentLineInfo.Automatic Payments are NOT enabled',
        defaultMessage: 'Automatic Payments are NOT enabled'
    },
    setupAutomaticPayment: {
        id: 'gateway.directives.GatewayBillingPaymentLineInfo.Set up Automatic Payments',
        defaultMessage: 'Set up Automatic Payments'
    },
    hidePaymentScheduleDetail: {
        id: 'billing.directives.templates.payment-line-info.Hide Payment Schedule Detail',
        defaultMessage: 'Hide Payment Schedule Detail'
    },
    viewPaymentScheduleDetail: {
        id: 'billing.directives.templates.payment-line-info.View Payment Schedule Detail',
        defaultMessage: 'View Payment Schedule Detail'
    },
    paid: {
        id: 'billing.directives.templates.policy-payment-schedule.Paid',
        defaultMessage: 'Paid'
    },
    unpaid: {
        id: 'billing.directives.templates.policy-payment-schedule.Unpaid',
        defaultMessage: 'Unpaid'
    },
    dueDate: {
        id: 'billing.directives.templates.payment-line-info.Due date',
        defaultMessage: 'Due date'
    },
    billStatus: {
        id: 'billing.directives.templates.payment-line-info.Bill Status',
        defaultMessage: 'Bill Status'
    },
    premium: {
        id: 'billing.directives.templates.payment-line-info.Premium',
        defaultMessage: 'Premium'
    },
    taxes: {
        id: 'billing.directives.templates.payment-line-info.Taxes',
        defaultMessage: 'Taxes'
    },
    fee: {
        id: 'billing.directives.templates.payment-line-info.Fee',
        defaultMessage: 'Fee'
    },
    totalPaid: {
        id: 'billing.directives.templates.payment-line-info.Total Paid',
        defaultMessage: 'Total Paid'
    },
    outstandingAmount: {
        id: 'billing.directives.templates.payment-line-info.Outstanding Amount',
        defaultMessage: 'Outstanding Amount'
    },
    invoiceNumber: {
        id: 'billing.directives.templates.payment-line-info.Invoice #',
        defaultMessage: 'Invoice #'
    },
    due: {
        id: 'billing.directives.templates.make-a-payment-form.Due',
        defaultMessage: 'Due'
    },
    billed: {
        id: 'billing.directives.templates.make-a-payment-form.Billed',
        defaultMessage: 'Billed'
    },
    carriedForward: {
        id: 'billing.directives.templates.make-a-payment-form.Carried Forward',
        defaultMessage: 'Carried Forward'
    },
    planned: {
        id: 'billing.directives.templates.make-a-payment-form.Planned',
        defaultMessage: 'Planned'
    },
    writtenOff: {
        id: 'billing.directives.templates.make-a-payment-form.Written Off',
        defaultMessage: 'Written Off'
    },
    timelineHeader: {
        id: 'journey.directives.timeline.This timeline includes everything that has happened to this account, whether performed by an agent, an insurer, or by the account holder',
        defaultMessage: 'This timeline includes everything that has happened to this account, whether performed by an agent, an insurer, or by the account holder'
    },
    timelinedetailsPolicies: {
        id: 'journey.directives.timeline.timeline-details.templates.timeline-details.Policies',
        defaultMessage: 'Policies'
    },
    timelineerrorErrorMessage: {
        id: 'journey.directives.timeline.timeline-error.templates.timeline-error.Error Message',
        defaultMessage: 'There was a problem processing your request. Please refresh the page to try again.'
    },
    journeypageTimeline: {
        id: 'journey.directives.timeline.views.journey-page.Timeline',
        defaultMessage: 'Timeline'
    },
    journeypageDescription: {
        id: 'journey.directives.timeline.views.journey-page.Description',
        defaultMessage: 'This timeline includes everything that has happened on your account - whether performed by you, your agent or your insurer.'
    },
    configAllPolicyLines: {
        id: 'journey.config.All Policy Lines',
        defaultMessage: 'All Policy Lines'
    },
    configPersonalAuto: {
        id: 'journey.config.Personal Auto',
        defaultMessage: 'Personal Auto'
    },
    configHomeowners: {
        id: 'journey.config.Homeowners',
        defaultMessage: 'Homeowners'
    },
    configGeneric: {
        id: 'journey.config.Generic',
        defaultMessage: 'Generic'
    },
    accountContactChangedAccountContactChanged: {
        id: 'journey.event-templates.accountContactChanged.Account Contact Changed',
        defaultMessage: 'Account Contact Changed'
    },
    accountContactChangedAccountContactName: {
        id: 'journey.event-templates.accountContactChanged.Account Contact Name',
        defaultMessage: 'Name'
    },
    accountContactChangedAccountContactAddress: {
        id: 'journey.event-templates.accountContactChanged.Account Contact Address',
        defaultMessage: 'Address'
    },
    accountContactChangedAccountContactPhone: {
        id: 'journey.event-templates.accountContactChanged.Account Contact Phone',
        defaultMessage: 'Phone'
    },
    accountContactChangedAccountContactEmail: {
        id: 'journey.event-templates.accountContactChanged.Account Contact Email',
        defaultMessage: 'Email'
    },
    policyCancelledPolicyCancelled: {
        id: 'journey.event-templates.policyCancelled.Policy Cancelled',
        defaultMessage: 'Policy Cancelled'
    },
    policyChangeWithdrawnSubmissionWithdrown: {
        id: 'journey.event-templates.policyChangeWithdrawn.Submission Withdrown',
        defaultMessage: 'Policy Change Withdrawn'
    },
    policyChangeWithdrawnTransactionID: {
        id: 'journey.event-templates.policyChangeWithdrawn.Transaction ID',
        defaultMessage: 'Transaction ID'
    },
    policyReinstatedPolicyReinstated: {
        id: 'journey.event-templates.policyReinstated.Policy Reinstated',
        defaultMessage: 'Policy Reinstated'
    },
    policyReinstatedEffectiveDate: {
        id: 'journey.event-templates.policyReinstated.Effective Date',
        defaultMessage: 'Effective Date'
    },
    submisionWithdrawnSubmissionWithdrown: {
        id: 'journey.event-templates.submisionWithdrawn.Submission Withdrown',
        defaultMessage: 'Submission Withdrawn'
    },
    accountContactChangedPhone: {
        id: 'journey.event-templates.accountContactChanged.Phone',
        defaultMessage: 'Phone'
    },
    accountContactChangedAddress: {
        id: 'journey.event-templates.accountContactChanged.Address',
        defaultMessage: 'Address'
    },
    accountContactChangedEmail: {
        id: 'journey.event-templates.accountContactChanged.Email',
        defaultMessage: 'Email'
    },
    claimCreatedClaimCreated: {
        id: 'journey.event-templates.claimCreated.Claim Created',
        defaultMessage: 'Claim Created'
    },
    dateOfLoss: {
        id: 'journey.event-templates.claimCreated.Date Of Loss',
        defaultMessage: 'Date Of Loss'
    },
    claimCreatedClaimNumber: {
        id: 'journey.event-templates.claimCreated.Claim Number',
        defaultMessage: 'Claim Number'
    },
    claimCreatedLossInformation: {
        id: 'journey.event-templates.claimCreated.Loss Information',
        defaultMessage: 'Loss Information'
    },
    claimCreatedDateOfFNOL: {
        id: 'journey.event-templates.claimCreated.Date Of FNOL',
        defaultMessage: 'Date Of FNOL'
    },
    claimCreatedLossCause: {
        id: 'journey.event-templates.claimCreated.Loss Cause',
        defaultMessage: 'Loss Cause'
    },
    claimReassignedClaimReassigned: {
        id: 'journey.event-templates.claimReassigned.Claim Reassigned',
        defaultMessage: 'Claim Reassigned'
    },
    claimReassignedClaimNumber: {
        id: 'journey.event-templates.claimReassigned.Claim Number',
        defaultMessage: 'Claim Number'
    },
    claimReassignedAssignmentDate: {
        id: 'journey.event-templates.claimReassigned.Assignment Date',
        defaultMessage: 'Assignment Date'
    },
    claimReassignedAssignedAgentName: {
        id: 'journey.event-templates.claimReassigned.Assigned Agent Name',
        defaultMessage: 'Assigned Agent Name'
    },
    claimReassignedAssignedAgentPhone: {
        id: 'journey.event-templates.claimReassigned.Assigned Agent Phone',
        defaultMessage: 'Assigned Agent Phone'
    },
    claimReassignedAssignedAgentEmail: {
        id: 'journey.event-templates.claimReassigned.Assigned Agent Email',
        defaultMessage: 'Assigned Agent Email'
    },
    claimClosedClaimClosed: {
        id: 'journey.event-templates.claimClosed.Claim Closed',
        defaultMessage: 'Claim Closed'
    },
    claimClosedClaimNumber: {
        id: 'journey.event-templates.claimClosed.Claim Number',
        defaultMessage: 'Claim Number'
    },
    paymentPlanChangedPaymentPlanChanged: {
        id: 'journey.event-templates.paymentPlanChanged.Payment Plan Changed',
        defaultMessage: 'Payment Plan Changed'
    },
    paymentPlanChangedPaymentPlan: {
        id: 'journey.event-templates.paymentPlanChanged.Payment Plan',
        defaultMessage: 'Payment Plan'
    },
    serviceEstimateApprovedServiceEstimateApproved: {
        id: 'journey.event-templates.serviceEstimateApproved.Service Estimate Approved',
        defaultMessage: 'Service Estimate Approved'
    },
    serviceEstimateApprovedClaimNumber: {
        id: 'journey.event-templates.serviceEstimateApproved.Claim Number',
        defaultMessage: 'Claim Number'
    },
    serviceEstimateApprovedServiceRequestReferenceNumber: {
        id: 'journey.event-templates.serviceEstimateApproved.Service Request Reference Number',
        defaultMessage: 'Service Request Reference Number'
    },
    serviceEstimateApprovedServiceRequestEstimateNumber: {
        id: 'journey.event-templates.serviceEstimateApproved.Service Request Estimate Number',
        defaultMessage: 'Service Request Estimate Number'
    },
    serviceEstimateApprovedApprovedDate: {
        id: 'journey.event-templates.serviceEstimateApproved.Approved Date',
        defaultMessage: 'Approved Date'
    },
    serviceEstimateApprovedApprovedBy: {
        id: 'journey.event-templates.serviceEstimateApproved.Approved By',
        defaultMessage: 'Approved By'
    },
    serviceEstimateApprovedVendorName: {
        id: 'journey.event-templates.serviceEstimateApproved.Vendor Name',
        defaultMessage: 'Vendor Name'
    },
    serviceEstimateApprovedServiceType: {
        id: 'journey.event-templates.serviceEstimateApproved.Service Type',
        defaultMessage: 'Service Type'
    },
    serviceEstimateApprovedAmount: {
        id: 'journey.event-templates.serviceEstimateApproved.Amount',
        defaultMessage: 'Amount'
    },
    serviceInvoiceReceivedServiceInvoiceReceived: {
        id: 'journey.event-templates.serviceInvoiceReceived.Service Invoice Received',
        defaultMessage: 'Service Invoice Received'
    },
    serviceInvoiceReceivedClaimNumber: {
        id: 'journey.event-templates.serviceInvoiceReceived.Claim Number',
        defaultMessage: 'Claim Number'
    },
    serviceInvoiceReceivedServiceRequestNumber: {
        id: 'journey.event-templates.serviceInvoiceReceived.Service Request Number',
        defaultMessage: 'Service Request Number'
    },
    serviceInvoiceReceivedVendorName: {
        id: 'journey.event-templates.serviceInvoiceReceived.Vendor Name',
        defaultMessage: 'Vendor Name'
    },
    serviceInvoiceReceivedVendorID: {
        id: 'journey.event-templates.serviceInvoiceReceived.Vendor ID',
        defaultMessage: 'Vendor ID'
    },
    serviceInvoiceReceivedServiceType: {
        id: 'journey.event-templates.serviceInvoiceReceived.Service Type',
        defaultMessage: 'Service Type'
    },
    serviceInvoiceReceivedAmount: {
        id: 'journey.event-templates.serviceInvoiceReceived.Amount',
        defaultMessage: 'Amount'
    },
    serviceInvoiceReceivedDescription: {
        id: 'journey.event-templates.serviceInvoiceReceived.Description',
        defaultMessage: 'Description'
    },
    serviceInvoiceReceivedEstimateInvoiceID: {
        id: 'journey.event-templates.serviceInvoiceReceived.Estimate / Invoice ID',
        defaultMessage: 'Estimate / Invoice ID'
    },
    serviceAssignedServiceAssigned: {
        id: 'journey.event-templates.serviceAssigned.Service Assigned',
        defaultMessage: 'Service Assigned'
    },
    serviceAssignedClaimNumber: {
        id: 'journey.event-templates.serviceAssigned.Claim Number',
        defaultMessage: 'Claim Number'
    },
    serviceAssignedServiceRequestReferenceNumber: {
        id: 'journey.event-templates.serviceAssigned.Service Request Reference Number',
        defaultMessage: 'Service Request Reference Number'
    },
    serviceAssignedVendorName: {
        id: 'journey.event-templates.serviceAssigned.Vendor Name',
        defaultMessage: 'Vendor Name'
    },
    serviceAssignedVendorID: {
        id: 'journey.event-templates.serviceAssigned.Vendor ID',
        defaultMessage: 'Vendor ID'
    },
    serviceAssignedRequestedQuoteCompletionDate: {
        id: 'journey.event-templates.serviceAssigned.Requested Quote Completion Date',
        defaultMessage: 'Requested Quote Completion Date'
    },
    serviceAssignedExpectedQuoteCompletionDate: {
        id: 'journey.event-templates.serviceAssigned.Expected Quote Completion Date',
        defaultMessage: 'Expected Quote Completion Date'
    },
    serviceAssignedServiceType: {
        id: 'journey.event-templates.serviceAssigned.Service Type',
        defaultMessage: 'Service Type'
    },
    newAccountContactBillingContactAdded: {
        id: 'journey.event-templates.newAccountContact.Billing Contact Added',
        defaultMessage: 'Billing Contact Added'
    },
    newAccountContactAddress: {
        id: 'journey.event-templates.newAccountContact.Address',
        defaultMessage: 'Address'
    },
    newAccountContactName: {
        id: 'journey.event-templates.newAccountContact.Name',
        defaultMessage: 'Name'
    },
    newAccountContactPhone: {
        id: 'journey.event-templates.newAccountContact.Phone',
        defaultMessage: 'Phone'
    },
    newAccountContactEmail: {
        id: 'journey.event-templates.newAccountContact.Email',
        defaultMessage: 'Email'
    },
    removedAccountContactBillingContactDeleted: {
        id: 'journey.event-templates.removedAccountContact.Billing Contact Deleted',
        defaultMessage: 'Billing Contact Deleted'
    },
    removedAccountContactName: {
        id: 'journey.event-templates.removedAccountContact.Name',
        defaultMessage: 'Name'
    },
    removedAccountContactAddress: {
        id: 'journey.event-templates.removedAccountContact.Address',
        defaultMessage: 'Address'
    },
    removedAccountContactPhone: {
        id: 'journey.event-templates.removedAccountContact.Phone',
        defaultMessage: 'Phone'
    },
    removedAccountContactEmail: {
        id: 'journey.event-templates.removedAccountContact.Email',
        defaultMessage: 'Email'
    },
    catastropheAddedCatastropheAddedtoaClaim: {
        id: 'journey.event-templates.catastropheAdded.Catastrophe Added to a Claim',
        defaultMessage: 'Catastrophe Added to a Claim'
    },
    catastropheAddedClaimNumber: {
        id: 'journey.event-templates.catastropheAdded.Claim Number',
        defaultMessage: 'Claim Number'
    },
    catastropheAddedCatastropheName: {
        id: 'journey.event-templates.catastropheAdded.Catastrophe Name',
        defaultMessage: 'Catastrophe Name'
    },
    catastropheAddedCatastropheNumber: {
        id: 'journey.event-templates.catastropheAdded.Catastrophe Number',
        defaultMessage: 'Catastrophe Number'
    },
    contactChangedContactChanged: {
        id: 'journey.event-templates.contactChanged.Contact Changed',
        defaultMessage: 'Billing Contact Changed'
    },
    contactChangedName: {
        id: 'journey.event-templates.contactChanged.Name',
        defaultMessage: 'Name'
    },
    contactChangedPhone: {
        id: 'journey.event-templates.contactChanged.Phone',
        defaultMessage: 'Phone'
    },
    contactChangedAddress: {
        id: 'journey.event-templates.contactChanged.Address',
        defaultMessage: 'Address'
    },
    contactChangedEmail: {
        id: 'journey.event-templates.contactChanged.Email',
        defaultMessage: 'Email'
    },
    submissionCreatedSubmissionCreated: {
        id: 'journey.event-templates.submissionCreated.Submission Created',
        defaultMessage: 'Submission Created'
    },
    delinquencyProcessStartedAndInceptedDelinquencyStarted: {
        id: 'journey.event-templates.delinquencyProcessStartedAndIncepted.Delinquency Started',
        defaultMessage: 'Delinquency Started'
    },
    delinquencyProcessStartedAndInceptedAccountNumber: {
        id: 'journey.event-templates.delinquencyProcessStartedAndIncepted.Account Number',
        defaultMessage: 'Account Number'
    },
    delinquencyProcessStartedAndInceptedPolicyNumber: {
        id: 'journey.event-templates.delinquencyProcessStartedAndIncepted.Policy Number',
        defaultMessage: 'Policy Number'
    },
    delinquencyProcessStartedAndInceptedAmountDue: {
        id: 'journey.event-templates.delinquencyProcessStartedAndIncepted.Amount Due',
        defaultMessage: 'Amount Due'
    },
    delinquencyProcessStartedAndInceptedLateFee: {
        id: 'journey.event-templates.delinquencyProcessStartedAndIncepted.Late Fee',
        defaultMessage: 'Late Fee'
    },
    delinquencyProcessStartedAndInceptedReinstatementFee: {
        id: 'journey.event-templates.delinquencyProcessStartedAndIncepted.Reinstatement Fee',
        defaultMessage: 'Reinstatement Fee'
    },
    delinquencyProcessStartedAndInceptedReason: {
        id: 'journey.event-templates.delinquencyProcessStartedAndIncepted.Reason',
        defaultMessage: 'Reason'
    },
    delinquencyProcessStartedAndInceptedStartDate: {
        id: 'journey.event-templates.delinquencyProcessStartedAndIncepted.Start Date',
        defaultMessage: 'Start Date'
    },
    delinquencyProcessStartedAndInceptedInceptionDate: {
        id: 'journey.event-templates.delinquencyProcessStartedAndIncepted.Inception Date',
        defaultMessage: 'Inception Date'
    },
    delinquencyProcessClosedDelinquencyClosed: {
        id: 'journey.event-templates.delinquencyProcessClosed.Delinquency Closed',
        defaultMessage: 'Delinquency Closed'
    },
    delinquencyProcessClosedAccountNumber: {
        id: 'journey.event-templates.delinquencyProcessClosed.Account Number',
        defaultMessage: 'Account Number'
    },
    delinquencyProcessClosedPolicyNumber: {
        id: 'journey.event-templates.delinquencyProcessClosed.Policy Number',
        defaultMessage: 'Policy Number'
    },
    delinquencyProcessClosedAmountDue: {
        id: 'journey.event-templates.delinquencyProcessClosed.Amount Due',
        defaultMessage: 'Amount Due'
    },
    delinquencyProcessClosedLateFee: {
        id: 'journey.event-templates.delinquencyProcessClosed.Late Fee',
        defaultMessage: 'Late Fee'
    },
    delinquencyProcessClosedReinstatementFee: {
        id: 'journey.event-templates.delinquencyProcessClosed.Reinstatement Fee',
        defaultMessage: 'Reinstatement Fee'
    },
    delinquencyProcessClosedReason: {
        id: 'journey.event-templates.delinquencyProcessClosed.Reason',
        defaultMessage: 'Reason'
    },
    delinquencyProcessClosedStartDate: {
        id: 'journey.event-templates.delinquencyProcessClosed.Start Date',
        defaultMessage: 'Start Date'
    },
    delinquencyProcessClosedInceptionDate: {
        id: 'journey.event-templates.delinquencyProcessClosed.Inception Date',
        defaultMessage: 'Inception Date'
    },
    delinquencyProcessClosedExitDate: {
        id: 'journey.event-templates.delinquencyProcessClosed.Exit Date',
        defaultMessage: 'Exit Date'
    },
    primaryPayerChangedPrimaryPayerChanged: {
        id: 'journey.event-templates.primaryPayerChanged.Primary Payer Changed',
        defaultMessage: 'Primary Payer Changed'
    },
    primaryPayerChangedPayer: {
        id: 'journey.event-templates.primaryPayerChanged.Payer',
        defaultMessage: 'Payer'
    },
    primaryPayerChangedAddress: {
        id: 'journey.event-templates.primaryPayerChanged.Address',
        defaultMessage: 'Address'
    },
    primaryPayerChangedEmail: {
        id: 'journey.event-templates.primaryPayerChanged.Email',
        defaultMessage: 'Email'
    },
    primaryPayerChangedPhone: {
        id: 'journey.event-templates.primaryPayerChanged.Phone',
        defaultMessage: 'Phone'
    },
    policyPolicyNumber: {
        id: 'journey.event-templates.policy.Policy Number',
        defaultMessage: 'Policy Number'
    },
    policyEffectiveDate: {
        id: 'journey.event-templates.policy.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyCancelledReason: {
        id: 'journey.event-templates.policyCancelled.Reason',
        defaultMessage: 'Reason'
    },
    policyCancelledSource: {
        id: 'journey.event-templates.policyCancelled.Source',
        defaultMessage: 'Source'
    },
    policyCancelledDescription: {
        id: 'journey.event-templates.policyCancelled.Description',
        defaultMessage: 'Description'
    },
    policyCancelledRefundMethod: {
        id: 'journey.event-templates.policyCancelled.Refund Method',
        defaultMessage: 'Refund Method'
    },
    policyIssuedPolicyIssued: {
        id: 'journey.event-templates.policyIssued.Policy Issued',
        defaultMessage: 'Policy Issued'
    },
    policyIssuedEffectiveDate: {
        id: 'journey.event-templates.policyIssued.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyIssuedTotalAmount: {
        id: 'journey.event-templates.policyIssued.Total Amount',
        defaultMessage: 'Total Amount'
    },
    policyIssuedSubmissionNumber: {
        id: 'journey.event-templates.policyIssued.Submission Number',
        defaultMessage: 'Submission Number'
    },
    policyIssuedExpirationDate: {
        id: 'journey.event-templates.policyIssued.Expiration Date',
        defaultMessage: 'Expiration Date'
    },
    policyIssuedPaymentPlan: {
        id: 'journey.event-templates.policyIssued.Payment Plan',
        defaultMessage: 'Payment Plan'
    },
    quoteShowedJobID: {
        id: 'journey.event-templates.quoteShowed.JobID',
        defaultMessage: 'JobID'
    },
    quoteShowedQuoteShowed: {
        id: 'journey.event-templates.quoteShowed.Quote Showed',
        defaultMessage: 'Quote Showed'
    },
    quoteShowedOffering: {
        id: 'journey.event-templates.quoteShowed.Offering',
        defaultMessage: 'Offering'
    },
    quoteShowedTotalPremium: {
        id: 'journey.event-templates.quoteShowed.Total Premium',
        defaultMessage: 'Total Premium'
    },
    accountLevelDueDateChangedPaymentDueDateChanged: {
        id: 'journey.event-templates.accountLevelDueDateChanged.Payment Due Date Changed',
        defaultMessage: 'Payment Due Date Changed'
    },
    accountLevelDueDateChangedMonthlyPaymentDueDate: {
        id: 'journey.event-templates.accountLevelDueDateChanged.Monthly Payment Due Date',
        defaultMessage: 'Monthly Payment Due Date'
    },
    accountLevelDueDateChangedWeeklyPaymentDueDay: {
        id: 'journey.event-templates.accountLevelDueDateChanged.Weekly Payment Due Day',
        defaultMessage: 'Weekly Payment Due Day'
    },
    accountLevelDueDateChangedFirstMonthlyPaymentDueDate: {
        id: 'journey.event-templates.accountLevelDueDateChanged.First Monthly Payment Due Date',
        defaultMessage: 'First Monthly Payment Due Date'
    },
    accountLevelDueDateChangedSecondMonthlyPaymentDueDate: {
        id: 'journey.event-templates.accountLevelDueDateChanged.Second Monthly Payment Due Date',
        defaultMessage: 'Second Monthly Payment Due Date'
    },
    accountLevelDueDateChangedFortnightlyPaymentDueDate: {
        id: 'journey.event-templates.accountLevelDueDateChanged.Fortnightly Payment Due Date',
        defaultMessage: 'Fortnightly Payment Due Date'
    },
    policyLevelDueDateChangedPaymentDueDateChanged: {
        id: 'journey.event-templates.policyLevelDueDateChanged.Payment Due Date Changed',
        defaultMessage: 'Payment Due Date Changed'
    },
    policyLevelDueDateChangedPaymentDueDate: {
        id: 'journey.event-templates.policyLevelDueDateChanged.Payment Due Date',
        defaultMessage: 'Payment Due Date'
    },
    invoiceBilledInvoiceBilled: {
        id: 'journey.event-templates.invoiceBilled.Invoice Billed',
        defaultMessage: 'Invoice Billed'
    },
    invoiceBilledInvoiceNumber: {
        id: 'journey.event-templates.invoiceBilled.Invoice Number',
        defaultMessage: 'Invoice Number'
    },
    invoiceBilledInvoiceTotal: {
        id: 'journey.event-templates.invoiceBilled.Invoice Total',
        defaultMessage: 'Invoice Total'
    },
    invoiceBilledStatus: {
        id: 'journey.event-templates.invoiceBilled.Status',
        defaultMessage: 'Status'
    },
    invoiceBilledBilledDate: {
        id: 'journey.event-templates.invoiceBilled.Billed Date',
        defaultMessage: 'Billed Date'
    },
    invoiceBilledDueDate: {
        id: 'journey.event-templates.invoiceBilled.Due Date',
        defaultMessage: 'Due Date'
    },
    invoiceBilledPaid: {
        id: 'journey.event-templates.invoiceBilled.Paid',
        defaultMessage: 'Paid'
    },
    invoiceBilledDue: {
        id: 'journey.event-templates.invoiceBilled.Due',
        defaultMessage: 'Due'
    },
    invoiceDueInvoiceDue: {
        id: 'journey.event-templates.invoiceDue.Invoice Due',
        defaultMessage: 'Invoice Due'
    },
    invoiceDueInvoiceNumber: {
        id: 'journey.event-templates.invoiceDue.Invoice Number',
        defaultMessage: 'Invoice Number'
    },
    invoiceDueInvoiceTotal: {
        id: 'journey.event-templates.invoiceDue.Invoice Total',
        defaultMessage: 'Invoice Total'
    },
    invoiceDueStatus: {
        id: 'journey.event-templates.invoiceDue.Status',
        defaultMessage: 'Status'
    },
    invoiceDueBilledDate: {
        id: 'journey.event-templates.invoiceDue.Billed Date',
        defaultMessage: 'Billed Date'
    },
    invoiceDueDueDate: {
        id: 'journey.event-templates.invoiceDue.Due Date',
        defaultMessage: 'Due Date'
    },
    invoiceDuePaid: {
        id: 'journey.event-templates.invoiceDue.Paid',
        defaultMessage: 'Paid'
    },
    invoiceDueDue: {
        id: 'journey.event-templates.invoiceDue.Due',
        defaultMessage: 'Due'
    },
    invoicePaidInvoicePaid: {
        id: 'journey.event-templates.invoicePaid.Invoice Paid',
        defaultMessage: 'Invoice Paid'
    },
    invoicePaidInvoiceNumber: {
        id: 'journey.event-templates.invoicePaid.Invoice Number',
        defaultMessage: 'Invoice Number'
    },
    invoicePaidInvoiceTotal: {
        id: 'journey.event-templates.invoicePaid.Invoice Total',
        defaultMessage: 'Invoice Total'
    },
    invoicePaidStatus: {
        id: 'journey.event-templates.invoicePaid.Status',
        defaultMessage: 'Status'
    },
    invoicePaidBilledDate: {
        id: 'journey.event-templates.invoicePaid.Billed Date',
        defaultMessage: 'Billed Date'
    },
    invoicePaidDueDate: {
        id: 'journey.event-templates.invoicePaid.Due Date',
        defaultMessage: 'Due Date'
    },
    invoicePaidPaid: {
        id: 'journey.event-templates.invoicePaid.Paid',
        defaultMessage: 'Paid'
    },
    invoicePaidDue: {
        id: 'journey.event-templates.invoicePaid.Due',
        defaultMessage: 'Due'
    },
    claimOpenedClaimOpened: {
        id: 'journey.event-templates.claimOpened.Claim Opened',
        defaultMessage: 'Claim Opened'
    },
    claimOpenedClaimNumber: {
        id: 'journey.event-templates.claimOpened.Claim Number',
        defaultMessage: 'Claim Number'
    },
    claimOpenedLossInformation: {
        id: 'journey.event-templates.claimOpened.Loss Information',
        defaultMessage: 'Loss Information'
    },
    claimOpenedDateOfLoss: {
        id: 'journey.event-templates.claimOpened.Date of Loss',
        defaultMessage: 'Date of Loss'
    },
    claimOpenedDateofFNOL: {
        id: 'journey.event-templates.claimOpened.Date of FNOL',
        defaultMessage: 'Date of FNOL'
    },
    claimOpenedLossCause: {
        id: 'journey.event-templates.claimOpened.Loss Cause',
        defaultMessage: 'Loss Cause'
    },
    disbursementSentDisbursementAmount: {
        id: 'journey.event-templates.disbursementSent.Disbursement Amount',
        defaultMessage: 'Disbursement Amount'
    },
    disbursementSentDisbursementSent: {
        id: 'journey.event-templates.disbursementSent.Disbursement Sent',
        defaultMessage: 'Disbursement Sent'
    },
    disbursementSentIssueDate: {
        id: 'journey.event-templates.disbursementSent.Issue Date',
        defaultMessage: 'Issue Date'
    },
    disbursementSentMemo: {
        id: 'journey.event-templates.disbursementSent.Memo',
        defaultMessage: 'Memo'
    },
    disbursementSentPayTo: {
        id: 'journey.event-templates.disbursementSent.Pay To',
        defaultMessage: 'Pay To'
    },
    disbursementSentReason: {
        id: 'journey.event-templates.disbursementSent.Reason',
        defaultMessage: 'Reason'
    },
    writeOffAddedWriteOff: {
        id: 'journey.event-templates.writeOffAdded.Write Off',
        defaultMessage: 'Write Off'
    },
    writeOffAddedDate: {
        id: 'journey.event-templates.writeOffAdded.Date',
        defaultMessage: 'Date'
    },
    writeOffAddedCharge: {
        id: 'journey.event-templates.writeOffAdded.Charge',
        defaultMessage: 'Charge'
    },
    writeOffAddedReason: {
        id: 'journey.event-templates.writeOffAdded.Reason',
        defaultMessage: 'Reason'
    },
    writeOffAddedInvoices: {
        id: 'journey.event-templates.writeOffAdded.Invoices',
        defaultMessage: 'Invoices'
    },
    writeOffReversalAddedWriteOffReversal: {
        id: 'journey.event-templates.writeOffReversalAdded.Write Off Reversal',
        defaultMessage: 'Write Off Reversed'
    },
    writeOffReversalAddedReversalReason: {
        id: 'journey.event-templates.writeOffReversalAdded.Reversal Reason',
        defaultMessage: 'Reversal Reason'
    },
    paymentReceivedPaymentReceived: {
        id: 'journey.event-templates.paymentReceived.Payment Received',
        defaultMessage: 'Payment Received'
    },
    paymentReceivedReceivedDate: {
        id: 'journey.event-templates.paymentReceived.Received Date',
        defaultMessage: 'Received Date'
    },
    paymentReceivedAmount: {
        id: 'journey.event-templates.paymentReceived.Amount',
        defaultMessage: 'Amount'
    },
    paymentReceivedReferenceNumber: {
        id: 'journey.event-templates.paymentReceived.Reference Number',
        defaultMessage: 'Reference Number'
    },
    paymentReceivedInvoiceNumber: {
        id: 'journey.event-templates.paymentReceived.Invoice Number',
        defaultMessage: 'Invoice Number'
    },
    paymentReceivedInvoiceNumbers: {
        id: 'journey.event-templates.paymentReceived.Invoice Numbers',
        defaultMessage: 'Invoice Numbers'
    },
    onetimePaymentReceivedOneTimePaymentReceived: {
        id: 'journey.event-templates.onetimePaymentReceived.One Time Payment Received',
        defaultMessage: 'One Time Payment Received'
    },
    onetimePaymentReceivedReceivedDate: {
        id: 'journey.event-templates.onetimePaymentReceived.Received Date',
        defaultMessage: 'Received Date'
    },
    onetimePaymentReceivedAmount: {
        id: 'journey.event-templates.onetimePaymentReceived.Amount',
        defaultMessage: 'Amount'
    },
    onetimePaymentReceivedReferenceNumber: {
        id: 'journey.event-templates.onetimePaymentReceived.Reference Number',
        defaultMessage: 'Reference Number'
    },
    onetimePaymentReceivedInvoiceNumber: {
        id: 'journey.event-templates.onetimePaymentReceived.Invoice Number',
        defaultMessage: 'Invoice Number'
    },
    onetimePaymentReceivedInvoiceNumbers: {
        id: 'journey.event-templates.onetimePaymentReceived.Invoice Numbers',
        defaultMessage: 'Invoice Numbers'
    },
    indemnityPaymentIndemnityPayment: {
        id: 'journey.event-templates.indemnityPayment.Indemnity Payment',
        defaultMessage: 'Indemnity Payment'
    },
    indemnityPaymentClaimNumber: {
        id: 'journey.event-templates.indemnityPayment.Claim Number',
        defaultMessage: 'Claim Number'
    },
    indemnityPaymentPayees: {
        id: 'journey.event-templates.indemnityPayment.Payees',
        defaultMessage: 'Payees'
    },
    indemnityPaymentCheckNumber: {
        id: 'journey.event-templates.indemnityPayment.Check Number',
        defaultMessage: 'Check Number'
    },
    indemnityPaymentExposureName: {
        id: 'journey.event-templates.indemnityPayment.Exposure Name',
        defaultMessage: 'Exposure Name'
    },
    indemnityPaymentAmount: {
        id: 'journey.event-templates.indemnityPayment.Amount',
        defaultMessage: 'Amount'
    },
    paymentReversedPaymentReversed: {
        id: 'journey.event-templates.paymentReversed.Payment Reversed',
        defaultMessage: 'Payment Reversed'
    },
    paymentReversedAmount: {
        id: 'journey.event-templates.paymentReversed.Amount',
        defaultMessage: 'Amount'
    },
    paymentReversedReceivedDate: {
        id: 'journey.event-templates.paymentReversed.Received Date',
        defaultMessage: 'Received Date'
    },
    paymentReversedReferenceNumber: {
        id: 'journey.event-templates.paymentReversed.Reference Number',
        defaultMessage: 'Reference Number'
    },
    paymentReversedReversalDate: {
        id: 'journey.event-templates.paymentReversed.Reversal Date',
        defaultMessage: 'Reversal Date'
    },
    paymentReversedReversalReason: {
        id: 'journey.event-templates.paymentReversed.Reversal Reason',
        defaultMessage: 'Reversal Reason'
    },
    paymentReversedInvoiceNumber: {
        id: 'journey.event-templates.paymentReversed.Invoice Number',
        defaultMessage: 'Invoice Number'
    },
    paymentReversedInvoiceNumbers: {
        id: 'journey.event-templates.paymentReversed.Invoice Numbers',
        defaultMessage: 'Invoice Numbers'
    },
    policyChangemortgageeAddedPolicyMortgageeAdded: {
        id: 'journey.event-templates.policyChange-mortgageeAdded.Policy Mortgagee Added',
        defaultMessage: 'Policy Mortgagee Added'
    },
    policyChangemortgageeAddedName: {
        id: 'journey.event-templates.policyChange-mortgageeAdded.Name',
        defaultMessage: 'Name'
    },
    policyChangemortgageeAddedAddress: {
        id: 'journey.event-templates.policyChange-mortgageeAdded.Address',
        defaultMessage: 'Address'
    },
    policyChangemortgageeAddedPhone: {
        id: 'journey.event-templates.policyChange-mortgageeAdded.Phone',
        defaultMessage: 'Phone'
    },
    policyChangemortgageeAddedEmail: {
        id: 'journey.event-templates.policyChange-mortgageeAdded.Email',
        defaultMessage: 'Email'
    },
    policyChangemortgageeAddedEffectiveDate: {
        id: 'journey.event-templates.policyChange-mortgageeAdded.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyChangemortgageeRemovedPolicyMortgageeRemoved: {
        id: 'journey.event-templates.policyChange-mortgageeRemoved.Policy Mortgagee Removed',
        defaultMessage: 'Policy Mortgagee Removed'
    },
    policyChangemortgageeRemovedName: {
        id: 'journey.event-templates.policyChange-mortgageeRemoved.Name',
        defaultMessage: 'Name'
    },
    policyChangemortgageeRemovedAddress: {
        id: 'journey.event-templates.policyChange-mortgageeRemoved.Address',
        defaultMessage: 'Address'
    },
    policyChangemortgageeRemovedPhone: {
        id: 'journey.event-templates.policyChange-mortgageeRemoved.Phone',
        defaultMessage: 'Phone'
    },
    policyChangemortgageeRemovedEmail: {
        id: 'journey.event-templates.policyChange-mortgageeRemoved.Email',
        defaultMessage: 'Email'
    },
    policyChangemortgageeRemovedEffectiveDate: {
        id: 'journey.event-templates.policyChange-mortgageeRemoved.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyChangemortgageeChangedPolicyMortgageeChanged: {
        id: 'journey.event-templates.policyChange-mortgageeChanged.Policy Mortgagee Changed',
        defaultMessage: 'Policy Mortgagee Changed'
    },
    policyChangemortgageeChangedName: {
        id: 'journey.event-templates.policyChange-mortgageeChanged.Name',
        defaultMessage: 'Name'
    },
    policyChangemortgageeChangedEffectiveDate: {
        id: 'journey.event-templates.policyChange-mortgageeChanged.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyChangevaluableAddedPolicyValuableAdded: {
        id: 'journey.event-templates.policyChange-valuableAdded.Policy Valuable Added',
        defaultMessage: 'Policy Valuable Added'
    },
    policyChangevaluableAddedType: {
        id: 'journey.event-templates.policyChange-valuableAdded.Type',
        defaultMessage: 'Type'
    },
    policyChangevaluableAddedDescription: {
        id: 'journey.event-templates.policyChange-valuableAdded.Description',
        defaultMessage: 'Description'
    },
    policyChangevaluableAddedValue: {
        id: 'journey.event-templates.policyChange-valuableAdded.Value',
        defaultMessage: 'Value'
    },
    policyChangevaluableRemovedPolicyValuableRemoved: {
        id: 'journey.event-templates.policyChange-valuableRemoved.Policy Valuable Removed',
        defaultMessage: 'Policy Valuable Removed'
    },
    policyChangevaluableRemovedType: {
        id: 'journey.event-templates.policyChange-valuableRemoved.Type',
        defaultMessage: 'Type'
    },
    policyChangevaluableRemovedDescription: {
        id: 'journey.event-templates.policyChange-valuableRemoved.Description',
        defaultMessage: 'Description'
    },
    policyChangevaluableRemovedValue: {
        id: 'journey.event-templates.policyChange-valuableRemoved.Value',
        defaultMessage: 'Value'
    },
    policyChangevaluableChangedPolicyValuableChanged: {
        id: 'journey.event-templates.policyChange-valuableChanged.Policy Valuable Changed',
        defaultMessage: 'Policy Valuable Changed'
    },
    policyChangevaluableChangedType: {
        id: 'journey.event-templates.policyChange-valuableChanged.Type',
        defaultMessage: 'Type'
    },
    policyChangevaluableChangedDescription: {
        id: 'journey.event-templates.policyChange-valuableChanged.Description',
        defaultMessage: 'Description'
    },
    policyChangevaluableChangedValue: {
        id: 'journey.event-templates.policyChange-valuableChanged.Value',
        defaultMessage: 'Value'
    },
    policyChangedriverAddedPolicyDriverAdded: {
        id: 'journey.event-templates.policyChange-driverAdded.Policy Driver Added',
        defaultMessage: 'Policy Driver Added'
    },
    policyChangedriverAddedName: {
        id: 'journey.event-templates.policyChange-driverAdded.Name',
        defaultMessage: 'Name'
    },
    policyChangedriverAddedAddress: {
        id: 'journey.event-templates.policyChange-driverAdded.Address',
        defaultMessage: 'Address'
    },
    policyChangedriverAddedPhone: {
        id: 'journey.event-templates.policyChange-driverAdded.Phone',
        defaultMessage: 'Phone'
    },
    policyChangedriverAddedEmail: {
        id: 'journey.event-templates.policyChange-driverAdded.Email',
        defaultMessage: 'Email'
    },
    policyChangedriverAddedEffectiveDate: {
        id: 'journey.event-templates.policyChange-driverAdded.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyChangedriverRemovedPolicyDriverRemoved: {
        id: 'journey.event-templates.policyChange-driverRemoved.Policy Driver Removed',
        defaultMessage: 'Policy Driver Removed'
    },
    policyChangedriverRemovedName: {
        id: 'journey.event-templates.policyChange-driverRemoved.Name',
        defaultMessage: 'Name'
    },
    policyChangedriverRemovedAddress: {
        id: 'journey.event-templates.policyChange-driverRemoved.Address',
        defaultMessage: 'Address'
    },
    policyChangedriverRemovedPhone: {
        id: 'journey.event-templates.policyChange-driverRemoved.Phone',
        defaultMessage: 'Phone'
    },
    policyChangedriverRemovedEmail: {
        id: 'journey.event-templates.policyChange-driverRemoved.Email',
        defaultMessage: 'Email'
    },
    policyChangedriverChangedPolicyDriverChanged: {
        id: 'journey.event-templates.policyChange-driverChanged.Policy Driver Changed',
        defaultMessage: 'Policy Driver Changed'
    },
    policyChangedriverChangedName: {
        id: 'journey.event-templates.policyChange-driverChanged.Name',
        defaultMessage: 'Name'
    },
    policyChangedriverChangedEffectiveDate: {
        id: 'journey.event-templates.policyChange-driverChanged.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyChangedriverRemovedEffectiveDate: {
        id: 'journey.event-templates.policyChange-driverRemoved.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyChangevehicleDriverAddedPolicyVehicleDriverAdded: {
        id: 'journey.event-templates.policyChange-vehicleDriverAdded.Policy Vehicle Driver Added',
        defaultMessage: 'Policy Vehicle Driver Added'
    },
    policyChangevehicleDriverAddedName: {
        id: 'journey.event-templates.policyChange-vehicleDriverAdded.Name',
        defaultMessage: 'Name'
    },
    policyChangevehicleDriverAddedAddress: {
        id: 'journey.event-templates.policyChange-vehicleDriverAdded.Address',
        defaultMessage: 'Address'
    },
    policyChangevehicleDriverAddedPhone: {
        id: 'journey.event-templates.policyChange-vehicleDriverAdded.Phone',
        defaultMessage: 'Phone'
    },
    policyChangevehicleDriverAddedEmail: {
        id: 'journey.event-templates.policyChange-vehicleDriverAdded.Email',
        defaultMessage: 'Email'
    },
    policyChangevehicleDriverAddedEffectiveDate: {
        id: 'journey.event-templates.policyChange-vehicleDriverAdded.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyChangevehicleDriverRemovedPolicyVehicleDriverRemoved: {
        id: 'journey.event-templates.policyChange-vehicleDriverRemoved.Policy Vehicle Driver Removed',
        defaultMessage: 'Policy Vehicle Driver Removed'
    },
    policyChangevehicleDriverRemovedName: {
        id: 'journey.event-templates.policyChange-vehicleDriverRemoved.Name',
        defaultMessage: 'Name'
    },
    policyChangevehicleDriverRemovedAddress: {
        id: 'journey.event-templates.policyChange-vehicleDriverRemoved.Address',
        defaultMessage: 'Address'
    },
    policyChangevehicleDriverRemovedPhone: {
        id: 'journey.event-templates.policyChange-vehicleDriverRemoved.Phone',
        defaultMessage: 'Phone'
    },
    policyChangevehicleDriverRemovedEmail: {
        id: 'journey.event-templates.policyChange-vehicleDriverRemoved.Email',
        defaultMessage: 'Email'
    },
    policyChangevehicleDriverRemovedEffectiveDate: {
        id: 'journey.event-templates.policyChange-vehicleDriverRemoved.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyChangevehicleAddedPolicyVehicleAdded: {
        id: 'journey.event-templates.policyChange-vehicleAdded.Policy Vehicle Added',
        defaultMessage: 'Policy Vehicle Added'
    },
    policyChangevehicleAddedVehicleNumber: {
        id: 'journey.event-templates.policyChange-vehicleAdded.Vehicle Number',
        defaultMessage: 'Vehicle Number'
    },
    policyChangevehicleAddedVehicleType: {
        id: 'journey.event-templates.policyChange-vehicleAdded.Vehicle Type',
        defaultMessage: 'Vehicle Type'
    },
    policyChangevehicleAddedVIN: {
        id: 'journey.event-templates.policyChange-vehicleAdded.VIN',
        defaultMessage: 'VIN'
    },
    policyChangevehicleAddedModelYear: {
        id: 'journey.event-templates.policyChange-vehicleAdded.Model Year',
        defaultMessage: 'Model Year'
    },
    policyChangevehicleAddedMake: {
        id: 'journey.event-templates.policyChange-vehicleAdded.Make',
        defaultMessage: 'Make'
    },
    policyChangevehicleAddedBodyType: {
        id: 'journey.event-templates.policyChange-vehicleAdded.Body Type',
        defaultMessage: 'Body Type'
    },
    policyChangevehicleAddedColor: {
        id: 'journey.event-templates.policyChange-vehicleAdded.Color',
        defaultMessage: 'Color'
    },
    policyChangevehicleAddedLicencePlate: {
        id: 'journey.event-templates.policyChange-vehicleAdded.Licence Plate',
        defaultMessage: 'Licence Plate'
    },
    policyChangevehicleAddedLicenceState: {
        id: 'journey.event-templates.policyChange-vehicleAdded.Licence State',
        defaultMessage: 'Licence State'
    },
    policyChangevehicleAddedPrimaryUse: {
        id: 'journey.event-templates.policyChange-vehicleAdded.Primary Use',
        defaultMessage: 'Primary Use'
    },
    policyChangevehicleAddedCostNew: {
        id: 'journey.event-templates.policyChange-vehicleAdded.Cost New',
        defaultMessage: 'Cost New'
    },
    policyChangevehicleAddedStatedValue: {
        id: 'journey.event-templates.policyChange-vehicleAdded.Stated Value',
        defaultMessage: 'Stated Value'
    },
    policyChangevehicleAddedAnnualMileage: {
        id: 'journey.event-templates.policyChange-vehicleAdded.Annual Mileage',
        defaultMessage: 'Annual Mileage'
    },
    policyChangevehicleAddedCommutingMiles: {
        id: 'journey.event-templates.policyChange-vehicleAdded.Commuting Miles',
        defaultMessage: 'Commuting Miles'
    },
    policyChangevehicleAddedEffectiveDate: {
        id: 'journey.event-templates.policyChange-vehicleAdded.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyChangevehicleRemovedPolicyVehicleRemoved: {
        id: 'journey.event-templates.policyChange-vehicleRemoved.Policy Vehicle Removed',
        defaultMessage: 'Policy Vehicle Removed'
    },
    policyChangevehicleRemovedVehicleNumber: {
        id: 'journey.event-templates.policyChange-vehicleRemoved.Vehicle Number',
        defaultMessage: 'Vehicle Number'
    },
    policyChangevehicleRemovedVehicleType: {
        id: 'journey.event-templates.policyChange-vehicleRemoved.Vehicle Type',
        defaultMessage: 'Vehicle Type'
    },
    policyChangevehicleRemovedVIN: {
        id: 'journey.event-templates.policyChange-vehicleRemoved.VIN',
        defaultMessage: 'VIN'
    },
    policyChangevehicleRemovedModelYear: {
        id: 'journey.event-templates.policyChange-vehicleRemoved.Model Year',
        defaultMessage: 'Model Year'
    },
    policyChangevehicleRemovedMake: {
        id: 'journey.event-templates.policyChange-vehicleRemoved.Make',
        defaultMessage: 'Make'
    },
    policyChangevehicleRemovedBodyType: {
        id: 'journey.event-templates.policyChange-vehicleRemoved.Body Type',
        defaultMessage: 'Body Type'
    },
    policyChangevehicleRemovedColor: {
        id: 'journey.event-templates.policyChange-vehicleRemoved.Color',
        defaultMessage: 'Color'
    },
    policyChangevehicleRemovedLicencePlate: {
        id: 'journey.event-templates.policyChange-vehicleRemoved.Licence Plate',
        defaultMessage: 'Licence Plate'
    },
    policyChangevehicleRemovedLicenceState: {
        id: 'journey.event-templates.policyChange-vehicleRemoved.Licence State',
        defaultMessage: 'Licence State'
    },
    policyChangevehicleRemovedCostNew: {
        id: 'journey.event-templates.policyChange-vehicleRemoved.Cost New',
        defaultMessage: 'Cost New'
    },
    policyChangevehicleRemovedStatedValue: {
        id: 'journey.event-templates.policyChange-vehicleRemoved.Stated Value',
        defaultMessage: 'Stated Value'
    },
    policyChangevehicleRemovedAnnualMileage: {
        id: 'journey.event-templates.policyChange-vehicleRemoved.Annual Mileage',
        defaultMessage: 'Annual Mileage'
    },
    policyChangevehicleRemovedCommutingMiles: {
        id: 'journey.event-templates.policyChange-vehicleRemoved.Commuting Miles',
        defaultMessage: 'Commuting Miles'
    },
    policyChangevehicleRemovedEffectiveDate: {
        id: 'journey.event-templates.policyChange-vehicleRemoved.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyChangevehicleChangedPolicyVehicleChanged: {
        id: 'journey.event-templates.policyChange-vehicleChanged.Policy Vehicle Changed',
        defaultMessage: 'Policy Vehicle Changed'
    },
    policyChangevehicleChangedVehicleNumber: {
        id: 'journey.event-templates.policyChange-vehicleChanged.Vehicle Number',
        defaultMessage: 'Vehicle Number'
    },
    policyChangevehicleChangedVehicleType: {
        id: 'journey.event-templates.policyChange-vehicleChanged.Vehicle Type',
        defaultMessage: 'Vehicle Type'
    },
    policyChangevehicleChangedVIN: {
        id: 'journey.event-templates.policyChange-vehicleChanged.VIN',
        defaultMessage: 'VIN'
    },
    policyChangevehicleChangedModelYear: {
        id: 'journey.event-templates.policyChange-vehicleChanged.Model Year',
        defaultMessage: 'Model Year'
    },
    policyChangevehicleChangedMake: {
        id: 'journey.event-templates.policyChange-vehicleChanged.Make',
        defaultMessage: 'Make'
    },
    policyChangevehicleChangedBodyType: {
        id: 'journey.event-templates.policyChange-vehicleChanged.Body Type',
        defaultMessage: 'Body Type'
    },
    policyChangevehicleChangedColor: {
        id: 'journey.event-templates.policyChange-vehicleChanged.Color',
        defaultMessage: 'Color'
    },
    policyChangevehicleChangedLicencePlate: {
        id: 'journey.event-templates.policyChange-vehicleChanged.Licence Plate',
        defaultMessage: 'Licence Plate'
    },
    policyChangevehicleChangedLicenceState: {
        id: 'journey.event-templates.policyChange-vehicleChanged.Licence State',
        defaultMessage: 'Licence State'
    },
    policyChangevehicleChangedCostNew: {
        id: 'journey.event-templates.policyChange-vehicleChanged.Cost New',
        defaultMessage: 'Cost New'
    },
    policyChangevehicleChangedStatedValue: {
        id: 'journey.event-templates.policyChange-vehicleChanged.Stated Value',
        defaultMessage: 'Stated Value'
    },
    policyChangevehicleChangedAnnualMileage: {
        id: 'journey.event-templates.policyChange-vehicleChanged.Annual Mileage',
        defaultMessage: 'Annual Mileage'
    },
    policyChangevehicleChangedCommutingMiles: {
        id: 'journey.event-templates.policyChange-vehicleChanged.Commuting Miles',
        defaultMessage: 'Commuting Miles'
    },
    policyChangevehicleChangedPrimaryUse: {
        id: 'journey.event-templates.policyChange-vehicleChanged.Primary Use',
        defaultMessage: 'Primary Use'
    },
    policyChangevehicleChangedEffectiveDate: {
        id: 'journey.event-templates.policyChange-vehicleChanged.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyChangeaddedCoveragesPolicyCoverageAdded: {
        id: 'journey.event-templates.policyChange-addedCoverages.Policy Coverage Added',
        defaultMessage: 'Policy Coverage Added'
    },
    policyChangeaddedCoveragesEffectiveDate: {
        id: 'journey.event-templates.policyChange-addedCoverages.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyChangeremovedCoveragesPolicyCoverageRemoved: {
        id: 'journey.event-templates.policyChange-removedCoverages.Policy Coverage Removed',
        defaultMessage: 'Policy Coverage Removed'
    },
    policyChangeremovedCoveragesEffectiveDate: {
        id: 'journey.event-templates.policyChange-removedCoverages.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyChangechangedCoveragesPolicyCoverageChanged: {
        id: 'journey.event-templates.policyChange-changedCoverages.Policy Coverage Changed',
        defaultMessage: 'Policy Coverage Changed'
    },
    policyChangechangedCoveragesEffectiveDate: {
        id: 'journey.event-templates.policyChange-changedCoverages.Effective Date',
        defaultMessage: 'Effective Date'
    },
    filterPlaceholder: {
        id: 'gateway.directives.ClaimSummaryDirective.ClaimSummary.Filters',
        defaultMessage: 'Filters'
    },
    transactionPolicyNumber: {
        id: 'gateway.directives.policy-transaction.Policy Number',
        defaultMessage: 'Contract Number'
    },
    transactionUWIssueStatus: {
        id: 'gateway.directives.policy-transaction.UW Issue Status',
        defaultMessage: 'UW Issue Status'
    },
    allTransaction: {
        id: 'gateway.views.policy-detail.All',
        defaultMessage: 'All'
    },
    draftTransaction: {
        id: 'gateway.views.policy-detail.Draft',
        defaultMessage: 'Draft'
    },
    noOpenTransaction: {
        id: 'gateway.views.account-landing.No results found for this filter',
        defaultMessage: 'No results found for this filter'
    },
    noPoliciesForAccount: {
        id: 'gateway.directives.account-detail.There are no policies on this account',
        defaultMessage: 'There are no contracts on this client'
    },
    setupAutomaticPaymentMessage: {
        id: 'billing.view.setup-stream-autopay.Enabling Automatic Payments Description',
        defaultMessage: 'Enabling Automatic Payments will mean that, starting with the next payment, the current balance due on your account will be paid on the due date specified on the schedule, using the specified payment method. If you have provided an email address in your personal information, you will receive a confirmation email when the payment has been processed or if there were any issues with your payment.'
    },
    setupAutomaticPaymentSchedule: {
        id: 'billing.view.setup-stream-autopay.Automatic Payment Schedule For Name',
        defaultMessage: 'Automatic Payment Schedule For {displayName}'
    },
    setupAutomaticPaymentNoPending: {
        id: 'billing.view.setup-stream-autopay.You have no pending payments in this group',
        defaultMessage: 'You have no pending payments in this group'
    },
    scheduleTermTitle: {
        id: 'gateway.views.gateway-setup-stream-autopay.Payment schedule is',
        defaultMessage: 'Payment schedule is'
    },
    scheduleTermDescription: {
        id: 'billing.view.setup-stream-autopay.Schedule Term Description',
        defaultMessage: '{periodicity} starting on {startDate}'
    },
    yourPaymentSchedule: {
        id: 'billing.view.setup-stream-autopay.Your payment schedule is',
        defaultMessage: 'Your payment schedule is:'
    },
    premiumAmount: {
        id: 'billing.view.setup-stream-autopay.Premium Amount',
        defaultMessage: 'Premium Amount:'
    },
    showDetailedSchedule: {
        id: 'billing.view.setup-stream-autopay.Show Detailed Schedule',
        defaultMessage: 'Show Detailed Schedule'
    },
    hideDetailedSchedule: {
        id: 'billing.view.setup-stream-autopay.Hide Detailed Schedule',
        defaultMessage: 'Hide Detailed Schedule'
    },
    invoicePolicyDetails: {
        id: 'billing.directives.templates.billing-line-policies-summary.Invoice Policy Details',
        defaultMessage: 'Invoice Policy Details'
    },
    detailedScheduleDueDate: {
        id: 'billing.view.setup-stream-autopay.Due date',
        defaultMessage: 'Due date'
    },
    detailedScheduleBillStatus: {
        id: 'billing.view.setup-stream-autopay.Bill Status',
        defaultMessage: 'Bill Status'
    },
    detailedSchedulePaidStatus: {
        id: 'billing.view.setup-stream-autopay.Paid Status',
        defaultMessage: 'Paid Status'
    },
    detailedSchedulePremium: {
        id: 'billing.view.setup-stream-autopay.Premium',
        defaultMessage: 'Premium'
    },
    detailedScheduleCharges: {
        id: 'billing.view.setup-stream-autopay.Charges',
        defaultMessage: 'Charges'
    },
    detailedScheduleTotalPaid: {
        id: 'billing.view.setup-stream-autopay.Total Paid',
        defaultMessage: 'Total Paid'
    },
    detailedScheduleOutstanding: {
        id: 'billing.view.setup-stream-autopay.Outstanding Amount',
        defaultMessage: 'Outstanding Amount'
    },
    detailedScheduleInvoice: {
        id: 'billing.view.setup-stream-autopay.Invoice Num',
        defaultMessage: 'Invoice #'
    },
    paymentMethod: {
        id: 'billing.view.setup-stream-autopay.Payment Method',
        defaultMessage: 'Payment Method'
    },
    setupPaymentCancel: {
        id: 'billing.view.setup-stream-autopay.Cancel',
        defaultMessage: 'Cancel'
    },
    setupPaymentConfirm: {
        id: 'billing.view.setup-stream-autopay.Set up Automatic Payments Now',
        defaultMessage: 'Set up Automatic Payments Now'
    },
    setupPaymentConfirmTitle: {
        id: 'billing.view.setup-autopay-confirmation.Automatic Payments',
        defaultMessage: 'Automatic Payments'
    },
    setupPaymentConfirmMessage: {
        id: 'billing.view.setup-autopay-confirmation.Automatic payments enabled',
        defaultMessage: 'Automatic payments enabled.'
    },
    setupPaymentConfirmPhone: {
        id: 'billing.view.setup-autopay-confirmation.If you want to disable automatic payments please contact us at',
        defaultMessage: 'If you want to disable automatic payments, please contact us at {supportPhone}.'
    },
    setupPaymentConfirmRreturn: {
        id: 'billing.view.setup-autopay-confirmation.Return',
        defaultMessage: 'Return'
    },
    setupPaymentConfirmNextPayment: {
        id: 'amp.bc3.directives.templates.autopay-summary.next-payment',
        defaultMessage: 'Next payment: {currencyAmount}'
    },
    setupPaymentConfirmDue: {
        id: 'amp.bc3.directives.templates.autopay-summary.due',
        defaultMessage: 'Due: {dueDate}'
    },
    primaryNumber: {
        id: 'gateway.directives.contactphone-details.Primary number',
        defaultMessage: 'Primary number'
    },
    noWorkOrderMessage: {
        id: 'gateway.directives.policy-transaction.There are no work orders matching these filters',
        defaultMessage: 'There are no work orders matching these filters:'
    },
    invalidPhoneMessage: {
        id: 'gateway.views.The provided phone number is not valid',
        defaultMessage: 'The provided phone number is not valid'
    },
    setupPaymentFailed: {
        id: 'billing.controllers.SetupStreamAutopayCtrl.Account update failed',
        defaultMessage: 'Account update failed'
    },
    setupPaymentFailedSorry: {
        id: 'billing.controllers.SetupStreamAutopayCtrl.Sorry, we were not able to setup automatic payments on your account',
        defaultMessage: 'Sorry, we were not able to setup automatic payments on your account'
    },
    automaticPaymentMessage: {
        id: 'gateway.views.gateway-setup-stream-autopay.Enabling Automatic Payments Description',
        defaultMessage: 'Enabling Automatic Payments will mean that, starting with the next payment, the current balance due on the customers account will be paid on the due date specified on the schedule, using the specified payment method. If customer has provided an email address in their personal information, they will receive a confirmation email when the payment has been processed or if there were any issues with the payment'
    },
    phoneNumber: {
        id: 'gateway.views.account-detail.Phone Number',
        defaultMessage: 'Phone Number'
    },
    summaryDetailsHeader: {
        id: 'gateway.views.account-details.Summary Details',
        defaultMessage: 'Summary Details'
    },
    producerInformationHeader: {
        id: 'gateway.views.account-details.Producer Information',
        defaultMessage: 'Producer Information'
    },
    editDetails: {
        id: 'gateway.views.account-detail.Edit Details',
        defaultMessage: 'Edit Details'
    }
});
