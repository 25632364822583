import { defineMessages } from 'react-intl';

export default defineMessages({
    genericTechnicalErrorTitle: {
        id: 'gateway.quoteandbind.common.util.actionmessageutil.Generic Title Message Errors',
        defaultMessage: 'Technical error. The service is temporarily not available.'
    },
    genericTechnicalErrorMsg: {
        id: 'gateway.quoteandbind.common.util.actionmessageutil.Generic Message Technical Errors',
        defaultMessage: 'Unfortunately, we cannot help you at this moment because of a technical error in the backend. We are aware of this problem and are doing our best to solve it as quickly as possible. Please try again in a moment or contact the help desk if the problem persists.'
    },
    genericTimeoutErrorMsg: {
        id: 'gateway.quoteandbind.common.util.actionmessageutil.Generic Message Timeout Errors',
        defaultMessage: 'It looks like the server is taking too long to respond. We are aware of this problem and are doing our best to solve it as quickly as possible. Please try again in a moment or contact the help desk if the problem persists.'
    },
    uwIssueErrorTitle: {
        id: 'gateway.quoteandbind.common.util.actionmessageutil.Underwriting issues have been triggered title',
        defaultMessage: "We're sorry"
    },
    uwIssueErrorMsg: {
        id: 'gateway.quoteandbind.common.util.actionmessageutil.Underwriting issues have been triggered information',
        defaultMessage: "Unfortunately, we can't provide you with a quote based on the provided information"
    },
    uwIssueErrorBtn: {
        id: 'gateway.quoteandbind.common.util.actionmessageutil.View issues',
        defaultMessage: 'View Issues'
    },
    infoWillBeSavedAsDraft: {
        id: 'quoteandbind.common.config.FlowConfig.The information previously entered will be stored as a quote submission',
        defaultMessage: 'The information you previously entered will be stored so you can return to the tariff later.'
    },
    finish: {
        id: "platform.widgets.wizard.Finish",
        defaultMessage: "Finish"
    },
    finishTariff: {
        id: "platform.widgets.wizard.Finish tariff",
        defaultMessage: "Finish tariff"
    },
    tariff: {
        id: 'app.html.index.Tariff',
        defaultMessage: 'Tariff'
    },
    newBusiness: {
        id: 'app.html.index.New Business',
        defaultMessage: 'New Business'
    },
    unableToQuoteTitle: {
        id: 'endorsement.common.config.Unable to quote policy change',
        defaultMessage: 'Unable to Quote Contract Change'
    },
    unableToQuoteMsg: {
        id: 'endorsement.common.config.An error occurred while attempting to quote the policy change.',
        defaultMessage: 'Something went wrong during this process.'
    },
    continueCancellation: {
        id: 'gateway.views.job-uwissues.Continue Cancellation',
        defaultMessage: 'Continue Cancellation'
    },
    discountError:{
        id: 'gateway.quoteandbind.common.util.actionmessageutil.Custom Discount maximum Error',
        defaultMessage: 'Total discounts may not exceed 100%'
    },
    enterpriseError:{
        id: "displaykey.PV.Edge.Web.Api.Model.AcccountHandler.EnterpriseNumber",
        defaultMessage: "Enterprise number: enterprise number is not in the valid format"
    }
});
