import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('gatewayquote/quote'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the Quote and Buy backend operations
 *
 * @export
 * @class LoadSaveService
 */
export default class LoadSaveService {
    /**
     * Updates, saves and performs a quote on an existing submission.
     * Generates quotes for all available product offerings.
     *
     * @param {Object} data tge submission that will be saved
     * @returns {Promise} the promise from the backend call
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof LoadSaveService
     */
    static saveAndQuoteSubmission(data, additionalHeaders = {}) {
        return processSubmission('saveAndQuote', [data], additionalHeaders);
    }

    /**
     * Retrieves a submission.
     *
     * @param {Object} data the retrieval payload (QuoteRetrievalDTO)
     * @returns {Promise} the promise from the backend call
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof LoadSaveService
     */
    static retrieveSubmission(data, additionalHeaders = {}) {
        return processSubmission('retrieve', [data], additionalHeaders);
    }

    /**
     * Updates a quoted submission with LOB Data.
     *
     * @param {Object} data the submission to be saved as draft
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateQuotedSubmissionWithLOBData(data, additionalHeaders = {}) {
        return processSubmission('updateQuotedSubmissionWithLOBData', [data], additionalHeaders);
    }

    /**
     * Retrieves a submission.
     *
     * @param {string} quoteID quoteID of submission that needs to be found
     * @param {string} sessionUUID current session id
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static retrievePaymentPlans(quoteID, sessionUUID, additionalHeaders = {}) {
        return processSubmission('retrievePaymentPlans', [quoteID, sessionUUID], additionalHeaders);
    }

    /**
     * Binds the submission.
     *
     * @param {Object} data the submission to be bound
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static bindSubmission(data, additionalHeaders = {}) {
        return processSubmission('bind', [data], additionalHeaders);
    }

    /**
     * Updates an existing draft submission.
     *
     * @param {Object} data the submission to be saved as draft
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateDraftSubmission(data, additionalHeaders = {}) {
        return processSubmission('updateDraftSubmission', [data], additionalHeaders);
    }

    /**
     * Updates a quoted submission.
     *
     * @param {Object} data the submission to be saved as draft
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateQuotedSubmission(data, additionalHeaders = {}) {
        return processSubmission('updateQuotedSubmission', [data], additionalHeaders);
    }

    /**
     * Updates coverages.
     *
     * @param {Object} quoteID quoteID
     * @param {Object} sessionUUID sessionUUID
     * @param {Object} lobCoverages the coverages to be saved
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */

    static updateCoverages(quoteID, sessionUUID, lobCoverages, additionalHeaders = {}) {
        return processSubmission(
            'updateCoverages',
            [quoteID, sessionUUID, lobCoverages],
            additionalHeaders
        );
    }

    /**
     * Approve Underwriting Issue.
     *
     * @param {Object} quoteID quoteID
     * @param {Object} uwIssue uwIssue
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static approveUnderwritingIssue(quoteID, uwIssue, additionalHeaders = {}) {
        return processSubmission(
            'approveUnderwritingIssue',
            [quoteID, uwIssue],
            additionalHeaders
        );
    }

    // P&V Functions

    /**
     * Save Own Reference.
     *
     * @param {Object} params Containing own-reference value
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static saveOwnReference(params, additionalHeaders = {}) {
        return processSubmission(
            'saveOwnReference_PV',
            [params],
            additionalHeaders
        );
    }

    /**
     * copy Submission
     *
     * @param {Object} quoteNumber quoteNumber
     * @param {Object} policyNumber policyNumber
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static copySubmissionPV(quoteNumber, policyNumber, additionalHeaders = {}) {
        return processSubmission(
            'copySubmission_PV',
            [quoteNumber, policyNumber],
            additionalHeaders
        );
    }

    /**
     * Full quote summary update
     *
     * @param {Object} params Containing submission value
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateSummaryAndProposeSubmissionPV(params, additionalHeaders = {}) {
        return processSubmission(
            'updateSummaryAndProposeSubmission_PV',
            [params],
            additionalHeaders
        );
    }

    static updateAdditionalInfoPV(quoteData, additionalHeaders = {}) {
        return processSubmission(
            'updateAdditionalInfo_PV',
            [quoteData],
            additionalHeaders
        );
    }

    static generateDocument(policyNumber, documentType, additionalHeaders = {}) {
        return processSubmission(
            'generateDocument_PV',
            [policyNumber, documentType],
            additionalHeaders
        );
    }

    static getPacks_PV(quoteData, additionalHeaders = {}) {
        return processSubmission(
            'getPacks_PV',
            [quoteData],
            additionalHeaders
        );
    }

    static getPremium_PV(quoteData, additionalHeaders = {}) {
        return processSubmission(
            'getPremium_PV',
            [quoteData],
            additionalHeaders
        );
    }

    static setSelectedVersionOnSubmission_PV(
        quoteId,
        branchName,
        sessionUUID,
        paymentMethod,
        additionalHeaders = {}) {
        return processSubmission(
            'setSelectedVersionOnSubmission_PV',
            [quoteId, branchName, sessionUUID, paymentMethod],
            additionalHeaders
        );
    }

    static getAvailableBillingMethodForProducerCode_PV(producerCode, jobnumber, paymentMethod, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quickquote'), 'getAvailableBillingMethodForProducerCode_PV', [producerCode, jobnumber, paymentMethod], additionalHeaders);
    }

    static retrievePaymentPlans_PV(QuoteID, BillingMethod, PaymentMethod, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quickquote'), 'retrievePaymentPlans_PV', [QuoteID, BillingMethod, PaymentMethod], additionalHeaders);
    }

    static retrievePaymentPlansForBroker_PPV(QuoteID, BillingMethod, PaymentMethod, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quickquote'), 'retrievePaymentPlansForBroker_PPV', [QuoteID, BillingMethod, PaymentMethod], additionalHeaders);
    }


    /**
     * Save the signing and payment method for a quote.
     *
     * @param {String} quoteId quoteID
     * @param {*} sessionUUID sessionUUID
     * @param {*} signingMethod signingMethod
     * @param {*} paymentMethod paymentMethod
     * @param {*} additionalHeaders additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static saveSigningAndPaymentMethod_PV(
        quoteId,
        sessionUUID,
        signingMethod,
        paymentMethod,
        additionalHeaders = {}
    ) {
        return processSubmission(
            "saveSigningAndPaymentMethod",
            [quoteId, sessionUUID, signingMethod, paymentMethod],
            additionalHeaders
        );
    }

    /**
     * Save SEPA details.
     *
     * @param {String} sessionUUID sessionUUID
     * @param {String} quoteID quoteID
     * @param {Object} bankAccountData bankAccountData
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static saveSepaDetails(sessionUUID, quoteID, bankAccountData, additionalHeaders = {}) {
        return processSubmission(
            'saveSepaDetails_PV',
            [sessionUUID, quoteID, bankAccountData],
            additionalHeaders
        );
    }

    /**
     * Generate and downloads quote document
     *
     * @param {Object} params Containing quote email value
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static generateQuoteDocumentPV(params, additionalHeaders = {}) {
        return processSubmission(
            'generateQuoteDocumentPV',
            [params],
            additionalHeaders
        );
    }

    /**
     * Updates contract questions
     *
     * @param {Object} params Containing submission to be saved
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateContractQuestions(params, additionalHeaders = {}) {
        return processSubmission(
            'updateContractQuestions_PV',
            [params],
            additionalHeaders
        );
    }
}
