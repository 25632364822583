import React, { useEffect,useCallback } from 'react';
import { JobService } from 'gw-capability-gateway';
import { JobUtil } from '@xengage/gw-portals-util-js';
import appConfig from 'app-config';
import { useModal } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import messages from "../../Endorsement/Summary/Summary.messages.js";


const { lobQuoteURL } = appConfig;

function BmsQuote(props) {

    const {
        showAlert
    } = useModal();
    const { authHeader } = useAuthentication();
    const bmsSubmissionPV = useCallback(async () => {
        const { history } = props;
        const quickQuote = props.match.params.quickQuote;
        const jobNumber = props.match.params.submissionNumber;
        try {
            const newSubmissionResponse = await JobService.findJobByJobNumber(jobNumber, authHeader);
            if (newSubmissionResponse.jobNumber !== undefined && newSubmissionResponse.jobNumber > 0) {
                const nextLocation = {
                    quoteentry: {
                        postalCode: newSubmissionResponse.policy.account.accountHolder.primaryAddress.postalCode,
                        quoteID: newSubmissionResponse.jobNumber,
                        productCode: newSubmissionResponse.productCode,
                        isBmsQuote: true // indicates that quote is accessed from Portima
                    },
                };
                const url = (quickQuote === 'Tariff') ? lobQuoteURL[`QQ${nextLocation.quoteentry.productCode}`] : lobQuoteURL[nextLocation.quoteentry.productCode];
                history.push(url, nextLocation);
            }
            else {
                JobUtil.openJobInXCenter(newSubmissionResponse.jobNumber);
            }
        }
        catch (e) {
            showAlert({
                title: messages.error,
                message: messages.details,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                history.push('/policies');
            }, _.noop);;
        }
    },[authHeader, props, showAlert]);


    useEffect(() => {
        bmsSubmissionPV();
    });

    return <></>;
}

export default withRouter(BmsQuote);
