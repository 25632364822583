import { defineMessages } from 'react-intl';

export default defineMessages({
    added: {
        id: 'pv.policyjob.policyChangesComponent.Added',
        defaultMessage: 'Added'
    },
    changed: {
        id: 'pv.policyjob.policyChangesComponent.Changed',
        defaultMessage: 'Changed'
    },
    removed: {
        id: 'pv.policyjob.policyChangesComponent.Removed',
        defaultMessage: 'Removed'
    },
    policyChanges: {
        id: 'pv.policyjob.policyChangesComponent.Policy Changes',
        defaultMessage: 'Policy Changes'
    },
    address: {
        id: 'pv.policyjob.policyChangesComponent.Address',
        defaultMessage: 'Address'
    },
    drivers: {
        id: 'pv.policyjob.policyChangesComponent.Drivers',
        defaultMessage: 'Drivers'
    },
    vehicles: {
        id: 'pv.policyjob.policyChangesComponent.Vehicles',
        defaultMessage: 'Vehicles'
    },
});
