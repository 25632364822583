import { defineMessages } from 'react-intl';

export default defineMessages({
    uwIssueMsg: {
        id: 'quoteandbind.common.directives.MultiQuoteView.UW Issue Warning message',
        defaultMessage: 'Underwriting issues are triggered for this submission. Binding the contract will require underwriting approval. {link}'
    },
    moreDetails: {
        id: 'quoteandbind.common.views.quote-details.More Details',
        defaultMessage: 'View More Details.'
    }
});
