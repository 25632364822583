import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Contract",
        defaultMessage: "Contract"
    },
    policyholder: {
        id: "quoteandbind.ppv.views.ppv-contract-details.The Policyholder",
        defaultMessage: "The contract holder:"
    },
    communicationWithThePolicyholder: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Communication with the policyholder",
        defaultMessage: "Communication with the contract holder"
    },
    policyholderEmail: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Email",
        defaultMessage: "Email"
    },
    policyholderEmailConsent: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Client does not have or does not want to share an e-mail address",
        defaultMessage: "Client does not have or does not want to share an e-mail address"
    },
    policyholderEmailPromoConsent: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Does the client want to receive promotions via email",
        defaultMessage: "Does the client want to receive promotions via email?"
    },
    mobilePhone: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Mobile Phone",
        defaultMessage: "Mobile Phone"
    },
    policyholderContactPhoneTooltip: {
        id: "quoteandbind.ppv.views.ppv-contract-details.phone-details.Tooltip",
        defaultMessage: "Please insert a phone number in case the contract documents will be signed online"
    },
    beginningOfContract: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Beginning of contract",
        defaultMessage: "Beginning of contract"
    },
    startDateOfThePolicy: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Start date of the policy",
        defaultMessage: "Start date of the contract"
    },
    startDateOfThePolicyValidation: {
        id: "quoteandbind.common.views.contract-details.Info Change Start Date",
        defaultMessage: "Change of the start date can have an impact on the premium, you might need to requote. You can go back to the previous screen to do so."
    },
    TPLCoverages: {
        id: "quoteandbind.ppv.views.ppv-contract-details.The Third Party Liability coverage starts at another time",
        defaultMessage: "The Third Party Liability coverage starts at another time"
    },
    TPLCoveragesTitle: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Start date of the Third party liability coverage for the",
        defaultMessage: "Start date of the Third party liability coverage for the"
    },
    TPLCoveragesTooltip: {
        id: "quoteandbind.ppv.views.ppv-contract-details.TPL coverages.Tooltip",
        defaultMessage: "A later start date on one of the TPL coverages will adapt the quote price accordingly."
    },
    legalProtectionCoverages: {
        id: "quoteandbind.ppv.views.ppv-contract-details.The Legal Protection coverage starts at another time",
        defaultMessage: "The Legal Protection coverage starts at another time"
    },
    legalProtectionCoveragesTitle: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Start date of the Legal Protection coverage for the",
        defaultMessage: "Start date of the Legal Protection coverage for"
    },
    legalProtectionCoveragesTooltip: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Legal Protection coverages.Tooltip",
        defaultMessage: "A later start date on the legal protection coverage will adapt the quote price accordingly."
    },
    payment: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Payment",
        defaultMessage: "Payment"
    },
    paymentMethod: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Payment method",
        defaultMessage: "Payment method"
    },
    sepaDescription: {
        id: "quoteandbind.ppv.views.ppv-contract-details.sepa-description",
        defaultMessage: "The details of the SEPA mandate are filled in with the details of the contract holder. In order to complete the mandate additional information is required:"
    },
    freeReference: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Free reference",
        defaultMessage: "Free reference"
    },
    belgianBankAccountNumber: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Belgian Bank account number",
        defaultMessage: "Belgian Bank account number"
    },
    iban: {
        id: "quoteandbind.ppv.views.ppv-contract-details.IBAN",
        defaultMessage: "IBAN"
    },
    sepa: {
        id: "typekey.PolicyPaymentMethod_CxPV.sepa",
        defaultMessage: "SEPA Direct Debit"
    },
    error: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Error",
        defaultMessage: "Error"
    },
    minimumCoverageError: {
        id: "quoteandbind.ppv.views.ppv-contract-details.At least one coverage needs to have the same starting date as policy",
        defaultMessage: "At least one coverage needs to have the same starting date as the policy."
    },
    areYouSure: {
        id: "platform.widgets.wizard.Are you sure?",
        defaultMessage: "Are you sure?"
    },
    yes: {
        id: "quoteandbind.ppv.views.ppv-contract-details.Yes",
        defaultMessage: "Yes"
    },
    no: {
        id: "quoteandbind.ppv.views.ppv-contract-details.No",
        defaultMessage: "No"
    },
    navigatingBack: {
        id: "platform.widgets.wizard.Navigating back to this screen will retrigger the already approved UW rule.",
        defaultMessage: "Navigating back to this screen will retrigger the already approved UW rule."
    }
});
