import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class PVInsurersListService {

    // little cache for a current user
    static _listOfInsurers = [];

    /**
     * Creates a submission for an account
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static getInsurersLists(additionalHeaders = {}) {
        if (this._listOfInsurers.length > 0) {
            // return cached version
            return Promise.resolve(this._listOfInsurers);
        }

        return JsonRPCService.send(
            getProxiedServiceUrl('insurers'),
            'getInsurers_PV',
            [],
            additionalHeaders
        ).then((result) => {
            this._listOfInsurers = result;
            return this._listOfInsurers;
        });
    }
}
