import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import FilterOptions from './FilterOptions';
import styles from './AdvancedFilter.module.scss';

class ProductFilterCard extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        productProperties: PropTypes.shape([]).isRequired,
        onValueChange: PropTypes.func.isRequired
    };

    onValueChange = (value) => {
        const { productProperties, onValueChange } = this.props;
        productProperties.lobFilter = value;
        onValueChange(productProperties);
    };

    render() {
        const translator = this.context;
        const { productProperties } = this.props;
        if (!productProperties.lobFilter) {
            return null;
        }
        return (
            <div className={styles.gw_filter_section}>
                <div className={styles.gw_filter_section_title}>
                    {translator(productProperties.lobFilter.displayKey)}
                </div>
                <FilterOptions
                    filters={productProperties.lobFilter}
                    onValueChange={this.onValueChange}
                />
            </div>
        );
    }
}

export default ProductFilterCard;
