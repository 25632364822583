import React from "react";

export const PolicyChangesContext = React.createContext();

const PolicyChangesProvider = ({ wizardContext, children }) => {
    return (
        <PolicyChangesContext.Provider value={wizardContext}>
            {children}
        </PolicyChangesContext.Provider>
    );
};

export default PolicyChangesProvider;
