import { defineMessages } from 'react-intl';

export default defineMessages({
    applicable:{
        id:'gateway.views.policy-clauses.Clauses applicable on',
        defaultMessage:'Clauses applicable on'
    },
    generalClauseTitle:{
        id:'gateway.views.policy-clauses.General clauses',
        defaultMessage:'General clauses'
    },
    noDataText:{
        id:'policy.ppv.directives.clauses-list.There are no clauses associated with this policy.',
        defaultMessage:'There are no clauses associated with this policy.'
    },
    clauseCode:{
        id:'policy.ppv.directives.clauses-list.Clause code',
        defaultMessage:'Clause code'
    },
    clauseType:{
        id:'policy.ppv.directives.clauses-list.Clause type',
        defaultMessage:'Clause type'
    },
    clauseTitle:{
        id:'policy.ppv.directives.clauses-list.Title',
        defaultMessage:'Title'
    }
});
