import { defineMessages } from 'react-intl';

export default defineMessages({
    premium: {
        id: 'gateway.directives.PolicySummary.Premium',
        defaultMessage: 'Premium'
    },
    openQuoteLabel: {
        id: 'gateway.directives.job-summary.Quote',
        defaultMessage: 'Quote'
    },
    openQuotesSearchQuotesLabel: {
        id: 'gateway.directives.job-summary.Search quotes',
        defaultMessage: 'Search for tariffs'
    },
    openQuotesLastDaysLabel: {
        id: 'gateway.views.quote-detail.last30Days',
        defaultMessage: 'Issued in Last 30 Days'
    },
    openQuotesOpenNotBoundLabel: {
        id: 'gateway.views.quote-detail.openNotBound',
        defaultMessage: 'Open - Not Bound'
    },
    openQuotesOpenBoundLabel: {
        id: 'gateway.views.quote-detail.openBound',
        defaultMessage: 'Open - Bound'
    },
    openQuotesCreatedLabel: {
        id: 'gateway.directives.job-summary.Created',
        defaultMessage: 'Created'
    },
    openQuotesProductLabel: {
        id: 'gateway.directives.job-summary.Line',
        defaultMessage: 'Product'
    },
    openQuotesPremiumLabel: {
        id: 'gateway.directives.job-summary.Cost',
        defaultMessage: 'Premium'
    },
    openQuoteStatusLabel: {
        id: 'gateway.directives.job-summary.Status',
        defaultMessage: 'Status'
    },
    openQuotesOwnReference: {
        id: 'gateway.directives.job-summary.Own Reference',
        defaultMessage: 'Own Reference'
    },
    openQuotesMake: {
        id: 'gateway.directives.job-summary.Make',
        defaultMessage: 'Make'
    },
    openQuotesModel: {
        id: 'gateway.directives.job-summary.Model',
        defaultMessage: 'Model'
    },
    openQuotesLicensePlate: {
        id: 'gateway.directives.job-summary.License Plate',
        defaultMessage: 'License Plate'
    },
    openQuotesUWIssueApproved: {
        id: 'gateway.directives.job-summary.UW Issue Approved',
        defaultMessage: 'UW Issue Approved'
    },
    openQuotesUWIssueApprovedUnderConditions: {
        id: 'gateway.directives.job-summary.UW Issue Approved Under Conditions',
        defaultMessage: 'UW Issue Approved Under Conditions'
    },
    openQuotesUWIssuePending: {
        id: 'gateway.directives.job-summary.UW Issue Pending',
        defaultMessage: 'UW Issue Pending'
    },
    openQuotesUWIssueRejected: {
        id: 'gateway.directives.job-summary.UW Issue Rejected',
        defaultMessage: 'UW Issue Rejected'
    },
    openQuotesUWIssueStatus: {
        id: 'gateway.directives.job-summary.UW Issue Status',
        defaultMessage: 'UW Issue Status'
    },
    noQuotesLabel: {
        id: 'gateway.directives.job-summary.There are no quotes meeting these filters',
        defaultMessage: 'There are no quotes meeting these filters'
    },
    noQuoteForAccount: {
        id: 'gateway.directives.job-summary.There are no open quotes for this account.',
        defaultMessage: 'There are no open quotes for this account.'
    },
});
