import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useModal } from '@jutro/components';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { messages as pvPlatformMessages} from 'pv-platform-translations';
import { useTranslator } from '@jutro/locale';


const useWizardExit = ({
    hasErrorOccurred,
    history
}) => {
    const { showConfirm } = useModal();
    const [ confirmedNextPage, setConfirmedNextPage ] = useState();
    const translator = useTranslator();

    const handleCancel = useCallback(({ wizardSnapshot, param: nextPath = '' }) => {
        const skipModal = ['knockoutpage', 'success', 'logout'];
        const lastPathName = nextPath.replace(/\/?.*\//g, '');
        const quoteSummaryRegex = /^\/quotes\/[0-9]+\/summary/;
        const periodStatus = _.get(wizardSnapshot, 'baseData.periodStatus.value.code');

        if (periodStatus === 'Bound'
            || nextPath === confirmedNextPage
            || skipModal.includes(lastPathName)
            || nextPath.match(quoteSummaryRegex)
            || hasErrorOccurred) {
            // allow transition once the submission is bound
            // or returning to quote summary page
            return true;
        }

        return showConfirm({
            title: platformMessages.wantToCancel,
            message: translator(pvPlatformMessages.infoWillBeSavedAsDraft),
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: platformMessages.yes,
            cancelButtonText: platformMessages.no
        }).then((results) => {
            if (results === 'cancel' || results === 'close') {
                setConfirmedNextPage(null);
                return false;
            }
            setConfirmedNextPage(nextPath);
            const quoteType = window.sessionStorage.getItem('createNewQuoteType_PV19883');
            if (quoteType) {
                window.sessionStorage.removeItem('createNewQuoteType_PV19883');
                history.push(nextPath, {quoteType});
            } else {
                history.push(nextPath);
            }
            return true;
        });
    }, [confirmedNextPage, hasErrorOccurred, showConfirm, translator, history]);
    return handleCancel;
};

export default useWizardExit;
