import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useTranslator } from '@jutro/locale';
import { EdgeErrorParser } from 'pv-portals-util-js';
import { useModal } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { messages as commonQuoteMessages } from 'gw-capability-policychange-common-react';

export default ({
    history,
    location,
    viewModelService,
    setIsLoading,
    wizardConfig,
    dtoPath = 'edge.capabilities.policychange.dto.PolicyChangeDataDTO',
    vmContext = {},
    EndorsementService
}) => {
    const { authHeader } = useAuthentication();
    const [policyChangeVM, setInitialPolicyChange] = useState(null);
    const [wizardSteps, setWizardSteps] = useState(null);
    const [wizardTitle, setWizardTitle] = useState(null);
    const { showConfirm } = useModal();
    const translator = useTranslator();

    useEffect(() => {
        if (!location.state) {
            history.push('/');
            return;
        }
        const { state: { policyNumber, jobNumber } } = location;
        if (!policyNumber) {
            return;
        }
        const errorModalBox = (errorMessage) => {
            showConfirm(errorMessage).then((results) => {
                if (results === 'cancel') {
                    return _.noop();
                }
                setIsLoading(false);
                return history.push(`/contactAgent/${policyNumber}`);
            }, _.noop);
        };

        // NOTE: do not use EndorsementService.loadEndorsementWithEffectiveDate. it will crash if effDate is in the past e.g. for older policychange jobs
        const resultPromise = EndorsementService.loadEndorsement(policyNumber, authHeader);

        // NOTE: section for appConfig.persona === 'policyholder' is removed, not needed for PE

        resultPromise
            .then((responseData) => {
                const vm = viewModelService.create(
                    responseData,
                    'pc',
                    dtoPath,
                    vmContext
                );
                setInitialPolicyChange(vm);
                setIsLoading(false);
            })
            .catch((error) => {
                const msg = EdgeErrorParser.getErrorMessage(error);
                errorModalBox({
                    title: commonQuoteMessages.unableToStartPolicy,
                    message: `${translator(commonQuoteMessages.anErrorOccurred)}\n${msg}`,
                    status: 'warning',
                    icon: 'mi-error-outline'
                });
            });

        setWizardTitle(_.get(wizardConfig, 'title'));
        const commonStepsProps = _.get(wizardConfig, 'commonStepsProps');
        let steps = _.get(wizardConfig, 'steps');
        if (!_.isUndefined(commonStepsProps)) {
            steps = steps.map((step) => {
                step.stepProps = {
                    ...step.stepProps,
                    ...commonStepsProps,
                    isContinueExistingJob: !_.isEmpty(jobNumber)
                };
                return step;
            });
        }
        setWizardSteps(steps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        policyChangeVM,
        wizardSteps,
        wizardTitle
    };
};
