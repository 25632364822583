import React from 'react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import messages from './CollectionMethodChangePopup.messages.js';
import metadata from './CollectionMethodChangePopup.metadata.json5';

import { Button } from '@jutro/legacy/components';

function CollectionMethodChangePopup(props) {
    const {
        invoicePayment,
        invoicePayment: {
            eventType,
            invoiceNumber,
            invoiceAmount,
            isPaymentDone,
            accountNo
        },
        onHandleCancel,
        onHandleConfirm
    } = props;
    const translator = useTranslator();

    const title = translator(messages.confirmTransactionLabel);
    const cancelMsg = translator(messages.cancelLabel);
    const confirmMsg = translator(messages.confirmLabel);

    const overrides = {
        eventType: {
            value: eventType === 'DRQ_PV' ? translator(messages.producerBillLabel) : translator(messages.directBillLabel)
        },
        invoiceNumber: {
            value: invoiceNumber && translator(messages.invoiceNumberLabel, { invoiceNumber: invoiceNumber })
        },
        invoiceAmount: {
            value: invoiceAmount && translator(messages.invoiceAmountLabel, { amount: invoiceAmount.amount, currency: "€" })
        },
        isPaymentDone: {
            visible: eventType === 'DRQ_PV',
            value: isPaymentDone ? translator(messages.yesLabel) : translator(messages.noLabel)
        },
        plannedInvoice: {
            visible: eventType === 'BRB_PV',
            value: eventType === 'BRB_PV' ? translator(messages.directBillLabel) : translator(messages.producerBillLabel)
        }
    };

    return (
        <ModalNext isOpen>
            <ModalHeader title={title} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    overrideProps={overrides}
                    model={invoicePayment}
                />
            </ModalBody>
            <ModalFooter>
                <Button type="outlined" onClick={onHandleCancel} >
                    {cancelMsg}
                </Button>
                <Button onClick={() => onHandleConfirm(accountNo, invoiceNumber, eventType, invoiceAmount, isPaymentDone)}>
                    {confirmMsg}
                </Button>
            </ModalFooter>
        </ModalNext>
    );

}

export default CollectionMethodChangePopup;
