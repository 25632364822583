import { defineMessages } from 'react-intl';

export default defineMessages({
    calculatePremium: {
        id: 'quoteandbind.quickquote.common.views.quick-quote-pack.Calculate Premium',
        defaultMessage: 'Calculate Premium'
    },
    recalculatePremium: {
        id: 'quoteandbind.quickquote.common.views.quick-quote-pack.Recalculate Premium',
        defaultMessage: 'Recalculate Premium'
    },
    totalPremium: {
        id: 'quoteandbind.common.views.qq-policy-info.Total Annual premium',
        defaultMessage: 'Total annual premium {premium}'
    },
    selectedPlanPremium: {
        id: 'quoteandbind.common.views.qq-policy-info.Selected plan premium',
        defaultMessage: 'Total annual premium {premium} / {plan}'
    },
    commercialPremium: {
        id: 'endorsement.common.views.history.Commercial Premium',
        defaultMessage: 'Commercial Premium'
    },
    totalSpreadCost: {
        id: 'endorsement.common.views.history.Total Spread Cost',
        defaultMessage: 'Total Spread Cost'
    },
    taxes: {
        id: 'endorsement.common.views.history.Taxes & Surcharges',
        defaultMessage: 'Taxes & Surcharges'
    },
    UWErrorMsg: {
        id: 'endorsement.common.views.Underwriting issues are triggered for this submission. Binding the policy will require underwriting approval',
        defaultMessage: 'Underwriting issues are triggered for this submission. Binding the contract will require underwriting approval.'
    },
    selectedPaymentPlan: {
        id: 'quoteandbind.ppv.directives.templates.ppv-payment-frequency-method.Payment plan',
        defaultMessage: '{premium} / Payment plan ({plan})'
    },
    toViewAnnualPremium: {
        id: 'endorsement.common.views.history.ToViewAnnualPremium',
        defaultMessage: 'Please open the documents to see the Annual Premium'
    }
});
