import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentChangeBilledInvoice: {
        id: 'endorsement.common.config.Tick this box',
        defaultMessage: 'Tick this box if you want the payment plan change to be applied to the last producer billed invoice'
    },
    paymentChangeBilledInvoiceTooltip: {
        id: 'endorsement.common.config.Tick this box tooltip',
        defaultMessage:
            'The change of payment plan is being applied only to unpaid direct bill invoices and to every future-dated bills. This choice also enables the conversion of the last producer bill invoiced that would still be unpaid on your end. Only an invoice for which the BRB delay has not been overdue can be taken into account.'
    }
});
