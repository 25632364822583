import { defineMessages } from 'react-intl';

export default defineMessages({
    downloadTariff: {
        id: "quoteandbind.common.views.qq-policy-info.Download tariff",
        defaultMessage: "Please click here to download the tariff"
    },
    chosenDeductible: {
        id: "quoteandbind.common.views.qq-policy-info.Chosen deductible",
        defaultMessage: "Chosen deductible: "
    },
    alternativeDeductible: {
        id: "quoteandbind.common.views.qq-policy-info.Alternative deductible",
        defaultMessage: "Alternative deductible: "
    },
    deductible: {
        id: 'quoteandbind.common.views.qq-policy-info.Deductible',
        defaultMessage: 'Deductible: '
    }
});
