import { defineMessages } from 'react-intl';

export default defineMessages({
    shouldCancelCurrentInsurer: {
        "id": "quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Would you like help to cancel the  current insurance policy?",
        "defaultMessage": "Would you like help to renunciate the current insurance contract?"
    },
    currentInsurerName: {
        "id": "quoteandbind.ppv.directives.templates.ppv-edit-vehicle.current insurer",
        "defaultMessage": "Current insurer"
    },
    currentContractNombre: {
        "id": "quoteandbind.ppv.directives.templates.ppv-edit-vehicle.policy number",
        "defaultMessage": "Contract Number"
    },
    currentContractCancellationDate: {
        "id": "quoteandbind.ppv.directives.templates.ppv-edit-vehicle.cancellation date",
        "defaultMessage": "Cancellation date"
    }
});
