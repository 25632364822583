import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';

import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { BusinessConstant } from 'pv-portals-util-js';

import metadata from './PaymentBankAccountComponent.metadata.json5';

const PaymentBankAccountComponent = (props) => {
    const {
        id,
        value: jobVM,
        onValidate,
        onValueChange,
        showRequired,
        showOptional,
        labelPosition,
        phoneWide,
        disabled,
        showErrors,
        isPaymentSEPA
    } = props;

    const translator = useTranslator();
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    const isJobSubmission = _.get(jobVM, 'baseData.jobType.value.code') === BusinessConstant.JOB_TYPE_SUBMISSION;
    const paymentDetailsPath = `bindData${isJobSubmission ? '' : '_PV'}.paymentDetails`;
    const bankAccountDataPath = `${paymentDetailsPath}.bankAccountData`;

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(() => {
        if (_.isEmpty(_.get(jobVM, `${paymentDetailsPath}.value`))) {
            onValueChange(_.get(jobVM, `${paymentDetailsPath}.value`), {});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const bankAccountNumber = _.get(jobVM, `${bankAccountDataPath}.bankAccountNumber.value`);
        if (isPaymentSEPA) {
            if (_.isEmpty(_.get(jobVM, `${bankAccountDataPath}.value`))) {
                _.set(jobVM, `${bankAccountDataPath}.value`, {});
            }

            if (_.isEmpty(_.get(jobVM, `${bankAccountDataPath}.sendingChannel_PV.value`))) {
                _.set(jobVM, `${bankAccountDataPath}.sendingChannel_PV.value`, BusinessConstant.SENDING_CHANNEL_MANDATE_FORM);
            }

            _.set(
                jobVM,
                `${bankAccountDataPath}.belgianBank_PV.value`,
                Boolean(_.isEmpty(bankAccountNumber) || _.startsWith(bankAccountNumber, 'BE'))
            );
        } else if (_.isEmpty(bankAccountNumber)) {
            _.set(jobVM, `${bankAccountDataPath}.value`, null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPaymentSEPA]);

    const onIBANChange = useCallback(
        (value, path) => {
            _.set(jobVM, path, value);
            _.set(jobVM, `${bankAccountDataPath}.sepaValid_PV.value`, false);
            const IBAN = _.replace(_.toUpper(_.trim(value)), /\s/g, '');
            if (IBAN) {
                if (
                    _.get(jobVM, `${bankAccountDataPath}.belgianBank_PV.value`) ||
                    (IBAN.length >= 2 && _.includes(_.get(jobVM, `${paymentDetailsPath}.sepaCountryList_PV.value`), IBAN.substring(0, 2)))
                ) {
                    _.set(jobVM, `${bankAccountDataPath}.sepaValid_PV.value`, true);
                }
            }
            _.set(jobVM, path, IBAN);
            onValueChange(IBAN, path);
        },
        [jobVM, bankAccountDataPath, onValueChange, paymentDetailsPath]
    );

    const onBICChange = useCallback(
        (value, path) => {
            const BIC = _.replace(_.toUpper(_.trim(value)), /\s/g, '');
            _.set(jobVM, path, BIC);
            onValueChange(BIC, path);
        },
        [jobVM, onValueChange]
    );

    const overrideProps = {
        '@field': {
            showRequired,
            showOptional,
            labelPosition,
            phoneWide,
            disabled,
            showErrors
        },
        paymentMethod: {
            visible: isJobSubmission,
            value: !_.isUndefined(_.get(jobVM, `${paymentDetailsPath}.paymentMethod_PV.value.name`))
                ? translator({
                    id: _.get(jobVM, `${paymentDetailsPath}.paymentMethod_PV.value.name`)
                })
                : '-'
        },
        paymentSEPADescription: {
            visible: isPaymentSEPA
        },
        paymentFreeReference: {
            showOptional: true,
            visible: isPaymentSEPA,
            path: `${bankAccountDataPath}.freeReference_PV`
        },
        paymentIsBelgianBank: {
            visible: isPaymentSEPA,
            path: `${bankAccountDataPath}.belgianBank_PV`
        },
        paymentIBAN: {
            visible: isPaymentSEPA,
            path: `${bankAccountDataPath}.bankAccountNumber`
        },
        paymentBIC: {
            visible: isPaymentSEPA && !_.get(jobVM, `${bankAccountDataPath}.belgianBank_PV.value`),
            path: `${bankAccountDataPath}.bankABANumber`
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onBICChange,
            onIBANChange
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={jobVM}
            overrideProps={overrideProps}
            onValueChange={onValueChange}
            onValidationChange={setComponentValidation}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
};

PaymentBankAccountComponent.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    showRequired: PropTypes.bool.isRequired,
    showOptional: PropTypes.bool.isRequired,
    labelPosition: PropTypes.string.isRequired,
    phoneWide: PropTypes.shape({}).isRequired,
    disabled: PropTypes.bool,
    showErrors: PropTypes.bool.isRequired,
    isPaymentSEPA: PropTypes.bool.isRequired
};

export default PaymentBankAccountComponent;
