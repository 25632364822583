export default class VehicleImageLookupService {
    // this service is intended to return a vehicle picture given year, make, and model
    // this implementation is hard coded to two vehicles for demo purpose only
    // consult documentation for a sample implementation using third party photo lookup
    static lookupPhoto(year, make, model) {
        const mo = model.toUpperCase();
        const mk = make.toUpperCase();

        let imageURL = '';

        if (mk === 'TOYOTA' && mo === 'AVENSIS' && year) {
            imageURL = 'toyota_avensis.jpg';
        } else if (mk === 'AUDI' && mo === 'A4' && year) {
            imageURL = 'audi_a4.jpg';
        }
        return imageURL;
    }
}
