import React, { useEffect, useContext, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { BusinessConstant, VehicleUtil, CoverageUtil } from 'pv-portals-util-js';

import metadata from './CoverageFieldsComponent.metadata.json5';
import './CoverageFieldsComponent.messages';

function generateClaimsValues(model, path, translator) {
    const values = _.get(model, `${path}.aspects.availableValues`, []);
    const filteredValues = _.sortBy(values.map((key) => {
        return {
            name: translator({
                id: key.name,
                defaultMessage: key.name
            }),
            code: key.code
        };
    }));
    return filteredValues;
}

function CoverageFieldsComponent(props) {
    const {
        vehicleVM,
        vehiclePath,
        driverVM,
        driverPath,
        onValidate,
        onValueChange,
        updateAndSync,
        showRequired,
        labelPosition,
        phoneWide,
        disabled,
        showErrors,
        clause: coverage,
        coverages,
        quickQuoteMode,
        vpVersionChecker
    } = props;

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(coverage.publicID);
    const [lastCurrenyValues, updateLastCurrenyValues] = useState({
        catalogValue_PV: _.get(vehicleVM, 'catalogValue_PV.amount.value', null),
        optionsValue: _.get(vehicleVM, 'optionsValue.amount.value', null),
        accessoriesValue: _.get(vehicleVM, 'accessoriesValue.amount.value', null),
        invoiceValue: _.get(vehicleVM, 'invoiceValue.amount.value', null),
        expertValue_PV: _.get(vehicleVM, 'expertValue_PV.amount.value', null),
        carDealerValue_PV: _.get(vehicleVM, 'carDealerValue_PV.amount.value', null)
    });

    const categoryName = _.get(vehicleVM, 'category.value.code');
    const {
        isTourismAndBusiness,
    } = VehicleUtil;

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, `${coverage.publicID }_CFC`);
        }
    }, [coverage.publicID, onValidate, isComponentValid]);

    const handleValueChange = useCallback((value, path) => {
        onValueChange(value, `${vehiclePath}.${path}`);
    }, [onValueChange, vehiclePath]);

    const onDriverFieldChange = useCallback(({ path }, value) => {
        if (path === 'claimsMD5YearsFault_PV' || _.get(vehicleVM, 'designatedDriver_PV.value')) {
            const newSubmissionVM = onValueChange(value, `${driverPath}.${path}`);
            updateAndSync(coverage.publicID, newSubmissionVM);
        } else {
            onValueChange(value, `${vehiclePath}.${path}`);
        }
    }, [coverage.publicID, driverPath, vehiclePath, onValueChange, updateAndSync, vehicleVM]);

    const onChangeCurrencyField = useCallback((value, path) => {
        const fieldName = path.split('.')[0];
        const lastValue = lastCurrenyValues[fieldName];
        if (lastValue !== value) {
            _.set(vehicleVM, path, value);
            onValueChange(vehicleVM, vehiclePath);
            updateLastCurrenyValues({
                ...lastCurrenyValues,
                [fieldName]: value,
                [`${fieldName}Changed`]: true
            });
        } else {
            updateLastCurrenyValues({
                ...lastCurrenyValues,
                [fieldName]: value,
                [`${fieldName}Changed`]: false
            });
        }
    }, [lastCurrenyValues, onValueChange, vehiclePath, vehicleVM]);

    const onBlurCurrencyField = useCallback((evt, { model: path }) => {
        const fieldName = path.split('.')[0];
        const lastValueChanged = lastCurrenyValues[`${fieldName}Changed`];
        if (_.get(vehicleVM, 'aspects.subtreeValid') && lastValueChanged) {
            updateAndSync(coverage.publicID);
        }
    }, [coverage.publicID, lastCurrenyValues, updateAndSync, vehicleVM]);

    const availableAntiTheftValues = useMemo(() => {
        if (quickQuoteMode) {
            return [];
        }

        const typelist = viewModelService.productMetadata.get('pc').types.getTypelist(BusinessConstant.ANTI_THEFT_SYSTEM_TYPELIST);
        let codes = typelist.codes;
        if (isTourismAndBusiness(categoryName)) {
            const filteredList = typelist.getFilter(BusinessConstant.FILTERED_LIST_FOR_TNB);
            codes = filteredList.codes;
        }

        return codes.map((code) => ({
            code: code.code,
            name: {
                id: code.name,
                defaultValue: code.code
            }
        }));
    }, [categoryName, isTourismAndBusiness, quickQuoteMode, viewModelService.productMetadata]);

    const overrideProps = {
        '@field': {
            showRequired,
            labelPosition,
            phoneWide,
            disabled,
            showErrors
        },
        claimsMD5YearsFault_PV: {
            value: _.get(driverVM, 'claimsMD5YearsFault_PV.value.code'),
            availableValues: generateClaimsValues(driverVM, 'claimsMD5YearsFault_PV', translator)
        },
        claimsMD5YearsFaultCL_PV: {
            value:  _.get(vehicleVM, 'designatedDriver_PV.value') ?  _.get(driverVM, 'claimsMD5YearsFaultCL_PV.value.code') : _.get(vehicleVM, 'claimsMD5YearsFaultCL_PV.value.code'),
            availableValues: _.get(vehicleVM, 'designatedDriver_PV.value') ? generateClaimsValues(driverVM, 'claimsMD5YearsFaultCL_PV', translator) : generateClaimsValues(vehicleVM, 'claimsMD5YearsFaultCL_PV', translator)
        },
        antiTheftCode: {
            availableValues: availableAntiTheftValues
        }
    };

    _.merge(overrideProps, CoverageUtil.visibleFields({ vehicleVM, coverage, coverages, quickQuoteMode, vpVersionChecker }));

    const resolvers = {
        resolveCallbackMap: {
            onDriverFieldChange,
            onChangeCurrencyField,
            onBlurCurrencyField
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

CoverageFieldsComponent.propTypes = {
    vehicleVM: PropTypes.shape({}).isRequired,
    vehiclePath: PropTypes.string.isRequired,
    driverVM: PropTypes.shape({}),
    driverPath: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    updateAndSync: PropTypes.func.isRequired,
    showRequired: PropTypes.bool.isRequired,
    labelPosition: PropTypes.string.isRequired,
    phoneWide: PropTypes.shape({}).isRequired,
    showErrors: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    clause: PropTypes.shape({}).isRequired,
    coverages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    quickQuoteMode: PropTypes.bool.isRequired
};

CoverageFieldsComponent.defaultProps = {
    driverVM: undefined
};

export default CoverageFieldsComponent;
