import { defineMessages } from 'react-intl';

export default defineMessages({
    tariffRequest: {
        id: 'gw-portals-policytransaction-common-angular.baseTransaction.baseTransactionWizard.Tariff Request',
        defaultMessage: 'Tariff'
    },
    newBusiness: {
        id: 'gw-portals-policytransaction-common-angular.baseTransaction.baseTransactionWizard.New Business Request',
        defaultMessage: 'New business'
    },
    vehicles: {
        id: 'quoteandbind.wizard.step.Vehicles',
        defaultMessage: 'Vehicles'
    },
    drivers: {
        id: 'quoteandbind.wizard.step.Drivers',
        defaultMessage: 'Drivers'
    },
    summary: {
        id: 'quoteandbind.quickquote.wizard.step.Summary',
        defaultMessage: 'Summary'
    },
    contract: {
        id: 'quoteandbind.pcv.views.pcv-contract-details.Contract',
        defaultMessage: 'Contract'
    },
    additionalInfo: {
        id: 'quoteandbind.wizard.step.Additional Info',
        defaultMessage: 'Additional Information'
    },
    policyInfo: {
        id: 'quoteandbind.wizard.step.Policy Info',
        defaultMessage: 'Contract Information'
    },
    esignature: {
        id: 'quoteandbind.wizard.step.ESignature',
        defaultMessage: 'Signature and payment'
    },
    confirmation: {
        id: 'quoteandbind.wizard.step.Confirmation',
        defaultMessage: 'Confirmation'
    }
});
