import _ from 'lodash';

/* eslint-disable camelcase */
const today_PV = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate()
    };
};

const addYears_PV = (localDate, years) => {
    return new Date(localDate.year + years, localDate.month, localDate.day);
};

const addYearsMonthsDays_PV = (date, years, months, days) => {
    if (_.isNil(date)) {
        return null;
    }

    return new Date(date.getFullYear() + years, date.getMonth() + months, date.getDate() + days);
};

const validateLocalDate_PV = (date) => {
    switch (typeof date) {
        case 'object':
            // If we have a local date object that means the datetimepicker directive validated the filled date
            return date !== null && date.hasOwnProperty('year') && date.hasOwnProperty('month') && date.hasOwnProperty('day');
        default:
            return false;
    }
};

const dateFormat_PV = (intl, date) => {
    return intl.formatDate(new Date(date), { year: 'numeric', month: 'short', day: 'numeric' });
};

export default {
    addYears_PV,
    addYearsMonthsDays_PV,
    today_PV,
    validateLocalDate_PV,
    dateFormat_PV
};
