import { defineMessages } from 'react-intl';

export default defineMessages({
    policyChangeEffectiveDate: {
        id: "endorsement.directives.templates.effective-date.Set Date of Change",
        defaultMessage: "Effective date policy change"
    },
    policyChangeEffectiveDateTooltip: {
        id: "gateway.endorsment.view.endorsement-error.tooltip.Policy change can be done up to 7 days before the current date",
        defaultMessage: "Contract change can be done up to 7 days before the current date. Moreover, you can’t start a new contract change at an earlier date than another pending contract change"
    },
    vehicles: {
        id: "endorsement.ppv.directives.templates.ppv-whats-next.Vehicles",
        defaultMessage: "Vehicles"
    },
    drivers: {
        id: "endorsement.ppv.directives.templates.ppv-whats-next.Drivers",
        defaultMessage: "Drivers"
    },
    payments: {
        id: "endorsement.ppv.directives.templates.ppv-whats-next.Payments",
        defaultMessage: "Payment Information"
    },
    coverages: {
        id: "endorsement.pcv.directives.templates.pcv-whats-next.Coverages",
        defaultMessage: "Coverages"
    },
    coveragesTooltip: {
        id: "gateway.endorsment.view.endorsement-error.tooltip.Some coverage changes will not be available for a change during term",
        defaultMessage: "Some coverage changes will not be available for a change during term"
    },
    vinLicenseplate: {
        id: "endorsement.pcv.directives.templates.pcv-whats-next.vin-and-licenseplate",
        defaultMessage: "VIN, License plate"
    },
});
