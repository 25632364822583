import { defineMessages } from 'react-intl';

export default defineMessages({
    dateFirstLicenced: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Date first licenced',
        defaultMessage: 'Date first licenced A'
    },
    dateFirstLicencedB: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Date first licenced B',
        defaultMessage: 'Date first licenced B'
    },
    mainDriver: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Main driver',
        defaultMessage: 'Main driver'
    },
    designatedVehiclesColumn: {
        id: 'quoteandbind.pcv.views.pcv-policy-info.Designated vehicles',
        defaultMessage: 'Designated vehicles'
    },
    mainDriverColumn: {
        id: 'quoteandbind.pcv.views.pcv-policy-info.Main driver?',
        defaultMessage: 'Main driver?'
    },
    additionalDriverColumn: {
        id: 'quoteandbind.pcv.views.pcv-policy-info.Additional driver',
        defaultMessage: 'Additional driver'
    },
    occasionalDriver: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Occasional driver',
        defaultMessage: 'Occasional driver'
    },
    thirdPartyLiability: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Number of claims for the Third Party Liability in the past 5 years',
        defaultMessage: 'Number of claims for the Third Party Liability in the past 5 years'
    },
    theftAttempt: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Number of claims for theft or theft attempt in the past 5 years',
        defaultMessage: 'Number of claims for theft or theft attempt in the past 5 years'
    }
});
