import { defineMessages } from 'react-intl';

export default defineMessages({
    genericWizardTitle: {
        id: "portal.components.transactionWizardTitle.transactionWizardTitle.Policy Change",
        defaultMessage: "Contract modification"
    },
    genericSelectChangesTitle: {
        id: "endorsement.common.view.Select Changes",
        defaultMessage: "Select Changes"
    },
    endorsementPaymentValidationErrorTitle: {
        id: "endorsement.common.config.validation.popup.title",
        defaultMessage: "Attention"
    },
    endorsementPaymentValidationError: {
        id: "endorsement.common.config.validation.popup.Error Message",
        defaultMessage: "A change of the payment plan can not be issued because all the payments are fully received. This modification can only be done at the renewal date."
    },
});
