import { defineMessages } from 'react-intl';

export default defineMessages({
    vehicleRejectionContainerTitlePPV: {
        "id": "quoteandbind.ppv.directives.templates.ppv-informex-vehicle-Rejection-popup.message",
        "defaultMessage": "the MAM of the vehicle is > 3500 kg, which is not possible for T&B"
    },
    vehicleRejectionContainerTitlePCV: {
        "id": "quoteandbind.pcv.directives.templates.pcv-informex-vehicle-Rejection-popup.message",
        "defaultMessage": "the MAM of the vehicle is > 3500 kg. Please remove the coverable T&B and select transport > 3,5T"
    }
});
