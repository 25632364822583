import { defineMessages } from 'react-intl';

export default defineMessages({
    errorTitle: {
        id: 'policy-transaction.Quote Flow Error',
        defaultMessage: 'Quote Flow Error'
    },
    errorMessage: {
        id: 'policy-transaction.Sorry, something went wrong with your quote',
        defaultMessage: 'Sorry, something went wrong with your quote'
    },
    failToLoadExperience: {
        id: 'policy-transaction.Failed to load experience',
        defaultMessage: 'Failed to load experience'
    }
});
