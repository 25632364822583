import { defineMessages } from 'react-intl';

export default defineMessages({
    wasRiskRefused: {
        id: "quoteandbind.templates.risk-refused-by-another-insurer.was risk refused?",
        defaultMessage: "Was the client / risk refused or renounced by another company or subjected to a sanitization rule?"
    },
    wasRiskRefusedReason: {
        id: "quoteandbind.templates.risk-refused-by-another-insurer.reason",
        defaultMessage: "Reason"
    },
    wasRiskRefusedCompany: {
        id: "quoteandbind.templates.risk-refused-by-another-insurer.insurer",
        defaultMessage: "Company"
    },
    wasRiskRefusedPolicyNumber: {
        id: "quoteandbind.templates.risk-refused-by-another-insurer.policy number",
        defaultMessage: "Contract Number"
    }
});
