import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { BusinessConstant as BC } from 'pv-portals-util-js';
import { useTranslator } from '@jutro/locale';
import metadata from './PolicyInfo.metadata.json5';
import styles from "./PolicyInfo.module.scss";
import './PolicyInfo.messages.js';

function PolicyInfo(props) {
    const {
        submissionVM,
        isPersonalVehicle
    } = props;
    const translator = useTranslator();
    const lobName = isPersonalVehicle ? BC.PPV_LOB_CODE : BC.PCV_LOB_CODE;

    const getAddress = useMemo(() => {
        return `${submissionVM.baseData.accountHolder.primaryAddress.value.displayName} ${submissionVM.baseData.accountHolder.primaryAddress.value.postalCode}`;
    }, [submissionVM.baseData.accountHolder.primaryAddress.value]);

    const getPrimaryPhoneNumber = useMemo(() => {
        let primaryPhoneValue = '';
        const accountHolder = submissionVM.baseData.accountHolder.value;
        switch (accountHolder.primaryPhoneType) {
            case BC.PRIMARYPHONETYPE_WORK: {
                primaryPhoneValue = accountHolder.workNumber;
                break;
            }
            case BC.PRIMARYPHONETYPE_MOBILE: {
                primaryPhoneValue = accountHolder.cellNumber;
                break;
            }
            default: {
                primaryPhoneValue = accountHolder.homeNumber;
                break;
            }
        }

        if (primaryPhoneValue === undefined) {
            primaryPhoneValue = [accountHolder.homeNumber, accountHolder.workNumber, accountHolder.cellNumber].find((number) => !!number);
        }
        return primaryPhoneValue;
    }, [submissionVM.baseData.accountHolder.value]);

    const paymentPlanDescription = useMemo(() => {
        const offeringBranchIdx = submissionVM?.value.lobData[lobName].offerings.findIndex((offering) => offering.branchCode === BC.BRANCH_CODE_CUSTOM);
        const paymentPlanPath = `lobData.${lobName}.offerings[${offeringBranchIdx !== -1 ? offeringBranchIdx : 0}].paymentFrequency`;
        let selectedPaymentPlan; let paymentPlanObject;
        if (_.get(submissionVM, 'baseData.quoteType.value.code') === 'Quick') {
            selectedPaymentPlan = _.get(submissionVM.value, paymentPlanPath);
            return translator({
                id: `typekey.BillingPeriodicity.${selectedPaymentPlan}`,
                defaultMessage: selectedPaymentPlan
            });
        }
        selectedPaymentPlan = _.get(submissionVM, 'bindData.selectedPaymentPlan.value');
        const paymentPlansArray = _.get(submissionVM, 'bindData.paymentPlans.value', []);
        if (paymentPlansArray.length > 0) {
            paymentPlanObject = paymentPlansArray.find((paymentPlan) => {
                return paymentPlan.billingId === selectedPaymentPlan;
            });
        }
        return paymentPlanObject ? paymentPlanObject.name : '-';
    }, [lobName, submissionVM, translator]);


    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        paymentPlan: {
            value: paymentPlanDescription
        },
        gender: {
            visible: isPersonalVehicle
        },
        dateOfBirth: {
            visible: isPersonalVehicle
        },
        legalForm: {
            visible: !isPersonalVehicle
        },
        enterpriseNumber: {
            visible: !isPersonalVehicle
        },
        specialTaxRegime: {
            visible: !isPersonalVehicle
        },
        phoneNumber: {
            visible: !!getPrimaryPhoneNumber,
            value: getPrimaryPhoneNumber
        },
        addressDetails: {
            value: getAddress
        },
        halfYearlyPremium: {
            visible: _.get(submissionVM, 'selectedPaymentPlan.billingId.value') === 'dh:halfyearpay1',
            value: _.get(submissionVM, 'quoteData.offeredQuotes.value').find((offering) => offering.branchCode === BC.BRANCH_CODE_CUSTOM).premium.amountAccordingSelectedPaymentPlan_PV
        },
        quarterlyPremium: {
            visible: _.get(submissionVM, 'selectedPaymentPlan.billingId.value') === 'dh:quartpay1',
            value: _.get(submissionVM, 'quoteData.offeredQuotes.value').find((offering) => offering.branchCode === BC.BRANCH_CODE_CUSTOM).premium.amountAccordingSelectedPaymentPlan_PV
        },
        monthlyPremium: {
            visible: _.get(submissionVM, 'selectedPaymentPlan.billingId.value') === 'dh:monthpay1',
            value: _.get(submissionVM, 'quoteData.offeredQuotes.value').find((offering) => offering.branchCode === BC.BRANCH_CODE_CUSTOM).premium.monthlyPremium
        },
        totalPremium: {
            value: _.get(submissionVM, 'quoteData.offeredQuotes.value').find((offering) => offering.branchCode === BC.BRANCH_CODE_CUSTOM).premium.total
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PolicyInfo.propTypes = {
    submissionVM: PropTypes.shape({}),
    isPersonalVehicle: PropTypes.bool
};

PolicyInfo.defaultProps = {
    submissionVM: {},
    isPersonalVehicle: true
};

export default PolicyInfo;
