import React, { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useModal } from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { IssuanceService } from 'pv-capability-issuance';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { messages as platformMessagesPV } from 'pv-platform-translations';
import metadata from './QuoteConfirmationPage.metadata.json5';
import styles from './QuoteConfirmationPage.module.scss';
import messages from './QuoteConfirmationPage.messages';

const webDivUrl = 'https://www.inmotiv.be/nl/voertuig-registratie/autoconnect-webdiv/';
const vmContext = {
    IsPackScreen: false,
    ContractQuestions: true,
    AdditionalQuestions: false,
    FinalConfirmation: true,
    SummaryQuestions: false,
    isAgent: true
};

const goToWebDIV = () => window.open(webDivUrl);

function QuoteConfirmationPage(props) {
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const history = useHistory();
    const { authHeader } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { EndorsementService } = useDependencies('EndorsementService');
    const [issuanceVM, updateIssuanceVM] = useState(null);
    const { wizardSnapshot, wizardData: submissionVM, updateWizardData } = props;
    const { onValidate, isComponentValid, initialValidation } = useValidation('QuoteConfirmationPage');
    const pageLOBType = submissionVM?.baseData?.productCode?.value === 'PersonalVehicle_PV' ? 'ppvPersonalAuto_PV' : 'pcvCommercialAuto_PV';
    const status = submissionVM?.value?.baseData?.periodStatus ?? submissionVM?.value?.status;
    const isBound = status === 'Bound';
    const vehiclesWithNoLP = useMemo(() => wizardSnapshot.lobData[pageLOBType].coverables.vehicles.value?.filter((vehicle) => _.isEmpty(vehicle.license)), [pageLOBType, wizardSnapshot.lobData]);
    const isPolicyChange = submissionVM?.value?.baseData?.jobType === 'PolicyChange';

    const { showAlert } = useModal();

    const handleError = useCallback(({ title, message, confirmationButtonText = platformMessages.ok }) => {
        showAlert({
            title: title,
            message: message,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: confirmationButtonText
        }).catch(_.noop);
    }, [showAlert]);

    const getOrCreateIssuanceForSubmissionVM = useCallback(async (subVM) => {
        const subVMStatus = subVM?.value?.baseData?.periodStatus ?? subVM?.value?.status;
        if (!subVM?.baseData?.isIssued_PV?.value && subVMStatus === 'Bound') {
            try {
                const issuance = await IssuanceService.getOrCreateIssuance(subVM?.sessionUUID?.value, subVM?.quoteID?.value, authHeader);
                const issuanceVm = viewModelService.create(issuance, 'pc', 'be.pvgroup.pc.edge.capabilities.issuance.dto.IssuanceDataDTO_PV', { isAgent: true });
                updateIssuanceVM(issuanceVm);
                return issuanceVm;
            } catch (e) {
                handleError({
                    title: platformMessagesPV.genericTechnicalErrorTitle,
                    message: platformMessagesPV.genericTechnicalErrorMsg
                });
            }
        }
        return null;
    }, [authHeader, handleError, viewModelService]);

    useEffect(() => {
        if (!_.get(submissionVM, '_context.ContractQuestions', false)) {
            const vm = viewModelService.changeContext(submissionVM, vmContext);
            updateWizardData(vm);
        }
        if (!isPolicyChange) {
            getOrCreateIssuanceForSubmissionVM(submissionVM);
        }
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            if (isBound) {
                const newIssuanceVM = viewModelService.clone(issuanceVM);
                _.set(newIssuanceVM, path, value);
                updateIssuanceVM(newIssuanceVM);
            } else {
                const newSubmissionVM = viewModelService.clone(submissionVM);
                _.set(newSubmissionVM, path, value);
                updateWizardData(newSubmissionVM);
            }
        },
        [submissionVM, updateWizardData, viewModelService, issuanceVM, isBound, updateIssuanceVM]
    );

    const copyLicensePlate = useCallback((fromSubmissionVM, toIssuanceVM) => {
        toIssuanceVM.issuanceLicensePlates.children.forEach((toVehicle) => {
            const fromVehicle = fromSubmissionVM.lobData[pageLOBType].coverables.vehicles.children.find((v) => v.fixedId.value === toVehicle.fixedId.value);
            if (fromVehicle) {
                toVehicle.license.value = fromVehicle.license.value;
            }
        });
    }, [pageLOBType]);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            return false;
        }

        try {
            if (!isPolicyChange) {
                const data = {
                    postalCode: submissionVM?.baseData?.policyAddress?.postalCode?.value,
                    quoteID: submissionVM?.quoteID?.value,
                    productCode: submissionVM?.baseData?.productCode?.value
                };
                const newSubmission = await LoadSaveService.retrieveSubmission(data, authHeader);
                const newSubmissionVM = viewModelService.create(newSubmission, 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO', vmContext);
                updateWizardData(newSubmissionVM);

                if (!newSubmissionVM?.baseData?.isIssued_PV?.value && !_.isEmpty(vehiclesWithNoLP)) {
                    if (newSubmissionVM?.baseData?.periodStatus?.value?.code === 'Bound') {
                        let newIssuanceVM = issuanceVM;
                        if (!issuanceVM) {
                            newIssuanceVM = await getOrCreateIssuanceForSubmissionVM(newSubmissionVM);
                            copyLicensePlate(submissionVM, newIssuanceVM);
                        }
                        await IssuanceService.quoteAndBindIssuance(newIssuanceVM.value, authHeader);
                    } else {
                        await LoadSaveService.updateDraftSubmission(submissionVM.value, authHeader);
                    }
                }
                history.push(`/quotes/${submissionVM.quoteID.value}/summary`);
            }
            else {
                if (!isBound) {
                    await EndorsementService.updateLicensePlateAndBind(
                        [submissionVM.value],
                        authHeader
                    );
                }
                history.push(`/change/${submissionVM.jobID.value}/summary`);
            }
            return submissionVM;
        } catch (e) {
            handleError({
                title: platformMessagesPV.genericTechnicalErrorTitle,
                message: platformMessagesPV.genericTechnicalErrorMsg
            });
            return false;
        }
    }, [EndorsementService, LoadSaveService, authHeader, copyLicensePlate, getOrCreateIssuanceForSubmissionVM, handleError, history, isBound, isComponentValid, isPolicyChange, issuanceVM, submissionVM, updateWizardData, vehiclesWithNoLP, viewModelService]);

    const generateLicensePlates = useCallback(() => {
        const vehicles = _.get(wizardSnapshot, `lobData.${pageLOBType}.coverables.vehicles.value`);

        const overrides = vehicles.map((vehicle, index) => {
            return {
                [`licensePlate${index}`]: {
                    label: `${translator(messages.licensePlate)} ${vehicle.displayName}`,
                    visible: _.isEmpty(vehicle.license)
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [pageLOBType, wizardSnapshot, translator]);

    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        contractNumberId: {
            value: !isPolicyChange ? _.get(submissionVM, 'bindData.policyNumber.value') : _.get(submissionVM, 'policyNumber.value'),
            visible: !isPolicyChange ? !!_.get(submissionVM, 'bindData.policyNumber.value') : _.get(submissionVM, 'policyNumber.value'),
        },
        pendingPolicyChangeInFuture: {
            visible: isPolicyChange && _.get(submissionVM, 'pendingPolicyChangeInFuture_PV.value') === true
        },
        successfullyIssued: {
            visible: !isPolicyChange ? (_.get(submissionVM, 'baseData.isIssued_PV.value') && (pageLOBType !== 'ppvPersonalAuto_PV' || !!_.get(submissionVM, 'bindData.policyNumber.value'))) : isBound,
            content: !isPolicyChange ? translator(messages.successfullyIssued) : translator(messages.successfullyChanged)
        },
        signaturePayment: {
            visible: !isPolicyChange ? (!_.get(submissionVM, 'baseData.isIssued_PV.value') && (pageLOBType !== 'ppvPersonalAuto_PV' || !_.get(submissionVM, 'bindData.policyNumber.value'))) : (vehiclesWithNoLP?.length > 0 && !isBound),
            content: !isPolicyChange ? translator(messages.signaturePayment) : translator(messages.noLicensePlate)
        },
        noSignature: {
            visible: isPolicyChange && (vehiclesWithNoLP?.length === 0 && !isBound),
            content: translator(messages.noSignature)
        },
        licensePlateContainer: {
            visible: !isPolicyChange ? (!_.get(submissionVM, 'baseData.isIssued_PV.value') && vehiclesWithNoLP?.length > 0) : (!isBound && vehiclesWithNoLP?.length > 0),
        },
        vehicleLicensePlates: {
            path: isBound ? `issuanceLicensePlates.children` : `lobData.${pageLOBType}.coverables.vehicles.children`
        },
        ...generateLicensePlates()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            goToWebDIV
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            showCancel={false}
            showPrevious={false}
            skipWhen={initialValidation}
            nextLabel={translator(platformMessagesPV.finish)}
            disableNext={!isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={isBound && !isPolicyChange ? issuanceVM : submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                className={styles.confirmationPageContent}
            />
        </WizardPage>
    );
}

QuoteConfirmationPage.propTypes = wizardProps;
export default QuoteConfirmationPage;
