import React, { useState, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { ServiceManager } from '@jutro/legacy/services';
import { InputField } from '@jutro/legacy/components';

// https://stackoverflow.com/questions/55364947/is-there-any-javascript-standard-api-to-parse-to-number-according-to-locale
class NumberParser {

    constructor(locale) {
        const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
        const numerals = [...new Intl.NumberFormat(locale, {useGrouping: false}).format(9876543210)].reverse();
        const index = new Map(numerals.map((d, i) => [d, i]));
        this._group = new RegExp(`[${parts.find((d) => d.type === "group").value}]`, "g");
        this._decimal = new RegExp(`[${parts.find((d) => d.type === "decimal").value}]`);
        this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
        this._index = (d) => index.get(d);
    }

    parse(string) {
        string = string.trim()
            .replace(/\s/g, '')
            .replace(this._group, "")
            .replace(this._decimal, ".")
            .replace(this._numeral, this._index);
        return string ? +string : NaN;
    }
}

/*
    Imitates CurrencyInput or NumberInput (isCurrency=true/false)
 */
function LocalizedCurrencyOrNumberInput(props) {

    const {
        value,
        onFocus = _.noop,
        onBlur = _.noop,
        onValueChange = _.noop,
        decimalPlaces = 2, // minimum & maximum decimals
        isCurrency, // is currency or just decimal number
        showGrouping = true,
        currencyDisplay = 'symbol',
        defaultCurrency,
    } = props;

    const localeService = ServiceManager.getService('locale-service');
    const locale = localeService.getStoredLocale() || localeService.getPreferredLocale();
    const currencyFromLocale = localeService.getDefaultCurrencyCode();
    const formatterEditing = useMemo(() => {
        return new Intl.NumberFormat(locale, {
            useGrouping: false, // editing mode supports grouping too, but maybe without grouping is easier?
            style: 'decimal',
            minimumFractionDigits: isCurrency ? decimalPlaces : 0,
            maximumFractionDigits: decimalPlaces,
            roundingMode: 'trunc'
        });
    }, [locale, decimalPlaces, isCurrency]);
    const formatterReading = useMemo(() => {
        return new Intl.NumberFormat(locale, {
            useGrouping: showGrouping ? 'auto': false,
            style: (isCurrency ? 'currency' : 'decimal'),
            currency: defaultCurrency || currencyFromLocale,
            minimumFractionDigits: isCurrency ? decimalPlaces : 0,
            maximumFractionDigits: decimalPlaces,
            roundingMode: 'trunc',
            currencyDisplay
        });
    }, [locale, decimalPlaces, isCurrency, showGrouping, currencyDisplay, currencyFromLocale, defaultCurrency]);
    const parser = useMemo(() => {
        return new NumberParser(locale);
    }, [locale]);

    const format = useCallback((input, formatter) => {
        if (input === undefined || _.isNaN(input)) {
            return input;
        }
        return formatter.format(input);
    }, []);

    const parseToNumber = useCallback((input) => {
        if (input === undefined) {
            return undefined;
        }
        const result = parser.parse(input);

        if (_.isNaN(result)) {
            return undefined;
        }
        // trim not needed decimals
        // eslint-disable-next-line no-restricted-properties
        const pow = Math.pow(10, decimalPlaces);
        return Math.floor(result * pow) / pow;
    }, [parser, decimalPlaces]);

    const convertToReading = useCallback((input) => {
        const result = parser.parse(input || '');
        return _.isNaN(result) ? input : format(result, formatterReading);
    }, [format, formatterReading, parser]);

    const [inFocus, setInFocus] = useState(false);
    const [valueForEditing, setValueForEditing] = useState(format(value, formatterEditing)); // raw value entered by keyboard
    const [valueForReading, setValueForReading] = useState(format(value, formatterReading)); // value transformed to out-of-focus reading
    const [valueInternal, setValueInternal] = useState(value); // value transformed to out-of-focus reading

    const handleBlur = useCallback((event, param) => {
        setInFocus(false);
        if (_.isNumber(valueInternal)) {
            // to align number and editing value again e.g. trim unneeded decimals
            const newValueForEditing = format(valueInternal, formatterEditing);
            setValueForEditing(newValueForEditing);
        }
        if (valueForEditing === '-') {
            setValueForEditing(undefined);
            setValueForReading(undefined);
        }
        onBlur(event, param);
    }, [onBlur, valueInternal, format, formatterEditing, valueForEditing]);

    const handleFocus = useCallback((event, param) => {
        setInFocus(true);
        onFocus(event, param);
    }, [onFocus]);

    if (value !== valueInternal) {
        setValueInternal(value);
        setValueForEditing(format(value, formatterEditing));
        setValueForReading(format(value, formatterReading));
    }

    const handleChange = useCallback((_value, _path, _param) => {
        if (_.isEmpty(_value)) {
            onValueChange(undefined, _path, _param);
            setValueInternal(undefined);
            setValueForEditing(undefined);
            setValueForReading(undefined);
            return;
        }
        const valueAsNumber = parseToNumber(_value);

        if (_.isEmpty(_value) || _value === '-' || !_.isEmpty(_value) && valueAsNumber != null) {

            // a) accept empty string
            // b) accept sign - (minus) as first char
            // c) accept non-empty string, which has numeric value

            const valueToReading = convertToReading(_value);

            setValueForEditing(_value);
            setValueForReading(valueToReading);
            setValueInternal(valueAsNumber);
            onValueChange(valueAsNumber, _path, _param);

            console.log(`edit=${_value} | reading=${valueToReading} | number=${valueAsNumber}`);
        } else {
            // ignore entered char, it doesn't make a number
            console.log(`edit=${_value} doesn't make a number, ignoring it`);
        }
    }, [convertToReading, onValueChange, parseToNumber]);

    const overridenProps = {
        ...props,
        value: (inFocus ? valueForEditing : valueForReading),
        onBlur: handleBlur,
        onFocus: handleFocus,
        onValueChange: handleChange,
        nullable: true
    };

    return (
        <InputField {...overridenProps}/>
    );
};

export default LocalizedCurrencyOrNumberInput;
