import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import { ServiceManager } from '@jutro/legacy/services';
import appConfig from 'app-config';

import _ from 'lodash';
import vehicleByLicensePlate from '../MockApi/licensePlate-TMA123.json';
import vehicleByVIN from '../MockApi/vin-TMAJ3817AKJ815924.json';
import businessSearchError from '../MockApi/vehicleNotFound.json';

import make from '../MockApi/make.json';

import audiModel from '../MockApi/audi-model.json';

import audiA4Engines from '../MockApi/audi-a4-enginePower.json';
import audiA3Engines from '../MockApi/audi-a3-enginePower.json';

import audiA366kw from '../MockApi/audi-a3-66kw-multiSearchResult.json';

import audiA466kwFuels from '../MockApi/audi-a4-66kw-fuelType.json';

import audiA466kwPetrol from '../MockApi/audi-a4-66kw-petrol1-singleSearchResult.json';
import audiA466kwDiesel from '../MockApi/audi-a4-66kw-diesel2-multiSearchResult.json';

import audiA2error from '../MockApi/audi-a2-tooManyResultsError.json';

import anyVehicleId from '../MockApi/searchByVehicleid.json';

const { gatewayParamConfig: { enableVehicleSearchMock } } = appConfig;

export default class PVVehicleLookupService {

    // search by LicensePlate
    static autofillBasedOnLicensePlate(plate, additionalHeaders = {}) {
        const localeService = ServiceManager.getService('locale-service');
        const lang = localeService.getStoredLanguage();

        if (!enableVehicleSearchMock) {
            return JsonRPCService.send(
                getProxiedServiceUrl('vehicleInfoLookup'),
                'lookupBasedOnRegistration',
                [plate, lang],
                additionalHeaders
            );
        }

        if (plate === 'TMA123') {
            return new Promise((resolve) => {
                setTimeout(() => resolve(vehicleByLicensePlate.result), 1000);
            });
            // return Promise.resolve(vehicleByLicensePlate.result);
        }

        if (plate === 'error') {
            return Promise.reject();
        }

        return Promise.resolve(businessSearchError.result);
    }

    // search by VIN
    static autofillBasedOnVIN(vin, additionalHeaders = {}) {
        const localeService = ServiceManager.getService('locale-service');
        const lang = localeService.getStoredLanguage();

        if (!enableVehicleSearchMock) {
            return JsonRPCService.send(
                getProxiedServiceUrl('vehicleInfoLookup'),
                'lookupBasedOnVIN',
                [vin, lang],
                additionalHeaders
            );
        }

        if (vin === 'TMAJ3817AKJ815924') {
            return new Promise((resolve) => {
                setTimeout(() => resolve(vehicleByVIN.result), 1000);
            });
            // return Promise.resolve(vehicleByVIN.result);
        }

        if (vin === 'error') {
            return Promise.reject();
        }

        return Promise.resolve(businessSearchError.result);
    }



    // search by multiple questions/answers (MakeModel search)
    static autofillBasedOnPartialDto(dto, additionalHeaders = {}) {

        if (!enableVehicleSearchMock) {
            return JsonRPCService.send(
                getProxiedServiceUrl('vehicleInfoLookup'),
                'lookupBasedOnPartialDTO',
                [dto],
                additionalHeaders
            );
        }

        if (dto.make === 'AUDI' && dto.model === 'A4' && dto.enginePower_PV === '66' && dto.fuelType_PV === '1') {
            return audiA466kwPetrol.result;
        }
        if (dto.make === 'AUDI' && dto.model === 'A4' && dto.enginePower_PV === '66' && dto.fuelType_PV === '2') {
            return audiA466kwDiesel.result;
        }
        if (dto.make === 'AUDI' && dto.model === 'A4' && dto.enginePower_PV === '66' && !_.isNil(dto.fuelType_PV)) {
            return businessSearchError.result;
        }

        if (dto.make === 'AUDI' && dto.model === 'A4' && dto.enginePower_PV === '66') {
            return audiA466kwFuels.result;
        }
        if (dto.make === 'AUDI' && dto.model === 'A3' && dto.enginePower_PV === '66') {
            return audiA366kw.result;
        }
        if (dto.make === 'AUDI' && dto.model === 'A3' && !_.isNil(dto.enginePower_PV)) {
            return businessSearchError.result;
        }

        if (dto.make === 'AUDI' && dto.model === 'A4') {
            return audiA4Engines.result;
        }
        if (dto.make === 'AUDI' && dto.model === 'A3') {
            return audiA3Engines.result;
        }
        if (dto.make === 'AUDI' && dto.model === 'A2') {
            return audiA2error.result;
        }
        if (dto.make === 'AUDI' && !_.isNil(dto.model)) {
            return businessSearchError.result;
        }

        if (dto.make === 'AUDI') {
            return audiModel.result;
        }

        if (_.isEmpty(dto)) {
            return make.result;
        }

        if (dto.make === 'ERROR') {
            return Promise.reject();
        }

        return businessSearchError.result;
    }

    // retrieves 1 vehicle by VehicleID. only used together with Informex MakeModel search
    static autofillBasedOnVehicleId(id, additionalHeaders = {}) {

        if (!enableVehicleSearchMock) {
            return JsonRPCService.send(
                getProxiedServiceUrl('vehicleInfoLookup'),
                'lookupBasedOnVehicleId',
                [id],
                additionalHeaders
            );
        }

        return Promise.resolve(anyVehicleId.result);
    }

}
