import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { TranslatorContext } from '@jutro/locale';
import { Avatar } from '@jutro/components';
import { ImageComponent } from 'gw-components-platform-react';
import { ServiceManager } from '@jutro/legacy/services';
import { PhoneUtil } from '@xengage/gw-portals-viewmodel-js';
import { ContactService } from 'gw-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './AssociatedContactsPage.metadata.json5';
import tabStyles from '../Contacts.module.scss';
import messages from '../Contacts.messages';
import './AssociatedContacts.messages';
import gatewayMessages from '../../gateway.messages';
import VennIntersectionImgSrc from './images/venn-intersection.svg'

const PRIMARY_PHONE_TYPE_MAP = {
    home: 'homeNumber',
    mobile: 'cellNumber',
    work: 'workNumber'
};

class AssociatedContactsPage extends Component {
    static contextType = TranslatorContext;

    state = {
        associatedContactsTableData: {},
        selectedTab: 'all'
    };

    static propTypes = {
        accountName: PropTypes.string.isRequired,
        accountNumber: PropTypes.string.isRequired,
        relatedTo: PropTypes.string.isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired
    };

    getAssociatedContacts = (selectedTab, paginationParams) => {
        const { accountNumber, authHeader } = this.props;
        let getContactsPromise;

        if (selectedTab !== 'commoncontacts') {
            const relationships = selectedTab ? [selectedTab] : [];
            getContactsPromise = ContactService.getRelatedAccountContacts(
                accountNumber,
                authHeader,
                relationships,
                paginationParams.fromPage,
                paginationParams.pageSize
            );
        } else {
            getContactsPromise = ContactService.getCommonAccountContacts(
                accountNumber,
                authHeader,
                paginationParams.fromPage,
                paginationParams.pageSize
            );
        }

        return getContactsPromise.then((response) => {
            this.setState({
                associatedContactsTableData: response
            });
            const data = this.getResponseData(response);
            return {
                rows: data,
                numberOfRows: response.maxNumberOfResults
            };
        });
    };

    getResponseData = (data) => {
        const { accountContacts } = data;
        if (accountContacts) {
            const contactsInfo = accountContacts.map((contact) => {
                return {
                    contact: contact.displayName,
                    account: contact.relatedAccountName,
                    involvedWith: contact.associatedPoliciesCount,
                    active: contact.active
                };
            });
            return contactsInfo;
        }
        return [];
    };

    redirectToContactDetails = (index) => {
        const { accountNumber, relatedTo, history } = this.props;
        const { associatedContactsTableData } = this.state;
        const { accountContacts } = associatedContactsTableData;

        history.push({
            pathname: `/${relatedTo}/${accountNumber}/contactDetails`,
            state: {
                contact: 'associatedContacts',
                accountOrPolicyNumber: accountNumber,
                relatedTo: relatedTo,
                contactId: accountContacts[index].accountContactPublicID
            }
        });
    };

    renderContact = (item, index, { path: property }) => {
        const { associatedContactsTableData } = this.state;
        const translator = this.context;
        if (index < associatedContactsTableData.accountContacts.length) {
            const contact = associatedContactsTableData.accountContacts[index];

            if (property === 'phone') {

                if(!contact.primaryPhoneType) {
                    return null;
                }
                const phoneNumber = contact[PRIMARY_PHONE_TYPE_MAP[contact.primaryPhoneType]];
                const localeService = ServiceManager.getService('locale-service');
                const phoneUtil = PhoneUtil();
                const primaryPhoneNumber = phoneUtil.prettyPrint(
                    phoneNumber,
                    localeService.getDefaultCountryCode()
                );

                return primaryPhoneNumber && (
                    <div className={tabStyles.gwContactPhoneNumber}>
                        {primaryPhoneNumber}
                    </div>
                );
            }

            if (property === 'email') {
                return contact.emailAddress1 && (
                    <div className={tabStyles.gwContactEmail}>
                        {contact.emailAddress1}
                    </div>
                );
            }

            const contactAvatarClass = cx(tabStyles.gwContactAvatar, {
                [tabStyles.gwActiveContactAvatar]: item.active,
                [tabStyles.gwInactiveContactAvatar]: !item.active
            });

            const commonContact = contact.isCommonContact && (
                <div>
                    <ImageComponent
                        className={tabStyles.gwContactCommonImage}
                        src={VennIntersectionImgSrc}
                        alt="common-contact"
                        onClick={() => {
                            this.redirectToContactDetails(index);
                        }}
                    />
                    <div className={tabStyles.gwContactCommonText}>
                        {translator(messages.commonContact)}
                    </div>
                </div>
            );

            return (
                <div className={tabStyles.gwContactsTableWrapper}>
                    <div className={tabStyles.gwContactAvatarContainer}>
                        <Avatar
                            className={contactAvatarClass}
                            username={item[property]}
                            onClick={() => {
                                this.redirectToContactDetails(index);
                            }}
                        />
                        {commonContact}
                    </div>
                    <div className={tabStyles.gwContactNameContainer}>
                        <div className={tabStyles.gwContactName}>{item[property]}</div>
                    </div>
                </div>
            );
        }

        return <div />;
    };

    renderRole = (item, index, { path: property }) => {
        return item[property];
    };

    onAssociatedContactsSelect = (selectedOption) => {
        this.setState({
            selectedTab: selectedOption
        });
    };

    renderPoliciesInvolved = (item, index, { path: property }) => {
        const policiesCount = item[property];
        const isActive = item.active;

        const policiesInvolved = isActive
            ? (
                <TranslatorContext.Consumer>
                    {(translator) => {
                        const activePolicies = policiesCount > 1
                            ? translator(messages.activePolicies)
                            : translator(messages.activePolicy);
                        return (
                            <div className={tabStyles.gwInvolvedPoliciesContainer}>
                                <h4 className={tabStyles.gwInvolvedPolicies}>{policiesCount}</h4>
                                {activePolicies}
                            </div>
                        );
                    }}
                </TranslatorContext.Consumer>
            )
            : (
                <TranslatorContext.Consumer>
                    {(translator) => {
                        return (
                            translator(gatewayMessages.inactive)
                        );
                    }}
                </TranslatorContext.Consumer>
            );

        return <div>{policiesInvolved}</div>;
    };

    loadData = ({ pageSize, page }) => {
        const { selectedTab } = this.state;
        const tab = selectedTab === 'all' ? null : selectedTab.toLowerCase();
        const paginationParams = {
            fromPage: page,
            pageSize: pageSize
        };

        return this.getAssociatedContacts(tab, paginationParams);
    };

    relatedByDropdownValues = () => {
        const translator = this.context;
        const availableValues =  [{
            name: translator(messages.relationshipTypeAll),
            code: 'all'
        }, {
            name: translator(messages.child),
            code: 'child'
        }, {
            name: translator(messages.parent),
            code: 'parent'
        },  {
            name: translator(messages.commonOwner),
            code: 'commonOwner'
        }, {
            name: translator(messages.commonContact),
            code: 'commonContact'
        }];

        return availableValues;
    };

    render() {
        const { selectedTab } = this.state;
        const { accountName, accountNumber } = this.props;
        const translator = this.context;

        const overrideProps = {
            associatedContactsDataTable: {
                key: selectedTab,
                onFetchData: this.loadData
            },
            relatedByDropdown: {
                availableValues: this.relatedByDropdownValues(),
                value: selectedTab
            },
            contactsRelatedByDescription: {
                content: translator(messages[`${selectedTab}RelationshipDescription`], {
                    accountName: accountName,
                    accountNumber: accountNumber
                })
            }
        };

        const resolvers = {
            resolveClassNameMap: tabStyles,
            resolveCallbackMap: {
                renderContact: this.renderContact,
                renderAccount: this.renderAccount,
                renderPoliciesInvolved: this.renderPoliciesInvolved,
                handleAssociatedContactSelect: this.onAssociatedContactsSelect,
                onRowClick: (data, index) => {
                    this.redirectToContactDetails(index);
                }
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}

export default withRouter(withAuthenticationContext(AssociatedContactsPage));
