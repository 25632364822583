import React, { useContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Loader, TooltipIcon, Icon, useModal } from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { LocalDateUtil } from '@xengage/gw-portals-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { EdgeErrorParser, BusinessConstant as BC } from 'pv-portals-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ESignatureService, EPaymentService} from 'pv-capability-policyjob';
import { messages as platformMessagesPV } from 'pv-platform-translations';
import messages from './ESignaturePage.messages';
import metadata from './ESignaturePage.metadata.json5';
import styles from './ESignaturePage.module.scss';

const NextAction = {
    Next: 'next',
    NextWithoutSave: 'next_no_save', // e.g. when skipping payment
    SignOnline: 'sign_online',
    PaymentOnline: 'payment_online'
};
function ESignaturePage(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const { showAlert } = useModal();
    const { wizardData: submissionVM, updateWizardData, goNext } = props;
    const [ continueWithoutOnlinePayment, setContinueWithoutOnlinePayment ] = useState(false);
    const [ showBrokerEmailPhoneMissingWarning, setShowBrokerEmailPhoneMissingWarning ] = useState(false);
    const [ nextAction, setNextAction ] = useState(NextAction.Next);
    const [ jobType ] = useState(_.get(submissionVM, 'baseData.jobType.value.code')); // Submission vs. PolicyChange
    const [ isSubmission ] = useState( jobType === 'Submission' );
    const [ isPolicyChange ] = useState( jobType === 'PolicyChange' );
    const [ bindDataPath ] = useState( isSubmission ? 'bindData' : 'bindData_PV' );
    const [ showWarningMessageESignature, setShowWarningMessageESignature ] = useState(submissionVM?.[bindDataPath]?.signatureStatus_PV?.value?.code === BC.SIGNATURE_STATUS_DECLINED);
    const [ showWarningMessageEPayment, setShowWarningMessageEPayment ] = useState(submissionVM?.[bindDataPath]?.paymentStatus_PV?.value?.code === BC.PAYMENT_STATUS_FAILED || submissionVM?.[bindDataPath]?.paymentStatus_PV?.value?.code === BC.SIGNATURE_STATUS_PENDING);
    const [ productCode ] = useState(_.get(submissionVM, 'baseData.productCode.value'));
    const [ isCommercial ] = useState(productCode === BC.PCV_PROD_CODE);
    const [ isLoading, setLoading ] = useState(false);
    const { onValidate, isComponentValid, initialValidation, registerInitialComponentValidation, registerComponentValidation } = useValidation('ESignaturePage');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const [ isBroker ] = useState(submissionVM.value.isSubmitBroker);
    const [ isPersonalLineBroker ] = useState(submissionVM.value.baseData.productCode === BC.PPV_PROD_CODE && isBroker);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const isSigningMethodDisabled = useCallback((itemCode) => {
        const phone = _.get(submissionVM, 'value.baseData.accountHolder.cellNumber');
        const email = _.get(submissionVM, 'value.baseData.accountHolder.emailAddress1');
        const paymentMethod = _.get(submissionVM, `${bindDataPath}.paymentType_PV.value.code`);
        const signatureStatus = _.get(submissionVM, `${bindDataPath}.signatureStatus_PV.value.code`);

        if (_.isEmpty(phone) && (itemCode === BC.SIGN_METHOD_ELECTRONIC || itemCode === BC.SIGN_METHOD_DEFERRED)) {
            return true;
        }

        if (_.isEmpty(email) && itemCode === BC.SIGN_METHOD_DEFERRED) {
            return true;
        }

        if (['nocom@', 'nomail@'].some((s) => email?.toLowerCase().includes(s))
            && (itemCode === BC.SIGN_METHOD_ELECTRONIC || itemCode === BC.SIGN_METHOD_DEFERRED)) {
            return true;
        }

        if (signatureStatus === BC.SIGNATURE_STATUS_SIGNED
            || (signatureStatus === BC.SIGNATURE_STATUS_PENDING && itemCode === BC.SIGN_METHOD_DEFERRED)) {
            return true;
        }

        if (paymentMethod === BC.PAYMENT_TYPE_ONLINE && (itemCode === BC.SIGN_METHOD_PAPER || itemCode === BC.SIGN_METHOD_DEFERRED)) {
            return true;
        }
    }, [submissionVM, bindDataPath]);

    const isPaymentMethodDisabled = useCallback((itemCode) => {
        const phone = _.get(submissionVM, 'value.baseData.accountHolder.cellNumber');
        const email = _.get(submissionVM, 'value.baseData.accountHolder.emailAddress1');
        const signingMethod = _.get(submissionVM, `${bindDataPath}.signatureMethod_PV.value.code`);
        const signatureStatus = _.get(submissionVM, `${bindDataPath}.signatureStatus_PV.value.code`);
        const startDate = LocalDateUtil.toMidnightDate(submissionVM.baseData.periodStartDate.value);
        const daysBetweenStartDate = moment(startDate).isAfter(moment().add(42, 'days'));

        // if phone is empty than only paper signature is possible which also means that online payment is not possible anymore
        if (_.isEmpty(phone) && itemCode === BC.PAYMENT_TYPE_ONLINE) {
            return true;
        }
        if (submissionVM.value.status === 'Quoted' || submissionVM.value.status === 'Proposal_pv' && !_.isUndefined(submissionVM.transactionCost?.amount) && (itemCode === BC.PAYMENT_TYPE_ONLINE || itemCode === BC.PAYMENT_TYPE_DEFERRED) && submissionVM.transactionCost.amount.value <= 0) {
            return true;
        }
        if (_.isEmpty(email) && itemCode === BC.PAYMENT_TYPE_DEFERRED) {
            return true;
        }
        if (['nocom@', 'nomail@'].some((s) => email?.toLowerCase().includes(s))
            && (itemCode === BC.PAYMENT_TYPE_DEFERRED || itemCode === BC.PAYMENT_TYPE_ONLINE)) {
            return true;
        }
        if (isPersonalLineBroker && _.includes(BC.CreateListCollectionMethodsWithoutOnlinePayment, submissionVM.value.baseData.billingMethod_PV) && (itemCode === BC.PAYMENT_TYPE_ONLINE || itemCode === BC.PAYMENT_TYPE_DEFERRED)) {
            return true;
        }
        if (isPolicyChange && (_.isUndefined(submissionVM.transactionCost.amount) || submissionVM.transactionCost.amount.value <= 0) && itemCode !== BC.PAYMENT_TYPE_NO_ONLINE) {
            return true;
        }
        if (signatureStatus === BC.SIGNATURE_STATUS_SIGNED
            || (signingMethod === BC.SIGN_METHOD_DEFERRED && signatureStatus === BC.SIGNATURE_STATUS_PENDING)) {
            return true;
        } if (itemCode === BC.PAYMENT_TYPE_ONLINE && (signingMethod === BC.SIGN_METHOD_PAPER || signingMethod === BC.SIGN_METHOD_DEFERRED)) {
            return true;
        } if (_.includes(BC.CreateListCollectionMethodsWithoutOnlinePayment, submissionVM.value.baseData.billingMethod_PV) && (itemCode === BC.PAYMENT_TYPE_ONLINE || itemCode === BC.PAYMENT_TYPE_DEFERRED)) {
            return true;
        }
        if (daysBetweenStartDate && (itemCode === BC.PAYMENT_TYPE_ONLINE || itemCode === BC.PAYMENT_TYPE_DEFERRED)) {
            return true;
        }
        return false;
    }, [isPersonalLineBroker, isPolicyChange, submissionVM, bindDataPath]);

    const checkBrokerEmailPhone = useCallback((_accountContact, _signingMethod, _paymentType, _isBroker) => {
        const emailAndPhoneSetAndNeeded = _isBroker
                                        && (_.isUndefined(_accountContact.emailAddress1) || !_accountContact.emailAddress1)
                                        && (_.isUndefined(_accountContact.cellNumber) || !_accountContact.cellNumber);
        if (emailAndPhoneSetAndNeeded) {
            if (_signingMethod !== BC.SIGN_METHOD_PAPER || (_signingMethod === BC.SIGN_METHOD_PAPER && _paymentType !== BC.PAYMENT_TYPE_NO_ONLINE)) {
                setShowBrokerEmailPhoneMissingWarning(true);
                return false;
            }
        }
        return true;
    }, []);

    const doDataSaveOnly = useCallback(async () => {
        const signingMethod = _.get(submissionVM, `${bindDataPath}.signatureMethod_PV.value.code`);
        const paymentType = _.get(submissionVM, `${bindDataPath}.paymentType_PV.value.code`);
        const submissionValue = submissionVM.value;

        // the same check in doSignOnline()
        if (!checkBrokerEmailPhone(submissionValue.baseData.accountHolder, signingMethod, paymentType, isBroker)) {
            setShowBrokerEmailPhoneMissingWarning(true);
            return false;
        }

        try {
            setLoading(true);
            let newData;
            if (isSubmission) {
                newData = await LoadSaveService.saveSigningAndPaymentMethod_PV(submissionValue.quoteID, submissionValue.sessionUUID, signingMethod, paymentType, authHeader);
            } else {
                newData = await LoadSaveService.saveSigningMethod_PV(submissionValue.jobID, signingMethod, paymentType, authHeader);
            }
            submissionVM.value = newData;
            updateWizardData(submissionVM);
            return submissionVM;
        } catch (error) {
            console.error('Error:', error);
            setShowWarningMessageESignature(submissionVM?.[bindDataPath]?.signatureStatus_PV?.value?.code === BC.SIGNATURE_STATUS_DECLINED);
            const msg = EdgeErrorParser.getErrorMessage(error);
            if (!_.isEmpty(msg)) {
                // show specific error in case description is available
                showAlert({
                    title: platformMessagesPV.genericTechnicalErrorTitle,
                    message: msg,
                    status: 'error',
                    icon: 'mi-error-outline'
                });
            }
            return false;
        } finally {
            setLoading(false);
        }
    }, [LoadSaveService, authHeader, isBroker, showAlert, isSubmission, checkBrokerEmailPhone, submissionVM, updateWizardData, bindDataPath]);

    /**
     * Returns URL after e-signature (directly to PE/quote/###/summary?autostart)
     */
    const createRedirectUrlForEsignature = useCallback((tokenUrl, quoteNumber, ajobType) => {
        const pathForJob = {
            'Submission': 'quotes',
            'PolicyChange': 'change'
        }[ajobType];
        const applicationBaseHref = `${window.location.pathname.substring(1, window.location.pathname.substring(1).indexOf('/') + 1)}`; // e.g. 'producer-engage'
        const refererUrl = `${window.location.origin}/${applicationBaseHref}/${pathForJob}/${quoteNumber}/summary?autostart`;
        return `${tokenUrl}&refererUrl=${encodeURIComponent(refererUrl)}`;
    }, []);

    const initSignatureSession = useCallback(async (submissionValue) => {

        setShowWarningMessageESignature(false);
        const jobNumber = submissionValue.quoteID || submissionValue.jobID;
        let tokenUrl;
        try {
            setLoading(true);
            tokenUrl = await ESignatureService.retryableGetOrCreateTokenUrl(jobNumber, submissionValue.sessionUUID, authHeader);
        } catch(error) {
            setShowWarningMessageESignature(true);
            const msg = EdgeErrorParser.getErrorMessage(error);
            if (!_.isEmpty(msg)) {
                showAlert({
                    title: platformMessagesPV.genericTechnicalErrorTitle,
                    message: msg,
                    status: 'error',
                    icon: 'mi-error-outline'
                });
            }
            return false;
        } finally {
            setLoading(false);
        }
        if (_.isEmpty(tokenUrl)) {
            setShowWarningMessageESignature(true);
            return false;
        }

        window.location.href = createRedirectUrlForEsignature(tokenUrl, jobNumber, jobType);
        return false;

    }, [authHeader, showAlert, createRedirectUrlForEsignature, jobType]);

    const doSignOnline = useCallback(async () => {
        const jobVM = await doDataSaveOnly();
        if (jobVM !== false) {
            initSignatureSession(jobVM.value);
        }
        return false; // we do not want wizard advancement, we want window.location.href to trigger browser redirected
    }, [doDataSaveOnly, initSignatureSession]);

    const createRedirectUrlForEpayment = useCallback((_jobType, _jobID) => {
        const _urlToJob = {
            'Submission': 'quotes',
            'PolicyChange': 'change'
        }[_jobType];
        const enduserPage = `${window.location.origin}/producer-engage/${_urlToJob}/${_jobID}/summary?autostart`;

        return enduserPage;
    }, []);

    const doPaymentOnline = useCallback(async () => {
        const submissionValue = submissionVM.value;
        const jobID = submissionValue.quoteID || submissionValue.jobID;
        const paymentRefererUrl = createRedirectUrlForEpayment(jobType, jobID);
        let tokenUrl;
        try {
            setLoading(true);
            tokenUrl = await EPaymentService.retryableGetOrCreateTokenUrl(jobID, submissionValue.sessionUUID, paymentRefererUrl, authHeader);
        } catch (error) {
            const msg = EdgeErrorParser.getErrorMessage(error);
            if (!_.isEmpty(msg)) {
                showAlert({
                    title: platformMessagesPV.genericTechnicalErrorTitle,
                    message: msg,
                    status: 'error',
                    icon: 'mi-error-outline'
                });
            }
            setShowWarningMessageEPayment(true);
            return false;
        } finally {
            setLoading(false);
        }

        if (_.isEmpty(tokenUrl)) {
            setShowWarningMessageEPayment(true);
            return false;
        }

        window.location.href = tokenUrl;
        return false; // good bye. window.location.href will do browser redirect
    }, [authHeader, showAlert, createRedirectUrlForEpayment, submissionVM, jobType]);

    const onNext = useCallback(async () => {
        switch (nextAction) {
            case NextAction.NextWithoutSave:
                return submissionVM;
            default:
            case NextAction.Next:
                return doDataSaveOnly();
            case NextAction.SignOnline:
                return doSignOnline();
            case NextAction.PaymentOnline:
                if (continueWithoutOnlinePayment) {
                    return submissionVM;
                }
                return doPaymentOnline();
        }
    }, [submissionVM, doDataSaveOnly, doPaymentOnline, doSignOnline, nextAction, continueWithoutOnlinePayment]);

    const determineAction = useCallback((newSubmissionVM, autoNext = false) => {
        const paymentMethod = _.get(newSubmissionVM, `${bindDataPath}.paymentType_PV.value.code`);
        const signingMethod = _.get(newSubmissionVM, `${bindDataPath}.signatureMethod_PV.value.code`);
        const signatureStatus = _.get(newSubmissionVM, `${bindDataPath}.signatureStatus_PV.value.code`);

        if (paymentMethod !== undefined && signingMethod !== undefined) {
            if (signingMethod === BC.SIGN_METHOD_ELECTRONIC) {
                if (signatureStatus === BC.SIGNATURE_STATUS_SIGNED) {
                    if (paymentMethod === BC.PAYMENT_TYPE_ONLINE) {
                        setNextAction(NextAction.PaymentOnline);
                    } else {
                        // signed, so cannot change anything already
                        setNextAction(NextAction.NextWithoutSave);
                        if (autoNext) {
                            goNext(); // auto-continue wizard
                        }
                    }
                } else {
                    setNextAction(NextAction.SignOnline);
                }
            } else if (signingMethod === BC.SIGN_METHOD_PAPER || signingMethod === BC.SIGN_METHOD_DEFERRED) {
                setNextAction(NextAction.Next);
            }
        }
    }, [bindDataPath, goNext]);

    const retryUpdateEsignatureStatus = useCallback(async () => {

        const jobValue = submissionVM.value;
        const jobNumber = jobValue.quoteID || jobValue.jobID;
        const sessionUUID = jobValue.sessionUUID; // NOTE: undefined for PolicyChange
        let newSigningStatus;

        try {
            setLoading(true);

            newSigningStatus = await ESignatureService.getRetryableSignatureStatus(jobNumber, sessionUUID, authHeader);

            if (isSubmission) {
                submissionVM.value = await LoadSaveService.retrieveSubmission({quoteID: jobNumber, postalCode: jobValue.baseData.policyAddress.postalCode}, authHeader);
            } else {
                submissionVM.value = await LoadSaveService.loadEndorsementWithEffectiveDate([jobValue.policyNumber, jobValue.baseData.effectiveDate], authHeader);
            }

            updateWizardData(submissionVM);
            determineAction(submissionVM, true /* auto-continue if possible */);
        } catch (error) {
            // do not pop alert here,
            // 1. mostlikely agent left signature page and that's why it is still PENDING
            // 2. no idea why loading submission/policychange might fail
            console.error(error);
            setShowWarningMessageESignature(true);
        } finally {
            setLoading(false);
        }

        setShowWarningMessageESignature(newSigningStatus !== BC.SIGNATURE_STATUS_SIGNED);

    }, [LoadSaveService, authHeader, determineAction, isSubmission, submissionVM, updateWizardData]);

    const isMandatoryValuesSet = useCallback(() =>
        !!submissionVM[bindDataPath].signatureMethod_PV.value && !!submissionVM[bindDataPath].paymentType_PV.value
    , [submissionVM, bindDataPath]);

    const shouldPageSkip = useCallback(() => {
        const aPolicyPeriodStatus = _.get(submissionVM, `baseData.periodStatus.value.code`);

        const aSignatureMethod = _.get(submissionVM, `${bindDataPath}.signatureMethod_PV.value.code`);
        const aSignatureStatus = _.get(submissionVM, `${bindDataPath}.signatureStatus_PV.value.code`);

        const aPaymentType = _.get(submissionVM, `${bindDataPath}.paymentType_PV.value.code`);
        const aPaymentStatus = _.get(submissionVM, `${bindDataPath}.paymentStatus_PV.value.code`);

        const isSigned = (aSignatureStatus === BC.SIGNATURE_STATUS_SIGNED) || (aSignatureMethod === BC.SIGN_METHOD_PAPER && aPolicyPeriodStatus === BC.BOUND_STATUS);
        const isPaid = (aPaymentType === BC.PAYMENT_TYPE_DEFERRED || aPaymentType === BC.PAYMENT_TYPE_NO_ONLINE || aPaymentStatus === BC.PAYMENT_STATUS_APPROVED);

        return isSigned && isPaid;
    }, [submissionVM, bindDataPath]);

    useEffect(() => {
        registerComponentValidation(isMandatoryValuesSet);
        registerInitialComponentValidation(shouldPageSkip);
    }, [isMandatoryValuesSet, shouldPageSkip, registerComponentValidation, registerInitialComponentValidation]);

    useEffect(() => {
        const aSignatureMethod = _.get(submissionVM, `${bindDataPath}.signatureMethod_PV.value.code`);
        const aSignatureStatus = _.get(submissionVM, `${bindDataPath}.signatureStatus_PV.value.code`);

        const aPaymentType = _.get(submissionVM, `${bindDataPath}.paymentType_PV.value.code`);

        let newSubmissionVM = submissionVM;

        if (aSignatureStatus !== BC.SIGNATURE_STATUS_SIGNED) {

            // if not signed, then it is possible to select esignMethod & epayType, therefore reset them if pre-selected incorrectly
            if (isSigningMethodDisabled(aSignatureMethod)
                || isPaymentMethodDisabled(aPaymentType)
                || _.isNil(aSignatureMethod)
                || _.isNil(aPaymentType))
            {
                newSubmissionVM = viewModelService.clone(submissionVM);

                // 1. reset not allowed values or combinations and force to select these options
                // 2. reset all options if at least 1 not set
                _.set(newSubmissionVM, `${bindDataPath}.signatureMethod_PV.value`, undefined);
                _.set(newSubmissionVM, `${bindDataPath}.paymentType_PV.value`, undefined);

                updateWizardData(newSubmissionVM);
            }
        }

        // on page enter, calculate correct next action (signature & payment are pre-selected on return!)
        determineAction(newSubmissionVM);

        if (aSignatureStatus === BC.SIGNATURE_STATUS_PENDING) {
            retryUpdateEsignatureStatus();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSigningChange = useCallback((event) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, `${bindDataPath}.signatureMethod_PV`, event.target.value);
        updateWizardData(newSubmissionVM);
        determineAction(newSubmissionVM);
    }, [determineAction, submissionVM, updateWizardData, viewModelService, bindDataPath]);

    const renderSigningRadioButton = useCallback(() => {
        const signatureStatus = _.get(submissionVM, `${bindDataPath}.signatureStatus_PV.value.code`);
        const signatureMethod = _.get(submissionVM, `${bindDataPath}.signatureMethod_PV.value.code`);
        return (
            <div className={styles.customRadioButtonContainer} onChange={(e) => onSigningChange(e)}>
                <div className={styles.radioGroupHeader}>{translator(messages.signingOfTheDocuments)}
                    {signatureStatus === BC.SIGNATURE_STATUS_SIGNED ? <Icon className={styles.checkIcon} icon="mi-check-circle" id="signing-icon" size="small" color="#009000" /> : null}
                </div>
                {[BC.SIGN_METHOD_ELECTRONIC, BC.SIGN_METHOD_DEFERRED, BC.SIGN_METHOD_PAPER].map((signMethod) => (
                    <React.Fragment key={signMethod}>
                        <input
                            checked={signatureMethod === signMethod}
                            disabled={isSigningMethodDisabled(signMethod)}
                            type="radio" id={signMethod}
                            name="signing" value={signMethod}
                        />
                        <label htmlFor={signMethod}>
                            <span className={styles.radioIcon}/>
                            <span className={styles.radioText}>{translator(messages[signMethod])}<TooltipIcon id={`${signMethod}_tooltip`} text={translator(messages[`${signMethod}_tooltip`])}/></span>
                        </label><br/>
                    </React.Fragment>
                ))}
            </div>
        );
    }, [isSigningMethodDisabled, onSigningChange, submissionVM, translator, bindDataPath]);

    const onPaymentChange = useCallback((event) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, `${bindDataPath}.paymentType_PV`, event.target.value);
        updateWizardData(newSubmissionVM);
        determineAction(newSubmissionVM);
    }, [determineAction, submissionVM, updateWizardData, viewModelService, bindDataPath]);

    const renderPaymentRadioButton = useCallback(() => {
        const apaymentType = _.get(submissionVM, `${bindDataPath}.paymentType_PV.value.code`);
        return (
            <div className={styles.customRadioButtonContainer} onChange={(e) => onPaymentChange(e)}>
                <div>{translator(messages.firstOnlinePayment)}</div>
                {[BC.PAYMENT_TYPE_ONLINE, BC.PAYMENT_TYPE_DEFERRED, BC.PAYMENT_TYPE_NO_ONLINE].map((paymentType) => (
                    <React.Fragment key={paymentType}>
                        <input
                            checked={apaymentType === paymentType}
                            disabled={isPaymentMethodDisabled(paymentType)} type="radio" id={paymentType} name="payment" value={paymentType}
                        />
                        <label htmlFor={paymentType}>
                            <span className={styles.radioIcon}/>
                            <span className={styles.radioText}>{translator({id: `typekey.PaymentType_PV.${paymentType}`})}<TooltipIcon id={`${paymentType}_tooltip`} text={translator(messages[`${paymentType}_tooltip`])}/></span>
                        </label><br/>
                    </React.Fragment>
                ))}
            </div>
        );
    }, [isPaymentMethodDisabled, onPaymentChange, submissionVM, translator, bindDataPath]);

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        warningMessageESignature: {
            visible: showWarningMessageESignature,
        },
        warningMessageEPayment: {
            visible: showWarningMessageEPayment,
        },
        warningMessageBrokerEmailPhoneMissing: {
            visible: showBrokerEmailPhoneMissingWarning,
        },
        singningRadioButtonContainer: {
            content: renderSigningRadioButton(),
        },
        paymentRadioButtonContainer: {
            content: renderPaymentRadioButton(),
        },
        signaturePageHeader: {
            content: isSubmission ? messages.headerSubmission : messages.headerPolicyChange,
        },
        finalStepsIntro: {
            visible: isSubmission,
            content: isCommercial ? messages.pcvIntro : messages.ppvIntro,
        },
        optionToSkipPayment: {
            visible: showWarningMessageEPayment,
            value: continueWithoutOnlinePayment
        }
    };

    const defineNextLabel = () => {
        if (continueWithoutOnlinePayment) {
            return translator(messages.continueWithoutOnlinePayment);
        }
        switch (nextAction) {
            case NextAction.SignOnline:
                return translator(messages.sign);

            case NextAction.PaymentOnline:
                return translator(messages.pay);

            default:
            case NextAction.Next:
                return translator(messages.carryOn);
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            setContinueWithoutOnlinePayment
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            disableNext={!isComponentValid || isLoading}
            showNext={!isLoading}
            nextLabel={defineNextLabel()}
            showPrevious={false}
            showCancel={isPolicyChange && !isLoading}
        >
            <Loader loaded={!isLoading}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onValueChange={writeValue}
                    onValidationChange={onValidate}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </Loader>
        </WizardPage>
    );
}

ESignaturePage.propTypes = wizardProps;
export default ESignaturePage;
