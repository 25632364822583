import React, { useContext, useState, useCallback } from 'react';
// import { Chevron } from '@jutro/components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { IntlContext, useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import VehicleCoverage from './VehicleCoverage/VehicleCoverage';
import metadata from './PolicyVehiclesDriversInfo.metadata.json5';
import styles from '../../Summary.module.scss';
import infostyles from './PolicyVehiclesDriversInfo.module.scss';
import messages from '../../Summary.messages';

// const getHeaderSection = (title) => {
//     return (isOpen) => (
//         <React.Fragment>
//             <Chevron isOpen={isOpen} className={styles.chevronStyle} />
//             <h2 className={`${styles.title} ${styles.gwOverrideDefaultMargin}`}>{title}</h2>
//         </React.Fragment>
//     );
// };

function getCell(items, index, property) {
    return !_.isObject(items[property.id]) ? items[property.id] : null;
}

function getCellVehicle(items, index, property) {
    return !_.isObject(items[property.id]) ? items[property.id] || '-' : null;
}

const renderFormattedCurrencyField = (item, index) => {
    return (
        <div className={styles.currencyContainer}>
            <CurrencyField
                id={`currency_${index}`}
                value={item}
                readOnly
                hideLabel
            />
        </div>
    );
};

const getFormattedCurrency = (item, index, property) => {
    return renderFormattedCurrencyField(item[property.id], index);
};

function PolicyVehiclesDriversInfo({ value, productCode }) {
    const translator = useTranslator();
    const intl = useContext(IntlContext);

    const getFormattedDate = useCallback((item, index, property) => {
        return intl.formatDate(new Date(item[property.id]), { year: 'numeric', month: 'short', day: 'numeric' });
    }, [intl]);

    // const [openItems, setOpenItems] = useState([]);
    // if (_.isEmpty(value.vehicleDTOs)) {
    //     return null;
    // }
    const getDriverCoverageData = () => {
        const driverData = value.vehicleDrivers; // value.driverDTOs;
        return driverData
            .map((driver) => {
                return {
                    driverName: driver.driverName, // driver.displayName,
                    licenseNumber: driver.licensePlate || '-', // driver.licenseNumber,
                    dateOfBirth: intl.formatDate(
                        new Date(
                            driver.driverDateOfBirth.year, // driver.dateOfBirth.year,
                            driver.driverDateOfBirth.month, // driver.dateOfBirth.month,
                            driver.driverDateOfBirth.day, // driver.dateOfBirth.day
                        ), { year: 'numeric', month: 'short', day: 'numeric' }
                    ),
                    // P&V
                    make: driver.make,
                    model: driver.model,
                    mainDriver: driver.isMainDriver ? '✔' : '-',
                    additionalDriver: driver.isAdditionalDriver ? '✔' : '-'
                };
            })
            .sort((a, b) => {
                return a.driverName - b.driverName;
            });
    };
    const getLimitDeductableValue = (terms, type) => {
        if (!_.isEmpty(terms) && terms[0].modelType === type) {
            return terms[0].amount;
        }
        return '-';
    };
    const getCoverage = (coverages, isLineCoverage) => {
        return coverages.map((coverage) => {
            return {
                name: coverage.name,
                coverageType: isLineCoverage ? translator(messages.vehicleInfoPolicy) : null,
                limit: getLimitDeductableValue(coverage.terms, 'Limit'),
                deductible: getLimitDeductableValue(coverage.terms, 'Deductible'),
                premium: coverage.premium
            };
        });
    };
    const lineCoverage = getCoverage(value.coverageDTOs, true);
    const onExpandRow = (row) => {
        const overrideProps = {
            vehicleCoverageInfo: {
                data: _.concat(lineCoverage, row.coverages),
                show: true
            }
        };
        const resolvers = {
            resolveComponentMap: {
                vehiclecoverage: VehicleCoverage
            }
        };
        return (
            <ViewModelForm
                uiProps={metadata.expandableRow}
                model={row}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        );
    };

//     const generateVehicleOverrides = () => {
//         const overrides = value.vehicleDTOs.map((vehicleDTO, index) => {
//             const vehicleCoverage = getCoverage(vehicleDTO.coverages);
//             return {
//                 [`vehicleCoverageInfo${index}`]: {
//                     data: _.concat(lineCoverage, vehicleCoverage),
//                     show: openItems
//                 },
//                 [`showContentToggleLink${index}`]: {
//                     onClick: () => toggleShowHide(index),
//                     icon: _.includes(openItems, index) ? 'mi-arrow-drop-up' : 'mi-arrow-drop-down',
//                     content: _.includes(openItems, index)
//                         ? translator(messages.hideVehicleCoverages)
//                         : translator(messages.showVehicleCoverages)
//                 },
//                 [`vehicleCoverageContainer${index}`]: {
//                     visible: _.includes(openItems, index)
//                 }
//             };
//         });
//
//         return Object.assign({}, ...overrides);
//     };

    const overrides = {
        driverCoverageInfo: {
            data: getDriverCoverageData()
        },
        // ...generateVehicleOverrides(),
        totalPremiumId: {
            visible: false
        },
        summaryDetailGridRow: {
            data: value.vehicleDTOs
        },
        vehicleSection: {
            cardHeadingClassName: styles.accordionHeaderSummary
        },
        vehicleUbi: {
            visible: productCode === 'PersonalVehicle_PV' && value.isUbiEnabled
        },
        vehicleDesignatedDriver: {
            visible: productCode === 'CommercialVehicle_PV'
        },
        vehicleDriverCount: {
            visible: productCode === 'CommercialVehicle_PV'
        },
        driverSection: {
            cardHeadingClassName: styles.accordionHeaderSummary
        },
//         driverCoverageInfo: {
//             data: getDriverCoverageData()
//         },
//         ...generateVehicleOverrides()
    };
    const resolvers = {
        resolveClassNameMap: infostyles,
        resolveCallbackMap: {
            getCellCoverage: getCell,
            getCellVehicle: getCellVehicle,
            getFormattedDate: getFormattedDate,
            getFormattedCurrency: getFormattedCurrency,
            onExpandRow: onExpandRow
        },
        // resolveComponentMap: {
        //     vehiclecoverage: VehicleCoverage
        // }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={value}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PolicyVehiclesDriversInfo.propTypes = {
    value: PropTypes.shape({
        coverageDTOs: PropTypes.shape({}),
        vehicleDTOs: PropTypes.shape([]),
        driverDTOs: PropTypes.shape([]),
        additionalLiabilityCoverages: PropTypes.shape([]),
        additionalPropertyCoverages: PropTypes.shape([])
    }).isRequired,
    productCode: PropTypes.string.isRequired
};
export default PolicyVehiclesDriversInfo;
