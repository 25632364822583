import _ from 'lodash';
import BusinessConstant from './BusinessConstant';

const SPECIFIC_ERRORS = ['DocumentNotReadyException_PV:', 'ExceptionMessage'];

const getErrorMessage = (error) => {
    let result;
    const allErrorMsg = _.get(error, 'baseError');
    if (_.isString(allErrorMsg)) {
        // initial parsing
        const splitted = allErrorMsg.split('ErrorMessage: ');
        if (_.isArray(splitted) && splitted.length > 0) {
            result = splitted[splitted.length - 1];
        }

        // additional parsing of special errors
        const specError = SPECIFIC_ERRORS.find((aspecError) => result.includes(aspecError));
        if (specError) {
            const splitted2 = result.split(specError);
            result = splitted2[splitted2.length - 1];
        }
    }
    if (_.isNil(result)) {
        result = '';
    }
    return result;
};

const isUWIssueError = (error) => {
    const allErrorMsg = _.get(error, 'baseError');
    return allErrorMsg.includes('602');
};

const isValidationsError = (error) => {
    const allErrorMsg = _.get(error, 'baseError');
    return allErrorMsg.includes('620');
};

const isRetryableError = (error) => {
    const allErrorMsg = _.get(error, 'baseError');
    return allErrorMsg.includes('621');
};

const isValidationDiscountError = (error) => {
    const allErrorMsg = _.get(error, 'baseError');
    return allErrorMsg.includes(BusinessConstant.DISCOUNT_COMMERCIAL_EXCEEDS_HUNDRED)
        || allErrorMsg.includes(BusinessConstant.DISCOUNT_INDUCEMENT_EXCEEDS_HUNDRED)
        || allErrorMsg.includes(BusinessConstant.DISCOUNT_TECHNICAL_EXCEEDS_HUNDRED);
};

export default {
    getErrorMessage,
    isUWIssueError,
    isValidationsError,
    isValidationDiscountError,
    isRetryableError
};
