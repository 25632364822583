import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { MetadataContent } from '@jutro/legacy/uiconfig';

import metadata from './UnderwritingMessageComponent.metadata.json5';
import messages from './UnderwritingMessageComponent.messages';

function UnderwritingMessageComponent({ quoteId }) {
    const translator = useTranslator();

    const getMsg = useCallback(() => {
        const redirectRoute = {
            pathname: `/quotes/${quoteId}/summary`
        };
        return (
            <FormattedMessage
                {...messages.uwIssueMsg}
                values={{
                    link: <Link to={redirectRoute}>{translator(messages.moreDetails)}</Link>
                }}
            />
        );
    }, [quoteId, translator]);

    const overrideProps = {
        messageNotification: {
            messageProps: {
                warning: getMsg()
            }
        }
    };

    return <MetadataContent
        uiProps={metadata.componentContent}
        overrideProps={overrideProps} />;
}

UnderwritingMessageComponent.propTypes = {
    quoteId: PropTypes.string.isRequired
};

export default UnderwritingMessageComponent;
