import { defineMessages } from 'react-intl';

export default defineMessages({
    liberalProfession: {
        id: 'gateway.views.new-quote.Are you an independent or liberal profession?',
        defaultMessage: 'Are you an independent or do you have a liberal profession?'
    },
    vatVehicle: {
        id: 'gateway.views.new-quote.Are you going to deduct VAT for the vehicle to be insured?',
        defaultMessage: 'Will the vehicle be professionally used as an independent or liberal profession?'
    },
    enterpriseNumber: {
        id: 'gateway.views.new-quote.Enterprise Number',
        defaultMessage: 'Enterprise Number'
    },
    previousContractInformation: {
        id: 'gateway.views.new-quote.Previous policy information',
        defaultMessage: 'Previous Contract Information'
    },
    existingContract: {
        id: 'gateway.views.new-quote.Are you making this new submission for a vehicle change in an existing contract with our company?',
        defaultMessage: 'Are you making this new contract for a vehicle change in an existing contract with our company?'
    },
    previousContractNumber: {
        id: 'gateway.views.new-quote.Previous policy number',
        defaultMessage: 'Previous contract number'
    },
    originalStartDate: {
        id: 'gateway.views.new-quote.Original start date',
        defaultMessage: 'Original start date'
    }
});
