// import AddressLookupComponent from './AddressLookupComponent/AddressLookupComponent';
import PVAddressLookupComponent from './PVAddressLookupComponent/AddressLookupComponent';

const AddressLookupComponentMap = {
    AddressLookupComponent: { component: 'AddressLookupComponent' }
};

const AddressLookupComponents = {
    AddressLookupComponent: PVAddressLookupComponent
};

export {
    AddressLookupComponentMap,
    AddressLookupComponents,
    // export single component
    PVAddressLookupComponent as AddressLookupComponent
};
