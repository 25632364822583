/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { AccountService } from 'gw-capability-gateway-policy';
import { GatewayAvailabilityService } from 'gw-capability-gateway-policycommon';
import { useModal } from '@jutro/components';

import metadata from './ProducerComponent.metadata.json5';
import styles from './ProducerComponent.module.scss';
import gatewayMessages from '../../../gateway.messages';
import './ProducerComponent.messages';

function ProducerComponent(props) {

    const {
        id,
        labelPosition,
        showRequired,
        showErrors,
        submissionVM,
        updateSubmissionVM,
        producerCodeValue,
        onValidate,
        isAnExistingAccount,
        accountHolderViewVM,
        updateAccountHolderVM,
        accountProducerCodes
    } = props;

    const { showAlert } = useModal();
    const { authHeader } = useAuthentication();
    const [availableProductCodes, setAvailableProductCodes] = useState([]);
    const [availableBillingMethods, setAvailableBillingMethods] = useState([]);

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const getAvailableBillingMethod = useCallback(async (producerCode, productCode) => {
        if (_.isUndefined(producerCode) || _.isUndefined(productCode)) {
            return;
        }
        try {
            const availableBillingMethodsResponse = await GatewayAvailabilityService.getAvailableBillingMethodForProducerCode(
                producerCode, productCode, authHeader
            );
            const billingMethodsVM = _.get(submissionVM, 'billingMethod_PV.aspects.availableValues', []);
            const availableBillingValues = billingMethodsVM.filter(
                (billingMethod) => (availableBillingMethodsResponse.some((response) => response === billingMethod.code)))
                .map((method) => ({
                    ...method,
                    name: {
                        id: method.name,
                        defaultMessage: method.code
                    }
                }));
            if (availableBillingValues.length > 0) {
                _.set(submissionVM, 'billingMethod_PV', availableBillingValues[0].code);

                const selectedProducerCode = _.find(accountProducerCodes, (producer) => { return producer.code ===  producerCode; });
                const defaultbillingMethod = _.find(availableBillingValues, (billing) => { return billing.code === _.get(selectedProducerCode, 'defaultProducerBillingMethod_PV'); });
                if(defaultbillingMethod) {
                    _.set(submissionVM, 'billingMethod_PV', defaultbillingMethod.code);
                }

                updateSubmissionVM(submissionVM);
            }
            setAvailableBillingMethods(availableBillingValues);
        } catch {
            setAvailableBillingMethods([]);
            showAlert({
                title: gatewayMessages.modalError,
                message: gatewayMessages.gatewayViewsModalErrorData,
                status: 'error',
                icon: 'mi-error-outline'
            }).catch(_.noop);
        }
    }, [submissionVM, authHeader, updateSubmissionVM, accountProducerCodes, showAlert]);

    const refreshProducts = useCallback(async (newSubmissionVM) => {
        try {
            const submission = {
                ...newSubmissionVM.value,
                // product code is a required field and must have a value
                productCode: _.get(newSubmissionVM, 'productCode.value', ''),
                hasPredecessorPolicy_PV: false // P&V: required for submission creation
            };
            const productCodeResponse = await AccountService.getAvailableProductsForAccount(
                submission,
                authHeader
            );
            const productCodeValues = productCodeResponse;
            const productCodeAvailableValue = productCodeValues
                .filter((value) => !value.isRiskReserved)
                .map(({ productCode, productName, virtualProductCode_PV, virtualProductVersion_PV }) => {
                    return {
                        code: productCode,
                        name: `${productName} (v${virtualProductVersion_PV})`, // for the convinience includes version
                        virtualProductCode: virtualProductCode_PV,
                        virtualProductVersion: virtualProductVersion_PV
                    };
                });
            if (productCodeAvailableValue.length > 0) {
                _.set(submissionVM, 'productCode', productCodeAvailableValue[0].code);
                _.set(submissionVM, 'virtualProductCode_PV', productCodeAvailableValue[0].virtualProductCode);
                _.set(submissionVM, 'virtualProductVersion_PV', productCodeAvailableValue[0].virtualProductVersion);
                _.set(submissionVM, 'billingMethod_PV', null);
                updateSubmissionVM(submissionVM);
                const { producerCode, productCode } = submissionVM.value;
                getAvailableBillingMethod(producerCode, productCode);
            }
            setAvailableProductCodes(productCodeAvailableValue);
        } catch {
            setAvailableProductCodes([]);
            showAlert({
                title: gatewayMessages.modalError,
                message: gatewayMessages.gatewayViewsModalErrorData,
                status: 'error',
                icon: 'mi-error-outline'
            }).catch(_.noop);
        }
    }, [authHeader, submissionVM, updateSubmissionVM, getAvailableBillingMethod, showAlert]);

    useEffect(() => {
        if (!_.isUndefined(submissionVM.billingMethod_PV.value)) {
            refreshProducts(submissionVM);
        }

        if (!submissionVM.independentOrLiberalProfession_PVQuestion.value
            && submissionVM.contactType_PV.value !== 'Company') {
            submissionVM.deductVatForVehicle_PVQuestion.value = undefined;
            if (isAnExistingAccount && !submissionVM.enterpriseNumber_PV.aspects.valid) {
                submissionVM.enterpriseNumber_PV.value = undefined;
            } else if (!isAnExistingAccount) {
                submissionVM.enterpriseNumber_PV.value = undefined;
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        submissionVM?.independentOrLiberalProfession_PVQuestion?.value,
        submissionVM?.deductVatForVehicle_PVQuestion?.value
    ]);

    const writeValue = useCallback(
        (value, path) => {
            _.set(submissionVM, path, value);
            if (value && path === 'producerCode') {
                refreshProducts(submissionVM);
                _.set(accountHolderViewVM, path, value);
                updateAccountHolderVM(accountHolderViewVM);
            }
            if (path === 'productCode') {
                const foundProductCode = availableProductCodes.find((element) => element.code === value);
                if (!_.isUndefined(foundProductCode.virtualProductCode)) {
                    _.set(submissionVM, 'virtualProductCode_PV', foundProductCode.virtualProductCode);
                    _.set(submissionVM, 'virtualProductVersion_PV', foundProductCode.virtualProductVersion);
                }
                const { producerCode } = submissionVM.value;
                getAvailableBillingMethod(producerCode, value);
            }
            updateSubmissionVM(submissionVM);
        },
        [
            submissionVM,
            updateSubmissionVM,
            refreshProducts,
            getAvailableBillingMethod,
            availableProductCodes,
            accountHolderViewVM,
            updateAccountHolderVM
        ]
    );

    const overrideProps = {
        '@field': {
            labelPosition,
            showRequired,
            showErrors
        },
        producerCode: {
            availableValues:
                _.isArray(producerCodeValue) && producerCodeValue.length > 0
                    ? producerCodeValue : []
        },
        productTariff: {
            visible: !_.isEmpty(availableProductCodes),
            availableValues: availableProductCodes
        },
        collectionMethod: {
            visible: _.get(submissionVM, 'producerCode.value', false),
            availableValues: availableBillingMethods
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={setComponentValidation}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}
ProducerComponent.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({})
    }),
    updateSubmissionVM: PropTypes.func,
    labelPosition: PropTypes.string,
    showRequired: PropTypes.bool,
    accountHolderViewVM: PropTypes.shape({
        value: PropTypes.shape({})
    }),
    updateAccountHolderVM: PropTypes.func,
    producerCodeValue: PropTypes.array,
    onValidate: PropTypes.func,
    isAnExistingAccount: PropTypes.bool,
    showErrors: PropTypes.bool
};

ProducerComponent.defaultProps = {
    submissionVM: {},
    updateSubmissionVM: undefined,
    labelPosition: 'left',
    showRequired: true,
    producerCodeValue: [],
    onValidate: undefined,
    accountHolderViewVM: {},
    updateAccountHolderVM: undefined,
    isAnExistingAccount: false,
    showErrors: false
};
export default ProducerComponent;
