import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { AdaptiveDataView, DataViewColumns, GenericColumn } from '@jutro/legacy/dataview';
import { uwIssuesMessages } from 'gw-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { BrandingUtil as BrandingService } from 'pv-portals-util-js';
import { messages as platformMessagesPV } from 'pv-platform-translations';
import classes from 'classnames';import gatewayMessages from '../../gateway.messages';
import UWUtil from './UWUtil';
import metadata from './UnderwritingComponent.metadata.json5';
import styles from './UnderwritingComponent.module.scss';
import messages from './UnderwritingComponent.messages';
import CopyContractComponent from '../CopyContractComponent/CopyContractComponent';
import summaryMessage from '../../QuoteDetails/Summary/Summary.messages';
import summaryStyles from '../../Policies/Summary/Summary.module.scss';

const UWIssuesTableComponent = (props) => {
    const { uwIssues, noRecordsMessage, uwUtil } = props;
    const translator = useTranslator();

    const getBlockingPointDisplayKey = useCallback((blockingPoint) => {
        switch (blockingPoint) {
            case 'BlocksBind':
                return translator(uwIssuesMessages.blocksBind);
            case 'BlocksQuote':
                return translator(uwIssuesMessages.blocksQuote);
            case 'BlocksQuoteRelease':
                return translator(uwIssuesMessages.blocksQuoteRelease);
            case 'NonBlocking':
                return translator(uwIssuesMessages.nonBlocking);
            default:
                return blockingPoint;
        }
    }, [translator]);

    const getUWIssueStatusByOffering = useCallback(
        (uwIssue, index, id) => {
            const offering = id !== 'Status' ? id : undefined;
            const currentUWIssue = offering
                ? _.find(uwIssue.onOfferings, (offer) => {
                    return offer.offering === offering;
                })
                : uwIssue;

            let displayStatus = translator(messages.notApplicable);
            if (!currentUWIssue) {
                return displayStatus;
            }

            if (currentUWIssue.currentBlockingPoint) {
                displayStatus = getBlockingPointDisplayKey(currentUWIssue.currentBlockingPoint);
            }

            return currentUWIssue.currentBlockingPoint === 'NonBlocking'
                && currentUWIssue.hasApprovalOrRejection
                ? translator(messages.approved)
                : displayStatus;
        },
        [getBlockingPointDisplayKey, translator]
    );

    const getCell = useCallback(
        (items, index, property) => {
            const isIgnoreId = ['shortDescription', 'longDescription'].includes(property.id);
            if (!isIgnoreId) {
                return getUWIssueStatusByOffering(items, index, property.id);
            }
            return items[property.id];
        },
        [getUWIssueStatusByOffering]
    );

//     PV-19434: Short Description and Custom columns was removed
//     const getHeader = useCallback((id) => {
//         const headerInfo = {
//             shortDescription: messages.shortDescription,
//             longDescription: messages.longDescription,
//             Status: messages.jobStatusUW
//         };
//         return headerInfo[id] || id;
//     }, []);

//     PV-19444: offeringAndStatus was removed because it is not needed for P&V
//     const getTableColumns = useCallback(() => {
//         const offeringAndStatus = uwUtil.offerings || ['Status'];
//         const bothOfferings = ['shortDescription', 'longDescription', offeringAndStatus];
//         const flattenOfferings = _.uniq(_.flatten(bothOfferings));
//         const dynamicColumn = flattenOfferings.map((issueOffer) => {
//             return (
//                 <GenericColumn
//                     id={issueOffer}
//                     textAlign="left"
//                     header={getHeader(issueOffer)}
//                     cell={getCell}
//                 />
//             );
//         });
//         return dynamicColumn;
//     }, [getCell, getHeader, uwUtil.offerings]);

    const getTableColumns = useCallback(() => {
        return (
            <GenericColumn
                id="shortDescription"
                textAlign="left"
                header={translator(messages.description)}
                cell={getCell}
            />
        );
    }, [getCell]);

    return (
        <AdaptiveDataView data={uwIssues} placeholder={noRecordsMessage}><DataViewColumns>
                {getTableColumns()}
            </DataViewColumns></AdaptiveDataView>
    );
};
UWIssuesTableComponent.propTypes = {
    uwIssues: PropTypes.shape([]).isRequired,
    noRecordsMessage: PropTypes.string.isRequired,
    uwUtil: PropTypes.shape({
        offerings: PropTypes.shape([])
    }).isRequired
};

const UnderwritingComponent = (props) => {
    const {
        showAlert
    } = useModal();

    const {
        job,
        continueJob,
        onWithdrawJob,
        onEditJob,
        jobService,
        authUserData,
        authHeader,
        onUpdateJobSummary,
        hasUnderwritingIssues
    } = props;
    const translator = useTranslator();
    const [formData, updateFormData] = useState({});
    // const [showReferToUW, setShowReferToUW] = useState(false);
    const brand = BrandingService.getBrandingTheme().name;
    const uwutil = useMemo(() => UWUtil(job, authUserData.userType), []);

    const getCell = useCallback((items, index, property) => {
        return items[property.id];
    }, []);

    const showUWIssuesAlert = useCallback(() => uwutil.hasUwIssues && !['Bound', 'Withdrawn'].includes(job.statusCode),
        [job, uwutil]);

    const showMessageBox = () => {
        return job
            ? !job.isEditLocked
                // && showReferToUW
                && job.statusCode !== 'Withdrawn'
                && uwutil.uwIssuesAreBlocking
            : false;
    };

    const showUWIssuesTriggeredMsg = () => showUWIssuesAlert()
        && !job.isEditLocked
        && uwutil.uwIssuesAreBlocking
        && !uwutil.anyUWIssuesRejectedPV
        && uwutil.anyUWIssuesPendingReviewPV
        && ['Draft', 'Quoted'].includes(job.statusCode);

    const showReferredToUWMsg = useCallback(() => showUWIssuesAlert()
    && job.isEditLocked && uwutil.hasUwIssues && job.createdByMe,
    [job, showUWIssuesAlert, uwutil]);

    const showUWIssuesRejectedMsg = useCallback(() => showUWIssuesAlert()
    && !job.isEditLocked && uwutil.anyUWIssuesRejectedPV,
    [job, showUWIssuesAlert, uwutil]);

    const showUWIssuesApprovedMsg = useCallback(() => showUWIssuesAlert()
    && !job.isEditLocked && !uwutil.uwIssuesAreBlocking,
    [job, showUWIssuesAlert, uwutil]);

    const theJobStateForLock = useCallback(() => {
        return ['Withdrawn'].includes(job.statusCode);
    }, [job]);

    const pendingUWReview = useCallback(() => uwutil.anyUWIssuesPendingReviewPV, [uwutil]);

    const showUWIssuesApprovedUnderConditionMsg = useCallback(() => showUWIssuesAlert()
        && !job.isEditLocked && uwutil.uwIssuesApprovedUnderConditionPV,
    [job.isEditLocked, showUWIssuesAlert, uwutil]);

    const getNotificationContent = useCallback(() => {
        return {
            infoMessageTitle: translator(messages.uwApproved),
            infoMessageDescription: continueJob.isContinueTransaction
                ? translator(messages[`withdrawOrContinue${uwutil.jobType}`])
                : translator(messages[`withdrawThe${uwutil.jobType}`]),
            withDrawContent: translator(messages[`withdraw${uwutil.jobType}`]),
            continueContent: translator(uwutil.jobType === 'Cancellation' ? platformMessagesPV[`continue${uwutil.jobType}`] : messages[`continue${uwutil.jobType}`]),
            copySubmissionContent: translator(summaryMessage.copySubmissionQuote)
        };
    }, [continueJob.isContinueTransaction, translator, uwutil.jobType]);

    const getWithDrawInfo = useCallback(
        (isWithDraw) => {
            const { link, message } = uwutil.translateWithdrawn;
            return isWithDraw ? translator(link) : translator(message);
        },
        [translator, uwutil.translateWithdrawn]
    );

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = _.cloneDeep(formData);
            _.set(nextFormData, path, value);
            updateFormData(nextFormData);
        },
        [formData]
    );

    const referQuoteToUnderWriter = useCallback(() => {
        const { uwNoteForUnderwriter } = formData;
        const noteForUW = uwNoteForUnderwriter === '' ? null : uwNoteForUnderwriter;
        jobService.referToUnderwriter(job.jobNumber, noteForUW, authHeader).then(
            (jobResponse) => {
                // setShowReferToUW(false);
                onUpdateJobSummary(jobResponse);
            },
            () => {
                showAlert({
                    title: gatewayMessages.modalError,
                    message: messages.errorSendingJobForReview,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            }
        );
    }, [authHeader, formData, job.jobNumber, jobService, onUpdateJobSummary]);

    // const onCancelReferUW = useCallback(() => {
    //     setShowReferToUW(false);
    // }, []);

    // const onReferToUW = useCallback(() => {
    //     setShowReferToUW(true);
    // }, []);
    const isTransactionDone = useCallback(() => job && ['Bound', 'Withdrawn',
        'Proposal_pv', 'Issued'].includes(job.statusCode), [job]);

    const getNoRecordMessage = useCallback(() => {
        if (isTransactionDone()) {
            return translator(messages[`noOpenUnderwriting${uwutil.jobType}`]);
        } if (!isTransactionDone() && job.type === 'PolicyChange') {
            return translator(messages.noUWContractChange);
        } if (!isTransactionDone() && (job.quoteType === 'Full'
            || job.quoteType === 'Quick')) {
            return translator(messages.noUWTariff);
        }
    }, [isTransactionDone, job.quoteType, job.type, translator, uwutil.jobType]);

    const getJobNotesUISrefByJobTypePV = useCallback((type, jobNumber) => {
        let uisref = `/quotes/${jobNumber}/summary`;

        switch (type) {
            case 'Submission': {
                uisref = `/quotes/${jobNumber}/notes`;
                break;
            }
            case 'PolicyChange': {
                uisref = `/change/${jobNumber}/notes`;
                break;
            }
            case 'Cancellation': {
                uisref = `/cancellation/${jobNumber}/notes`;
                break;
            }
            case 'Renewal': {
                uisref = `/renewal/${jobNumber}/notes`;
                break;
            }
            default: {
                break;
            }
        }

        return uisref;
    }, []);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCell: getCell,
            handleEditJob: onEditJob,
            handleUnderwrite: referQuoteToUnderWriter,
            handleWithDraw: onWithdrawJob,
            // onCancelReferUW: onCancelReferUW,
            // onReferToUW: onReferToUW,
            writeValue: writeValue
        },
        resolveComponentMap: {
            uwissuestablecomponent: UWIssuesTableComponent,
            copycontractcomponent: CopyContractComponent
        }
    };
    const overrideProps = {
        underwritingIssues: {
            uwIssues: job.underwritingIssues,
            uwUtil: uwutil,
            noRecordsMessage: getNoRecordMessage()
        },
        uwIssuesWarningNotification: {
            job: job
        },
        quoteNotificationIsApproved: {
            notificationContent: getNotificationContent(),
            transactionVisibleActions: continueJob,
            visible: showUWIssuesApprovedMsg() && !showUWIssuesApprovedUnderConditionMsg()
                && !showUWIssuesRejectedMsg() && !pendingUWReview(),
            addUWApprovedClass: true,
            job
        },
        uwIssuesRaisedContainer: {
            visible: showUWIssuesTriggeredMsg()
        },
        referForReviewContainer: {
            visible:
                showReferredToUWMsg() && !showUWIssuesRejectedMsg() && !showUWIssuesApprovedMsg()
        },
        uwIssuesRejectedByUnderViewerContainer: {
            visible: showUWIssuesRejectedMsg() && !pendingUWReview()
        },
        uwIssuesLockedContainer: {
            visible: uwutil.isLockedToUser && !showReferredToUWMsg() && !theJobStateForLock()
        },
        referToUWPanel: {
            visible: showMessageBox()
        },
        showIssuesHeader: {
            content: translator(messages[`underwriter${uwutil.jobType}`])
        },
        underWritingIssueLine1: {
            content: translator(messages[`toCompletePointersFor${uwutil.jobType}`])
        },
        editJobForAccept: {
            content: translator(messages[`edit${uwutil.jobType}`])
        },
        reviewForUnderWritter: {
            content: translator(messages[`review${uwutil.jobType}`])
        },
        referReviewForUW: {
            content: translator(messages[`underwriterReviewing${uwutil.jobType}`])
        },
        referredToUnderwriterMessage: {
            content: translator(uwutil.getReferredToUnderwriterMessage)
        },
//         rejectedIssuesHeader: {
//             title: !uwutil.offerings
//                 ? translator(messages.rejectedByUW)
//                 : translator(messages.offerUnresolvedIssue)
//         },
        editOrReferUW: {
            content: translator(messages[`editOrRefer${uwutil.jobType}`])
        },
        withdrawLink: {
            visible: continueJob.isWithdrawTransaction,
            content: getWithDrawInfo(true)
        },
        wishToContinue: {
            content: getWithDrawInfo()
        },
        referReviewWithDrawJob: {
            visible: continueJob.isWithdrawTransaction,
            content: translator(messages[`withdraw${uwutil.jobType}`]),
            className: brand === 'vivium' ? 'actionBarUW buttonPrimary' : 'actionBarUW'
        },
        rejectReviewWithDrawJob: {
            visible: continueJob.isWithdrawTransaction,
            content: translator(messages[`withdraw${uwutil.jobType}`]),
            className: brand === 'vivium' ? 'actionBarUW buttonPrimary' : 'actionBarUW'
        },
        cancellationWithdraw: {
            visible: continueJob.isWithdrawTransaction && (uwutil.jobType === "Cancellation"
                || uwutil.jobType === 'PolicyChange'),
            content: translator(messages[`withdraw${uwutil.jobType}`]),
            className: brand === 'vivium' ? 'actionBarUW buttonPrimary' : 'actionBarUW'
        },
        copyContractButton: {
            quoteNumber: job.jobNumber,
            visible: continueJob.isCopyTransaction,
            duplicateButton: true,
            buttonType: "outlined"
        },
//         rejectEditJob: {
//             content: translator(messages[`warningEdit${uwutil.jobType}`])
//         },
//         raisedEditJob: {
//             content: translator(messages[`warningEdit${uwutil.jobType}`])
//         },
        lockedIssuesHeader: {
            content: translator(messages[`locked${uwutil.jobType}`])
        },
        referralInfo: {
            content: translator(messages[`referralInfo${uwutil.jobType}`])
        },
        wantToReferInfo: {
            content: translator(messages[`referToUW${uwutil.jobType}`])
        },
        uwApprovedUnderConditionContainer: {
            visible: showUWIssuesApprovedUnderConditionMsg()
                && !showUWIssuesRejectedMsg()
                && !pendingUWReview()
        },
        uwApprovedConditionCanContinue: {
            visible: continueJob.isContinueTransaction,
            content: translator(messages[`withdrawOrContinue${uwutil.jobType}`])
        },
        uwApprovedConditionCannotContinue: {
            visible: !continueJob.isContinueTransaction,
            content: translator(messages[`withdrawThe${uwutil.jobType}`])
        },
        uwApprovedConditionWithdraw: {
            visible: continueJob.isWithdrawTransaction,
            content: translator(messages[`withdraw${uwutil.jobType}`]),
            className: brand === 'vivium' ? 'actionBarUW buttonPrimary' : 'actionBarUW'
        },
        uwApprovedConditionContinue: {
            content: translator(messages[`continue${uwutil.jobType}`]),
            className: brand === 'pv' ? 'actionBarUW buttonPrimary' : 'actionBarUW'
        },
        warningInfosubHeading: {
            visible: false
        },
        showNotesLink: {
            visible: !_.isUndefined(job.jobNumber),
            to: getJobNotesUISrefByJobTypePV(job.type, job.jobNumber)
        },
        rejectReferToUnderwriterButton: {
            className: brand === 'pv' ? 'actionBarUW buttonPrimary' : 'actionBarUW'
        },
        referToUnderwriterButton: {
            className: brand === 'pv' ? 'actionBarUW buttonPrimary' : 'actionBarUW'
        },
        underwritingTableSection: {
            cardHeadingClassName: classes(summaryStyles.accordionHeaderSummary, 'policyTitleColor')
        },
        underwritingTableAccordion: {
            accordionStates: hasUnderwritingIssues ? ['underwritingTableSection'] : []
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={formData}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

UnderwritingComponent.propTypes = {
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
    job: PropTypes.shape({
        statusCode: PropTypes.string,
        isEditLocked: PropTypes.bool,
        createdByMe: PropTypes.bool,
        jobNumber: PropTypes.number,
        underwritingIssues: PropTypes.shape({})
    }),
    continueJob: PropTypes.shape({
        isContinueTransaction: PropTypes.bool,
        isWithdrawTransaction: PropTypes.bool,
        isCopyTransaction: PropTypes.bool
    }),
    onWithdrawJob: PropTypes.func.isRequired,
    onUpdateJobSummary: PropTypes.func.isRequired,
    onEditJob: PropTypes.func.isRequired,
    jobService: PropTypes.shape({
        referToUnderwriter: PropTypes.string
    }).isRequired,
    authUserData: PropTypes.shape({
        userType: PropTypes.string
    }).isRequired,
    hasUnderwritingIssues: PropTypes.bool
};
UnderwritingComponent.defaultProps = {
    job: {},
    continueJob: {}
};

export default withAuthenticationContext(UnderwritingComponent);
