import _ from 'lodash';
import BusinessConstant from './BusinessConstant';

function getOffering(offerings) {
    let selectedOffering;
    if (offerings.length === 1) {
        selectedOffering = offerings[0];
    } else {
        selectedOffering = offerings.find((elt) => elt.branchCode === 'CUSTOM');
    }
    return selectedOffering;
}

function findSystemOptionTerm(offerings, fixedId) {
    let systemTerm;
    const selectedOffering = getOffering(offerings);
    if (!_.isUndefined(selectedOffering)) {
        const vehicleCoverages = selectedOffering.coverages.vehicleCoverages.find((elt) => elt.fixedId === fixedId);
        if (!_.isUndefined(vehicleCoverages)) {
            const systemCompensationFormulaCoverage = vehicleCoverages.coverages.find((elt) => elt.publicID === 'PPVSystemCompFormulaFakeCovPv'
                || elt.publicID === 'PCVSystemCompFormulaFakeCovPVCV');
            if (!_.isUndefined(systemCompensationFormulaCoverage) && systemCompensationFormulaCoverage.selected) {
                systemTerm = systemCompensationFormulaCoverage.terms.find((elt) => elt.publicID === 'zciigul2h71hr7me0uu464ku1cb'
                    || elt.publicID === 'zlmieirhp2vdmfabgfc96ktd60a');
            }
        }
    }
    return systemTerm;
}

function isClassicSytemInvoiceValueSelected(offerings, fixedId) {
    const systemTerm = findSystemOptionTerm(offerings, fixedId);
    if (!_.isUndefined(systemTerm)) {
        return systemTerm.chosenTerm === 'zkeg6ppqqutntatbiaccgnscvka' || systemTerm.chosenTerm === 'ztujc3p33mevrdca2tuuldi6v79';
    }
    return false;
}

function isClassicSytemCatalogValueSelected(offerings, fixedId) {
    const systemTerm = findSystemOptionTerm(offerings, fixedId);
    if (!_.isUndefined(systemTerm)) {
        return systemTerm.chosenTerm === 'z2uiocm7chno07ebs32cos0h1v9' || systemTerm.chosenTerm === 'zlgjuqi1r6v9ofqtcfnm6uvjq79';
    }
    return false;
}

function isClassicSytemInvoiceValueSelectedPPV(offerings, fixedId) {
    return isClassicSytemInvoiceValueSelected(offerings, fixedId);
}

function isClassicSytemInvoiceValueSelectedPCV(offerings, fixedId) {
    return isClassicSytemInvoiceValueSelected(offerings, fixedId);
}

function isClassicSytemInvoiceValueSelectedQQ(offerings, fixedId) {
    return isClassicSytemInvoiceValueSelected(offerings, fixedId);
}


function isClassicSytemCatalogValueSelectedPPV(offerings, fixedId) {
    return isClassicSytemCatalogValueSelected(offerings, fixedId);
}

function isClassicSytemCatalogValueSelectedPCV(offerings, fixedId) {
    return isClassicSytemCatalogValueSelected(offerings, fixedId);
}

function isClassicSytemCatalogValueSelectedQQ(offerings, fixedId) {
    return isClassicSytemCatalogValueSelected(offerings, fixedId);
}

function isSytemOptionTermSelected(offerings, fixedId) {
    const systemTerm = findSystemOptionTerm(offerings, fixedId);
    if (!_.isUndefined(systemTerm)) {
        return systemTerm.chosenTerm === 'zkeg6ppqqutntatbiaccgnscvka' || systemTerm.chosenTerm === 'z2uiocm7chno07ebs32cos0h1v9'
            || systemTerm.chosenTerm === 'z5cj05ljea8iq4pr8gtr28cggea' || systemTerm.chosenTerm === 'ztujc3p33mevrdca2tuuldi6v79'
            || systemTerm.chosenTerm === 'zlgjuqi1r6v9ofqtcfnm6uvjq79' || systemTerm.chosenTerm === 'zrri2fk4iv6lgb601mopqkj0bqa';
    }
    return false;
}

function isSytemOptionTermSelectedPPV(offerings, fixedId) {
    return isSytemOptionTermSelected(offerings, fixedId);
}

function isSytemOptionTermSelectedPCV(offerings, fixedId) {
    return isSytemOptionTermSelected(offerings, fixedId);
}

function isSytemOptionTermSelectedQQ(offerings, fixedId) {
    return isSytemOptionTermSelected(offerings, fixedId);
}

function isSystemCompensationFormulaSelected(offerings, fixedId) {
    const selectedOffering = getOffering(offerings);
    if (!_.isUndefined(selectedOffering)) {
        const vehicleCoverages = selectedOffering.coverages.vehicleCoverages.find((elt) => elt.fixedId === fixedId);
        if (!_.isUndefined(vehicleCoverages)) {
            const systemCompensationFormulaCoverage = vehicleCoverages.coverages.find((elt) => elt.publicID === 'PPVSystemCompFormulaFakeCovPv'
                || elt.publicID === 'PCVSystemCompFormulaFakeCovPVCV');
            if (!_.isUndefined(systemCompensationFormulaCoverage) && systemCompensationFormulaCoverage.selected) {
                return true;
            }
        }
    }
    return false;
}

function isSystemCompensationFormulaSelectedPPV(offerings, fixedId) {
    return isSystemCompensationFormulaSelected(offerings, fixedId);
}

function isSystemCompensationFormulaSelectedPCV(offerings, fixedId) {
    return isSystemCompensationFormulaSelected(offerings, fixedId);
}

function isSystemCompensationFormulaSelectedQQ(offerings, fixedId) {
    return isSystemCompensationFormulaSelected(offerings, fixedId);
}

function findVehicles(vehicles, vehicleDrivers, driver) {
    if (_.isUndefined(vehicles) || _.isUndefined(vehicleDrivers) || _.isUndefined(driver)) {
        return false;
    }
    if (vehicles === undefined || vehicleDrivers === undefined || driver === undefined) {
        return false;
    }
    const vehiclesDriversMatches = vehicleDrivers.filter((elt) => ((elt.driverID !== undefined && elt.driverID === driver.fixedId) || (elt.driverTempID !== undefined && elt.driverTempID === driver.tempID)) && elt.vehicleDriverUsage !== 'NDR');
    return vehicles.filter((elt) => vehiclesDriversMatches.some((elt1) => (elt1.vehicleID !== undefined && elt1.vehicleID === elt.fixedId) || (elt1.vehicleTempID !== undefined && elt1.vehicleTempID === elt.tempID)));
}

function findPrimaryVehicle(vehicles, vehicleDrivers, driver) {
    if (vehicles === undefined || vehicleDrivers === undefined || driver === undefined) {
        return false;
    }
    const vehiclesDriversMatches = vehicleDrivers.filter((elt) => ((elt.driverID !== undefined && elt.driverID === driver.fixedId) || (elt.driverTempID !== undefined && elt.driverTempID === driver.tempID)) && elt.vehicleDriverUsage === 'PRI');
    return vehicles.filter((elt) => vehiclesDriversMatches.some((elt1) => (elt1.vehicleID !== undefined && elt1.vehicleID === elt.fixedId) || (elt1.vehicleTempID !== undefined && elt1.vehicleTempID === elt.tempID)));
}

function isDateFirstLicensedCatARequired(vehicle) {
    return (vehicle.type === 'Moto_PV' || vehicle.type === 'ClassicMoto_PV'
        || vehicle.type === 'Tricycle_PV' || vehicle.type === 'Quad_PV')
        && (vehicle.enginePower_PV > 80 || vehicle.engineCapacity_PV > 500);
}

function isRequiredDateFirstLicenseAForDriver(vehicles, vehicleDrivers, driver) {
    if (vehicles === null || vehicleDrivers === null || driver === null) {
        return false;
    }
    if (vehicles === undefined || vehicleDrivers === undefined || driver === undefined) {
        return false;
    }
    const vehiclesMatches = findVehicles(vehicles, vehicleDrivers, driver);
    return vehiclesMatches.some((elt1) => isDateFirstLicensedCatARequired(elt1));
}

function isDriverEmailMobileRequired(vehicles, vehicleDrivers, driver) {
    if (vehicles === null || vehicleDrivers === null || driver === null) {
        return false;
    }
    if (vehicles === undefined || vehicleDrivers === undefined || driver === undefined) {
        return false;
    }
    const vehiclesMatches = findPrimaryVehicle(vehicles, vehicleDrivers, driver);
    return vehiclesMatches.some((elt1) => elt1.ubi && !elt1.isUbiUpdated);
}

function isDateFirstLicensedCatBRequired(vehicle) {
    return vehicle.type === 'Car_PV' || vehicle.type === 'ClassicCar_PV'
        || vehicle.type === 'Van_PV' || vehicle.type === 'ClassicVan_PV'
        || vehicle.type === 'Minibus_PV' || vehicle.type === 'ClassicMinibus_PV'
        || vehicle.type === 'Mobilhome_PV';
}

function isRequiredDateFirstLicenseBForDriver(vehicles, vehicleDrivers, driver) {
    if (vehicles === null || vehicleDrivers === null || driver === null) {
        return false;
    }
    if (vehicles === undefined || vehicleDrivers === undefined || driver === undefined) {
        return false;
    }
    const vehiclesMatches = findVehicles(vehicles, vehicleDrivers, driver);
    return vehiclesMatches.some((elt1) => isDateFirstLicensedCatBRequired(elt1));
}

// Check for validating Mandatory Insurance question, as part of SNLBT-746 /  ALPH-6581
function isDriverAssignedTo2WheelersOnly(vehicles, vehicleDrivers, driver) {
    if (_.isUndefined(vehicles) || _.isUndefined(vehicleDrivers) || _.isUndefined(driver)) {
        return false;
    }
    return vehicles.every((elt1) => elt1.category === BusinessConstant.VEHICLE_CATEGORY_TWO_WHEELS_AND_SIMILAR);
}


function atLeastOneDriverWithMedicalConditions(drivers, medicalCondition) {
    let dri = true;

    if (medicalCondition) {
        dri = drivers.some((elt1) => {
            return !_.isUndefined(elt1.medicalDisability_PV) && elt1.medicalDisability_PV;
        });
    }

    return dri;
};

function atLeastOneDriverWithLicenseWithdrawal(drivers, medicalCondition) {
    let dri = true;

    if (medicalCondition) {
        dri = drivers.some((elt1) => {
            return !_.isUndefined(elt1.person.licenceWithdrawel_PV) && elt1.person.licenceWithdrawel_PV;
        });
    }

    return dri;
};

function atLeastOneDriverWithDriverCommitedOffense(drivers, medicalCondition) {
    let dri = true;

    if (medicalCondition) {
        dri = drivers.some((elt1) => {
            return !_.isUndefined(elt1.person.driverCommitedOffense_PV) && elt1.person.driverCommitedOffense_PV;
        });
    }

    return dri;
}


export default {
    isClassicSytemInvoiceValueSelectedPPV,
    isClassicSytemInvoiceValueSelectedPCV,
    isClassicSytemInvoiceValueSelectedQQ,

    isClassicSytemCatalogValueSelectedPPV,
    isClassicSytemCatalogValueSelectedPCV,
    isClassicSytemCatalogValueSelectedQQ,

    isSystemCompensationFormulaSelectedPPV,
    isSystemCompensationFormulaSelectedPCV,
    isSystemCompensationFormulaSelectedQQ,

    isSytemOptionTermSelectedPPV,
    isSytemOptionTermSelectedPCV,
    isSytemOptionTermSelectedQQ,

    atLeastOneDriverWithMedicalConditions,
    atLeastOneDriverWithLicenseWithdrawal,
    atLeastOneDriverWithDriverCommitedOffense,


    isRequiredDateFirstLicenseBForDriver: isRequiredDateFirstLicenseBForDriver,
    isRequiredDateFirstLicenseAForDriver: isRequiredDateFirstLicenseAForDriver,
    isDriverEmailMobileRequired: isDriverEmailMobileRequired,
    isDriverAssignedTo2WheelersOnly,
};
