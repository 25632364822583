import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useModal, Icon, Loader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { DriverUtil, CoverablesUtil, BusinessConstant as BC, CustomClassValidations, SanitizerUtil } from 'pv-portals-util-js';
import { ScrollToError } from '@jutro/legacy/wizard-next';
import DriverComponent from '../../components/DriverComponent/DriverComponent';
import DriverPageUtil from './DriverPageUtil';
import metadata from './DriversPage.metadata.json5';
import messages from './DriversPage.messages';
import styles from "./DriversPage.module.scss";


function DriversPage(props) {
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { wizardData: submissionVM, updateWizardData, wizardSnapshot: initialSubmissionVM } = props;
    const [quickQuoteMode] = useState(_.get(submissionVM, 'baseData.quoteType.value.code') === 'Quick');
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
  	const defaultActiveTab = 'driver0driverList';
    const [activeTab, setActiveTab] = useState(defaultActiveTab);
    const [showErrors, setShowErrors] = useState(false);
    const [errorTimestamp, setErrorTimestamp] = useState(0);
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        registerInitialComponentValidation,
        disregardFieldValidation
    } = useValidation('DriverPage');

    // NOTE: workaround. for unknown reason Jutro/React still render 1time DriverComponent and AddressComponent even
    // all drivers are removed
    const [dataUpdating, setDataUpdating] = useState(false);
    const [jobType] = useState(_.get(submissionVM, 'baseData.jobType.value.code')); // Submission vs. PolicyChange
    const [isPolicyChange] = useState(jobType === BC.JOB_TYPE_POLICY_CHANGE);
    const [productCode] = useState(_.get(submissionVM, 'baseData.productCode.value'));
    const [brand] = useState(_.get(submissionVM, 'baseData.value.brand_PV'));
    const [lobVmPath] = useState(productCode === BC.PCV_PROD_CODE ? `lobData.${BC.PCV_LOB_CODE}` : `lobData.${BC.PPV_LOB_CODE}`);
    const [isCommercial] = useState(productCode === BC.PCV_PROD_CODE);
    const { validVehicleDrivers } = DriverPageUtil();

    // we don't want modify/add not valid persons to original list and fail DTO validations on backend
    const [availableContacts, setAvailableContacts] = useState( _.clone(_.get(submissionVM, `${lobVmPath}.coverables.availableContactsOnAccount.value`) ) );

    // does not make sense to use caching/useState... whole submissionVM changes brutally often anyway
    const coverablesVm = _.get(submissionVM, `${lobVmPath}.coverables`);

    const activeTabIdx = activeTab.match(/\d/)[0];
    const selectedDriverVm = _.get(coverablesVm, `drivers.children[${activeTabIdx}]`);

    const { showAlert, showConfirm } = useModal();

    useEffect(() => {

        // NOTE: data update in React is asynch and several useEffect[] will override each other unless updateWizardData(fn) is used
        updateWizardData((oldVm) => {
            // change context
            const context = {
                'Submission': {
                    IsPackScreen: false,
                    ContractQuestions: false,
                    AdditionalQuestions: false,
                    SummaryQuestions: false,
                    DriverDetails: true
                },
                'PolicyChange': {
                    IsAgent: true,
                    preQuote: true,
                    DriverDetails: true
                }
            };
            const newVm = viewModelService.changeContext(oldVm, context[jobType]);
            return newVm;
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        // NOTE: data update in React is asynch and several useEffect[] will override each other unless updateWizardData(fn) is used
        updateWizardData((oldVm) => {
            // sanitize/fix drivers
            const newVm = _.clone(oldVm);
            const coverables = _.get(newVm, `${lobVmPath}.coverables.value`);
            if (coverables.drivers.length > 0) {
                coverables.drivers.forEach((driver) => {
                    // run sanitizer
                    SanitizerUtil.sanitisePrefillDataFromQuickQuote(driver.person);

                    // fix missing default values
                    DriverUtil.restoreMissingDefaultValues(driver);

                    // auto-require license B for persons which are driving cars
                    if (CustomClassValidations.isRequiredDateFirstLicenseBForDriver(coverables.vehicles, coverables.vehicleDrivers, driver)) {
                        // eslint-disable-next-line camelcase
                        driver.person.hasDrivingLicenseB_PV = true;
                    }

                });
            }
            if (isPolicyChange) {
                if (coverables.drivers.length === 1) {
                    CoverablesUtil.setDriverUsageForVehicles(coverables, coverables.drivers[0], true /* isPrimary */);
                }
            }
            return newVm;
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const shouldPageSkip = useCallback(() => {
        return (_.get(submissionVM, `${lobVmPath}.coverables.drivers.children.length`, 0) > 0);
    }, [lobVmPath, submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(shouldPageSkip);
    }, [registerInitialComponentValidation, shouldPageSkip]);

    const isDriversLimitReached = useCallback((drivers) => {
        return drivers.length < 6;
    }, []);

    const onTabChange = useCallback((selectedTabID) => {
        setActiveTab(selectedTabID);
    }, []);

    const onNext = useCallback(async () => {
        const checkDrivingLicenseA = () => {
            const drivers = _.get(submissionVM, `${lobVmPath}.coverables.drivers`);
            if (drivers) {
                const coverables = _.get(submissionVM, `${lobVmPath}.coverables`);
                for (const [index, driver] of drivers.children.entries()) {
                    if (CustomClassValidations.isRequiredDateFirstLicenseAForDriver(coverables.vehicles.value, coverables.vehicleDrivers.value, driver.value)) {
                        // eslint-disable-next-line camelcase
                        if (!!driver.person.dateFirstLicenseA_PV.value === false) {
                            return index;
                        };
                    }
                }
            }
            return -1;
        };

        const getDriverWithNameAndDOBChanged = () => {
            const initialDrivers = _.get(initialSubmissionVM, `${lobVmPath}.coverables.drivers`);
            const drivers = _.get(submissionVM, `${lobVmPath}.coverables.drivers`);
            return drivers.children
                .filter((d) => d.fixedId.value)
                .findIndex((driver) => {
                    const initialDriver = initialDrivers.children.find((d) => d.fixedId?.value === driver.fixedId?.value);
                    return initialDriver.person.firstName.value !== driver.person.firstName.value &&
                        initialDriver.person.lastName.value !== driver.person.lastName.value &&
                        !_.isEqual(initialDriver.person.dateOfBirth.value, driver.person.dateOfBirth.value);
                });
        };

        const resetDriverNameAndDOB = (driverIdx) => {
            const driver = _.get(submissionVM, `${lobVmPath}.coverables.drivers.children[${driverIdx}]`);
            const initialDriver = _.get(initialSubmissionVM, `${lobVmPath}.coverables.drivers.children`)
                .find((d) => d.fixedId.value === driver.fixedId.value);
            driver.person.firstName.value = initialDriver.person.firstName.value;
            driver.person.lastName.value = initialDriver.person.lastName.value;
            driver.person.dateOfBirth.value = initialDriver.person.dateOfBirth.value;
        };

        setShowErrors(true);

        // 0. check if least 1 driver created, but actually needed
        if (coverablesVm.drivers.children.length === 0 && !validVehicleDrivers(coverablesVm.value, isCommercial)) {
            setErrorTimestamp(Date.now());
            return false;
        }

        // 1. do not switch to 1st invalid TAB, but stay on current invalid
        if (!selectedDriverVm.aspects.valid || !selectedDriverVm.aspects.subtreeValid) {
            setErrorTimestamp(Date.now());
            return false;
        }

        // 2. check other tabs now ...
        let firstNotValidDriverIdx = coverablesVm.drivers.children.findIndex((driverVm) => !driverVm.aspects.valid || !driverVm.aspects.subtreeValid);
        firstNotValidDriverIdx = firstNotValidDriverIdx !== -1 ? firstNotValidDriverIdx : checkDrivingLicenseA();
        if (firstNotValidDriverIdx !== -1) {
            setActiveTab(`driver${firstNotValidDriverIdx}driverList`);
            setErrorTimestamp(Date.now());
            return false;
        }

        // 3. check assignments PRI & SEC
        if (!validVehicleDrivers(coverablesVm.value, isCommercial)) {
            setErrorTimestamp(Date.now());
            return false;
        }

        // 4. PolicyChange: Check for simultanously changed firstname, lastname and date of birth
        const driverIdx = isPolicyChange ? getDriverWithNameAndDOBChanged() : -1;
        if (driverIdx !== -1) {
            showAlert({
                title: commonMessages.genericError,
                message: messages.errorNameAndDOBChanged,
                status: 'error',
                icon: 'mi-error-outline',
            }).then(() => {
                resetDriverNameAndDOB(driverIdx);
                setActiveTab(`driver${driverIdx}driverList`);
                setErrorTimestamp(Date.now());
            }).catch(_.noop);
            return false;
        };

        if (!isComponentValid) {
            setErrorTimestamp(Date.now());
            return false;
        }

        submissionVM.value = await LoadSaveService.updateDraftSubmission(submissionVM.value, authHeader);

        return submissionVM;
    }, [isComponentValid, selectedDriverVm, coverablesVm, validVehicleDrivers, submissionVM, initialSubmissionVM, LoadSaveService, authHeader, isCommercial, lobVmPath, showAlert, isPolicyChange]);


    const onAddNewDriver = useCallback((personPublicId) => {

        // NOTE: data update in React is asynch and several useEffect[] will override each other unless updateWizardData(fn) is used
        updateWizardData((oldSubmissionVM) => {
            // new driver is pure and do not show errors immediately
            setShowErrors(false);

            const newSubmissionVM = _.clone(oldSubmissionVM); // let's trigger mass re-render
            const coverables = _.get(newSubmissionVM, `${lobVmPath}.coverables.value`);

            const personFromAccountContacts = availableContacts.find((ac) => ac.publicID === personPublicId);
            const newPerson = CoverablesUtil.createPerson(personFromAccountContacts);

            const newDriver = CoverablesUtil.createDriver(coverables, newPerson);
            DriverUtil.setDefaultValues(newDriver);
            if (coverables.drivers.length === 1) {
                // 1st driver immediately becomes PRIMARY driver for all vehicles
                CoverablesUtil.setDriverUsageForVehicles(coverables, newDriver, true /* isPrimary */);
            } else {
                // all the rest drivers auto-assigned to a single vehicle (if 1 vehicle only)
                CoverablesUtil.tryAssignNonMainDriverRelation(coverables, newDriver);
            }

            setAvailableContacts(_.without(availableContacts, personFromAccountContacts));
            setActiveTab(`driver${coverables.drivers.length - 1}driverList`);

            return newSubmissionVM;
        });

    }, [availableContacts, lobVmPath, updateWizardData]);


    const onRemoveDriver = useCallback((index) => {

        const driver = _.get(submissionVM, `${lobVmPath}.coverables.drivers.children[${index}].value`);

        showConfirm({
            title: messages.removeDriverTitle,
            message: translator(messages.removeDriverDescription, { driver: DriverUtil.getShortDisplayName(driver, translator) }),
            status: 'warning',
            icon: 'mi-error-outline'
        }).then(
            (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }

                setDataUpdating(true);

                if (!_.isNil(driver.person.publicID)) {
                    // return Person to available list if it is persistent contact
                    // eslint-disable-next-line camelcase
                    driver.person.dateFirstLicensed_PV = driver.dateFirstLicensed_PV;
                    setAvailableContacts((oldAvailableContact) => {
                        // should return contact to original position in array ?
                        oldAvailableContact.push(driver.person);
                        return oldAvailableContact;
                    });
                }
                setActiveTab(`driver0driverList`); // NOTE: do this as early as possible to avoid racing and redrawing non-existing driver

                disregardFieldValidation(`driver${index}`);
                disregardFieldValidation(`driverDetails${index}`);

                const newSubmissionVM = _.clone(submissionVM); // let's trigger mass re-render
                const coverables = _.get(newSubmissionVM, `${lobVmPath}.coverables.value`);
                CoverablesUtil.removeDriver(coverables, driver);

                updateWizardData(newSubmissionVM);

                setDataUpdating(false);
            });
    }, [disregardFieldValidation, submissionVM, updateWizardData, translator, lobVmPath, showConfirm]);


    const getTabHeader = useCallback((driverVm, isCurrentTab, index) => {
        const driverName = DriverUtil.getShortDisplayName(driverVm.value, translator);
        return (
            <React.Fragment>
                <span>{driverName}&nbsp;</span>
                {isCurrentTab && (
                    <Icon icon="mi-delete" onClick={(event) => { event.stopPropagation(); onRemoveDriver(index); }}/>
                )}
            </React.Fragment>
        );
    }, [translator, onRemoveDriver]);


    const onIsMainDriverChange = useCallback((driverPath, value) => {

        const newSubmissionVM = _.clone(submissionVM); // let's trigger mass re-render
        const driver = _.get(newSubmissionVM, `${driverPath}.value`);
        const coverables = _.get(newSubmissionVM, `${lobVmPath}.coverables.value`);

        // reset insurance and claim questions
        driver.hasOwnInsurance = true;
        driver.isCurrentlyARegularDriver = undefined;
        driver.insuranceEndTime = undefined;
        driver.hasClaimAttestLast3Years = true;
        driver.insuranceEndTime = undefined;

        CoverablesUtil.setDriverUsageForVehicles(coverables, driver, value);

        updateWizardData(newSubmissionVM);
    }, [lobVmPath, submissionVM, updateWizardData]);


    const onCopyMainAddressToDriver = useCallback((driverPath) => {
        const mainAddressCopy = _.clone(_.get(submissionVM, 'baseData.accountHolder.primaryAddress.value', {}));
        _.unset(mainAddressCopy, 'publicID'); // !!! we don't want re-use the same address entity in DB !!!
        _.set(submissionVM, `${driverPath}.person.value.primaryAddress`, mainAddressCopy);
        updateWizardData(submissionVM);
    }, [updateWizardData, submissionVM]);


    const onAssignmentVehicleToNonMainDriverChange = useCallback((vehiclesIds, driverPath) => {

        const newSubmissionVM = _.clone(submissionVM); // let's trigger mass re-render
        const driver = _.get(newSubmissionVM, `${driverPath}.value`);
        const coverables = _.get(newSubmissionVM, `${lobVmPath}.coverables.value`);
        const vehicles = _.get(newSubmissionVM, `${lobVmPath}.coverables.vehicles.value`);

        vehicles.forEach((vehicle) => {
            const isAssigned = vehiclesIds.includes(vehicle.fixedId);
            CoverablesUtil.updateVehicleToNonMainDriverRelation(coverables, vehicle, driver, isAssigned);
        });

        updateWizardData(newSubmissionVM);
    }, [lobVmPath, submissionVM, updateWizardData]);


    useEffect(() => {
        const numberOfDrivers = _.get(submissionVM, `${lobVmPath}.coverables.drivers.children.length`);
        if (!isCommercial && numberOfDrivers === 0) {
            // be kind and pre-add accountholder, please
            const accountHolderPublicId = submissionVM.baseData.accountHolder.publicID.value;
            onAddNewDriver(accountHolderPublicId);
        }
        // it must run only once, at the beginning
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const generateAccountContactsOverrides = useCallback(() => {

        const overrideProps = {};
        availableContacts.forEach((contact, index) => {
            overrideProps[`accountContact${index}`] = {
                content: `${contact.displayName}`,
                onClick: () => onAddNewDriver(contact.publicID)
            };
        });
        return overrideProps;

    }, [availableContacts, onAddNewDriver]);


    const generateDriversOverrides = useCallback(() => {

        const overrideProps = {};
        coverablesVm.drivers.children.forEach((driverVm, index) => {

            overrideProps[`driver${index}`] = {
                heading: getTabHeader(driverVm, `driver${index}driverList` === activeTab, index)
            };
            overrideProps[`driverDetails${index}`] = {
                brand,
                jobType,
                isCommercial,
                onCopyMainAddressToDriver,
                quickQuoteMode,
                onValidate,
                allCoverables: coverablesVm.value,
                onIsMainDriverChange,
                onAssignmentVehicleToNonMainDriverChange
            };

        });
        return overrideProps;

    }, [jobType, brand, isCommercial, onValidate, onIsMainDriverChange, coverablesVm, quickQuoteMode, getTabHeader, activeTab, onCopyMainAddressToDriver, onAssignmentVehicleToNonMainDriverChange]);

    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        accountContactList: {
            disabled: !isDriversLimitReached(coverablesVm.drivers.children),
            data: availableContacts,
        },
        newDriverContact: {
            disabled: !isDriversLimitReached(coverablesVm.drivers.children),
            onClick: onAddNewDriver // NOTE: no publicID is provided, so no AccountContact will be found
        },
        driverList: {
          	defaultActiveTab: defaultActiveTab,
            activeTab: activeTab,
            path: `${lobVmPath}.coverables.drivers.children`,
            onTabChange: onTabChange
        },
        ...generateAccountContactsOverrides(),
        ...generateDriversOverrides()
    };

    // NOTE: workaround
    if (dataUpdating) {
        return <Loader loaded={false}/>;
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddNewDriver,
            onRemoveDriver,
            onTabChange,
            onValidate
        },
        resolveComponentMap: {
            DriverComponent
        }
    };
    return (
        <WizardPage
            onNext={onNext}
            showCancel={isPolicyChange}
            skipWhen={initialValidation}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                showErrors={showErrors}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
            <ScrollToError counter={errorTimestamp} timeout={200}/>
        </WizardPage>
    );
}

DriversPage.propTypes = wizardProps;
export default DriversPage;
