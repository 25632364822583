import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class PVFinancialInstitutionService {

    static searchFinancialCompaniesUsingPartialName(partialName, brand, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('financial'),
            'searchFinancialCompaniesUsingPartialName_PV',
            [partialName, brand],
            additionalHeaders
        );
    }
}
