import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import VehicleInfo from './VehicleInfo/VehicleInfo';
import metadata from './VehiclesDetails.metadata.json5';
import styles from './VehiclesDetails.module.scss';
import './VehiclesDetails.messages.js';

function VehiclesDetails(props) {
    const {
        submissionVM,
        pageLoBType,
        quickQuoteMode
    } = props;

    const generatVehiclesOverrides =  useCallback(() => {
        const vehicles = _.get(submissionVM, `lobData.${pageLoBType}.coverables.vehicles.value`, []);
        const overrides = vehicles.map((vehicle, index) => ({
            [`vehicleDetails${index}`]: {
                heading: `${_.get(vehicle, "make")} ${_.get(vehicle, "model")}`
            },
            [`vehicle${index}`]: {
                submissionVM,
                pageLoBType,
                quickQuoteMode
            },
        }));

        return Object.assign({}, ...overrides);
    }, [submissionVM, pageLoBType, quickQuoteMode]);

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        vehiclesList: {
            defaultActiveTab: 'vehicleDetails0vehiclesList',
            path: `lobData.${pageLoBType}.coverables.vehicles.children`
        },
        ...generatVehiclesOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            VehicleInfoComponent: VehicleInfo
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

VehiclesDetails.propTypes = {
    data: PropTypes.shape({}),
    submissionVM: PropTypes.shape({}),
    pageLoBType: PropTypes.string,
    quickQuoteMode: PropTypes.bool
};

VehiclesDetails.defaultProps = {
    data: {},
    submissionVM: {},
    pageLoBType: undefined,
    quickQuoteMode: false
};

export default VehiclesDetails;
