import { defineMessages } from 'react-intl';

export default defineMessages({
    ppvIntro: {
        id: "quoteandbind.ppv.views.ppv-e-signature.Intro",
        defaultMessage: "For next steps, choose the signature and payment method for your client's first fracture. Attention, for online payment, an immediate online signature is required. To complete these steps, your customer will need his mobile phone and credit or debit card."
    },
    pcvIntro: {
        id: "quoteandbind.pcv.views.pcv-e-signature.Intro",
        defaultMessage: "To handle the next steps efficiently, choose the sign and payment method for your customer. Attention: For an on-line payment an immediate electronic signature is required. Have the customer keep his telephone and credit or debit card at hand.",
    },
    signingOfTheDocuments: {
        id: "quoteandbind.pcv.views.pcv-e-signature.Signing of the documents.",
        defaultMessage: "Signing of the documents",
    },
    electronic_online: {
        id: "quoteandbind.pcv.views.pcv-e-signature.SignatureType Online",
        defaultMessage: "Sign online",
    },
    electronic_online_tooltip: {
        id: "quoteandbind.pcv.views.pcv-e-signature.SignatureType electronic_online.Tooltip",
        defaultMessage: "Sign online: the documents are signed immediately by sms /e-ID.",
    },
    electronic_deferred: {
        id: "quoteandbind.pcv.views.pcv-e-signature.SignatureType Deferred",
        defaultMessage: "Sign deferred via email",
    },
    electronic_deferred_tooltip: {
        id: "quoteandbind.pcv.views.pcv-e-signature.SignatureType electronic_deferred.Tooltip",
        defaultMessage: "Electronic deferred: the client receives by mail a link to sign the documents by sms / e-ID.",
    },
    paper_signature: {
        id: "quoteandbind.pcv.views.pcv-e-signature.SignatureType Paper",
        defaultMessage: "Sign on paper",
    },
    paper_signature_tooltip: {
        id: "quoteandbind.pcv.views.pcv-e-signature.SignatureType paper_signature.Tooltip",
        defaultMessage: "Sign on paper: the documents are send by the system where you have the choice to have them signed on paper or by your own application.",
    },
    firstOnlinePayment: {
        id: "quoteandbind.ppv.views.ppv-e-signature.First online payment.",
        defaultMessage: "First online payment",
    },
    online_bancomat_payment_tooltip: {
        id: "quoteandbind.pcv.views.pcv-e-signature.PaymentType online_bancomat_payment.Tooltip",
        defaultMessage: "Online payment via Bancontact: the payment of the first invoice is executed immediately by scanning a QR-code.",
    },
    deferred_payment_tooltip: {
        id: "quoteandbind.pcv.views.pcv-e-signature.PaymentType deferred_payment.Tooltip",
        defaultMessage: "Deferred payment: the client receives a link by mail to pay the first invoice by Bancontact / Visa / Mastercard.",
    },
    no_online_payment_tooltip: {
        id: "quoteandbind.pcv.views.pcv-e-signature.PaymentType no_online_payment.Tooltip",
        defaultMessage: "No-online payment: the client will pay by bank transfer or domiciliation (sepa).",
    },
    sign: {
        id: "quoteandbind.ppv.views.ppv-e-signature.Sign",
        defaultMessage: "Sign",
    },
    pay: {
        id: "quoteandbind.ppv.views.ppv-e-signature.Pay",
        defaultMessage: "Pay",
    },
    carryOn: {
        id: "quoteandbind.ppv.views.ppv-e-signature.Continue",
        defaultMessage: "Carry on",
    },
    continueWithoutOnlinePayment: {
        id: "quoteandbind.ppv.views.ppv-e-signature.Continue without online payment",
        defaultMessage: "Continue without online payment",
    },
    headerSubmission: {
        id: "quoteandbind.pcv.views.pcv-e-signature.A few more minutes and you will be insured.",
        defaultMessage: "A few more minutes and you will be insured."
    },
    headerPolicyChange: {
        id: "endorsement.common.views.payment.Final steps before completing the policy change.",
        defaultMessage: "Final steps before completing the contract change."
    }
});
