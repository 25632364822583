import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import appConfig from 'app-config';

import PostalCodesMockData from '../MockApi/PostalCode.json5';
import StreetsMockData from '../MockApi/Street.json5';

const SN_ADDRESS_LOOKUP = 'addressLookup';
const { gatewayParamConfig: { enableAddressSearchMock, enablePostcodeSearchMock } } = appConfig;

export default class AddressLookupService {
    static lookupAddressUsingStringAndFilterByPostalCode(
        addressStr,
        postalCode,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl(SN_ADDRESS_LOOKUP),
            'lookupAddressUsingStringAndFilterByPostalCode',
            [addressStr, postalCode],
            additionalHeaders
        );
    }

    static lookupAddressUsingString(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl(SN_ADDRESS_LOOKUP), 'lookupAddressUsingString', [data], additionalHeaders);
    }

    // P&V Functions

    static getListPostalCodeCity(additionalHeaders = {}) {

        if (!enablePostcodeSearchMock) {
            return JsonRPCService.send(getProxiedServiceUrl(SN_ADDRESS_LOOKUP), 'retrievePostalCity', [], additionalHeaders);
        }

        // TODO remove mock data
        return Promise.resolve(PostalCodesMockData.result.data);
    }

    static autofillBasedOnPostalCode(address, additionalHeaders = {}) {

        if (!enableAddressSearchMock) {
            return JsonRPCService.send(getProxiedServiceUrl(SN_ADDRESS_LOOKUP), 'lookupAddressUsingObject', [address], additionalHeaders);
        }

        // TODO remove mock data
        return Promise.resolve(StreetsMockData.result);
    }
}
