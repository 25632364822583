import { defineMessages } from 'react-intl';

export default defineMessages({
    titleLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Has the payment of this invoice been received by the broker',
        defaultMessage: 'Has the payment of this invoice been received by the broker?'
    },
    okLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Ok',
        defaultMessage: 'Ok'
    },
});
