import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class PVPortimaService {

    static triggerBlocRetourPV(quoteID, policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('portima'),
            'triggerBlocRetour_PV',
            [quoteID, policyNumber],
            additionalHeaders
        );
    }

    static retrieveLemonLearningBaseUrl(isBrokerTypePortima, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('portima'),
            'retrieveLemonLearningBaseUrl',
            [isBrokerTypePortima],
            additionalHeaders
        );
    }

}
