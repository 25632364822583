import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { IntlContext } from '@jutro/locale';
import styles from './ClauseComponent.module.scss';

import { CheckboxField, CurrencyField } from '@jutro/legacy/components';

/**
 * @memberof gw-components-platform-react.ClauseComponent
 * @prop {Object} propTypes - the props that are passed to this component
 * @prop {string} propTypes.displayName - name of clause to display
 * @prop {bool} propTypes.readOnly - if the clause selection is read only
 * @prop {bool} propTypes.value - is the clause is seleceted
 * @prop {function} propTypes.onValueChange - callback when change is made
 * @prop {string} propTypes.path - path to value in the view modal
 * @prop {bool} propTypes.isLoading - should the clause be loading
 * @prop {string} propTypes.loadingMessage - message to be shown while loading
 * @prop {string} propTypes.containerClassName - clause container class
 * @prop {bool} propTypes.showAmount - determine to show amount next to displayName
 *
 * @returns {Node} the Clause component DOM Node
 */
function ClauseComponent({
    isEditable,
    displayName,
    amount,
    readOnly,
    value,
    id,
    isLoading,
    loadingMessage,
    children,
    path,
    checked,
    description,
    containerClassName,
    showAmount,
    onValueChange,
    // P&V
    coverageContent,
    isAClauseLoading
}) {
    const intlContext = useContext(IntlContext);
    const handleChange = useCallback((changedValue) => {
        if (onValueChange) {
            onValueChange(changedValue, path);
        }
    }, [onValueChange, path]);

    // P&V update to display amount outside label
    const formattedCurrency = useMemo(() => {
        if (!_.isUndefined(amount) && amount.amount > 0 && showAmount) {
            return intlContext.formatNumber(
                amount.amount,
                {
                    style: 'currency',
                    currency: amount.currency,
                    currencyDisplay: 'symbol'
                }
            );
        }
        return null;
    }, [amount, showAmount, intlContext]);

    const hasChildren = !_.isNil(children);
    const clauseContainerStyles = classNames(styles.clause, containerClassName);
    const checkboxControlStyles = classNames({
        [styles.clauseAndTermSpacing]: hasChildren
    });
    const checkboxStyles = classNames({
        [styles.clauseNoPadding]: !hasChildren
    });

    return isEditable ? (
        <div className={clauseContainerStyles}>
            {isLoading ? (
                <Loader loaded={!isLoading} text={loadingMessage} />
            ) : (
                <>
                    <div className={styles.coverageAmount}>
                        <span>{formattedCurrency}</span>
                    </div>
                    <CheckboxField
                        id={id}
                        label={displayName}
                        value={value}
                        readOnly={readOnly}
                        onValueChange={handleChange}
                        className={checkboxStyles}
                        controlClassName={checkboxControlStyles}
                        path={path}
                        checked={checked}
                        showInlineLabel
                        tooltip={!_.isUndefined(description) ? { text: description } : null}
                        layout="full-width"
                        disabled={isAClauseLoading /* P&V */}
                    >
                        {coverageContent} { /* P&V */ }
                        {value ? children : null}
                    </CheckboxField>
                </>
            )}
        </div>
    ) : (
        <div className={styles.readOnlyGrid}>
            <span className={styles.readOnlyGridSlotLeft}>
                {displayName}
                <span className={styles.readOnlyGridAmount}>
                    {!_.isUndefined(amount) ? (
                        <CurrencyField
                            id={id}
                            value={amount}
                            showFractions
                            readOnly
                        />
                    ) : undefined}
                </span>
            </span>
            <div className={styles.readOnlyGridSlotRight}>{children}</div>
        </div>
    )
}

ClauseComponent.propTypes = {
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    value: PropTypes.bool,
    path: PropTypes.string,
    isLoading: PropTypes.bool,
    loadingMessage: PropTypes.string,
    checked: PropTypes.bool,
    isEditable: PropTypes.bool,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    containerClassName: PropTypes.string,
    amount: PropTypes.shape({}),
    showAmount: PropTypes.bool,
    coverageContent: PropTypes.element
};

ClauseComponent.defaultProps = {
    readOnly: false,
    path: undefined,
    value: undefined,
    isLoading: false,
    loadingMessage: '',
    checked: false,
    children: undefined,
    isEditable: true,
    description: undefined,
    containerClassName: undefined,
    amount: undefined,
    showAmount: true,
    coverageContent: null
};

export default ClauseComponent;
