/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import { Icon } from '@jutro/components';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import cx from 'classnames';
import metadata from './PremiumInfo.metadata.json5';
import styles from './PremiumInfo.module.scss';
import messages from './PremiumInfo.messages';

const renderIcon = (item) => {
    return <div className={cx(
        item.isCategoryNameRow && styles.markedRow, !item.isCategoryNameRow && styles.nonMarkedRow
    )}>
        {item.coverageSelected && <Icon icon='mi-done' />}
    </div>;
};

const renderFormattedCurrencyField = (item, index, { id: property }) => {
    return (
        <div className={cx(item.isCategoryNameRow && styles.markedRow, !item.isCategoryNameRow && styles.nonMarkedRow)}>
            {item[property] &&
                <CurrencyField
                    id={`currency_${index}`}
                    value={item[property]}
                    readOnly
                    hideLabel
                    className={cx(item.isCategoryNameRow && styles.currencyContainer)}
                />
            }
        </div>
    );
};

const hideDeductibleWhenEmtpy = (coverage) => {
    return coverage.terms.length > 0 && !coverage.terms[0].chosenTermValue && coverage.terms[0].patternCode === 'PPVThirdPartyLiabilityCovPvDeductible';
};

function PremiumInfo(props) {
    const {
        data: vehicleCoverages,
        labelPosition
    } = props;

    const translator = useTranslator();

    const renderCell = useCallback((item, index, { id: property }) => {
        return <div className={cx(
            item.isCategoryNameRow && styles.markedRow, !item.isCategoryNameRow && styles.nonMarkedRow
        )}><span>{_.isObject(item[property]) ? translator(item[property]) : item[property]}</span></div>;
    });

    const getTableData = useCallback(() => {
        const selectedCoverages = _.get(vehicleCoverages, 'coverages', []).filter((c) => c.selected === true);
        const clauseCategory = _.groupBy(selectedCoverages, 'coverageCategoryCode');
        const data = Object.values(clauseCategory)
            .map((category) => {
                let categoryCoverageDetails;
                const isSystemCompFormulaSelected = category.filter((c) => c.publicID === 'PPVSystemCompFormulaFakeCovPv' || c.publicID === 'PCVSystemCompFormulaFakeCovPVCV');
                if(!_.isEmpty(isSystemCompFormulaSelected)){
                    const cov = isSystemCompFormulaSelected[0];
                    const myVehicleSystem = cov.terms[0].chosenTermValue;
                    let compensationFormula = cov.terms[1].chosenTermValue;
                    let myVehicleSystemAmount;
                    if(isSystemCompFormulaSelected[0].terms.length === 3) {
                        myVehicleSystemAmount = cov.terms[1].chosenTermValue;
                        compensationFormula = cov.terms[2].chosenTermValue;
                    }

                    categoryCoverageDetails =  (<Fragment>
                        <span className={styles.displayBlock}>
                            {myVehicleSystem}
                            {myVehicleSystemAmount  && `: ${myVehicleSystemAmount}`}
                        </span>
                        <span>{translator(messages.compensationFormula)} : {compensationFormula}</span>
                    </Fragment>);
                }
                const categoryRow = {
                    isCategoryNameRow: true,
                    coverageName: messages[`label${category[0].coverageCategoryCode}`],
                    coverageDetails: categoryCoverageDetails
                };

                const coverageNetPremium = { amount: 0, currency: 'eur'};
                const coverageSpreadCosts = { amount: 0, currency: 'eur'};
                const coverageTax = { amount: 0, currency: 'eur'};
                let coverageAmount = 0;

                const newCoverages = category.filter((c) => c.publicID !== 'PPVSystemCompFormulaFakeCovPv' && c.publicID !== 'PCVSystemCompFormulaFakeCovPVCV');

                const categoryCovs = newCoverages.map((coverage) => {
                    let coverageDetails;
                    if(!hideDeductibleWhenEmtpy(coverage) && coverage.terms.length > 0 && coverage.terms[0].chosenTermValue){
                        coverageDetails = `${_.get(coverage, 'terms.0.name')} ${_.get(coverage, 'terms.0.chosenTermValue')}`;
                    }

                    // start calculation for grand total
                    coverageNetPremium.amount += coverage.commercialPremium_PV?.amount;
                    coverageSpreadCosts.amount += coverage.spreadCosts_PV?.amount;
                    coverageTax.amount += coverage.tax_PV?.amount;
                    coverageAmount += coverage.amount?.amount;
                    // end

                    const coverageData = [];

                    coverageData.push({
                        isCategoryNameRow: false,
                        isInclusiveRow: false,
                        coverageSelected: coverage.selected,
                        coverageName: coverage.name,
                        coverageDetails: coverageDetails,
                        coverageNetPremium: coverage.commercialPremium_PV,
                        spreadCosts: coverage.spreadCosts_PV,
                        tax: coverage.tax_PV,
                        amount: coverage.amount
                    });
                    if(coverage.publicID === 'PPVThirdPartyLiabilityCovPv' || coverage.publicID === 'PCVThirdPartyLiabilityCovPvCV') {
                        // adding inclusive acquistion and commission costs for category
                        coverageData.push({
                            isCategoryNameRow: false,
                            isInclusiveRow: true,
                            coverageSelected: undefined,
                            coverageName: messages.inclusiveAcquistionAndCommissionCosts,
                            coverageDetails: undefined,
                            coverageNetPremium: { amount: coverage.commissionCosts_PV?.amount + coverage.acquisitionCosts_PV?.amount, currency: 'eur'},                            spreadCosts: undefined,
                            tax: undefined,
                            amount: undefined,
                        });
                    } else{
                        // adding inclusive acquistion costs for category
                        coverageData.push({
                            isCategoryNameRow: false,
                            isInclusiveRow: true,
                            coverageSelected: undefined,
                            coverageName: messages.inclusiveAcquistionCosts,
                            coverageDetails: undefined,
                            coverageNetPremium: coverage.acquisitionCosts_PV,
                            spreadCosts: undefined,
                            tax: undefined,
                            amount: undefined,
                        });

                        // adding inclusive administration costs for category
                        coverageData.push({
                            isCategoryNameRow: false,
                            isInclusiveRow: true,
                            coverageSelected: undefined,
                            coverageName: messages.inclusiveAdministrationCosts,
                            coverageDetails: undefined,
                            coverageNetPremium: coverage.administrativeCosts_PV,
                            spreadCosts: undefined,
                            tax: undefined,
                            amount: undefined,
                        });
                    }
                    return coverageData;
                });
                const newcategoryCovs = _.flatten(categoryCovs);

                // adding total amount for category to the table
                const totalCategoryCovs = {
                    isCategoryNameRow: true,
                    coverageName:messages[`total${category[0].coverageCategoryCode}`],
                    coverageDetails: undefined,
                    coverageNetPremium:coverageNetPremium,
                    spreadCosts: coverageSpreadCosts,
                    tax: coverageTax,
                    amount: { amount: coverageAmount.toFixed(2), currency: 'eur'}
                };

                const resultData = [categoryRow, ...newcategoryCovs, totalCategoryCovs];


                if(category[0].coverageCategoryCode === 'PPVMyPassengersTheOthersAndIGrp' || category[0].coverageCategoryCode === 'PCVMyPassengersTheOthersAndIGrpCV') {
                    resultData.push({
                        isCategoryNameRow: false,
                        coverageName: undefined,
                        coverageDetails: undefined
                    });

                    resultData.push({
                        isCategoryNameRow: true,
                        coverageName: translator(messages.additionalCoverages),
                        coverageDetails: undefined
                    });
                }


                return resultData;
            });

        // adding Grand total row to table
        data.push({
            isCategoryNameRow: true,
            coverageName: messages.grandTotal,
            coverageDetails: undefined,
            coverageNetPremium: undefined,
            spreadCosts: undefined,
            tax: undefined,
            amount: _.get(vehicleCoverages, 'vehiclePremium')
        });
        return _.flatten(data);
    });

    const overrideProps = {
        '@field': {
            labelPosition
        },
        details: {
            data: getTableData()
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            renderCell,
            renderIcon,
            renderFormattedCurrencyField
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleCoverages}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={styles}
        />
    );
}

PremiumInfo.propTypes = {
    data: PropTypes.shape({}),
    labelPosition: PropTypes.string
};

PremiumInfo.defaultProps = {
    data: {},
    labelPosition: 'top'
};

export default PremiumInfo;
