import React from 'react';

export type VehicleTypeIconProps = { selected: boolean };

export const CarIcon = ({
    selected,
}: VehicleTypeIconProps): React.ReactElement => {
    const color = selected ? '#fff' : '#000';
    const bgColor = selected ? '#00739d' : '#fff';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 0 24 24" width="48px" fill="#000000">
            <path fill={bgColor} d="M0 0h24v24H0V0z" />
            <path fill={color} d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01l-1.97 5.67c-.07.21-.11.43-.11.66v7.16c0 .83.67 1.5 1.5 1.5S6 20.33 6 19.5V19h12v.5c0 .82.67 1.5 1.5 1.5.82 0 1.5-.67 1.5-1.5v-7.16c0-.22-.04-.45-.11-.66l-1.97-5.67zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.27-3.82c.14-.4.52-.68.95-.68h9.56c.43 0 .81.28.95.68L19 11H5z" /></svg>
    );
};

export const VanIcon = ({
    selected,
}: VehicleTypeIconProps): React.ReactElement => {
    const color = selected ? '#fff' : '#000';

    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="44.75px" height="44.75px" viewBox="0 0 44.75 44.75">
            <path fill={color} d="M37.409,18.905l-4.946-7.924c-0.548-0.878-1.51-1.411-2.545-1.411H3c-1.657,0-3,1.343-3,3v16.86c0,1.657,1.343,3,3,3h0.787
                    c0.758,1.618,2.391,2.75,4.293,2.75s3.534-1.132,4.292-2.75h20.007c0.758,1.618,2.391,2.75,4.293,2.75
                    c1.9,0,3.534-1.132,4.291-2.75h0.787c1.656,0,3-1.343,3-3v-2.496C44.75,22.737,41.516,19.272,37.409,18.905z M8.087,32.395
                    c-1.084,0-1.963-0.879-1.963-1.963s0.879-1.964,1.963-1.964s1.963,0.88,1.963,1.964S9.171,32.395,8.087,32.395z M26.042,21.001
                    V15.57v-2.999h3.876l5.264,8.43H26.042z M36.671,32.395c-1.084,0-1.963-0.879-1.963-1.963s0.879-1.964,1.963-1.964
                    s1.963,0.88,1.963,1.964S37.755,32.395,36.671,32.395z"/>
        </svg>
    );
};

export const MobileHomeIcon = ({
    selected,
}: VehicleTypeIconProps): React.ReactElement => {
    const color = selected ? '#fff' : '#000';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 20 20" height="48px" viewBox="0 0 20 20" width="48px" fill="#000000">
            <g>
                <rect fill="none" height="20" width="20" />
            </g>
            <g>
                <path fill={color} d="M17,9.05v-1.7c0-0.23-0.08-0.45-0.22-0.62L13.3,2.38C13.11,2.14,12.82,2,12.52,2H2C1.45,2,1,2.45,1,3v8c0,0.55,0.45,1,1,1 h1.05c0.23,1.14,1.24,2,2.45,2s2.22-0.86,2.45-2h6.1c0.28,1.38,1.69,2.34,3.22,1.89c0.77-0.23,1.39-0.85,1.62-1.62 C19.34,10.74,18.38,9.33,17,9.05z M5.5,13C4.67,13,4,12.33,4,11.5S4.67,10,5.5,10S7,10.67,7,11.5S6.33,13,5.5,13z M7,7H2V3.5 C2,3.22,2.22,3,2.5,3H7V7z M12,11H8V8h1.5C9.78,8,10,7.78,10,7.5v0C10,7.22,9.78,7,9.5,7H8V3h3.5C11.78,3,12,3.22,12,3.5V11z M13,7 V3.6L15.72,7H13z M16.5,13c-0.83,0-1.5-0.67-1.5-1.5s0.67-1.5,1.5-1.5s1.5,0.67,1.5,1.5S17.33,13,16.5,13z" />
            </g>
        </svg>
    );
};
export const MinibusIcon = ({
    selected,
}: VehicleTypeIconProps): React.ReactElement => {
    const color = selected ? '#fff' : '#000';
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 0 24 24" width="48px" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path fill={color} d="M22.41 10.41l-4.83-4.83c-.37-.37-.88-.58-1.41-.58H3c-1.1 0-2 .89-2 2v7c0 1.1.9 2 2 2 0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3c1.1 0 2-.9 2-2v-2.17c0-.53-.21-1.04-.59-1.42zM3 10V8c0-.55.45-1 1-1h3v4H4c-.55 0-1-.45-1-1zm3 7.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25zM13 11H9V7h4v4zm5 6.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25zM15 11V7h1l4 4h-5z" />
        </svg>
    );
};

export const OtherIcon = ({
    selected,
}: VehicleTypeIconProps): React.ReactElement => {
    const color = selected ? '#fff' : '#000';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 0 24 24" width="48px" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path fill={color} d="M21 17h-1v-6c0-1.1-.9-2-2-2H7v-.74c0-.46-.56-.7-.89-.37L4.37 9.63c-.2.2-.2.53 0 .74l1.74 1.74c.33.33.89.1.89-.37V11h4v3H5c-.55 0-1 .45-1 1v2c0 1.1.9 2 2 2h2c0 1.66 1.34 3 3 3s3-1.34 3-3h7c.55 0 1-.45 1-1s-.45-1-1-1zm-10 3c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm7-6h-4v-3h3c.55 0 1 .45 1 1v2zm-8-8h7v.74c0 .46.56.7.89.37l1.74-1.74c.2-.2.2-.53 0-.74l-1.74-1.74c-.33-.33-.89-.1-.89.37V4h-7c-.55 0-1 .45-1 1s.45 1 1 1z" />
        </svg>
    );


};
