import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentDetails: {
        id: 'quoteandbind.pcv.directives.templates.pcv-payment-frequency-method.Payment Details',
        defaultMessage: 'Payment Details'
    },
    collectionMethod: {
        id: 'endorsement.pcv.directives.templates.pcv-prequote-payment-edit.Collection Method',
        defaultMessage: 'Collection Method'
    },
    paymentMethod: {
        id: 'endorsement.ppv.directives.templates.ppv-prequote-payment-edit.Payment Method',
        defaultMessage: 'Payment Method'
    },
    paymentPlan: {
        id: 'endorsement.ppv.directives.templates.ppv-prequote-payment-edit.Payment Plan',
        defaultMessage: 'Payment Plan'
    }
});
