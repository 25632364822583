import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { BusinessConstant } from 'pv-portals-util-js';

import metadata from './PaymentDetailsComponent.metadata.json5';
import './PaymentDetailsComponent.messages';

const transformTypelistValues = (values) =>
    values.map((key) => ({
        ...key,
        name: key.code ? { id: key.name, defaultMessage: key.code } : key.name,
        code: key.code || key.billingId
    }));

function PaymentDetailsComponent(props) {
    const {
        value: jobVM,
        id,
        onValidate,
        onValueChange,
        showRequired,
        labelPosition,
        phoneWide,
        disabled,
        showErrors,
        isParentPageInitialized,
        onPaymentPlanChange,
        onUpdateShowRecalculateButton,
        onUpdateGettingPaymentDetails
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const { authHeader } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');

    const [availableBillingMethods, updateAvailableBillingMethods] = useState([]);
    const [availablePaymentMethods, updateAvailablePaymentMethods] = useState([]);
    const [availablePaymentPlans, updateAvailablePaymentPlans] = useState([]);

    const isJobSubmission = _.get(jobVM, 'baseData.jobType.value.code') === BusinessConstant.JOB_TYPE_SUBMISSION;
    const isBroker = jobVM.baseData.brand_PV.value.code === BusinessConstant.PV_BRAND_VIVIUM;

    const paymentDetailsPath = isJobSubmission ? '' : 'bindData_PV.paymentDetails.';
    const paymentMethodPath = `${paymentDetailsPath}paymentMethod_PV`;
    const jobIdPath = isJobSubmission ? 'quoteID' : 'jobID';
    const jobId = _.get(jobVM.value, jobIdPath);
    const producerCodePath = `${isJobSubmission ? '' : 'baseData.'}producerCode`;
    const isMajorPolicyChange = _.get(jobVM.value, 'migratedPolicyData_PV.isMajorPolicyChange_PV', false);

    const retrievePaymentPlansCall = isBroker
        ? LoadSaveService.retrievePaymentPlansForBroker_PPV
        : LoadSaveService.retrievePaymentPlans_PV;

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid);
        }
    }, [onValidate, isComponentValid]);

    const updatePaymentPlans = useCallback(async () => {
        const billingMethod = _.get(jobVM.value, 'baseData.billingMethod_PV');
        const paymentMethod = _.get(jobVM.value, paymentMethodPath);

        try {
            onUpdateGettingPaymentDetails(true);
            const paymentList = await retrievePaymentPlansCall(jobId, billingMethod, paymentMethod, authHeader);

            const selectedPaymentPlan = _.get(jobVM, 'selectedPaymentPlan.value');
            if (
                !isMajorPolicyChange &&
                _.get(jobVM.value, 'migratedPolicyData_PV.isClosedPopulation_PV') &&
                !_.isUndefined(selectedPaymentPlan)
            ) {
                const foundPaymentPlan = paymentList.find((plan) => plan.billingId === selectedPaymentPlan.billingId);
                if (_.isUndefined(foundPaymentPlan)) {
                    paymentList.push(selectedPaymentPlan);
                }
            }

            const paymentPlan = paymentList.find(({ billingId }) => billingId === _.get(selectedPaymentPlan, 'billingId'));
            if (_.isUndefined(paymentPlan) || (!isBroker && isMajorPolicyChange)) {
                onValueChange(paymentList[0], 'selectedPaymentPlan');
            }

            updateAvailablePaymentPlans(transformTypelistValues(paymentList));
        } finally {
            onUpdateGettingPaymentDetails(false);
        }
    }, [
        jobVM,
        paymentMethodPath,
        onUpdateGettingPaymentDetails,
        retrievePaymentPlansCall,
        jobId,
        authHeader,
        isMajorPolicyChange,
        isBroker,
        onValueChange
    ]);

    const updatePaymentMethods = useCallback(() => {
        const paymentMethods = _.get(jobVM, `${paymentMethodPath}.aspects.availableValues`);

        if (isBroker) {
            const billingMethod = _.get(jobVM, 'baseData.billingMethod_PV.value.code');
            if (BusinessConstant.BillingMethodListForProducerBill.includes(billingMethod)) {
                const bankTransfer = paymentMethods.find((value) => value.code === BusinessConstant.PAYMENT_METHOD_BANK);
                onValueChange(bankTransfer, paymentMethodPath);
                updateAvailablePaymentMethods(transformTypelistValues([bankTransfer]));
            } else {
                // NOTE: do not do any other default PaymentMethod selection
                updateAvailablePaymentMethods(transformTypelistValues(paymentMethods));
            }
        } else {
            // NOTE: do not do any other default PaymentMethod selection
            updateAvailablePaymentMethods(transformTypelistValues(paymentMethods));
        }

        updatePaymentPlans();
    }, [jobVM, paymentMethodPath, isBroker, updatePaymentPlans, onValueChange]);

    const getBillingMethods = useCallback(async () => {
        const paymentMethod = _.get(jobVM.value, paymentMethodPath);
        const producerCode = _.get(jobVM.value, producerCodePath);
        try {
            onUpdateGettingPaymentDetails(true);
            const response = await LoadSaveService.getAvailableBillingMethodForProducerCode_PV(
                producerCode,
                jobId,
                paymentMethod,
                authHeader
            );
            const billingMethods = response.map((billingMethod) =>
                jobVM.baseData.billingMethod_PV.aspects.availableValues.find(
                    (typecodeBillingMethod) => typecodeBillingMethod.code === billingMethod
                )
            );

            if (isJobSubmission && _.isUndefined(jobVM.baseData.billingMethod_PV.value)) {
                onValueChange(billingMethods[0], 'baseData.billingMethod_PV');
            } else if (isMajorPolicyChange) {
                const newBillingMethod = response.map(() =>
                    jobVM.baseData.billingMethod_PV.aspects.availableValues.find(
                        (item2) => item2.code === BusinessConstant.BILLING_METHOD_PRODUCER_BILL_DEFAULT_PRODUCER
                    )
                );
                onValueChange(newBillingMethod, 'baseData.billingMethod_PV');
            }
            updateAvailableBillingMethods(transformTypelistValues(billingMethods));
            updatePaymentMethods();
        } catch (error) {
            // Only update flag on error, if flow continues successfully flag will be updated later after payment plans are updated
            onUpdateGettingPaymentDetails(false);
        }
    }, [
        LoadSaveService,
        authHeader,
        isMajorPolicyChange,
        jobId,
        jobVM,
        onUpdateGettingPaymentDetails,
        onValueChange,
        paymentMethodPath,
        producerCodePath,
        updatePaymentMethods,
        isJobSubmission
    ]);

    useEffect(() => {
        if (isParentPageInitialized) {
            getBillingMethods();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isParentPageInitialized]);

    const handleValueChange = useCallback(
        (value, path) => {
            onValueChange(value, path);
            if (_.get(jobVM, 'baseData.periodStatus.value.code') === 'Quoted') {
                onUpdateShowRecalculateButton(true);
            }
        },
        [onValueChange, jobVM, onUpdateShowRecalculateButton]
    );

    const onChangeBillingMethod = useCallback(
        (value, path) => {
            handleValueChange(value, path);
            updatePaymentMethods();
        },
        [handleValueChange, updatePaymentMethods]
    );

    const onChangePaymentMethod = useCallback(
        (value, path) => {
            handleValueChange(value, path);
            updatePaymentPlans();
        },
        [handleValueChange, updatePaymentPlans]
    );

    const onChangePaymentPlan = useCallback(
        (value, path) => {
            const selectedPlan = availablePaymentPlans.find((plan) => plan.billingId === value);
            onPaymentPlanChange(value);
            handleValueChange(selectedPlan, path);
        },
        [availablePaymentPlans, handleValueChange, onPaymentPlanChange]
    );

    const overrideProps = {
        '@field': {
            showRequired,
            labelPosition,
            phoneWide,
            disabled,
            showErrors
        },
        billingMethod: {
            disabled:
                _.get(jobVM, 'baseData.billingMethod_PV.value.code') ===
                    BusinessConstant.BILLING_METHOD_DIRECT_BILL_DEFAULT_FOR_THIS_POLICY || availableBillingMethods.length === 1,
            availableValues: availableBillingMethods
        },
        paymentMethod: {
            value: _.get(jobVM, `${paymentMethodPath}.value.code`),
            path: paymentMethodPath,
            availableValues: availablePaymentMethods
        },
        selectedPaymentPlan: {
            value: _.get(jobVM, 'selectedPaymentPlan.value.billingId'),
            availableValues: availablePaymentPlans
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate,
            onChangeBillingMethod,
            onChangePaymentMethod,
            onChangePaymentPlan
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={jobVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PaymentDetailsComponent.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    showRequired: PropTypes.bool.isRequired,
    labelPosition: PropTypes.string.isRequired,
    phoneWide: PropTypes.shape({}).isRequired,
    disabled: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired,
    isParentPageInitialized: PropTypes.bool.isRequired,
    onPaymentPlanChange: PropTypes.func,
    onUpdateShowRecalculateButton: PropTypes.func,
    onUpdateGettingPaymentDetails: PropTypes.func.isRequired
};

PaymentDetailsComponent.defaultProps = {
    onPaymentPlanChange: (x) => x,
    onUpdateShowRecalculateButton: (x) => x
};

export default PaymentDetailsComponent;
