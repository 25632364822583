import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Loader } from '@jutro/components';
import { useDependencies, DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { Wizard } from '@xengage/gw-portals-wizard-react';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { useWizardInit, useWizardExit, useWizardError } from 'pv-capability-quoteandbind-common-react';

import wizardConfig from './config/qqppv-wizard-config.json5';

function QQPPVWizard(props) {
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { QuickQuoteService } = useDependencies('QuickQuoteService');
    const { viewModelService, history, location } = props;

    const vmContext = {
        AccountEmailRequired: false,
        DriverEmailRequired: true,
        AccountDOBRequired: false,
        IsAgent: true
    };

    // hook to initialize the wizard
    const { submissionVM, wizardSteps, wizardTitle, skipCompletedSteps } = useWizardInit({
        location,
        history,
        viewModelService,
        setIsLoading,
        wizardConfig,
        dtoPath: 'be.pvgroup.pc.edge.capabilities.quickquote.dto.QuickQuoteDTO_PV',
        vmContext,
        LoadSaveService: QuickQuoteService
    });

    // handles modals when exiting/cancelling the flow
    const handleCancel = useWizardExit({ hasErrorOccurred, history });

    // handles error modals
    const handleError = useWizardError({ location, setHasErrorOccurred });

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!submissionVM) {
        return null;
    }

    return (
        <ErrorBoundary onError={handleError}>
            <DependencyProvider
                value={{
                    LoadSaveService: QuickQuoteService
                }}
            >
                <Wizard
                    initialSteps={wizardSteps}
                    wizardTitle={wizardTitle}
                    initialData={submissionVM}
                    onCancel={handleCancel}
                    skipCompletedSteps={skipCompletedSteps}
                />
            </DependencyProvider>
        </ErrorBoundary>
    );
}

QQPPVWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            quoteentry: PropTypes.shape({
                postalCode: PropTypes.string,
                quoteID: PropTypes.string
            })
        })
    }).isRequired
};

export default withRouter(withViewModelService(QQPPVWizard));
