import { defineMessages } from 'react-intl';

export default defineMessages({
    pack: {
        id: 'quoteandbind.quickquote.common.views.quick-quote-pack.Pack',
        defaultMessage: 'Pack'
    },
    coverages: {
        id: "endorsement.ppv.directives.templates.ppv-whats-next.Coverages",
        defaultMessage: "Coverages"
    },
    startDate: {
        id: 'quoteandbind.common.views.quote-details.Start Date',
        defaultMessage: 'Start Date'
    },
    apply: {
        id: 'quoteandbind.common.views.quote-details.Apply',
        defaultMessage: 'Apply'
    },
    effectiveDateNoMore7days: {
        id: 'pv.quoteanbind.page.pack.The policy effective date cannot be more than 7 working days in the past.',
        defaultMessage: 'The policy effective date cannot be more than 7 working days in the past.'
    },
    warningChangeStartDate: {
        id: 'quoteandbind.common.views.quote-details.Warning Change Start Date',
        defaultMessage: 'Be aware that changing the start date might have an impact on the premium and the coverage.'
    },
    invalidCoverageOptionTitle: {
        id: 'integration.quoteandbind.directives.custom-quote.Invalid Coverage Option',
        defaultMessage: 'Invalid Coverage Option'
    },
    invalidCoverageOptionMsg: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Deductibles for Theft and Material Damage should always be equal',
        defaultMessage: 'Deductibles for Theft and Material Damage should always be equal.'
    },
    coverageOptionIssueTitle: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.CommonOfferingSelection.Invalid Coverage Option',
        defaultMessage: 'Invalid Coverage Option'
    },
    coverageOptionIssueMsg: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.CommonOfferingSelection.Sorry, but that coverage option is invalid, please select a different value',
        defaultMessage: 'Sorry, but that coverage option is invalid, please select a different value'
    },
    syncingCoverageDependencies: {
        id: 'policycommon.directives.coverages.Syncing Coverage Dependencies',
        defaultMessage: 'Syncing Coverage Dependencies'
    }
});
