import React, { useCallback } from 'react';
import { Chevron } from '@jutro/components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import HoPolicyCoverageTermsIterableComponent from '../../../../Components/HoPolicyCoverageTermsIterableComponent/HoPolicyCoverageTermsIterableComponent';
import styles from '../../Summary.module.scss';
import metadata from './PolicyCoverage.metadata.json5';
import messages from './PolicyCoverage.messages';

function PolicyCoverage({ value }) {
    const translator = useTranslator();

    const getHeaderInfo = useCallback((isOpen) => {
        return (
            <React.Fragment>
                <Chevron isOpen={isOpen} className={styles.chevronStyle} />
                <h2 className={`${styles.title} ${styles.gwOverrideDefaultMargin}`}>
                    {translator(messages.coverages)}
                </h2>
            </React.Fragment>
        );
    }, [translator]);

    const generateScheduledItemsCoverageOverrides = useCallback(() => {
        const overrides = value.scheduledItems.map((coverage, cindex) => {
            return {
                [`scheduledItemsCoverageTerm${cindex}`]: {
                    label: coverage.description,
                    value: coverage.value
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [value.scheduledItems]);
    const overrides = {
        coveragesDetailsCard: {
            renderHeader: getHeaderInfo
        },
        sectionICoverables: {
            visible: !_.isEmpty(value.sectionICoverages)
        },
        sectionIICoverables: {
            visible: !_.isEmpty(value.sectionIICoverages)
        },
        additionalLiabilityCoverables: {
            visible: !_.isEmpty(value.additionalLiabilityCoverages)
        },
        additionalPropertyCoverables: {
            visible: !_.isEmpty(value.additionalPropertyCoverages)
        },
        scheduledItemsCoverables: {
            visible: !_.isEmpty(value.scheduledItems)
        },
        sectionICoverableTitle: {
            content: !_.isEmpty(value.sectionICoverages)
                ? value.sectionICoverages[0].coverageCategory
                : undefined
        },
        sectionIICoverableTitle: {
            value: !_.isEmpty(value.sectionIICoverages)
                ? value.sectionIICoverages[0].coverageCategory
                : undefined
        },
        additionalLiabilityCoverableTitle: {
            value: !_.isEmpty(value.additionalLiabilityCoverages)
                ? value.additionalLiabilityCoverages[0].coverageCategory
                : undefined
        },
        additionalPropertyCoverableTitle: {
            value: !_.isEmpty(value.additionalPropertyCoverages)
                ? value.additionalPropertyCoverages[0].coverageCategory
                : undefined
        },
        scheduledItemsCoverableTitle: {
            value: !_.isEmpty(value.scheduledItems)
                ? translator(messages.scheduledItems)
                : undefined
        },
        ...generateScheduledItemsCoverageOverrides()
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            hopolicycoveragetermsiterablecomponent: HoPolicyCoverageTermsIterableComponent
        }
    };
    const readValue = (id, path) => {
        return readViewModelValue(metadata.pageContent, value, id, path, overrides);
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={value}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            resolveValue={readValue}
        />
    );
}

PolicyCoverage.propTypes = {
    value: PropTypes.shape({
        scheduledItems: PropTypes.shape([]),
        sectionICoverages: PropTypes.shape([]),
        sectionIICoverages: PropTypes.shape([]),
        additionalLiabilityCoverages: PropTypes.shape([]),
        additionalPropertyCoverages: PropTypes.shape([])
    }).isRequired
};

export default PolicyCoverage;
