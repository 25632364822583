import { defineMessages } from 'react-intl';

export default defineMessages({
    new: {
        id: 'app.html.index.Quote',
        defaultMessage: 'New'
    },
    forThisAccount: {
        id: 'app.html.index.for this account',
        defaultMessage: 'For this account'
    },
    forAnyAccount: {
        id: 'app.html.index.for any account',
        defaultMessage: 'For any account'
    }
});
