import { defineMessages } from 'react-intl';

export default defineMessages({
    selectedvehicle: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Selected vehicle',
        defaultMessage: 'Selected vehicle'
    },
    generalInformation: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.General information',
        defaultMessage: 'General information'
    },
    licenseplateoptional: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.License plate (optional)',
        defaultMessage: 'License plate'
    },
    vINnumberoptional: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.VIN number (optional)',
        defaultMessage: 'VIN'
    },
    make: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Make',
        defaultMessage: 'Make'
    },
    model: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Model',
        defaultMessage: 'Model'
    },
    fuelType: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Fuel Type',
        defaultMessage: 'Fuel Type'
    },
    detailsofthevehicle: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Details of the vehicle',
        defaultMessage: 'Details of the vehicle'
    },
    whatisthepowerofyourvehiclekW: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Engine Power',
        defaultMessage: 'What is the power of your vehicle? (kW)'
    },
    whatistheweightofyourvehiclekg: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.What is the weight of your vehicle? (kg)',
        defaultMessage: 'What is the weight of your vehicle? (kg)'
    },
    isthisvehicleamotocross: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Will you use your moto for off-road',
        defaultMessage: 'Is this vehicle a moto-cross?'
    },
    yes: {
        id: 'quoteandbind.pcv.views.pcv-driver-details.Yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'quoteandbind.pcv.views.pcv-driver-details.No',
        defaultMessage: 'No'
    },
    firstregistrationdate: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.First registration date',
        defaultMessage: 'First registration date'
    },
    firstOwner: {
        id: "quoteandbind.ppv.directives.templates.ppv-edit-vehicle.First owner",
        defaultMessage: "First owner"
    },
    firstOwnerTooltip: {
        id: "quoteandbind.ppv.directives.templates.ppv-edit-vehicle.First owner.toolTip",
        defaultMessage: "The first owner of a vehicle is the owner of the vehicle at first registration"
    },
    soldByOfficial: {
        id: "quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Sold by official vendor",
        defaultMessage: "Sold by an official vendor"
    },
    soldByOfficialTooltip: {
        id: "quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Official vendor.toolTip",
        defaultMessage: "Professional seller subject to VAT and having its head office in Belgium"
    },
    thisvehicleisnotyetincirculation: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.This vehicle is not yet in circulation',
        defaultMessage: 'This vehicle is not yet in circulation'
    },
    securitysystemsinstalled: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Security system(s) installed',
        defaultMessage: 'Security system(s) installed'
    },
    automaticemergencybraking: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.security-system.AutomaticEmergencyBraking',
        defaultMessage: 'Automatic emergency braking'
    },
    lanedeparturewarningsystem: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.security-system.LaneDepartureWarningSystem',
        defaultMessage: 'Lane departure warning system'
    },
    driverdrowsinessdetection: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.security-system.DriverDrowsinessDetection',
        defaultMessage: 'Driver drowsiness detection'
    },
    adaptivecruisecontrol: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.security-system.AdaptiveCruiseControl',
        defaultMessage: 'Adaptive cruise control'
    },
    blindSpotSystem: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.security-system.BlindSpotSystem',
        defaultMessage: 'Blind Spot System'
    },
    cyclistsandpedestriansdetectionsystem: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.security-system.CyclAndPedestrDetectSystem',
        defaultMessage: 'Cyclists and pedestrians detection system'
    },
    usageofthevehicle: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Usage of the vehicle',
        defaultMessage: 'Usage of the vehicle'
    },
    primaryUse: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Primary Use',
        defaultMessage: 'Primary Use'
    },
    primaryUseOther: {
        id: 'quoteandbind.pcv.primaryuse.other',
        defaultMessage: 'Other'
    },
    numberofkilometersperyear: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Number of kilometers per year',
        defaultMessage: 'Number of kilometers per year'
    },
    doesthevehiclehaveasidecar: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Does the vehicle have a sidecar?',
        defaultMessage: 'Does the vehicle have a sidecar?'
    },
    semiPrivateTrips: {
        id: "quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Do you use your vehicle for semi-private trips, such as volunteering?",
        defaultMessage: "Do you use your vehicle for semi-private trips, such as volunteering?"
    },
    semiPrivateTripsTooltip: {
        id: "quoteandbind.pcv.directives.templates.ppv-edit-vehicle.Do you use your vehicle for semi-private trips, such as volunteering?.tooltip",
        defaultMessage: "If the answer is in the affirmative, a clause aimed at respecting the rights of volunteers (law of 03/07/2005) is included in the contract."
    },
    designatedDriverInfoText: {
        id: "quoteandbind.pcv.directives.templates.pcv-edit-vehicle.This value is set for all vehicles of this policy on the first vehicles tab",
        defaultMessage: "This value is set for all vehicles of this policy on the first vehicle's tab."
    }
});
