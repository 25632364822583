import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { TranslatorContext } from "@jutro/locale";
import metadata from "./Clauses.metadata.json5";
import messages from "./Clauses.messages.js";
import styles from "./Clauses.module.scss";

class Clauses extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        fromAccountLanding: PropTypes.shape({
            policyDetailsData: PropTypes.shape({
                policyResponse: PropTypes.shape({
                    policyNumber: PropTypes.string,
                    account: PropTypes.shape({
                        accountNumber: PropTypes.string,
                    }),
                }),
            }),
        }).isRequired
    };

    state = {
        clauseData: [],
        generalClauseData: [],
    };

    componentDidMount = () => {
        this.getClauseSummaryInfo();
    };

    getClauseSummaryInfo = () => {
        const { fromAccountLanding } = this.props;
        const policyDetailsData = _.get(
            fromAccountLanding,
            "policyDetailsData"
        );
        const policyResponse = _.get(policyDetailsData, "policyResponse");
        const lobs = _.get(policyResponse, "lobs");
        const ppvPersonalAuto = _.get(lobs, "ppvPersonalAuto_PV");
        const pcvCommercialAuto = _.get(lobs, "pcvCommercialAuto_PV");
        const vehicleDTOs = !_.isEmpty(ppvPersonalAuto)
            ? _.get(ppvPersonalAuto, "vehicleDTOs")
            : _.get(pcvCommercialAuto, "vehicleDTOs");
        const generalClauses = !_.isEmpty(ppvPersonalAuto)
            ? _.get(ppvPersonalAuto, "generalClauses_PV")
            : _.get(pcvCommercialAuto, "generalClauses_PV");
        if (!_.isEmpty(generalClauses)) {
            const generalClausesTable = this.getGeneralClausesTable(generalClauses);
            this.setState({
                generalClauseData: generalClausesTable,
            });
        }
        if (!_.isEmpty(vehicleDTOs)) {
            this.setState({
                clauseData: vehicleDTOs,
            });
        }
    };

    getClauseType = (item) => {
        const translator = this.context;
        return translator({
            id: `typekey.ClausePatternsType_PV.${item.type}`,
            defaultMessage: item.type,
        });
    };

    getGeneralClausesTable = (generalClauses) => {
        let generalClausesArrayResult = [];
        generalClausesArrayResult = generalClauses.map((data) => {
            const objData = {
                code: data.code,
                type: this.getClauseType(data),
                title: data.title,
            };
            return objData;
        });
        return generalClausesArrayResult;
    };

    getHeader = (data) => {
        const translator = this.context;
        if (_.isUndefined(data.licensePlate)) data.licensePlate = "";
        return `${translator(messages.applicable)} ${data.make} ${
            data.model
        } (${data.licensePlate})`;
    };

    overridesClausesApplicableTable = (clauseData) => {
        const overrides =
            clauseData &&
            clauseData.map((data, index) => {
                return {
                    [`clausesApplicableDataTable${index}`]: {
                        data: data.clauses_PV,
                    },
                    [`clausesApplicableTitle${index}`]: {
                        content: this.getHeader(data),
                    },
                };
            });
        return Object.assign({}, ...overrides);
    };

    render() {
        const { clauseData, generalClauseData } = this.state;

        const overrides = {
            generalClauseDataTable: {
                data: generalClauseData,
            },
            clauseIterable: {
                data: clauseData,
            },
            ...this.overridesClausesApplicableTable(clauseData),
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: { getClauseType: this.getClauseType },
        };

        const clausePage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;

        return <div>{clausePage}</div>;
    }
}

export default Clauses;
