import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useModal } from '@jutro/components';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { messages as pvPlatformMessages} from 'pv-platform-translations';
import { useTranslator } from '@jutro/locale';


const useWizardExit = ({ 
    hasErrorOccurred,
    history
}) => {
    const { showConfirm } = useModal();
    const [ confirmedNextPage, setConfirmedNextPage ] = useState();
    const translator = useTranslator();

    const handleOnCancel = useCallback(({ wizardSnapshot, param: nextPath = '' }) => {
        const status = wizardSnapshot.value?.status;
        const jobID = wizardSnapshot.value?.jobID;
        const policyNumber = _.get(wizardSnapshot.value, 'policyNumber');
        if ((status === 'Bound' && !_.isNil(jobID) && !history.location.pathname.includes('/policychange')) || nextPath === confirmedNextPage || (nextPath.startsWith('/change') || nextPath.startsWith('/contactAgent')) || hasErrorOccurred) {
            return true;
        }
        return showConfirm({
            title: platformMessages.wantToCancel,
            message: translator(pvPlatformMessages.infoWillBeSavedAsDraft),
            status: 'warning',
            icon: 'mi-error-outline'
        }).then((results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            let redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/change/${jobID}/summary`;
            if (!jobID) {
                redirectLobPath = `/policies/${policyNumber}/summary`;
            }

            setConfirmedNextPage(redirectLobPath);
            history.push(redirectLobPath);
            return true;
        });
    }, [history, confirmedNextPage, hasErrorOccurred, showConfirm, translator]);

    return handleOnCancel;
};

export default useWizardExit;
