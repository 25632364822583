const { CustomClassValidations } = require('pv-portals-util-js');
const customValidations = require('./Utils/CustomValidations');

/* eslint-disable camelcase */
/** ****************************************************
 *                                                    *
 *   EXAMPLE: Adding a new compiler kind              *
 *   Can also overwrite out of the box                *
 *     const: compileConst,                           *
 *     parameter: compileParameter,                   *
 *     getfield: compileFieldAccess,                  *
 *     nativecall: compileInvocation,                 *
 *     translate: compileTranslate,                   *
 *                                                    *
 ***************************************************** */

// const compileParameter = (expr) => {
//    const paramIdx = expr.index;
//    return (args) => {
//        return args[paramIdx];
//    };
// }
// const getaspect = {
//    compileInvocation: () => {
//        return (expr, buildContext) => {
//            console.log(expr);
//            console.log(buildContext);
//        }
//    }
// }

/** ****************************************************
 *                                                    *
 *   EXAMPLE: Adding a new Expression Language Type   *
 *   Declare a new Expression Language Type           *
 *                                                    *
 ***************************************************** */

// const exampleExpressionLanguageType = {
//     namespace: 'pc', // Optional namespace for Expression Type
//     type: {  // Required Expression Language Object which should contain function properties that correspond to your backend Expression Object
//         getValue(val) {
//             return val || 'default value';
//         }
//     }
// }



/** *************************************
 *                                     *
 *   EXAMPLE - creating a new aspect   *
 *                                     *
 ************************************** */

// const myaspect = {
//    getAspectProperties: (currentViewModelNode, currentMetadataNode, ancestorChain) => {
//        const { aspectAttribute } = currentMetadataNode;
//        const { value } = currentViewModelNode;
//
//        const isPatternApplied = value === maskInfo.pattern;
//        return {
//            aspectInfo1: {
//                get: () => !!aspectAttribute?.aspectInfo1 === 'a value';
//            },
//            isVisible: {
//                get: () => !!aspectAttribute?.aspectInfo2 < 42;
//            },
//        };
//    }
// }



/** **************************************************************
 *                                                              *
 *   EXAMPLE - creating a new ExpressionLanguage aware aspect   *
 *                                                              *
 *************************************************************** */

// const myELAwareAspect = {
//     create: (expressionLanguage) => {
//         // compute parameters based on the expression language
//         return {
//             getAspectProperties: (currentViewModelNode, currentMetadataNode, ancestorChain) => {
//                 return {
//                     property1: {
//                         get: () => ''; // Replace with desired value
//                     }
//                 }
//             }
//         }
//     }
// }


/** **************************************
 *                                      *
 *   EXAMPLE - Importing a new aspect   *
 *                                      *
 *************************************** */

// PLEASE NOTE THE .default is important here
// const AvailableValues = require('./AvailableValues').default;



/** ************************************************
 *                                                *
 *   EXAMPLE - Adding a new Validation function   *
 *                                                *
 ************************************************* */

// const exampleArrLength = (a) => {
//     return a ? a.length : 0;
// }




// Export Expression Types and Validation Functions
module.exports = {
    // add different types of aspects with or without expression language
    // NOTE you can override existing aspects by providing its name as the key for the object entry
    aspectFactory: {
        // myELAwareAspect: myELAwareAspect.create,
        // myaspect: () => myaspect
    },
    // Types
    // Key should match the backend package name for your Expression Object
    // Value should be your new Expression Language Type
    types: {
        // 'gw.api.example.ExampleExpressionLanguageType': exampleExpressionLanguageType,
        'be.pvgroup.pc.edge.common.lob.ppv.vehicle.util.VehicleUtil_PV': {
            namespace: 'pc',
            type: CustomClassValidations
        },
        'be.pvgroup.pc.edge.capabilities.policyjob.lob.ppvpersonalauto.coverables.util.DriverUtil_PV': {
            namespace: 'pc',
            type: CustomClassValidations
        },
        'be.pvgroup.pc.edge.capabilities.policyjob.lob.pcvcommercialauto.coverables.util.DriverUtil_PV': {
            namespace: 'pc',
            type: CustomClassValidations
        }
    },
    // Validations
    // Function name should match backend validation function
    validations: customValidations,
    validationMessagesToIgnore: [
        // validation messages that the view model surfaces that Jutro might duplicate

        // This field is required
        'displaykey.Edge.Web.Api.Model.NotNull',
        // Value entered must be a valid phone number
        'displaykey.Edge.Web.Api.Model.Phone'
    ],
    kindCompilers: {
        // getaspect1: compileParameter,
        // getaspect2: getaspect.compileAspectAccess(),
        // parameter: compileParameter
    }
}
