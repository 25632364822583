import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withRouter } from 'react-router-dom';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { withModalContext } from '@jutro/components';
import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { isCapabilityEnabled } from '@xengage/gw-portals-config-js';
import { DocumentsPage } from 'gw-capability-document-react';
import { SubmissionService, JobService } from 'gw-capability-gateway';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PortimaService } from 'pv-capability-portima';
import { BusinessConstant } from 'pv-portals-util-js';// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { PageHeaderComponent } from '../Components/FormattedHeaderComponent/FormattedHeaderComponent';
import CommonRoutes from '../Common/CommonRouting';
import metadata from './QuoteDetails.metadata.json5';
import pageRouting from '../Common/QuoteDetails-config.json5';
import messages from './QuoteDetails.messages';

const { capabilitiesConfig } = appConfig;

const DocumentPageService = {
    getDocuments: SubmissionService.getDocumentsForSubmission,
    generateUploadToken: GatewayDocumentService.generateUploadToken,
    uploadDocument: GatewayDocumentService.uploadDocument,
    removeDocument: GatewayDocumentService.removeDocument,
    downloadDocument: GatewayDocumentService.downloadDocument
};

function getRoutes() {
    const documentRoute = {
        path: '/documents',
        component: DocumentsPage
    };
    if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'document' })) {
        return [...pageRouting, documentRoute];
    }
    return pageRouting;
}

class QuoteDetails extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                jobNumber: PropTypes.string
            }),
            url: PropTypes.string
        }).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string
        }).isRequired
    };

    static contextType = TranslatorContext;

    state = {
        currentView: 'summary',
        tilesCountSubmissionData: {},
        quotes: {}
    };

    componentDidMount() {
        const { authUserData } = this.props;
        this.setState({
            isViviumPortal: _.lowerCase(authUserData.organizationType_PV) === BusinessConstant.PV_BRAND_VIVIUM
        });
        this.getQuoteSummaryCount();
        this.getJobByJobNumber();
    }

    componentDidUpdate(preProp) {
        const {
            match: {
                params: { jobNumber }
            }
        } = this.props;
        if (preProp.location.pathname.indexOf(jobNumber) === -1) {
            this.getQuoteSummaryCount();
            this.getJobByJobNumber();
        }
    }

    handleTilesOnClick = (id) => {
        this.setState({ currentView: id });
    };

    getQuoteSummaryCount = async () => {
        const {
            authHeader,
            match: {
                params: { jobNumber }
            },
            location: { pathname }
        } = this.props;

        const routePath = pathname && (pathname.trim().split('/') || []);
        const selectedPageFromPath = routePath[routePath.length - 1];
        const activeTile = selectedPageFromPath || 'summary';

        await SubmissionService.getSubmissionSummary(jobNumber, authHeader).then((response) => {
            this.setState({
                currentView: activeTile,
                tilesCountSubmissionData: response
            });
        });
    };

    blockRetourPV = async () => {
        const {
            authHeader,
            match: {
                params: { jobNumber }
            },
            modalContext
        } = this.props;

        const { quotes } = this.state;
        const enterpriseNumberPV = quotes.policy.account.accountHolder.enterpriseNumber_PV;

        if (
            quotes.productCode === 'CommercialVehicle_PV' &&
            (_.isNil(enterpriseNumberPV) || enterpriseNumberPV === '0000000000')
        ) {
            modalContext.showAlert({
                title: messages.numberMissingTitle,
                message: messages.numberMissing,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: platformMessages.close
            });
        } else {
            const response = await PortimaService.triggerBlocRetourPV(jobNumber, null, authHeader);
            if (response !== null) {
                this.setState({
                    disableBlocRetour: true
                });
            }
        }
    };

    updateQuoteSummary = (quotes) => {
        this.setState({ quotes });
    };

    getJobByJobNumber = async () => {
        const {
            authHeader,
            match: {
                params: { jobNumber }
            }
        } = this.props;

        const submission = await JobService.findJobByJobNumber(jobNumber, authHeader);
        this.updateQuoteSummary(submission);
    };

    render() {
        const { currentView, tilesCountSubmissionData, quotes, disableBlocRetour, isViviumPortal } = this.state;

        if (_.isEmpty(quotes)) {
            return null;
        }

        const translator = this.context;
        const isQuoted = quotes.statusCode === 'Quoted' && quotes.latestPeriod.totalCost.amount !== null;

        const overrides = {
            [currentView]: {
                active: true
            },
            printDocumentAndRetour: {
                visible: isQuoted && isViviumPortal,
                quoteID: quotes.jobNumber,
                sessionUUID: tilesCountSubmissionData.sessionUUID,
                branchName: tilesCountSubmissionData.offerings?.branchName,
                buttonLabel: translator(messages.downloadTariffAndBlocRetour),
                disabled: disableBlocRetour,
                buttonType: 'filled'
            },
            printDocument: {
                visible: isQuoted,
                quoteID: quotes.jobNumber,
                sessionUUID: tilesCountSubmissionData.sessionUUID,
                branchName: tilesCountSubmissionData.offerings?.branchName,
                buttonLabel: translator(messages.downloadTariff),
                buttonType: 'outlined'
            }
        };
        const quoteVM = { quotes, tilesCountSubmissionData };
        const resolvers = {
            resolveCallbackMap: {
                handleTilesOnClick: this.handleTilesOnClick,
                onAfterPrinting: this.blockRetourPV
            },
            resolveComponentMap: {
                pageheadercomponent: PageHeaderComponent
            }
        };
        const QuoteDetailspage = (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={quoteVM}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );

        const {
            match: {
                url,
                params: { jobNumber }
            }
        } = this.props;
        const quoteData = {
            jobNumber: jobNumber,
            loadQuoteSummary: quotes,
            updateJobSummary: this.updateQuoteSummary,
            getQuoteSummaryCount: this.getQuoteSummaryCount
        };
        return (
            <DependencyProvider value={{ DocumentPageService }}>
                {QuoteDetailspage}
                <CommonRoutes
                    steps={getRoutes()}
                    basePath={url}
                    setDocumentCount={this.getQuoteSummaryCount}
                    jobNumber={jobNumber}
                    quoteDetailsData={quoteData}
                    additionalDocMetadataPropsPV={{ availability_PV: 'gpa' }} // P&V: stating the visibility of the document gpa/customerzone/internal/customer zone & gpa
                />
            </DependencyProvider>
        );
    }
}

export default withRouter(withAuthenticationContext(withModalContext(QuoteDetails)));
