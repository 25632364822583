/**
 * THIS IS A COPY FROM THE OOTB BRANDING SERVICE TO PICK UP BRAND NAME FROM THE MAIN DOMAIN INSTEAD SUBDOMAIN
 *
 * Provides branding data from URL
 * using either 'branding' query string
 * or sub domain
 */
import { themes, defaultTheme } from 'customer-brandingservice-config';
import queryString from 'query-string';
import {
    themeList, consumerThemeConfig
} from '@jutro/theme-styles';

const getBrandName = () => (
    // e.g. XXX?branding=custom gives "custom"
    queryString.parse(window.location.search).branding
    // Check brand name in local storage
    || localStorage.getItem('brandName')
    // e.g. custom-domain.guidewire.com gives "custom-domain"
    // || window.location.hostname.split('.')[0]

    // P&V e.g. gpa.pv.be gives "pv"
    || window.location.hostname.split('.')[1]
);

// Check for Jutro config theme
const checkJutroTheme = (brandName) => (
    themeList.find((theme) => theme.name === brandName)
);

/**
 * BrandingService getBrandingTheme method
 * @function getBrandingTheme
 * @memberof module:gw-portals-branding-js
 * @returns {Object} a Jutro theme configuration
 */
function getBrandingTheme() {
    const brandName = getBrandName();
    if (brandName !== localStorage.getItem('brandName')) {
        localStorage.setItem('brandName', brandName);
    }
    // Check for customer config theme
    if (themes) {
        const customerTheme = themes[brandName];
        if (customerTheme) {
            return customerTheme;
        }
    }

    // Check for customer default config theme
    if (defaultTheme) {
        if (themes) {
            const getDefaultTheme = themes[defaultTheme];
            if (defaultTheme) {
                return getDefaultTheme;
            }
        }

        const jutroDefaultTheme = checkJutroTheme(defaultTheme);

        if (jutroDefaultTheme) {
            return jutroDefaultTheme;
        }
    }

    const jutroTheme = checkJutroTheme(brandName);
    if (jutroTheme) {
        return jutroTheme;
    }


    if (brandName && process.env.NODE_ENV !== 'production') {
        console.error(`User theme does not match with any brand name ${brandName}`);
    }

    return consumerThemeConfig;

}

export default {
    getBrandingTheme
};
