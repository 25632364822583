// This is now moved to package pv-capability-quoteandbind-common-react
// import DriverComponent from './components/DriverComponent/DriverComponent';
import VehicleComponent from './components/VehicleComponent/VehicleComponent';
import PolicyChangeDetailsComponent from './components/PolicyChangeDetailsComponent/PolicyChangeDetailsComponent';
import PolicyDiffComponent from './components/PolicyDiffComponent/PolicyDiffComponent';

const policyJobComponentMap = {
    // drivercomponent: { component: 'DriverComponent' },
    vehiclecomponent: { component: 'VehicleComponent' },
    PolicyDiffComponent: { component: 'PolicyDiffComponent' },
};

const policyJobComponents = {
    // DriverComponent,
    VehicleComponent,
    PolicyDiffComponent
};

export {
    // DriverComponent,
    VehicleComponent,
    PolicyChangeDetailsComponent,
    PolicyDiffComponent,
    policyJobComponentMap,
    policyJobComponents
};
