import _ from 'lodash';
import { ServiceManager } from '@jutro/legacy/services';
import BusinessConstant from './BusinessConstant';


const isTourismAndBusiness = (categoryName) => {
    return categoryName ===  BusinessConstant.VEHICLE_CATEGORY_TOURISM_AND_BUSINESS;
};

const isTwoWheelsAndsimilar = (categoryName) => {
    return categoryName === BusinessConstant.VEHICLE_CATEGORY_TWO_WHEELS_AND_SIMILAR;
};

const isMobilhomeVehicle = (categoryName) => {
    return categoryName === BusinessConstant.VEHICLE_CATEGORY_MOBILE_HOME;
};

const isOldTimerAndClassic = (categoryName) => {
    return categoryName === BusinessConstant.VEHICLE_CATEGORY_OLD_TIMER_OR_CLASSIC_CAR;
};

const isTrailerVehicle = (categoryName) => {
    return categoryName === BusinessConstant.VEHICLE_CATEGORY_TRAILER;
};

const isTransportVehicle35t = (vehicleType) => {
    return vehicleType === BusinessConstant.VEHICLE_TYPE_TRANSPORT_ABOVE_35T;
};

const isClassicMoto = (categoryName, vehicleType) => {
    return categoryName === BusinessConstant.VEHICLE_CATEGORY_OLD_TIMER_OR_CLASSIC_CAR && vehicleType === BusinessConstant.VEHICLE_TYPE_CLASSIC_MOTO;
};

const isTrailerBelow35t = (vehicleType) => {
    return vehicleType === BusinessConstant.VEHICLE_TYPE_TRAILER_BELOW_35T;
};

const isTrailerAbove35t = (vehicleType) => {
    return vehicleType === BusinessConstant.VEHICLE_TYPE_TRAILER_ABOVE_35T;
};

const isMotoVehicle = (vehicleType) => {
    return vehicleType === BusinessConstant.VEHICLE_TYPE_MOTO_PV;
};

const isUBIAvailable =  (isUbiEnabled, categoryName, vehicleType) => {
    return isUbiEnabled  && categoryName === BusinessConstant.VEHICLE_CATEGORY_TOURISM_AND_BUSINESS && vehicleType === BusinessConstant.VEHICLE_CATEGORY_CAR ;
};

const isTrailerCaravan = (vehicleType) => {
    return vehicleType === BusinessConstant.VEHICLE_TYPE_TRAILER_CARAVAN;
};

const isOtherTrailerVehicle = (vehicleType) => {
    return vehicleType === BusinessConstant.VEHICLE_TYPE_OTHER_TRAILER;
};

const securitySystemEnabled = (automaticEmergencyBraking, laneDepartureWarningSystem, driverDrowsinessDetection, adaptiveCruiseControl, blindSpotSystem, cyclAndPedestrDetectSystem) => {
    if ( !!automaticEmergencyBraking || !!laneDepartureWarningSystem || !!driverDrowsinessDetection ||
        !!adaptiveCruiseControl || !!blindSpotSystem || !!cyclAndPedestrDetectSystem) {
        return true;
    }
    return false;
};

const isCommercialLines = (productCode) => {
    return productCode === BusinessConstant.PCV_PROD_CODE;
};

function isPrimaryUsageFoodTruck(primaryUse) {
    return primaryUse === BusinessConstant.PRIMARY_USE_FOODTRUCK;
};

function isPrimaryUsageMarketVehicle(primaryUse) {
    return primaryUse === BusinessConstant.PRIMARY_USE_MARKET_VEHICLE;
};

function isCourtesyVehicle(primaryUse) {
    return primaryUse === BusinessConstant.PRIMARY_USE_COURTESY_VEHICLE;
};

function isLicensePlateValid(licensePlateValue) {
    return !_.isEmpty(licensePlateValue) && licensePlateValue.match(/^[A-Z0-9]{1,8}$/);
}

function isVinValid(vinValue) {
    const validVinOf17Chars = vinValue?.match(/^[A-NP-Z0-9]{17,17}$/) && vinValue.length === 17;
    const validVinOfLessThan17Chars = vinValue?.match(/^[A-Z0-9]{0,16}$/) && vinValue.length < 17;
    return validVinOf17Chars || validVinOfLessThan17Chars;
}

function copyFromInformex(vehicle, vehicleInfo) {
    vehicle.make = vehicleInfo.make;
    vehicle.model = vehicleInfo.model;
    vehicle.weight_PV = vehicleInfo.weight_PV;
    vehicle.codeCarSegment_PV = vehicleInfo.codeCarSegment_PV;
    vehicle.engineCapacity_PV = vehicleInfo.engineCapacity_PV;
    vehicle.enginePower_PV = vehicleInfo.enginePower_PV;
    vehicle.enginePowerPlaceHolder_PV = vehicleInfo.enginePower_PV;
    vehicle.engineCapacityPlaceHolder_PV = vehicleInfo.engineCapacity_PV;
    vehicle.fuelType = vehicleInfo.fuelType;
    vehicle.gearBox = vehicleInfo.gearBox;
    vehicle.numOfSeats = vehicleInfo.numOfSeats;
    if (vehicleInfo.catalogValue_PV && vehicleInfo.catalogValue_PV.amount) {
        vehicle.catalogValue_PV = {
            amount: vehicleInfo.catalogValue_PV.amount,
            currency: vehicleInfo.catalogValue_PV.currency
        };
        vehicle.catalogValuePlaceHolder_PV = vehicleInfo.catalogValue_PV.amount;
    }
    vehicle.license = vehicleInfo.licensePlate;
    vehicle.vin = vehicleInfo.vin;
    vehicle.maxAuthorizedMass_PV = vehicleInfo.maxAuthorizedMass_PV;
    vehicle.isTotalWtGreater12T_PV = (vehicle.maxAuthorizedMass_PV >= 12000);
};

function getShortDisplayName(vehicle, translator) {

    if (_.isNil(vehicle) && _.isNil(vehicle.make) && _.isNil(vehicle.model) && _.isNil(vehicle.year) && _.isNil(vehicle.license)) {
        return translator({id: 'quoteandbind.controllers.this vehicle', defaultMessage: 'this vehicle'});
    }

    let name = vehicle.make || '';
    if (vehicle.model) {
        if (name) {
            name += ' ';
        }
        name += vehicle.model;
    }
    if (vehicle.year) {
        if (name) {
            name += ' ';
        }
        name += vehicle.year;
    }
    if (name.length < 1) {
        name = translator({id: 'quoteandbind.controllers.this vehicle', defaultMessage: 'this vehicle'});
    } else if (vehicle.license !== '0streamlinedquote0') {
        if (vehicle.license) {
            name += ' (';
            name += vehicle.license;
            name += ')';
        }
    }
    return name;
};

const addPropertyToString = (value, string, separator, suffix) => {
    if (value) {
        if (string) {
            string += separator;
        }

        if (value.name) {
            string += value.name;
        } else {
            string += value;
        }

        if (suffix) {
            string += ` ${suffix}`;
        }

    }
    return string;
};

function getFullDisplayName(vehicleVM, translator) {
    const vehicle = vehicleVM.value;
    let name = vehicle.make || '';

    const fuelType = !_.isEmpty(vehicleVM.fuelType.value) ? translator({id: vehicleVM.fuelType.value.name, defaultMessage: vehicleVM.fuelType.value.code}) : '';

    name = addPropertyToString(vehicle.model, name, ' ');
    name = addPropertyToString(vehicle.year, name, ', ');
    name = addPropertyToString(fuelType, name, ', ');
    name = addPropertyToString(translator({id:`typekey.PPVVehicleGearBox_PV.${vehicle.gearBox}`, defaultMessage:vehicle.gearBox}), name, ', ');
    name = addPropertyToString(vehicle.numOfSeats, name, ', ', translator({id: 'quoteandbind.ppv.model.PPVVehicle.places', defaultMessage: 'places'}));
    name = addPropertyToString(vehicle.engineCapacity_PV, name, ', ', translator({ id: 'quoteandbind.ppv.model.PPVVehicle.cc', defaultMessage: 'cc'}));
    name = addPropertyToString(vehicle.enginePower_PV, name, ', ', translator({ id: 'quoteandbind.ppv.model.PPVVehicle.kW', defaultMessage: 'kW'}));

    if (vehicle.catalogValue_PV?.amount > 0) {
        if (name) {
            name += ', ';
        }
        const localeService = ServiceManager.getService('locale-service');
        const locale = localeService.getStoredLocale() || localeService.getPreferredLocale(); // if no stored locale available, use the preferred locale
        const currency = vehicle.catalogValue_PV.currency || 'eur';
        name += new Intl.NumberFormat(locale, { style: 'currency', currency }).format(vehicle.catalogValue_PV.amount);
    }
    return name;
};

const setDefaultObjectProperties = (vehicle, isCommercial) => {
    const defaultToEmptyObjectIfNotSet = (value) => {
        if (_.isUndefined(value)) {
            return {};
        }
        return value;
    };
    const defaultTypeKey = (typekey, newValue) => {
        if (typekey === undefined) {
            return newValue;
        }
        return typekey;
    };

    const defaultUndefinedIfNotSet = (value) => {
        if (value !== undefined){
            return value;
        }
        return undefined;
    };

    vehicle.insuredValue = defaultToEmptyObjectIfNotSet(vehicle.insuredValue);
    vehicle.catalogValue_PV = defaultToEmptyObjectIfNotSet(vehicle.catalogValue_PV);
    vehicle.accessoriesValue = defaultToEmptyObjectIfNotSet(vehicle.accessoriesValue);
    vehicle.optionsValue = defaultToEmptyObjectIfNotSet(vehicle.optionsValue);
    vehicle.invoiceValue = defaultToEmptyObjectIfNotSet(vehicle.invoiceValue);
    vehicle.expertValue_PV = defaultToEmptyObjectIfNotSet(vehicle.expertValue_PV);
    vehicle.carDealerValue_PV = defaultToEmptyObjectIfNotSet(vehicle.carDealerValue_PV);
    vehicle.annualKilometer = defaultTypeKey(vehicle.annualKilometer, 'more_than_twenty_thousand');

    if (isCommercial) {
        vehicle.claimsTPL5YearsFault_PV = defaultTypeKey(vehicle.claimsTPL5YearsFault_PV, '0');
        vehicle.noOfYearsSinceLastClaim_PV = defaultTypeKey(vehicle.noOfYearsSinceLastClaim_PV, 'None');
        vehicle.claimsMD5YearsFault_PV = defaultTypeKey(vehicle.claimsMD5YearsFault_PV, '0');
        vehicle.claimsMD5YearsFaultCL_PV = defaultTypeKey(vehicle.claimsMD5YearsFaultCL_PV, '0');
        // ALPH-14879:GPA | Tariff summary screen- Vivium & P&V CL accounts without designated driver - Download Tariff button is not working
        vehicle.claimsTPL5YearsNFault_PV = defaultTypeKey(vehicle.claimsTPL5YearsNFault_PV, '0');
        vehicle.claimsTheft5YearsFault_PV = defaultTypeKey(vehicle.claimsTheft5YearsFault_PV, '0');

        vehicle.dangerousGoods_PV = defaultUndefinedIfNotSet(vehicle.dangerousGoods_PV);
    }
};

const setDefaultValuesToFalse = (vehicle, isCommercial, vpVersionChecker) => {
    const defaultBooleanToFalseIfNotSet = (value) => {
        if (!_.isBoolean(value)) {
            return false;
        }
        return value;
    };

    vehicle.towbar = defaultBooleanToFalseIfNotSet(vehicle.towbar);
    vehicle.existingDamage = defaultBooleanToFalseIfNotSet(vehicle.existingDamage);
    vehicle.automaticEmergencyBraking = defaultBooleanToFalseIfNotSet(vehicle.automaticEmergencyBraking);
    vehicle.laneDepartureWarningSystem = defaultBooleanToFalseIfNotSet(vehicle.laneDepartureWarningSystem);
    vehicle.driverDrowsinessDetection = defaultBooleanToFalseIfNotSet(vehicle.driverDrowsinessDetection);
    vehicle.adaptiveCruiseControl = defaultBooleanToFalseIfNotSet(vehicle.adaptiveCruiseControl);
    vehicle.blindSpotSystem = defaultBooleanToFalseIfNotSet(vehicle.blindSpotSystem);
    vehicle.cyclAndPedestrDetectSystem = defaultBooleanToFalseIfNotSet(vehicle.cyclAndPedestrDetectSystem);
    vehicle.semiPrivateTrips = defaultBooleanToFalseIfNotSet(vehicle.semiPrivateTrips);
    vehicle.ubi = defaultBooleanToFalseIfNotSet(vehicle.ubi);
    vehicle.isSecuritySystemInstalled = defaultBooleanToFalseIfNotSet(vehicle.isSecuritySystemInstalled);
    vehicle.garaged = defaultBooleanToFalseIfNotSet(vehicle.garaged);
    vehicle.financialPlan = defaultBooleanToFalseIfNotSet(vehicle.financialPlan);
    vehicle.sideCar_PV = defaultBooleanToFalseIfNotSet(vehicle.sideCar_PV);
    vehicle.carSharing = defaultBooleanToFalseIfNotSet(vehicle.carSharing);
    vehicle.isUbiUpdated = defaultBooleanToFalseIfNotSet(vehicle.isUbiUpdated);
    vehicle.cancelPolicy_PV = defaultBooleanToFalseIfNotSet(vehicle.cancelPolicy_PV);
    vehicle.antiTheftCodeMoto_PV = defaultBooleanToFalseIfNotSet(vehicle.antiTheftCodeMoto_PV);
    vehicle.twoWheelerCategory_PV = defaultBooleanToFalseIfNotSet(vehicle.twoWheelerCategory_PV);
    vehicle.wasRiskRefusedByInsurer = defaultBooleanToFalseIfNotSet(vehicle.wasRiskRefusedByInsurer);
    if (vpVersionChecker.isEqualOrAfterVP6()) {
        vehicle.firstOwner = defaultBooleanToFalseIfNotSet(vehicle.firstOwner);
    }

    if (isCommercial) {
        vehicle.wasRiskRefusedByInsurer = defaultBooleanToFalseIfNotSet(vehicle.wasRiskRefusedByInsurer);
    }
};

const setDefaultValuesToTrue = (vehicle, isCommercial) => {
    const defaultBooleanToTrueIfNotSet = (value) => {
        if (!_.isBoolean(value)) {
            return true;
        }
        return value;
    };

    if (isCommercial) {
        vehicle.designatedDriver_PV = defaultBooleanToTrueIfNotSet(vehicle.designatedDriver_PV);
        vehicle.claimsVehiclePrevInsuerByPolicyHolder_PV = defaultBooleanToTrueIfNotSet(vehicle.claimsVehiclePrevInsuerByPolicyHolder_PV);
        vehicle.claimsProofOfClaimHistoryLast3Years = defaultBooleanToTrueIfNotSet(vehicle.claimsProofOfClaimHistoryLast3Years);
    }
};

const setDefaultPrimaryUseValue = (vehicle, isCommercial, availablePrimaryUseValues) => {
    const defaultTypeKey = (typekey, newValue) => {
        if (typekey === undefined) {
            return newValue;
        }
        return typekey;
    };

    if (availablePrimaryUseValues.length === 1) {
        vehicle.primaryUse = defaultTypeKey(vehicle.primaryUse, availablePrimaryUseValues[0].code);
    } else if (isCommercial) {
        vehicle.primaryUse = defaultTypeKey(vehicle.primaryUse, 'Mix_PV');
    } else {
        vehicle.primaryUse = (vehicle.category === 'Mobilhome' ? undefined : defaultTypeKey(vehicle.primaryUse, 'PrivateAndWayToWork_PV'));
    }
};

function setDefaultValues(vehicle, isCommercial, noRemoveTheseFields, availablePrimaryUseValues = [], vpVersionChecker) {
    let noRemoveProperties = ['tempID', 'publicID', 'fixedId', 'vehicleNumber'];

    if (noRemoveTheseFields) {
        noRemoveProperties = noRemoveProperties.concat(noRemoveTheseFields);
    }

    for (const prop in vehicle) {
        if (typeof vehicle[prop] !== 'function'
            && vehicle.hasOwnProperty(prop)
            && !noRemoveProperties.includes(prop)) {
            vehicle[prop] = undefined;
        }
    }

    setDefaultObjectProperties(vehicle, isCommercial);
    setDefaultValuesToFalse(vehicle, isCommercial, vpVersionChecker);
    setDefaultValuesToTrue(vehicle, isCommercial);
    setDefaultPrimaryUseValue(vehicle, isCommercial, availablePrimaryUseValues);
};

function restoreMissingDefaultValues(vehicle, isCommercial, vpVersionChecker) {
    // setup scene
    const vehicleWithDefaults = _.cloneDeep(vehicle);
    setDefaultValues(vehicleWithDefaults, isCommercial, ['category', 'type', 'designatedDriver_PV'], undefined, vpVersionChecker);

    // iterate through default values and find out what is missing in given vehicle
    for (const prop in vehicleWithDefaults) {
        if (typeof vehicleWithDefaults[prop] !== 'function'
            && vehicleWithDefaults.hasOwnProperty(prop)
            && _.isNil(vehicle[prop]))
        {
            // add missing default property
            vehicle[prop] = vehicleWithDefaults[prop];
        }
    }
}

const getAvailablePrimaryUseValues = (viewModelService, vehicleVM, lineOfBusiness, vpVersionChecker) => {
    const primaryUses = [];
    if (vehicleVM.category.value && vehicleVM.type.value) {
        const availablePrimaryUseValues = vehicleVM.primaryUse.aspects.availableValues;
        const lob = viewModelService.productMetadata.get('pc').types.getTypelist('PolicyLine').getCode(lineOfBusiness);
        _.each(availablePrimaryUseValues, (primaryUse) => {
            if (vpVersionChecker.isEqualOrAfterVP7() && primaryUse.code === BusinessConstant.PRIMARY_USE_TRANSPORT_OWN_ACCOUNT
                    && vehicleVM.value.category === BusinessConstant.VEHICLE_CATEGORY_TOURISM_AND_BUSINESS) {
                // TransportOwnAccount_PV is not used VirtualProduct >= 7 in combination with T&B category
                return;
            }
            if (primaryUse.belongsToCategory(lob) && primaryUse.belongsToCategory(vehicleVM.category.value)) {
                if (lineOfBusiness === BusinessConstant.LINE_OF_BUSINESS_CODE_PPVLINE_PV) {
                    primaryUses.push(primaryUse);
                }
                if (lineOfBusiness === BusinessConstant.LINE_OF_BUSINESS_CODE_PCVLINE_PV && primaryUse.belongsToCategory(vehicleVM.type.value)) {
                    primaryUses.push(primaryUse);
                }
            }
        });
    }
    return primaryUses;
};

const visibleFields = (vehicleVM, quickQuoteMode, jobType, productCode, vpVersionChecker) => {
    const vehicleType = vehicleVM.value.type;
    const category = vehicleVM.value.category;
    const designatedDriverValue = vehicleVM.designatedDriver_PV?.value;
    const existingVehicle = vehicleVM.existingVehicle_PV?.value;
    const isPolicyChange = jobType === BusinessConstant.JOB_TYPE_POLICY_CHANGE;
    const isCommercial = productCode === BusinessConstant.PCV_PROD_CODE;
    const fromVirtualProd6 = vpVersionChecker.isEqualOrAfterVP6();

    let visibilityOptions = {};

    // Visibility options based on vehicle category
    switch (category) {
        case BusinessConstant.VEHICLE_CATEGORY_TOURISM_AND_BUSINESS:
            visibilityOptions = {
                // VEHICLE INFO
                vehicleInfoContainer: {
                    visible: true,
                },
                vehicleTitle: {
                    visible: true,
                },
                license: {
                    visible: isPolicyChange
                },
                vin: {
                    visible: isPolicyChange,
                },
                make: {
                    visible: true,
                },
                model: {
                    visible: true,
                },
                fuelType: {
                    visible: true,
                },

                // VEHICLE DETAILS
                vehicleDetailsContainer: {
                    visible: true,
                },
                enginePower_PV: {
                    visible: true
                },
                firstRegistrationDate_PV: {
                    visible: true,
                },
                vehicleNotCirculation: {
                    visible: !(isPolicyChange && existingVehicle),
                    showOptional: false,
                },
                isSecuritySystemInstalled: {
                    visible: true,
                },
                securitySystems: {
                    visible: _.get(vehicleVM, 'value.isSecuritySystemInstalled', false),
                },

                // VEHICLE USAGE
                vehicleUsageContainer: {
                    visible: true,
                },
                primaryUse: {
                    visible: true,
                },
                semiPrivateTrips: {
                    visible: !isCommercial && !quickQuoteMode
                },
                annualKilometer: {
                    visible: true,
                },
                towbar: {
                    visible: true,
                },
                typeOfPreparation_PV: {
                    visible: isCommercial && (vehicleVM.primaryUse.value?.code === BusinessConstant.PRIMARY_USE_FOODTRUCK || vehicleVM.primaryUse.value?.code === BusinessConstant.PRIMARY_USE_MARKET_VEHICLE)
                },
                cancenCurrentInsurerDetails: {
                    visible: isPolicyChange && !existingVehicle,
                },
                financialPlan: {
                    visible: !quickQuoteMode,
                },
                designatedDriver_PV: {
                    visible: isCommercial,
                },

                // CLAIM DETAILS
                claimSectionContainer: {
                    visible: designatedDriverValue === false,
                },
            };
            break;
        case BusinessConstant.VEHICLE_CATEGORY_TWO_WHEELS_AND_SIMILAR:
            visibilityOptions = {
                // VEHICLE INFO
                vehicleInfoContainer: {
                    visible: true,
                },
                vehicleTitle: {
                    visible: true,
                },
                license: {
                    visible: isPolicyChange
                },
                vin: {
                    visible: isPolicyChange,
                },
                make: {
                    visible: true,
                },
                model: {
                    visible: true,
                },
                fuelType: {
                    visible: true,
                },

                // VEHICLE DETAILS
                vehicleDetailsContainer: {
                    visible: true,
                },
                enginePower_PV: {
                    visible: true
                },
                engineCapacity_PV: {
                    visible: true
                },
                firstRegistrationDate_PV: {
                    visible: true,
                },
                vehicleNotCirculation: {
                    visible: !(isPolicyChange && existingVehicle),
                },

                // VEHICLE USAGE
                vehicleUsageContainer: {
                    visible: true,
                },
                primaryUse: {
                    visible: true,
                },
                semiPrivateTrips: {
                    visible: !isCommercial && !quickQuoteMode
                },
                cancenCurrentInsurerDetails: {
                    visible: isPolicyChange && !existingVehicle,
                },
                financialPlan: {
                    visible: !quickQuoteMode,
                },
                designatedDriver_PV: {
                    visible: isCommercial,
                },

                // CLAIM DETAILS
                claimSectionContainer: {
                    visible: designatedDriverValue === false && !quickQuoteMode,
                },
            };
            break;
        case BusinessConstant.VEHICLE_CATEGORY_MOBILE_HOME:
            visibilityOptions = {
                // VEHICLE INFO
                vehicleInfoContainer: {
                    visible: true,
                },
                vehicleTitle: {
                    visible: true,
                },
                license: {
                    visible: true
                },
                vin: {
                    visible: true,
                },
                make: {
                    visible: true,
                },
                model: {
                    visible: true,
                },
                fuelType: {
                    visible: true,
                },

                // VEHICLE DETAILS
                vehicleDetailsContainer: {
                    visible: true,
                },
                maxAuthorizedMass_PV: {
                    visible: true
                },
                enginePower_PV: {
                    visible: true
                },
                firstRegistrationDate_PV: {
                    visible: true,
                },
                vehicleNotCirculation: {
                    visible: !(isPolicyChange && existingVehicle),
                },
                isSecuritySystemInstalled: {
                    visible: true,
                },
                securitySystems: {
                    visible: _.get(vehicleVM, 'value.isSecuritySystemInstalled', false),
                },

                // VEHICLE USAGE
                vehicleUsageContainer: {
                    visible: true,
                },
                primaryUse: {
                    visible: isCommercial,
                },
                semiPrivateTrips: {
                    visible: !isCommercial && !quickQuoteMode
                },
                annualKilometer: {
                    visible: true,
                },
                towbar: {
                    visible: true,
                },
                cancenCurrentInsurerDetails: {
                    visible: isPolicyChange && !existingVehicle,
                },
                financialPlan: {
                    visible: !quickQuoteMode,
                },
                designatedDriver_PV: {
                    visible: isCommercial,
                },

                // CLAIM DETAILS
                claimSectionContainer: {
                    visible: designatedDriverValue === false,
                },
            };
            break;
        case BusinessConstant.VEHICLE_CATEGORY_TRAILER:
            visibilityOptions = {
                // VEHICLE INFO
                vehicleInfoContainer: {
                    visible: true,
                },
                vehicleTitle: {
                    visible: true,
                },
                license: {
                    visible: isPolicyChange
                },
                vin: {
                    visible: isPolicyChange,
                },
                make: {
                    visible: true,
                },
                model: {
                    visible: true,
                },

                // VEHICLE DETAILS
                vehicleDetailsContainer: {
                    visible: true,
                },
                maxAuthorizedMass_PV: {
                    visible: !quickQuoteMode
                },
                firstRegistrationDate_PV: {
                    visible: true,
                },
                vehicleNotCirculation: {
                    visible: !(isPolicyChange && existingVehicle),
                },

                // VEHICLE USAGE
                vehicleUsageContainer: {
                    visible: isCommercial || !quickQuoteMode || (isPolicyChange && !existingVehicle),
                },
                primaryUse: {
                    visible: isCommercial,
                },
                typeOfPreparation_PV: {
                    visible: isCommercial && (vehicleVM.primaryUse.value?.code === BusinessConstant.PRIMARY_USE_FOODTRUCK || vehicleVM.primaryUse.value?.code === BusinessConstant.PRIMARY_USE_MARKET_VEHICLE)
                },
                cancenCurrentInsurerDetails: {
                    visible: isPolicyChange && !existingVehicle,
                },
                dangerousGoods_PV: {
                    visible: isCommercial && (vehicleVM.primaryUse.value?.code === BusinessConstant.PRIMARY_USE_TRANSPORT_ACCOUNT_OF_OTHERS || vehicleVM.primaryUse.value?.code === BusinessConstant.PRIMARY_USE_TRANSPORT_OWN_ACCOUNT)
                },
                adrclassValue_PV: {
                    visible: isCommercial && !!vehicleVM.value.dangerousGoods_PV
                },
                financialPlan: {
                    visible: !quickQuoteMode,
                },
                designatedDriver_PV: {
                    visible: isCommercial,
                },

                // CLAIM DETAILS
                claimSectionContainer: {
                    visible: designatedDriverValue === false && !quickQuoteMode,
                },
            };
            break;
        case BusinessConstant.VEHICLE_CATEGORY_OLD_TIMER_OR_CLASSIC_CAR:
            visibilityOptions = {
                // VEHICLE INFO
                vehicleInfoContainer: {
                    visible: true,
                },
                vehicleTitle: {
                    visible: true,
                },
                license: {
                    visible: isPolicyChange
                },
                vin: {
                    visible: isPolicyChange,
                },
                make: {
                    visible: true,
                },
                model: {
                    visible: true,
                },

                // VEHICLE DETAILS
                vehicleDetailsContainer: {
                    visible: true,
                },
                firstRegistrationDate_PV: {
                    visible: true,
                },

                // VEHICLE USAGE
                vehicleUsageContainer: {
                    visible: (isPolicyChange && !existingVehicle || isCommercial),
                },
                primaryUse: {
                    visible: isCommercial,
                },
                cancenCurrentInsurerDetails: {
                    visible: isPolicyChange && !existingVehicle,
                },
                designatedDriver_PV: {
                    visible: isCommercial,
                },

                // CLAIM DETAILS
                claimSectionContainer: {
                    visible: designatedDriverValue === false && !quickQuoteMode,
                },
            };
            break;
        case BusinessConstant.VEHICLE_CATEGORY_OTHER_VEHICLES:
            visibilityOptions = {
                // VEHICLE INFO
                vehicleInfoContainer: {
                    visible: true,
                },
                vehicleTitle: {
                    visible: true,
                },
                license: {
                    visible: isPolicyChange
                },
                vin: {
                    visible: isPolicyChange,
                },
                make: {
                    visible: true,
                },
                model: {
                    visible: true,
                },

                // VEHICLE DETAILS
                vehicleDetailsContainer: {
                    visible: true,
                },
                maxAuthorizedMass_PV: {
                    visible: true
                },
                enginePower_PV: {
                    visible: true
                },
                engineCapacity_PV: {
                    visible: true
                },
                firstRegistrationDate_PV: {
                    visible: true,
                },
                vehicleNotCirculation: {
                    visible: !(isPolicyChange && existingVehicle),
                },

                // VEHICLE USAGE
                vehicleUsageContainer: {
                    visible: true,
                },
                primaryUse: {
                    visible: true,
                },
                isTotalWtGreater12T_PV: {
                    visible: true
                },
                towbar: {
                    visible: true,
                },
                typeOfPreparation_PV: {
                    visible: isCommercial && (vehicleVM.primaryUse.value?.code === BusinessConstant.PRIMARY_USE_FOODTRUCK || vehicleVM.primaryUse.value?.code === BusinessConstant.PRIMARY_USE_MARKET_VEHICLE)
                },
                isInternationalTransport_PV: {
                    visible: vehicleVM.primaryUse.value?.code === BusinessConstant.PRIMARY_USE_TRANSPORT_ACCOUNT_OF_OTHERS || vehicleVM.primaryUse.value?.code === BusinessConstant.PRIMARY_USE_TRANSPORT_OWN_ACCOUNT
                },
                dangerousGoods_PV: {
                    visible: isCommercial && (vehicleVM.primaryUse.value?.code === BusinessConstant.PRIMARY_USE_TRANSPORT_ACCOUNT_OF_OTHERS || vehicleVM.primaryUse.value?.code === BusinessConstant.PRIMARY_USE_TRANSPORT_OWN_ACCOUNT)
                },
                adrclassValue_PV: {
                    visible: isCommercial && !!vehicleVM.value.dangerousGoods_PV
                },
                cancenCurrentInsurerDetails: {
                    visible: isPolicyChange && !existingVehicle,
                },
                financialPlan: {
                    visible: isCommercial && !quickQuoteMode,
                },
                designatedDriver_PV: {
                    visible: isCommercial,
                },

                // CLAIM DETAILS
                claimSectionContainer: {
                    visible: designatedDriverValue === false && !quickQuoteMode,
                },
            };
            break;
        default:
            break;
    }

    // Visibility options based on vehicle type
    switch (vehicleType) {
        case BusinessConstant.VEHICLE_TYPE_VAN:
            visibilityOptions = {
                ...visibilityOptions,
                dangerousGoods_PV: {
                    visible: isCommercial && (vehicleVM.primaryUse.value?.code === BusinessConstant.PRIMARY_USE_TRANSPORT_ACCOUNT_OF_OTHERS || vehicleVM.primaryUse.value?.code === BusinessConstant.PRIMARY_USE_TRANSPORT_OWN_ACCOUNT)
                },
                adrclassValue_PV: {
                    visible: isCommercial && !!vehicleVM.value.dangerousGoods_PV
                },
            };
            break;
        case BusinessConstant.VEHICLE_TYPE_MOTO_PV:
            visibilityOptions = {
                ...visibilityOptions,
                twoWheelerCategory_PV: {
                    visible: true
                },
                sideCar_PV: {
                    visible: true
                },
            };
            break;
        case BusinessConstant.VEHICLE_TYPE_CLASSIC_MOTO:
            visibilityOptions = {
                ...visibilityOptions,
                engineCapacity_PV: {
                    visible: true
                },
            };
            break;
        case BusinessConstant.VEHICLE_TYPE_TRAILER_ABOVE_35T:
        case BusinessConstant.VEHICLE_TYPE_TRAILER_BELOW_35T:
            visibilityOptions = {
                ...visibilityOptions,
                isTotalWtGreater12T_PV: {
                    visible: true
                },
            };
            break;
        default:
            break;
    }

    // Visibility for Claims section (same for all)
    const showClaimsSection = visibilityOptions.claimSectionContainer.visible;
    visibilityOptions = {
        ...visibilityOptions,

        // vehicle details
        firstOwner: {
            visible: fromVirtualProd6
        },
        soldByOfficialVendor: {
            visible: fromVirtualProd6 && _.get(vehicleVM, 'value.firstOwner', false) === false
        },

        // claims section, history subsection - same for all categories
        claimsVehiclePrevInsuerByPolicyHolder_PV: {
            visible: showClaimsSection && isCommercial && !quickQuoteMode
        },
        claimsIsVehicleReplacementOfAnotherOne: {
            visible: showClaimsSection && vehicleVM.claimsVehiclePrevInsuerByPolicyHolder_PV?.value === false
        },
        claimsProofOfClaimHistoryLast3Years: {
            visible: showClaimsSection && vehicleVM.claimsVehiclePrevInsuerByPolicyHolder_PV?.value === true || vehicleVM.claimsIsVehicleReplacementOfAnotherOne?.value === false
        },
        claimsProofOfClaimHistoryLast3YearsForPrevVehicle: {
            visible: showClaimsSection && vehicleVM.claimsVehiclePrevInsuerByPolicyHolder_PV?.value === false && vehicleVM.claimsIsVehicleReplacementOfAnotherOne?.value === true
        },

        riskRefusedDetails: {
            visible: showClaimsSection && !quickQuoteMode
        },

        // claims section, number of claims subsection - same for all categories
        claimsTPL5YearsFault_PV: {
            visible: showClaimsSection && isCommercial
        },
        noOfYearsSinceLastClaim_PV: {
            visible: showClaimsSection && vehicleVM.claimsTPL5YearsFault_PV?.value?.code !== '0'
        },
        claimsTPL5YearsNFault_PV: {
            visible: showClaimsSection && !quickQuoteMode && _.isObject(vehicleVM.claimsTPL5YearsFault_PV?.value)
        },
        claimsTheft5YearsFault_PV: {
            visible: showClaimsSection && !quickQuoteMode && _.isObject(vehicleVM.claimsTPL5YearsFault_PV?.value)
        },
        claimsMD5YearsFault_PV: {
            visible: showClaimsSection && !quickQuoteMode && _.isObject(vehicleVM.claimsTPL5YearsFault_PV?.value)
        }
    };
    return visibilityOptions;
};



export default {

    isOldTimerAndClassic: isOldTimerAndClassic,
    isTrailerVehicle: isTrailerVehicle,
    isTransportVehicle35t: isTransportVehicle35t,
    isClassicMoto: isClassicMoto,
    isTrailerBelow35t: isTrailerBelow35t,
    isTrailerAbove35t: isTrailerAbove35t,
    isTourismAndBusiness: isTourismAndBusiness,
    isMobilhomeVehicle: isMobilhomeVehicle,
    isMotoVehicle: isMotoVehicle,
    isUBIAvailable: isUBIAvailable,
    isTwoWheelsAndsimilar: isTwoWheelsAndsimilar,
    isOtherTrailerVehicle: isOtherTrailerVehicle,
    securitySystemEnabled: securitySystemEnabled,
    isCommercialLines:isCommercialLines,
    isPrimaryUsageFoodTruck,
    isPrimaryUsageMarketVehicle,
    isCourtesyVehicle,
    isTrailerCaravan,
    visibleFields,
    isLicensePlateValid,
    isVinValid,
    getAvailablePrimaryUseValues,

    setDefaultValues, // old defaultValues,
    restoreMissingDefaultValues,
    getFullDisplayName, // old displayVehicleInformation,
    getShortDisplayName, // old getDisplayName,
    copyFromInformex // old createFromVehicleInfo()
};
