import React from 'react';
import PropTypes from 'prop-types';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { withErrorBoundary } from '@xengage/gw-portals-error-react';

function getLanguage() {
    const locale = localStorage.getItem('selectedLanguage');

    if (locale === 'zh-CN') {
        return locale.toLowerCase();
    }
    return locale.split('-')[0];
}

function TwitterComponent(props) {
    const { config, id } = props;
    const { dataSource, tweetLimit } = config;
    const { screenName } = dataSource;

    return (
        <TwitterTimelineEmbed
            id={id}
            sourceType="profile"
            screenName={screenName}
            lang={getLanguage()}
            options={{
                tweetLimit: tweetLimit
            }}
        />
    );
}

TwitterComponent.propTypes = {
    id: PropTypes.string,
    config: PropTypes.shape({
        dataSource: PropTypes.shape({
            screenName: PropTypes.string
        }),
        tweetLimit: PropTypes.number
    }).isRequired
};

TwitterComponent.defaultProps = {
    id: undefined
};

export default withErrorBoundary({ resetError: false })(TwitterComponent);
