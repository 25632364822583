import _ from 'lodash';
import React, { useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { EdgeErrorParser } from 'pv-portals-util-js';
import { messages as platformMessages } from '@xengage/gw-platform-translations';

const useWizardError = ({ location, setHasErrorOccurred }) => {

    const { showAlert } = useModal();
    const translator = useTranslator();

    const handleError = useCallback((error) => {
        console.error(error);

        const msg = EdgeErrorParser.getErrorMessage(error);

        const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
        const quoteIDFromWizard = _.get(error, 'gwInfo.params[0].quoteID');
        const quoteIDFromLocation = _.get(location, 'state.quoteentry.quoteID');
        const quoteID = quoteIDFromWizard || quoteIDFromLocation;

        const redirectPath = `/quotes/${quoteID}/summary`;
        const state = {
            pathname: redirectPath,
            state: error
        };

        const genericError = isQuotePage ? platformMessages.unableToSaveQuoteMessage : platformMessages.genericErrorMessage;
        const fullErrorMsg = `${translator(genericError)}\n${msg}`;

        return showAlert({
            title: isQuotePage ? platformMessages.unableToSaveQuote : platformMessages.genericError,
            message: fullErrorMsg,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: platformMessages.ok
        }).then(() => {
            setHasErrorOccurred(true);
            return <Redirect to={state} />;
        }, _.noop);
    }, [translator, location, setHasErrorOccurred, showAlert]);

    return handleError;
};

export default useWizardError;
