import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { BusinessConstant } from 'pv-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { messagesPolicyJob } from 'pv-capability-policyjob-react';

import styles from "./SummaryPageDownloadDocumentComponent.module.scss";
import metadata from './SummaryPageDownloadDocumentComponent.metadata.json5';
import messages from  './SummaryPageDownloadDocumentComponent.messages';

function SummaryPageDownloadDocumentComponent(props) {
    const {
        submission,
        vehicleCoverages,
        offeredQuoteLowDeductible,
        offeredQuoteEnglishDeductible,
        branchName
    } = props;

    const translator = useTranslator();
    const quoteDataCustomBranchIdx = useMemo(() => submission.quoteData?.offeredQuotes?.findIndex((o) => o.branchCode === BusinessConstant.BRANCH_CODE_CUSTOM), [submission.quoteData?.offeredQuotes]);
    const quoteTotal = submission.quoteData.offeredQuotes[quoteDataCustomBranchIdx !== -1 ? quoteDataCustomBranchIdx : 0].premium.total;
    const quoteID = submission.quoteID;
    const sessionUUID = submission.sessionUUID;
    const [ isButtonsDisabled, setButtonsDisabled] = useState(false);
    const [ totalPremiumForLowDed, setTotalPremiumForLowDed] = useState(undefined);
    const [ totalPremiumForEngDed, setTotalPremiumForEngDed] = useState(undefined);
    const [ deductible, setDeductible] = useState(undefined);
    const [ englishDeductible, setEnglishDeductible] = useState(undefined);
    const [ lowDeductible, setLowDeductible] = useState(undefined);
    const [ chosenDeductible, setChosenDeductible] = useState(undefined);
    const [ englishChosenDeductible, setEnglishChosenDeductible] = useState(undefined);
    const [ lowChosenDeductible, setLowChosenDeductible] = useState(undefined);
    const [ englishDeductibleVisible, setEnglishDeductibleVisible] = useState(false);
    const [ lowDeductibleVisible, setLowDeductibleVisible] = useState(false);
    const [ chosenDeductibleSet, setChosenDeductibleSet] = useState([]);
    const [ isMoreThanOneVehicleHavingMaterialDamageSelected, setIsMoreThanOneVehicleHavingMaterialDamageSelected ] = useState(false);

    useEffect(() => {
        offeredQuoteLowDeductible?.coverages.vehicleCoverages.forEach((vehicle) => {
            vehicle.coverages.forEach((coverage) => {
                if ((coverage.publicID === BusinessConstant.COVERAGE_PUBLICID_PPV_MATERIAL_DAMAGE || coverage.publicID === BusinessConstant.COVERAGE_PUBLICID_PCV_MATERIAL_DAMAGE) && coverage.selected) {
                    if (coverage.terms[1] !== undefined &&
                        (coverage.terms[1].patternCode === BusinessConstant.COVERAGE_TERM_PATTERNCODE_PPV_MATERIAL_DAMAGE || coverage.terms[1].patternCode === BusinessConstant.COVERAGE_TERM_PATTERNCODE_PCV_MATERIAL_DAMAGE_QQ) &&
                        coverage.terms[1].chosenTermValue !== undefined) {
                        setLowChosenDeductible(coverage.terms[1].chosenTermValue);
                    }
                    if (coverage.terms[2] !== undefined &&
                        (coverage.terms[2].patternCode === BusinessConstant.COVERAGE_TERM_PATTERNCODE_PPV_MATERIAL_DAMAGE_DEDUCTIBLE ||coverage.terms[2].patternCode === BusinessConstant.COVERAGE_TERM_PATTERNCODE_PCV_MATERIAL_DAMAGE_DEDUCTIBLE) &&
                        coverage.terms[2].chosenTermValue !== undefined) {
                        setLowDeductible(coverage.terms[2].directValue);
                    }
                    setTotalPremiumForLowDed(submission.quoteData.offeredQuotes.find((offering) => offering.branchName.includes('- Low')).premium.total);
                }
            });
        });
    }, [offeredQuoteLowDeductible?.coverages.vehicleCoverages, submission.quoteData.offeredQuotes]);

    useEffect(() => {
        offeredQuoteEnglishDeductible?.coverages.vehicleCoverages.forEach((vehicle) => {
            vehicle.coverages.forEach((coverage) => {
                if ((coverage.publicID === BusinessConstant.COVERAGE_PUBLICID_PPV_MATERIAL_DAMAGE || coverage.publicID === BusinessConstant.COVERAGE_PUBLICID_PCV_MATERIAL_DAMAGE) && coverage.selected) {
                    if (coverage.terms[1] !== undefined &&
                        (coverage.terms[1].patternCode === BusinessConstant.COVERAGE_TERM_PATTERNCODE_PPV_MATERIAL_DAMAGE || coverage.terms[1].patternCode === BusinessConstant.COVERAGE_TERM_PATTERNCODE_PCV_MATERIAL_DAMAGE_QQ) &&
                        coverage.terms[1].chosenTermValue !== undefined) {
                        setEnglishChosenDeductible(coverage.terms[1].chosenTermValue);
                    }
                    if (coverage.terms[2] !== undefined &&
                        (coverage.terms[2].patternCode === BusinessConstant.COVERAGE_TERM_PATTERNCODE_PPV_MATERIAL_DAMAGE_DEDUCTIBLE || coverage.terms[2].patternCode === BusinessConstant.COVERAGE_TERM_PATTERNCODE_PCV_MATERIAL_DAMAGE_DEDUCTIBLE) &&
                        coverage.terms[2].chosenTermValue !== undefined) {
                        setEnglishDeductible(coverage.terms[2].directValue);
                    }
                    setTotalPremiumForEngDed(submission.quoteData.offeredQuotes.find((offering) => offering.branchName.includes('- English')).premium.total);
                }
            });
        });
    }, [offeredQuoteEnglishDeductible?.coverages.vehicleCoverages, submission.quoteData.offeredQuotes]);

    // ALPH-11379 - to verify if low and english deductible button will be visible
    const lowEnglishDeductibleVisible = useCallback((chosenTerm) => {
        if ((chosenTerm === BusinessConstant.MATERIAL_DAMAGE_DEDUCTIBLE_HIGH_PPV || chosenTerm === BusinessConstant.MATERIAL_DAMAGE_DEDUCTIBLE_HIGH_PCV) ||
            (chosenTerm === BusinessConstant.MATERIAL_DAMAGE_DEDUCTIBLE_MEDIUM_PPV || chosenTerm === BusinessConstant.MATERIAL_DAMAGE_DEDUCTIBLE_MEDIUM_PCV)) {
            setEnglishDeductibleVisible(true);
            setLowDeductibleVisible(true);
        }
        if (chosenTerm === BusinessConstant.MATERIAL_DAMAGE_DEDUCTIBLE_LOW_PPV || chosenTerm === BusinessConstant.MATERIAL_DAMAGE_DEDUCTIBLE_LOW_PCV) {
            setEnglishDeductibleVisible(true);
        }
        if (chosenTerm === BusinessConstant.MATERIAL_DAMAGE_DEDUCTIBLE_ENGLISH_PPV || chosenTerm === BusinessConstant.MATERIAL_DAMAGE_DEDUCTIBLE_ENGLISH_PCV) {
            setLowDeductibleVisible(true);
        }
    }, []);

    useEffect(() => {
        const vehiclesWithMaterialDamageCov = [];
        vehicleCoverages?.forEach((vehicle) => {
            vehicle.coverages.forEach((coverage) => {
                if ((coverage.publicID === BusinessConstant.COVERAGE_PUBLICID_PPV_MATERIAL_DAMAGE || coverage.publicID === BusinessConstant.COVERAGE_PUBLICID_PCV_MATERIAL_DAMAGE) && coverage.selected) {
                    vehiclesWithMaterialDamageCov.push(coverage);
                }
            });
        });
        setIsMoreThanOneVehicleHavingMaterialDamageSelected(vehiclesWithMaterialDamageCov.length > 1);
    }, [vehicleCoverages]);

    useEffect(() => {
        vehicleCoverages?.forEach((vehicle) => {
            vehicle.coverages.forEach((coverage) => {
                if ((coverage.publicID === BusinessConstant.COVERAGE_PUBLICID_PPV_MATERIAL_DAMAGE || coverage.publicID === BusinessConstant.COVERAGE_PUBLICID_PCV_MATERIAL_DAMAGE) && coverage.selected) {
                    if (coverage.terms[1] !== undefined &&
                       (coverage.terms[1].patternCode === BusinessConstant.COVERAGE_TERM_PATTERNCODE_PPV_MATERIAL_DAMAGE ||
                        coverage.terms[1].patternCode === BusinessConstant.COVERAGE_TERM_PATTERNCODE_PCV_MATERIAL_DAMAGE_QQ)) {
                        setChosenDeductible(coverage.terms[1].chosenTermValue);
                        lowEnglishDeductibleVisible(coverage.terms[1].chosenTerm);
                        if(!chosenDeductibleSet.includes(coverage.terms[1].chosenTermValue)) {
                            const deductibleSet = chosenDeductibleSet;
                            deductibleSet.push(coverage.terms[1].chosenTermValue);
                            setChosenDeductibleSet(deductibleSet);
                        }

                    } else if (coverage.terms[1] !== undefined &&
                              (coverage.terms[1].patternCode === BusinessConstant.COVERAGE_TERM_DROPDOWN_PATTERNCODE_PPV_MATERIAL_DAMAGE ||
                               coverage.terms[1].patternCode === BusinessConstant.COVERAGE_TERM_DROPDOWN_PATTERNCODE_PCV_MATERIAL_DAMAGE )) {
                        if(!chosenDeductibleSet.includes(coverage.terms[1].chosenTermValue)) {
                            const deductibleSet = chosenDeductibleSet;
                            deductibleSet.push(coverage.terms[0].chosenTermValue);
                            setChosenDeductibleSet(deductibleSet);
                        }
                    }
                    if (coverage.terms[2] !== undefined &&
                       (coverage.terms[2].patternCode === BusinessConstant.COVERAGE_TERM_PATTERNCODE_PPV_MATERIAL_DAMAGE_DEDUCTIBLE ||
                        coverage.terms[2].patternCode === BusinessConstant.COVERAGE_TERM_PATTERNCODE_PCV_MATERIAL_DAMAGE_DEDUCTIBLE)) {
                        setDeductible(coverage.terms[2].directValue);
                    }
                }
            });
        });
    }, [chosenDeductibleSet, lowEnglishDeductibleVisible, vehicleCoverages]);

    const showSpanPrintDocumentText = useMemo(() => {
        return (chosenDeductible !== undefined && chosenDeductible.length !== 0 && chosenDeductibleSet.length === 1) ? styles.showSpan : styles.hideSpan;
    }, [chosenDeductible, chosenDeductibleSet.length]);

    const showSpanPrintLowDeductibleDocumentText = useMemo(() => {
        return (lowChosenDeductible !== undefined && lowChosenDeductible.length !== 0) ? styles.showSpan : styles.hideSpan;
    }, [lowChosenDeductible]);

    const showSpanPrintEnglishDeductibleDocumentText = useMemo(() => {
        return (englishChosenDeductible !== undefined && englishChosenDeductible.length !== 0) ? styles.showSpan : styles.hideSpan;
    }, [englishChosenDeductible]);

    const showDeductibleInButton = useMemo(() => {
        return !isMoreThanOneVehicleHavingMaterialDamageSelected ? styles.showSpan : styles.hideSpan;
    }, [isMoreThanOneVehicleHavingMaterialDamageSelected]);

    const printDocumentText = (
        <span>

            <span className={showSpanPrintDocumentText}>
                <b>{translator(messages.chosenDeductible)}{chosenDeductible}</b><br/>
                <span className={showDeductibleInButton}>
                    {translator(messages.deductible)}{deductible}<br/>
                </span>
            </span>
            {translator(messagesPolicyJob.vehiclePremium, {premium: quoteTotal !== undefined ? quoteTotal.amount.toFixed(2) : null})} € <br/> {translator(messages.downloadTariff)}
        </span>
    );

    const printLowDeductibleDocumentText = (
        <span>
            <span className={showSpanPrintLowDeductibleDocumentText}>
                <b>{translator(messages.alternativeDeductible)}{lowChosenDeductible}</b><br/>
                <span className={showDeductibleInButton}>
                    {translator(messages.deductible)}{lowDeductible}<br/>
                </span>
            </span>
            {translator(messagesPolicyJob.vehiclePremium, {premium: totalPremiumForLowDed !== undefined ? totalPremiumForLowDed.amount.toFixed(2) : null})} € <br/> {translator(messages.downloadTariff)}
        </span>
    );

    const printEnglishDeductibleDocumentText = (
        <span>
            <span className={showSpanPrintEnglishDeductibleDocumentText}>
                <b>{translator(messages.alternativeDeductible)}{englishChosenDeductible}</b><br/>
                <span className={showDeductibleInButton}>
                    {translator(messages.deductible)}{englishDeductible}<br/>
                </span>
            </span>
            {translator(messagesPolicyJob.vehiclePremium, {premium: totalPremiumForEngDed !== undefined ? totalPremiumForEngDed.amount.toFixed(2) : null})} € <br/> {translator(messages.downloadTariff)}
        </span>
    );

    /**
     * TODO: Document IDs are generated with a timestamp with 'seconds'-precision.
     * Workaround: This method sets the buttons to disabled for one second to avoid document IDs with the same timestamp (PV-21884)
     */
    const blockButtons = () => {
        setButtonsDisabled(true);
        setTimeout(() => setButtonsDisabled(false), 1000);
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    const overrideProps = {
        printDocument: {
            quoteID: quoteID,
            sessionUUID: sessionUUID,
            branchName: branchName,
            buttonLabel: printDocumentText,
            disabled: isButtonsDisabled,
            onBeforePrinting: blockButtons
        },
        printLowDeductibleDocument: {
            visible: lowDeductibleVisible && chosenDeductibleSet.length === 1 && !!offeredQuoteLowDeductible,
            quoteID: quoteID,
            sessionUUID: sessionUUID,
            branchName: offeredQuoteLowDeductible?.branchName,
            buttonLabel: printLowDeductibleDocumentText,
            disabled: isButtonsDisabled,
            onBeforePrinting: blockButtons
        },
        printEnglishDeductibleDocument: {
            visible: englishDeductibleVisible && chosenDeductibleSet.length === 1 && !!offeredQuoteEnglishDeductible,
            quoteID: quoteID,
            sessionUUID: sessionUUID,
            branchName: offeredQuoteEnglishDeductible?.branchName,
            buttonLabel: printEnglishDeductibleDocumentText,
            disabled: isButtonsDisabled,
            onBeforePrinting: blockButtons
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default SummaryPageDownloadDocumentComponent;
