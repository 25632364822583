import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

/*
    For now we place these pages here as they are common for PPV and PCV,
    if one of this becomes LOB specific we'll have to create the page in the LOB capability
 */
import PolicyChangePage from './pages/PolicyChanges/PolicyChangePage';
import DeclarationMifidPage from './pages/DeclarationMifid/DeclarationMifidPage';
import PaymentChangePage from './pages/PaymentChangePage/PaymentChangePage';

setComponentMapOverrides(
    {
        PolicyChangePage: { component: 'PolicyChangePage' },
        DeclarationMifidPage: { component: 'DeclarationMifidPage' },
        PaymentChangePage: { component: 'PaymentChangePage' }
    },
    {
        PolicyChangePage: PolicyChangePage,
        DeclarationMifidPage: DeclarationMifidPage,
        PaymentChangePage: PaymentChangePage
    }
);

export { default as PolicyChangesContextProvider, PolicyChangesContext } from './components/PolicyChangesContext/PolicyChangesContextProvider';
export { default as useWizardError } from './hooks/useWizardError';
export { default as useWizardExit } from './hooks/useWizardExit';
export { default as useWizardInit } from './hooks/useWizardInit';
export { default as commonQuoteMessages } from './policychange.messages';
