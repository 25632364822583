import { useCallback } from 'react';
import { useTranslator } from '@jutro/locale';
import { CoverablesUtil, VehicleUtil } from 'pv-portals-util-js';

const DriverComponentUtil = () => {

    const translator = useTranslator();

    const allVehiclesAsCheckboxes = useCallback((coverables) => {
        return coverables.vehicles.map((vehicle) => {
            return {
                code: `${vehicle.fixedId}`, // converts to string, CheckboxField wants String not Number
                name: VehicleUtil.getShortDisplayName(vehicle, translator)
            };
        });
    }, [translator]);

    const selectedVehicleCheckboxes = useCallback((coverables, driver) => {
        const vehicleIds = [];
        coverables.vehicles.forEach((vehicle) => {
            const relation = CoverablesUtil.findVehicleDriver(coverables, vehicle, driver);
            if (relation && relation.vehicleDriverUsage !== 'NDR') {
                // CheckboxField wants String not Number
                vehicleIds.push(`${vehicle.fixedId}`);
            }
        });
        return vehicleIds;
    }, []);

    const showClaimsQuestions = useCallback((coverables, driver) => {

        // true - if driver is assigned to TB or Mobilhome

        let res = false;
        coverables.vehicles.forEach((vehicle) => {
            const vc = vehicle.category;
            if (CoverablesUtil.isVehicleAssignedToDriver(coverables, vehicle, driver) &&
                (VehicleUtil.isTourismAndBusiness(vc) || VehicleUtil.isMobilhomeVehicle(vc))) {
                res = true;
            }
        });
        return res;

    }, []);

    return {
        allVehiclesAsCheckboxes,
        selectedVehicleCheckboxes,
        showClaimsQuestions
    };
};

export default DriverComponentUtil;
