import { defineMessages } from 'react-intl';

export default defineMessages({
    contractChangeDetails: {
        id: 'gateway.views.policy-detail.Policy Changes details',
        defaultMessage: 'Contract Change Details'
    },
    vehicles: {
        id: 'gateway.directives.policy-change-history.Vehicles',
        defaultMessage: 'Vehicles'
    },
    changesMade: {
        id: 'gateway.directives.policy-change-history.Changes Made',
        defaultMessage: 'Changes made:'
    },
    drivers: {
        id: 'gateway.directives.policy-change-history.Drivers',
        defaultMessage: 'Drivers'
    },
    coverages: {
        id: 'gateway.directives.policy-change-history.Coverages',
        defaultMessage: 'Coverages'
    },
    payments: {
        id: 'gateway.directives.policy-change-history.Payments',
        defaultMessage: 'Payments'
    },
    changedVehicle: {
        id: 'gateway.directives.policy-change-history.changed vehicle',
        defaultMessage: 'Changed vehicle(/s)'
    },
    changedDriver: {
        id: 'gateway.directives.policy-change-history.changed driver',
        defaultMessage: 'Changed driver(/s)'
    },
    changedCoverage: {
        id: 'gateway.directives.policy-change-history.changed coverage',
        defaultMessage: 'Changed coverage(/s)'
    },
    addedVehicle: {
        id: 'gateway.directives.policy-change-history.added vehicle',
        defaultMessage: 'Added vehicle(/s)'
    },
    addedDriver: {
        id: 'gateway.directives.policy-change-history.added driver',
        defaultMessage: 'Added driver(/s)'
    },
    addedCoverage: {
        id: 'gateway.directives.policy-change-history.added coverage',
        defaultMessage: 'Added coverage(/s)'
    },
    removedVehicle: {
        id: 'gateway.directives.policy-change-history.removed vehicle',
        defaultMessage: 'Removed vehicle(/s)'
    },
    removedDriver: {
        id: 'gateway.directives.policy-change-history.removed driver',
        defaultMessage: 'Removed driver(/s)'
    },
    removedCoverage: {
        id: 'gateway.directives.policy-change-history.removed coverage',
        defaultMessage: 'Removed coverage(/s)'
    },
    removedPayment: {
        id: 'gateway.directives.policy-change-history.removed payment',
        defaultMessage: 'Changed payment plan'
    }
});
