import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


export default class GatewayBillingService {
    static getAccountBillingSummary(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
            'getAccountBillingDetails', [accountNumber], additionalHeaders);
    }

    static getAccountBillingSummaryData(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
            'getAccountBillingSummary', [accountNumber], additionalHeaders);
    }

    static getPaymentGroupPaymentInformation(accountNumber, streamInternalId,
        additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
            'getPaymentGroupPaymentInformation', [accountNumber, streamInternalId], additionalHeaders);
    }

    static setPaymentGroupPaymentInformation(accountNumber, streamInternalId, paymentMethod,
        additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
            'setPaymentGroupPaymentInformation', [accountNumber, streamInternalId, paymentMethod], additionalHeaders);
    }

    static getPolicyBillingSummary(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
            'getPolicyBillingSummary', [policyNumber], additionalHeaders);
    }

    static getDisbursementsInfo(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
            'getDisbursementsInfo_PV', [policyNumber], additionalHeaders);
    }

    static changeCollectionMethod(accountNumber, invoiceNumber, eventType, isPaymentDone, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
            'changeCollectionMethod_PV', [accountNumber, invoiceNumber, eventType, isPaymentDone], additionalHeaders);
    }

    static handlePortalBRBATKRequests(accountNumber, invoiceNumber, eventType, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBilling'),
            'handlePortalBRB_ATKRequests_PV', [accountNumber, invoiceNumber, eventType], additionalHeaders);
    }

    static getDocuments(docUIDs, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingDocument'),
            'getDocuments_PV', [docUIDs], additionalHeaders);
    }

    static getNonInvoiceDocuments(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingDocument'),
            'getNonInvoiceDocuments_PV', [policyNumber], additionalHeaders);

    }

    static getCountNonInvoiceDocuments(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayBillingDocument'),
            'getCountNonInvoiceDocuments_PV', [policyNumber], additionalHeaders);
    }

}
