import { getProxiedServiceUrl, generateURL } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import { ServiceManager } from '@jutro/legacy/services';
import _ from 'lodash';
import appConfig from 'app-config';
import { DocumentUploadService } from '@xengage/gw-portals-document-js';

export default class GatewayDocumentService {
    static generateUploadToken(additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayDocument'), 'generateUploadToken', [], additionalHeaders);
    }

    static removeDocument(params, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayDocument'), 'removeDocument', [params], additionalHeaders);
    }

    static uploadDocument(file, documentMetadata, additionalHeaders) {
        return DocumentUploadService.send(getProxiedServiceUrl('gatewayUpload'), file, documentMetadata, additionalHeaders);
    }

    /**
     * Generate the custom link to the static GatewayDocumentDownload.html page. Replaces the OOTB implementation: DocumentDownloadService.getDocumentLink(getProxiedServiceUrl('downloadDocument'), documentID, token);
     *
     * @param {String} documentID id of the document (publicID)
     * @param {String} token document token
     * @param {String} defaultServer pc or bc as a download server (default value 'pc')
     * @returns {String} link to donwload document
     * @memberof LoadSaveService
     */
    static downloadDocument(documentID, token, defaultServer = 'pc') {
        const host = _.get(appConfig, 'env.DEPLOYMENT_URL');
        const baseUrl = (defaultServer === 'bc') ? getProxiedServiceUrl('downloadDocumentBC') : getProxiedServiceUrl('downloadDocumentPC');
        const redirectRoute = _.get(appConfig, 'gatewayDocumentDownload') || '';
        const localeService = ServiceManager.getService('locale-service');
        const lang = localeService.getStoredLanguage();

        // retry/re-download URL is needed to handle proxied URLs
        const retryUrl = generateURL(baseUrl, { urlParam: documentID }).replace('?', '');

        const params = {
            urlParam: documentID,
            token,
            portalRoute: `${host}/${redirectRoute}`, // waiting page
            language: lang,
            retryUrl // re-download page
        };

        const finalUrl = generateURL(baseUrl, params);
        return finalUrl;
    }
}
