import _ from 'lodash';
import React, { useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { EdgeErrorParser } from 'pv-portals-util-js';
import { useModal } from '@jutro/components';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { messages as pvPlatformMessages } from 'pv-platform-translations';

const useWizardError = ({ location, setHasErrorOccurred }) => {

    const { showAlert } = useModal();

    const handleError = useCallback((error) => {
        console.error(error);
        const jobIDFromWizard = _.get(error, 'gwInfo.params[0].jobID');
        const jobIDFromLocation = _.get(location, 'state.policyNumber');
        const msg = EdgeErrorParser.getErrorMessage(error);
        const messageText = msg || pvPlatformMessages.genericTimeoutErrorMsg;
        let redirectPath = `/contactAgent/${jobIDFromLocation}`;
        if (!_.isEmpty(jobIDFromWizard)) {
            redirectPath = `/change/${jobIDFromWizard}/summary`;
        }
        const state = {
            pathname: redirectPath,
            state: {
                redirectPath: '/home',
            }
        };

        return showAlert({
            title: pvPlatformMessages.genericTechnicalErrorTitle,
            message: messageText,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: platformMessages.ok
        }).then(() => {
            setHasErrorOccurred(true);
            return <Redirect to={state} />;
        }, _.noop);
    }, [location, setHasErrorOccurred, showAlert]);

    return handleError;
};

export default useWizardError;
