import { defineMessages } from 'react-intl';

export default defineMessages({
    HomeDetails: {
        id: 'policy.ho.directives.policy-dwelling-info.Home Details',
        defaultMessage: 'Home Details'
    },
    DistanceToFireHydrant: {
        id: 'policy.ho.directives.policy-dwelling-info.Distance To Fire Hydrant',
        defaultMessage: 'Distance to Fire Hydrant'
    },
    DistanceToFireStation: {
        id: 'policy.ho.directives.policy-dwelling-info.Distance To Fire Station',
        defaultMessage: 'Distance to Fire Station'
    },
    FeetToFireHydrant: {
        id: 'policy.ho.directives.policy-dwelling-info.Feet To Fire Hydrant',
        defaultMessage: '{distance} ft'
    },
    MilesToFireStation: {
        id: 'policy.ho.directives.policy-dwelling-info.Miles',
        defaultMessage: '{miles} miles'
    },
    FloodingorFireHazard: {
        id: 'policy.ho.directives.policy-dwelling-info.Flooding or Fire Hazard',
        defaultMessage: 'Flooding or Fire Hazard'
    },
    WithinftofCommercialProperty: {
        id: 'policy.ho.directives.policy-dwelling-info.Within 300ft of Commercial Property',
        defaultMessage: 'Within 300ft of Commercial Property'
    },
    LocationType: {
        id: 'policy.ho.directives.policy-dwelling-info.Location Type',
        defaultMessage: 'Location Type'
    },
    ResidenceType: {
        id: 'policy.ho.directives.policy-dwelling-info.Residence Type',
        defaultMessage: 'Residence Type'
    },
    Homeisusedas: {
        id: 'policy.ho.directives.policy-dwelling-info.Home Type',
        defaultMessage: 'Home Type'
    },
    DwellingOccupied: {
        id: 'policy.ho.directives.policy-dwelling-info.Dwelling Occupied',
        defaultMessage: 'Dwelling Occupied'
    },
    Mortgagee: {
        id: 'policy.ho.directives.policy-dwelling-info.Mortgagee',
        defaultMessage: 'Mortgagee'
    },
    MortgageeName: {
        id: 'policy.ho.directives.policy-dwelling-info.Mortgagee Name',
        defaultMessage: 'Name'
    },
    MortgageeStreet: {
        id: 'policy.ho.directives.policy-dwelling-info.Mortgagee Street',
        defaultMessage: 'Street'
    },
    MortgageeCity: {
        id: 'policy.ho.directives.policy-dwelling-info.Mortgagee City',
        defaultMessage: 'City'
    },
    MortgageeState: {
        id: 'policy.ho.directives.policy-dwelling-info.Mortgagee State',
        defaultMessage: 'State'
    },
    ConstructionDetails: {
        id: 'policy.ho.directives.policy-dwelling-info.Construction Details',
        defaultMessage: 'Construction Details'
    },
    YearBuilt: {
        id: 'policy.ho.directives.policy-dwelling-info.Year Built',
        defaultMessage: 'Year Built'
    },
    ofStories: {
        id: 'policy.ho.directives.policy-dwelling-info.No of Stories',
        defaultMessage: 'No. of Stories'
    },
    Garage: {
        id: 'policy.ho.directives.policy-dwelling-info.Garage',
        defaultMessage: 'Garage'
    },
    ConstructionType: {
        id: 'policy.ho.directives.policy-dwelling-info.Construction Type',
        defaultMessage: 'Construction Type'
    },
    FoundationType: {
        id: 'policy.ho.directives.policy-dwelling-info.Foundation Type',
        defaultMessage: 'Foundation Type'
    },
    RoofType: {
        id: 'policy.ho.directives.policy-dwelling-info.Roof Type',
        defaultMessage: 'Roof Type'
    },
    RoofUpgraded: {
        id: 'policy.ho.directives.policy-dwelling-info.Roof Upgraded',
        defaultMessage: 'Roof Upgraded'
    },
    PlumbingType: {
        id: 'policy.ho.directives.policy-dwelling-info.Plumbing Type',
        defaultMessage: 'Plumbing Type'
    },
    PlumbingUpgraded: {
        id: 'policy.ho.directives.policy-dwelling-info.Plumbing Upgraded',
        defaultMessage: 'Plumbing Upgraded'
    },
    PrimaryHeatingType: {
        id: 'policy.ho.directives.policy-dwelling-info.Primary Heating Type',
        defaultMessage: 'Primary Heating Type'
    },
    HeatingUpgraded: {
        id: 'policy.ho.directives.policy-dwelling-info.Heating Upgraded',
        defaultMessage: 'Heating Upgraded'
    },
    WiringType: {
        id: 'policy.ho.directives.policy-dwelling-info.Wiring Type',
        defaultMessage: 'Wiring Type'
    },
    ElectricalSystem: {
        id: 'policy.ho.directives.policy-dwelling-info.Electrical System',
        defaultMessage: 'Electrical System'
    },
    TotalTaxes: {
        id: 'policy.ho.directives.policy-dwelling-info.Total Taxes',
        defaultMessage: 'Total Taxes'
    },
    TotalCost: {
        id: 'policy.ho.directives.policy-dwelling-info.Total Cost',
        defaultMessage: 'Total Cost'
    },
    WiringUpgraded: {
        id: 'policy.ho.directives.policy-dwelling-info.Wiring Upgraded',
        defaultMessage: 'Wiring Upgraded'
    },
    ProtectionDetails: {
        id: 'policy.ho.directives.policy-dwelling-info.Protection Details',
        defaultMessage: 'Protection Details'
    },
    FireExtinguisher: {
        id: 'policy.ho.directives.policy-dwelling-info.Fire Extinguisher',
        defaultMessage: 'Fire Extinguisher'
    },
    BurglarAlarm: {
        id: 'policy.ho.directives.policy-dwelling-info.Burglar Alarm',
        defaultMessage: 'Burglar Alarm'
    },
    MonitoringCenterFireAlarm: {
        id: 'policy.ho.directives.policy-dwelling-info.Monitoring Center Fire Alarm',
        defaultMessage: 'Monitoring Center Fire Alarm'
    },
    SmokeAlarms: {
        id: 'policy.ho.directives.policy-dwelling-info.Smoke Alarms',
        defaultMessage: 'Smoke Alarms'
    },
    PolicyType: {
        id: 'policy.ho.directives.policy-dwelling-info.Policy Type',
        defaultMessage: 'Policy Type'
    },
    DeadBolts: {
        id: 'policy.ho.directives.policy-dwelling-info.Dead Bolts',
        defaultMessage: 'Deadbolts'
    },
    ResidenceVisibletoNeighbours: {
        id: 'policy.ho.directives.policy-dwelling-info.Residence Visible to Neighbours',
        defaultMessage: 'Residence Visible to Neighbours'
    },
    SprinklerSystemType: {
        id: 'policy.ho.directives.policy-dwelling-info.Sprinkler System Type',
        defaultMessage: 'Sprinkler System Type'
    },
    RoomersorBorders: {
        id: 'policy.ho.directives.policy-dwelling-info.Roomers or Borders',
        defaultMessage: 'Roomers or Borders'
    },
    ofUnits: {
        id: 'policy.ho.directives.policy-dwelling-info.No. of Units',
        defaultMessage: 'No. of Units'
    },
    FireplaceorWoodstove: {
        id: 'policy.ho.directives.policy-dwelling-info.Fireplace or Woodstove',
        defaultMessage: 'Fireplace or Woodstove'
    },
    SwimmingPool: {
        id: 'policy.ho.directives.policy-dwelling-info.Swimming Pool',
        defaultMessage: 'Swimming Pool'
    },
    Trampoline: {
        id: 'policy.ho.directives.policy-dwelling-info.Trampoline',
        defaultMessage: 'Trampoline'
    },
    FloodingWaterLeakinpastYears: {
        id: 'policy.ho.directives.policy-dwelling-info.Flooding/Water Leak in past 5 Years',
        defaultMessage: 'Flooding/Water Leak in past 5 Years'
    },
    Coverages: {
        id: 'policy.ho.directives.policy-dwelling-info.Coverages',
        defaultMessage: 'Coverages'
    },
    SectionICoverages: {
        id: 'policy.ho.directives.policy-dwelling-info.Section I Coverages',
        defaultMessage: 'Section I Coverages'
    },
    SectionIICoverages: {
        id: 'policy.ho.directives.policy-dwelling-info.Section II Coverages',
        defaultMessage: 'Section II Coverages'
    },
    AdditionalLiabilityCoverages: {
        id: 'policy.ho.directives.policy-dwelling-info.Additional Liability Coverages',
        defaultMessage: 'Additional Liability Coverages'
    },
    AdditionalPropertyCoverages: {
        id: 'policy.ho.directives.policy-dwelling-info.Additional Property Coverages',
        defaultMessage: 'Additional Property Coverages'
    },
    ScheduledItems: {
        id: 'policy.ho.directives.policy-dwelling-info.Scheduled Items',
        defaultMessage: 'Scheduled Items'
    },
    infoYes: {
        id: 'policy.ho.directives.policy-dwelling-info.Yes',
        defaultMessage: 'Yes'
    },
    infoNo: {
        id: 'policy.ho.directives.policy-dwelling-info.No',
        defaultMessage: 'No'
    }
});
