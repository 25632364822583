import { defineMessages } from 'react-intl';

export default defineMessages({
    coverableType: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Coverable type',
        defaultMessage: 'Coverable type'
    },
    modelType: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Model type',
        defaultMessage: 'Model type'
    },
    makeAndModel: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Make and model',
        defaultMessage: 'Make and model'
    },
    primaryUse: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Primary use',
        defaultMessage: 'Primary use'
    },
    catalogueValue: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Catalogue value',
        defaultMessage: 'Catalogue value'
    },
    optionsValue: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Options value',
        defaultMessage: 'Value of the options without discount (exclusive of VAT) (optional)'
    },
    accessoriesValue: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Accessories Value',
        defaultMessage: 'Value of the accessories (exclusive of VAT) (optional)'
    },
    invoiceValue: {
        id: 'quoteandbind.quickquote.common.directives.qq-editable-coverages.Invoice Value',
        defaultMessage: 'Invoice value'
    },
    expertValue: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Expert value',
        defaultMessage: 'Expert value'
    },
    carDealerValue: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Car dealer value',
        defaultMessage: 'Car dealer value'
    },
    fuelType: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Fuel type',
        defaultMessage: 'Fuel type'
    },
    maximumAuthorizedMass: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Maximum Authorized Mass (kg)',
        defaultMessage: 'Maximum Authorized Mass (kg)'
    },
    enginePower: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Engine power',
        defaultMessage: 'Engine power'
    },
    vehicleWeight: {
        id: 'quoteandbind.pcv.views.pcv-policy-info.Vehicle weight (kg)',
        defaultMessage: 'Vehicle weight (kg)'
    },
    totalWeightTractor: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Is the total weight of the tractor + trailer > 12T?',
        defaultMessage: 'Is the total weight of the tractor + trailer > 12T?'
    },
    licencePlate: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Licence plate',
        defaultMessage: 'Licence plate'
    },
    vinNumber: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Vin number',
        defaultMessage: 'Vin number'
    },
    numberOfKilometersPerYear: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Number of kilometers per year',
        defaultMessage: 'Number of kilometers per year'
    },
    firstRegistrationDatePV: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Year first registratio',
        defaultMessage: 'Year first registration'
    },
    securitySystemInstalled: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Security system(s) installed',
        defaultMessage: 'Security system(s) installed'
    },
    automaticEmergencyBraking: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Automatic Emergency Breaking?',
        defaultMessage: 'Automatic Emergency Breaking'
    },
    laneDepartureWarningSystem: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Lane Departure Warning System',
        defaultMessage: 'Lane departure warning system'
    },
    driverDrowsinessDetection: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Driver Drowsiness Detection?',
        defaultMessage: 'Driver drowsiness detection'
    },
    adaptiveCruiseControl: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Adaptive Cruise Control?',
        defaultMessage: 'Adaptive cruise control'
    },
    blindSpotSystem: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Blind Spot System?',
        defaultMessage: 'Blind spot system'
    },
    cyclAndPedestrDetectSystem: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Cyclists and pedestrians detection system?',
        defaultMessage: 'Cyclists and pedestrians detection system'
    },
    isVehicleParked: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Is the vehicle parked in a garage or carport?',
        defaultMessage: 'Is the vehicle parked in a garage or carport?'
    },
    isVehicleMotoCross: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Is the vehicle a moto-cross?',
        defaultMessage: 'Is the vehicle a moto-cross?'
    },
    vehicleSidecar: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Does the vehicle have a sidecar?',
        defaultMessage: 'Does the vehicle have a sidecar?'
    },
    vehicleCarryDangerousGoods: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Does the vehicle carry dangerous goods',
        defaultMessage: 'Does the vehicle carry dangerous goods?'
    },
    typeOfPreparation: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Type of preparation?',
        defaultMessage: 'Type of preparation?'
    },
    ADRClassValue: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-vehicle.ADR Class Value',
        defaultMessage: 'ADR Class Value'
    },
    isVehicleUsedForInternational: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Is the vehicle used for international transport?',
        defaultMessage: 'Is the vehicle used for international transport?'
    },
    UBIAvailable: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.UBI Available?',
        defaultMessage: 'UBI Available?'
    },
    vehiclePullATrailder: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Does the vehicle pull a trailer > 750 kg?',
        defaultMessage: 'Does the vehicle pull a trailer > 750 kg?'
    },
    numberOfClaimsReported: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Number of claims reported in the last 5 years',
        defaultMessage: 'Number of claims reported in the last 5 years'
    },
    numberOfYearsSinceLast: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Number of years since last claim TPL in fault',
        defaultMessage: 'Years since last claim TPL in fault'
    },
    numberOfClaimsForMaterialDamage: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Number of claims for matarial damage in the past 5 years',
        defaultMessage: 'Number of claims for material damage in the past 5 years'
    },
    engineCapacity: {
        id: 'quoteandbind.ppv.directives.templates.ppv-vehicle-lookup-based-on-make-and-model.Engine Capacity',
        defaultMessage: 'Engine Capacity (CC)'
    },
    insuredValue: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Insured value',
        defaultMessage: 'Insured value'
    },
    gearBox: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Gear Box',
        defaultMessage: 'Gear Box'
    },
    designatedDriver: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Designated Driver',
        defaultMessage: 'Does vehicle has a designated driver?'
    }
});
