import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PVFinancialInstitutionService } from 'pv-capability-policyjob';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import metadata from './FinancialInstitutionComponent.metadata.json5';
import styles from "./FinancialInstitutionComponent.module.scss";
import './FinancialInstitutionComponent.messages';

function FinancialInstitutionComponent(props) {

    const {
        vehicleVM,
        captureDetails,
        showPlanSelection,
        onValueChange,
        vehiclePath,
        labelPosition,
        phoneWide,
        showOptional,
        showErrors,
        showRequired,
        onValidate,
        id,
        brand
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const { authHeader } = useAuthentication();
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, vehicleVM]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${vehiclePath}.${changedPath}`;
        if (onValueChange) {
            onValueChange(value, fullPath);
        }
    }, [onValueChange, vehiclePath]);

    const clearFinancialCompDetails = useCallback(() => {

        // reset many fields ...
        _.set(vehicleVM, 'financialCompanyName_PV', null);
        _.set(vehicleVM, 'contractNumber_PV', null);
        _.set(vehicleVM, 'contractEndDate_PV', null);
        _.set(vehicleVM, 'financialCompanyType_PV', null);
        _.set(vehicleVM, 'financialCompanyBDPId_PV', null);
        _.set(vehicleVM, 'financialCompanyBrand_PV', null);

    }, [vehicleVM]);

    const handleFinancialPlanValueChange = useCallback((value, changedPath) => {
        _.set(vehicleVM, changedPath, value);

        if (!value) {
            clearFinancialCompDetails();
        }

        if (onValueChange) {
            onValueChange(vehicleVM, vehiclePath);
        }
    }, [vehicleVM, onValueChange, vehiclePath, clearFinancialCompDetails]);

    const onSearchItems = useCallback(async (userInput) => {
        let results = [];
        if (_.isString(userInput) && userInput.length >= 3) {
            results = await PVFinancialInstitutionService.searchFinancialCompaniesUsingPartialName(userInput, brand, authHeader);
        }
        results = results.map((item) => {
            // making ITEM look like code/name dropdown value
            item.code = item.financialCompanyBdpId_PV; // selected value
            item.name = item.financialCompanyName_PV; // displayed in the dropdown
            return item;
        });
        setSearchResults(results);
        return results;
    }, [brand, authHeader]);

    const handleUserSelection = useCallback((companyCode) => {

        const company = searchResults.find((item) => item.code === companyCode);

        // set many fields ...
        _.set(vehicleVM, 'financialCompanyName_PV', company.financialCompanyName_PV);
        _.set(vehicleVM, 'contractNumber_PV', company.contractNumber_PV);
        _.set(vehicleVM, 'contractEndDate_PV', company.contractEndDate_PV);
        _.set(vehicleVM, 'financialCompanyType_PV', company.financialCompanyType_PV);
        _.set(vehicleVM, 'financialCompanyBDPId_PV', company.financialCompanyBdpId_PV);
        _.set(vehicleVM, 'financialCompanyBrand_PV', company.financialCompanyBrand_PV);

        onValueChange(vehicleVM, vehiclePath);
    }, [searchResults, vehicleVM, vehiclePath, onValueChange]);

    const handleOnBlur = useCallback(() => {
        setSearchResults([]);
    }, []);

    const isSearchVisible = vehicleVM?.financialPlan?.value;
    const isAdditionalFieldsVisible = _.isString(vehicleVM?.financialCompanyName_PV?.value);

    const resolvers = {
        resolveClassNameMap: styles
    };

    const overrideProps = {
        '@field': {
            labelPosition,
            phoneWide,
            showOptional,
            showRequired
        },
        financialPlan: {
            visible: showPlanSelection,
            onValueChange: handleFinancialPlanValueChange
        },
        financialCompanyName: {
            visible: captureDetails && isSearchVisible,
            className: searchResults.length > 0 ? "" : "hideDropdownIndicator",
            onLoadValues: onSearchItems,
            onValueChange: handleUserSelection,
            value: vehicleVM?.financialCompanyBdpId_PV?.value,
            noOptionsMessage: null,
            onBlur: handleOnBlur
        },
        financialCompanyType: {
            visible: isAdditionalFieldsVisible
        },
        contractNumber: {
            visible: isAdditionalFieldsVisible
        },
        contractEndDate: {
            visible: isAdditionalFieldsVisible
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={vehicleVM}
            showErrors={showErrors}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

FinancialInstitutionComponent.propTypes = {
    vehicleVM: PropTypes.shape({}).isRequired,
    vehiclePath: PropTypes.string.isRequired,

    captureDetails: PropTypes.bool.isRequired,
    showPlanSelection: PropTypes.bool.isRequired,

    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,

    labelPosition: PropTypes.string,
    phoneWide: PropTypes.shape({}),
    showOptional: PropTypes.bool,
    showErrors: PropTypes.bool,
    showRequired: PropTypes.bool,
    id: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired
};

FinancialInstitutionComponent.defaultProps = {
};

export default FinancialInstitutionComponent;
