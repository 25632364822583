import React from 'react';
import { DateField } from '@jutro/components';
import messages from './DateFieldComponent.messages';

// We filter these messages as we rely on Jutro required validation to show them
// instead of the message from the VM
const MESSAGES_TO_BE_FILTERED = ['This is a required field', 'Ce champ est obligatoire', 'Dit is een vereist veld'];

function DateFieldComponent(props) {
    const { validationMessages = [] } = props;
    const filteredValidationMessages = validationMessages.filter((msg) => !MESSAGES_TO_BE_FILTERED.includes(msg));
    return (
        <DateField {...props} messageProps={messages} validationMessages={filteredValidationMessages} />
    );
}

DateFieldComponent.propTypes = DateField.propTypes;
DateFieldComponent.defaultProps = {
    ...DateField.defaultProps,
    openWithIconOnly: true
};

export default DateFieldComponent;
