import { defineMessages } from 'react-intl';

export default defineMessages({
    financialPlan: {
        id: "quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Financial Plan",
        defaultMessage: "Is this vehicle financed by a leasing or a loan?"
    },
    finantialCompanySearchBox: {
        id: "quoteandbind.ppv.views.ppv-additional-info.Financial Company",
        defaultMessage: "Financial Company"
    },
    finantialCompanyType: {
        id: "quoteandbind.ppv.views.ppv-additional-info.Type of Company",
        defaultMessage: "Financial Institution or Leasing Company"
    },
    finantialCompanyContract: {
        id: "quoteandbind.ppv.views.ppv-additional-info.Contract Dossier Number",
        defaultMessage: "Contract Number"
    },
    finantialCompanyContractEnd: {
        id: "quoteandbind.ppv.views.ppv-additional-info.End date of the Contract",
        defaultMessage: "End date of the Contract"
    }
});
