import React, { useState, useCallback } from 'react';
import cx from 'classnames';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import metadata from './SearchField.metadata.json5';
import styles from './SearchField.module.scss';

const SearchField = (props) => {
    const {
        isDarkTheme,
        onSearchValueChange,
        onLoadValues,
        searchFieldPlaceholder
    } = props;

    const [searchValue, setSearchValue] = useState();
    const translator = useTranslator();

    const onSearchChange = useCallback((val) => {
        setSearchValue(val);
        if (onSearchValueChange) {
            onSearchValueChange(val);
        }
    }, [onSearchValueChange]);

    const overrideProps = {
        searchField: {
            value: searchValue,
            className: cx({
                [styles.darkSearchContainer]: isDarkTheme,
                [styles.searchContainer]: !isDarkTheme,
            }),
            placeholder: translator(searchFieldPlaceholder),
            internalClassNames: styles
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onSearchChange,
            onLoadValues
        }
    }

    return <MetadataContent
        uiProps={metadata.componentContent}
        overrideProps={overrideProps}
        {...resolvers} />
};


export default SearchField;
