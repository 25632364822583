import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import DriverInfo from './DriverInfo/DriverInfo';
import metadata from './DriversDetails.metadata.json5';
import styles from './DriversDetails.module.scss';
import './DriversDetails.messages.js';

function DriversDetails(props) {
    const {
        submissionVM,
        pageLoBType,
        quickQuoteMode
    } = props;

    const generateDriversOverrides =  useCallback(() => {
        const drivers = _.get(submissionVM, `lobData.${pageLoBType}.coverables.drivers.value`, []);

        const overrides = drivers.map((driver, index) => {
            return {
                [`driver${index}`]: {
                    submissionVM,
                    pageLoBType,
                    quickQuoteMode
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [submissionVM, pageLoBType, quickQuoteMode]);

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        driversList: {
            defaultActiveTab: 'driverDetails0driversList',
            path: `lobData.${pageLoBType}.coverables.drivers.children`
        },
        ...generateDriversOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            DriverInfoComponent: DriverInfo
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

DriversDetails.propTypes = {
    data: PropTypes.shape({}),
    submissionVM: PropTypes.shape({}),
    pageLoBType: PropTypes.string,
    quickQuoteMode: PropTypes.bool
};

DriversDetails.defaultProps = {
    data: {},
    submissionVM: {},
    pageLoBType: undefined,
    quickQuoteMode: false
};

export default DriversDetails;
