import React, { useContext, useCallback, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useHistory } from 'react-router-dom';
import { useModal } from '@jutro/components';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { messages as platformMessagesPV } from 'pv-platform-translations';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { BusinessConstant, EdgeErrorParser} from 'pv-portals-util-js';
import { PolicyInfo, DriversDetails, VehiclesDetails, PremiumsDetails, SummaryPageDownloadDocumentComponent } from '../../components/SummaryDetails';
import styles from "./SummaryPage.module.scss";
import metadata from './SummaryPage.metadata.json5';
import messages from './SummaryPage.messages';

function SummaryPage(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const { QuickQuoteService, LoadSaveService } = useDependencies(['QuickQuoteService', 'LoadSaveService']);
    const history = useHistory();
    const translator = useTranslator();
    const { wizardData: submissionVM, updateWizardData } = props;
    const { authHeader } = useAuthentication();
    const [ quickQuoteMode ] = useState(_.get(submissionVM, 'baseData.quoteType.value.code') === 'Quick');
    const [ isPersonalVehicle ] =  useState(_.get(submissionVM, 'baseData.productCode.value') === BusinessConstant.PPV_PROD_CODE);
    const [ pageLoBType ] = useState(isPersonalVehicle ? 'ppvPersonalAuto_PV' : 'pcvCommercialAuto_PV');
    const { onValidate, isComponentValid, initialValidation, registerInitialComponentValidation } = useValidation('SummaryPage');
    const [ showError, setShowError ] = useState(false);
    const [ isVivium ] = useState(_.get(submissionVM, 'baseData.brand_PV.value.code') === BusinessConstant.PV_BRAND_VIVIUM);

    const { showAlert } = useModal();

    const handleError = useCallback(({ title, message, confirmationButtonText = platformMessages.ok, redirectToSummaryPage = true }) => {
        showAlert({
            title: title,
            message: message,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: confirmationButtonText
        }).then(() => {
            if (redirectToSummaryPage) {
                history.push(`/quotes/${_.get(submissionVM, 'quoteID.value')}/summary`);
            }
        }, _.noop);
    }, [history, submissionVM, showAlert]);

    useEffect(() => {
        const vm = viewModelService.changeContext(submissionVM, {
            SummaryQuestions: true
        });
        if (!isVivium) {
            // default for non-Vivium brands
            _.set(vm, 'bindData.declaration.producerAnalysisReq_PV', false);
        }
        updateWizardData(vm);
        // Only on first render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const shouldSkip = useCallback(() => {
        const { periodStatus } = _.get(submissionVM.value, 'baseData');
        return periodStatus === 'Proposal_pv' || periodStatus === 'Bound';
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(shouldSkip);
    }, [registerInitialComponentValidation, shouldSkip]);

    const onNext = useCallback(async () => {
        if(!isComponentValid) {
            setShowError(true);
            return false;
        }

        try {
            if (quickQuoteMode) {
                const response = await QuickQuoteService.referToUnderwriter_PV(submissionVM.value, authHeader);
                history.push(`/quotes/${_.get(response, 'quoteID')}/summary`);
            } else {
                const response = await LoadSaveService.updateSummaryAndProposeSubmissionPV(submissionVM.value, authHeader);
                _.set(submissionVM, 'value', response);
            }
        } catch (error) {
            if (EdgeErrorParser.isUWIssueError(error)) {
                handleError({
                    title: platformMessagesPV.uwIssueErrorTitle,
                    message: platformMessagesPV.uwIssueErrorMsg,
                    confirmationButtonText: platformMessagesPV.uwIssueErrorBtn
                });
            } else {
                const msg = EdgeErrorParser.getErrorMessage(error);
                handleError({
                    title: platformMessagesPV.genericTechnicalErrorTitle,
                    message: `${translator(platformMessagesPV.genericTechnicalErrorMsg)}\n${msg}`,
                    redirectToSummaryPage: false
                });
            }
            return false;
        }
        return submissionVM;
    }, [submissionVM, authHeader, QuickQuoteService, history, quickQuoteMode, LoadSaveService, isComponentValid, handleError, translator]);

    const isBlockingUWIssues = useMemo(() => {
        const underwritingIssues = submissionVM.underwritingIssues?.value || [];
        const offeredQuotes = (submissionVM.quoteData?.value?.offeredQuotes || [])[0];
        const hasBlockingUWIssues = !!offeredQuotes?.hasBlockingUWIssues;
        const onlyFreeTextUWBlockBind = underwritingIssues.length === 1 && underwritingIssues[0].issueType_PV === 'RequestFromProducer';
        return hasBlockingUWIssues && !onlyFreeTextUWBlockBind;
    }, [submissionVM.underwritingIssues?.value, submissionVM.quoteData?.value?.offeredQuotes]);

    const getContractName = useMemo(() => {
        const contractName = submissionVM.baseData.accountHolder.displayName.value;
        if(isPersonalVehicle) {
            return `${translator({
                id: submissionVM.baseData.accountHolder.prefix.value.name,
                defaultMessage: submissionVM.baseData.accountHolder.prefix.value.name
            })} ${contractName}`;
        }
        return contractName;
    }, [submissionVM.baseData.accountHolder.displayName.value, submissionVM.baseData.accountHolder.prefix.value?.name, isPersonalVehicle, translator]);

    const overrideProps = {
        '@field': {
            showOptional: false
        },
        underWritingIssuesMsg: {
            visible: quickQuoteMode ? !_.isEmpty(submissionVM.underwritingIssues.value) : isBlockingUWIssues,
            quoteId: submissionVM.quoteID.value
        },
        policyHolderDetailsContainer: {
            submissionVM,
            isPersonalVehicle
        },
        contractHolderName: {
            value: getContractName
        },
        driversDetails: {
            submissionVM,
            pageLoBType,
            quickQuoteMode
        },
        vehiclesDetails: {
            submissionVM,
            pageLoBType,
            quickQuoteMode
        },
        premiumsDetails: {
            submissionVM,
            pageLoBType
        },
        declarationsDetails: {
            submissionVM,
            isBlockingUWIssues,
            showError
        },
        summaryPageDownloadDocumentComponent: {
            visible: quickQuoteMode,
            submission: submissionVM.value,
            vehicleCoverages: _.get(submissionVM,`value.lobData.${pageLoBType}.offerings`).find((offering) => offering.branchCode === BusinessConstant.BRANCH_CODE_CUSTOM).coverages.vehicleCoverages,
            offeredQuoteLowDeductible: _.get(submissionVM,`value.lobData.${pageLoBType}.offerings`).find((offering) => offering.branchName.includes('- Low')),
            offeredQuoteEnglishDeductible: _.get(submissionVM,`value.lobData.${pageLoBType}.offerings`).find((offering) => offering.branchName.includes('- English')),
            branchName: 'CUSTOM'
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        },
        resolveComponentMap: {
            DriversDetailsComponent: DriversDetails,
            VehiclesDetailsComponent: VehiclesDetails,
            PremiumsDetailsComponent: PremiumsDetails,
            policyInfoComponent: PolicyInfo,
            SummaryPageDownloadDocumentComponent
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            showCancel={false}
            nextLabel={translator(quickQuoteMode ? platformMessagesPV.finishTariff : messages.next)}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

SummaryPage.propTypes = wizardProps;
export default SummaryPage;
