import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { AvailabilityService } from 'gw-capability-policycommon';

class QuoteEntry extends Component {
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired
    };

    componentDidMount() {
        const { history } = this.props;

        AvailabilityService.productAvailableBasedOnPostalCode().then((response) => {
            if (response.isAvailable) {
                const nextState = {
                    address: response.addressCompletion
                };
                history.push('/QuoteEntry', nextState);
            }
        });
    }

    render() {
        return true;
    }
}
export default withRouter(QuoteEntry);
