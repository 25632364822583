import { start } from '@jutro/app';
import {
    BrowserRouter
} from 'react-router-dom';
import { initDefaultGA } from '@jutro/events';
import  { BrandingUtil as BrandingService } from 'pv-portals-util-js';
import { LocaleService, G11nstore } from 'gw-portals-i18n-react';
import 'regenerator-runtime';
import appConfig from 'app-config';

import App from './app/App';

import { configureLegacyComponentMap } from '@jutro/legacy/uiconfig';
configureLegacyComponentMap();

export const startApp = (scriptAttributes) => {
    if (window.self === window.top) { 
        const theBody = document.getElementsByTagName('body')[0];

        theBody.style.display = 'block';
    } else { 
        const {host, protocol} = new URL(window.top.location)
        const root = `${protocol}//${host}`
        const rootPath = process.env.PUBLIC_PATH

        window.top.location = new URL(rootPath, root);
    }

    const { trackingConfig = {}, authentication = {}, microFrontendsConfig = {}, policyTransactionMicroAppConfig = {} } = appConfig;

    const gaTrackingId = trackingConfig.GA_TRACKING_ID;

    if (gaTrackingId) {
        initDefaultGA(trackingConfig);
    }

    const config = {
        ...authentication.servers.okta,
        ...policyTransactionMicroAppConfig,
        ...{microFrontendsConfig},
        ...appConfig.credentials
    };

    start(App, {
        rootId: 'root',
        themeConfig: BrandingService.getBrandingTheme(),
        messageLoader: LocaleService.loadMessages,
        coreMessageLoader: LocaleService.loadCoreMessages,
        refreshOnLanguageOrLocaleChange: false,
        routerBasename: '/producer-engage',
        config: [config],
        g11nStore: G11nstore(),

        onInit: () => {
            LocaleService.register();
        },

        Router: BrowserRouter,

        appName: {
            id: 'digital.appName',
            defaultMessage: 'ProducerEngage'
        },

        appDescription: 'ProducerEngage',
        mfeData: {scriptAttributes},
    });
};
