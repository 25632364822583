import React from 'react';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { FieldComponent } from '@jutro/components';
import messages from './CreditCardExpiryDate.messages';
import styles from './CreditCardExpiryDate.module.scss';

import { DropdownSelectField } from '@jutro/legacy/components';

function setFutureDate(date) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
}

function getMonthValues(intl) {
    
    const months = Array.from({length: 12}, (e, month) => 
    { 
        return intl.formatDate(new Date(2000, month , 1), { month: 'long' });
    })
  
    return months;
}


class CreditCardExpiryDate extends FieldComponent {
    /**
     * @memberof gw-components-platform-react.CreditCardExpiryDate
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.value - value of the component
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {string} propTypes.path - path to value in the view modal
     */

    static propTypes = {
        ...FieldComponent.propTypes
    };

    static defaultProps = {
        label: messages.expirationDate
    };

    state = {
        monthAvailableValues: [],
        yearAvailableValues: [],
        expirationMonth: undefined,
        expirationYear: undefined
    };

    componentDidMount() {
        const { value: creditCardExpDate, intl } = this.props;
        const months = getMonthValues(intl);
        const currentYear = new Date().getUTCFullYear();
        const yearRange = _.range(currentYear, currentYear + 20);

        const expirationDate = new Date(creditCardExpDate);

        this.setState({
            monthAvailableValues: months.map((month, index) => ({
              name: month,
              code: _.toString(index)
            })),
            yearAvailableValues: yearRange.map(_.toString).map((year) => ({
              code: year,
              name: year
            }))
          });
      
          if (expirationDate) {
            this.setState({
              expirationMonth: expirationDate.getMonth(),
              expirationYear: expirationDate.getFullYear()
            });
          }
        }

    handleValueChange = (value, inputpath, fieldObject) => {
        const id = fieldObject.fieldUniqueId.split('_')[0]
        const { onValueChange, path } = this.props;
        const { expirationMonth, expirationYear } = this.state;
        const isMonthValid = !!(expirationMonth || (value && id === 'expirationMonth'));
        const isYearValid = !!(expirationYear || (value && id === 'expirationYear'));

        const currentDate = new Date();
        const isCurrentYear = id === 'expirationYear' && currentDate.getFullYear().toString() === value;

        const isDateValid = isMonthValid && isYearValid;
        const shouldCurrentDateBeSelected = isCurrentYear && !expirationMonth;

        this.setState({ [id]: value });

        if (onValueChange && isDateValid) {
            const year = (id === 'expirationYear' && value) || expirationYear;
            const month = (id === 'expirationMonth' && value) || expirationMonth;
            const date = new Date(year, month);

            onValueChange(setFutureDate(date), path);
        } else if (shouldCurrentDateBeSelected) {
            this.setState({ expirationMonth: currentDate.getMonth() });
            onValueChange(setFutureDate(currentDate), path);
        }
    };

    renderControl() {
        const {
            monthAvailableValues,
            yearAvailableValues,
            expirationMonth,
            expirationYear
        } = this.state;

        return (
            <div className={styles.dateContainer}>
                <DropdownSelectField
                    onValueChange={this.handleValueChange}
                    availableValues={monthAvailableValues}
                    className={styles.date}
                    value={expirationMonth}
                    id="expirationMonth"
                    layout="full-width"
                    placeholder="-"
                />
                <DropdownSelectField
                    onValueChange={this.handleValueChange}
                    availableValues={yearAvailableValues}
                    className={styles.expirationYear}
                    value={expirationYear}
                    id="expirationYear"
                    layout="full-width"
                    placeholder="-"
                />
            </div>
        );
    }
}

export default injectIntl(CreditCardExpiryDate);
