import { defineMessages } from 'react-intl';

export default defineMessages({
    declarations: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Declarations',
        defaultMessage: 'Declarations'
    },
    management: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Management',
        defaultMessage: 'Management'
    },
    mifidDocument: {
        id: 'quoteandbind.pcv.views.pcv-policy-info.Mifid document',
        defaultMessage: 'MiFiD document, which version do you use?'
    },
    ownDocument: {
        id: 'quoteandbind.ppv.views.ppv-contract-details.OwnDocument',
        defaultMessage: 'Own document'
    },
    documentVivium: {
        id: 'quoteandbind.ppv.views.ppv-contract-details.DocumentVivium',
        defaultMessage: 'Document Vivium'
    },
    brochureInformed: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Policyholder has been informed about the customer brochure and infofiche',
        defaultMessage: 'Contractholder has been informed about <a>the customer brochure and info fiche</a>'
    },
    generalConditionsInformed: {
        id: 'quoteandbind.pcv.views.pcv-policy-info.Policyholder has been informed about the general conditions and agrees with it',
        defaultMessage: 'Contractholder has been informed about <a>the general conditions</a> and agrees with it'
    },
    statementsConfirmed: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Policyholder confirms the statement regarding the vehicle(s) and its driver(s)',
        defaultMessage: 'Contractholder confirms the statement regarding the vehicle(s) and its driver(s)'
    },
    eInfoAccepted: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Policyholder accepts in receiving all policy-related information',
        defaultMessage: 'Contractholder accepts in receiving all contract-related information via e-mail or consultation on the company\'s website'
    },
    proposedSolutionAgreed: {
        id: 'quoteandbind.pcv.views.pcv-policy-info.Customer agrees with proposed solution',
        defaultMessage: 'Customer agrees with proposed solution'
    },
    consequencesAccepted: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Policyholder confirms not having accepted the proposed result and accepts its consequences',
        defaultMessage: 'Contractholder confirms not having accepted the proposed result and accepts its consequences'
    },
    producerRemarks: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Producer Remarks',
        defaultMessage: 'Producer Remarks'
    },
    producerRemarksInfo: {
        id: 'quoteandbind.pcv.views.pcv-policy-info.Producer Remarks.info',
        defaultMessage: 'This text will be part of the contract and won\'t block your quote.'
    },
    producerRemarksFieldMaxLength: {
        id: 'quoteandbind.ppv.views.ppv-policy-info.Producer Remarks Field max length warning',
        defaultMessage: 'Maximum 255 characters allowed for this field'
    },
});
