import { defineMessages } from 'react-intl';

export default defineMessages({
    searchBtn:{
        id:'quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Search',
        defaultMessage:'Search'
    },
    model: {
        defaultMessage: 'Model',
        id: 'quoteandbind.pcv.directives.templates.pcv-vehicle-lookup-based-on-make-and-model.Model',
    },
    make: {
        defaultMessage: 'Make',
        id: 'quoteandbind.pcv.directives.templates.pcv-vehicle-lookup-based-on-make-and-model.Make',
    },
    fuelType: {
        defaultMessage: 'Fuel Type',
        id: 'quoteandbind.pcv.directives.templates.pcv-vehicle-lookup-based-on-make-and-model.Fuel Type',
    },
    enginePower: {
        defaultMessage: 'Engine Power',
        id: 'quoteandbind.pcv.directives.templates.pcv-vehicle-lookup-based-on-make-and-model.Engine Power',
    },
    chooseOtherVehicle: {
        defaultMessage: 'Choose an other vehicle',
        id: 'quoteandbind.pcv.directives.templates.pcv-informex-vehicle-confirmation-popupvehicle-list.OtherVehicle',
    },
    confirm: {
        defaultMessage: 'Confirm',
        id: 'quoteandbind.pcv.directives.templates.pcv-informex-vehicle-confirmation-popupvehicle-list.Confirm',
    },
    searchDataTableMssg: {
        defaultMessage: 'Search year,version,sub-model or catalog value...',
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Search year,version,sub-model or catalog value...',
    },
    year: {
        defaultMessage: 'Year',
        id: 'quoteandbind.ppv.directives.templates.ppv-informex-vehicle-list.year',
    },
    version: {
        id: "quoteandbind.ppv.directives.templates.ppv-informex-vehicle-list.version",
        defaultMessage: "Version"
    },
    subModel: {
        id: "quoteandbind.ppv.directives.templates.ppv-informex-vehicle-list.sub model",
        defaultMessage: "Sub Model"
    },
    catalogValue: {
        id: "quoteandbind.ppv.directives.templates.ppv-informex-vehicle-list.catalog value",
        defaultMessage: "Catalog Value"
    },
    primaryUse: {
        id: "quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Primary Use",
        defaultMessage: "Primary Use"
    },
    work: {
        id: "quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Work",
        defaultMessage: "Work"
    },
    pleasure: {
        id: "quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Pleasure",
        defaultMessage: "Pleasure"
    },
    addVehicleContract: {
        id: "quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Add a vehicle on the policy",
        defaultMessage: "Add a vehicle on the contract"
    },
    selectYourvehicleMsg: {
        id: "quoteandbind.pcv.directives.templates.pcv-informex-vehicle-list.Select your vehicle in the following list",
        defaultMessage: "Select your vehicle from the following list."
    },
    insertPlateNumber: {
        id: "quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Insert your plate number",
        defaultMessage: "Insert your plate number"
    },
    insertVin: {
        id: "quoteandbind.pcv.directives.templates.pcv-edit-vehicle.Insert your VIN",
        defaultMessage: "Insert your VIN"
    },
    informexNoConnectionError: {
        id: 'gateway.quoteandbind.common.util.actionmessageutil.Generic Message No Connection Informex Errors',
        defaultMessage: 'Generic Message No Connection Informex Errors'
    },
    informexErrorInvVinPlate: {
        id: 'quoteandbind.ppv.expection.informex.InvVinPlate',
        defaultMessage: 'Vehicle not found. Please search again.'

    },
    informexErrorVehicleNotFound: {
        id: 'quoteandbind.ppv.expection.informex.VehicleNotFound',
        defaultMessage: 'Vehicle not found. Please search again.'
    },
    invalidLicense: {
        id: "quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Invalid License Plate.",
        defaultMessage: "Invalid License Plate."
    },
    invalidVin: {
        id: "quoteandbind.ppv.directives.templates.ppv-edit-vehicle.Invalid VIN.",
        defaultMessage: "Invalid VIN."
    },
    mostPopularMakes: {
        id: "quoteandbind.ppv.directives.templates.ppv-informex-vehicle-list.MostPopularMakes",
        defaultMessage: "<b>Most Popular Makes</b>"
    },
    allMakes: {
        id: "quoteandbind.ppv.directives.templates.ppv-informex-vehicle-list.AllMakes",
        defaultMessage: "<b>All Makes</b>"
    },
    genericErrorTitle: {
        id: "quoteandbind.pcv.directives.templates.pcv-vehicle-lookup-based-on-make-and-model.informex-error-occurred-title",
        defaultMessage: "Oops! An error occurred while searching for your vehicle"
    },
    genericErrorMsg: {
        id: "quoteandbind.pcv.directives.templates.pcv-vehicle-lookup-based-on-make-and-model.informex-error-occurred-message",
        defaultMessage: "We could not find your vehicle, please try again later"

    },
    tooManyResultsErrorTitle: {
        id: "quoteandbind.pcv.directives.templates.pcv-vehicle-lookup-based-on-make-and-model.Informex-Too-Many-Results-Exception-Title",
        defaultMessage: "Vehicle Not Found"

    },
    tooManyResultsErrorMsg: {
        id: "quoteandbind.pcv.directives.templates.pcv-vehicle-lookup-based-on-make-and-model.Informex-Too-Many-Results-Exception-Message",
        defaultMessage: "Please provide more criteria for your search"
    },

    fuelTypeDiesel: {
        id: "quoteandbind.pcv.directives.templates.pcv-informex-vehicle-confirmation-popup.FuelType_Diesel",
        defaultMessage: "Diesel"
    },
    fuelTypePetrol: {
        id: "quoteandbind.pcv.directives.templates.pcv-informex-vehicle-confirmation-popup.FuelType_Petrol",
        defaultMessage: "Petrol"
    },
    fuelTypeLPG: {
        id: "quoteandbind.pcv.directives.templates.pcv-informex-vehicle-confirmation-popup.FuelType_LPG",
        defaultMessage: "L.P.G."
    },
    fuelTypeElectric: {
        id: "quoteandbind.pcv.directives.templates.pcv-informex-vehicle-confirmation-popup.FuelType_Electric",
        defaultMessage: "ELECTRIC"
    },
    fuelTypeNaturalGas: {
        id: "quoteandbind.pcv.directives.templates.pcv-informex-vehicle-confirmation-popup.FuelType_NaturalGas",
        defaultMessage: "Natural_Gas"
    },
    fuelTypeHybrid: {
        id: "quoteandbind.pcv.directives.templates.pcv-informex-vehicle-confirmation-popup.FuelType_Hybrid",
        defaultMessage: "Hybrid"
    },
    invalidVinValidation: {
        id: "displaykey.Edge.Web.Api.Model.InvalidVin",
        defaultMessage: "The VIN cannot exceed 17 characters, characters must be in uppercase and cannot contain an 'O'."
    }
});
