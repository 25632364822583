import { defineMessages } from 'react-intl';

export default defineMessages({
    creationInProgress: {
        id: 'gateway.directives.gateway-documents.Create Documents.Creation in progress',
        defaultMessage: 'Creation in progress'
    },
    documentWarning: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.The document you requested will appear in the newly opened tab in a few seconds. This document will also be available in the documents section of your quote/policy.',
        defaultMessage: 'The document you requested will appear in the newly opened tab in a few seconds. This document will also be available in the documents section of your quote/policy.'
    },
    errorTittle: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.mailError Title',
        defaultMessage: 'Technical error'
    },
    error: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.mailError Message',
        defaultMessage: 'An error occurred with your request. Please try again later.'
    },
    close: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.close',
        defaultMessage: 'Close'
    },
    downloadQuote: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Download the quote',
        defaultMessage: 'Download the quote'
    }
});
