import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { Loader, withModalContext} from '@jutro/components';
import { getApdLobQuoteUrl } from '@xengage/gw-portals-url-js';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { TranslatorContext, withIntl } from '@jutro/locale';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { JobService, UserService, SubmissionService } from 'gw-capability-gateway';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PolicyVehiclesDriversInfo from '../../Policies/Summary/pa/PolicyVehiclesDriversInfo/PolicyVehiclesDriversInfo';
import UnderwritingComponent from '../../Components/UnderwritingComponent/UnderwritingComponent';
import metadata from './Summary.metadata.json5';
import messages from './Summary.messages';
import gatewayMessages from '../../gateway.messages';
import styles from './Summary.module.scss';

class QuoteDetailsSummary extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                getQuoteSummaryCount: PropTypes.func,
                jobNumber: PropTypes.string.isRequired,
                loadQuoteSummary: PropTypes.shape({}),
                updateJobSummary: PropTypes.func
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired
    };

    state = {
        showisContinueTransactionButton: false,
        canWithdraw: false,
        canCopy: true,
        isLoading: true
    };

    componentDidMount = () => {
        this.canWithdraw();
        this.getShowisContinueTransaction();

        // P&V: returning to quote from esign or epay
        if (this.props.location?.search?.includes("autostart")) {
            this.setState({ isLoading: true });
            this.onContinueTransaction();
        }
    };

    getShowisContinueTransaction = async () => {
        const { authHeader } = this.props;
        const permissionDTO = {
            permission: 'createsubmission'
        };
        const showisContinueTransactionButton = await UserService.hasUserSystemPermission(
            permissionDTO,
            authHeader
        );
        this.setState({ showisContinueTransactionButton, isLoading: false });
    };

    canWithdraw = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;
        if (!_.isEmpty(submission)) {
            const translator = this.context;
            if (
                submission.status !== translator('typekey.PolicyPeriodStatus.Bound')
                && !submission.policy.issued
                && !submission.closeDate
            ) {
                this.setState({ canWithdraw: true });
            }
        }
    };

    onContinueTransaction = () => {
        const { lobQuoteURL, gatewayParamConfig: { enableContinueQuickQuote } } = appConfig;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            },
            history
        } = this.props;
        const productCode = _.get(submission, 'productCode');
        const postalCode = _.get(
            submission,
            'policy.account.accountHolder.primaryAddress.postalCode'
        );
        if (submission.jobNumber > 0) {
            if (!_.isNil(lobQuoteURL[productCode]) || (enableContinueQuickQuote && !_.isNil(lobQuoteURL[`QQ${productCode}`]))) {
                const nextLocation = {
                    quoteentry: {
                        postalCode: postalCode,
                        quoteID: submission.jobNumber
                    },
                    productCode
                };
                const url = enableContinueQuickQuote && submission.quoteType === 'Quick' ? lobQuoteURL[`QQ${productCode}`] : lobQuoteURL[productCode];
                history.push(url, nextLocation);
            } else if (!_.isNil(getApdLobQuoteUrl(productCode))) {
                const nextLocation = {
                    productCode,
                    accountId: submission.policy.account.publicID,
                    jobId: submission.publicID
                };
                history.push(getApdLobQuoteUrl(productCode), nextLocation);
            } else {
                JobUtil.openJobInXCenter(submission.jobNumber);
            }
        }
    };

    onWithdrawTransaction = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            },
            authHeader,
            modalContext
        } = this.props;
        modalContext.showConfirm({
            title: messages.withdrawJob,
            message: `${translator(messages.sureWithDrawQuote)} ${submission.jobNumber}?`,
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.yesModel,
            cancelButtonText: commonMessages.noModel
        }).then((results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            JobService.withdrawJobByJobNumber(submission.jobNumber, authHeader).then(
                () => {
                    this.updateWithDrawQuote('Withdrawn', submission);
                },
                () => {
                    modalContext.showAlert({
                        title: gatewayMessages.modalError,
                        message: messages.failedWithdrawSubmission,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).catch(_.noop);
                }
            );
        }, _.noop);
    };

    hasUwIssues = (uwIssues) => {
        return uwIssues.length > 0;
    };

    getNotificationContent = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;
        const { showisContinueTransactionButton } = this.state;
        const content = {};
        if (
            submission
            && !['Bound', 'Quoted', 'Withdrawn'].includes(submission.statusCode)
            && !this.hasUwIssues(submission.underwritingIssues)
        ) {
            content.infoMessageTitle = translator(messages.quoteSuccessfullyStarted);
            content.infoMessageDescription = showisContinueTransactionButton
                ? translator(messages.continiueQuoteQizard)
                : translator(messages.mayWithdrawQuote);
        }
        if (
            submission
            && submission.statusCode === 'Quoted'
            && !this.hasUwIssues(submission.underwritingIssues)
        ) {
            content.infoMessageTitle = translator(messages.premiumQuoteCalculated);
            content.infoMessageDescription = showisContinueTransactionButton
                ? translator(messages.changeQuoteWizardToBind)
                : translator(messages.mayWithdrawQuote);
        }
        if (submission && submission.statusCode === 'Withdrawn') {
            content.infoMessageTitle = translator(messages.thisQuoteHasBeenWithDrawn);
            content.infoMessageDescription = '';
        }
        if (submission && submission.statusCode === 'Bound' && submission.policy.issued) {
            content.infoMessageTitle = translator(messages.quoteHasBeenBound);
            content.infoMessageDescription = '';
        }
        if (submission && submission.statusCode === 'Bound' && !submission.policy.issued) {
            content.infoMessageTitle = translator(messages.premiumQuoteCalculated);
            content.infoMessageDescription = showisContinueTransactionButton
                ? translator(messages.changeQuoteWizardToBind)
                : translator(messages.mayWithdrawQuote);
        }
        return {
            infoMessageTitle: content.infoMessageTitle,
            infoMessageDescription: content.infoMessageDescription,
            withDrawContent: translator(messages.withdrawQuote),
            copySubmissionContent: translator(messages.copySubmissionQuote),
            continueContent: translator(messages.continueQuote)
        };
    };

    getSubmissionToProceed = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;
        const { canWithdraw, showisContinueTransactionButton, canCopy } = this.state;
        if (
            submission
            && (submission.statusCode === 'Withdrawn' || (submission.statusCode === 'Bound' && submission.policy.issued))
        ) {
            return {
                isContinueTransaction: false,
                isWithdrawTransaction: false,
                isCopyTransaction: false
            };
        }
        if (submission && submission.statusCode === 'Bound' && !submission.policy.issued) {
            return {
                isContinueTransaction:
                    showisContinueTransactionButton && !submission.isEditLocked,
                isWithdrawTransaction: false,
                isCopyTransaction: canCopy
            };
        }
        return {
            isContinueTransaction:
                showisContinueTransactionButton && canWithdraw && !submission.isEditLocked,
            isWithdrawTransaction: canWithdraw,
            isCopyTransaction: canCopy
        };
    };

    getPolicyLinkVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;
        const policyNumber = _.get(submission, 'latestPeriod.policyNumber');
        const policyPeriodStatus = _.get(submission, 'latestPeriod.policyPeriodStatus_PV');
        if (policyNumber) {
            const shouldLinkToPolicy = !['New', 'Draft', 'Quoted'].includes(policyPeriodStatus);
            return policyNumber !== 'Unassigned' && shouldLinkToPolicy;
        }
        return false;
    };

    redirectToPolicy = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;
        const policyNumber = _.get(submission, 'latestPeriod.policyNumber');
        return (
            <Link to={`/policies/${policyNumber}/summary`} className={styles.removeLinkStyle}>
                {policyNumber}
            </Link>
        );
    };

    getJobStatus = (job) => {
        const translator = this.context;
        if (job.policy) {
            if (job.policy.issued) {
                return translator(gatewayMessages.issued);
            }
            if (job.status === 'Not-taken') {
                return translator(gatewayMessages.notTaken);
            }
        }
        return job.status;
    };

    getStatusInfo = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;
        const translator = this.context;
        const submissionStatus = this.getJobStatus(submission);
        if (submissionStatus.toLowerCase() === 'bound' && submission.policy.issued) {
            return translator('gateway.directives.JobSummary.Issued');
        }
        return submissionStatus;
    };

    updateWithDrawQuote = (jobStatusCode, submission) => {
        const translator = this.context;
        const status = translator({
            id: `typekey.PolicyPeriodStatus.${jobStatusCode}`,
            defaultMessage: jobStatusCode
        });
        _.set(submission, 'status', status);
        _.set(submission, 'statusCode', jobStatusCode);
        this.updateJobSummary(submission);
    };

    updateJobSummary = (submission) => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { updateJobSummary, getQuoteSummaryCount }
            }
        } = this.props;

        if (updateJobSummary) {
            updateJobSummary(submission);
        }
        if (getQuoteSummaryCount) {
            getQuoteSummaryCount();
        }
    };

    getPaymentPlanTranslation = (paymentMethod) => {
        const translator = this.context;
        const { viewModelService } = this.props;
        if (paymentMethod) {
            const paymentPlan = viewModelService.productMetadata.get('pc').types.getTypelist('BillingPeriodicity').getCode(paymentMethod);
            return translator({
                id: paymentPlan.name,
                defaultMessage: paymentPlan.code
            });
        }
        return '-';
    };

    getPaymentMethodTranslation = (paymentMethod) => {
        const translator = this.context;
        const { viewModelService } = this.props;
        if (paymentMethod) {
            const activePaymentMethod = viewModelService.productMetadata.get('pc').types.getTypelist('PolicyPaymentMethod_CxPV').getCode(paymentMethod);
            return translator({
                id: activePaymentMethod.name,
                defaultMessage: activePaymentMethod.code
            });
        }
        return '-';
    };

    render() {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }, intl
        } = this.props;

        const { isLoading } = this.state;
        if (_.isEmpty(submission)) {
            return <Loader loaded={!isLoading} />;
        }
        const notificationContent = this.getNotificationContent();
        const actions = this.getSubmissionToProceed();
        const notificationContentVisible = !_.isEmpty(notificationContent.infoMessageTitle);
        const overrides = {
            quoteNotification: {
                visible: notificationContentVisible,
                notificationContent,
                transactionVisibleActions: actions,
                job: submission,
            },
            underwritingTable: {
                job: submission,
                continueJob: notificationContentVisible ? {} : actions, // show action buttons here if no NotificationBar visible
                jobService: SubmissionService,
                onUpdateJobSummary: this.updateJobSummary,
                hasUnderwritingIssues: this.hasUwIssues(submission.underwritingIssues)
            },
            policyInfoLink: {
                visible: this.getPolicyLinkVisible(),
                value: this.redirectToPolicy()
            },
            detailGridTotalColumn: {
                visible: submission.statusCode !== 'Draft'
            },
            policyVehicleInfo: {
                visible: _.get(submission, 'policy.product.productCode') === 'PersonalVehicle_PV' || _.get(submission, 'policy.product.productCode') === 'CommercialVehicle_PV',
                value: _.get(submission, 'policy.lobs.ppvPersonalAuto_PV') || _.get(submission, 'policy.lobs.pcvCommercialAuto_PV'),
                productCode: _.get(submission, 'policy.product.productCode'),
            },
            producerCodeOfRecordOrgId: {
                value: `${_.get(
                    submission,
                    'latestPeriod.producerCodeOfRecordOrg'
                )} (${_.get(submission, 'latestPeriod.producerCodeOfRecord')})`
            },
            producerOfServiceId: {
                value: `${_.get(
                    submission,
                    'latestPeriod.producerCodeOfServiceOrg'
                )} (${_.get(submission, 'latestPeriod.producerCodeOfService')})`
            },
            playmentPlanId: {
                value: this.getPaymentPlanTranslation(_.get(submission, 'latestPeriod.selectedPaymentPlan'))
            },
            activePaymentMethodId: {
                value: this.getPaymentMethodTranslation(_.get(submission, 'latestPeriod.selectedPaymentMethod'))
            },
            summaryStatusDataId: {
                value: this.getStatusInfo()
            },
            summaryCreatedDataId: {
                value: intl.formatDate(new Date(_.get(submission, 'createTime')), { year: 'numeric', month: 'short', day: 'numeric' }),
            },
            summaryPolicyInceptionId: {
                value: intl.formatDate(new Date(_.get(submission, 'latestPeriod.effectiveDate')), { year: 'numeric', month: 'short', day: 'numeric' }),
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                underwritingcomponent: UnderwritingComponent,
                policypacomponent: PolicyVehiclesDriversInfo
            },
            resolveCallbackMap: {
                onContinueTransaction: this.onContinueTransaction,
                onWithdrawTransaction: this.onWithdrawTransaction,
                redirectToPolicy: this.redirectToPolicy
            }
        };
        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, submission, id, path, overrides);
        };
        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submission}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        );
    }
}

export default withIntl(withViewModelService(withAuthenticationContext(withModalContext(QuoteDetailsSummary))));
