import { defineMessages } from 'react-intl';

export default defineMessages({
    producerCode: {
        id: 'gateway.views.new-quote.Producer Code',
        defaultMessage: 'Producer Code'
    },
    choseProducerCodePlaceholder: {
        id: 'gateway.views.new-quote.Choose Producer Code',
        defaultMessage: '-- Please Select --'
    },
    productTariff: {
        id: 'gateway.views.new-quote.Product offer',
        defaultMessage: 'Product tariff'
    },
    choseProductPlaceholder: {
        id: 'gateway.views.new-quote.Choose Product',
        defaultMessage: '-- Please Select --'
    },
    collectionMethod: {
        id: 'quoteandbind.ppv.views.ppv-contract-details.Collection Method',
        defaultMessage: 'Collection Method'
    }
});
