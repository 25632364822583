module.exports = {

    /**
     * Key values for Lines of Business
     * May be a good idea to ensure that
     * multiple mutations of your line name are covered
     * where requirements apply as shown below:
     *     CommercialAuto: 'CA',
     *     BusinessAuto: 'CA',
     *     BusinessAutoLine: 'CA',
     */
    lobKeys: {
        PersonalVehicle_PV: 'PA',
        CommercialVehicle_PV: 'PA'
    },

    /**
     * Normalised Icons for Line of business
     * Add to this object in the following format,
     * in the case of HomeOwners, you would add:
     *     HO: {
     *   image: 'home-owners',
     *   font: 'mi-home'
     *  },
     */
    normalisedLobIcons: {
        PA: {
            image: 'personal-auto',
            font: 'mi-directions_car'
        }
    },

    /**
     * Material icons for Lines of Business
     * Objects should be formatted like so,
     * Where line is Personal auto:
     *     PA: {
     *      image: 'personal-auto',
     *      font: 'mi-directions_car'
     *  },
     */
    materialLobIcons: {
        PA: {
            image: 'personal-auto',
            font: 'mi-directions_car'
        }
    },
}
