import React, { useCallback, useEffect, useMemo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { PVInsurersListService } from 'pv-capability-policyjob';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { DriverUtil, DriverComponentUtil, BusinessConstant as BC, CustomClassValidations } from 'pv-portals-util-js';
import metadata from './DriverComponent.metadata.json5';
import messages from './DriverComponent.messages';
import styles from "./DriverComponent.module.scss";

function DriverComponent(props) {
    const {
        data: driverVm,
        path: driverVmPath,

        labelPosition,
        phoneWide,
        id,
        onValidate,
        onValueChange,
        showErrors,
        quickQuoteMode,

        onCopyMainAddressToDriver,
        onIsMainDriverChange,
        onAssignmentVehicleToNonMainDriverChange,

        allCoverables,
        brand,
        jobType,
        isCommercial
    } = props;

    const [insurers, setInsurers] = useState([]);
    const { authHeader } = useAuthentication();
    const { isComponentValid, onValidate: setComponentValidation, registerComponentValidation } = useValidation(id);
    const { allVehiclesAsCheckboxes, selectedVehicleCheckboxes } = DriverComponentUtil();
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [isPolicyChange] = useState(jobType === BC.JOB_TYPE_POLICY_CHANGE);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [driverVm, id, onValidate, isComponentValid]);

    useEffect(() => {
        async function fetchData() {
            const list = await PVInsurersListService.getInsurersLists(authHeader);
            setInsurers(list.map((item) => {
                return {
                    name: item.currentInsurerName_PV,
                    code: item.publicID_PV
                };
            }));
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkDrivingLicenseA = useCallback(() => {
        const driver = driverVm.value;
        if (CustomClassValidations.isRequiredDateFirstLicenseAForDriver(allCoverables.vehicles, allCoverables.vehicleDrivers, driver)) {
            // eslint-disable-next-line camelcase
            return !!driver.person.dateFirstLicenseA_PV;
        }
        return true;
    }, [allCoverables.vehicleDrivers, allCoverables.vehicles, driverVm.value]);

    useEffect(() => {
        registerComponentValidation(checkDrivingLicenseA);
    }, [registerComponentValidation, checkDrivingLicenseA]);

    const handleValueChange = useCallback((value, changedPath) => {
        _.set(driverVm, changedPath, value);

        // --------------additional logic for dependant fields--------------------

        // contact secion
        if (changedPath.includes('cellNumber')) {
            if (_.isEmpty(value)) {
                driverVm.person.primaryPhoneType.value = null;
            } else {
                driverVm.person.primaryPhoneType.value = 'mobile';
            }
        }
        if (changedPath.includes('shareEmailAddress_PV')) {
            // NOTE: shareEmailAddress_PV meaning is inverted, it is rather DoesNOTHaveOrDoesNOTwantShareEmail
            if (value) {
                driverVm.person.emailAddress1.value = '';
            }
        }

        // license section
        if (changedPath.includes("hasDrivingLicenseB_PV") && value === false) {
            // eslint-disable-next-line camelcase
            driverVm.value.dateFirstLicensed_PV = null;
        }

        // history section
        if (changedPath.includes("hasOwnInsurance")) {
            driverVm.isCurrentlyARegularDriver.value = undefined;
            driverVm.insuranceEndTime.value = undefined;
            driverVm.hasClaimAttestLast3Years.value = undefined;
        }
        if (changedPath.includes("isCurrentlyARegularDriver") && value === false) {
            driverVm.hasClaimAttestLast3Years.value = undefined;
            driverVm.insuranceEndTime.value = undefined;
        }
        if (changedPath.includes("insuranceEndTime") && (value === "never_insured" || value === "more_than_3_years")) {
            driverVm.hasClaimAttestLast3Years.value = undefined;
        }

        // claims section
        if ((changedPath.includes('claimsTPL5YearsFault_PV')
            || changedPath.includes('claimsTPL5YearsFaultCL_PV')) && value === '0') {
            driverVm.noOfYearsSinceLastClaim_PV.value = undefined;
        }

        if (changedPath.includes('noOfYearsSinceLastClaim_PV')) {
            driverVm.noOfYearsValChanged_PV.value = true;
        }

        // insurance section
        if (changedPath.includes('medicalDisability_PV') && !value) {
            driverVm.medicalPermissionToDrive_PV.value = false;
        }

        // -------------------end of additional logic------------------------

        onValueChange(driverVm, driverVmPath);
    }, [driverVm, onValueChange, driverVmPath]);


    const writeStepperValue = useCallback(
        (value, changedPath) => {
            const valueToString = _.toString(value);
            handleValueChange(valueToString, changedPath);
        },
        [handleValueChange]
    );

    const numberOfYearsSinceLastClaimValuesWithoutNone = useMemo(() => {
        // NOTE: backedn allows values: None, 0, 1, 2, 3, 4
        // due to widget change, None is not needed anymore on frontend
        const typelistDef = viewModelService.productMetadata.get('pc').types.getTypelist('NoOfYrsSinceLastClaim_PV');
        const filteredValues = typelistDef.codes.filter((typecode) => typecode.code !== 'None');
        const translatedValues = filteredValues.map((item) => {
            return {
                id: item.code,
                displayName: translator({ id: item.name, defaultMessage: item.code })
            };
        });
        return translatedValues;
    }, [viewModelService, translator]);


    const driver = driverVm.value;

    if (_.isNil(driver) || _.isNil(driver.person)) {
        return <Loader loaded={false} />;
    }

    const isPrimaryDriver = DriverUtil.isPrimaryDriver(driver, allCoverables.vehicleDrivers);
    const isPersonInBDP = !_.isNil(driver.person.bdpid_PV);
    const existingDriver = driver.existingDriver_PV;

    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition,
            phoneWide,
        },

        // driver general info
        driverFirstName: {
            readOnly: isPolicyChange ? existingDriver : isPersonInBDP
        },
        driverLastName: {
            readOnly: isPolicyChange ? existingDriver : isPersonInBDP
        },
        driverDateOfBirth: {
            readOnly: isPolicyChange ? existingDriver : isPersonInBDP
        },

        // address
        driverSameAddress: {
            onClick: () => onCopyMainAddressToDriver(driverVmPath),
        },
        addressComponent: {
            onValidate: setComponentValidation
        },

        // driver contact info
        driverEmail: {
            disabled: driver.person.shareEmailAddress_PV
        },

        // driving license & driving vehicles
        driverIsMain: {
            value: isPrimaryDriver,
            onValueChange: (newValue) => onIsMainDriverChange(driverVmPath, newValue)
        },
        driverVehicleToDrive: {
            value: selectedVehicleCheckboxes(allCoverables, driver),
            availableValues: allVehiclesAsCheckboxes(allCoverables),
            disabled: isPrimaryDriver,
            onValueChange: (selectedVehicleIds) => {
                // CheckboxField wants String not Number
                const selectedVehicleIdsAsNumbers = selectedVehicleIds.map((vehicleId) => parseInt(vehicleId));
                return onAssignmentVehicleToNonMainDriverChange(selectedVehicleIdsAsNumbers, driverVmPath);
            }
        },

        // history
        insuranceEndTime: {
            value: driver.insuranceEndTime
        },
        riskRefusedDetails: {
            riskObjectVM: driverVm,
            riskObjectPath: driverVmPath,
            onValidate: setComponentValidation,
            insurers
        },

        // claims
        claimsTPL5YearsFault_PV: {
            value: driver.claimsTPL5YearsFault_PV,
            onValueChange: writeStepperValue
        },
        claimsTPL5YearsFaultCL_PV: {
            value: driver.claimsTPL5YearsFaultCL_PV,
            onValueChange: writeStepperValue
        },
        noOfYearsSinceLastClaim_PV: {
            value: driver.noOfYearsSinceLastClaim_PV,
            onValueChange: writeStepperValue,
            availableValues: numberOfYearsSinceLastClaimValuesWithoutNone
        },
        claimsTPL5YearsNFault_PV: {
            value: driver.claimsTPL5YearsNFault_PV,
            onValueChange: writeStepperValue
        },
        claimsTPL5YearsNFaultCL_PV: {
            value: driver.claimsTPL5YearsNFaultCL_PV,
            onValueChange: writeStepperValue
        },
        claimsTheft5YearsFault_PV: {
            value: driver.claimsTheft5YearsFault_PV,
            onValueChange: writeStepperValue
        },
        claimsTheft5YearsFaultCL_PV: {
            value: driver.claimsTheft5YearsFaultCL_PV,
            onValueChange: writeStepperValue
        },
        claimsMD5YearsFault_PV: {
            value: driver.claimsMD5YearsFault_PV,
            onValueChange: writeStepperValue
        },
        claimsMD5YearsFaultCL_PV: {
            value: driver.claimsMD5YearsFaultCL_PV,
            onValueChange: writeStepperValue
        },

        // insurance
        medicalDisability_PV: {
            disabled: existingDriver
        },
        medicalPermissionToDrive_PV: {
            disabled: existingDriver
        },
        licenceWithdrawel_PV: {
            disabled: existingDriver,
            label: existingDriver ? messages.existingDriverLicenceWithdrawelLabel : messages.newDriverLicenceWithdrawelLabel
        },
        driverCommitedOffense_PV: {
            disabled: existingDriver,
            label: existingDriver ? messages.existingDriverCommitedOffenseLabel : messages.newDriverCommitedOffenseLabel
        }
    };

    // need deep merge, not shallow (operator ... doesn't work)
    _.merge(overrideProps, DriverUtil.visibleFields(driverVm, allCoverables, jobType, quickQuoteMode, isCommercial, brand));

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={driverVm}
            showErrors={showErrors}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

DriverComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showRequired: PropTypes.bool,
    id: PropTypes.string,
    brand: PropTypes.string,
    jobType: PropTypes.string,
};

DriverComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    showRequired: true,
    id: undefined,
    index: 0,
    brand: 'pv',
    jobType: 'Submission',
};

export default DriverComponent;
