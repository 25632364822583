import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';

export default class CustomQuoteService {
    /**
     * Selects a quote
     * @param {Object} submissionDTO QuoteDataDTO
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static createSubmission(submissionDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('slquote'),
            'createBaseOffering',
            [submissionDTO],
            additionalHeaders
        );
    }


    static retrieveSubmission(QuoteID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quickquote'), 'retrieve', [QuoteID], additionalHeaders);
    }

    static updateDraftSubmission(quickQuoteData, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quickquote'), 'quickQuoteUpdates_PV', [quickQuoteData], additionalHeaders);
    }

    static retrievePaymentPlans_PV(QuoteID, BillingMethod, PaymentMethod, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quickquote'), 'retrievePaymentPlans_PV', [QuoteID, BillingMethod, PaymentMethod], additionalHeaders);
    }

    static retrievePaymentPlansForBroker_PPV(QuoteID, BillingMethod, PaymentMethod, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quickquote'), 'retrievePaymentPlansForBroker_PPV', [QuoteID, BillingMethod, PaymentMethod], additionalHeaders);
    }

    static getAvailableBillingMethodForProducerCode_PV(producerCode, jobnumber, paymentMethod, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quickquote'), 'getAvailableBillingMethodForProducerCode_PV', [producerCode, jobnumber, paymentMethod], additionalHeaders);
    }

    static quickQuoteAndSave_PV(quickQuoteData, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quickquote'), 'quickQuoteAndSave_PV', [quickQuoteData], additionalHeaders);
    }

    static getPacks_PV(quickQuoteData, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quickquote'), 'quickQuoteGetPacks_PV', [quickQuoteData], additionalHeaders);
    }

    static referToUnderwriter_PV(quickQuoteData, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quickquote'), 'referToUnderwriter_PV', [quickQuoteData], additionalHeaders);
    }

    static getPremium_PV(quickQuoteData, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quickquote'), 'quickQuoteGetPremium_PV', [quickQuoteData], additionalHeaders);
    }

    static updateCustomQuoteCoverages(customQuoteDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('quickquote'), 'updateCustomQuoteCoverages', [customQuoteDTO], additionalHeaders);
    }

    static saveOwnReference(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayquote/quote'), 'saveOwnReference_PV', [params], additionalHeaders);
    }

    /**
     * Generate and downloads quote document
     *
     * @param {Object} params Containing quote email value
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static generateQuoteDocumentPV(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayquote/quote'), 'generateQuoteDocumentPV', [params], additionalHeaders);
    }

}
