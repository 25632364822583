import React, { Component } from 'react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Redirect } from 'react-router-dom';
import { withIntl } from '@jutro/locale';
import { SelectProducerCode, withProducerContext } from 'gw-gateway-common-react';
import { Loader } from '@jutro/components';
import { UserService } from 'gw-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import metadata from './Analytics.metadata.json5';
import analyticsStyles from './Analytics.module.scss';
import GrowthImgSrc from './images/growth_image.png';
import LossRatioImgSrc from './images/lossratios_image.png';
import './Analytics.messages';

class Analytics extends Component {


    state = {
        selectedTile: 'growthTile',
        hasPermission: false,
        isLoading: true
    };

    static propTypes = {
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired
    };

    componentDidMount() {
        this.checkAnalyticsPermission();
    }

    checkAnalyticsPermission() {
        const { authHeader } = this.props;
        const reportsDTO = {
            permission: 'gpaviewreports'
        };

        UserService.hasUserSystemPermission(reportsDTO, authHeader)
        .then(checkPermission => {
            this.setState({
                hasPermission: checkPermission,
                isLoading: false
            });
        });
    }

    handleOnClick = (id) => {
        this.setState({
            selectedTile: id
        });
    };

    toggleVisibilityGrowth = (selectedTile) => {
        if (selectedTile === 'growthTile') {
            return true;
        }
        return false;
    };

    toggleVisibilityRatios = (selectedTile) => {
        if (selectedTile === 'lossRatiosTile') {
            return true;
        }
        return false;
    };

    render() {
        const { selectedTile, hasPermission, isLoading } = this.state;

        const overrides = {
            growth: {
                src: GrowthImgSrc,
                visible: this.toggleVisibilityGrowth(selectedTile)
            },
            ratios: {
                src: LossRatioImgSrc,
                visible: this.toggleVisibilityRatios(selectedTile)
            },
            [selectedTile]: {
                active: true
            }
        };
        const resolvers = {
            resolveComponentMap: {
                selectproducercode: SelectProducerCode
            },
            resolveCallbackMap: {
                handleOnClick: this.handleOnClick
            },
            resolveClassNameMap: analyticsStyles
        };

        if (isLoading) {
            return ( <Loader /> );
        }

        const AnalyticsPage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
        return ( <div className={cx(analyticsStyles.analytics)}>
            { hasPermission ?  AnalyticsPage : <Redirect to="/" /> }
            </div>
        );
    }
}

export const AnalyticsComponent = Analytics;
export default withIntl(withAuthenticationContext(withProducerContext(AnalyticsComponent)));
