import { defineMessages } from 'react-intl';

export default defineMessages({
    policylandingCategory: {
        id: 'gateway.views.policy-landing.Category',
        defaultMessage: 'Category',
    },
    policylandingPolicyLine: {
        id: 'gateway.views.policy-landing.Policy Line',
        defaultMessage: 'Product',
    }
});
