import { defineMessages } from 'react-intl';

export default defineMessages({
    driversInsurance: {
        id: "quoteandbind.ppv.views.ppv-additional-info.Driver Insurance",
        defaultMessage: "Drivers Insurance"
    },
    disability: {
        id: "quoteandbind.ppv.views.ppv-additional-info.Does one of the drivers have a disability",
        message: "Does one of the drivers have a disability?"
    },
    whichDriver: {
        id: "quoteandbind.ppv.views.ppv-additional-info.Which driver(s)",
        message: "Which driver(s)?"
    },
    medicalPermissionToDrive: {
        id: "quoteandbind.ppv.views.ppv-additional-info.Does the driver with the disability has a medical permission to drive a vehicle",
        message: "Does the driver with the disability has a medical permission to drive a vehicle?"
    },
    licenseWithdrawal: {
        id: "quoteandbind.ppv.views.ppv-additional-info.Has one of the drivers experienced a license withdrawal within the 2 years",
        message: "Has one of the drivers experienced a license withdrawal within the 2 years?"
    },
    driverCommitedOffense: {
        id: "quoteandbind.ppv.views.ppv-additional-info.Has one of the drivers commit any offense within the last 2 years (hit and run, driving under intoxication or fraud)",
        message: "Has one of the drivers commit any offense within the last 2 years (hit and run, driving under intoxication or fraud)?"
    },
    detailsOfVehicles: {
        id: "quoteandbind.ppv.views.ppv-additional-info.Details of the vehicle(s)",
        defaultMessage: "Details of the vehicle(s)"
    },
    license: {
        id: "quoteandbind.ppv.views.ppv-additional-info.Licence plate",
        message: "Licence plate"
    },
    vin: {
        id: "quoteandbind.ppv.views.ppv-additional-info.VIN number",
        message: "VIN number"
    },
    cancellationCurrentPolicy: {
        id: "quoteandbind.ppv.views.ppv-additional-info.Cancellation current policy",
        message: "Renunciation of current contract"
    },
    financialCompany: {
        id: "quoteandbind.ppv.views.ppv-additional-info.Financial Institution Leasing Company",
        message: "Financial Institution/ Leasing Company"
    },
    yes: {
        id: "quoteandbind.ppv.views.ppv-driver-details.Yes",
        defaultMessage: 'Yes'
    },
    no: {
        id: 'quoteandbind.ppv.views.ppv-driver-details.No',
        defaultMessage: 'No'
    },
});
