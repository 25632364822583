import React, { useContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';

import { useModal } from '@jutro/components';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';

import { messages as commonMessages } from 'gw-capability-policychange-common-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { BusinessConstant, EdgeErrorParser } from 'pv-portals-util-js';

import metadata from './PaymentChangePage.metadata.json5';
import './PaymentChangePage.messages';

function PaymentChangePage(props) {
    const { updateWizardData, wizardData: policyChangeVM } = props;

    const { authHeader } = useAuthentication();
    const { EndorsementService } = useDependencies('EndorsementService');
    const viewModelService = useContext(ViewModelServiceContext);
    const { onValidate, isComponentValid } = useValidation('PaymentChangePage');
    // NOTE: need pure skipping on initial validation function, without ViewModel validation
    const [ initialValidation ] = useState(policyChangeVM.value.status === 'Proposal_pv' || policyChangeVM.value.status === 'Bound');

    const [isPageInitialised, setPageInitialised] = useState(false);
    const [showErrors, updateShowErrors] = useState(false);


    const [gettingPaymentDetails, updateGettingPaymentDetails] = useState(false);
    const [isPaymentDetailsValid, updateIsPaymentDetailsValid] = useState(true);

    const paymentDetailsPath = 'bindData_PV.paymentDetails';
    const bankAccountDataPath = `${paymentDetailsPath}.bankAccountData`;
    const paymentMethodPath = `${paymentDetailsPath}.paymentMethod_PV`;
    const isPaymentSEPA = _.get(policyChangeVM, `${paymentMethodPath}.value.code`) === 'sepa';
    const [showInvoiceOption, updateShowInvoiceOption] = useState(!_.isNil(_.get(policyChangeVM, `${paymentDetailsPath}.lastInvoiceNotPaid_PV.value`)));

    const { showAlert } = useModal();

    const getInitialValidation = useCallback(() => {
        return initialValidation;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const vm = viewModelService.changeContext(policyChangeVM, {
            ContractQuestions: true
        });
        updateWizardData(vm);
        setPageInitialised(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(async () => {
        if (
            _.get(policyChangeVM, `${bankAccountDataPath}.belgianBank_PV.value`) &&
            _.get(policyChangeVM, `${bankAccountDataPath}.bankABANumber.value`)
        ) {
            _.set(policyChangeVM, `${bankAccountDataPath}.bankABANumber.value`, '');
        }

        if (!isComponentValid || !isPaymentDetailsValid) {
            updateShowErrors(true);
            return false;
        }

        try {
            const response = await EndorsementService.updatePaymentPlan_PV(policyChangeVM.value, authHeader);
            policyChangeVM.value = response;
        } catch (e) {
            const msg = EdgeErrorParser.getErrorMessage(e);
            showAlert({
                title: platformMessages.genericError,
                message: msg,
                status: 'error',
                icon: 'mi-error-outline'
            });

            return false;
        }

        return policyChangeVM;
    }, [EndorsementService, authHeader, bankAccountDataPath, isComponentValid, isPaymentDetailsValid, policyChangeVM, showAlert]);

    const writeValue = useCallback(
        (value, path) => {
            _.set(policyChangeVM, path, value);
            updateWizardData(policyChangeVM);
        },
        [policyChangeVM, updateWizardData]
    );

    const onPaymentPlanChange = useCallback(
        async (billingId) => {
            const oldBillingMethod = _.get(policyChangeVM, 'oldBillingMethod_PV.value.code');
            const oldPaymentPlanId = _.get(policyChangeVM, 'oldPaymentPlan_PV.billingId.value');
            const isBrandVivium = _.get(policyChangeVM, 'baseData.brand_PV.value.code') === BusinessConstant.PV_BRAND_VIVIUM;

            if (billingId !== oldPaymentPlanId && isBrandVivium &&
                (_.includes(BusinessConstant.BillingMethodListForProducerBill, oldBillingMethod) ||
                    _.includes(BusinessConstant.BillingMethodListForMixedBill, oldBillingMethod))
            ) {
                let billedInvoiceWithBrbValid = false;
                try {
                    billedInvoiceWithBrbValid = await EndorsementService.billedInvoiceWithBrbValid_PV(policyChangeVM.jobID.value, authHeader);
                    updateShowInvoiceOption(billedInvoiceWithBrbValid);
                    if (billedInvoiceWithBrbValid && _.isNil(_.get(policyChangeVM, `${paymentDetailsPath}.lastInvoiceNotPaid_PV.value`))) {
                        // default to false (un-selected checkbox)
                        _.set(policyChangeVM, `${paymentDetailsPath}.lastInvoiceNotPaid_PV.value`, false);
                    }
                } catch (error) {
                    // change nothing
                    updateShowInvoiceOption(false);
                    _.set(policyChangeVM, `${paymentDetailsPath}.lastInvoiceNotPaid_PV.value`, undefined);
                }
            } else {
                // change nothing
                _.set(policyChangeVM, `${paymentDetailsPath}.lastInvoiceNotPaid_PV.value`, undefined);
                updateShowInvoiceOption(false);
            }
        },
        [EndorsementService, authHeader, policyChangeVM]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidatePaymentDetails: updateIsPaymentDetailsValid,
            onValueChange: writeValue,
            updateGettingPaymentDetails,
            onPaymentPlanChange,
            onValidate
        }
    };

    const overrideProps = {
        '@field': {
            showRequired: true,
            showOptional: false,
            labelPosition: 'left',
            phoneWide: {
                labelPosition: 'top'
            },
            disabled: gettingPaymentDetails
        },
        paymentDetails: {
            value: policyChangeVM,
            disabled: gettingPaymentDetails,
            isParentPageInitialized: isPageInitialised
        },
        paymentBankAccount: {
            value: policyChangeVM,
            isPaymentSEPA
        },
        paymentInvoiceOption: {
            visible: showInvoiceOption
        }
    };
    return (
        <WizardPage onNext={onNext} showPrevious={false} cancelLabel={commonMessages.cancel} skipWhen={getInitialValidation}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                showErrors={showErrors}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

PaymentChangePage.propTypes = wizardProps;
export default PaymentChangePage;
