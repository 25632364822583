const DUMMY_FIRSTNAME_BASIC = 'First Name';
const DUMMY_FIRSTNAME = '__Firstname__';
const DUMMY_LASTNAME = '__Lastname__';
const DUMMY_COMPANYNAME = '__CompanyName__';
const DUMMY_ENTERPRISENUMBER = '0000000097';
const DUMMY_EMAIL = '__dummy@dummy.be';
const DUMMY_ADDRESS_LINE_1 = '__Address__';
const DUMMY_ADDRESS_LINE_2 = '__0__';
const DUMMY_LICENSE_PLATE = '*'; // this is not agreed dummy value, but still happens
const DUMMY_YEAR = 119;

function clearDummyPastDates(date) {
    // If Date is older than 119, it is a dummy value which should be cleaned
    if (date !== undefined) {
        const year = date.year;
        const startingYear = new Date();
        const minYear = startingYear.getFullYear() - DUMMY_YEAR;

        if (year <= minYear) {
            date = undefined;
        }
        return date;
    }
    return date;
}

function hasDummyValue(value, dummyValue) {
    return value && value.toUpperCase() === dummyValue.toUpperCase();
}

function sanitisePrefillDataFromQuickQuote(contact) {
    if (hasDummyValue(contact.firstName, DUMMY_FIRSTNAME_BASIC)) {
        contact.firstName = undefined;
        contact.lastName = undefined;
        // person.primaryLanguage_PV = undefined;
        contact.maritalStatus = undefined;
        contact.socialStatus_PV = undefined;
        contact.emailAddress1 = undefined;
        contact.dateOfBirth = undefined;
        contact.gender = undefined;
    }
    if (hasDummyValue(contact.contactName, DUMMY_COMPANYNAME)) {
        contact.contactName = undefined;
        // In the BMS system, the name field is always mandatory so in case it is empty we know there is no accountholder passed from the BMS and we also need to make the language empty.
        // The language need to be made empty because it is always defaulted to french by PolicyCenter.
        contact.primaryLanguage_PV = undefined;
    }
    if (hasDummyValue(contact.enterpriseNumber_PV, DUMMY_ENTERPRISENUMBER)) {
        contact.enterpriseNumber_PV = undefined;
    }
    if (hasDummyValue(contact.firstName, DUMMY_FIRSTNAME) && hasDummyValue(contact.lastName, DUMMY_LASTNAME)) {
        // In the BMS system, the name field is always mandatory so in case it is empty we know there is no accountholder passed from the BMS and we also need to make the language empty.
        // The language need to be made empty because it is always defaulted to french by PolicyCenter.
        contact.primaryLanguage_PV = undefined;
    }
    if (hasDummyValue(contact.firstName, DUMMY_FIRSTNAME)) {
        contact.firstName = undefined;
    }
    if (hasDummyValue(contact.lastName, DUMMY_LASTNAME)) {
        contact.lastName = undefined;
    }
    if (hasDummyValue(contact.emailAddress1, DUMMY_EMAIL)) {
        contact.emailAddress1 = undefined;
    }
    if (contact.primaryAddress && (hasDummyValue(contact.primaryAddress.addressLine1, DUMMY_ADDRESS_LINE_1)
        || (contact.primaryAddress.displayName && contact.primaryAddress.displayName.includes(DUMMY_ADDRESS_LINE_1)))) {
        contact.primaryAddress.addressLine1 = undefined;
        contact.primaryAddress.addressLine2 = undefined;
        contact.primaryAddress.addressLine3 = undefined;
        contact.primaryAddress.city = undefined;
        contact.primaryAddress.postalCode = undefined;
    }
    if (contact.primaryAddress && hasDummyValue(contact.primaryAddress.addressLine2, DUMMY_ADDRESS_LINE_2)) {
        contact.primaryAddress.addressLine2 = undefined;
    }

    // If Date of birth is older than 119, it is a dummy value which should be cleaned
    if (contact.dateOfBirth !== undefined) {
        contact.dateOfBirth = clearDummyPastDates(contact.dateOfBirth);
    }
}

function sanitizeVehicleData(vehicle) {
    vehicle.firstRegistrationDate_PV = clearDummyPastDates(vehicle.firstRegistrationDate_PV);
    if (hasDummyValue(vehicle.license, DUMMY_LICENSE_PLATE)) {
        vehicle.license = undefined;
    }
}

export default {
    sanitisePrefillDataFromQuickQuote,
    sanitizeVehicleData,
    clearDummyPastDates
};
