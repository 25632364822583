import { defineMessages } from 'react-intl';

export default defineMessages({
    vehiclesTitle:{
        id:'quoteandbind.pcv.views.pcv-vehicle-details.Vehicles',
        defaultMessage:'Vehicles'
    },
    removeVehicle: {
        id: 'quoteandbind.ppv.controllers.PPVVehicleDetailsCtrl.Remove vehicle?',
        defaultMessage: 'Remove Vehicle?'
    },
    removeConfirmationMsg: {
        id: 'quoteandbind.ppv.controllers.PPVVehicleDetailsCtrl.RemoveConfirmationMsg',
        defaultMessage: 'Are you sure you want to remove {vehicle} from the list of vehicles?'
    },
    atLeast1TBErrorMsg: {
        id: 'quoteandbind.ppv.controllers.PPVVehicleDetailsCtrl.At least one Tourism & Business vehicle should be present',
        defaultMessage: 'At least one Tourism & Business vehicle should be present in a multi-vehicle contract.'
    },
    editQuoteMsg: {
        id: 'quoteandbind.ppv.controllers.PPVVehicleDetailsCtrl.Please edit your quote',
        defaultMessage: 'Please edit your quote'
    },
    vehicle: {
        id: "quoteandbind.ppv.views.ppv-vehicle-details.Vehicle",
        defaultMessage: 'Vehicle'
    },
    addOrRemoveVehicle: {
        id: 'quoteandbind.ppv.controllers.PPVVehicleDetailsCtrl.Please add or remove the edited vehicle',
        defaultMessage: 'Please add or remove the edited vehicle'
    },
    oneVehicleIncomplete: {
        id: 'quoteandbind.ppv.controllers.PPVVehicleDetailsCtrl.One of the vehicles that you added is incomplete.',
        defaultMessage: 'One of the vehicles that you added is incomplete.'
    },
    licenceMatchedMsg: {
        id: 'quoteandbind.ppv.controllers.PPVVehicleDetailsCtrl.LicenceMatchMsg',
        defaultMessage: 'Sorry, but the license plate of {firstVehicle} matches that of {secondVehicle}. Please ensure that all license plates are unique.'
    },
    licenseNotUnique: {
        id: 'quoteandbind.ppv.controllers.PPVVehicleDetailsCtrl.Licence plate is not unique',
        defaultMessage: 'Licence plate is not unique'
    },
    atLeast1SecuritySelected: {
        id: 'quoteandbind.quickquote.common.controllers.PPVQuickQuoteDetailsCtrl.Security System Installed',
        defaultMessage: 'Please select at least 1 security system in the list below'
    }

});
