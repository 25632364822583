import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { PolicyChangeService } from 'gw-capability-gateway';
import metadata from './PolicyChangeHistory.metadata.json5';
import styles from './PolicyChangeHistory.module.scss';
import summaryStyles from '../../Policies/Summary/Summary.module.scss';
import messages from './PolicyChangeHistory.messages.js';

function PolicyChangeHistory(props) {
    const { jobNumber, authHeader } = props;
    const translator = useTranslator();
    const [policyChangeHistory, setPolicyChangeHistory] = useState([]);

    useEffect(() => {
        const getPolicyHistoryData = async () => {
            const policyChangeSummary = await PolicyChangeService.getPolicyChangeSummary(jobNumber, authHeader);
            const historyPv = _.groupBy(policyChangeSummary.history_PV, 'entityType');
            const history = _.mapValues(historyPv, (entityType) =>
                _.countBy(entityType, 'action')
            );
            setPolicyChangeHistory(history);
        };
        getPolicyHistoryData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const appliedActionToString = useCallback((entityName) => {
        const entityType = policyChangeHistory[entityName];
        if (entityType) {
            if (entityType.changed) {
                return translator(messages[`changed${_.capitalize(entityName)}`]);
            }

            if (entityType.added) {
                return translator(messages[`added${_.capitalize(entityName)}`]);
            }

            if (entityType.removed) {
                return translator(messages[`removed${_.capitalize(entityName)}`]);
            }
        }

        return '';
    }, [policyChangeHistory, translator]);

    const overrides = {
        contractChangeDetailsSection: {
            cardHeadingClassName: summaryStyles.accordionHeaderSummary
        },
        vehicleContainer: {
            visible: !_.isEmpty(policyChangeHistory.vehicle)
        },
        driverContainer: {
            visible: !_.isEmpty(policyChangeHistory.driver)
        },
        coverageContainer: {
            visible: !_.isEmpty(policyChangeHistory.coverage)
        },
        paymentContainer: {
            visible: !_.isEmpty(policyChangeHistory.payment)
        },
        vehicleChangeMadeData: {
            content: appliedActionToString('vehicle')
        },
        driverChangeMadeData: {
            content: appliedActionToString('driver')
        },
        coverageChangeMadeData: {
            content: appliedActionToString('coverage')
        },
        paymentChangeMadeData: {
            content: appliedActionToString('payment')
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };

    return <MetadataContent
        uiProps={metadata.pageContent}
        overrideProps={overrides}
        {...resolvers} />;
}

PolicyChangeHistory.propTypes = {
    authHeader: PropTypes.shape({}).isRequired,
    jobNumber: PropTypes.string.isRequired
};
export default PolicyChangeHistory;
