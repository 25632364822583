import { defineMessages } from 'react-intl';

export default defineMessages({
    country: {
        id: 'platform.inputs.address-details.Country',
        defaultMessage: 'Country'
    },
    selectCountry: {
        id: 'quoteandbind.ppv.views.ppv-contact-details.Select Country',
        defaultMessage: '-- Select Country --'
    },
    postalCodeOrCity: {
        id: 'platform.inputs.address-details.Postal code or City',
        defaultMessage: 'Postal Code Or City'
    },
    street: {
        id: 'pv.platform.inputs.address-details.Address Line 1',
        defaultMessage: 'Street'
    },
    number: {
        id: 'platform.inputs.address-details.Address Line 2',
        defaultMessage: 'Number'
    },
    box: {
        id: 'platform.inputs.address-details.Address Line 3',
        defaultMessage: 'Box'
    },
    emptyLabel: {
        id: 'pv.address.components.addressLookup.Empty Label',
        defaultMessage: ' '
    }
});
