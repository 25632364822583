import { defineMessages } from 'react-intl';

export default defineMessages({
    address: {
        id: 'gateway.views.new-quote.Address',
        defaultMessage: 'Address'
    },
    legalForm: {
        id: 'gateway.views.new-quote.Legal Form',
        defaultMessage: 'Legal Form'
    },
    pleaseSelect: {
        id: 'gateway.views.new-quote.Please Select',
        defaultMessage: 'Please Select'
    },
    specialTaxRegime: {
        id: 'gateway.views.new-quote.Special Tax Regime',
        defaultMessage: 'Special Tax Regime'
    },
    generalInformation:{
        id:"gateway.views.new-quote.General Information",
        defaultMessage: "General Information"
    }
});
