import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


export default class IssuanceService {

    static getOrCreateIssuance(sessionId, quoteId, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('issuance'),
            'getOrCreateForSubmission', [sessionId, quoteId], additionalHeaders);
    }

    static quoteAndBindIssuance(issuance, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('issuance'),
            'quoteAndBind', [issuance], additionalHeaders);
    }
}
