import React, {
    useContext, useCallback, useState, useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader, useModal } from '@jutro/components';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { IntlContext } from '@jutro/locale';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { valueTypeMap, getFormattedValue } from './ScheduleItemsUtil';
import ScheduleItemModalPopover from './ScheduleItemModalPopover';
import messages from './ScheduleItemsComponent.messages';
import styles from './ScheduleItemsComponent.module.scss';
import metadata from './ScheduleItemsComponent.metadata.json5';

import { Button } from '@jutro/legacy/components';

/**
 * A component for rendering Schedule Items
 *
 * @param {object} props - the props for rendering the component
 * @param {object} props.value - an object that contains the array of schedule items and property infos
 * @param {function} props.onScheduleChange - function to trigger when a schedule item changes
 * @param {string} props.path - the path to the schedule items on the data model
 * @param {string} props.id - the ID of the component
 * @param {string} props.labelPosition - where the label should be positioned - default: left
 * @param {boolean} props.readOnly - a flag to indicate if the component should render as readonly
 * @param {boolean} props.showTitle - a flag to indicate if the title should be shown
 * @param {boolean} props.phoneWide - a flag to indicate if the layout is phoneWide
 * @returns {object} a ScheduleItem Element
 */
function ScheduleItemsComponent(props) {
    const {
        showConfirm,
        showModal
    } = useModal();

    const viewModelService = useContext(ViewModelServiceContext);
    const intl = useContext(IntlContext);
    const [isLoading, setLoading] = useState(false);
    const {
        value: scheduleItem,
        labelPosition,
        id,
        readOnly,
        onScheduleChange,
        path,
        showTitle,
        phoneWide
    } = props;

    const renderCell = useCallback((items, index, property) => {
        const { id: pathToValue, valueType } = property.data;

        if (valueType === 'ICON') {
            const { icon, onClick } = property.data;
            return (
                <Button
                    className="actionIcon"
                    icon={icon}
                    type="outlined"
                    onClick={onClick(items, index)}
                />
            );
        }

        const valueObject = _.get(items.itemData, pathToValue);
        const value = _.get(valueObject, valueTypeMap[valueType]);

        if (valueType === 'INTEGER' && _.has(property.data, 'currency')) {
            const { currency } = property.data;
            return intl.formatNumber(
                value,
                {
                    style: 'currency',
                    currency: currency,
                    currencyDisplay: 'code'
                }
            );
        }
        if (valueType === 'DATE') {
            return intl.formatDate(new Date(value), { year: 'numeric', month: 'short', day: 'numeric' });
        }
        return getFormattedValue(value, valueType, property.data);
    }, [intl]);

    const generateColumnOverrides = useCallback(
        (columnData) => {
            const overrideProps = columnData.map((info, index) => ({
                [`scheduleItemColumn${index}`]: {
                    renderCell: renderCell,
                    header: info.id === 'ArticleLimit' ? messages.scheduledPersonalPropertyValueColumnHeader : info.label
                }
            }));
            return Object.assign({}, ...overrideProps);
        },
        [renderCell]
    );

    const processModalData = useCallback((modalData = {}) => {
        const data = modalData;
        if (data.policyContact) {
            delete data.policyContact;
        } else if (data.location) {
            delete data.location;
        }
        return data;
    }, []);

    const openModal = useCallback(
        async (scheduleData) => {
            const componentProps = {
                propertyInfo: scheduleItem.propertyInfos,
                scheduleData: processModalData(scheduleData),
                viewModelService,
                scheduleItem,
                labelPosition,
                phoneWide,
                id,
                isNew: !scheduleData
            };

            const result = await showModal(
                <ScheduleItemModalPopover {...componentProps} />
            );
            return result;
        },
        [id, labelPosition, scheduleItem, viewModelService, phoneWide, processModalData, showModal]
    );

    const removeSchedule = useCallback(
        (scheduleToRemove) => () => {
            showConfirm({
                title: messages.scheduleRemoveTitle,
                message: messages.scheduleRemove,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: messages.delete,
                cancelButtonText: messages.cancel
            }).then((results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                const clonedSchedule = _.cloneDeep(scheduleItem);
                clonedSchedule.scheduleItems = clonedSchedule.scheduleItems.filter(
                    (schedule) => !_.isEqual(schedule, scheduleToRemove)
                );

                if (onScheduleChange) {
                    setLoading(true);
                    onScheduleChange(clonedSchedule, path).finally(() => setLoading(false));
                }
            }, _.noop);
        },
        [onScheduleChange, path, scheduleItem, showConfirm]
    );

    const editSchedule = useCallback(
        (schedule, index) => () => {
            const localPathToSchedule = `scheduleItems[${index}]`;

            openModal(schedule)
                .then((updatedSchedule) => {
                    const clonedSchedule = _.cloneDeep(scheduleItem);
                    _.set(clonedSchedule, localPathToSchedule, updatedSchedule);

                    if (onScheduleChange) {
                        setLoading(true);
                        onScheduleChange(clonedSchedule, path).finally(() => setLoading(false));
                    }
                })
                .catch(_.noop);
        },
        [onScheduleChange, path, scheduleItem, openModal]
    );

    const addSchedule = useCallback(() => {
        openModal()
            .then((schedule) => {
                const clonedSchedule = _.cloneDeep(scheduleItem);
                const newScheduleItem = {
                    '@deserialization-class': scheduleItem.deserializationClass,
                    ...schedule
                };

                clonedSchedule.scheduleItems = [...scheduleItem.scheduleItems, newScheduleItem];

                if (onScheduleChange) {
                    setLoading(true);
                    onScheduleChange(clonedSchedule, path).finally(() => setLoading(false));
                }
            })
            .catch(_.noop);
    }, [onScheduleChange, path, scheduleItem, openModal]);

    const columnData = useMemo(() => {
        const orderedPropertyInfo = _.sortBy(scheduleItem.propertyInfos, 'order');
        const icons = [
            {
                valueType: 'ICON',
                icon: 'mi-delete',
                onClick: removeSchedule
            },
            {
                valueType: 'ICON',
                icon: 'mi-edit',
                onClick: editSchedule
            }
        ];
        const iconsShow = !readOnly ? icons : [];
        return [...orderedPropertyInfo, ...iconsShow];
    }, [editSchedule, readOnly, removeSchedule, scheduleItem.propertyInfos]);

    const renderAdd = useCallback(
        () => (
            <Button icon="mi-add" onClick={addSchedule} type="outlined">
                {messages.scheduleAdd}
            </Button>
        ),
        [addSchedule]
    );

    const overrideProps = useMemo(() => {
        return {
            scheduleItemTable: {
                data: columnData,
                title: showTitle ? scheduleItem.displayName : undefined,
                renderTitleAction: !readOnly ? renderAdd : undefined,
                placeholder: readOnly ? messages.noItems : '',
                className: styles.scheduleItemsTableOverflow,
            },
            ...generateColumnOverrides(columnData)
        };
    }, [
        columnData,
        scheduleItem.displayName,
        showTitle,
        readOnly,
        renderAdd,
        generateColumnOverrides
    ]);

    const readValue = useCallback(
        (elementId, schedulePath) => {
            return _.get(scheduleItem, schedulePath);
        },
        [scheduleItem]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveClassNameMap: styles
    };

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

ScheduleItemsComponent.propTypes = {
    value: PropTypes.shape({
        scheduleItems: PropTypes.arrayOf(PropTypes.shape({})),
        propertyInfos: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    onScheduleChange: PropTypes.func,
    path: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    labelPosition: PropTypes.string,
    readOnly: PropTypes.bool,
    showTitle: PropTypes.bool,
    phoneWide: PropTypes.shape({})
};

ScheduleItemsComponent.defaultProps = {
    readOnly: false,
    showTitle: true,
    phoneWide: {
        labelPosition: 'top'
    },
};

export default ScheduleItemsComponent;
