import { defineMessages } from 'react-intl';

export default defineMessages({
    Notes: {
        id: 'gateway.views.activity-detail.Notes',
        defaultMessage: 'Notes'
    },
    Documents: {
        id: 'gateway.views.cancellation-detail.Documents',
        defaultMessage: 'Documents'
    },
    Claims: {
        id: 'gateway.views.policy-detail-summary.Claims',
        defaultMessage: 'Claims'
    },
    searchTransactions: {
        id: 'gateway.views.policy-detail.Search Transactions',
        defaultMessage: 'Search Transactions'
    },
    Billing: {
        id: 'gateway.views.policy-detail-summary.Billing',
        defaultMessage: 'Billing'
    },
    Commission: {
        id: 'gateway.views.policy-detail-summary.Commission',
        defaultMessage: 'Commission'
    },
    Businessowners: {
        id: 'gateway.views.policy-detail-summary.Businessowners',
        defaultMessage: 'Businessowners'
    },
    JobNumber: {
        id: 'gateway.directives.policy-transaction.Job Number',
        defaultMessage: 'Job Number'
    },
    TransactionStatus: {
        id: 'gateway.directives.policy-transaction.Transaction Status',
        defaultMessage: 'Transaction Status'
    },
    Type: {
        id: 'gateway.directives.policy-transaction.Type',
        defaultMessage: 'Type'
    },
    PeriodStatus: {
        id: 'gateway.directives.policy-transaction.Period Status',
        defaultMessage: 'Period Status'
    },
    Premium: {
        id: 'gateway.directives.policy-transaction.Premium',
        defaultMessage: 'Premium'
    },
    EffectiveDate: {
        id: 'gateway.directives.policy-transaction.Effective Date',
        defaultMessage: 'Effective Date'
    },
    CancelPolicy: {
        id: 'gateway.views.policy-detail-summary.Cancel Policy',
        defaultMessage: 'Cancel Contract'
    },
    ChangePolicy: {
        id: 'gateway.views.policy-detail-summary.Change Policy',
        defaultMessage: 'Change Contract'
    },
    RenewPolicy: {
        id: 'gateway.views.policy-detail-summary.Renew Policy',
        defaultMessage: 'Renew Contract'
    },
    DeclarationPage: {
        id: 'gateway.views.policy-detail-summary.Declaration page',
        defaultMessage: 'Declaration page'
    },
    IdCard: {
        id: 'gateway.views.policy-detail-summary.ID Card',
        defaultMessage: 'ID Card'
    },
    PolicyTransactions: {
        id: 'gateway.views.policy-detail.Policy Transactions',
        defaultMessage: 'Contract Transactions'
    },
    Details: {
        id: 'gateway.views.policy-detail.Details',
        defaultMessage: 'Details'
    },
    SummaryDetails: {
        id: 'gateway.views.policy-detail.Summary Details',
        defaultMessage: 'Summary Details'
    },
    SummaryDetailsPreRenewalDirectionNone: {
        id: 'gateway.views.policy-detail.None',
        defaultMessage: 'None'
    },
    ProducerInformation: {
        id: 'gateway.views.policy-detail.Producer Information',
        defaultMessage: 'Producer Information'
    },
    PolicyValue: {
        id: 'gateway.views.policy-detail.Policy Value',
        defaultMessage: 'Contract Value'
    },
    Coverages: {
        id: 'gateway.views.policy-detail.Coverages',
        defaultMessage: 'Coverages'
    },
    PolicyInception: {
        id: 'gateway.views.policy-detail-summary.Policy Inception',
        defaultMessage: 'Contract Inception'
    },
    PolicyExpiration: {
        id: 'gateway.views.policy-detail-summary.Policy Expiration',
        defaultMessage: 'Contract Expiration'
    },
    ProducerOfRecord: {
        id: 'gateway.views.policy-detail.Producer of Record',
        defaultMessage: 'Producer of Record'
    },
    PreRenewalDirection: {
        id: 'gateway.views.policy-detail-summary.Pre Renewal Direction',
        defaultMessage: 'Pre-Renewal Direction'
    },
    PolicyStatus: {
        id: 'gateway.views.policy-detail-summary.Policy Status',
        defaultMessage: 'Contract Status'
    },
    ProducerOfService: {
        id: 'gateway.views.policy-detail.Producer of Service',
        defaultMessage: 'Producer of Service'
    },
    TotalPremium: {
        id: 'gateway.views.policy-detail.Total Premium',
        defaultMessage: 'Total Premium'
    },
    CommercialPremium: {
        id: 'gateway.views.policy-detail.Commercial Premium',
        defaultMessage: 'Commercial Premium'
    },
    TaxesAndFees: {
        id: 'gateway.views.renewal.renewal-summary.Taxes and Fees',
        defaultMessage: 'Taxes and Fees'
    },
    TotalCost: {
        id: 'gateway.views.renewal.renewal-summary.Total Cost',
        defaultMessage: 'Total Cost'
    },
    NoneRenew: {
        id: 'gateway.views.policy-detail-summary.None',
        defaultMessage: 'None'
    },
    vehicles: {
        id: 'policy.ppv.directives.policy-vehicle-info.vehicles',
        defaultMessage: 'Vehicles'
    },
    make: {
        id: 'policy.ppv.directives.policy-driver-info.Make',
        defaultMessage: 'Make'
    },
    model: {
        id: 'policy.ppv.directives.policy-driver-info.Model',
        defaultMessage: 'Model'
    },
    year: {
        id: 'policy.pa.directives.policy-vehicle-info.year',
        defaultMessage: 'Year'
    },
    licensePlate: {
        id: 'policy.pa.directives.policy-vehicle-info.license plate',
        defaultMessage: 'License Plate'
    },
    hideVehicleCoverages: {
        id: 'policy.pa.directives.policy-vehicle-info.hide vehicle coverages',
        defaultMessage: 'Hide Vehicle Coverages'
    },
    showVehicleCoverages: {
        id: 'policy.pa.directives.policy-vehicle-info.show vehicle coverages',
        defaultMessage: 'Show Vehicle Coverages'
    },
    Coverage: {
        id: 'policy.ppv.directives.policy-vehicle-info.coverage',
        defaultMessage: 'Coverage'
    },
    Deductible: {
        id: 'policy.pa.directives.policy-vehicle-info.deductible',
        defaultMessage: 'Deductible'
    },
    Limits: {
        id: 'policy.pa.directives.policy-vehicle-info.limits',
        defaultMessage: 'Limits'
    },
    vehiclePremiumPPV: {
        id: 'policy.ppv.directives.policy-vehicle-info.Premium',
        defaultMessage: 'Premium'
    },
    vehiclePremiumPCV: {
        id: 'policy.pcv.directives.policy-vehicle-info.Premium',
        defaultMessage: 'Premium'
    },
    vehicleDesignatedDriver: {
        id: 'policy.pcv.directives.policy-vehicle-info.designated driver',
        defaultMessage: 'Designated Driver'
    },
    vehicleDriverCount: {
        id: 'policy.pcv.directives.policy-vehicle-info.Driver Count',
        defaultMessage: 'Driver Count'
    },
    PremiumBreakdown: {
        id: 'policy.ppv.directives.policy-vehicle-info.premium breakdown',
        defaultMessage: 'Premium Breakdown'
    },
    vehicleInfoType: {
        id: 'policy.pa.directives.policy-vehicle-info.type',
        defaultMessage: 'Type'
    },
    vehicleInfoPolicy: {
        id: 'policy.pa.directives.policy-vehicle-info.Policy',
        defaultMessage: 'Policy'
    },
    coveredDrivers: {
        id: 'policy.ppv.directives.policy-driver-info.Covered Drivers',
        defaultMessage: 'Covered Drivers'
    },
    coveredDriversName: {
        id: 'policy.ppv.directives.policy-driver-info.Name',
        defaultMessage: 'Name'
    },
    coveredDriversLicense: {
        id: 'policy.pa.directives.policy-driver-info.License',
        defaultMessage: 'License'
    },
    dateOfBirth: {
        id: 'policy.ppv.directives.policy-driver-info.Date of Birth',
        defaultMessage: 'Date Of Birth'
    },
    licencePlateDriver: {
        id: 'policy.ppv.directives.policy-driver-info.Licence Plate',
        defaultMessage: 'License Plate'
    },
    licensePlateVehicle:{
        id: 'policy.ppv.directives.policy-vehicle-info.license plate',
        defaultMessage: 'License Plate'
    },
    totalPremium: {
        id: 'integration.policy.directives.policy-coverages.Total Premium:',
        defaultMessage: 'Total Premium:'
    },
    noPolicy: {
        id: "gateway.views.policy-detail.findPolicy.nopolicy",
        defaultMessage: "Could not find a policy with number: {policyNumber}."
    },
    noPolicyInEffect: {
        id: "gateway.views.policy-detail.findPolicy.no period in effect",
        defaultMessage: "No period effective on : {asOfDate}"
    },
    activePayment:{
        id: "gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Active payment method",
        defaultMessage: "Active Payment Method"
    },
    viewContractAsOf:{
        id: "gateway.views.policy-detail.ViewContractAsOf",
        defaultMessage: "View contract as of"
    }

});
