import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { appendMetadataWithIndex } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { AddressLookupService } from 'gw-capability-address';
import metadata from './AddressLookupComponent.metadata.json5';
import styles from './AddressLookupComponent.module.scss';
import './AddressLookupComponent.messages';

function AddressLookupComponent(props) {
    const {
        value: addressVM,
        labelPosition,
        showRequired,
        path,
        id,
        onValidate,
        onValueChange,
        showErrors,
        hideFields,
        index,
        readOnly,
        authCode
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [postalCodeCity, updatePostalCodeCity] = useState(addressVM.postalCode?.value && addressVM.city?.value ? {postalCode: addressVM.postalCode.value, city: addressVM.city.value} : undefined);
    const [postalCodeCityValues, updatePostalCodeCityValues] = useState([]);
    const [originalPostalCodeCityNotFound, updateOriginalPostalCodeCityNotFound] = useState(undefined);
    const [lastStreetSearch, updateLastStreetSearch] = useState();
    const [foundAddresses, updateFoundAddresses] = useState([]);
    const [isStreetLoading, updateIsStreetLoading] = useState(false);

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.componentContent, index);
    }, [index]);

    useEffect(() => {
        const retrievePostalCodeCityValues = async () => {
            const result = await AddressLookupService.getListPostalCodeCity(authHeader || authCode);
            const formattedValues = result.map((value, indexValue) => ({
                displayName: `${value.city} (${value.postalCode})`,
                city: value.city,
                postalCode: value.postalCode,
                id: indexValue,
                type: 'address'
            }));
            updatePostalCodeCityValues(formattedValues);
        };

        retrievePostalCodeCityValues();
        // Execute only on first render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Define postalCodeCity value
        const address = _.get(addressVM, 'value', {});
        if (!_.isEmpty(postalCodeCityValues) && !_.isUndefined(address.city) && !_.isUndefined(address.postalCode)) {
            const foundValue = postalCodeCityValues.find((value) => value.city.toLowerCase() === address.city.toLowerCase() && value.postalCode === address.postalCode);
            if (foundValue) {
                updatePostalCodeCity(foundValue);
            } else {
                // If the current combination city/postal code wasn't found
                // we inject it as a new option
                const value = {
                    displayName: `${address.city} (${address.postalCode})`,
                    city: address.city,
                    postalCode: address.postalCode,
                    id: postalCodeCityValues.length,
                    type: 'address'
                };
                updatePostalCodeCityValues([...postalCodeCityValues, value]);
                updatePostalCodeCity(foundValue);
                updateOriginalPostalCodeCityNotFound(postalCodeCityValues.length);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postalCodeCityValues, addressVM?.value]);


    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [addressVM, id, onValidate, isComponentValid]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = path ? `${path}.${changedPath}` : changedPath;
        if (onValueChange) {
            onValueChange(value, fullPath);
        }
    }, [onValueChange, path]);

    const loadPostalCodeValues = useCallback((searchParam) => {
        if (!searchParam || (searchParam && searchParam.length < 3)) {
            return [];
        }

        const numberToDisplay = _.isNumber(_.toNumber(searchParam)) ? Infinity : 10;
        const values = _.take(postalCodeCityValues, numberToDisplay);
        if (originalPostalCodeCityNotFound) {
            return values.filter((value) => value.id !== originalPostalCodeCityNotFound);
        }
        return values;
    }, [originalPostalCodeCityNotFound, postalCodeCityValues]);

    const onPostalCodeOrCityChange = useCallback((value) => {
        if (value) {
            updatePostalCodeCity({ ...value });
            handleValueChange(value.city, 'city');
            handleValueChange(value.postalCode, 'postalCode');
        } else {
            updatePostalCodeCity(undefined);
            handleValueChange(undefined, 'city');
            handleValueChange(undefined, 'postalCode');
        }
    }, [handleValueChange]);

    const onChangeStreet = useCallback((value, changedPath) => {
        handleValueChange(value, changedPath);
        updateFoundAddresses([]);
    }, [handleValueChange]);

    const onSelectStreet = useCallback((value) => {
        handleValueChange(foundAddresses[value].displayName, 'addressLine1');
        handleValueChange(null, 'streetInvalid_PV');
        updateFoundAddresses([]);
    }, [foundAddresses, handleValueChange]);

    const onBlurStreet = useCallback(async () => {
        if (lastStreetSearch === addressVM.addressLine1.value) {
            return;
        }

        updateLastStreetSearch(addressVM.addressLine1.value);
        updateIsStreetLoading(true);
        try {
            // PV-19905: Added missing authcode
            const response = await AddressLookupService.autofillBasedOnPostalCode(addressVM.value, authHeader || authCode);
            // only show results for the current postal code so the same street won't appear multiple times
            const filteredMatches = response.matches
                .filter((match) => match.address.postalCode === addressVM.postalCode.value)
                .map((match, indexValue) => ({
                    id: indexValue,
                    displayName: match.address.addressLine1
                }));
            if (filteredMatches.length === 1) {
                handleValueChange(filteredMatches[0].displayName, 'addressLine1');
                handleValueChange(null, 'streetInvalid_PV');
            } else if (filteredMatches.length > 1) {
                const exactMatch = filteredMatches.find((item) => item.displayName.toLowerCase() === addressVM.addressLine1.value.toLowerCase());
                if (exactMatch) {
                    handleValueChange(exactMatch.displayName, 'addressLine1');
                    handleValueChange(null, 'streetInvalid_PV');
                    updateFoundAddresses([]);
                } else {
                    handleValueChange(true, 'streetInvalid_PV');
                    updateFoundAddresses(filteredMatches);
                }
            } else {
                // In case we don't get results from dedale then it means the street is invalid
                handleValueChange(true, 'streetInvalid_PV');
            }
        } catch {
            handleValueChange(null, 'streetInvalid_PV');
        } finally {
            updateIsStreetLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressVM.value, handleValueChange, lastStreetSearch]);

    const postalCodeCityProps = useMemo(() => {
        const required = addressVM?.postalCode?.aspects?.required;
        const validationMessages = addressVM?.postalCode?.aspects?.validationMessages || [];

        return {
            validationMessages: validationMessages.map((message) => translator(message)),
            required
        };
    }, [addressVM?.postalCode?.aspects?.required, addressVM?.postalCode?.aspects?.validationMessages, translator]);

    const overrideProps = {
        '@field': {
            labelPosition,
            showRequired,
            showErrors,
            readOnly
        },
        [`country${index}`]: {
            visible: !_.includes(hideFields, 'country')
        },
        [`postalCodeOrCity${index}`]: {
            visible: !_.includes(hideFields, 'postalCodeOrCity'),
            value: postalCodeCity,
            internalClassNames: styles,
            ...postalCodeCityProps
        },
        [`addressLine1${index}`]: {
            visible: !_.includes(hideFields, 'addressLine1'),
            disabled: _.isUndefined(postalCodeCity)
        },
        [`addressLine1Select${index}`]: {
            visible: !_.isEmpty(foundAddresses),
            availableValues: foundAddresses
        },
        [`addressLine2${index}`]: {
            visible: !_.includes(hideFields, 'addressLine2')
        },
        [`addressLine3${index}`]: {
            visible: !_.includes(hideFields, 'addressLine3')
        },
        [`streetLoader${index}`]: {
            visible: isStreetLoading,
            loading: isStreetLoading
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            loadPostalCodeValues,
            onPostalCodeOrCityChange,
            onChangeStreet,
            onSelectStreet,
            onBlurStreet
        }
    };

    return (
        <ViewModelForm
            uiProps={formattedMetadata}
            model={addressVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

AddressLookupComponent.propTypes = {
    value: PropTypes.shape({}),
    labelPosition: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    hideFields: PropTypes.arrayOf(PropTypes.string),
    readOnly: PropTypes.bool,
    showRequired: PropTypes.bool,
    showErrors: PropTypes.bool,
    index: PropTypes.number,
    authCode: PropTypes.shape({})
};

AddressLookupComponent.defaultProps = {
    value: {},
    path: undefined,
    labelPosition: 'left',
    hideFields: [],
    readOnly: false,
    id: undefined,
    showRequired: true,
    showErrors: false,
    index: 0,
    authCode: {}
};

export default AddressLookupComponent;
