import _ from 'lodash';
import BusinessConstant from './BusinessConstant';
import VehicleUtil from './VehicleUtil';

const {
    isOldTimerAndClassic,
    isTrailerVehicle,
    isTwoWheelsAndsimilar,
    isTourismAndBusiness,
    isMobilhomeVehicle,
    isPrimaryUsageFoodTruck,
    isPrimaryUsageMarketVehicle,
    isCourtesyVehicle
} = VehicleUtil;

function isBrokenWindowSelected(coverages) {
    const brokenWindowCoverage = coverages.find((cov) => cov.publicID === 'PPVBrokenWindowCovPv' || cov.publicID === 'PCVBrokenWindowCovPvCV');
    return !_.isUndefined(brokenWindowCoverage) && brokenWindowCoverage.selected;
};

function isForceOfNatureSelected(coverages) {
    const forceOfNatureCoverage = coverages.find((cov) => cov.publicID === 'PPVForceOfNatureCovPv' || cov.publicID === 'PCVForceOfNatureCovPvCV');
    return !_.isUndefined(forceOfNatureCoverage) && forceOfNatureCoverage.selected;
};

function isTheftCov(coverage) {
    return coverage.publicID === 'PPVTheftCovPv' || coverage.publicID === 'PCVTheftCovPvCV';
}

function isMaterialDamageCov(coverage) {
    return coverage.publicID === 'PPVMaterialDamageCovPv';
}

function isMaterialDamageCLCov(coverage) {
    return coverage.publicID === 'PCVMaterialDamageCovPvCV';
}

function isSystemCompFormulaCov(coverage) {
    return coverage.publicID === 'PPVSystemCompFormulaFakeCovPv' || coverage.publicID === 'PCVSystemCompFormulaFakeCovPVCV';
}

function isClassicalInvoiceSelected(coverage) {
    return isSystemCompFormulaCov(coverage) &&
        coverage.terms.some((term) => term.chosenTerm === 'zkeg6ppqqutntatbiaccgnscvka' || term.chosenTerm === 'ztujc3p33mevrdca2tuuldi6v79');
}

function isClassicalCatalogSelected(coverage) {
    return isSystemCompFormulaCov(coverage) &&
        coverage.terms.some((term) => term.chosenTerm === 'z2uiocm7chno07ebs32cos0h1v9' || term.chosenTerm === 'zlgjuqi1r6v9ofqtcfnm6uvjq79');
}

function checkGarageDisplay(coverage, selectedCoverages, categoryName) {
    return (((coverage.publicID === 'PPVBrokenWindowCovPv' || coverage.publicID === 'PCVBrokenWindowCovPvCV')
        || ((coverage.publicID === 'PPVForceOfNatureCovPv' || coverage.publicID === 'PCVForceOfNatureCovPvCV') && !isBrokenWindowSelected(selectedCoverages))
        || ((coverage.publicID === 'PPVTheftCovPv' || coverage.publicID === 'PCVTheftCovPvCV') && !isBrokenWindowSelected(selectedCoverages) && !isForceOfNatureSelected(selectedCoverages))))
        && (!isTwoWheelsAndsimilar(categoryName) && !isTrailerVehicle(categoryName));
}

function visibleFields({ vehicleVM, coverage = {}, coverages = [], quickQuoteMode, vpVersionChecker }) {

    const categoryName = _.get(vehicleVM, 'category.value.code');
    const primaryUse = _.get(vehicleVM, 'primaryUse.value.code');
    const selectedCoverages = coverages.filter((c) => c.selected === true);
    const firstMyVehicleCoverageSelected = selectedCoverages
        .find((cov) => cov.coverageCategoryCode === 'PPVMyVehicleGrp' || cov.coverageCategoryCode === 'PCVMyVehicleGrpCV') || {};

    const isFieldVisible = (fieldConditionFn) => {
        // Coverage level check - used in Pack screen
        if (!_.isEmpty(coverage)) {
            return fieldConditionFn(coverage, selectedCoverages, categoryName);
        }

        // Vehicle level check - used in Summary screen
        const foundCoverages = _.filter(selectedCoverages, (cov) => {
            return fieldConditionFn(cov, selectedCoverages, categoryName);
        });
        return !_.isEmpty(foundCoverages);
    };

    const isEqualOrAfterVP6 = vpVersionChecker.isEqualOrAfterVP6();
    const showSystemCompFormulaField = isFieldVisible(isSystemCompFormulaCov) && !(isPrimaryUsageFoodTruck(primaryUse) || isPrimaryUsageMarketVehicle(primaryUse));
    const showTheftField = !quickQuoteMode && isFieldVisible(isTheftCov);

    return {
        garaged: {
            visible: isFieldVisible(checkGarageDisplay)
        },
        claimsMD5YearsFault_PV: {
            visible: quickQuoteMode && isFieldVisible(isMaterialDamageCov),
        },
        claimsMD5YearsFaultCL_PV: {
            visible: quickQuoteMode && isFieldVisible(isMaterialDamageCLCov),
        },
        catalogValue_PV: {
            // NOTE: visibility depends on VP version
            visible: (!isEqualOrAfterVP6 && showSystemCompFormulaField && (!isOldTimerAndClassic(categoryName) && !isCourtesyVehicle(primaryUse))
                    || isEqualOrAfterVP6 && showSystemCompFormulaField && (isFieldVisible(isClassicalCatalogSelected) && !isOldTimerAndClassic(categoryName) && !isCourtesyVehicle(primaryUse)))
        },
        optionsValue: {
            visible: showSystemCompFormulaField && (!isOldTimerAndClassic(categoryName) && !isCourtesyVehicle(primaryUse))
        },
        accessoriesValue: {
            visible: showSystemCompFormulaField && (!isOldTimerAndClassic(categoryName) && !isCourtesyVehicle(primaryUse))
        },
        invoiceValue: {
            // NOTE: annoying duplication here due invoiceValue vs. invoiceValue6 different placement on Pack screen
            visible: !isEqualOrAfterVP6 && showSystemCompFormulaField && (isFieldVisible(isClassicalInvoiceSelected) && !isOldTimerAndClassic(categoryName) && !isCourtesyVehicle(primaryUse))
        },
        invoiceValue6: {
            // NOTE: annoying duplication here due invoiceValue vs. invoiceValue6 different placement on Pack screen
            visible: isEqualOrAfterVP6 && showSystemCompFormulaField && (isFieldVisible(isClassicalInvoiceSelected) && !isOldTimerAndClassic(categoryName) && !isCourtesyVehicle(primaryUse))
        },
        invoiceDate: {
            // NOTE: visibility depends on VP version
            visible: isEqualOrAfterVP6 && showSystemCompFormulaField && (isFieldVisible(isClassicalInvoiceSelected) && !isOldTimerAndClassic(categoryName) && !isCourtesyVehicle(primaryUse))
        },
        expertValue_PV: {
            visible: showSystemCompFormulaField && (isOldTimerAndClassic(categoryName) && !isCourtesyVehicle(primaryUse))
        },
        carDealerValue_PV: {
            visible: showSystemCompFormulaField && (!isOldTimerAndClassic(categoryName) && isCourtesyVehicle(primaryUse))
        },
        antiTheftCode: {
            visible: showTheftField && (isTourismAndBusiness(categoryName) || isMobilhomeVehicle(categoryName)),
        },
        antiTheftSystemOther: {
            visible: showTheftField && (_.get(vehicleVM, 'antiTheftCode.value.code') === BusinessConstant.ANTI_THEFT_CODE_OTHER)
        },
        antiTheftCodeMoto_PV: {
            visible: showTheftField && isTwoWheelsAndsimilar(categoryName)
        },
        existingDamage: {
            visible: !quickQuoteMode && !isOldTimerAndClassic(categoryName) && !isTrailerVehicle(categoryName)
                && firstMyVehicleCoverageSelected.publicID === coverage.publicID
        }
    };

}

export default {
    visibleFields,
    isClassicalInvoiceSelected
};
