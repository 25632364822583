import _ from 'lodash';
import { useCallback } from 'react';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import BusinessConstant from 'pv-portals-util-js/BusinessConstant';
import DriverUtil from 'pv-portals-util-js/DriverUtil';
import VehicleUtil from 'pv-portals-util-js/VehicleUtil';
import messages from './DriversPage.messages';

const DriverPageUtil = () => {

    const {
        showAlert
    } = useModal();

    const translator = useTranslator();

    const validVehicleDrivers = useCallback((coverables, isCommercial) => {

        // 1) at least 1 PRI driver. different check PCV vs. PPV
        if (isCommercial) {
            // 1a) each vehicle can have only 1 PRI driver
            const designatedDriversSelected = _.get(coverables, 'vehicles[0].designatedDriver_PV', false);

            // check only if drivers exist at all ...
            if (designatedDriversSelected) {

                for (const v of coverables.vehicles) { // NOTE: do not use .forEach since 'return' does not escape from it

                    const mainVehicleDrivers = coverables.vehicleDrivers.filter(
                        (vd) => (vd.vehicleID && vd.vehicleID === v.fixedId || vd.vehicleTempID && vd.vehicleTempID === v.tempID)
                                && vd.vehicleDriverUsage === 'PRI'
                    );
                    const vehicleName = VehicleUtil.getShortDisplayName(v, translator);

                    if (mainVehicleDrivers.length > 1) {
                        showAlert({
                            title: messages.vehicleCanHave1PriDriver,
                            message: translator(messages.vehicleHasMoreThen1Drivers, { vehicleName: vehicleName }),
                            status: 'error',
                            icon: 'mi-error-outline'
                        });

                        return false;
                    }

                    if (mainVehicleDrivers.length < 1) {
                        showAlert({
                            title: messages.inconsistentInformation,
                            message: translator(messages.vehicleHas0Drivers, { vehicleName: vehicleName }),
                            status: 'error',
                            icon: 'mi-error-outline'
                        });

                        return false;
                    }
                }
            }
        } else {
            // 1b) can be only 1 PRI driver
            const primaryDriver = coverables.vehicleDrivers.find((vd) => vd.vehicleDriverUsage === 'PRI');

            if (_.isUndefined(primaryDriver)) {
                showAlert({
                    title: messages.missingInformationErrorTitle,
                    message: messages.mainDriverErrorDesc,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: messages.close
                });
                return false;
            }
        }

        // 2) each driver must drive at least 1 vehicle
        for (const d of coverables.drivers) { // NOTE: for instead of forEach used to breakaway from the cycle at first error
            const driverName = DriverUtil.getShortDisplayName(d, translator);
            const anyVehicleDrivers = coverables.vehicleDrivers.filter(
                (vd) => ((vd.driverID && vd.driverID === d.fixedId || vd.driverTempID && vd.driverTempID === d.tempID) &&
                            vd.vehicleDriverUsage !== BusinessConstant.VEHICLE_DRIVER_USAGE_NDR)
            );

            if (anyVehicleDrivers.length === 0) {

                showAlert({
                    title: messages.missingInformationErrorTitle,
                    message: translator(messages.secondaryDriverMustDriveDesc, { driverName: driverName }),
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: messages.close
                });

                return false;
            }
        }


        return true;
    }, [translator, showAlert]);

    return {
        validVehicleDrivers
    };
};

export default DriverPageUtil;
