import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useModal } from '@jutro/components';
import { getConfigValue } from "@jutro/config";
import { useTranslator } from '@jutro/locale';

import { getExperienceById } from '@xengage/gw-microapp-policytransaction-js';
import { messages as platformMessages} from '@xengage/gw-platform-translations';

import { PolicyTransactionMicroAppWrapper } from '@xengage/gw-microapp-policytransaction-react';
import { MapUtil } from '@xengage/gw-portals-util-js';


import messages from './PolicyTransactionPage.messages.js';
import styles from './PolicyTransactionPage.module.scss'

export const PolicyTransactionPage = () => {
    const {
        showAlert
    } = useModal();

    const history = useHistory();
    let { state } = useLocation();
    if (!!state && (state?.productCode)) {
        sessionStorage.setItem('ptm.state', JSON.stringify(state));
    } else {
        state = JSON.parse(sessionStorage.getItem('ptm.state'));
    }
    const { productCode, accountId, jobId } = state;
    const [{ schema, metadata }, setExperience] = useState({});
    const [hasExperienceFailed, setExperienceFailureState] = useState(false);
    const translator = useTranslator();

    useEffect(() => {
        const getExperience = async () => {
            try {
                const experienceIds = getConfigValue('EXPERIENCE_IDS');
                const experienceIdsTokens = !_.isNil(experienceIds) && experienceIds.length > 0 ? experienceIds.split(";") : [];
                if (experienceIdsTokens.length > 0) {
                    const experienceId = experienceIdsTokens
                        .filter((token) => token.includes(`${productCode}:`))
                        .map((token) => {
                            const lobExperience = token.split(":");
                            return lobExperience[1];
                        });
                    const experience = await getExperienceById(experienceId);
                    setExperience(experience);
                } else {
                    handleError(messages.failToLoadExperience);
                }
            } catch (ex) {
                console.error(ex);
                setExperienceFailureState(true);
            }
        }

        getExperience();
        // We want to get the experience only once, so we need to disable eslint validation here
        // eslint-disable-next-line
    }, []);

    const handleError = useCallback((error = {}) => {
        const resetState = {
            pathname: '/',
            state: error
        };

        return showAlert({
            title: translator(messages.errorTitle),
            message: error === messages.failToLoadExperience ? translator(messages.failToLoadExperience) : translator(messages.errorMessage),
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: translator(platformMessages.ok)
        }).then(
            () => history.push(resetState)
        );
    }, [translator, history]);

    const googleMapsApiKey = MapUtil.isApiKeySet() ? MapUtil.getApiKey() : undefined;

    const pageContent = hasExperienceFailed ? (
        <div id='experience-failure-message'>{translator(messages.failToLoadExperience)}</div>
    ) : (
        <PolicyTransactionMicroAppWrapper
            id='policy-transaction-microapp-wrapper'
            schema={schema}
            metadata={metadata}
            handleError={handleError}
            jobId={jobId}
            accountId={accountId}
            productCode={productCode}
            googleMapsApiKey={googleMapsApiKey}
        />
    )

    return (
        <div className={styles.wrapperContainer}>
            {pageContent}
        </div>
    );
};
