import { defineMessages } from 'react-intl';

export default defineMessages({
    finalConfirmation: {
        id: "quoteandbind.ppv.views.ppv-confirmation.Final Confirmation",
        defaultMessage: "Final Confirmation"
    },
    licensePlate: {
        id: "quoteandbind.ppv.views.ppv-confirmation.LicensePlate",
        defaultMessage: "License Plate"
    },
    successfullyIssued: {
        id: "quoteandbind.ppv.views.ppv-confirmation.The policy was successfully issued",
        defaultMessage: "The contract was successfully issued"
    },
    signaturePayment: {
        id: "quoteandbind.ppv.views.ppv-confirmation.Almost there",
        defaultMessage: "Almost there! The contract can be issued as soon as a signature, payment, and (all) license plate(s) are received."
    },
    contractNumber: {
        id: "quoteandbind.ppv.views.ppv-confirmation.Policy Number",
        defaultMessage: "Contract Number"
    },
    requestNewLicense: {
        id: "quoteandbind.ppv.views.ppv-confirmation.Request a new license plate or send an update to the DIV",
        defaultMessage: "Request a new license plate or send an update to the DIV"
    },
    processTransferUpdate: {
        id: "quoteandbind.ppv.views.ppv-confirmation.If applicable, don't forget to request a new license plate or process transfer updates on an existing one",
        defaultMessage: "If applicable, don't forget to request a new license plate or process transfer updates on an existing one"
    },
    WebDIV: {
        id: "quoteandbind.ppv.views.ppv-confirmation.Go to WebDIV",
        defaultMessage: "Go to WebDIV"
    },
    enterLicensePlateDetails: {
        id: "quoteandbind.ppv.views.ppv-confirmation.Enter license plate details",
        defaultMessage: "Enter license plate details"
    },
    providedLicensePlateDetails: {
        id: "quoteandbind.ppv.views.ppv-confirmation.Paste the license plate(s) provided by WebDIV below",
        defaultMessage: "Paste the license plate(s) provided by WebDIV below"
    },
    successfullyChanged:{
        id: "endorsement.common.views.confirmation.The policy was successfully changed",
        defaultMessage: "The contract was successfully changed"
    },
    noLicensePlate:{
        id:"endorsement.common.views.confirmation.Almost there",
        defaultMessage:"Almost there! The contract change can be issued as soon as a signature and (all) license plate(s) are received."
    },
    noSignature:{
        id:"endorsement.common.views.confirmation.Almost there LP Available",
        defaultMessage:"Almost there! The contract change can be issued as soon as a signature is received."
    }
});
