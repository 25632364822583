import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


export default class ActivitiesService {
    static getActivitiesForUser(additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayActivity'),
            'getActivitiesForUser',
            [],
            additionalHeaders
        );
    }

    static getActivitiesForCurrentUserAndGroups(additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayActivity'),
            'getActivitiesForCurrentUserAndGroups',
            [],
            additionalHeaders
        );
    }

    static getActivitiesForPolicy(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'getActivitiesForPolicy', [policyNumber], additionalHeaders);
    }

    static getActivitiesForAccount(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'getActivitiesForAccount', [accountNumber], additionalHeaders);
    }

    static getActivitiesForJob(jobNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'getActivitiesForJob', [jobNumber], additionalHeaders);
    }

    static getActivityPatternsFor(actPatternEntity, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'getActivityPatternsFor', [actPatternEntity], additionalHeaders);
    }

    static getAssignableUserForActivity(actPatternEntity, id, activityPattern,
        additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'getAssignableUserForActivity', [actPatternEntity, id, activityPattern], additionalHeaders);
    }

    static createNewActivity(activity, note, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'createNewActivity', [activity, note], additionalHeaders);
    }

    static createNoteForActivity(param, data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'createNoteForActivity',
            [param, data], additionalHeaders);
    }

    static markActivityAsCompleted(activityPublicID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'markActivityAsCompleted', [activityPublicID], additionalHeaders);
    }

    static reassignActivity(selectedUser, id, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'reassignActivity', [selectedUser, id], additionalHeaders);
    }

}
