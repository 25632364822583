import _ from 'lodash';

export default function formatDocumentTableData(intl, documentsData, keyword) {
    const filterDocuments = (documents) => {
        if (_.isEmpty(keyword)) {
            return documents;
        }
        return _.filter(documents, (document) => _.includes(document.name.toLocaleLowerCase(),
            keyword.toLocaleLowerCase()));
    };
    if (!_.isEmpty(documentsData)) {
        const documents = documentsData.map((documentsInfo) => {
            return {
                name: documentsInfo.name,
                dateModified: documentsInfo.dateModified,
                remove: '',
                documentType: documentsInfo.documentType,
                source_PV: documentsInfo.source_PV,
                sessionID: documentsInfo.sessionID,
                publicID: documentsInfo.publicID
            };
        });
        return filterDocuments(documents);
    }
    return documentsData;
}
