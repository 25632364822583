import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BusinessConstant } from 'pv-portals-util-js';
import metadata from './DeclarationsComponent.metadata.json5';
import styles from "./DeclarationsComponent.module.scss";
import messages from './DeclarationsComponent.messages.js';

const URL_AGENT_FR = 'https://www.pv.be/documents/1465818/1490710/Brochure+informations+pour+le+preneur+assurance+MiFID.pdf/dd00d2e7-5558-4ad4-9710-38a61b2b50d3?t=1533540804363';
const URL_AGENT_NL = 'https://www.pv.be/documents/1465818/1490710/Informatiebrochure+voor+de+verzekeringsnemer+MiFID.pdf/c1cbcc79-e2ce-4f76-bc38-b9df00d6e967?t=1533540757755';
const URL_BROKER_FR = 'https://www.vivium.be/documents/2559811/4187210/Brochure+d%E2%80%99informations+MiFID+pour+le+preneur+d%E2%80%99assurance.pdf/88e67479-9d3e-126c-9cae-4fce52aa93b2?t=1483960694000';
const URL_BROKER_NL = 'https://www.vivium.be/documents/2559811/3213051/Informatiebrochure+MiFID+voor+de+verzekeringsnemer.pdf/8e7dbd86-9cb8-4ead-9286-c6b2352360f6?t=1533539966097';

function DeclarationsComponent(props) {
    const {
        id,
        submissionVM,
        onValueChange,
        onValidate,
        isPolicyChange,
        isBlockingUWIssues,
        showError
    } = props;
    const translator = useTranslator();
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const bindData = isPolicyChange ? 'bindData_PV' : 'bindData';
    const [ isVivium ] = useState(_.get(submissionVM, 'baseData.brand_PV.value.code') === BusinessConstant.PV_BRAND_VIVIUM);
    const [ quickQuoteMode ] = useState(_.get(submissionVM, 'baseData.quoteType.value.code') === 'Quick');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const getBrochureInformedUrl  = useCallback(() => {
        const currentLanguage = _.get(submissionVM, 'baseData.primaryLanguage_PV.value.code');
        if(isVivium) {
            return currentLanguage === 'nl' ? URL_BROKER_NL : URL_BROKER_FR;
        }
        return currentLanguage === 'nl' ? URL_AGENT_NL : URL_AGENT_FR;
    }, [submissionVM, isVivium]);

    const getGeneralConditionsInformedUrl  = useCallback(() => {
        const currentLanguage = _.get(submissionVM, 'baseData.primaryLanguage_PV.value.code');
        return currentLanguage === 'nl' || currentLanguage === 'fr' ? _.get(submissionVM, `${bindData}.declaration.generalConditionsURL.value`) : '#';
    }, [bindData, submissionVM]);

    const overrideProps = {
        '@field': {
            showRequired: false
        },
        triggerUwFreeText: {
            readOnly: isPolicyChange ? isBlockingUWIssues : false
        },
        declarationsContainer: {
            visible: !quickQuoteMode
        },
        managementContainer: {
            visible: isVivium,
            disabled: isBlockingUWIssues
        },
        brochureInformed: {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            label: translator(messages.brochureInformed, {a: (chunks) => <a href={getBrochureInformedUrl()} target='_blank' rel="noreferrer" className='urlWithColor'>{chunks}</a>}),
            disabled: isBlockingUWIssues,
            path: `${bindData}.declaration.brochureInformed`,
            visible:  !isPolicyChange
        },
        generalConditionsInformed: {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            label: translator(messages.generalConditionsInformed, {a: (chunks) => <a href={getGeneralConditionsInformedUrl()} target='_blank' rel="noreferrer" className='urlWithColor'>{chunks}</a>}),
            disabled: isBlockingUWIssues,
            path: `${bindData}.declaration.generalConditionsInformed`,
            visible:  !isPolicyChange
        },
        statementsConfirmed: {
            path: `${bindData}.declaration.statementsConfirmed`,
            disabled: isBlockingUWIssues
        },
        eInfoAccepted: {
            visible: !isPolicyChange && !!_.get(submissionVM, 'baseData.accountHolder.emailAddress1.value'),
            path: `${bindData}.declaration.einfoAccepted`,
            disabled: isBlockingUWIssues
        },
        customerAgrees: {
            path: `${bindData}.declaration.proposedSolutionAgreed`,
            disabled: isBlockingUWIssues,
            showRequired: true
        },
        mifiedDocument: {
            path: `${bindData}.declaration.producerAnalysisReq_PV`,
        },
        consequencesAccepted: {
            path: `${bindData}.declaration.consequencesAccepted`,
            visible: _.get(submissionVM, `${bindData}.declaration.proposedSolutionAgreed.value`) === false,
            disabled: isBlockingUWIssues
        },
        producerRemarksContainer: {
            visible:  isPolicyChange || _.get(submissionVM, `${bindData}.declaration.producerAnalysisReq_PV.value`) === false,
        },
        producerRemarksText: {
            path: `${bindData}.declaration.producerRemarks_PV`,
            readOnly: isBlockingUWIssues
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            onValueChange={onValueChange}
            onValidationChange={setComponentValidation}
            overrideProps={overrideProps}
            showErrors={showError}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

DeclarationsComponent.propTypes = {
    submissionVM: PropTypes.shape({}),
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    isPolicyChange: PropTypes.bool,
    isBlockingUWIssues: PropTypes.bool,
    showError: PropTypes.bool
};

DeclarationsComponent.defaultProps = {
    submissionVM: {},
    isPolicyChange: false,
    isBlockingUWIssues: false,
    showError: false
};

export default DeclarationsComponent;
