import React from 'react';
import PropTypes from 'prop-types';
import PolicyContext from './PolicyContext';

const PolicyContextProvider = (props) => {
    const [canJumpPage, setCanJumpPage] = React.useState(true);
    const [onJump, setOnJump] = React.useState(false);

    return <PolicyContext.Provider value={{ canJumpPage, setCanJumpPage, onJump, setOnJump }}>{props.children}</PolicyContext.Provider>;
};

PolicyContextProvider.propTypes = {
    children: PropTypes.node
};

export default PolicyContextProvider;
