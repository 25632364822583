import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import {
    ViewModelServiceContext,
    withViewModelService,
    ViewModelForm
} from '@xengage/gw-portals-viewmodel-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { UserService } from 'gw-capability-gateway';
import metadata from './NotesComponent.metadata.json5';
import messages from './NotesComponent.messages';
import styles from './NotesComponent.module.scss';

const NotesComponent = (props) => {
    const {
        authHeader,
        initialNotesData,
        createNote,
        getNotesTileUpdatedCount,
        publicID,
        noDataMessage,
        openActivitiesNote,
        showNoteButton
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [notesSubmissionVM, updateNotesSubmissionVM] = useState({});
    const [showNotes, updateShowNotes] = useState(false);
    const [notesDataTotal, updateNotesDataFiltered] = useState([]);
    const [noOfMoreNotes, updateNoOfMoreNotes] = useState(true);
    const [searchFilter, updateSearchFilter] = useState('');
    const [addClicked,updateAddClicked]=useState(false);
    const intl = useContext(IntlContext);

    const [showAddNoteButton, updateShowAddNoteButton] = useState(false);

    const { onValidate, isComponentValid } = useValidation('NotesComponent');

    const createVM = useCallback(
        (model) => {
            return viewModelService.create(
                model,
                'pc',
                'edge.capabilities.gateway.note.dto.NoteDTO'
            );
        },
        [viewModelService]
    );

    const displayNotes = useCallback(
        (notesData) => {
            const topicAvailableValues = _.get(notesSubmissionVM, 'topic.aspects.availableValues');
            if (!_.isEmpty(notesData) && !_.isEmpty(topicAvailableValues)) {
                const notes = _.orderBy(notesData, ['createdDate'], ['desc']).map((notesInfo) => {
                    const topicName = _.get(
                        topicAvailableValues.find((value) => {
                            return value.code === notesInfo.topic;
                        }),
                        'name'
                    );
                    return {
                        noteTopic: translator(topicName),
                        noteSubject: notesInfo.subject,
                        body: notesInfo.body,
                        createdDate: notesInfo.createdDate,
                        author: notesInfo.authorName
                    };
                });
                return notes;
            }
            return notesData;
        },
        [notesSubmissionVM, translator]
    );

    const getLatestNote = useCallback((latestNotesData) => {
        if (!_.isEmpty(latestNotesData)) {
            const displayNoteLatestData = _.orderBy(latestNotesData, ['createdDate'], ['desc'])[0];
            const note = {
                noteTopic: displayNoteLatestData.topic,
                noteSubject: displayNoteLatestData.subject,
                body: displayNoteLatestData.body,
                createdDate: displayNoteLatestData.createdDate,
                author: displayNoteLatestData.authorName
            };
            return note;
        }
        return latestNotesData;
    }, []);

    const getOpenActivitiesNotesList = useCallback(() => {
        const openActivitiesNotesList = noOfMoreNotes
            ? [getLatestNote(initialNotesData)]
            : displayNotes(initialNotesData);
        return openActivitiesNotesList;
    }, [initialNotesData, noOfMoreNotes, displayNotes, getLatestNote]);

    const showHideNotesOnClick = () => {
        updateNoOfMoreNotes(!noOfMoreNotes);
    };

    const shouldShowNotesButton = useCallback(async () => {
        const permissionDTO = {
            permission: 'notecreate'
        };
        const isPermisson = await UserService.hasUserSystemPermission(permissionDTO, authHeader);
        updateShowAddNoteButton(isPermisson);
    }, [authHeader]);

    const writeValue = useCallback(
        (value, path) => {
            const newNotesSubmissionVM = viewModelService.clone(notesSubmissionVM);
            _.set(newNotesSubmissionVM.value, path, value);
            updateNotesSubmissionVM(newNotesSubmissionVM);
        },
        [notesSubmissionVM, viewModelService]
    );

    const handleFormSubmit = useCallback(() => {
        updateAddClicked(true);
        if(isComponentValid){
            if (!_.isUndefined(publicID)) {
                createNote(notesSubmissionVM.value, publicID);
                if (getNotesTileUpdatedCount) {
                    getNotesTileUpdatedCount();
                }
            } else {
                createNote(notesSubmissionVM.value);
                if (getNotesTileUpdatedCount) {
                    getNotesTileUpdatedCount();
                }
            }
            updateShowNotes(false);
            updateAddClicked(false);
        }
    }, [isComponentValid, publicID, createNote, notesSubmissionVM.value, getNotesTileUpdatedCount]);

    const addNotesBox = useCallback(() => {
        updateAddClicked(false);
        updateNotesSubmissionVM(createVM({}));
        updateShowNotes(true);
    }, [updateShowNotes, createVM]);

    const cancelNotesBox = useCallback(() => {
        updateNotesSubmissionVM(createVM({}));
        updateShowNotes(false);
    }, [updateShowNotes, updateNotesSubmissionVM, createVM]);

    const formatAMPM = useCallback((date) => {
        let hours = date.getHours() % 12;
        let minutes = date.getMinutes();
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        hours = hours || 12;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        const strTime = `${hours}:${minutes} ${ampm}`;
        return strTime;
    }, []);

    const getUserCellData = useCallback(
        (items, index, { path: property }) => {
            return (
                <div>
                    <div className={styles.noteTopic}>{items[property]}</div>
                    <div className={styles.noteDate}>
                        {intl.formatDate(new Date(items.createdDate), { year: 'numeric', month: 'short', day: 'numeric' })}
                    </div>
                    <div className={styles.noteDate}>{formatAMPM(new Date(items.createdDate))}</div>
                </div>
            );
        },
        [formatAMPM, intl]
    );

    const getNotesCellData = useCallback((items, index, { path: property }) => {
        return (
            <div>
                <p className={styles.noteSubject}>{items[property]}</p>
                <p style={{ whiteSpace: 'pre-wrap' }}>{items.body}</p>
            </div>
        );
    }, []);

    const getSearchFilterValues = useCallback((notesArrayResult, filter) => {
        const lowerCaseFilterValue = filter.value.toLocaleLowerCase();
        return _.filter(notesArrayResult, (res) => {
            return Object.keys(res).some(
                (key) => typeof res[key] === 'string'
                    && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
            );
        });
    }, []);

    const getTopicCellData = useCallback(
        (items, index, { path: property }) => {
            const getValue = translator({
                id: items[property],
                defaultMessage: items[property]
            });
            return openActivitiesNote ? '' : <span className={styles.noteTopic}>{getValue}</span>;
        },
        [openActivitiesNote, translator]
    );

    const renderTableData = useCallback((item, index, { id: property }) => {
        return item[property];
    }, []);

    const getShowOrHideNoteLabel = useCallback(() => {
        const showOrHideLabel = noOfMoreNotes
            ? translator(messages.moreNotes, { value: initialNotesData.length - 1 })
            : translator(messages.hideNotes);
        return showOrHideLabel;
    }, [initialNotesData.length, translator, noOfMoreNotes]);

    const getFilteredNotesList = useCallback(
        (notesDataList, filter) => {
            let notesDataFiltered = notesDataList;
            notesDataFiltered = filter.type === 'SearchFilter'
                ? getSearchFilterValues(notesDataFiltered, filter)
                : notesDataList;
            updateNotesDataFiltered(notesDataFiltered);
        },
        [updateNotesDataFiltered, getSearchFilterValues]
    );

    useEffect(() => {
        const model = {};
        const notesNextSubmissionVM = createVM(model);
        updateNotesSubmissionVM(notesNextSubmissionVM);
        shouldShowNotesButton();
        getFilteredNotesList(initialNotesData, '');
    }, [createVM, getFilteredNotesList, initialNotesData,
        shouldShowNotesButton, updateNotesSubmissionVM]);

    const handleSearchChange = (value) => {
        const filter = {
            type: null,
            value: null
        };
        filter.type = 'SearchFilter';
        filter.value = value;
        updateSearchFilter(value);
        getFilteredNotesList(initialNotesData, filter);
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            showErrors : !isComponentValid && addClicked
        },
        notesTitleContainer: {
            visible: !openActivitiesNote
        },
        notesSectionId: {
            visible: showNotes
        },
        notesDataContainer: {
            visible: !_.isEmpty(initialNotesData)
        },
        noteAndShowHideSection: {
            visible: openActivitiesNote && !showNotes
        },
        notesTableGrid: {
            data: openActivitiesNote
                ? getOpenActivitiesNotesList(initialNotesData)
                : displayNotes(notesDataTotal),
            showSearch: false
        },
        notesAddButtonId: {
            visible: showAddNoteButton && !showNotes
        },
        noNotesDetails: {
            visible: _.isEmpty(initialNotesData)
        },
        noNotesText: {
            message: noDataMessage
        },
        infoBoxIcon: {
            visible: _.isUndefined(publicID)
        },
        showHideLinkContainer: {
            visible: initialNotesData.length > 1
        },
        showHideLinkLabel: {
            content: openActivitiesNote ? getShowOrHideNoteLabel() : ''
        },
        notesAddButtonIdinShowHide: {
            visible: showAddNoteButton && !showNotes && openActivitiesNote && showNoteButton
        },
        notesContainer: {
            className: openActivitiesNote ? styles.notesContainerStyle : styles.notesContainer
        },
        notesText: {
            visible: openActivitiesNote && showNoteButton
        },
        searchFilter: {
            value: searchFilter
        },
        seachFilterLayout: {
            visible: !_.isEmpty(initialNotesData)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            addNotesBox: addNotesBox,
            cancelNotesBox: cancelNotesBox,
            handleFormSubmit: handleFormSubmit,
            renderTableData: renderTableData,
            onUserCell: getUserCellData,
            onNotesCell: getNotesCellData,
            onTopicCell: getTopicCellData,
            showHideNotesOnClick: showHideNotesOnClick,
            formatAMPM: formatAMPM,
            handleSearchValueChange: handleSearchChange
        }
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            notesSubmissionVM,
            id,
            path,
            overrideProps
        );
    };
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            model={notesSubmissionVM}
            onValueChange={writeValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
            onValidationChange={onValidate}
        />
    );
};

NotesComponent.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
    initialNotesData: PropTypes.arrayOf(
        PropTypes.shape({
            noteTopic: PropTypes.string,
            noteSubject: PropTypes.string,
            body: PropTypes.string,
            createdDate: PropTypes.string,
            author: PropTypes.string
        })
    ).isRequired,
    publicID: PropTypes.string.isRequired,
    createNote: PropTypes.func.isRequired,
    openActivitiesNote: PropTypes.bool.isRequired,
    noDataMessage: PropTypes.string.isRequired,
    getNotesTileUpdatedCount: PropTypes.func.isRequired,
    showNoteButton: PropTypes.bool.isRequired
};
export default withAuthenticationContext(withViewModelService(NotesComponent));
