import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { VehicleUtil } from 'pv-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './VinAndLicenseplate.metadata.json5';
import styles from "./VinAndLicenseplate.module.scss";

const FIELDS_CONVERT_UPPERCASE = ['license', 'vin'];

function VinAndLicenseplate(props) {
    const translator = useTranslator();

    const {
        data: vehicleVM,
        onValueChange,
        path,
        labelPosition,
        phoneWide,
        showOptional,
        showErrors,
        showRequired,
        onValidate,
        id,
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, vehicleVM]);

    const handleValueChange = useCallback((value, changedPath) => {
        let formattedValue = value;
        if (changedPath === "license" && value) {
            formattedValue = value.replace(/[^A-Za-z0-9]+/gi, '');
        }

        if (FIELDS_CONVERT_UPPERCASE.includes(changedPath)) {
            formattedValue = formattedValue?.toUpperCase();
        }
        const fullPath = `${path}.${changedPath}`;
        if (onValueChange) {
            onValueChange(formattedValue, fullPath);
        }
    }, [onValueChange, path]);

    const overrideProps = {
        '@field': {
            labelPosition,
            phoneWide,
            showOptional,
            showRequired
        },

        vehicleTitle: {
            content: VehicleUtil.getFullDisplayName(vehicleVM, translator)
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {}
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={vehicleVM}
            showErrors={showErrors}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

VinAndLicenseplate.propTypes = {
    data: PropTypes.shape({}).isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string,
    onValidate: PropTypes.func.isRequired,
    labelPosition: PropTypes.string,
    phoneWide: PropTypes.shape({}),
    showOptional: PropTypes.bool,
    showRequired: PropTypes.bool,
    showErrors: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string.isRequired,
};

VinAndLicenseplate.defaultProps = {
    data: {},
    labelPosition: 'left',
    phoneWide: {
        labelPosition: 'top'
    },
    path: undefined,
    showOptional: true,
    showRequired: true,
    index: 0
};

export default VinAndLicenseplate;
