import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useModal, Icon } from '@jutro/components';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { BusinessConstant, CoverablesUtil, VehicleUtil, SanitizerUtil, VirtualProductUtil } from 'pv-portals-util-js';
import { ScrollToError } from '@jutro/legacy/wizard-next';
import VehiclePageUtil from './VehiclePageUtil';
import VehicleDetails from '../../components/VehicleDetails/VehicleDetails';
import VehicleLookup from '../../components/VehicleLookup/VehicleLookup';
import metadata from './VehiclesPage.metadata.json5';
import messages from './VehiclesPage.messages';

const vehicleNumberLimits = {
    ppv_agent_pv: 6,
    pcv_agent_pv: 10,
    ppv_broker_pv: 6,
    pcv_broker_pv: 10,
    ppv_direct_pv: 2,
    pcv_direct_pv: 2
};

function VehiclesPage(props) {
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { initialSteps } = useDependencies('initialSteps');
    const breakpoint = useContext(BreakpointTrackerContext);
    const { wizardData: submissionVM, updateWizardData, currentStep, changeNextSteps, steps } = props;
    const { stepProps: {
        quickQuoteMode = false
    } } = currentStep;
    const {
        onValidate,
        initialValidation,
        disregardFieldValidation,
        registerInitialComponentValidation,
        registerComponentValidation
    } = useValidation('VehiclesPage');
  	const defaultActiveTab = 'vehicle0vehicleList';
    const [activeTab, setActiveTab] = useState(defaultActiveTab);
    const [showErrors, setShowErrors] = useState(false);
    const [errorTimestamp, setErrorTimestamp] = useState(0);
    const { authHeader } = useAuthentication();
    const { isValid, ensureVehicleCategoryTB } = VehiclePageUtil();

    const [jobType] = useState(_.get(submissionVM, 'baseData.jobType.value.code')); // Submission vs. PolicyChange
    const [brand] = useState(_.get(submissionVM, 'baseData.brand_PV.value.code'));
    const [jobNumber] = useState(jobType === 'Submission' ? _.get(submissionVM, 'value.quoteID') : _.get(submissionVM, 'value.jobID'));
    const [productCode] = useState(_.get(submissionVM, 'baseData.productCode.value'));
    const [virtualProductCode] = useState(_.get(submissionVM, 'baseData.virtualProductCode_PV.value'));
    const [showPrevious, setShowPrevious] = useState(false);
    const [isCommercial] = useState(productCode === BusinessConstant.PCV_PROD_CODE);
    const [lobVmPath] = useState(isCommercial ? BusinessConstant.PCV_LOB_CODE : BusinessConstant.PPV_LOB_CODE);
    const [isPolicyChange] = useState(jobType === BusinessConstant.JOB_TYPE_POLICY_CHANGE);

    // does not make sense to use caching/useState... whole submissionVM changes brutally often anyway
    const lobModel = submissionVM.lobData[lobVmPath].value;
    const vehicleListVm = submissionVM.lobData[lobVmPath].coverables.vehicles.children;
    const vehicleList = submissionVM.lobData[lobVmPath].coverables.value.vehicles;
    const activeTabIdx = activeTab.match(/\d/)[0];
    const selectedVehicleVm = submissionVM.lobData[lobVmPath].coverables.vehicles.children[activeTabIdx];
    const vehicleDrivers = submissionVM.lobData[lobVmPath].coverables.vehicleDrivers;

    const prepareWizardSteps = useCallback(() => {
        if (isCommercial) {
            const pagesToSkip = ['policyChanges', 'vehicles', 'contact', BusinessConstant.POL_CHNG_VIN_LICENSEPLATE];
            if (vehicleList[0].designatedDriver_PV === false) {
                pagesToSkip.push('drivers');
            }
            const newSteps = initialSteps.filter((step) => !pagesToSkip.includes(step.id));
            changeNextSteps(newSteps);
        }
    }, [changeNextSteps, initialSteps, isCommercial, vehicleList]);

    const vpVersionChecker = useMemo(() => VirtualProductUtil.getVersionChecker(submissionVM), []);

    const { showConfirm } = useModal();

    const addVehicle = useCallback(() => {

        // allow to add first vehicle without checking errors/validity, which is NULL
        if (vehicleList.length > 0 && !isValid(selectedVehicleVm, vehicleList)) {
            setShowErrors(true);
            setErrorTimestamp(Date.now());
            return;
        }

        const vehicle = CoverablesUtil.createVehicle(lobModel.coverables);
        CoverablesUtil.tryAssignPrimaryDriver(lobModel.coverables, vehicle);
        VehicleUtil.setDefaultValues(vehicle, isCommercial, undefined, undefined, vpVersionChecker);
        // eslint-disable-next-line camelcase
        vehicle.designatedDriver_PV = vehicleList[0].designatedDriver_PV;

        updateWizardData(submissionVM);
        setActiveTab(`vehicle${vehicleList.length - 1}vehicleList`);
        setShowErrors(false);
    }, [lobModel, submissionVM, updateWizardData, vehicleList, isCommercial, isValid, vpVersionChecker, selectedVehicleVm]);

    useEffect(() => {
        // 0. Add vehicle if none present
        if (vehicleList.length === 0) {
            addVehicle();
        }

        updateWizardData((oldVm) => {
            // 1. change context
            const context = {
                'Submission': {
                    IsPackScreen: false,
                    ContractQuestions: false,
                    AdditionalQuestions: false,
                    SummaryQuestions: false,
                    VehicleDetails: true,
                    DriverDetails: false
                },
                'PolicyChange': {
                    IsAgent: true,
                    preQuote: true,
                    AdditionalQuestions: true,
                    DriverDetails: false
                }
            };
            // NOTE: evil fix for the Digital platform defect:
            //      useWizardData.js has "undefined" as initial value: const [data = initialData, updateData] = useState(undefined);
            // Problem: when continuing a transaction with already existing vehicle(s), oldVm first time is still undefined
            if (_.isNil(oldVm)) {
                oldVm = submissionVM;
            }
            const vm = viewModelService.changeContext(oldVm, context[jobType]);
            if (steps.find((step) => step.id === 'contact')) {
                setShowPrevious(true);
            }

            // 2. sanitize & fix missing default values
            _.get(vm, `lobData.${lobVmPath}.coverables.value.vehicles`, []).forEach((veh) => {
                SanitizerUtil.sanitizeVehicleData(veh);

                VehicleUtil.restoreMissingDefaultValues(veh, isCommercial, vpVersionChecker);
            });

            return vm;
        });
        // Only on first render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const shouldPageSkip = useCallback(() => {
        const { quoteType } = submissionVM.baseData.value;

        // We're in New business and submission is still QQ, so we remain
        if (quickQuoteMode === false && quoteType === 'Quick') {
            return false;
        }

        let areThereVehiclesAndDrivers;
        if (isCommercial) {
            areThereVehiclesAndDrivers = (
                submissionVM.lobData[lobVmPath].coverables.vehicles.children.length > 0
                && (submissionVM.lobData[lobVmPath].coverables.vehicles.children[0].designatedDriver_PV.value === false || submissionVM.lobData[lobVmPath].coverables.drivers.children.length > 0)
            );
        } else {
            areThereVehiclesAndDrivers = (
                submissionVM.lobData[lobVmPath].coverables.vehicles.children.length > 0
                && submissionVM.lobData[lobVmPath].coverables.drivers.children.length > 0
            );
        }

        if (areThereVehiclesAndDrivers) {
            prepareWizardSteps();
            return true;
        }

        return false;
    }, [isCommercial, lobVmPath, prepareWizardSteps, quickQuoteMode, submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(shouldPageSkip);
    }, [registerInitialComponentValidation, shouldPageSkip]);

    const onChangeDesignatedDriver = useCallback((value) => {
        vehicleListVm.forEach((veh) => {
            veh.designatedDriver_PV.value = value;
        });
        if (value === false) {
            // in case, vehicle designated driver is removed (false), then remove all assigned drivers too
            vehicleDrivers.value.forEach((vd) => {
                vd.vehicleDriverUsage = BusinessConstant.VEHICLE_DRIVER_USAGE_NDR;
            });
        }
        updateWizardData(submissionVM);
    }, [vehicleListVm, updateWizardData, submissionVM, vehicleDrivers.value]);

    const onDeleteVehicle = useCallback(
        ({ iterableIndex, path }) => {
            const vehicleListPath = `lobData.${lobVmPath}.coverables.vehicles.value`;
            const vehicleVm = _.get(submissionVM, `${path}]`);

            showConfirm({
                title: messages.removeVehicle,
                message: translator(messages.removeConfirmationMsg, {
                    vehicle: VehicleUtil.getShortDisplayName(vehicleVm.value, translator),
                }),
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.yesModel,
                cancelButtonText: commonMessages.noModel
            }).then((results) => {
                if (results !== 'confirm') {
                    return;
                }
                disregardFieldValidation(`vehicle${iterableIndex}`);
                setActiveTab(`vehicle0vehicleList`);
                CoverablesUtil.removeVehicle(lobModel.coverables, vehicleVm.value);
                _.set(submissionVM, vehicleListPath, lobModel.coverables.vehicles);
                updateWizardData(submissionVM);
            });
        },
        [lobVmPath, submissionVM, showConfirm, translator, disregardFieldValidation, lobModel.coverables, updateWizardData]
    );

    const setVehicleLookupData = useCallback((path, informexVehicleInfo) => {
        const vehicleVm = _.get(submissionVM, path);
        // if new informed car is set ... everything is defaulted depending on cat & type
        const lob = productCode === BusinessConstant.PPV_PROD_CODE ? BusinessConstant.LINE_OF_BUSINESS_CODE_PPVLINE_PV : BusinessConstant.LINE_OF_BUSINESS_CODE_PCVLINE_PV;
        const availablePrimaryUseValues = VehicleUtil.getAvailablePrimaryUseValues(viewModelService, vehicleVm, lob, vpVersionChecker);
        VehicleUtil.setDefaultValues(vehicleVm.value, isCommercial, ['category', 'type', 'designatedDriver_PV'], availablePrimaryUseValues, vpVersionChecker);
        VehicleUtil.copyFromInformex(vehicleVm.value, informexVehicleInfo);
        updateWizardData(submissionVM);
    }, [submissionVM, productCode, viewModelService, isCommercial, updateWizardData, vpVersionChecker]);

    const setVehicleCategoryAndType = useCallback((path, category, type) => {
        const vehicleVm = _.get(submissionVM, path);
        // if category & type changes ... everything is reset
        VehicleUtil.setDefaultValues(vehicleVm.value, isCommercial, ['designatedDriver_PV'], undefined, vpVersionChecker);
        vehicleVm.category.value = category;
        vehicleVm.type.value = type;
        // if category & type changes ... everything is defaulted depending on cat & type
        const lob = productCode === BusinessConstant.PPV_PROD_CODE ? BusinessConstant.LINE_OF_BUSINESS_CODE_PPVLINE_PV : BusinessConstant.LINE_OF_BUSINESS_CODE_PCVLINE_PV;
        const availablePrimaryUseValues = VehicleUtil.getAvailablePrimaryUseValues(viewModelService, vehicleVm, lob, vpVersionChecker);
        VehicleUtil.setDefaultValues(vehicleVm.value, isCommercial, ['category', 'type', 'designatedDriver_PV'], availablePrimaryUseValues, vpVersionChecker);
        updateWizardData(submissionVM);
    }, [submissionVM, isCommercial, productCode, viewModelService, updateWizardData, vpVersionChecker]);

    const getTabHeader = useCallback((vehicleVm, isCurrentTab) => {
        const typeName = _.get(vehicleVm, 'type.value.name');
        const typeMsg = typeName ? translator({ id: typeName }) : translator(messages.vehicle);
        const iconUndoVisible = isCurrentTab && typeName && !isPolicyChange;
        return (
            <React.Fragment>
                <span id={typeMsg}>{typeMsg}</span>
                <span>&nbsp;</span>
                {iconUndoVisible && (
                    <Icon icon="mi-undo" onClick={(event) => { event.stopPropagation(); setShowErrors(false); VehicleUtil.setDefaultValues(vehicleVm.value, isCommercial, ['designatedDriver_PV'], undefined, vpVersionChecker); updateWizardData(submissionVM); }} />
                )}
            </React.Fragment>
        );
    }, [translator, submissionVM, updateWizardData, isCommercial, isPolicyChange, vpVersionChecker]);

    const checkLimitVehicles = useCallback((vehList) => {
        return !(vehicleNumberLimits[virtualProductCode] === vehList.length);
    }, [virtualProductCode]);

    const writeValue = useCallback(
        (value, path) => {
            if (value === '') {
                value = undefined;
            }
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const onTabChange = useCallback((selectedTabID) => {
        setActiveTab(selectedTabID);
    }, []);

    useEffect(() => {
        registerComponentValidation(() => {
            return selectedVehicleVm &&
                selectedVehicleVm.aspects.valid &&
                selectedVehicleVm.aspects.subtreeValid;

        });
    }, [selectedVehicleVm, registerComponentValidation]);

    const onNext = useCallback(async () => {
        setShowErrors(true);

        // 1. do not switch to 1st invalid TAB, but stay on current invalid
        if (!selectedVehicleVm?.aspects.valid || !selectedVehicleVm?.aspects.subtreeValid) {
            setErrorTimestamp(Date.now());
            return false;
        }

        // 2. check other tabs now ...
        const invalidVehicleIdx = vehicleListVm.findIndex((vehicleVm) => !vehicleVm.aspects.valid || !vehicleVm.aspects.subtreeValid);
        if (invalidVehicleIdx !== -1) {
            setActiveTab(`vehicle${invalidVehicleIdx}vehicleList`);
            setErrorTimestamp(Date.now());
            return false;
        }

        // 3. complex validations
        if (!isValid(selectedVehicleVm, vehicleList) || (!isCommercial && !ensureVehicleCategoryTB(vehicleList))) {
            setErrorTimestamp(Date.now());
            return false;
        }

        submissionVM.value = await LoadSaveService.updateDraftSubmission(submissionVM.value, authHeader);

        prepareWizardSteps();

        return submissionVM;
    }, [isValid, selectedVehicleVm, vehicleList, isCommercial, ensureVehicleCategoryTB, submissionVM, LoadSaveService, authHeader, prepareWizardSteps, vehicleListVm]);

    const generateOverrides = useCallback(() => {

        const overrides = vehicleListVm.map((vehicleVm, index) => {
            const vehicleCategoryCode = _.get(vehicleVm, 'category.value.code');
            const vehicleTypeCode = _.get(vehicleVm, 'type.value.code');
            const vehicleTypeSelectorVisible = _.isNil(vehicleCategoryCode) && _.isNil(vehicleTypeCode);
            return {
                [`vehicle${index}`]: {
                    heading: getTabHeader(vehicleVm, `vehicle${index}vehicleList` === activeTab)
                },
                [`vehicleLookup${index}`]: {
                    setVehicleCategoryAndType,
                    setVehicleLookupData,
                    visible: !(isPolicyChange && vehicleVm.value.existingVehicle_PV),

                    jobType,
                    brand,
                    offerNumber: jobNumber,
                    virtualProduct: virtualProductCode,
                    vehicles: vehicleList,
                    productCode
                },
                [`deleteVehicle${index}`]: {
                    visible: vehicleListVm.length > 1 || (isPolicyChange && !vehicleTypeSelectorVisible)
                },
                [`vehicleDetails${index}`]: {
                    visible: !_.isEmpty(vehicleVm.category.value) && !_.isEmpty(vehicleVm.type.value),
                    onChangeDesignatedDriver,
                    jobType,
                    quickQuoteMode,
                    productCode,
                    vpVersionChecker,
                    brand
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [vehicleListVm, getTabHeader, activeTab, setVehicleCategoryAndType, setVehicleLookupData, jobType, isPolicyChange, brand, jobNumber, virtualProductCode, vpVersionChecker, vehicleList, productCode, onChangeDesignatedDriver, quickQuoteMode]);


    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        addVehicleBtn: {
            visible: checkLimitVehicles(vehicleList)
        },
        vehicleList: {
          	defaultActiveTab: defaultActiveTab,
            activeTab: activeTab,
            path: `lobData.${lobVmPath}.coverables.vehicles.children`
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            addVehicle,
            onDeleteVehicle,
            onTabChange,
            onValidate
        },
        resolveComponentMap: {
            VehicleDetails,
            VehicleLookup
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            showCancel={isPolicyChange}
            skipWhen={initialValidation}
            showPrevious={showPrevious}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                showErrors={showErrors}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
            <ScrollToError counter={errorTimestamp} timeout={200} />
        </WizardPage>
    );
}

VehiclesPage.propTypes = wizardProps;
export default VehiclesPage;
