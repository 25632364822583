import { defineMessages } from 'react-intl';

export default defineMessages({
    paVehiclesInContractTitle: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.VehiclesYouDrive Title',
        defaultMessage: 'Vehicles in contract'
    },
    paDriverLicenseTitle: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.DriversLicense Title',
        defaultMessage: 'Driving license'
    },
    paDrivingLicenseB: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.Do you have a driving license B?',
        defaultMessage: 'Do you have a driving license B?'
    },
    paDrivingLicenseBDate: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.When have you obtained your drivers licence B?',
        defaultMessage: `When have you obtained your driver's licence B?`
    },
    paClaimTitle: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.Claims Title',
        defaultMessage: 'Claims'
    },
    paDriverClaimCertificates: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.Claims hasClaimAttestLast3Years',
        defaultMessage: 'Are you able to provide a claim certificate for the last 3 years?'
    },
    paRegularUseCar: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.Claims isCurrentlyARegularDriver',
        defaultMessage: 'Do you regularly use a car? (vehicle of the parents, partner, company,…).'
    },
    paVehiclesYouDrive: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.Maindriver VehiclesYouDrive',
        defaultMessage: 'The main driver is automatically assigned as driver of all vehicles of the same insurance contract.'
    },
    paGeneralInformation: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.GeneralInfo Title',
        defaultMessage: 'General information'
    },
    paDriverFirstName: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.First Name',
        defaultMessage: 'First Name',
    },
    paDriverLastName: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.Last Name',
        defaultMessage: 'Last Name',
    },
    paDriverDateOfBirth: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.Date of Birth',
        defaultMessage: 'Date of Birth',
    },
    paDriverGender: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.Gender',
        defaultMessage: 'Gender',
    },
    paDriverAddressDetails: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.Address Title',
        defaultMessage: 'Address'
    },
    paDriverSameAddress: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.Address copy',
        defaultMessage: 'Same address as contract holder'
    },

    paDriverNumberOfAccidents: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-drivers.Number Of Accidents',
        defaultMessage: 'Number Of Accidents',
    },
    paDriverNumberOfViolations: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-drivers.Number Of Violations',
        defaultMessage: 'Number Of Violations',
    },
    paDriverLicenseYear: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-drivers.-- Choose License Year --',
        defaultMessage: '-- Choose License Year --',
    },
    paDriverLicenseState: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-drivers.-- Choose License State --',
        defaultMessage: '-- Choose License State --',
    },
    paIsMainDriver: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-driver.Are you the main driver?',
        defaultMessage: 'Are you the main driver?'
    },
    paIsMainDriverToolTip: {
        id: 'quoteandbind.pcv.directives.templates.pcv-edit-driver.Maximum one main driver per vehicle is allowedToolTip',
        defaultMessage: 'Maximum one main driver per vehicle is allowed'
    },
    paVehiclesToDrive: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.Which vehicles will you drive?',
        defaultMessage: 'Which vehicles will you drive?'
    },
    paHistoryTitle: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.History Title',
        defaultMessage: 'History'
    },

    pasInsuranceAssociated: {
        id: 'quoteandbind.ppv.directives.templates.ppv-edit-driver.Claims hasOwnInsurance',
        defaultMessage: 'Do you have currently an insurance associated to your name?'
    },
    emailTooltip: {
        id: "endorsement.ppv.directives.ppv-edit-driver.emailToolTip",
        defaultMessage: "Your email address will not be transmitted to third parties."
    },
    licenseBtooltip: {
        id: "quoteandbind.ppv.directives.templates.ppv-edit-driver.dateLicenseToolTip",
        defaultMessage: "The date can be found on the driving license."
    },
    claimsReportedTooltip: {
        id: "quoteandbind.ppv.directives.templates.ppv-edit-driver.Number of claims last 5 years.toolTip",
        defaultMessage: "Number of accidents for which the driver has used his third party liability insurance and for which he was responsible."
    },
    claimsHappenedTooltip: {
        id: "quoteandbind.ppv.directives.templates.ppv-edit-driver.Number of years since last claim happened.toolTip",
        defaultMessage: "If your last claim happened less than 1 year ago, select “0”. If it happened 2 years ago, select “2”."
    },
    claims3partyTooltip: {
        id: "quoteandbind.ppv.directives.templates.ppv-edit-driver.Number of claims for the Third Party Liability.toolTip",
        defaultMessage: "The number of accidents caused by third parties, in which the customer was involved, and for which an intervention of the insurance was requested."
    },
    claimsTheftTooltip: {
        id: "quoteandbind.ppv.directives.templates.ppv-edit-driver.Number of claims for theft or theft attempt.toolTip",
        defaultMessage: "Number of claims due to theft (or attempted theft) for which the driver has used his car insurance."
    },
    medicalDisabilityLabel: {
        id: "endorsement.ppv.directives.ppv-edit-driver.Does one of the drivers have a disability",
        defaultMessage: "Does the driver have a disability?"
    },
    newDriverCommitedOffenseLabel: {
        id: "endorsement.ppv.directives.ppv-edit-driver.Has the driver committed any offense within the last 2 years (hit and run, driving under intoxication or fraud)?",
        defaultMessage: "Has the driver committed any offense within the last 2 years (hit and run, driving under intoxication or fraud)?"
    },
    existingDriverCommitedOffenseLabel: {
        id: 'endorsement.ppv.directives.ppv-edit-driver.Any offense within the last 2 years (hit and run, driving under intoxication or fraud)?',
        defaultMessage: "Any offense within the last 2 years (hit and run, driving under intoxication or fraud)?"
    },
    newDriverLicenceWithdrawelLabel: {
        id: "endorsement.ppv.directives.ppv-edit-driver.Has the drivers experienced a license withdrawal within the last 2 years?",
        defaultMessage: "Has the drivers experienced a license withdrawal within the last 2 years?"
    },
    existingDriverLicenceWithdrawelLabel: {
        id: "endorsement.ppv.directives.ppv-edit-driver.License withdrawal within the last 2 years",
        defaultMessage: "License withdrawal within the last 2 years"
    }
});
