import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { InfoLabel, Icon } from '@jutro/components';
import cx from 'classnames';
import _ from 'lodash';
import { BusinessConstant } from 'pv-portals-util-js';
import styles from './FormattedHeaderComponent.module.scss';
import messages from './FormattedHeaderComponent.messages';
import gatewayMessages from '../../gateway.messages';

import { Link } from '@jutro/router';

function FormattedHeaderComponent({ redirectPath, message, displayName }) {
    const translator = useTranslator();
    return (
        <FormattedMessage
            {...message}
            values={{
                link: (
                    <Link to={redirectPath} className={styles.gwHeaderLink}>
                        {translator(displayName)}
                    </Link>
                )
            }}
        />
    );
}
FormattedHeaderComponent.propTypes = {
    redirectPath: PropTypes.string.isRequired,
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    }).isRequired,
    displayName: PropTypes.string.isRequired
};
function PageHeaderComponent(props) {
    const translator = useTranslator();
    const [isSideHeader, setSideHeader] = useState(false);
    const [renderContent, updateRenderContent] = useState({});
//     const [isPolicyAssigned, setPolicyAssigned] = useState(true);
    const submissionVM = props;

    const isPolicyChange = useMemo(() => {
        return _.get(submissionVM.value, 'type') === BusinessConstant.JOB_TYPE_POLICY_CHANGE;
    }, [submissionVM]);

    const isCancellation = useMemo(() => {
        return _.get(submissionVM.value, 'type') === BusinessConstant.JOB_TYPE_CANCELLATION;
    }, [submissionVM]);

    const shouldLinkToPolicy = useMemo(() => {
        // NOTE: The submissionVM contains the policyNumber if it is 'Bound/In Force'
        // or if during quote process the account uses SEPA payment method.
        const isPolicyAssigned = !!submissionVM.value?.policy?.policyNumber;
        return (isPolicyAssigned && !['New', 'Draft', 'Quoted'].includes(submissionVM.value.statusCode)) ||
                    ((isPolicyChange || isCancellation) && ['Quoted', 'Draft', 'Withdrawn'].includes(submissionVM.value.statusCode));
    }, [isCancellation, isPolicyChange, submissionVM.value?.policy?.policyNumber, submissionVM.value.statusCode]);

    const getDisplayType = useCallback(() => {
        const type = _.get(submissionVM.value, 'type');
        if (type === 'Submission') {
            return translator(messages.quote);
        }
        return translator(messages[_.camelCase(type)]) || type;
    }, [submissionVM.value, translator]);

    const getJobStatus = useCallback(() => {
        const type = _.get(submissionVM.value, 'type');
        if (type === 'Submission') {
            if (submissionVM.value.policy) {
                if (submissionVM.value.policy.issued) {
                    return translator(gatewayMessages.issued);
                }
                if (
                    submissionVM.value.status
                    === translator({
                        id: 'typekey.PolicyPeriodStatus.NotTaken',
                        defaultMessage: 'NotTaken'
                    })
                ) {
                    return translator(gatewayMessages.notTaken);
                }
            }
        }
        return _.get(submissionVM.value, 'status');
    }, [submissionVM.value, translator]);

//     const getStatusText = useCallback(() => {
//         const displayType = _.get(submissionVM.value, 'displayType');
//         if (displayType === 'Submission') {
//             return translator(messages.quote);
//         }
//         return _.get(submissionVM.value, 'status');
//     }, [submissionVM.value, translator]);

    useEffect(() => {
        if (!_.isEmpty(submissionVM.value)) {
            if (submissionVM.value.displayType) {
                setSideHeader(true);
            }
//             if (!_.get(submissionVM.value, 'policy.policyNumber')) {
//                 setPolicyAssigned(false);
//             }
            updateRenderContent({
                accountName: _.get(submissionVM.value, 'policy.account.accountHolder.displayName'),
                accountLink: `/accounts/${_.get(
                    submissionVM.value,
                    'policy.account.accountNumber'
                )}/summary`,
                policyName:
                    shouldLinkToPolicy
                        ? `${submissionVM.value.policy.product.productName} (${submissionVM.value.policy.policyNumber})`
                        : submissionVM.value.policy.product.productName,
                policyLink:
                    shouldLinkToPolicy
                    && `/policies/${_.get(submissionVM.value, 'policy.policyNumber')}/summary`,
                typeWithJobNumber: `${getDisplayType()} (${_.get(
                    submissionVM.value,
                    'jobNumber'
                )})`,
                infoText: getJobStatus(),
                infoType: ['New', 'Draft', 'Quoted', 'Bound', 'LegacyConversion'].includes(
                    submissionVM.value.statusCode
                )
                    ? 'info'
                    : 'warning'
            });
        }
    }, [getDisplayType, getJobStatus, shouldLinkToPolicy, submissionVM]);

    const titleContainerClass = cx({
        [styles.gwPageTitle]: !isSideHeader,
        [styles.gwSidePageTitle]: isSideHeader
    });

    const categoryContainerClass = cx({
        [styles.gwSidePageCategory]: isSideHeader
    });

    const associatedPageClass = cx({
        [styles.gwPageAssociated]: !isSideHeader
    });

    if (_.isEmpty(renderContent) || _.isEmpty(submissionVM.value)) {
        return null;
    }
    return (
        <>
            <div className={titleContainerClass}>
                <div className={categoryContainerClass}>
                    {translator(messages.accountTitle)}
                    <Link className={associatedPageClass} to={renderContent.accountLink}>
                        {renderContent.accountName}
                    </Link>
                </div>
                {isSideHeader && (
                    <>
                        <div className={styles.gwSidePageTitleSeparator} />
                        {shouldLinkToPolicy ? (
                            <div className={categoryContainerClass}>
                                {translator(messages.policyNavigation)}
                                <Link className={associatedPageClass} to={renderContent.policyLink}>
                                    {renderContent.policyName}
                                </Link>
                            </div>
                        ) :
                            <div className={categoryContainerClass}>
                                <span>{renderContent.policyName}</span>
                            </div>
                        }
                    </>
                )}
            </div>
            <div className={styles.gwTypeWithLOB}>
                <Icon icon="mi-sync_alt" className={styles.syncIcon} />
                <h1 className={styles.gwPageTitle}>{renderContent.typeWithJobNumber}</h1>
                <InfoLabel
                    id="infoStatus"
                    size="medium"
                    type={renderContent.infoType}
                >
                    {renderContent.infoText}
                </InfoLabel>
            </div>
        </>
    );
}
PageHeaderComponent.propTypes = {
    value: PropTypes.shape({}).isRequired
};
export { FormattedHeaderComponent as default, PageHeaderComponent };
